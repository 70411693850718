import React, { useEffect } from "react";

import { apiVariableGroup } from "App/redux/variable-group/variable-group.api";
import TemplateObsVarGroupPart from "App/components/templates/observation/edition/TemplateObsVarGroupPart";
import { useTemplateObsEdition } from "../../../../../../../components/templates/observation/TemplateObsEditionPage.utils";

export default function TemplateObsEditionVarGroupsPage() {
	const {
		remoteTemplateObs,
		setCurrentVarGroupId,
		setVarGroupEditOpen,
		setVarGroupCreateOpen,
	} = useTemplateObsEdition();

	const { data: remoteVariableGroups } =
		apiVariableGroup.useGetVarGroupsByTemplateObsIdQuery(
			remoteTemplateObs?._id,
		);

	useEffect(() => {
		return () => {
			setVarGroupEditOpen(false);
			setVarGroupCreateOpen(false);
		};
	}, []);

	return (
		<TemplateObsVarGroupPart
			remoteVariableGroups={remoteVariableGroups}
			setCurrentVarGroupId={setCurrentVarGroupId}
			onVarGroupCreateClick={() => {
				setVarGroupCreateOpen(true);
				setVarGroupEditOpen(false);
			}}
			onVarGroupEditClick={() => {
				setVarGroupEditOpen(true);
				setVarGroupCreateOpen(false);
			}}
		/>
	);
}
