"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMaterialType = void 0;
var EMaterialType;
(function (EMaterialType) {
    EMaterialType["SY"] = "synthetic method";
    EMaterialType["CR"] = "cross";
    EMaterialType["HY"] = "hybrid";
    EMaterialType["BC"] = "back cross";
    EMaterialType["DH"] = "double haploid";
    EMaterialType["MX"] = "mix";
    EMaterialType["LI"] = "line";
    EMaterialType["FL"] = "fixed line";
    EMaterialType["PF"] = "polycross female";
})(EMaterialType = exports.EMaterialType || (exports.EMaterialType = {}));
