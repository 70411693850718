import { AxiosError } from "axios";

export type ReduxApiError = {
	message: string;
	statusCode?: number;
	responsePayload?: any;
	axiosMessage?: string;
};
export type ReduxApiResponse<TData = any> = {
	data?: TData;
	error?: ReduxApiError;
};
export function defaultApiError(
	err: any,
	message: string,
): { error: ReduxApiError } {
	let statusCode: number | undefined;
	let payload: any;
	let axiosMessage: string | undefined;
	if (err instanceof AxiosError) {
		statusCode = err.response?.status;
		payload = err.response?.data;
		axiosMessage = err.message;
	}
	const errorResult: { error: ReduxApiError } = {
		error: {
			message: message,
			statusCode: statusCode,
			responsePayload: payload,
			axiosMessage,
		},
	};
	return errorResult;
}

export function getReduxApiError(result: any): ReduxApiError | undefined {
	return result?.error;
}
