import React from "react";
import { RouteObject } from "react-router-dom";

import {
	PARAM_MET_ID,
	PARAM_OBSERVATION_ROUND_ID,
	PATH_DATA,
	PATH_GANTT,
	PATH_GENERAL,
	PATH_GERMPLASM,
	PATH_GROWING_AREA,
	PATH_METS,
	PATH_NOTEBOOKS,
	PATH_OBSERVATION_ROUND,
	PATH_OBSERVATION_VARIABLES,
	PATH_OPERATION,
	PATH_PLANNING,
	PATH_SYNTHESIS,
	PATH_TRIALS,
	removeStartingSlash,
	ROUTING_CREATION,
} from "../../routes";
import ExpEditionGeneralPage from "../../Views/main/experiment/experiments/common/part/general/ExpEditionGeneralPage";
import ExpObsUnitPage from "../../Views/main/experiment/experiments/common/part/observation-unit/ExpObsUnitPage";
import ExpPlanningPage from "../../Views/main/experiment/experiments/common/part/planning/ExpPlanningPage";
import ExpGanttPage from "../../Views/main/experiment/experiments/common/part/planning/gantt/ExpGanttPage";
import NotebooksPage from "../../Views/main/experiment/experiments/common/part/planning/observation-round-edition/NotebooksPage.tsx/NotebooksPage";
import ObsRoundEditionGeneralPage from "../../Views/main/experiment/experiments/common/part/planning/observation-round-edition/ObsRoundEditionGeneralPage/ObsRoundEditionGeneralPage";
import ObsRoundEditionPage from "../../Views/main/experiment/experiments/common/part/planning/observation-round-edition/ObsRoundEditionPage";
import ExpObsRoundPage from "../../Views/main/experiment/experiments/common/part/planning/observation-round/ExpObsRoundPage";
import ExpOperationsPage from "../../Views/main/experiment/experiments/common/part/planning/operations/ExpOperationPage";
import ExpVarietyListPage from "../../Views/main/experiment/experiments/common/part/variety-list/ExpVarietyListPage";
import METCreationWizardPage from "../../Views/main/experiment/met/creation/MetCreationWizardPage";
import METGrowingAreaPage from "../../Views/main/experiment/met/edition/growing-area/METGrowingAreaPage";
import METEditionPage from "../../Views/main/experiment/met/edition/METEditionPage";
import METDataPage from "../../Views/main/experiment/met/edition/part/METDataPage";
import METSynthesisPage from "../../Views/main/experiment/met/edition/part/METSynthesisPage";
import { METTrialsPage } from "../../Views/main/experiment/met/edition/part/METTrialsPage";

export function metRouter(): RouteObject {
	return {
		path: removeStartingSlash(PATH_METS),
		children: [
			{
				path: ROUTING_CREATION + PARAM_MET_ID, // if MET already exist
				element: <METCreationWizardPage />,
			},
			{
				path: ROUTING_CREATION, // if new MET
				element: <METCreationWizardPage />,
			},
			{
				path: removeStartingSlash(PARAM_MET_ID),
				element: <METEditionPage />,
				children: [
					{
						path: removeStartingSlash(PATH_GENERAL),
						element: <ExpEditionGeneralPage />,
					},
					{
						path: removeStartingSlash(PATH_OBSERVATION_VARIABLES),
						element: <ExpObsUnitPage />,
					},
					{
						path: removeStartingSlash(PATH_GERMPLASM),
						element: <ExpVarietyListPage />,
					},
					{
						path: removeStartingSlash(PATH_TRIALS),
						element: <METTrialsPage />,
					},
					{
						path: removeStartingSlash(PATH_PLANNING),
						element: <ExpPlanningPage />,
						children: [
							{
								index: true,
								path: removeStartingSlash(PATH_GANTT),
								element: <ExpGanttPage />,
							},
							{
								path: removeStartingSlash(PATH_OPERATION),
								element: <ExpOperationsPage />,
							},
							{
								path: removeStartingSlash(
									PATH_OBSERVATION_ROUND,
								),
								element: <ExpObsRoundPage />,
							},
						],
					},
					{
						path: removeStartingSlash(PATH_GROWING_AREA),
						element: <METGrowingAreaPage />,
					},
					{
						path: removeStartingSlash(PATH_DATA),
						element: <METDataPage />,
					},
					{
						path: removeStartingSlash(PATH_SYNTHESIS),
						element: <METSynthesisPage />,
					},
				],
			},
			{
				path: removeStartingSlash(
					PARAM_MET_ID +
						PATH_PLANNING +
						PATH_OBSERVATION_ROUND +
						PARAM_OBSERVATION_ROUND_ID,
				),
				element: <ObsRoundEditionPage />,
				children: [
					{
						path: removeStartingSlash(PATH_GENERAL),
						element: <ObsRoundEditionGeneralPage />,
					},
				],
			},
		],
	};
}
