import dayjs from "dayjs";
import * as Yup from "yup";

import {
	ESpecies,
	EExperimentProgram,
	EExperimentProject,
	ECurrency,
	EYesNo,
	EEntitiesLength,
} from "shared-type";

import { dayjsYup, dayjsYupTestConstraint } from "../../utils";
import { DIC, EDIC_KEY } from "../../../../dictionary";

export const expGeneralValidator = Yup.object().shape({
	name: Yup.string().required()
.max(EEntitiesLength.name),
	description: Yup.string().max(EEntitiesLength.description),
	label: Yup.string().max(EEntitiesLength.label),
	objective: Yup.string().max(EEntitiesLength.objective),

	startDate: dayjsYup.required("Field is required").test(
		dayjsYupTestConstraint({
			maxDate: new Date("2099-12-31T23:59:59"),
		}),
	),
	endDate: dayjsYup
		.nullable()
		.test(
			dayjsYupTestConstraint({
				maxDate: new Date("2099-12-31T23:59:59"),
			}),
		)
		.test({
			message: DIC(EDIC_KEY.DATE_GREATER_THAN_START_DATE_ERROR),
			test: (value, ctx) =>
				!value || !dayjs(value).isBefore(ctx.parent.startDate),
		}),

	species: Yup.mixed<ESpecies>().oneOf(Object.values(ESpecies))
.required(),

	program: Yup.mixed<EExperimentProgram>().oneOf(
		Object.values(EExperimentProgram),
	),
	project: Yup.mixed<EExperimentProject>().oneOf(
		Object.values(EExperimentProject),
	),

	surface: Yup.number().min(0),
	rowsNumber: Yup.number().integer()
.min(1),
	rowSpacing: Yup.number().integer()
.min(0),
	plantsNumber: Yup.number().integer()
.min(1),
	density: Yup.number().integer()
.min(1),
	cultivationMethod: Yup.string().max(EEntitiesLength.cultivationMethod),
	trialTotalCosts: Yup.number().min(0),
	currency: Yup.mixed<ECurrency>().oneOf(Object.values(ECurrency)),
	contract: Yup.mixed<EYesNo>().oneOf(Object.values(EYesNo)),

	marketSegmentIds: Yup.array().of(Yup.string().required()),
	latitude: Yup.number().min(-90)
.max(90),
	longitude: Yup.number().min(-180)
.max(180),
});

// Type of the validate schema
export type ExpGeneralSchemaType = Yup.InferType<typeof expGeneralValidator>;
