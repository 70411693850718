import TableChartIcon from "@mui/icons-material/TableChart";
import ViewListIcon from "@mui/icons-material/ViewList";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { paginateList } from "common";

import DorianeDataGrid from "App/components/base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import { InfoMessage } from "../../../../../../stories/base-components/InfoMessage/InfoMessage";
import { trialColumns } from "../../../../../components/base-components/data-grid/doriane-data-grid/default-grid-columns/TrialColumns";
import { TRIAL_DEFAULT_PAGE_SIZE } from "../../../../../const";
import { DIC, EDIC_KEY } from "../../../../../dictionary";
import { apiTrial } from "../../../../../redux/experiment/trial/trial.api";
import { getTrialPath } from "../../../../../router/experiment/TrialRouter";
import DorianeButton from "../../../../base-components/button/DorianeButton";
import PaginationTool from "../../../../base-components/pagination/PaginationTool";
import FlexBox, {
	EGapSize,
} from "../../../../base-components/placement/flex-box/FlexBox";
import TextSearchBar from "../../../../base-components/searchBar/TextSearchBar";
import TrialCardList from "../../../trial/list/TrialCardList";
import {
	TrialSerializedWithDeps,
	useTrialWithDeps,
} from "../../../trial/list/TrialWithDeps.hook";
import { convertTrialsWithDepsToMETTrialRows } from "./METTrialHelper";

import "./METTrialsList.scss";

export default function METTrialsList(props: {
	onChangeToEditing: () => void;
	metId: string;
	tableView: boolean;
	setTableView: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const nav = useNavigate();
	const { metId } = useParams();

	const [page, setPage] = useState<number>(0);
	const [filter, setFilter] = useState("");
	const [filterDebounce, filterDebounceManager] = useDebounce(filter, 500);

	const trialsRequest = apiTrial.useGetTrialsByMETIdQuery(
		metId ? metId : skipToken,
	);
	const { trialsWithDeps, isError: isTrialError } = useTrialWithDeps(
		trialsRequest.data,
		true,
		true,
	);

	const sortedTrials = useMemo(() => {
		return trialsWithDeps.sort((trialA, trialB) =>
			trialA.name.localeCompare(trialB.name),
		);
	}, [trialsWithDeps]);

	const filteredTrials = useMemo(() => {
		if (filterDebounce === "") return sortedTrials;
		return sortedTrials.filter((elt) => {
			const filterLC = filterDebounce.toLowerCase();
			let growingAreaMatches = false;
			const growingArea = elt.growingArea;

			if (growingArea) {
				growingAreaMatches =
					growingArea.name.toLowerCase().includes(filterLC) ||
					(growingArea.country !== undefined &&
						growingArea.country.toLowerCase().includes(filterLC)) ||
					(growingArea.region !== undefined &&
						growingArea.region.toLowerCase().includes(filterLC));
			}
			// Filter to review to allow fuzzy search everywhere.
			// It works now because there is no pagination... to review upon implementing lucene search
			return (
				elt.name.toLowerCase().includes(filterLC) ||
				elt.species.toLowerCase().includes(filterLC) ||
				growingAreaMatches ||
				elt.year.toString() === filterDebounce
			);
		});
	}, [filterDebounce, sortedTrials]);

	const paginatedTrials = useMemo(() => {
		return paginateList<TrialSerializedWithDeps>(
			filteredTrials,
			page,
			TRIAL_DEFAULT_PAGE_SIZE,
		);
	}, [filteredTrials, page]);

	const convertedTrialsToRows = useMemo(() => {
		return convertTrialsWithDepsToMETTrialRows(
			paginatedTrials.paginatedList,
		);
	}, [paginatedTrials.paginatedList]);

	return (
		<FlexBox flexDirection="column" fullParentSize gap={EGapSize.MEDIUM}>
			<h1>{DIC(EDIC_KEY.TRIALS)}</h1>
			<FlexBox justifyContent="space-between">
				<TextSearchBar
					onChange={(newFilter) => {
						setFilter(newFilter);
						setPage(0);
					}}
					onSubmit={() => filterDebounceManager.flush()}
				/>
				<FlexBox>
					<DorianeButton
						onClick={() => {
							props.setTableView(
								(tableView: boolean) => !tableView,
							);
						}}
						dorianeStyle="tertiary"
					>
						{props.tableView ? (
							<TableChartIcon className="MET_Table_Icon_small" />
						) : (
							<ViewListIcon className="MET_Table_Icon_medium" />
						)}
					</DorianeButton>
					<DorianeButton
						onClick={props.onChangeToEditing}
						dorianeStyle="tertiary"
					>
						{DIC(EDIC_KEY.EDIT)}
					</DorianeButton>
				</FlexBox>
			</FlexBox>
			{props.tableView ? (
				<>
					<DorianeDataGrid
						columns={trialColumns()}
						rows={convertedTrialsToRows}
						className={"take-remaining-space"}
					/>
					<FlexBox justifyContent={"center"}>
						<PaginationTool
							currentPage={page}
							maxPage={paginatedTrials.totalPages}
							onPageRoll={(newPage) => setPage(newPage)}
						/>
					</FlexBox>
				</>
			) : (
				<>
					{isTrialError.isGAError && (
						<InfoMessage
							infoType="error"
							message={DIC(EDIC_KEY.ERROR_FETCHING_GA_TEXT)}
						/>
					)}
					{isTrialError.isMETError && (
						<InfoMessage
							infoType="error"
							message={DIC(EDIC_KEY.ERROR_FETCHING_MET_TEXT)}
						/>
					)}
					{isTrialError.isMkSegError && (
						<InfoMessage
							infoType="error"
							message={DIC(EDIC_KEY.ERROR_FETCHING_MKSEG_TEXT)}
						/>
					)}
					<TrialCardList
						trials={paginatedTrials.paginatedList}
						isLoading={trialsRequest.isLoading}
						onCardClick={(trial) =>
							trial._id && nav(getTrialPath(trial._id))
						}
						paginate={{
							currentPage: page,
							onPageChange: (newPage) => setPage(newPage),
							totalElements: paginatedTrials.totalPages,
						}}
					/>
				</>
			)}
		</FlexBox>
	);
}
