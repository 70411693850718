import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { CustomTabs } from "../../../components/base-components/tabs/CustomTabs";
import { CustomLinkTab } from "../../../components/base-components/tabs/CustomLinkTab";
import { DIC, EDIC_KEY } from "../../../dictionary";
import { PATH_GENOTYPE, PATH_GERMPLASM, PATH_LOTS } from "../../../routes";

import "./GermplasmDataPage.scss";

export default function GermplasmDataPage() {
	const [tabIdx, setTabIdx] = useState(0);
	const location = useLocation();
	const nav = useNavigate();

	const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
		setTabIdx(Number(newIndex));
	};

	useEffect(() => {
		const pathname = "/" + location.pathname.split("/")[2];

		if (!pathname) return;

		switch (pathname) {
			case PATH_GENOTYPE:
				setTabIdx(0);
				break;
			case PATH_LOTS:
				setTabIdx(1);
				break;
			default:
				setTabIdx(0);
				// == index (because not compatible with path)
				nav("." + PATH_GENOTYPE);
				break;
		}
	}, [location, nav]);

	return (
		<div className="GermplasmDataPage-page flex-column take-remaining-space">
			<div className="flex-column ">
				<div>
					<CustomTabs
						value={tabIdx}
						onChange={handleTabChange}
						indicatorColor="secondary"
					>
						<CustomLinkTab
							label={DIC(EDIC_KEY.GENOTYPE)}
							href={PATH_GERMPLASM + PATH_GENOTYPE}
						/>
						<CustomLinkTab
							label={DIC(EDIC_KEY.LOTS)}
							href={PATH_GERMPLASM + PATH_LOTS}
						/>
					</CustomTabs>
				</div>
			</div>
			<div className="GermplasmDataPage-selected-page">
				<Outlet />
			</div>
		</div>
	);
}
