import { EVariableCoreInputType, IVariableCoreSerialized } from "shared-type";

export function computeLimitedChoicesDisplay(
	initValue: string,
	value?: string | boolean | number,
	variable?: IVariableCoreSerialized,
) {
	if (value === undefined) return "";

	let valueDisplayed = initValue;
	if (variable === undefined) return valueDisplayed;

	if (variable?.inputType === EVariableCoreInputType.LIMITED_CHOICE) {
		const choice = variable.limitedChoices?.find(
			(elt) => elt.value === value,
		);

		// Find the right choice through the limited choices and display it's label with unit if there is one.
		valueDisplayed = `${choice?.label} (${value}${
			variable.unit ? ` ${variable.unit}` : ""
		})`;
	} else {
		// Display the value followed by the eventual unit.
		valueDisplayed = `${value} ${variable.unit ? ` (${variable.unit})` : ""}`;
	}
	return valueDisplayed;
}
