// Projects Paths
export const PATH_PROJECTS = "/projects";

// Template Paths
export const PATH_TEMPLATES = "/templates";
export const PATH_OBSERVATION = "/observation";
export const PATH_OBSERVATION_VARIABLES = "/observation-variables";
export const PARAM_OBS_TEMPLATE_ID = "/:templateObsId";
export const PATH_VARIABLE_GROUPS = "/variable-groups";

// Variables Paths
export const PATH_VARIABLES = "/variables";
export const PATH_ONTOLOGY = "/ontology";
export const PATH_OTHERS = "/others";

// Experience
export const ROUTING_CREATION = "creation";
export const PATH_EXPERIMENTS = "/experiments";
export const PATH_GENERAL = "/general";
export const PARAM_TRIAL_ID = "/:trialId";
export const PATH_TRIALS = "/trials";
export const PATH_METS = "/mets";
export const PATH_TRIALS_CREATION = `${PATH_EXPERIMENTS}${PATH_TRIALS}/${ROUTING_CREATION}`;
export const PATH_MET_CREATION = `${PATH_EXPERIMENTS}${PATH_METS}/${ROUTING_CREATION}`;
export const PARAM_MET_ID = "/:metId";
export const PATH_PLANNING = "/planning";
export const PATH_GANTT = "/gantt";
export const PATH_OPERATION = "/operation";
export const PATH_DATA = "/data";
export const PATH_SYNTHESIS = "/synthesis";
export const PATH_ANALYSIS = "/analysis";
export const PATH_VALIDATION = "/validation";
export const PATH_OVERVIEW = "/overview";
export const PATH_OBSERVATION_ROUND = "/observation-round";
export const PARAM_OBSERVATION_ROUND_ID = "/:obsRoundId";
export const PATH_NOTEBOOKS = "/notebooks";
export const PATH_LOCATION = "/location";

// Germplasm
export const PATH_GERMPLASM = "/germplasm";

export const PATH_GENOTYPE = "/genotype";
export const ROUTING_GENOTYPE = "genotype";

export const PATH_LOTS = "/lots";
export const ROUTING_LOTS = "lots";

// Users
export const PATH_USERS = "/users";
export const PARAM_USER_ID = "/:userId";

// Growing Area
export const PATH_GROWING_AREA = "/growing-area";
export const PARAM_GROWING_AREA_ID = "/:growingAreaId";

// Picture
export const PATH_PICTURE = "/picture";

// Dashboard
export const PATH_DASHBOARD = "/dashboard";
export const PATH_DASHBOARD_TEAM = "/team";
export const PATH_DASHBOARD_TEAM_MANAGER = "/managers";
export const PATH_DASHBOARD_TEAM_DEV = "/developers";

export const PATH_DASHBOARD_VARIETY = "/variety";
export const PATH_DASHBOARD_ORGANIZATION = "/organization";
export const PATH_DASHBOARD_PLANNING = "/planning";

export function removeStartingSlash(path: string) {
	return path.replace(/^(\/)+/, "");
}

// UTILS
export const PATH_LANDING_PAGE = PATH_DASHBOARD;
