import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { formatString } from "common";
import { INotebookSerialized } from "shared-type";

import { DIC, EDIC_KEY } from "../../dictionary";
import { useRequest } from "../../request-provider";
import { defaultApiError } from "../utils/errors";

export const apiNotebook = createApi({
	reducerPath: "api-notebook",
	baseQuery: () => ({ data: undefined }),
	keepUnusedDataFor: 30, // global configuration for the api (in second)
	refetchOnMountOrArgChange: 30, // global configuration for the api
	tagTypes: ["Update", "Delete", "Create"],

	endpoints: (build) => ({
		getNotebooksByObsRoundId: build.query<
			INotebookSerialized[] | undefined,
			string | undefined
		>({
			queryFn: async (id) => {
				try {
					if (!id) {
						return { data: undefined };
					}

					const result =
						await useRequest().notebook.getNotebooksByObsRoundId(
							id,
						);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.FETCH).toLowerCase(),
							DIC(EDIC_KEY.NOTEBOOKS).toLowerCase(),
						),
					);
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),
		getNotebooksByTrialId: build.query<
			INotebookSerialized[] | undefined,
			string | undefined
		>({
			queryFn: async (id, api) => {
				try {
					if (id === undefined) {
						api.abort();
						return { data: [] };
					}

					const result = await useRequest().notebook.getNotebooks({
						trialId: id,
					});
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.FETCH).toLowerCase(),
							DIC(EDIC_KEY.NOTEBOOKS).toLowerCase(),
						),
					);
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),
	}),
});
