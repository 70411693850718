import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { EntitySerializer, formatString } from "common";
import {
	ICreateMarketSegment,
	IFilter,
	IMarketSegment,
	IPatchMarketSegment,
	ISort,
} from "shared-type";

import { DIC, EDIC_KEY } from "../../dictionary";
import { useRequest } from "../../request-provider";
import { defaultApiError, ReduxApiError } from "../utils/errors";

export const apiMarketSegment = createApi({
	reducerPath: "api-market-segment",
	baseQuery: () => ({ data: undefined }),
	keepUnusedDataFor: 30, // global configuration for the api (in second)
	refetchOnMountOrArgChange: 30, // global configuration for the api
	tagTypes: ["Update", "Delete", "Create"],
	endpoints: (build) => ({
		// I want a blank line here but eslint won't let me without this comment :c

		getMarketSegments: build.query<
			IMarketSegment[],
			{ filter?: IFilter; sort?: ISort }
		>({
			queryFn: async ({ filter, sort }) => {
				try {
					const result =
						await useRequest().marketSegment.getMarketSegments(
							filter,
							sort,
						);
					return {
						data: result.data.map((data) =>
							EntitySerializer.deserialize<IMarketSegment>(data),
						),
					};
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.FETCH).toLowerCase(),
							DIC(EDIC_KEY.MARKET_SEGMENTS).toLowerCase(),
						),
					);
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),

		getMarketSegmentById: build.query<
			IMarketSegment | undefined,
			string | undefined
		>({
			queryFn: async (id) => {
				try {
					if (!id) {
						return { data: undefined };
					}
					const result =
						await useRequest().marketSegment.getMarketSegmentById(
							id,
						);
					return {
						data: EntitySerializer.deserialize<IMarketSegment>(
							result.data,
						),
					};
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.FETCH).toLowerCase(),
							DIC(EDIC_KEY.MARKET_SEGMENTS).toLowerCase(),
						),
					);
				}
			},
			providesTags: (result, error, id) => {
				return [
					{ type: "Delete", id: id },
					{ type: "Update", id: id },
					{ type: "Create", id: id },
				];
			},
		}),

		getMarketSegmentByIds: build.query<
			IMarketSegment[] | undefined,
			string[]
		>({
			queryFn: async (ids) => {
				try {
					if (ids.length === 0) {
						return { data: [] };
					}

					const result =
						await useRequest().marketSegment.getMarketSegmentByIds(
							ids,
						);
					return {
						data: result.data.map((data) =>
							EntitySerializer.deserialize<IMarketSegment>(data),
						),
					};
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.FETCH).toLowerCase(),
							DIC(EDIC_KEY.MARKET_SEGMENTS).toLowerCase(),
						),
					);
				}
			},
			providesTags: (result, error, id) => {
				return [
					{ type: "Delete", id: "ALL" },
					{ type: "Update", id: "ALL" },
					{ type: "Create", id: "ALL" },
				];
			},
		}),

		getMarketSegmentUsagesById: build.query<
			string[] | undefined,
			string | undefined
		>({
			queryFn: async (id) => {
				try {
					if (!id) {
						return { data: undefined };
					}
					const result =
						await useRequest().marketSegment.getMarketSegmentUsagesById(
							id,
						);
					return { data: result.data };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch market segment usages for id : " + id,
					);
				}
			},
		}),

		createMarketSegment: build.mutation<
			IMarketSegment,
			ICreateMarketSegment
		>({
			queryFn: async (newMarketSegment) => {
				let id: string;

				try {
					id = await useRequest()
						.marketSegment.createMarketSegment(newMarketSegment)
						.then((resp) => resp.data);
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.CREATE).toLowerCase(),
							DIC(EDIC_KEY.MARKET_SEGMENT).toLowerCase(),
						),
					);
				}
				try {
					const createdMarketSegment =
						await useRequest().marketSegment.getMarketSegmentById(
							id,
						);
					return {
						data: EntitySerializer.deserialize<IMarketSegment>(
							createdMarketSegment.data,
						),
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to get the market segment with id : " + id,
					);
				}
			},
			invalidatesTags: (result, error) => {
				if (error) {
					return [];
				}
				return [
					{ type: "Create", id: result?._id },
					{ type: "Create", id: "ALL" },
				];
			},
		}),

		updateMarketSegmentById: build.mutation<
			void,
			{ id: string; update: IPatchMarketSegment }
		>({
			queryFn: async (arg) => {
				try {
					const result = await useRequest()
						.marketSegment.updateMarketSegmentById(
							arg.id,
							arg.update,
						)
						.then((resp) => resp.data);
					return { data: result };
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.UPDATE).toLowerCase(),
							DIC(EDIC_KEY.MARKET_SEGMENT).toLowerCase(),
						),
					);
				}
			},
			invalidatesTags: (result, error, arg) => {
				if (error) {
					return [];
				}
				return [
					{ type: "Update", id: arg.id },
					{ type: "Update", id: "ALL" },
				];
			},
		}),

		deleteMarketSegmentById: build.mutation<void, string | undefined>({
			queryFn: async (id) => {
				if (!id) {
					const error: { error: ReduxApiError } = {
						error: {
							message:
								"Id is required to delete a market segment.",
						},
					};
					return error;
				}

				try {
					const result =
						await useRequest().marketSegment.deleteMarketSegmentById(
							id,
						);
					return { data: result.data };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to delete market segment with id : " + id,
					);
				}
			},
			invalidatesTags: (result, error, id) => {
				if (error) {
					return [];
				}
				return [
					{ type: "Delete", id: id },
					{ type: "Delete", id: "ALL" },
				];
			},
		}),
	}),
});
