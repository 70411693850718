import { Tooltip } from "@mui/material";
import React from "react";

import "./DorianeStepper.scss";

export interface CustomStep {
	description: string;
	noLinkToPrevious?: boolean;
	name?: string;
	completed: boolean;
	index: number;
	disabled?: boolean;
	isError?: boolean;
	errorMessage?: string;
}

interface DorianeStepperProps<TStep extends CustomStep> {
	currentStepIndex: number;
	steps: TStep[];
	onStepClick: (step: TStep) => void;
}

export function StepComponent<TStep extends CustomStep>(
	props: DorianeStepperProps<TStep> & { step: TStep; index: number },
) {
	const isFirst = props.index === 0;

	let classModifier: string;

	if (props.currentStepIndex === props.step.index) {
		classModifier = " StepperLevel-current";
	} else if (props.step.completed) {
		classModifier = " StepperLevel-completed";
	} else if (props.step.disabled) {
		classModifier = " StepperLevel-disabled";
	} else {
		classModifier = "";
	}

	const separator = props.step.noLinkToPrevious ? (
		<div
			key={props.step.index + "-space"}
			className={"StepperSpace" + classModifier}
		/>
	) : (
		<div
			key={props.step.index + "-link"}
			className={"StepperLink" + classModifier}
		/>
	);
	const level = (
		<div
			key={props.step.index + "-step"}
			className={"StepperLevel-container" + classModifier}
			onClick={() => {
				if (!props.step.disabled) {
					props.onStepClick(props.step);
				}
			}}
		>
			<div className={"StepperLevel-body center" + classModifier}>
				<div>{props.step.name ?? props.index + 1}</div>
			</div>
			<div className={"StepperLevel-title" + classModifier}>
				{props.step.description}
			</div>
			{props.step.isError ? (
				<Tooltip title={props.step.errorMessage}>
					<div className={"StepperLevel-error" + classModifier}>
						!
					</div>
				</Tooltip>
			) : null}
		</div>
	);

	if (isFirst) {
		return level;
	}
	return (
		<>
			{separator}
			{level}
		</>
	);
}

export function DorianeStepper<TStep extends CustomStep>(
	props: DorianeStepperProps<TStep>,
) {
	return (
		<div className="Stepper-container center">
			{props.steps.map((step, index) => (
				<StepComponent
					key={index}
					index={index}
					step={step}
					{...props}
				/>
			))}
		</div>
	);
}
