import React from "react";

import {
	IScopedVariable,
	ITemplateObsCoreSerialized,
	IVariableCore,
} from "shared-type";

import { templateObsVarGroupColumns } from "../../../../base-components/data-grid/doriane-data-grid/default-grid-columns/TemplateObsVarGroupColumns";
import DorianeDataGrid from "../../../../base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import { templateVarGridMethods } from "../../TemplateObsEditionPage.utils";

export interface ITemplateObsVarGroupGridProps {
	templateObservation: ITemplateObsCoreSerialized;
	variableOntology: IVariableCore[];
	variables: IScopedVariable[];
	checkDisabled: boolean;
	onVariableCheck?: (row: IVariableCore, checked: boolean) => void;
}

export default function TemplateObsVarGroupGrid(
	props: ITemplateObsVarGroupGridProps,
) {
	const {
		variableInTemplate,
		isTrialCheck,
		isMaterialCheck,
	}: {
		variableInTemplate: IVariableCore[];
		isTrialCheck: (row: IVariableCore) => boolean;
		isMaterialCheck: (row: IVariableCore) => boolean;
	} = templateVarGridMethods(
		props.templateObservation,
		props.variableOntology,
	);

	const isVariableCheck = (row: IVariableCore) => {
		const variable = props.variables.find((variable) => {
			return (
				variable.scope === row.scope && variable.variableId === row._id
			);
		});

		return variable !== undefined;
	};

	return (
		<div className="flex-column take-remaining-space">
			<DorianeDataGrid<IVariableCore>
				rows={variableInTemplate}
				getRowId={(row) => row._id}
				columns={templateObsVarGroupColumns({
					isTrialCheck: isTrialCheck,
					isMaterialCheck: isMaterialCheck,
					isVariableCheck: isVariableCheck,
					onVariableCheck: props.onVariableCheck,
					checkDisabled: props.checkDisabled,
				})}
			/>
		</div>
	);
}
