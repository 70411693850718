import React from "react";

import { IUser } from "shared-type";

import { DIC, EDIC_KEY } from "../../../../../dictionary";
import {
	IDorianeFilter,
	IDorianeObjectFilter,
} from "../../../../../interfaces/filters/doriane-filter-interface";
import { IUserWithTaskSource } from "../../../../task/select-user/types";
import { CheckBoxColumn } from "../columns/CheckBoxColumn";
import { IconColumn } from "../columns/IconColumn";
import { IColumn } from "../doriane-data-grid/DorianeDataGrid";
import HeaderWithFilter from "../doriane-data-grid/header/HeaderWithFilter";

export const userColumns = (
	filter: IDorianeObjectFilter,
	onFilterChange: (newFilter: IDorianeFilter) => void,
): IColumn<IUser>[] => [
	{
		id: "firstName",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "firstName",
					type: "string",
					value: filter.firstName?.value,
				}}
				label={DIC(EDIC_KEY.FIRST_NAME)}
				onFilterChange={onFilterChange}
			/>
		),
		getCellRenderer: (user) => user.firstName ?? "",
	},
	{
		id: "lastName",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "lastName",
					type: "string",
					value: filter.lastName?.value,
				}}
				label={DIC(EDIC_KEY.LAST_NAME)}
				onFilterChange={onFilterChange}
			/>
		),
		getCellRenderer: (user) => user.lastName ?? "",
	},
	{
		id: "role",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "role",
					type: "string",
					value: filter.role?.value,
				}}
				label={DIC(EDIC_KEY.ROLE)}
				onFilterChange={onFilterChange}
			/>
		),
		getCellRenderer: (user) => user.role?.toString() ?? "",
	},
	{
		id: "mail",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "mail",
					type: "string",
					value: filter.mail?.value,
				}}
				label={DIC(EDIC_KEY.EMAIL)}
				onFilterChange={onFilterChange}
			/>
		),
		getCellRenderer: (user) => user.mail ?? "",
	},
];

export function userColumnsForSelection(
	filter: {
		filter: IDorianeObjectFilter;
		onFilterChange: (newFilter: IDorianeFilter) => void;
	},
	rowCallback: {
		isCheck: (row: IUser) => boolean;
		onCheck: (row: IUser, check: boolean) => void;
	},
): IColumn<IUser>[] {
	const checkBoxColumn = CheckBoxColumn({
		isCheck: rowCallback.isCheck,
		onCheck: rowCallback.onCheck,
	});
	return [
		checkBoxColumn,
		...userColumns(filter.filter, filter.onFilterChange),
	];
}

export function userColumnsForTaskSelection(
	filter: {
		filter: IDorianeObjectFilter;
		onFilterChange: (newFilter: IDorianeFilter) => void;
	},
	rowCallback: {
		isCheck: (row: IUserWithTaskSource) => boolean;
		onCheck: (row: IUserWithTaskSource, check: boolean) => void;
	},
	userSrcIcon: {
		iconSrc: (row: IUserWithTaskSource) => string;
		iconTitle: (row: IUserWithTaskSource) => string;
	},
	onCheckMultiple?: any,
): IColumn<IUserWithTaskSource>[] {
	const checkBoxColumn = CheckBoxColumn({
		isCheck: rowCallback.isCheck,
		onCheck: rowCallback.onCheck,
		headerCheckAllAction: onCheckMultiple,
	});
	const sourceColumns = IconColumn(
		userSrcIcon.iconSrc,
		userSrcIcon.iconTitle,
	);
	return [
		checkBoxColumn,
		sourceColumns,
		...userColumns(filter.filter, filter.onFilterChange),
	];
}
