import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useMemo, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { formatString } from "common";
import { ETaskStatus, ETaskType, IObservationRound } from "shared-type";

import DorianeButton from "App/components/base-components/button/DorianeButton";
import SaveButton from "App/components/base-components/button/SaveButton";
import TopMenu from "App/components/base-components/menu/top-menu/TopMenu";
import { obsRoundValidator } from "App/components/form/task/observation-round";
import ObservationRoundForm, {
	IObservationRoundForm,
} from "App/components/form/task/observation-round/ObservationRoundForm";
import { DIC, EDIC_KEY } from "App/dictionary";
import useAppSnackbar from "App/hooks/useAppSnackbar";
import { apiObsRound } from "App/redux/observation-round/obs-round.api";
import { ReduxApiError } from "App/redux/utils/errors";
import {
	obsRoundFormToObject,
	obsRoundObjectToForm,
} from "../../../../../../../../../components/form/task/observation-round/transformer";
import { obsRoundEditionActions } from "../../../../../../../../../redux/observation-round-edition/obs-round-edition.slice";
import { apiUserCustomHook } from "../../../../../../../../../redux/user/user.api";
import { AppDispatch } from "../../../../../../../../../store";
import { useObsRoundEdition } from "../ObsRoundEditionPage.utils";

import "./ObsRoundEditionGeneralPage.scss";

export default function ObsRoundEditionGeneralPage() {
	const dispatch = useDispatch<AppDispatch>();
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();
	const { metId } = useParams();

	const {
		currentObsRound,
		setSelectUserOpen,
		setCommentOpen,
		setSelectVarGroupOpen,
		setCreateVarGroupOpen,
		setConsultVarGroupOpen,
		setIsSendPopupOpen,
		isEditing,
		isSendNotebookDisplayed,
	} = useObsRoundEdition();

	const [updateObsRoundById] = apiObsRound.useUpdateObsRoundByIdMutation();
	const { data: obsRoundUsers } = apiUserCustomHook.useGetUsersByIds(
		currentObsRound?.team || [],
	);

	const [isFormInitialized, setIsFormInitialized] = useState(false);

	const currentObsRoundForm = useMemo(
		() => obsRoundObjectToForm(currentObsRound),
		[currentObsRound],
	);
	const isMET = useMemo(() => metId !== undefined, [metId]);
	const formMode = useMemo(() => {
		if (!currentObsRound?.status) {
			return "creation";
		}
		if (currentObsRound.status === ETaskStatus.DRAFT) {
			return "unused";
		}
		return "used";
	}, [currentObsRound]);

	const form = useForm<IObservationRoundForm>({
		resolver: yupResolver(obsRoundValidator as any),
	});
	const formState = useFormState({ control: form.control });
	const { handleSubmit } = form;
	const { isSubmitting } = formState;

	useEffect(() => {
		if (currentObsRound) {
			form.reset(currentObsRoundForm);
			setIsFormInitialized(true);
		}
	}, [currentObsRound, currentObsRoundForm, form]);

	useEffect(() => {
		const formUpdated = isFormInitialized && formState.isDirty;
		if (formUpdated && !isEditing) {
			dispatch(obsRoundEditionActions.setEditingState());
		}
	}, [
		currentObsRoundForm,
		dispatch,
		formState,
		isEditing,
		isFormInitialized,
	]);

	useEffect(() => {
		return () => {
			setSelectUserOpen(false);
			setSelectVarGroupOpen(false);
			setCreateVarGroupOpen(false);
			setConsultVarGroupOpen(false);
			setCommentOpen(false);
			setIsSendPopupOpen(false);
			dispatch(obsRoundEditionActions.resetEditingState());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveObsRound = async (data: IObservationRoundForm) => {
		if (currentObsRound === undefined) return;
		await updateObsRoundById({
			id: currentObsRound._id,
			update: {
				...(obsRoundFormToObject(data) as IObservationRound),
				type: ETaskType.OBSERVATION_ROUND,
			},
		})
			.unwrap()
			.then(() => {
				setIsFormInitialized(false);
				dispatch(obsRoundEditionActions.resetEditingState());
				enqueueSnackbarSuccess(
					formatString(
						DIC(EDIC_KEY.ENTITY_UPDATED),
						DIC(EDIC_KEY.OBSERVATION_ROUND),
					),
				);
			})
			.catch((err: ReduxApiError) => {
				console.warn(err);
				enqueueSnackbarError(
					formatString(
						DIC(EDIC_KEY.CANT_ACTION_ENTITY),
						DIC(EDIC_KEY.UPDATE).toLowerCase(),
						DIC(EDIC_KEY.OBSERVATION_ROUND).toLowerCase(),
					),
				);
			});
	};

	return (
		<div className="ObsRoundEditionGeneralPage-body full-parent-size">
			<div className="ObsRoundEditionGeneralPage-stickyBar">
				<TopMenu
					saveButton={{
						customDisplay: isSendNotebookDisplayed ? (
							<DorianeButton
								className="ObsRoundEditionPage-btn-send"
								dorianeStyle="primary"
								onClick={() =>
									dispatch(
										obsRoundEditionActions.setIsOpenSendPopupNb(
											true,
										),
									)
								}
							>
								{DIC(EDIC_KEY.SEND)}
							</DorianeButton>
						) : (
							<SaveButton
								onSave={() =>
									handleSubmit(
										(data) => saveObsRound(data as any),
										(error) => console.error(error),
									)()
								}
								isLoading={isSubmitting}
								disabled={!isEditing}
							/>
						),
					}}
				/>
			</div>
			<div className="ObsRoundEditionGeneralPage-form">
				{currentObsRound && (
					<ObservationRoundForm
						mode={formMode}
						isTemplate={isMET}
						form={form}
						obsRoundUsers={obsRoundUsers}
						onSelectUserClick={() => setSelectUserOpen(true)}
						onSelectVarGroupClick={() => {
							setSelectVarGroupOpen(true);
							setConsultVarGroupOpen(false);
						}}
						onVarGroupClick={() => {
							setConsultVarGroupOpen(true);
							setSelectVarGroupOpen(false);
							setCreateVarGroupOpen(false);
						}}
					/>
				)}
			</div>
		</div>
	);
}
