import React from "react";

import { IMarketSegment } from "shared-type";

import MarketSegmentBanner, {
	IMarketSegmentBannerProps,
} from "./MarketSegmentBanner";

import "./MarketSegmentCard.scss";

export interface IMarketSegmentCardProps {
	marketSegment: IMarketSegment;
	onClick?: () => void;
	disabledClick?: boolean;
	isSelected?: boolean;
}
export default function MarketSegmentCard(props: IMarketSegmentCardProps) {
	const enhancedProps: IMarketSegmentBannerProps = {
		...props,
		className: "MarketSegmentCard",
	};

	return <MarketSegmentBanner {...enhancedProps} />;
}
