import { Dayjs } from "dayjs";
import React, { useMemo } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";

import { ETaskStatus, IUser } from "shared-type";

import { FormMode } from "../../../../const";
import { DIC, EDIC_KEY } from "../../../../dictionary";
import DorianeButton from "../../../base-components/button/DorianeButton";
import {
	EFormInputType,
	InputInformation,
} from "../../../base-components/dynamic-form";
import DynamicForm from "../../../base-components/dynamic-form/DynamicForm";
import FormSection from "../../../base-components/dynamic-form/form-section/FormSection";
import FormSectionTitle from "../../../base-components/dynamic-form/form-section/FormSectionTitle";
import UserCard from "../../../users/user-card/UserCard";

import "./OperationForm.scss";

export interface IOperationForm {
	name: string;
	identifier: string;
	description?: string;
	label?: string;
	status: ETaskStatus;

	startDate: Dayjs;
	endDate: Dayjs;
	stage?: string;

	team: string[];
}

interface OperationFormProps {
	isTemplate: boolean;
	mode: FormMode;
	form: UseFormReturn<IOperationForm>;
	operationUsers?: IUser[];
	onSelectUserClick?: () => void;
}

export default function OperationForm(props: OperationFormProps) {
	const form = props.form;
	const startDate = useWatch({ control: form.control, name: "startDate" });

	const identificationPart = useMemo(() => {
		const identificationData: InputInformation[] = [
			{
				inputType: EFormInputType.STRING,
				id: "name",
				label: DIC(EDIC_KEY.NAME),
				takeAllRow: true,
				required: true,
				disabled: props.mode === "used",
			},
			{
				inputType: EFormInputType.STRING,
				id: "identifier",
				label: DIC(EDIC_KEY.ID),
				required: true,
				disabled: props.mode !== "creation",
			},
			{
				inputType: EFormInputType.STRING,
				id: "label",
				label: DIC(EDIC_KEY.LABEL),
				disabled: props.mode === "used",
			},
			{
				inputType: EFormInputType.STRING,
				id: "description",
				label: DIC(EDIC_KEY.DESCRIPTION),
				multiline: true,
				takeAllRow: true,
				disabled: props.mode === "used",
			},
		];
		if (props.mode !== "creation" && !props.isTemplate) {
			identificationData.splice(3, 0, {
				inputType: EFormInputType.COMBOBOX,
				id: "status",
				label: DIC(EDIC_KEY.STATUS),
				disabled: props.mode === "used",
				required: true,
				comboboxValues: Object.values(ETaskStatus),
				translateMethod: (item) => item,
			});
		}
		return identificationData;
	}, [props.isTemplate, props.mode]);

	return (
		<div className="OperationForm-container">
			<FormSection title={DIC(EDIC_KEY.IDENTIFICATION)}>
				<DynamicForm
					control={form.control}
					formInfos={identificationPart}
				/>
			</FormSection>
			<FormSection title={DIC(EDIC_KEY.PERIOD)}>
				<DynamicForm
					control={form.control}
					formInfos={[
						{
							inputType: EFormInputType.DATE,
							id: "startDate",
							label: DIC(EDIC_KEY.FROM),
							required: true,
							disabled: props.mode === "used",
						},
						{
							inputType: EFormInputType.DATE,
							id: "endDate",
							label: DIC(EDIC_KEY.TO),
							required: true,
							disabled: props.mode === "used",
							minDate: startDate,
						},
						{
							inputType: EFormInputType.STRING,
							id: "stage",
							label: DIC(EDIC_KEY.STAGE),
							disabled: props.mode === "used",
						},
					]}
				/>
			</FormSection>
			{!props.isTemplate && (
				<FormSection>
					<div className="OperationForm-title-container">
						<FormSectionTitle title={DIC(EDIC_KEY.TEAM)} />
						{props.onSelectUserClick && (
							<DorianeButton
								dorianeStyle="tertiary"
								onClick={() => props.onSelectUserClick?.()}
							>
								{DIC(EDIC_KEY.EDIT_SELECTION)}
							</DorianeButton>
						)}
					</div>
					<h2>{DIC(EDIC_KEY.TEAM)}</h2>
					<div className="OperationForm-user-list flex-column">
						{(props.operationUsers ?? []).map((user) => (
							<UserCard user={user} key={user.id} disabledClick />
						))}
					</div>
				</FormSection>
			)}
		</div>
	);
}
