import React, { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { formatString } from "common";
import { IVariableCore, IVariableGroupSerialized } from "shared-type";

import { useGetOntologyVarRow } from "App/components/base-components/data-grid/doriane-data-grid/default-grid-columns/VariableColumns";
import { PATH_VARIABLES } from "App/routes";
import { EmptyState } from "stories/base-components/EmptyState/EmptyState";
import { HeaderTitleSearchButton } from "stories/base-components/Layout/HeaderTitleSearchButton/HeaderTitleSearchButton";
import varGroupNoData from "../../../../../assets/images/no_variable_group.svg";
import VariableGroupCard from "../../../../../stories/base-components/Card/mostUsed/VariableGroupCard";
import { DIC, EDIC_KEY } from "../../../../dictionary";
import {
	getVariableInTemplate,
	useTemplateObsEdition,
} from "../../observation/TemplateObsEditionPage.utils";

import "./VarGroupList.scss";

interface IVarGroupListProps {
	onVarGroupCreateClick: () => void;
	remoteVariableGroups: IVariableGroupSerialized[];
	handleVarGroupClick: (id: string) => void;
	selectedGroupIds?: string[];
	mode?: "configuration" | "selectVariableGroup";
}

export default function VarGroupList(props: IVarGroupListProps) {
	const [filter, setFilter] = useState("");

	const filteredVarGroups = useMemo(
		() =>
			props.remoteVariableGroups.filter((elt) => {
				// Filter to review to allow fuzzy search everywhere.
				return elt.name.toLowerCase().startsWith(filter.toLowerCase());
			}),
		[filter, props.remoteVariableGroups],
	);

	const nav = useNavigate();
	const location = useLocation();

	const { currentTemplateObs } = useTemplateObsEdition();
	const { data: ontologyVariables } = useGetOntologyVarRow();
	const variableInTemplate: IVariableCore[] = getVariableInTemplate(
		currentTemplateObs,
		ontologyVariables,
	);

	return (
		<div className="VarGroupList-card-container">
			{props.mode === "configuration" &&
			props.remoteVariableGroups.length === 0 &&
			variableInTemplate.length === 0 ? (
				<EmptyState
					beforeActionMessage={DIC(EDIC_KEY.ADD_VARIABLES_IN_THE)}
					actionMessage={DIC(EDIC_KEY.VARIABLES_TAB)}
					afterActionMessage={
						DIC(EDIC_KEY.TO_CREATE_VARIABLE_GROUPS) + "."
					}
					actionOnClick={() =>
						nav(
							location.pathname
								.split("/")
								.slice(0, -1)
								.join("/") + PATH_VARIABLES,
						)
					}
					noDataImage={varGroupNoData}
				/>
			) : (
				<>
					<HeaderTitleSearchButton
						subtitle={DIC(EDIC_KEY.VARIABLE_GROUP)}
						list={filteredVarGroups || []}
						searchPlaceholder={DIC(EDIC_KEY.VARIABLE_GROUP)}
						setSearch={setFilter}
						searchValue={filter}
						btnText={`${formatString(
							DIC(EDIC_KEY.ACTION_ENTITY),
							DIC(EDIC_KEY.NEW),
							DIC(EDIC_KEY.VARIABLE_GROUP).toLowerCase(),
						)} +`}
						btnCreateText={
							DIC(EDIC_KEY.CREATE_VARIABLE_GROUP) + " +"
						}
						btnOnClick={() => props.onVarGroupCreateClick()}
						btnType="secondary"
					/>

					<div className="VarGroupList-card-list flex-column">
						{filteredVarGroups.map((elt) => (
							<VariableGroupCard
								key={elt._id}
								variableGroup={elt}
								onClick={() =>
									props.handleVarGroupClick(elt._id)
								}
							/>
						))}
					</div>
				</>
			)}
		</div>
	);
}
