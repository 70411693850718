import { Dayjs } from "dayjs";
import { EVariableCoreType, VariableConstraint } from "shared-type";

export interface EditingMinMaxDate {
	minDate?: Date | Dayjs;
	maxDate?: Date | Dayjs;
}
export interface EditingMinMax {
	min?: string;
	max?: string;
}

export interface EditingDateConstraint extends EditingMinMaxDate {
	type: EVariableCoreType.DATE;
}
export interface EditingDatetimeConstraint extends EditingMinMaxDate {
	type: EVariableCoreType.DATETIME;
}
export interface EditingStringConstraint extends EditingMinMax {
	type: EVariableCoreType.STRING;
}
export interface EditingIntConstraint extends EditingMinMax {
	type: EVariableCoreType.INT;
}
export interface EditingDecConstraint extends EditingMinMax {
	type: EVariableCoreType.DEC;
	nbDigits?: number;
}

export type EditingVariableConstraint =
	| EditingStringConstraint
	| EditingDateConstraint
	| EditingDatetimeConstraint
	| EditingIntConstraint
	| EditingDecConstraint;

export class ConstraintConverter {
	static convertFromEdition(
		constraint: Partial<EditingVariableConstraint>,
	): VariableConstraint {
		const result: any = { ...constraint };
		if (
			constraint.type === EVariableCoreType.STRING ||
			constraint.type === EVariableCoreType.INT ||
			constraint.type === EVariableCoreType.DEC
		) {
			if (constraint.min) {
				result.min = Number(constraint.min);
			}
			if (constraint.max) {
				result.max = Number(constraint.max);
			}
		}
		return result;
	}

	static convertToEdition(
		constraint: Partial<VariableConstraint>,
	): EditingVariableConstraint {
		const result: any = { ...constraint };
		if (
			constraint.type === EVariableCoreType.STRING ||
			constraint.type === EVariableCoreType.INT ||
			constraint.type === EVariableCoreType.DEC
		) {
			if (constraint.min) {
				result.min = constraint.min.toString();
			}
			if (constraint.max) {
				result.max = constraint.max.toString();
			}
		}
		return result;
	}
}
