import { createApi } from "@reduxjs/toolkit/query/react";
import { useMemo } from "react";

import {
	ICreateGrowingArea,
	IFilter,
	IGrowingArea,
	IGrowingAreaSerialized,
	IPatchUnusedGrowingArea,
	IPatchUsedGrowingArea,
	ISort,
	PaginateResponse,
} from "shared-type";
import { EntitySerializer } from "common";

import { useRequest } from "../../request-provider";
import { defaultApiError, ReduxApiError } from "../utils/errors";

export const apiGrowingArea = createApi({
	reducerPath: "api-growing-area",
	baseQuery: () => ({ data: undefined }),

	// global cache configuration for the api (in second)
	keepUnusedDataFor: 30,
	refetchOnMountOrArgChange: 30,

	// update tag
	tagTypes: ["Update", "Delete", "Create"],

	// api definition
	endpoints: (build) => ({
		getGrowingAreas: build.query<
			IGrowingAreaSerialized[],
			{ filter?: IFilter; sort?: ISort }
		>({
			queryFn: async ({ filter, sort }) => {
				try {
					const result =
						await useRequest().growingArea.getGrowingAreas(
							filter,
							sort,
						);
					return {
						data: result.data.map(
							(elt) =>
								EntitySerializer.serialize<IGrowingArea>(elt), // because lib request deserialize and we need serialized version
						),
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch growing areas",
					);
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),
		getPaginatedGrowingAreas: build.query<
			PaginateResponse<IGrowingAreaSerialized>,
			{ filter?: IFilter; sort?: ISort; page?: number; pageSize?: number }
		>({
			queryFn: async (args) => {
				try {
					const result =
						await useRequest().growingArea.getPaginatedGrowingAreas(
							args,
						);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch paginated growing areas",
					);
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),
		getGrowingAreaById: build.query<
			IGrowingAreaSerialized | undefined,
			string | undefined
		>({
			queryFn: async (id) => {
				try {
					if (!id) {
						return { data: undefined };
					}
					const result =
						await useRequest().growingArea.getGrowingAreaById(id);
					return {
						data: EntitySerializer.serialize<IGrowingArea>(
							result.data,
						),
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch growing area with id : " + id,
					);
				}
			},
			providesTags: (result, error, id) => {
				return [
					{ type: "Delete", id: id },
					{ type: "Update", id: id },
					{ type: "Create", id: id },
				];
			},
		}),

		getGrowingAreaByIds: build.query<
			IGrowingAreaSerialized[] | undefined,
			string[] | undefined
		>({
			queryFn: async (ids) => {
				try {
					if (!ids) {
						return { data: undefined };
					}
					const result =
						await useRequest().growingArea.getGrowingAreaByIds(ids);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch growing area with id : " + ids,
					);
				}
			},
			providesTags: () => {
				return [
					{ type: "Delete", id: "ALL" },
					{ type: "Update", id: "ALL" },
					{ type: "Create", id: "ALL" },
				];
			},
		}),

		getGrowingAreaUsageInfoById: build.query<
			string[] | undefined,
			string | undefined
		>({
			queryFn: async (id) => {
				try {
					if (!id) {
						return { data: undefined };
					}
					const result =
						await useRequest().growingArea.getGrowingAreaUsageInfoById(
							id,
						);
					return { data: result.data };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch growing area usages for id : " + id,
					);
				}
			},
		}),

		deleteGrowingAreaById: build.mutation<void, string | undefined>({
			queryFn: async (id) => {
				if (!id) {
					const error: { error: ReduxApiError } = {
						error: {
							message: "Id is required for delete growing area",
						},
					};
					return error;
				}

				try {
					const result =
						await useRequest().growingArea.deleteGrowingAreaById(
							id,
						);
					return { data: result.data };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to delete growing area with id : " + id,
					);
				}
			},
			invalidatesTags: (result, error, arg) => {
				if (error) {
					return [];
				}
				return [
					{ type: "Delete", id: arg },
					{ type: "Delete", id: "ALL" },
				];
			},
		}),

		createGrowingArea: build.mutation<
			IGrowingAreaSerialized,
			ICreateGrowingArea
		>({
			queryFn: async (newGrowingArea) => {
				let id: string;

				try {
					id = await useRequest()
						.growingArea.createGrowingArea(newGrowingArea)
						.then((resp) => resp.data);
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to create the growing area",
					);
				}
				try {
					const createdGrowingArea: IGrowingArea = await useRequest()
						.growingArea.getGrowingAreaById(id)
						.then((resp) => resp.data);

					const resultSerialized =
						EntitySerializer.serialize<IGrowingArea>(
							createdGrowingArea,
						);
					return { data: resultSerialized };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to get the growing area with id : " + id,
					);
				}
			},
			invalidatesTags: (result, error) => {
				if (error) {
					return [];
				}
				return [
					{ type: "Create", id: result?._id },
					{ type: "Create", id: "ALL" },
				];
			},
		}),

		updateUsedGrowingArea: build.mutation<
			void,
			{ id: string; update: IPatchUsedGrowingArea }
		>({
			queryFn: async (arg) => {
				if (!arg.id) {
					const error: { error: ReduxApiError } = {
						error: {
							message:
								"Id is required for updated a growing area",
						},
					};
					return error;
				}
				try {
					const result = await useRequest()
						.growingArea.updateUsedGrowingArea(arg.id, arg.update)
						.then((resp) => resp.data);
					return { data: result };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to update growing area",
					);
				}
			},
			invalidatesTags: (result, error, arg) => {
				if (error) {
					return [];
				}
				return [
					{ type: "Update", id: arg.id },
					{ type: "Update", id: "ALL" },
				];
			},
		}),

		updateUnusedGrowingArea: build.mutation<
			void,
			{ id: string; update: IPatchUnusedGrowingArea }
		>({
			queryFn: async (arg) => {
				if (!arg.id) {
					const error: { error: ReduxApiError } = {
						error: {
							message:
								"Id is required for updated a growing area",
						},
					};
					return error;
				}
				try {
					const result = await useRequest()
						.growingArea.updateUnusedGrowingArea(arg.id, arg.update)
						.then((resp) => resp.data);
					return { data: result };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to update growing area",
					);
				}
			},
			invalidatesTags: (result, error, arg) => {
				if (error) {
					return [];
				}
				return [
					{ type: "Update", id: arg.id },
					{ type: "Update", id: "ALL" },
				];
			},
		}),
	}),
});

const useGetGrowingAreaByIdQueryDeserialized = (
	id?: string,
	option?: Parameters<typeof apiGrowingArea.useGetGrowingAreaByIdQuery>[1],
) => {
	const apiResult = apiGrowingArea.useGetGrowingAreaByIdQuery(
		id,
		option as any,
	);
	const dataDeserialized = useMemo(() => {
		return apiResult.data
			? EntitySerializer.deserialize<IGrowingArea>(apiResult.data)
			: undefined;
	}, [apiResult.data]);
	return { ...apiResult, data: dataDeserialized };
};

const useGetGrowingAreasQueryDeserialized = (
	args: {
		filter?: IFilter;
		sort?: ISort;
	},
	option?: Parameters<typeof apiGrowingArea.useGetGrowingAreasQuery>[1],
) => {
	const apiResult = apiGrowingArea.useGetGrowingAreasQuery(
		args,
		option as any,
	);
	const dataDeserialized = useMemo(
		() =>
			apiResult?.data?.map((elt) =>
				EntitySerializer.deserialize<IGrowingArea>(elt),
			),
		[apiResult.data],
	);
	return { ...apiResult, data: dataDeserialized };
};

export const growingAreaCustomHook = {
	useGetGrowingAreaByIdQueryDeserialized,
	useGetGrowingAreasQueryDeserialized,
};
