import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IObsRoundSerialized } from "shared-type";

export type IObsRoundEdition = {
	isInit: boolean;
	remoteObsRound?: IObsRoundSerialized;
	canDeleteObsRound: boolean;
	observationRoundEdition: Partial<IObsRoundSerialized>;
	isSelectUserOpen: boolean;
	isSelectVarGroupOpen: boolean;
	isCreateVarGroupOpen: boolean;
	isConsultVarGroupOpen: boolean;
	isCommentOpen: boolean;
	isSendPopupOpen: boolean;
	isEditingInProgress: boolean;
};

function createInitialState(): IObsRoundEdition {
	return {
		isInit: false,
		canDeleteObsRound: false, // block deletion if not init
		observationRoundEdition: {},
		isSelectUserOpen: false,
		isSelectVarGroupOpen: false,
		isCreateVarGroupOpen: false,
		isConsultVarGroupOpen: false,
		isCommentOpen: false,
		isSendPopupOpen: false,
		isEditingInProgress: false,
	};
}

const obsRoundEditionSlice = createSlice({
	name: "obsRoundEdition",
	initialState: createInitialState(),
	reducers: {
		resetObsRound: (state) => {
			state.isInit = false;
			state.canDeleteObsRound = false;
			state.remoteObsRound = undefined;
			state.observationRoundEdition = {};
		},
		setRemoteObsRound: (
			state,
			action: PayloadAction<IObsRoundSerialized | undefined>,
		) => {
			if (action.payload === undefined) {
				state.isInit = false;
				state.remoteObsRound = undefined;
			}
			state.isInit = true;
			state.remoteObsRound = action.payload;
		},
		setCanDeleteObsRound: (
			state,
			action: PayloadAction<boolean | undefined>,
		) => {
			if (action.payload === undefined) {
				state.canDeleteObsRound = false;
			}
			state.canDeleteObsRound = action.payload!;
		},
		updateObsRound: (
			state,
			action: PayloadAction<Partial<IObsRoundSerialized>>,
		) => {
			state.observationRoundEdition = {
				...state.observationRoundEdition,
				...action.payload,
			};
			state.isEditingInProgress = true;
		},
		setSelectUserOpen: (state, action: PayloadAction<boolean>) => {
			state.isSelectUserOpen = action.payload;
		},
		setSelectVarGroupOpen: (state, action: PayloadAction<boolean>) => {
			state.isSelectVarGroupOpen = action.payload;
		},
		setConsultVarGroupOpen: (state, action: PayloadAction<boolean>) => {
			state.isConsultVarGroupOpen = action.payload;
		},
		setCreateVarGroupOpen: (state, action: PayloadAction<boolean>) => {
			state.isCreateVarGroupOpen = action.payload;
		},
		setCommentOpen: (state, action: PayloadAction<boolean>) => {
			state.isCommentOpen = action.payload;
		},
		setIsOpenSendPopupNb: (state, action: PayloadAction<boolean>) => {
			state.isSendPopupOpen = action.payload;
		},
		resetEditingState: (state) => {
			state.isEditingInProgress = false;
			state.observationRoundEdition = {};
		},
		setEditingState: (state) => {
			state.isEditingInProgress = true;
		},
	},
});

export const obsRoundEditionReducer = obsRoundEditionSlice.reducer;
export const obsRoundEditionActions = { ...obsRoundEditionSlice.actions };
