import React, { CSSProperties } from "react";

import CustomCard from "../CustomCard";

import "./LabelledCard.scss";

export default function LabelledCard(props: {
	style?: CSSProperties;
	label: string;
	value: string;
	isMultiline?: boolean;
}) {
	return (
		<div className="LabelledCard-container" style={props.style}>
			<div className="LabelledCard-label">{props.label}</div>
			<CustomCard
				disabledClick
				className={
					props.isMultiline ? "LabelledCardMultiline" : "LabelledCard"
				}
			>
				<div
					className={
						props.isMultiline
							? "LabelledCardMultiline-value"
							: "LabelledCard-value"
					}
					title={props.value}
				>
					{props.value}
				</div>
			</CustomCard>
		</div>
	);
}
