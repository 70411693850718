import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { computeTotalPagesNb } from "common";

import DorianeButton from "App/components/base-components/button/DorianeButton";
import TextSearchBar from "App/components/base-components/searchBar/TextSearchBar";
import { CustomLinkTab } from "App/components/base-components/tabs/CustomLinkTab";
import { CustomTabs } from "App/components/base-components/tabs/CustomTabs";
import CreateGrowingAreaModal from "App/components/growing-area/create/CreateGrowingAreaModal";
import { DIC, EDIC_KEY } from "App/dictionary";
import { EPaddingSize } from "../../../components/base-components/placement/box/Box";
import FlexBox, {
	EGapSize,
} from "../../../components/base-components/placement/flex-box/FlexBox";
import GrowingAreaCardList from "../../../components/growing-area/list/GrowingAreaCardList";
import {
	GROWING_AREA_DEFAULT_PAGE_SIZE,
	GROWING_AREA_DEFAULT_SORT,
} from "../../../const";
import {
	convertDorObjFilterToFilter,
	IDorianeObjectFilter,
} from "../../../interfaces/filters/doriane-filter-interface";
import { apiGrowingArea } from "../../../redux/growing-area/growing-area.api";

import "./GrowingAreaPage.scss";

export default function GrowingAreaPage() {
	const nav = useNavigate();

	const [page, setPage] = useState<number>(0);
	const [filter, setFilter] = useState<IDorianeObjectFilter>({});
	const [creationOpen, setCreationOpen] = useState<boolean>(false);

	const filterForQuery = useMemo(
		() => convertDorObjFilterToFilter(filter, true),
		[filter],
	);

	const {
		data: growingAreas,
		isError,
		isLoading,
	} = apiGrowingArea.useGetPaginatedGrowingAreasQuery({
		sort: GROWING_AREA_DEFAULT_SORT,
		page: page,
		pageSize: GROWING_AREA_DEFAULT_PAGE_SIZE,
		filter: filterForQuery,
	});

	const createGrowingAreaFilter = useCallback((newFilterValue: string) => {
		const filter: IDorianeObjectFilter = {
			name: { key: "name", type: "string", value: newFilterValue },
			country: { key: "country", type: "string", value: newFilterValue },
			region: {
				key: "region",
				type: "string",
				value: newFilterValue,
			},
		};
		setFilter(filter);
		setPage(0); // Reset page number
	}, []);

	return (
		<FlexBox fullParentSize>
			<FlexBox
				className="GrowingAreaPage-list-part"
				fullParentSize
				flexDirection={"column"}
			>
				<CustomTabs value={0} indicatorColor="secondary">
					<CustomLinkTab
						label={DIC(EDIC_KEY.GROWING_AREA)}
						href={"."}
					/>
				</CustomTabs>
				<FlexBox
					takeRemainingSpace
					flexDirection={"column"}
					padding={EPaddingSize.MEDIUM}
					gap={EGapSize.MEDIUM}
				>
					<TextSearchBar
						onSubmit={(newFilter) => {
							createGrowingAreaFilter(newFilter);
						}}
					/>
					<DorianeButton
						dorianeStyle="secondary"
						onClick={() => setCreationOpen(true)}
					>
						{DIC(EDIC_KEY.NEW_GROWING_AREA)} +
					</DorianeButton>
					<GrowingAreaCardList
						growingArea={growingAreas?.data}
						onCardClick={(elt) => nav("./" + elt._id)}
						isError={isError}
						isLoading={isLoading}
						paginate={{
							currentPage: page,
							onPageChange: (pageIndex) => setPage(pageIndex),
							totalElements: computeTotalPagesNb(
								growingAreas?.metadata.pageInfo,
							),
						}}
					/>
				</FlexBox>
			</FlexBox>
			{creationOpen && (
				<CreateGrowingAreaModal
					onClose={() => setCreationOpen(false)}
				/>
			)}
		</FlexBox>
	);
}
