import { IDictionaryEntryLang } from "shared-type";
import { AbstractRequest } from "../abstract-request";
import { AxiosResponse } from "axios";

export class DictionaryRequest extends AbstractRequest {
	dictionaryRoute = "/dictionary";

	getDictionaryByLanguage(
		language: string
	): Promise<AxiosResponse<IDictionaryEntryLang[], any>> {
		return this.axios.get<IDictionaryEntryLang[]>(
			this.hostConfig + this.dictionaryRoute + "/" + language
		);
	}
}
