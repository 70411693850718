import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Mathjs from "mathjs";

import { convertToUniqueVarIdString } from "common";
import { ETrialLevel, IScopedVarWithLevelInfo } from "shared-type";

export interface IExperimentReviewNotationState {
	computedValue: {
		min?: number;
		max?: number;
		average?: number;
		standardDev?: number;
	};
	variablesListByLevel: { [key in ETrialLevel]: string[] };
}

function createInitialState(): IExperimentReviewNotationState {
	return {
		computedValue: {},
		variablesListByLevel: {
			trial: [],
			material: [],
		},
	};
}

const experimentReviewNotationSlice = createSlice({
	name: "experiment-review-notation",
	initialState: createInitialState(),
	reducers: {
		computeComputedValue: (state, action: PayloadAction<number[]>) => {
			if (action.payload.length === 0) {
				state.computedValue = {};
				return;
			}
			state.computedValue.min = Math.min(...action.payload);
			state.computedValue.max = Math.max(...action.payload);
			state.computedValue.average = Mathjs.mean(...action.payload);
			state.computedValue.standardDev = Mathjs.std(...action.payload);
		},
		computeVariablesListByLevel: (
			state,
			action: PayloadAction<IScopedVarWithLevelInfo[]>,
		) => {
			const variablesListByLevel: { [key in ETrialLevel]: Set<string> } =
				{
					trial: new Set(),
					material: new Set(),
				};
			for (const variableGroupByNotebook of action.payload) {
				for (const [level, variables] of Object.entries(
					variableGroupByNotebook || {},
				)) {
					const levelSet: Set<string> | undefined =
						variablesListByLevel[
							level as keyof typeof variablesListByLevel
						];
					for (const variable of variables) {
						levelSet?.add(
							convertToUniqueVarIdString({
								_id: variable.variableId,
								scope: variable.scope,
							}),
						);
					}
				}
			}
			state.variablesListByLevel = {
				trial: Array.from(variablesListByLevel.trial),
				material: Array.from(variablesListByLevel.material),
			};
		},
	},
});

export const experimentReviewNotationReducer =
	experimentReviewNotationSlice.reducer;
export const experimentReviewNotationAction = {
	...experimentReviewNotationSlice.actions,
};
