import { createApi } from "@reduxjs/toolkit/dist/query/react";
import _ from "lodash";

import { EGermplasmLevel, IFilter, IGenotype, ILot, ISort } from "shared-type";

import { useRequest } from "../../request-provider";
import { defaultApiError } from "../utils/errors";

export const apiGermplasm = createApi({
	reducerPath: "api-germplasm",
	baseQuery: () => ({ data: undefined }),

	// global cache configuration for the api (in second)
	keepUnusedDataFor: 30,
	refetchOnMountOrArgChange: 30,

	// update tag
	tagTypes: ["Update", "Delete", "Create"],

	// api definition
	endpoints: (build) => ({
		getGenotypesByIds: build.query<IGenotype[], string[]>({
			queryFn: async (ids) => {
				try {
					if (ids.length === 0) {
						return { data: [] };
					}
					const result = await useRequest().genotype.getGenotypeByIds(
						ids,
					);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch genotypes for ids :" + ids.join(","),
					);
				}
			},
			providesTags: (args) => {
				const ids = _.uniq(args?.map((elt) => elt._id)) || [];
				return [
					...ids.map(
						(id) =>
							({ type: "Create", id: id } as {
								type: "Create";
								id: string;
							}),
					),
					...ids.map(
						(id) =>
							({ type: "Delete", id: id } as {
								type: "Delete";
								id: string;
							}),
					),
					...ids.map(
						(id) =>
							({ type: "Update", id: id } as {
								type: "Update";
								id: string;
							}),
					),
				];
			},
		}),
		getGenotypes: build.query<
			IGenotype[],
			{
				page?: number;
				pageSize?: number;
				filter?: IFilter;
				sort?: ISort;
			} | void
		>({
			queryFn: async (arg) => {
				try {
					const result = await useRequest().genotype.getGenotypes(
						arg?.page,
						arg?.pageSize,
						arg?.filter,
						arg?.sort,
					);
					return {
						data: result.data.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch genotypes with arg :" +
							JSON.stringify(arg),
					);
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),
		getGenotypeById: build.query<IGenotype, string>({
			queryFn: async (arg) => {
				try {
					const result = await useRequest().genotype.getGenotypeById(
						arg,
					);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch genotype with arg :" +
							JSON.stringify(arg),
					);
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),
		getLotByIds: build.query<ILot[], string[]>({
			queryFn: async (ids) => {
				try {
					if (ids.length === 0) {
						return { data: [] };
					}
					const result = await useRequest().lot.getLots(
						0,
						ids.length,
						{
							_id: { $in: ids },
						},
					);
					return {
						data: result.data.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch lots for ids :" + ids.join(","),
					);
				}
			},
			providesTags: (args) => {
				const ids = _.uniq(args?.map((elt) => elt._id)) || [];
				return [
					...ids.map(
						(id) =>
							({ type: "Create", id: id } as {
								type: "Create";
								id: string;
							}),
					),
					...ids.map(
						(id) =>
							({ type: "Delete", id: id } as {
								type: "Delete";
								id: string;
							}),
					),
					...ids.map(
						(id) =>
							({ type: "Update", id: id } as {
								type: "Update";
								id: string;
							}),
					),
				];
			},
		}),

		getGermplasmById: build.query<
			IGenotype | ILot,
			{ id: string; level: EGermplasmLevel }
		>({
			queryFn: async (arg) => {
				try {
					if (arg.level === EGermplasmLevel.GENOTYPE) {
						const result =
							await useRequest().genotype.getGenotypeById(arg.id);
						return {
							data: result.data,
						};
					}
					const result = await useRequest().lot.getLotById(arg.id);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch germplasm with arg :" +
							JSON.stringify(arg),
					);
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),
	}),
});
