import React from "react";
import { useNavigate } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import UserTag from "App/components/base-components/tag/user-tag/UserTag";
import { DIC, EDIC_KEY } from "App/dictionary";
import { getColorFromTrialStatus } from "App/utils";
import { AggroScientist, MetTrial } from "../../../../../../assets";
import { getDicSpecies } from "../../../../../dictionary/dictionary-key-getter";
import { apiUser } from "../../../../../redux/user/user.api";
import { PATH_EXPERIMENTS, PATH_METS } from "../../../../../routes";
import ImgBannerCard from "../../../../base-components/card/img-banner-card/ImgBannerCard";
import ColorTag from "../../../../base-components/tag/color-tag/ColorTag";
import TitledTag from "../../../../base-components/tag/titled-tag/TitledTag";
import { ITrialGenericBannerProps } from "../TrialGenericBanner";

import "./MetTrialBanner.scss";

export default function MetTrialBanner(props: ITrialGenericBannerProps) {
	const nav = useNavigate();

	const { data: user } = apiUser.useGetUserByIdQuery(
		props.trial.systemMetadata?.createdBy ?? skipToken,
	);

	return (
		<ImgBannerCard
			onClick={props.onClick}
			className={props.className}
			disabledClick={props.disabledClick}
			style={{ ...props.style }}
			sideBanner={{ imageSrc: AggroScientist, logoSrc: MetTrial }}
			backgroundColor={"#99CC3360"}
		>
			<div className="MetTrialBanner-container flex-column">
				<div className="MetTrialBanner-header">
					<div className="flex-column">
						<h1 className="MetTrialBanner-title clamped-text">
							<a
								className="MetTrialBanner-title-met-link"
								onClick={(event) => {
									event.stopPropagation();
									nav(
										`${PATH_EXPERIMENTS}${PATH_METS}/${props.trial.metId}`,
									);
								}}
							>
								{props.trial.met?.name ?? "_"}
							</a>
							<>&nbsp;</>
							{"/ " + props.trial.name}
						</h1>
					</div>
					{props.trial.status && (
						<ColorTag
							title={DIC(EDIC_KEY.STATUS)}
							tagValue={props.trial.status}
							colorIndicator={getColorFromTrialStatus(
								props.trial.status,
							)}
						/>
					)}
				</div>

				{user && (
					<UserTag title={DIC(EDIC_KEY.CREATED_BY)} user={user} />
				)}
				<h2 className="MetTrialBanner-description clamped-text">
					{props.trial.description}
				</h2>

				<div className="MetTrialBanner-tags">
					<TitledTag
						title={DIC(EDIC_KEY.YEAR)}
						tagValue={`${props.trial.year}`}
					/>
					<TitledTag
						title={DIC(EDIC_KEY.GROWING_AREA)}
						tagValue={props.trial.growingArea?.name || "_"}
					/>
					{props.trial.species && (
						<TitledTag
							title={DIC(EDIC_KEY.SPECIES)}
							tagValue={getDicSpecies(props.trial.species) || "_"}
						/>
					)}
				</div>
			</div>
		</ImgBannerCard>
	);
}
