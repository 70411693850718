import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	EChartAnalysisContext,
	EChartTrialAnalysis,
	EVariableCoreType,
	IChart,
	IVariableCore,
} from "shared-type";

import ChartCard from "App/components/base-components/chart/ChartCard";
import TrialAnalysisHeader from "App/components/experiment/trial/data/analysis/TrialAnalysisHeader";
import { MONGODB_ATLAS_CHARTS_BASE_URL } from "App/const";
import { apiChart } from "App/redux/chart/chart.api";
import { selectECW } from "App/redux/ECW/ECW.selector";
import { templateObsEditionActions } from "App/redux/template-obs-edition/template-obs-edition.slice";
import { apiTemplateObs } from "App/redux/template-obs/template-obs.api";
import { variablesCustomHook } from "App/redux/variables/variables.api";
import { AppDispatch } from "App/store";

import "./TrialAnalysisPage.scss";

export default function TrialAnalysisPage() {
	const dispatch = useDispatch<AppDispatch>();

	const sdk = new ChartsEmbedSDK({
		baseUrl: MONGODB_ATLAS_CHARTS_BASE_URL,
	});

	const { data: charts } = apiChart.useGetChartsQuery({
		filter: { context: EChartAnalysisContext.TRIAL },
	});
	const trial = useSelector(selectECW.trialRemote);
	const { data: templateObs } = apiTemplateObs.useGetTemplateObsByIdQuery(
		trial?.templateObsId,
	);

	const { data: templateVariables, isSuccess } =
		variablesCustomHook.useGetVariablesByScopedIdsDeserialized(
			templateObs?.variablesPool,
		);
	const templateVarSorted = useMemo(() => {
		return templateVariables
			.filter(
				// keep only numerical
				(variable) =>
					variable.type === EVariableCoreType.INT ||
					variable.type === EVariableCoreType.DEC,
			)
			.sort((varA, varB) => varA.name.en.localeCompare(varB.name.en));
	}, [templateVariables]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [filterGlobal, setFilterGlobal] = useState<any>(undefined);

	const chartExpMaterialGrid: IChart | undefined = charts?.find(
		(chart: IChart) =>
			chart.identifier === EChartTrialAnalysis.MATERIAL_GRID,
	);
	const chartExpMaterialTime: IChart | undefined = charts?.find(
		(chart: IChart) =>
			chart.identifier === EChartTrialAnalysis.MATERIAL_TIME,
	);

	const renderChart = (
		chart: IChart | undefined,
		variables?: IVariableCore[],
	) => {
		if (chart) {
			const { name, description, atlasEmbeddedId } = chart;

			return (
				<ChartCard
					title={name}
					chartName={name}
					description={description}
					variables={variables}
					sdk={sdk}
					chartId={atlasEmbeddedId}
					filter={filterGlobal}
					chartAutoRefresh={false}
					chartMaxDataAge={0}
				/>
			);
		}
	};

	useEffect(() => {
		return () => {
			dispatch(templateObsEditionActions.resetTemplateObs);
		};
	}, [dispatch]);

	return (
		<div className="TrialAnalysisPage-container full-parent-size flex-column">
			{trial !== undefined && (
				<>
					<TrialAnalysisHeader
						filterGlobal={filterGlobal}
						setFilterGlobal={setFilterGlobal}
					/>

					<div className="TrialAnalysisPage-body take-remaining-space">
						{filterGlobal && renderChart(chartExpMaterialGrid)}
						{filterGlobal &&
							isSuccess &&
							renderChart(
								chartExpMaterialTime,
								templateVarSorted,
							)}
					</div>
				</>
			)}
		</div>
	);
}
