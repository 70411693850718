import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { DIC, EDIC_KEY } from "App/dictionary";
import { selectECW } from "App/redux/ECW/ECW.selector";
import { ECWAction } from "App/redux/ECW/ECW.slice";
import { AppDispatch } from "App/store";
import {
	CustomStep,
	DorianeStepper,
} from "../../../base-components/stepper/DorianeStepper";
import ColorTag from "../../../base-components/tag/color-tag/ColorTag";

import styles from "../../../../../sassTheme";
import "./ECWHeader.scss";

interface ECWHeaderProps {
	steps: CustomStep[];
	activeStepIndex: number;
}

export default function ECWHeader(props: ECWHeaderProps) {
	const dispatch = useDispatch<AppDispatch>();

	const setActiveStep = (step: number) => {
		dispatch(ECWAction.setActiveStep(step));
	};

	const generalInfo = useSelector(selectECW.generalInfo);
	const wizardType = useSelector(selectECW.wizardType);

	return (
		<div className="ECWHeader-container flex-column">
			<div className="ECWHeader-title">
				<h1 className="clamped-text">
					{generalInfo.generalInfo.name ||
						(wizardType === "MET"
							? DIC(EDIC_KEY.MET)
							: DIC(EDIC_KEY.TRIAL))}
				</h1>
				{props.steps[0].completed && (
					<div className="ECWHeader-title-status">
						<ColorTag
							title={DIC(EDIC_KEY.STATUS)}
							tagValue={DIC(EDIC_KEY.DRAFT)}
							colorIndicator={styles["custom-var-color"]}
						/>
					</div>
				)}
			</div>
			<div className="ECWHeader-steps">
				<DorianeStepper
					currentStepIndex={props.activeStepIndex}
					onStepClick={(step) => setActiveStep(step.index)}
					steps={props.steps}
				/>
			</div>
		</div>
	);
}
