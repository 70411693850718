// TEMPORARY -> Test to create a custom file (not finish and not use for now)
import {
	CustomCell,
	CustomRenderer,
	GridCellKind,
	interpolateColors,
} from "@glideapps/glide-data-grid";
// import { roundedRect } from "../draw-fns";

type PackedColor = string | readonly [normal: string, hover: string];
type Point = { x: number; y: number };
type Size = { width: number; height: number };
type SquareHitBox = [Point, Point];

const reviewedImage = {
	path: new Path2D(
		"M7.33099 12.1018L12.932 6.50072L12.0216 5.61009L7.33099 10.3007L4.95599 7.92572L4.06536 8.81634L7.33099 12.1018ZM8.4987 16.4163C7.41675 16.4163 6.39418 16.2085 5.43099 15.7929C4.4678 15.3773 3.62665 14.8099 2.90755 14.0908C2.18845 13.3717 1.62109 12.5306 1.20547 11.5674C0.789844 10.6042 0.582031 9.58162 0.582031 8.49967C0.582031 7.40454 0.789844 6.37537 1.20547 5.41217C1.62109 4.44898 2.18845 3.61113 2.90755 2.89863C3.62665 2.18613 4.4678 1.62207 5.43099 1.20645C6.39418 0.79082 7.41675 0.583008 8.4987 0.583008C9.59384 0.583008 10.623 0.79082 11.5862 1.20645C12.5494 1.62207 13.3872 2.18613 14.0997 2.89863C14.8122 3.61113 15.3763 4.44898 15.7919 5.41217C16.2076 6.37537 16.4154 7.40454 16.4154 8.49967C16.4154 9.58162 16.2076 10.6042 15.7919 11.5674C15.3763 12.5306 14.8122 13.3717 14.0997 14.0908C13.3872 14.8099 12.5494 15.3773 11.5862 15.7929C10.623 16.2085 9.59384 16.4163 8.4987 16.4163Z",
	),
	height: 17,
	width: 17,
};
const excludedImage = {
	path: new Path2D(
		"M4.375 15L0 10.625V4.375L4.375 0H10.625L15 4.375V10.625L10.625 15H4.375ZM4.9375 10.9375L7.5 8.375L10.0625 10.9375L10.9375 10.0625L8.375 7.5L10.9375 4.9375L10.0625 4.0625L7.5 6.625L4.9375 4.0625L4.0625 4.9375L6.625 7.5L4.0625 10.0625L4.9375 10.9375Z",
	),
	height: 15,
	width: 15,
};
interface StatusButtonCellProps {
	readonly kind: "data-status-cell";
	readonly onClick?: () => void;
	readonly backgroundColor?: PackedColor;
	readonly color?: PackedColor;
	readonly borderColor?: PackedColor;
	readonly borderRadius?: number;
}

function unpackColor(
	color: PackedColor,
	theme: Record<string, any>,
	hoverAmount: number,
): string {
	if (typeof color === "string") {
		if (theme[color] !== undefined) return theme[color];
		return color;
	}

	let [normal, hover] = color;
	if (theme[normal] !== undefined) normal = theme[normal];
	if (theme[hover] !== undefined) hover = theme[hover];
	return interpolateColors(normal, hover, hoverAmount);
}

function drawnImageLeft(
	ctx: CanvasRenderingContext2D,
	originX: number,
	originY: number,
	cellWidth: number,
	cellHeight: number,
	gap: number,
	img: {
		path: Path2D;
		height: number;
		width: number;
	},
	hover?: { x: number; y: number },
) {
	const cellCenter = {
		x: originX + cellWidth / 2,
		y: originY + cellHeight / 2,
	};

	// 2 button
	const scale = Math.min(
		cellWidth / 2 + gap / img.width,
		cellHeight / img.height,
	);

	const widthScale = img.width * scale;
	const heightScale = img.height * scale;

	const startDrawn = {
		x: cellCenter.x - widthScale - gap / 2,
		y: cellCenter.y - heightScale / 2,
	};
	const endDrawn = {
		x: startDrawn.x + widthScale,
		y: startDrawn.y + heightScale,
	};

	ctx.save();
	ctx.translate(Math.floor(startDrawn.x), Math.floor(startDrawn.y));

	ctx.scale(scale, scale);
	if (
		hover &&
		hover.x > startDrawn.x &&
		hover.x < endDrawn.x &&
		hover.y > startDrawn.y &&
		hover.y < endDrawn.y
	) {
		ctx.fillStyle = "red";
	} else {
		ctx.fillStyle = "#99cc33";
	}
	ctx.fill(img.path);
	ctx.restore();
}

function drawnImageRight(
	ctx: CanvasRenderingContext2D,
	originX: number,
	originY: number,
	cellWidth: number,
	cellHeight: number,
	gap: number,
	img: {
		path: Path2D;
		height: number;
		width: number;
	},
	hover?: { x: number; y: number },
) {
	ctx.save();
	const cellCenter = {
		x: originX + cellWidth / 2,
		y: originY + cellHeight / 2,
	};

	const scale = Math.min(
		cellWidth / img.width,
		(cellHeight / 2 + gap) / img.height,
	);

	const widthScale = img.width * scale;
	const heightScale = img.height * scale;

	const startDrawn = {
		x: cellCenter.x + gap / 2,
		y: cellCenter.y - heightScale / 2,
	};
	const endDrawn = {
		x: startDrawn.x + widthScale,
		y: startDrawn.y + heightScale,
	};

	ctx.translate(startDrawn.x, startDrawn.y);

	ctx.scale(scale, scale);

	ctx.fillStyle = "red";

	ctx.fill(img.path);
	ctx.restore();
}
export type DorianeStatusButtonCell = CustomCell<StatusButtonCellProps>;
const DorianeStatusButtonRenderer: CustomRenderer<DorianeStatusButtonCell> = {
	kind: GridCellKind.Custom,
	isMatch: (c): c is DorianeStatusButtonCell =>
		(c.data as any).kind === "data-status-cell",
	needsHoverPosition: true,
	needsHover: true,
	onSelect: (a) => a.preventDefault(),
	onClick: (event) => {
		event.cell.data.onClick?.();
		return undefined;
	},
	drawPrep: (args) => {
		const { ctx } = args;

		ctx.textAlign = "center";

		return {
			deprep: (a) => {
				a.ctx.textAlign = "start";
			},
		};
	},
	draw: (args, cell) => {
		const { ctx, theme, rect, hoverAmount, imageLoader, hoverX, hoverY } =
			args;
		const { color, onClick } = cell.data;

		const x = Math.floor(rect.x + theme.cellHorizontalPadding + 1);
		const y = Math.floor(rect.y + theme.cellVerticalPadding + 1);
		const width = Math.ceil(
			rect.width - theme.cellHorizontalPadding * 2 - 1,
		);
		const height = Math.ceil(
			rect.height - theme.cellVerticalPadding * 2 - 1,
		);

		// if (backgroundColor !== undefined) {
		//     ctx.beginPath();
		//     roundedRect(ctx, x, y, width, height, borderRadius ?? 0);
		//     ctx.fillStyle = unpackColor(backgroundColor, theme, hoverAmount);
		//     ctx.fill();
		// }

		// if (borderColor !== undefined) {
		//     ctx.beginPath();
		//     roundedRect(ctx, x + 0.5, y + 0.5, width - 1, height - 1, borderRadius ?? 0);
		//     ctx.strokeStyle = unpackColor(borderColor, theme, hoverAmount);
		//     ctx.lineWidth = 1;
		//     ctx.stroke();
		// }

		ctx.fillStyle = unpackColor(
			color ?? theme.accentColor,
			theme,
			hoverAmount,
		);
		const gap = 10;

		let hover: { x: number; y: number } | undefined;
		if (hoverX && hoverY) {
			hover = {
				x: hoverX + rect.x,
				y: hoverY + rect.y,
			};
		}
		drawnImageLeft(ctx, x, y, width, height, gap, reviewedImage, hover);
		drawnImageRight(ctx, x, y, width, height, gap, excludedImage, hover);

		return true;
	},
	provideEditor: undefined,
};

// export default DorianeStatusButtonRenderer;
