import * as React from "react";
import { IUser } from "shared-type";
import { Tooltip } from "@mui/material";
import TitledTag from "../titled-tag/TitledTag";

import "./UserTag.scss";

interface IUserTagProps {
	title: string;
	user: IUser;
	initialsOnly?: boolean;
	fullNameOnHover?: boolean;
}

export default function UserTag(props: IUserTagProps) {
	const getInitials = () => {
		const firstNameIn = props.user.firstName?.charAt(0).toUpperCase() ?? "";
		const lastNameIn = props.user.lastName?.charAt(0).toUpperCase() ?? "";
		return firstNameIn + lastNameIn;
	};

	return (
		<TitledTag title={props.title}>
			<Tooltip
				title={props.user.firstName + " " + props.user.lastName}
				open={props.fullNameOnHover ? undefined : false}
			>
				<div
					className="UserTag-badge"
					style={{
						backgroundColor: props.user.color,
					}}
				>
					{getInitials()}
				</div>
			</Tooltip>

			{!props.initialsOnly && (
				<h3>{`${props.user?.firstName || "_"} ${
					props.user?.lastName || "_"
				}`}</h3>
			)}
		</TitledTag>
	);
}
