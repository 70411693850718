"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESeason = exports.ESoilType = exports.EClimate = void 0;
var EClimate;
(function (EClimate) {
    EClimate["TROPICAL"] = "tropical";
    EClimate["DRY"] = "dry";
    EClimate["TEMPERATE"] = "temperate";
    EClimate["CONTINENTAL"] = "continental";
    EClimate["POLAR"] = "polar";
})(EClimate = exports.EClimate || (exports.EClimate = {}));
var ESoilType;
(function (ESoilType) {
    ESoilType["SANDY"] = "sandy";
    ESoilType["SILT"] = "silt";
    ESoilType["CLAY"] = "clay";
    ESoilType["LOAMY"] = "loamy";
})(ESoilType = exports.ESoilType || (exports.ESoilType = {}));
var ESeason;
(function (ESeason) {
    ESeason["SUMMER"] = "summer";
    ESeason["AUTUMN"] = "autumn";
    ESeason["WINTER"] = "winter";
    ESeason["SPRING"] = "spring";
})(ESeason = exports.ESeason || (exports.ESeason = {}));
