import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { formatString } from "common";
import { ILevelCompletionStats } from "shared-type";

import { DIC, EDIC_KEY } from "../../dictionary";
import { useRequest } from "../../request-provider";
import { defaultApiError } from "../utils/errors";

export const apiLevelCompletion = createApi({
	reducerPath: "api-level-completion",
	baseQuery: () => ({ data: undefined }),
	// global configuration for the api (in second)
	keepUnusedDataFor: 300,
	// global configuration for the api
	refetchOnMountOrArgChange: 60,
	tagTypes: [],
	endpoints: (build) => ({
		computeNbCompletionById: build.query<
			ILevelCompletionStats | undefined,
			string | undefined
		>({
			queryFn: async (notebookId) => {
				if (!notebookId) {
					return { data: undefined };
				}
				try {
					const result =
						await useRequest().expLevelCompletion.computeNbCompletionById(
							notebookId,
						);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.FETCH).toLowerCase(),
							DIC(EDIC_KEY.LEVEL_COMPLETION).toLowerCase(),
						),
					);
				}
			},
			providesTags: [],
		}),
		computeObsRoundCompletionById: build.query<
			ILevelCompletionStats | undefined,
			string | undefined
		>({
			queryFn: async (obsRoundId) => {
				if (!obsRoundId) {
					return { data: undefined };
				}
				try {
					const result =
						await useRequest().expLevelCompletion.computeObsRoundCompletionById(
							obsRoundId,
						);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.FETCH).toLowerCase(),
							DIC(EDIC_KEY.LEVEL_COMPLETION).toLowerCase(),
						),
					);
				}
			},
			providesTags: [],
		}),

		computeTrialCompletionById: build.query<
			ILevelCompletionStats | undefined,
			string | undefined
		>({
			queryFn: async (trialId) => {
				if (!trialId) {
					return { data: undefined };
				}
				try {
					const result =
						await useRequest().expLevelCompletion.computeTrialCompletionById(
							trialId,
						);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.FETCH).toLowerCase(),
							DIC(EDIC_KEY.LEVEL_COMPLETION).toLowerCase(),
						),
					);
				}
			},
			providesTags: [],
		}),
	}),
});
