"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EExperimentProgram = exports.EExperimentProject = exports.EExperimentStatus = void 0;
var EExperimentStatus;
(function (EExperimentStatus) {
    EExperimentStatus["DRAFT"] = "draft";
    EExperimentStatus["READY"] = "ready";
    EExperimentStatus["VALIDATED"] = "validated";
    EExperimentStatus["NOT_VALIDATED"] = "not validated";
    EExperimentStatus["COMPLETED"] = "completed";
})(EExperimentStatus = exports.EExperimentStatus || (exports.EExperimentStatus = {}));
var EExperimentProject;
(function (EExperimentProject) {
    EExperimentProject["DROUGHT"] = "drought tolerance";
    EExperimentProject["DISEASE"] = "disease resistance";
    EExperimentProject["YIELD"] = "yield increase";
})(EExperimentProject = exports.EExperimentProject || (exports.EExperimentProject = {}));
var EExperimentProgram;
(function (EExperimentProgram) {
    EExperimentProgram["BREEDING"] = "breeding";
    EExperimentProgram["TESTING"] = "testing";
    EExperimentProgram["PRODUCT_DEV"] = "product dev";
})(EExperimentProgram = exports.EExperimentProgram || (exports.EExperimentProgram = {}));
