import { formatString } from "common";
import { IVariableCore } from "shared-type";

import { DIC, EDIC_KEY } from "App/dictionary";
import { CheckBoxColumn } from "../columns/CheckBoxColumn";
import { IColumn } from "../doriane-data-grid/DorianeDataGrid";
import { templateObsVarColumns } from "./TemplateObsVarColumns";

export interface ITemplateObsVarColumnsProps {
	isTrialCheck: (row: IVariableCore) => boolean;
	isMaterialCheck: (row: IVariableCore) => boolean;
	isVariableCheck: (row: IVariableCore) => boolean;
	onVariableCheck?: (row: IVariableCore, check: boolean) => void;
	checkDisabled?: boolean;
}

export const templateObsVarGroupColumns: (
	props: ITemplateObsVarColumnsProps,
) => IColumn<IVariableCore>[] = (props) => {
	const columns: IColumn<IVariableCore>[] = templateObsVarColumns({
		displayMode: "checkbox",
		isTrialCheck: props.isTrialCheck,
		isMaterialCheck: props.isMaterialCheck,
		hiddenColorStripe: false,
		checkDisabled: true,
	});

	columns.splice(
		1, // We set the variable group checkbox to the index 1 (0 is the color stripe)
		0,
		CheckBoxColumn({
			isCheck: props.isVariableCheck,
			onCheck: props.onVariableCheck,
			headerName: formatString(
				DIC(EDIC_KEY.ADD_VARIABLES_TO_ENTITY),
				DIC(EDIC_KEY.GROUP).toLowerCase(),
			),
			width: "1fr",
			isDisabled: () => props.checkDisabled,
		}),
	);

	return columns;
};
