import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import {
	ICreateMarketSegment,
	IFilter,
	IMarketSegmentSerialized,
	IPatchMarketSegment,
	ISort,
} from "shared-type";
import { AbstractRequest } from "../abstract-request";

export class MarketSegmentRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/market-segment";
	}

	getMarketSegments(
		filter?: IFilter,
		sort?: ISort
	): Promise<AxiosResponse<IMarketSegmentSerialized[]>> {
		return this.axios.get<IMarketSegmentSerialized[]>(`${this.baseUrl}`, {
			params: {
				filter: JSON.stringify(filter),
				sort: JSON.stringify(sort),
			},
		});
	}

	createMarketSegment(
		createMarketSegment: ICreateMarketSegment
	): Promise<AxiosResponse<string, any>> {
		return this.axios.post<string>(`${this.baseUrl}`, createMarketSegment);
	}

	updateMarketSegmentById(
		marketSegmentId: string,
		updateMarketSegment: IPatchMarketSegment
	): Promise<AxiosResponse<void, any>> {
		return this.axios.patch<void>(
			`${this.baseUrl}/${marketSegmentId}`,
			updateMarketSegment
		);
	}

	getMarketSegmentById(id: string, config?: AxiosRequestConfig) {
		return this.axios.get<IMarketSegmentSerialized>(this.baseUrl + "/" + id, {
			...config,
		});
	}

	getMarketSegmentByIds(
		marketSegmentIds: string[],
		config?: AxiosRequestConfig
	) {
		return this.axios.post<IMarketSegmentSerialized[]>(
			this.baseUrl + "/get/many",
			marketSegmentIds,
			config
		);
	}

	getMarketSegmentUsagesById(
		id: string,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<string[], any>> {
		return this.axios.get<string[]>(`${this.baseUrl}/usage/${id}`, config);
	}

	deleteMarketSegmentById(
		id: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<void, any>> {
		return this.axios.delete<void>(`${this.baseUrl}/${id}`, {
			...config,
		});
	}
}
