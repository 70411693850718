import React from "react";
import { RouteObject } from "react-router-dom";

import {
	PATH_ONTOLOGY,
	PATH_OTHERS,
	PATH_VARIABLES,
	removeStartingSlash,
} from "../../../routes";
import VariablesOntologyPage from "../../../Views/main/settings/variables/ontology/VariablesOntologyPage";
import VariablesOthersPage from "../../../Views/main/settings/variables/others/VariablesOthersPage";
import VariablesPage from "../../../Views/main/settings/variables/VariablesPage";

export function variableRouter(): RouteObject {
	return {
		path: PATH_VARIABLES,
		element: <VariablesPage />,
		children: [
			{
				index: true,
				path: removeStartingSlash(PATH_ONTOLOGY),
				element: <VariablesOntologyPage />,
			},
			{
				path: removeStartingSlash(PATH_OTHERS),
				element: <VariablesOthersPage />,
			},
		],
	};
}
