import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { EntitySerializer } from "common";

import {
	ICreateGrowingArea,
	IFilter,
	IGrowingArea,
	IGrowingAreaSerialized,
	IPatchUnusedGrowingArea,
	IPatchUsedGrowingArea,
	ISort,
	PaginateResponse,
} from "shared-type";

import { AbstractRequest } from "../abstract-request";

export class GrowingAreaRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/growing-areas";
	}

	createGrowingArea(
		createGrowingArea: ICreateGrowingArea
	): Promise<AxiosResponse<string, any>> {
		return this.axios.post<string>(`${this.baseUrl}`, createGrowingArea);
	}

	getGrowingAreaByIds(
		growingAreaIds: string[],
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<IGrowingAreaSerialized[], any>> {
		return this.axios.post<IGrowingAreaSerialized[]>(
			this.baseUrl + "/get/many",
			growingAreaIds,
			config
		);
	}

	getGrowingAreaById(
		id: string,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<IGrowingArea, any>> {
		return this.axios
			.get<IGrowingAreaSerialized>(`${this.baseUrl}/${id}`, config)
			.then((resp) => {
				// Need to convert date
				const returnedValue = resp as unknown as AxiosResponse<
					IGrowingArea,
					any
				>;
				returnedValue.data = EntitySerializer.deserialize<IGrowingArea>(
					resp.data
				);
				return returnedValue;
			});
	}

	getGrowingAreaUsageInfoById(
		id: string,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<string[], any>> {
		return this.axios.get<string[]>(`${this.baseUrl}/usage/${id}`, config);
	}

	getGrowingAreas(
		filter?: IFilter,
		sort?: ISort,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<IGrowingArea[], any>> {
		return this.axios
			.get<IGrowingAreaSerialized[]>(`${this.baseUrl}`, {
				...config,
				params: {
					...config?.params,
					filter: JSON.stringify(filter),
					sort: JSON.stringify(sort),
				},
			})
			.then((resp) => {
				// Need to deserialized
				const returnedValue = resp as unknown as AxiosResponse<
					IGrowingArea[],
					any
				>;
				returnedValue.data = resp.data.map((elt: IGrowingAreaSerialized) =>
					EntitySerializer.deserialize<IGrowingArea>(elt)
				);
				return returnedValue;
			});
	}

	getPaginatedGrowingAreas(
		args: { page?: number; pageSize?: number; filter?: IFilter; sort?: ISort },
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<PaginateResponse<IGrowingAreaSerialized>, any>> {
		return this.axios.get<PaginateResponse<IGrowingAreaSerialized>>(
			`${this.baseUrl}/paginate/`,
			{
				...config,
				params: {
					...config?.params,
					page: JSON.stringify(args.page),
					pageSize: JSON.stringify(args.pageSize),
					filter: JSON.stringify(args.filter),
					sort: JSON.stringify(args.sort),
				},
			}
		);
	}

	updateUnusedGrowingArea(
		id: string,
		updateUnusedGA: IPatchUnusedGrowingArea
	): Promise<AxiosResponse<void, any>> {
		return this.axios.patch<void, AxiosResponse<void, any>>(
			`${this.baseUrl}/unused/${id}`,
			updateUnusedGA
		);
	}

	updateUsedGrowingArea(
		id: string,
		updateUsedGA: IPatchUsedGrowingArea
	): Promise<AxiosResponse<void, any>> {
		return this.axios.patch<void, AxiosResponse<void, any>>(
			`${this.baseUrl}/used/${id}`,
			updateUsedGA
		);
	}

	deleteGrowingAreaById(
		id: string,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<void, any>> {
		return this.axios.delete<void>(`${this.baseUrl}/${id}`, config);
	}
}
