import React from "react";

import { ITemplateExpStructCore } from "shared-type";

import TemplateExperimentStructureBanner, {
	ITemplateExperimentStructureBannerProps,
} from "./TemplateExperimentStructureBanner";

import "./TemplateExperimentStructureCard.scss";

export interface ITemplateExperimentStructureCardProps {
	templateExperimentStructure: ITemplateExpStructCore;
	onClick?: () => void;
	disabledClick?: boolean;
}

export default function TemplateExperimentStructureCard(
	props: ITemplateExperimentStructureCardProps,
) {
	const enhancedProps: ITemplateExperimentStructureBannerProps = {
		...props,
		className: "TemplateExperimentStructureCard",
	};

	return <TemplateExperimentStructureBanner {...enhancedProps} />;
}
