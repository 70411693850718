import React from "react";
import { UseFormReturn } from "react-hook-form";

import { EExperimentStatus } from "shared-type";

import { DIC, EDIC_KEY } from "../../../../dictionary";
import { getDicExpStatus } from "../../../../dictionary/dictionary-key-getter";
import { EFormInputType } from "../../../base-components/dynamic-form";
import DynamicForm from "../../../base-components/dynamic-form/DynamicForm";
import FormSection from "../../../base-components/dynamic-form/form-section/FormSection";

export type EExpConclusionStatus =
	| EExperimentStatus.NOT_VALIDATED
	| EExperimentStatus.VALIDATED;

export interface IExpConclusionForm {
	conclusion?: string;
	status: EExpConclusionStatus;
}

interface ExpConclusionFormProps {
	form: UseFormReturn<IExpConclusionForm>;
}

export default function ExpConclusionForm(props: ExpConclusionFormProps) {
	const form = props.form;

	return (
		<div>
			<FormSection title={DIC(EDIC_KEY.PERIOD)}>
				<DynamicForm
					control={form.control}
					formInfos={[
						{
							inputType: EFormInputType.COMBOBOX,
							id: "status",
							label: DIC(EDIC_KEY.CONCLUSION_STATUS),
							comboboxValues: [
								EExperimentStatus.VALIDATED,
								EExperimentStatus.NOT_VALIDATED,
							],
							translateMethod: getDicExpStatus,
							required: true,
							takeAllRow: true,
						},
						{
							inputType: EFormInputType.STRING,
							id: "conclusion",
							label: DIC(EDIC_KEY.CONCLUSION),
							multiline: true,
							takeAllRow: true,
						},
					]}
				/>
			</FormSection>
		</div>
	);
}
