import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IGrowingArea } from "shared-type";

import { useGrowingAreaWithUsersAndMarketSegments } from "App/components/growing-area/hooks/growingAreaHook";
import {
	GROWING_AREA_DEFAULT_PAGE_SIZE,
	GROWING_AREA_DEFAULT_SORT,
} from "../../../../const";
import { DIC, EDIC_KEY } from "../../../../dictionary";
import {
	IDorianeFilter,
	IDorianeObjectFilter,
} from "../../../../interfaces/filters/doriane-filter-interface";
import { selectECW } from "../../../../redux/ECW/ECW.selector";
import { ECWAction } from "../../../../redux/ECW/ECW.slice";
import { AppDispatch } from "../../../../store";
import { growingAreaColumnsForSelection } from "../../../base-components/data-grid/doriane-data-grid/default-grid-columns/GrowingAreaColumns";
import DorianeDataGrid from "../../../base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import DorianeDrawer from "../../../base-components/modal/DorianeDrawer";
import PaginationTool from "../../../base-components/pagination/PaginationTool";
import Box, { EPaddingSize } from "../../../base-components/placement/box/Box";
import FlexBox from "../../../base-components/placement/flex-box/FlexBox";

export default function ExpSelectGrowingArea() {
	const dispatch = useDispatch<AppDispatch>();

	const [filter, setFilter] = useState<IDorianeObjectFilter>({});
	const [page, setPage] = useState<number>(0);

	const currentGrowingAreaId = useSelector(selectECW.growingAreaId);

	const handleFilterChange = (newFilter: IDorianeFilter) => {
		setFilter((old) => {
			return { ...old, [newFilter.key]: newFilter };
		});
		setPage(0);
	};

	const { sortedGAsWithInfos, totalPages } =
		useGrowingAreaWithUsersAndMarketSegments({
			sort: GROWING_AREA_DEFAULT_SORT,
			filter,
			pagination: { page, pageSize: GROWING_AREA_DEFAULT_PAGE_SIZE },
		});

	return (
		<DorianeDrawer
			takeRemainingSpace
			onClose={() => dispatch(ECWAction.setSelectGrowingAreaOpen(false))}
			title={DIC(EDIC_KEY.GROWING_AREA)}
		>
			<Box takeRemainingSpace>
				<DorianeDataGrid<IGrowingArea>
					className="full-parent-size no-animation"
					rowHeight="40px"
					columns={growingAreaColumnsForSelection(
						{
							filter: filter,
							onFilterChange: handleFilterChange,
						},
						{
							isCheck: (row) => currentGrowingAreaId === row._id,
							onCheck: (row) =>
								dispatch(
									ECWAction.updateGrowingAreaId(
										currentGrowingAreaId === row._id
											? undefined
											: row._id,
									),
								),
						},
					)}
					rows={sortedGAsWithInfos || []}
				/>
			</Box>
			<FlexBox padding={EPaddingSize.SMALL} justifyContent={"center"}>
				<PaginationTool
					currentPage={page}
					maxPage={totalPages ?? 0}
					onPageRoll={(newPage) => setPage(newPage)}
				/>
			</FlexBox>
		</DorianeDrawer>
	);
}
