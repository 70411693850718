import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { formatString } from "common";

import { DIC, EDIC_KEY } from "App/dictionary";
import useAppSnackbar from "../../../../../hooks/useAppSnackbar";
import { apiTrial } from "../../../../../redux/experiment/trial/trial.api";
import { ApiErrorSnackbar } from "../../../../../redux/utils/api-error-snackbar/ApiErrorSnackbar";
import { useAppSelector } from "App/hooks/reduxHook";
import { selectTrial } from "App/redux/experiment/trial/trial.selector";
import store from "App/store";
import { trialAction } from "App/redux/experiment/trial/trial.slice";
import { Modal } from "stories/base-components/Modal/Modal";
import { ReduxApiError } from "App/redux/utils/errors";
import {
	ITrialDuplicateForm,
	TrialDuplicateFormPart1,
	TrialDuplicateFormPart2,
} from "App/components/form/trial-duplicate/TrialDuplicateForm";
import {
	trialDuplicateFormToObject,
	trialDuplicateValidator,
} from "App/components/form/trial-duplicate";
import useGlobalModal from "App/hooks/useGlobalModal";
import { IMETTrialRowDetails } from "App/redux/experiment/met/met-trial-management.slice";

export const TrialDuplicateModal = () => {
	const { enqueueSnackbarError } = useAppSnackbar();
	const { openModal, closeModal } = useGlobalModal();

	const nav = useNavigate();

	const [duplicateTrialsMutation] = apiTrial.useDuplicateTrialsMutation();

	const selectedDuplicatedTrial = useAppSelector(
		selectTrial.trialDuplicateSelected,
	);

	const [trialDuplicateLoading, setTrialDuplicateLoading] = useState(false);

	const defaultFormValues = {
		name: "",
		periodFrom: undefined,
		periodTo: undefined,
		material: true,
		growingArea: true,
		obsTemplate: true,
		duplicateTasks: true,
		tasks: "tasks_users",
	};

	const form = useForm<ITrialDuplicateForm>({
		defaultValues: defaultFormValues,
		resolver: yupResolver(trialDuplicateValidator as any),
	});

	const [trialDuplicateFormStep, setTrialDuplicateFormStep] = useState(0);

	const handleClose = async () => {
		store.dispatch(trialAction.selectDuplicateTrial(undefined));
		setTrialDuplicateFormStep(0);
		form.reset(defaultFormValues);
	};

	const duplicationValidated = async (
		selectedDuplicatedTrial: IMETTrialRowDetails,
		newTrial: Array<string>,
	) => {
		setTrialDuplicateLoading(false);
		const duplicatedTrialName = selectedDuplicatedTrial.name || "";
		// Close Modal
		handleClose();
		openModal({
			title: formatString(
				DIC(EDIC_KEY.ENTITY_DUPLICATED),
				DIC(EDIC_KEY.TRIAL),
			),
			type: "success",
			btn: {
				label: DIC(EDIC_KEY.SEE_DUPLICATED_TRIAL),
				action: newTrial[0]
					? () => {
							nav(newTrial[0]);
							closeModal();
					  }
					: undefined,
			},
			cancelLabel: DIC(EDIC_KEY.RETURN_TO_TRIAL_LIST),
			descriptions: [
				formatString(
					DIC(EDIC_KEY.ENTITY_TRIAL_DUPLICATED),
					duplicatedTrialName,
				),
				DIC(EDIC_KEY.TRIAL_DUPLICATED_CONFIRM_QUESTION),
			],
		});
	};

	const handleDuplicate = async () => {
		form.clearErrors();

		form.handleSubmit(
			async (data: any) => {
				if (!selectedDuplicatedTrial?._id) {
					return;
				}
				setTrialDuplicateLoading(true);
				const formDataWithId = {
					...data,
					id: selectedDuplicatedTrial?._id,
				};
				const objData = trialDuplicateFormToObject(formDataWithId);

				duplicateTrialsMutation(objData)
					.unwrap()
					.then((newTrial) => {
						duplicationValidated(selectedDuplicatedTrial, newTrial);
					})
					.catch((err: ReduxApiError) => {
						setTrialDuplicateLoading(false);
						console.warn(err);
						enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
					});
			},
			(error) => {
				console.log(error);
				enqueueSnackbarError(DIC(EDIC_KEY.BAD_INPUT));
			},
		)();
	};

	const handleNext = async () => {
		form.handleSubmit(
			(data) => {
				setTrialDuplicateFormStep(1);
			},
			(error) => {
				// Block step2 only if fields of first step in errors
				if (!(error.name || error.periodFrom || error.periodTo)) {
					setTrialDuplicateFormStep(1);
				}
			},
		)();
	};

	useEffect(() => {
		if (!selectedDuplicatedTrial) {
			return;
		}
		const noMaterials =
			(selectedDuplicatedTrial?.materialLevel?.materials?.length || 0) ===
			0;
		const noGrowingArea = !Boolean(selectedDuplicatedTrial?.growingAreaId);
		const noObsTemplate = !Boolean(selectedDuplicatedTrial?.templateObsId);
		if (noMaterials) {
			form.setValue("material", false);
		}
		if (noGrowingArea) {
			form.setValue("growingArea", false);
		}
		if (noObsTemplate) {
			form.setValue("obsTemplate", false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDuplicatedTrial]);

	return (
		<Modal
			open={Boolean(selectedDuplicatedTrial)}
			handleClose={handleClose}
			title={
				DIC(EDIC_KEY.DUPLICATE) +
				" " +
				DIC(EDIC_KEY.TRIAL).toLowerCase()
			}
			validateBtn={
				trialDuplicateFormStep === 1
					? {
							action: () => {
								handleDuplicate();
							},
							label:
								DIC(EDIC_KEY.DUPLICATE) +
								" " +
								DIC(EDIC_KEY.TRIAL).toLowerCase(),
							isLoading: trialDuplicateLoading,
					  }
					: {
							action: () => {
								handleNext();
							},
							label: DIC(EDIC_KEY.NEXT),
							iconName: "arrow-line-right",
					  }
			}
			titleIcon="template"
			width={750}
			stepperSelected={trialDuplicateFormStep}
			stepper={[DIC(EDIC_KEY.GENERAL), DIC(EDIC_KEY.DEPENDENCIES)]}
			onStepperClick={(step: number) => {
				if (step === 1) {
					handleNext();
				} else {
					setTrialDuplicateFormStep(step);
				}
			}}
			maxHeight={600}
		>
			{trialDuplicateFormStep === 0 ? (
				<TrialDuplicateFormPart1 form={form} />
			) : (
				<TrialDuplicateFormPart2 form={form} />
			)}
		</Modal>
	);
};
