import { PayloadAction } from "@reduxjs/toolkit";

import { IProjectState } from "./project.slice";

export const projectReducers = {
	updateCurrentProject: (
		state: IProjectState,
		action: PayloadAction<string | undefined>,
	) => {
		state.currentProject = action.payload;
	},
};
