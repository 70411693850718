import React from "react";

import { DIC, EDIC_KEY } from "../../../../dictionary";
import DorianeButton from "../../../base-components/button/DorianeButton";
import DorianeModal from "../../../base-components/modal/DorianeModal";

import "./ObsRoundModalSendNotebooks.scss";

export interface IObsRoundModalSendNotebooksProps {
	onClose: () => void;
	generateNotebook: () => void;
	errors?: string[];
}
export default function ObsRoundModalSendNotebooks(
	props: IObsRoundModalSendNotebooksProps,
) {
	const hasError = props.errors && props.errors.length > 0;
	return (
		<DorianeModal
			onClose={props.onClose}
			title={DIC(EDIC_KEY.SET_OBS_ROUND_TO_READY)}
			width="500px"
		>
			<div className="ObsRoundModalSendNotebooks flex-column">
				<div>{DIC(EDIC_KEY.OBS_ROUND_SEND_NOTEBOOK_TEXT)}</div>
				<div className="text-warn">
					{DIC(EDIC_KEY.OBS_ROUND_SEND_NOTEBOOK_WARNING_TEXT)}
					<ul className="ObsRoundModalSendNotebooks-list">
						<li>{DIC(EDIC_KEY.LIST_OF_MATERIALS)}</li>
						<li>{DIC(EDIC_KEY.ASSIGNED_GROWING_AREA)}</li>
					</ul>
				</div>
				{hasError && (
					<div className="text-error">
						{DIC(EDIC_KEY.MUST_CORRECT_ERROR_TEXT)}
						<ul className="ObsRoundModalSendNotebooks-list">
							{props.errors!.map((errMessage, index) => (
								<li key={index}>{errMessage}</li>
							))}
						</ul>
					</div>
				)}
				<div className="ObsRoundModalSendNotebooks-footer">
					<DorianeButton
						dorianeStyle="primary"
						onClick={props.generateNotebook}
						disabled={hasError}
					>
						{DIC(EDIC_KEY.SEND)}
					</DorianeButton>
				</div>
			</div>
		</DorianeModal>
	);
}
