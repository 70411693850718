import React, { useCallback, useEffect, useMemo, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { EVariableScope, IVariableCore } from "shared-type";
import { formatString } from "common";

import { DIC, EDIC_KEY } from "App/dictionary";
import {
	IDorianeFilter,
	IDorianeObjectFilter,
} from "App/interfaces/filters/doriane-filter-interface";
import { apiMarketSegment } from "../../../../../redux/market-segment/market-segment.api";
import {
	apiVariables,
	variablesCustomHook,
} from "../../../../../redux/variables/variables.api";
import { getColorFromVariableScope } from "../../../../../utils";
import { IColumn } from "../doriane-data-grid/DorianeDataGrid";
import HeaderWithFilter from "../doriane-data-grid/header/HeaderWithFilter";
import ChipListCell from "../doriane-data-grid/cell/ChipListCell";
import Size from "stories/constants/Size/Size";
import store from "App/store";
import { variableAction } from "App/redux/variables/variable.slice";
import { ReduxApiError } from "App/redux/utils/errors";
import { ApiErrorSnackbar } from "App/redux/utils/api-error-snackbar/ApiErrorSnackbar";
import useAppSnackbar from "App/hooks/useAppSnackbar";
import {
	DropdownButton,
	IDropdownOption,
} from "stories/base-components/DropdownButton/DropdownButton";

export interface IVariableCoreRow extends IVariableCore {
	marketSegmentsWithNames?: { _id: string; name: string }[];
	marketSegmentRender?: string;

	limitedChoiceRender?: string;
	multiNotationRender?: string;
}
export const variableColumns: (filter?: {
	filter: IDorianeObjectFilter;
	onFilterChange: (newFilter: IDorianeFilter) => void;
	withActions?: boolean;
}) => IColumn<IVariableCoreRow>[] = (filter) => {
	// Here are the columns that will be rendered the same unconditionally if there is a filter or no.
	const unfilteredColumns: IColumn<IVariableCoreRow>[] = [
		{
			id: "colorCategory",
			getHeaderRenderer: "",
			width: Size.sm_small,
			getCellRenderer: (row) => {
				return (
					<div
						className="full-parent-size"
						style={{
							backgroundColor: getColorFromVariableScope(
								row.scope,
							),
						}}
					/>
				);
			},
			headerClassName: "DorianeDataGrid-color",
			cellClassName: "DorianeDataGrid-color",
		},
	];

	const actionColumn = (row: IVariableCoreRow) => {
		if (row.scope !== EVariableScope.CUSTOM) return "";

		// Skip the query unless the menu has been clicked.
		const [isClick, setIsClick] = useState(false);
		const { data: variableUsages } =
			apiVariables.useGetVariableUsageByIdQuery(
				!isClick
					? skipToken
					: {
							id: row._id,
							scope: EVariableScope.CUSTOM,
					  },
			);
		const isVariableUsed = useMemo(() => {
			// Set as used until query result.
			if (variableUsages === undefined) return true;
			return variableUsages.length !== 0;
		}, [variableUsages]);

		const [deleteCustomVariableById] =
			apiVariables.useDeleteCustomVariableByIdMutation();
		const [duplicateCustomVariableByIds] =
			apiVariables.useDuplicateCustomVariableByIdsMutation();
		const { enqueueSnackbarSuccess, enqueueSnackbarError } =
			useAppSnackbar();

		const actionsDropdown: IDropdownOption[] = [
			{
				label: DIC(EDIC_KEY.COMMENT),
				iconName: "comment",
				action: (event) => {
					event.stopPropagation();
					store.dispatch(
						variableAction.changeSelectedCommentId(row._id),
					);
				},
			},
			{
				label: DIC(EDIC_KEY.DUPLICATE),
				iconName: "template",
				action: async (event) => {
					event.stopPropagation();
					await duplicateCustomVariableByIds([row._id])
						.unwrap()
						.then(() => {
							enqueueSnackbarSuccess(
								formatString(
									DIC(EDIC_KEY.ENTITY_DUPLICATED),
									DIC(EDIC_KEY.VARIABLE),
								),
							);
						})
						.catch((err: ReduxApiError) => {
							console.warn(err);
							enqueueSnackbarError(
								<ApiErrorSnackbar error={err} />,
							);
						});
				},
			},
			{
				label: DIC(EDIC_KEY.DELETE),
				disabled: isVariableUsed,
				iconName: "bin",
				action: async (event) => {
					event.stopPropagation();
					await deleteCustomVariableById(row._id)
						.unwrap()
						.then(() => {
							enqueueSnackbarSuccess(
								formatString(
									DIC(EDIC_KEY.ENTITY_DELETED),
									DIC(EDIC_KEY.VARIABLE),
								),
							);
						})
						.catch((err: ReduxApiError) => {
							console.warn(err);
							enqueueSnackbarError(
								<ApiErrorSnackbar error={err} />,
							);
						});
				},
			},
		];

		return (
			<DropdownButton
				btn={{
					iconButton: true,
					icon: { name: "dots-vertical" },
					magicIcon: true,
					buttonType: "tertiary",
					onClick: () => setIsClick(true),
				}}
				options={actionsDropdown}
			/>
		);
	};

	if (!filter) {
		const columns: IColumn<IVariableCoreRow>[] = [
			...unfilteredColumns,
			{
				id: "identifier",
				width: "150px",
				getHeaderRenderer: DIC(EDIC_KEY.ID),
				getCellRenderer: (row) => row.identifier,
			},
			{
				id: "name",
				width: "150px",
				getHeaderRenderer: DIC(EDIC_KEY.NAME),
				getCellRenderer: (row) => row.name.en,
			},
			{
				id: "short name",
				width: "150px",
				getHeaderRenderer: DIC(EDIC_KEY.SHORT_NAME),
				getCellRenderer: (row) => row.shortName,
			},
			{
				id: "description",
				width: "250px",
				getHeaderRenderer: DIC(EDIC_KEY.DESCRIPTION),
				getCellRenderer: (row) => row.description ?? "",
			},
			{
				id: "comments",
				width: "150px",
				getHeaderRenderer: DIC(EDIC_KEY.COMMENTS),
				getCellRenderer: (row) => row.comments ?? "",
			},
			{
				id: "varType",
				width: "100px",
				getHeaderRenderer: DIC(EDIC_KEY.TYPE),
				getCellRenderer: (row) => row.type.toString(),
			},
			{
				id: "unit",
				width: "140px",
				getHeaderRenderer: DIC(EDIC_KEY.UNIT),
				getCellRenderer: (row) => row.unit ?? "",
			},
			{
				id: "limitedChoice",
				width: "140px",
				getHeaderRenderer: DIC(EDIC_KEY.LIMITED_CHOICE),
				getCellRenderer: (row) =>
					(row.limitedChoices ? DIC(EDIC_KEY.YES) : DIC(EDIC_KEY.NO)),
			},
			{
				id: "multiNotation",
				width: "140px",
				getHeaderRenderer: DIC(EDIC_KEY.MULTI_NOTATIONS),
				getCellRenderer: (row) =>
					(row.multiNotation ? DIC(EDIC_KEY.YES) : DIC(EDIC_KEY.NO)),
			},
			{
				id: "marketSegment",
				minWidth: "300px",
				getHeaderRenderer: DIC(EDIC_KEY.MARKET_SEGMENTS),
				getCellRenderer: (row) => row.marketSegments?.join(", ") ?? "",
			},
		];

		return columns;
	}
	const columnsWithFilter: IColumn<IVariableCoreRow>[] = [
		...unfilteredColumns,
		{
			id: "identifier",
			width: "150px",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "identifier",
						type: "string",
						value: filter.filter.identifier?.value,
					}}
					label={DIC(EDIC_KEY.ID)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (row) => row.identifier,
		},
		{
			id: "name",
			width: "150px",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "name.en",
						type: "string",
						value: filter.filter.name?.value,
					}}
					label={DIC(EDIC_KEY.NAME)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (row) => row.name.en,
		},
		{
			id: "shortName",
			width: "150px",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "shortName",
						type: "string",
						value: filter.filter.shortName?.value,
					}}
					label={DIC(EDIC_KEY.SHORT_NAME)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (row) => row.shortName,
		},
		{
			id: "description",
			width: "250px",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "description",
						type: "string",
						value: filter.filter.description?.value,
					}}
					label={DIC(EDIC_KEY.DESCRIPTION)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (row) => row.description ?? "",
		},
		{
			id: "comments",
			width: "150px",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "comments",
						type: "string",
						value: filter.filter.comments?.value,
					}}
					label={DIC(EDIC_KEY.COMMENTS)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (row) => row.comments ?? "",
		},
		{
			id: "type",
			width: "100px",

			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "type",
						type: "string",
						value: filter.filter.varType?.value,
					}}
					label={DIC(EDIC_KEY.TYPE)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (row) => row.type.toString(),
		},
		{
			id: "unit",
			width: "140px",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "unit",
						type: "string",
						value: filter.filter.unit?.value,
					}}
					label={DIC(EDIC_KEY.UNIT)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (row) => row.unit ?? "",
		},
		{
			id: "limitedChoice",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "limitedChoiceRender",
						type: "string",
						value: filter.filter.limitedChoiceRender?.value,
					}}
					label={DIC(EDIC_KEY.LIMITED_CHOICE)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			width: "140px",
			getCellRenderer: (row) => row.limitedChoiceRender ?? "",
		},
		{
			id: "multiNotation",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "multiNotationRender",
						type: "string",
						value: filter.filter.multiNotationRender?.value,
					}}
					label={DIC(EDIC_KEY.MULTI_NOTATIONS)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			width: "140px",
			getCellRenderer: (row) => row.multiNotationRender ?? "",
		},
		{
			id: "marketSegment",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "marketSegmentsWithNamesConcat",
						type: "string",
						value: filter.filter.marketSegmentsWithNamesConcat
							?.value,
					}}
					label={DIC(EDIC_KEY.MARKET_SEGMENTS)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			minWidth: "300px",
			getCellRenderer: (row) => (
				<ChipListCell items={row.marketSegmentsWithNames} />
			),
		},
	];

	const columnsWithAction: IColumn<IVariableCoreRow>[] = [
		columnsWithFilter[0],
		// Actions placed here because after color column
		{
			id: "actions",
			getHeaderRenderer: "",
			getCellRenderer: (row) => actionColumn(row),
			minWidth: "48px",
		},
		...columnsWithFilter.slice(1),
	];

	return filter.withActions ? columnsWithAction : columnsWithFilter;
};

// hooks only for the variables row ----------------------------------------------------------
/**
 * hook that creates a function that can fetch the market segments for a variable
 */
function useGetMarketSegmentName() {
	const [marketSegmentsRequest] =
		apiMarketSegment.useLazyGetMarketSegmentByIdQuery();

	const fetchMarketSegments = useCallback(
		async (marketSegmentsIds: string[]) => {
			if (!marketSegmentsIds) {
				return [];
			}
			const marketSegments: { _id: string; name: string }[] =
				await Promise.allSettled(
					marketSegmentsIds.map((marketSegment) =>
						marketSegmentsRequest(marketSegment).unwrap(),
					),
				).then((resp) => {
					return resp.map((resp, index) => {
						if (
							resp.status === "fulfilled" &&
							resp.value &&
							resp.value?.name &&
							resp.value?._id
						) {
							return {
								name: resp.value.name,
								_id: resp.value._id,
							};
						}
						return {
							name: `unknown (${marketSegmentsIds[index]})`,
							_id: marketSegmentsIds[index],
						};
					});
				});

			return marketSegments;
		},
		[marketSegmentsRequest],
	);
	return { fetchMarketSegments };
}

/**
 * hook that return a function that can transform a variable to a variable row
 */
export function useGetTransformerVariableCore() {
	const { fetchMarketSegments } = useGetMarketSegmentName();
	const transformVarToVarRow = useCallback(
		async (variable: IVariableCore) => {
			const marketSegments = await fetchMarketSegments(
				variable.marketSegments?.map((ms) => ms._id) || [],
			);
			return {
				...variable,
				marketSegmentsWithNames: marketSegments,
				marketSegmentsWithNamesConcat: marketSegments
					.map((ms) => ms.name)
					.join("¤"),

				limitedChoiceRender: variable.limitedChoices
					? DIC(EDIC_KEY.YES)
					: DIC(EDIC_KEY.NO),
				multiNotationRender: variable.multiNotation
					? DIC(EDIC_KEY.YES)
					: DIC(EDIC_KEY.NO),
			};
		},
		[fetchMarketSegments],
	);
	return { transformVarToVarRow };
}

/**
 * Get the other variables formate for the data table
 */
export function useGetOtherVarRow() {
	const { transformVarToVarRow } = useGetTransformerVariableCore();
	const { data: otherVarList } =
		variablesCustomHook.useGetOthersVariableListDeserialized();
	const [otherVarListRow, setOtherVarListRow] = useState<IVariableCoreRow[]>(
		[],
	);

	useEffect(() => {
		const transformAllVariable = async () => {
			const variablesRow = await Promise.all(
				otherVarList?.map(async (variable: IVariableCore) =>
					transformVarToVarRow(variable),
				),
			);
			setOtherVarListRow(variablesRow || []);
		};
		transformAllVariable();
	}, [otherVarList, transformVarToVarRow]);
	return { data: otherVarListRow };
}

/**
 * Get the ontology variables formate for the data table
 */
export function useGetOntologyVarRow() {
	const { transformVarToVarRow } = useGetTransformerVariableCore();
	const { data: ontologyVarList } =
		variablesCustomHook.useGetOntologyVariableListDeserialized();
	const [ontologyVarListRow, setOntologyVarListRow] = useState<
		IVariableCoreRow[]
	>([]);

	useEffect(() => {
		const transformAllVariable = async () => {
			const variablesRow = await Promise.all(
				ontologyVarList?.map(async (variable: IVariableCore) =>
					transformVarToVarRow(variable),
				),
			);
			setOntologyVarListRow(variablesRow || []);
		};
		transformAllVariable();
	}, [ontologyVarList, transformVarToVarRow]);
	return { data: ontologyVarListRow };
}
