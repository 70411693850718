import React, { useEffect, useState } from "react";

import { EGermplasmLevel, IGenotype, ILot, IResource } from "shared-type";

import TopMenu from "App/components/base-components/menu/top-menu/TopMenu";
import { DIC, EDIC_KEY } from "App/dictionary";
import { apiResource } from "App/redux/attachment/resource.api";
import { apiTrial } from "App/redux/experiment/trial/trial.api";
import { apiGermplasm } from "App/redux/germplasm/germplasm.api";
import { apiVariables } from "App/redux/variables/variables.api";
import { Full } from "assets";
import DorianeButton from "../../base-components/button/DorianeButton";
import InputText from "../../base-components/input-text/InputText";
import DorianeDrawer from "../../base-components/modal/DorianeDrawer";
import TitledTag from "../../base-components/tag/titled-tag/TitledTag";
import PictureModal from "../modal/PictureModal";

import "./PictureDetailDrawer.scss";

export interface IPictureDetailDrawerProps {
	onClose: () => void;
	resource: IResource;
	errors?: string[];
}
export default function PictureDetailDrawer(props: IPictureDetailDrawerProps) {
	const hasError = props.errors && props.errors.length > 0;
	const [isPictureShow, setIsPictureShow] = useState<boolean>(false);
	const [commentary, setCommentary] = useState<string>("");

	useEffect(() => {
		setCommentary(props.resource.commentary ?? "");
	}, [props.resource]);

	const { data: variable } = apiVariables.useGetVariableByIdQuery({
		id: props.resource.metadata.variableId,
		scope: props.resource.metadata.variableScope,
	});

	const { data: material } = apiGermplasm.useGetGermplasmByIdQuery({
		id: props.resource.metadata.materialId,
		level: props.resource.metadata.materialLevel,
	});

	const { data: trial } = apiTrial.useGetTrialByIdQuery(
		props.resource.metadata.trialId,
	);

	const [updateResource] = apiResource.useUpdateResourceMutation();

	return (
		<DorianeDrawer
			onClose={props.onClose}
			title={DIC(EDIC_KEY.PICTURE_DETAILS)}
			width="500px"
		>
			<TopMenu
				saveButton={{
					onClick: async () => {
						await updateResource({
							id: props.resource._id,
							update: {
								commentary,
							},
						});
					},
					isDisabled: hasError,
				}}
			/>
			<div className="PictureDetailDrawer flex-column">
				<div className="PictureDetailDrawer-list">
					<div className="PictureDetailDrawer-picture-preview">
						<img
							className="PictureDetailDrawer-picture-preview-img"
							src={props.resource.url}
							onClick={() => setIsPictureShow(true)}
						/>
						<DorianeButton
							dorianeStyle="quaternary"
							className="PictureDetailDrawer-picture-preview-img-button"
							onClick={() => setIsPictureShow(true)}
							disabled={hasError}
						>
							<img src={Full} />
						</DorianeButton>
					</div>

					<TitledTag
						title={DIC(EDIC_KEY.TRIAL)}
						tagValue={trial?.name}
						className="PictureDetailDrawer-tag"
					/>
					<TitledTag
						title={DIC(EDIC_KEY.MATERIAL)}
						className="PictureDetailDrawer-tag"
						tagValue={
							props.resource.metadata.materialLevel ===
							EGermplasmLevel.GENOTYPE
								? (material as IGenotype)?.name
								: (material as ILot)?.lotName
						}
					/>
					<TitledTag
						title={DIC(EDIC_KEY.VARIABLE)}
						className="PictureDetailDrawer-tag"
						tagValue={variable?.name.en}
					/>

					<InputText
						label={DIC(EDIC_KEY.DESCRIPTION)}
						onChange={(event) => {
							setCommentary(event?.target.value);
						}}
						value={commentary}
						multiline
						minRows={4}
					/>
				</div>
			</div>

			{isPictureShow && (
				<PictureModal
					url={props.resource.url}
					onClose={() => setIsPictureShow(false)}
				></PictureModal>
			)}
		</DorianeDrawer>
	);
}
