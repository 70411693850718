import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useBloomeoAuth } from "../auth/AuthContext";
import { useGetCurrentTenant } from "../redux/tenant/hooks";
import { ReduxApiError } from "../redux/utils/errors";

export const RoutesGuard = () => {
	const { isAuth } = useBloomeoAuth();

	// For alert the user if he is login in bad environment
	const tenantRequest = useGetCurrentTenant();

	useEffect(() => {
		if (tenantRequest.isError && tenantRequest.error) {
			const error = tenantRequest.error as ReduxApiError;
			if (error.statusCode === 404) {
				alert("Unable to access to your tenant");
			} else {
				alert(
					"unknown error: " + JSON.stringify(error.responsePayload),
				);
			}
		}
	}, [tenantRequest.isError, tenantRequest.error]);

	if (isAuth) {
		return <Outlet />;
	}
	return <Navigate to="/login" />;
};
