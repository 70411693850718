import React, { useState } from "react";
import { useParams } from "react-router-dom";

import Box, {
	EPaddingSize,
} from "../../../../../../components/base-components/placement/box/Box";
import METTrialsEdition from "../../../../../../components/experiment/met/edition/trials-edition/METTrialsEdition";
import METTrialsList from "../../../../../../components/experiment/met/edition/trials/METTrialsList";

export function METTrialsPage() {
	const { metId } = useParams();
	const [isInEdition, setIsInEdition] = useState(false);

	const [tableView, setTableView] = useState(false);

	if (!metId) {
		return <div>something wrong has happened</div>;
	}
	return (
		<Box fullParentSize padding={EPaddingSize.MEDIUM}>
			{!isInEdition ? (
				<METTrialsList
					metId={metId}
					onChangeToEditing={() => setIsInEdition(true)}
					tableView={tableView}
					setTableView={setTableView}
				/>
			) : (
				<METTrialsEdition
					metId={metId}
					onEditionEnd={() => setIsInEdition(false)}
					setTableView={setTableView}
				/>
			)}
		</Box>
	);
}
