import { useCallback } from "react";

import { globalAction, IGlobalModal } from "App/redux/global/global.slice";
import store from "App/store";

export default function useGlobalModal() {
	const openModal = useCallback((modalInfos: IGlobalModal) => {
		return store.dispatch(globalAction.selectGlobalModal(modalInfos));
	}, []);
	const closeModal = useCallback(() => {
		return store.dispatch(globalAction.selectGlobalModal(undefined));
	}, []);

	return {
		openModal,
		closeModal,
	};
}
