"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_OBS_ROUND_FIELDS = void 0;
exports.CREATE_OBS_ROUND_FIELDS = [
    "description",
    "endDate",
    "eventOption",
    "experimentId",
    "identifier",
    "isTemplate",
    "label",
    "name",
    "stage",
    "startDate",
    "team",
    "type",
    "variableGroupId",
];
