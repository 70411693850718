import React from "react";

import { EUserRole, IUser } from "shared-type";

import { IColumn } from "App/components/base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import { DIC, EDIC_KEY } from "App/dictionary";
import FlexBox from "App/components/base-components/placement/flex-box/FlexBox";
import Box from "App/components/base-components/placement/box/Box";
import Size from "stories/constants/Size/Size";
import InitialsUser from "stories/base-components/Initials/mostUsed/InitialsUser";
import { Typography } from "stories/base-components/Typography/Typography";

export const userColumns = (): IColumn<IUser>[] => [
	{
		id: "firstname",
		getHeaderRenderer: DIC(EDIC_KEY.FIRST_AND_LASTNAME),
		getCellRenderer: (row) => (
			<FlexBox alignItems="center" height="100%" paddingRight={Size.sm}>
				<Box
					title={
						row.role === EUserRole.ADMIN
							? DIC(EDIC_KEY.ADMIN)
							: DIC(EDIC_KEY.OBSERVER)
					}
				>
					<InitialsUser user={row} />
				</Box>
				<Box paddingLeft={Size.md} overflow="hidden">
					<Typography
						variant="body2Medium"
						text={row.firstName + " " + row.lastName}
						noWrap
					/>
				</Box>
			</FlexBox>
		),
		width: "300px",
	},
	{
		id: "mail",
		getHeaderRenderer: DIC(EDIC_KEY.EMAIL),
		getCellRenderer: (row) => row.mail + "",
		width: "300px",
	},
	{
		id: "growingArea",
		getHeaderRenderer: DIC(EDIC_KEY.GROWING_AREA),
		getCellRenderer: (row) => "",
	},
];
