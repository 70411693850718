import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import React from "react";

import DorianeButton from "./DorianeButton";

interface BackButtonProps {
	onBack: () => void;
}

export default function BackButton(props: BackButtonProps) {
	return (
		<DorianeButton onClick={() => props.onBack()} dorianeStyle="quaternary">
			<ArrowBackIosNewIcon />
		</DorianeButton>
	);
}
