import React from "react";
import { Outlet } from "react-router-dom";

import TopBar from "App/components/top-bar/TopBar";
import SideMenu from "../../components/side-menu/SideMenu";
import { apiExpGantt } from "../../redux/experiment-statistics/exp-gantt.api";
import GlobalModal from "App/components/global-modal/GlobalModal";

import "./MainLayoutPage.scss";

export default function MainLayoutPage() {
	// WARN Fetch in background the Gantt - NEED TO REMOVE AFTER DEMO
	const { isSuccess } = apiExpGantt.useComputeInterExpGanttInfoQuery();

	return (
		<>
			<div className="MainLayoutPage-container">
				<SideMenu />
				<div className="MainLayoutPage-containerRight">
					<TopBar />
					<div className="MainLayoutPage-page flex-column">
						<Outlet />
					</div>
					<GlobalModal />
				</div>
			</div>
		</>
	);
}
