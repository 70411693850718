"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMaterialSource = exports.EGermplasmLevel = void 0;
var EGermplasmLevel;
(function (EGermplasmLevel) {
    EGermplasmLevel["LOT"] = "LOT";
    EGermplasmLevel["GENOTYPE"] = "GENOTYPE";
})(EGermplasmLevel = exports.EGermplasmLevel || (exports.EGermplasmLevel = {}));
var EMaterialSource;
(function (EMaterialSource) {
    EMaterialSource["PRIVATE"] = "PRIVATE";
    EMaterialSource["GERMPLASM"] = "GERMPLASM";
})(EMaterialSource = exports.EMaterialSource || (exports.EMaterialSource = {}));
