import { Theme } from "@glideapps/glide-data-grid";

import styles from "../../../../../sassTheme";

export const dorianeDatagridTheme: Partial<Theme> = {
	accentColor: "#4F5DFF",
	accentFg: "#FFFFFF",
	accentLight: "rgba(62, 116, 253, 0.1)",

	textDark: styles.black,
	textMedium: "#737383",
	textLight: "#B2B2C0",
	textBubble: "#313139",

	bgIconHeader: "#737383",
	fgIconHeader: "#FFFFFF",
	textHeader: styles.black,
	textGroupHeader: styles.black,
	textHeaderSelected: "#FFFFFF",

	bgCell: "#FFFFFF",
	bgCellMedium: "#FAFAFB",
	bgHeader: "#F7F7F8",
	bgHeaderHasFocus: "#E9E9EB",
	bgHeaderHovered: "#EFEFF1",

	bgBubble: "#EDEDF3",
	bgBubbleSelected: "#FFFFFF",

	bgSearchResult: "#fff9e3",

	borderColor: "rgba(115, 116, 131, 0.16)",
	horizontalBorderColor: "rgba(115, 116, 131, 0.16)",
	drilldownBorder: "rgba(0, 0, 0, 0)",

	linkColor: "#4F5DFF",

	cellHorizontalPadding: 8,
	cellVerticalPadding: 3,

	headerFontStyle: "600 15px",
	baseFontStyle: "14px",
	editorFontSize: "14px",
	lineHeight: 1.4,
	fontFamily:
		"DidactGothic-Regular, Inter, Roboto, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, noto, arial, sans-serif",
};

export const dorianeDatagridOddCellBackground = (
	row: number,
): Partial<Theme> | undefined => {
	if (row % 2 !== 0) {
		const theme: Partial<Theme> = {
			bgCell: styles.grey,
		};
		return theme;
	}
	return undefined;
};
