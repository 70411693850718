import React from "react";

import CustomCard, { ICustomCardProps } from "../CustomCard";

import styles from "../../../../../sassTheme";
import "./ImgBannerCard.scss";

interface ISideBannerCard {
	imageSrc: string;
	logoSrc: string;
	logoPadding?: string;
}

interface IImgBannerCardProps extends ICustomCardProps {
	sideBanner: ISideBannerCard;
	backgroundColor?: string;
}

export default function ImgBannerCard(props: IImgBannerCardProps) {
	return (
		<CustomCard
			onClick={props.onClick}
			className={
				props.className
					? props.className + " ImgBannerCard-container "
					: "ImgBannerCard-container"
			}
			disabledClick={props.disabledClick}
			style={props.style}
			isSelected={props.isSelected}
		>
			<div
				className="ImgBannerCard-side-banner-image"
				style={{
					backgroundImage: `url("${props.sideBanner.imageSrc}")`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
				}}
			>
				<div
					className="ImgBannerCard-side-banner-image-layer full-parent-size"
					style={{ backgroundColor: props.backgroundColor }}
				>
					<img
						className="ImgBannerCard-side-banner-logo"
						style={{
							backgroundColor: styles.white, // To change with status when refactoring the cards.
							border:
								styles["thin-border"] +
								styles["tertiary-background-color"], // To change with status when refactoring the cards.
							padding: props.sideBanner.logoPadding,
						}}
						src={props.sideBanner.logoSrc}
					/>
				</div>
			</div>

			<div className="ImgBannerCard-body">{props.children}</div>
		</CustomCard>
	);
}
