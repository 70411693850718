import { createSlice } from "@reduxjs/toolkit";
import { IGenotype, ILot, PageInformation } from "shared-type";
import {
	germplasmFetchReducerBuilder,
	germplasmThunk,
} from "./germplasm.fetch-reducers";

export interface IGermplasmState {
	genotype?: {
		genotypeList: IGenotype[];
		paginationInfo: PageInformation;
	};
	lot?: {
		lotList: ILot[];
		paginationInfo: PageInformation;
	};
}

function createInitialState(): IGermplasmState {
	return {};
}

const germplasmSlice = createSlice({
	name: "germplasm",
	initialState: createInitialState(),
	reducers: {},
	extraReducers: germplasmFetchReducerBuilder,
});

export const germplasmReducer = germplasmSlice.reducer;
export const germplasmAction = { ...germplasmSlice.actions, ...germplasmThunk };
