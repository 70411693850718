import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { IGoogleMapCredential } from "shared-type";
import { AbstractRequest } from "../abstract-request";

export class CredentialRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/credentials";
	}

	getMapCredential(
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<IGoogleMapCredential, any>> {
		return this.axios.get<IGoogleMapCredential>(this.baseUrl + "/map", config);
	}
}
