import { IUser } from "shared-type";

import { IUserForm } from "./UserForm";

export function userFormToObject(form: Partial<IUserForm>): Partial<IUser> {
	return {
		color: form.color,
		firstName: form.firstName,
		lastName: form.lastName,
		mail: form.mail,
		role: form.role,
	};
}

export function userObjectToForm(user: Partial<IUser>): Partial<IUserForm> {
	return {
		color: user.color,
		firstName: user.firstName,
		lastName: user.lastName,
		mail: user.mail,
		role: user.role,
	};
}
