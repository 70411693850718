import React from "react";

import { concatClassName } from "common";

import { StoryTheme } from "stories/StoryTheme";
import FlexBox from "App/components/base-components/placement/flex-box/FlexBox";
import Box from "App/components/base-components/placement/box/Box";

import "./colorSelector.scss";

export type IColorSelector = {
	color: string;
	onColorChange: (color: string) => void;
};

export const ColorSelector = (colorSelector: IColorSelector) => {
	const colorsToSelect = [
		"#DE3618",
		"#EFC81D",
		"#1EC39F",
		"#2075D9",
		"#9A68DD",
	];
	return (
		<StoryTheme>
			<FlexBox>
				{colorsToSelect.map((color: string, index: number) => (
					<Box
						key={"color_" + index}
						className={concatClassName(
							"colorDotContainer",
							colorSelector.color === color
								? "colorDotContainerSelected"
								: "",
						)}
						onClick={() => colorSelector.onColorChange(color)}
					>
						<Box
							className="colorDot"
							style={{ backgroundColor: color }}
						></Box>
					</Box>
				))}
			</FlexBox>
		</StoryTheme>
	);
};
