import { useMemo, useState } from "react";

import {
	applyDorianeFilter,
	IDorianeFilter,
	IDorianeObjectFilter,
} from "../../../../interfaces/filters/doriane-filter-interface";
import { IColumn } from "./doriane-data-grid/DorianeDataGrid";

export function useGetFilteredGridData<T>(props: {
	columns: (
		filter: {
			filter: IDorianeObjectFilter;
			onFilterChange: (newFilter: IDorianeFilter) => void;
			withActions?: boolean;
		},
		withActions?: boolean,
	) => IColumn<T>[];
	rows: T[];
	withActions?: boolean;
}) {
	const [filter, setFilter] = useState<IDorianeObjectFilter>({});

	const handleFilterChange = (newFilter: IDorianeFilter) => {
		setFilter((old) => {
			return { ...old, [newFilter.key]: newFilter };
		});
	};

	const rowsToDisplay = useMemo(() => {
		return applyDorianeFilter(props.rows, filter);
	}, [props.rows, filter]);

	const columns = useMemo(
		() =>
			props.columns({
				filter,
				onFilterChange: handleFilterChange,
				withActions: props.withActions,
			}),
		[filter, props],
	);

	return { columns, rowsToDisplay: rowsToDisplay };
}
