import { getter } from "@progress/kendo-react-common";
import {
	GridCustomCellProps,
	GridCustomHeaderCellProps,
} from "@progress/kendo-react-grid";

import React, { createContext, useContext } from "react";

import { Checkbox } from "../../../../../../stories/base-components/Checkbox/Checkbox";
import FlexBox from "../../../placement/flex-box/FlexBox";
import { BaseCell } from "./BaseCell";

export const GridCheckboxContext = createContext<{
	header: {
		onSelect:(columnId: string, isSelected: boolean) => void;
		isSelected: (columnId: string) => boolean;
	};
	row: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onSelectRow: (columnId: string, row: any, isSelected: boolean) => void;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		isDisabled: (columnId: string, row: any) => boolean;
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}>({} as any);

export const CheckboxCustomCell = (props: GridCustomCellProps) => {
	const field = props.field || "selected";
	const columnId = props.id || "selected";

	const selectContext = useContext(GridCheckboxContext);
	const fieldGetter = getter(field);
	return (
		<BaseCell {...props}>
			<FlexBox
				fullParentSize
				justifyContent="center"
				alignItems="center"
			>
				<Checkbox
					checked={fieldGetter(props.dataItem)}
					onChange={(_, check) => {
						selectContext.row.onSelectRow(
							columnId,
							props.dataItem,
							check,
						);
					}}
					disabled={selectContext.row.isDisabled?.(
						columnId,
						props.dataItem,
					)}
				></Checkbox>
			</FlexBox>
		</BaseCell>
	);
};

export const CheckboxHeaderCell = (props: GridCustomHeaderCellProps) => {
	const columnId = props.columnMenuWrapperProps.column.id || "selected";
	const selectContext = useContext(GridCheckboxContext);

	return (
		<FlexBox fullParentSize justifyContent="center" alignItems={"center"}>
			<Checkbox
				checked={selectContext.header.isSelected(columnId)}
				onChange={(event, check) =>
					selectContext.header.onSelect(columnId, check)
				}
			></Checkbox>
		</FlexBox>
	);
};
