import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SnackbarProvider } from "notistack";
import React, { useMemo } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, useRoutes } from "react-router-dom";

import { ReduxStore } from "./redux";
import mainRouter from "./router/MainRouter";
import store from "./store";
import { theme } from "./Theme";

import "./App.scss";
import AuthContextProvider from "./auth/AuthContext";

ReduxStore.injectStore(store);
function App() {
	const routesDefinition = useMemo(() => mainRouter(), []);
	const routes = useRoutes(routesDefinition);
	return routes;
}

const AppWrapper = () => (
	<BrowserRouter>
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Provider store={store}>
				<AuthContextProvider>
					<ThemeProvider theme={theme}>
						<SnackbarProvider maxSnack={3}>
							<App />
							{/* For Glide data grid (do not remove protal) */}
							<div id="portal"></div>
						</SnackbarProvider>
					</ThemeProvider>
				</AuthContextProvider>
			</Provider>
		</LocalizationProvider>
	</BrowserRouter>
);
export default AppWrapper;
