import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { formatString } from "common";

import { DIC, EDIC_KEY } from "../../../../dictionary";
import useAppSnackbar from "../../../../hooks/useAppSnackbar";
import { apiGrowingArea } from "../../../../redux/growing-area/growing-area.api";
import { ReduxApiError } from "../../../../redux/utils/errors";
import { PATH_GROWING_AREA } from "../../../../routes";
import { ApiErrorSnackbar } from "../../../../redux/utils/api-error-snackbar/ApiErrorSnackbar";
import { useRemoteGrowingArea } from "./remoteGrowingArea.hook";
import {
	growingAreaFormToObject,
	IGrowingAreaForm,
} from "../../../form/growing-area";

export const useGrowingAreaEditAction = (growingAreaId?: string) => {
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();
	const nav = useNavigate();

	const [deleteGrowingArea] =
		apiGrowingArea.useDeleteGrowingAreaByIdMutation();
	const [updateGrowingAreaUsed] =
		apiGrowingArea.useUpdateUsedGrowingAreaMutation();
	const [updateGrowingAreaUnused] =
		apiGrowingArea.useUpdateUnusedGrowingAreaMutation();

	const { growingAreaFetched, isGrowingAreaUsed } =
		useRemoteGrowingArea(growingAreaId);

	const handleDelete = useCallback(() => {
		deleteGrowingArea(growingAreaId)
			.unwrap()
			.then(() => {
				enqueueSnackbarSuccess(
					formatString(
						DIC(EDIC_KEY.ENTITY_DELETED),
						DIC(EDIC_KEY.GROWING_AREA),
					),
				);
				nav(PATH_GROWING_AREA);
			})
			.catch((err: ReduxApiError) => {
				console.warn(err);
				enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
			});
	}, [
		deleteGrowingArea,
		enqueueSnackbarError,
		enqueueSnackbarSuccess,
		growingAreaId,
		nav,
	]);

	const handleSaveForm = useCallback(
		(data: IGrowingAreaForm, saveCallback: () => void) => {
			if (!growingAreaFetched) return;

			const growingAreaForm = growingAreaFormToObject(data);

			const request = isGrowingAreaUsed
				? updateGrowingAreaUsed
				: updateGrowingAreaUnused;

			request({
				id: growingAreaFetched._id,
				update: growingAreaForm,
			})
				.unwrap()
				.then(() => {
					saveCallback();
					enqueueSnackbarSuccess(
						formatString(
							DIC(EDIC_KEY.ENTITY_UPDATED),
							DIC(EDIC_KEY.GROWING_AREA),
						),
					);
				})
				.catch((err: ReduxApiError) => {
					console.warn(err);
					enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
				});
		},
		[
			enqueueSnackbarError,
			enqueueSnackbarSuccess,
			growingAreaFetched,
			isGrowingAreaUsed,
			updateGrowingAreaUnused,
			updateGrowingAreaUsed,
		],
	);

	const handleSaveComment = useCallback(
		async (comments: string) => {
			if (!growingAreaFetched) return;

			const request = isGrowingAreaUsed
				? updateGrowingAreaUsed
				: updateGrowingAreaUnused;

			await request({
				id: growingAreaFetched._id,
				update: { comments: comments },
			}).unwrap();
		},
		[
			growingAreaFetched,
			isGrowingAreaUsed,
			updateGrowingAreaUnused,
			updateGrowingAreaUsed,
		],
	);

	return { handleDelete, handleSaveForm, handleSaveComment };
};
