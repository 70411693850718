import { AbstractRequest } from "../abstract-request";
import {
	ITask,
	ITaskList,
	ITaskIdOptional,
	IActionResult,
	IContext,
	PaginateResponse,
} from "shared-type";
import { AxiosInstance, AxiosResponse } from "axios";

export class TaskRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/tasks";
	}

	getTaskList(): Promise<AxiosResponse<PaginateResponse<ITaskList>, any>> {
		return this.axios.get<PaginateResponse<ITaskList>>(`${this.baseUrl}/`);
	}

	getTask(taskId: string): Promise<AxiosResponse<ITask, any>> {
		return this.axios.get<ITask>(`${this.baseUrl}/${taskId}`);
	}

	doTask(
		taskId: string,
		context: IContext
	): Promise<AxiosResponse<IActionResult, any>> {
		return this.axios.post<
			IActionResult,
			AxiosResponse<IActionResult, any>,
			IContext
		>(`${this.baseUrl}/do/${taskId}`, context);
	}

	doTaskObj(
		task: ITaskIdOptional,
		context: IContext
	): Promise<AxiosResponse<IActionResult, any>> {
		return this.axios.post<
			IActionResult,
			AxiosResponse<IActionResult, any>,
			{ task: ITaskIdOptional; context: IContext }
		>(`${this.baseUrl}/doObject`, { task, context });
	}

	save(task: ITaskIdOptional): Promise<AxiosResponse<void, any>> {
		return this.axios.post<void, AxiosResponse<void, any>, ITaskIdOptional>(
			`${this.baseUrl}/save`,
			task
		);
	}

	delete(taskId: string): Promise<AxiosResponse<void, any>> {
		return this.axios.post<void>(`${this.baseUrl}/delete/${taskId}`);
	}
}
