import React, { useState } from "react";

import { IUser } from "shared-type";

import { DIC, EDIC_KEY } from "../../../dictionary";
import {
	IDorianeFilter,
	IDorianeObjectFilter,
} from "../../../interfaces/filters/doriane-filter-interface";
import { apiUserCustomHook } from "../../../redux/user/user.api";
import { userColumnsForSelection } from "../../base-components/data-grid/doriane-data-grid/default-grid-columns/UserColumns";
import DorianeDataGrid from "../../base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import DorianeDrawer from "../../base-components/modal/DorianeDrawer";

interface SelectUserGAModalProps {
	onClose: () => void;
	currentUserIds: string[];
	onUserCheck: (user: IUser, checked: boolean) => void;
	isGrowingAreaUsed?: boolean;
	initialUserIds: string[]; // User before edit
}

export default function SelectUserGAModal(props: SelectUserGAModalProps) {
	const [filter, setFilter] = useState<IDorianeObjectFilter>({});
	const { data: fetchedUserList } =
		apiUserCustomHook.useGetUsersWithFilter(filter);

	const handleFilterChange = (newFilter: IDorianeFilter) => {
		setFilter((old) => {
			return { ...old, [newFilter.key]: newFilter };
		});
	};

	const isUserCheck = (user: IUser) => {
		const found = props.currentUserIds.find((userId) => userId === user.id);
		return found !== undefined;
	};

	const getDisplayUser = () => {
		if (props.isGrowingAreaUsed && fetchedUserList) {
			return fetchedUserList.filter((user) => {
				const found = props.initialUserIds.find(
					(initialUserId) => initialUserId === user.id,
				);
				return found === undefined;
			});
		}
		return fetchedUserList;
	};

	return (
		<DorianeDrawer
			onClose={props.onClose}
			title={DIC(EDIC_KEY.USERS)}
			takeRemainingSpace
		>
			<DorianeDataGrid
				columns={userColumnsForSelection(
					{
						filter: filter,
						onFilterChange: handleFilterChange,
					},
					{ isCheck: isUserCheck, onCheck: props.onUserCheck },
				)}
				rows={getDisplayUser() || []} // Beware that only admin and observers are present in the list
			/>
		</DorianeDrawer>
	);
}
