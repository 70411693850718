import React from "react";

import { IVariableCore } from "shared-type";

import { DIC, EDIC_KEY } from "App/dictionary";
import { getColorFromVariableScope } from "../../../../../utils";
import { CheckBoxColumn } from "../columns/CheckBoxColumn";
import { TickMarkColumn } from "../columns/TickMarkColumn";
import { IColumn } from "../doriane-data-grid/DorianeDataGrid";
import Box from "App/components/base-components/placement/box/Box";
import Colors from "stories/constants/Colors/Colors";
import { Typography } from "stories/base-components/Typography/Typography";
import Size from "stories/constants/Size/Size";

export interface ITemplateObsVarColumnsProps {
	displayMode: "checkbox" | "tickMark";
	isTrialCheck: (row: IVariableCore) => boolean;
	isMaterialCheck: (row: IVariableCore) => boolean;
	onTrialCheck?: (row: IVariableCore, check: boolean) => void;
	onMaterialCheck?: (row: IVariableCore, check: boolean) => void;
	hiddenColorStripe?: boolean;
	checkDisabled?: boolean;
}

export const templateObsVarColumns: (
	props: ITemplateObsVarColumnsProps,
) => IColumn<IVariableCore>[] = (props) => {
	const columns: IColumn<IVariableCore>[] = [];

	if (!props.hiddenColorStripe) {
		columns.push({
			id: "colorCategory",
			getHeaderRenderer: "",
			width: Size.sm_small,
			getCellRenderer: (row) => {
				return (
					<div
						className="full-parent-size"
						style={{
							backgroundColor: getColorFromVariableScope(
								row.scope,
							),
						}}
					/>
				);
			},
			headerClassName: "DorianeDataGrid-color",
			cellClassName: "DorianeDataGrid-color",
		});
	}

	columns.push({
		id: "varName",
		getHeaderRenderer: (
			<Box>
				<Typography
					text={DIC(EDIC_KEY.OBSERVATION_VARIABLES)}
					variant="body2Medium"
					color={Colors.neutral100}
				/>
			</Box>
		),
		getCellRenderer: (row) => row.name.en,
	});

	if (props.displayMode === "checkbox") {
		columns.push(
			CheckBoxColumn({
				isCheck: props.isTrialCheck,
				onCheck: props.onTrialCheck,
				headerName: DIC(EDIC_KEY.TRIAL),
				width: "1fr",
				isDisabled: () => props.checkDisabled,
			}),
		);
		columns.push(
			CheckBoxColumn({
				isCheck: props.isMaterialCheck,
				onCheck: props.onMaterialCheck,
				headerName: DIC(EDIC_KEY.MATERIAL),
				width: "1fr",
				isDisabled: () => props.checkDisabled,
			}),
		);
	}
	if (props.displayMode === "tickMark") {
		columns.push(
			TickMarkColumn(
				props.isTrialCheck,
				DIC(EDIC_KEY.TRIAL),
				"tickTrial",
			),
		);
		columns.push(
			TickMarkColumn(
				props.isMaterialCheck,
				DIC(EDIC_KEY.MATERIAL),
				"tickMaterial",
			),
		);
	}

	return columns;
};
