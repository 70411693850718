import React, { useMemo } from "react";

import { concatClassName } from "common";

import { DorianeDataGridRowHeader } from "./header/DorianeDataGridRowHeader";
import { DorianeDataGridRow } from "./row/DorianeDataGridRow";

import "./DorianeDataGrid.scss";

export interface IColumn<Row> {
	id: string;
	getCellRenderer: (row: Row) => React.ReactElement | string;
	getHeaderRenderer: React.ReactElement | string;
	width?: string;
	minWidth?: string;
	headerClassName?: string;
	cellClassName?: string;
}

export type IDorianeDataGridProps<Row> = {
	rows: Row[];
	columns: IColumn<Row>[];
	getRowId?: (row: Row) => string;
	customRowClass?: (row: Row, index: number) => string[];
	onCellClick?: (
		event: React.MouseEvent<HTMLDivElement>,
		row: Row,
		column: IColumn<Row>,
	) => void;
	onRowClick?: (event: React.MouseEvent<HTMLDivElement>, row: Row) => void;
	rowHeight?: string;
	className?: string;
	secondaryStyle?: boolean;
};
export default function DorianeDataGrid<Row>(
	props: IDorianeDataGridProps<Row>,
) {
	// columns to display
	const columns = useMemo(() => {
		const columns: IColumn<Row>[] = [];
		columns.push(...props.columns);
		return columns;
	}, [props.columns]);

	// Compute columns width
	const computedColumnsWidth: string = useMemo(
		() =>
			columns
				.map((elt) => {
					if (elt.minWidth) {
						return `minmax(${elt.minWidth || "1fr"},1fr)`;
					}
					return elt.width || "1fr";
				})
				.join(" "),
		[columns],
	);

	// data row height (35px by default)
	const rowHeight = props.rowHeight || "35px";
	return (
		<div
			className={concatClassName(
				"DorianeDataGrid",
				"flex-column",
				props.secondaryStyle ? "DorianeDataGridSecondaryStyle" : "",
				props.className ? props.className : "",
			)}
			style={
				{
					"--row-height": rowHeight,
				} as React.CSSProperties
			}
		>
			<DorianeDataGridRowHeader
				columns={columns}
				computedColumnsWidth={computedColumnsWidth}
				secondaryStyle={props.secondaryStyle}
			/>
			<div className={"DorianeDataGrid-data take-remaining-space"}>
				{props.rows.map((row, index) => (
					<DorianeDataGridRow
						key={props.getRowId?.(row) ?? index}
						row={row}
						columns={columns}
						computedColumnsWidth={computedColumnsWidth}
						cellOnClick={props.onCellClick}
						rowHeight={rowHeight}
						rowOnClick={props.onRowClick}
						className={props.customRowClass?.(row, index).join(" ")}
						secondaryStyle={props.secondaryStyle}
					/>
				))}
			</div>
		</div>
	);
}
