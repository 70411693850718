import React from "react";

import { EReviewStatus, IPutNotationReview } from "shared-type";
import {
	NotationExcluded,
	NotationNA,
	NotationReviewed,
} from "../../../../../../../../../assets";

import {
	IModificationRow,
	INotationTableRow,
	MutateRows,
} from "../../../utils/datagrid.types";
import { canEditDataStatus, editDataStatus } from "../edit-data-status";

import "./DataStatusMenu.scss";

const statusList = [
	{
		title: "Selection valid",
		status: EReviewStatus.REVIEWED,
		icon: NotationReviewed,
	},
	{
		title: "Selection outlier",
		status: EReviewStatus.EXCLUDED,
		icon: NotationExcluded,
	},
	{ title: "Selection N/A", status: EReviewStatus.NA, icon: NotationNA },
];
export interface IDataStatusMenuProps {
	rowSelected: INotationTableRow[];
	mutateRows: MutateRows;
	onClose: () => void;
}
export default function DataStatusMenu(props: IDataStatusMenuProps) {
	const changeRowStatus = (newDataStatus: EReviewStatus) => {
		const modificationsList: IModificationRow[] = [];
		for (const row of props.rowSelected) {
			if (canEditDataStatus(row)) {
				const modification = editDataStatus(row, newDataStatus);
				if (modification) {
					modificationsList.push({
						rowId: row.id,
						notationReviewModification: modification,
					});
				}
			}
		}
		props.mutateRows(modificationsList);
		props.onClose();
	};

	return (
		<div className="DataStatusMenu flex-column">
			{statusList.map((elt) => (
				<div
					className="DataStatusMenu-item"
					onClick={() => changeRowStatus(elt.status)}
					key={elt.status}
				>
					<div
						className="DataStatusMenu-item-icon"
						style={{ backgroundImage: `url(${elt.icon})` }}
					/>
					<div className="DataStatusMenu-item-title">{elt.title}</div>
				</div>
			))}
		</div>
	);
}
