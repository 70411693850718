/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { EntitySerializer } from "common";
import {
	ICreateNotation,
	INotation,
	INotationSerialized,
	IPatchNotationInfo,
	IPutNotationReview,
} from "shared-type";
import { AbstractRequest } from "../abstract-request";

export class NotationRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/notation";
	}

	createManyNotations(
		createdNotations: ICreateNotation[],
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<string[], any>> {
		return this.axios.post<string[]>(
			this.baseUrl + "/many",
			createdNotations,
			config
		);
	}

	patchManyNotations(
		patchNotationInfos: IPatchNotationInfo[]
	): Promise<AxiosResponse<any, any>> {
		return this.axios.patch<any>(this.baseUrl + "/many", patchNotationInfos);
	}

	getNotationsByNotebookId(
		notebookId: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<INotation[], any>> {
		return this.axios
			.get<INotationSerialized[]>(
				this.baseUrl + "/notebook/" + notebookId,
				config
			)
			.then((resp) => {
				// Need to convert date
				const returnedValue = resp as unknown as AxiosResponse<
					INotation[],
					any
				>;
				returnedValue.data = resp.data.map((elt: INotationSerialized) =>
					EntitySerializer.deserialize<INotation>(elt)
				);
				return returnedValue;
			});
	}

	getNotationByTrialIdSerialized(idTrial: string, config?: AxiosRequestConfig) {
		return this.axios.get<INotationSerialized[]>(
			`${this.baseUrl}/trial/${idTrial}`,
			config
		);
	}

	getNotationsByNotebookIdsSerialized(
		notebookIds: string[],
		config?: AxiosRequestConfig
	) {
		return this.axios.get<INotationSerialized[]>(this.baseUrl + "/notebooks", {
			...config,
			params: { notebookIds: notebookIds, ...config?.params },
		});
	}

	getNotationsByIds(notationIds: string[], config?: AxiosRequestConfig) {
		return this.axios.post<INotationSerialized[]>(
			this.baseUrl + "/get/many",
			notationIds,
			config
		);
	}

	putManyNotationReview(
		notationsReviewToUpdate: IPutNotationReview[],
		config?: AxiosRequestConfig
	) {
		return this.axios.put<void>(
			`${this.baseUrl}/review/many`,
			notationsReviewToUpdate,
			config
		);
	}
}
