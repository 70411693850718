"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_MET_FIELDS = void 0;
exports.CREATE_MET_FIELDS = [
    "description",
    "endDate",
    "label",
    "marketSegmentIds",
    "materialLevel",
    "name",
    "objective",
    "program",
    "project",
    "species",
    "startDate",
    "templateExpStructId",
    "templateObsId",
    "trialsDraft",
];
