import Tab from "@mui/material/Tab";
import React from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";

import styles from "sassTheme";

import "./TabStyle.scss";

interface LinkTabProps {
	label: string;
	href: string;
	navigateOption?: NavigateOptions;
	secondaryLevel?: boolean;
	onTabClick?: () => boolean; // Boolean for allowing navigation or not
}

export const CustomLinkTab = (props: LinkTabProps) => {
	const nav = useNavigate();
	const { secondaryLevel, navigateOption, onTabClick, ...tabProps } = props;

	return (
		<Tab
			className={
				secondaryLevel ? "CustomSecondaryLinkTab" : "CustomLinkTab"
			}
			component="a"
			onClick={(
				event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
			) => {
				event.preventDefault();
				if (onTabClick) {
					if (onTabClick()) {
						nav(props.href, navigateOption);
					}
				} else {
					nav(props.href, navigateOption);
				}
			}}
			style={{
				textTransform: "none",
				fontWeight: secondaryLevel ? "normal" : "bold",
				color: styles["tertiary-font-color"],
			}}
			{...tabProps}
		/>
	);
};
