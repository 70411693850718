import { parseBooleanVarValue } from "common";
import {
	EVariableCoreType,
	IVariableCore,
	IVarLimitedChoice,
} from "shared-type";

import { ICustomVariableForm } from ".";

export function customVarFormToObject(
	form: Partial<ICustomVariableForm>,
): Partial<IVariableCore> {
	return {
		name: { en: form.name ?? "" },
		description: form.description,
		constraint: form.constraint,
		multiNotation: form.multiNotation,
		limitedChoices: form.limitedChoices?.map((elt: IVarLimitedChoice) => {
			switch (form.type) {
				case EVariableCoreType.INT:
				case EVariableCoreType.DEC:
					return { ...elt, value: Number(elt.value) };
				case EVariableCoreType.DATE:
				case EVariableCoreType.DATETIME:
					return { ...elt, value: (elt.value as Date).toJSON() };
				case EVariableCoreType.BOOLEAN:
					return { ...elt, value: parseBooleanVarValue(elt.value) };
				case EVariableCoreType.STRING:
					return { ...elt, value: elt.value.toString() };

				default:
					return { ...elt };
			}
		}),
		identifier: form.identifier,
		type: form.type,
		inputType: form.inputType,
		shortName: form.shortName,
		unit: form.unit,
	};
}

export function customVarObjectToForm(
	object: Partial<IVariableCore>,
): Partial<ICustomVariableForm> {
	return {
		name: object.name?.en ?? "",
		description: object.description,
		constraint: object.constraint,
		multiNotation: object.multiNotation,
		limitedChoices: object.limitedChoices,
		identifier: object.identifier,
		type: object.type,
		inputType: object.inputType,
		shortName: object.shortName,
		unit: object.unit,
	};
}
