import React from "react";
import Slider, { Settings } from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoginCarouselItem, { LoginCarouselItemProps } from "./LoginCarouselItem";

import "./LoginCarousel.scss";

export default function LoginCarousel() {
	const settings: Settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
	};
	const items: LoginCarouselItemProps[] = [
		{
			iconClass: "welcome-agro",
			title: "Product Development",
			text: (
				<>
					By gathering all the information collected on past trials in
					the organisation and planning in a standard way the trials
					with right factors, I will be able to compare my varieties
					by relevant segments and provide the most accurate
					information adapted to zones and segments for smart
					commercialisation.
				</>
			),
		},
		{
			iconClass: "welcome-agro",
			title: "Agronomy Testing",
			text: (
				<>
					By setting up diversified protocols and taking into account
					all the constrainst from location to materiel to people, in
					a fast way, I will be able to manage accurately all field
					setting up, designs and phenotyping measures for the best
					quality results.
				</>
			),
		},
		{
			iconClass: "welcome-agro",
			title: "Breeding Program",
			text: (
				<>
					By having visibility on my overall breeding program from the
					first cross project to commercialisation, I will be able to
					manage objectives, genetic ressources with high tracability
					standards, people and processes. And finally link all with
					phenotyping, environement and genotyping data available in
					the company for effective innovation
				</>
			),
		},
	];

	return (
		<div className="LoginCarousel-container flex-column">
			<Slider {...settings} className="LoginCarousel">
				{items.map((elt, index) => (
					<LoginCarouselItem {...elt} key={index} />
				))}
			</Slider>
		</div>
	);
}
