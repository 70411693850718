import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { CustomLinkTab } from "App/components/base-components/tabs/CustomLinkTab";
import { CustomTabs } from "App/components/base-components/tabs/CustomTabs";
import { ETooltipPosition } from "App/components/base-components/tooltip/CustomTooltip";
import SelectUserGAModal from "App/components/growing-area/select-user/SelectUserGAModal";
import { DIC, EDIC_KEY } from "App/dictionary";
import { PATH_GROWING_AREA } from "App/routes";
import TopMenu from "../../../../components/base-components/menu/top-menu/TopMenu";
import CommentDrawer from "../../../../components/base-components/modal/comment-drawer/CommentDrawer";
import {
	GrowingAreaForm,
	growingAreaValidator,
	IGrowingAreaForm,
	growingAreaObjectToForm,
} from "../../../../components/form/growing-area";
import GrowingAreaBanner from "../../../../components/growing-area/card/GrowingAreaBanner";
import { useGrowingAreaEditAction } from "../../../../components/growing-area/edition/hook/growingAreaEditAction";
import { useRemoteGrowingArea } from "../../../../components/growing-area/edition/hook/remoteGrowingArea.hook";
import useAppSnackbar from "../../../../hooks/useAppSnackbar";
import { apiUserCustomHook } from "../../../../redux/user/user.api";

import "./GrowingAreaEditPage.scss";

const defaultGrowingArea: IGrowingAreaForm = {
	startDate: dayjs().startOf("day"),
	name: "",
	identifier: "",
	label: "",
	users: [],
};

interface GrowingAreaEditPageProps {
	hideNavBack: boolean;
	growingAreaId?: string;
}

export default function GrowingAreaEditPage(props: GrowingAreaEditPageProps) {
	const { growingAreaId } = useParams();
	const nav = useNavigate();
	const { enqueueSnackbarError } = useAppSnackbar();

	const [selectUserOpen, setSelectUserOpen] = useState<boolean>(false);
	const [isCommentOpen, setCommentOpen] = useState<boolean>(false);
	const [initialGAUserIds, setInitialGAUserIds] = useState<
		string[] | undefined
	>(undefined);

	const form = useForm<IGrowingAreaForm>({
		resolver: yupResolver(growingAreaValidator as any),
	});
	const userIds = useWatch({ control: form.control, name: "users" });

	const { data: currentGrowingAreaUsers, isSuccess: isGAUserSuccess } =
		apiUserCustomHook.useGetUsersByIds(userIds || []);

	const {
		growingAreaFetched,
		isGrowingAreaUsed,
		hasError: hasFetchError,
	} = useRemoteGrowingArea(props.growingAreaId ?? growingAreaId);

	const { handleDelete, handleSaveForm, handleSaveComment } =
		useGrowingAreaEditAction(props.growingAreaId ?? growingAreaId);

	// Initialize the initial growing area user ids
	useEffect(() => {
		if (isGAUserSuccess && initialGAUserIds === undefined) {
			setInitialGAUserIds(currentGrowingAreaUsers.map((user) => user.id));
		}
	}, [currentGrowingAreaUsers, initialGAUserIds, isGAUserSuccess]);

	const handleSave = async () => {
		form.handleSubmit(
			async (data) => {
				handleSaveForm(data, () => {
					setInitialGAUserIds(
						currentGrowingAreaUsers.map((user) => user.id),
					);
				});
			},
			(error) => {
				console.warn(error);
				enqueueSnackbarError(DIC(EDIC_KEY.BAD_INPUT));
			},
		)();
	};

	useEffect(() => {
		if (hasFetchError || !growingAreaFetched) {
			form.reset(defaultGrowingArea);
			return;
		}
		form.reset(growingAreaObjectToForm(growingAreaFetched));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [growingAreaFetched, hasFetchError]);

	if (!growingAreaFetched) {
		return <div>LOADING...</div>;
	}
	return (
		<div className="GrowingAreaEditPage-container full-parent-size">
			<div className="GrowingAreaEditPage-edit-part flex-column">
				<TopMenu
					saveButton={{ onClick: handleSave }}
					deleteButton={{
						onClick: handleDelete,
						isDisabled: isGrowingAreaUsed,
						disabledText: DIC(EDIC_KEY.THIS_GROWING_AREA_IS_USED),
						disabledTextPosition: ETooltipPosition.UNDER,
					}}
					onBackClick={
						props.hideNavBack
							? undefined
							: () => nav(PATH_GROWING_AREA)
					}
					onCommentClick={() => setCommentOpen(true)}
				/>
				<div className="GrowingAreaEditPage-header-banner">
					<GrowingAreaBanner
						growingArea={growingAreaFetched}
						disabledClick
					/>
				</div>
				<CustomTabs value={0} indicatorColor="secondary">
					<CustomLinkTab
						label={DIC(EDIC_KEY.GENERAL)}
						href={"."}
						navigateOption={{ relative: "path" }}
					/>
				</CustomTabs>
				<div className="GrowingAreaEditPage-body take-remaining-space">
					<div className="GrowingAreaEditPage-form">
						<GrowingAreaForm
							growingAreaUsers={currentGrowingAreaUsers}
							mode={isGrowingAreaUsed ? "used" : "unused"}
							onSelectUserClick={() => setSelectUserOpen(true)}
							form={form}
						/>
					</div>
				</div>
			</div>
			{selectUserOpen && (
				<SelectUserGAModal
					initialUserIds={initialGAUserIds ?? []}
					isGrowingAreaUsed={isGrowingAreaUsed}
					currentUserIds={userIds}
					onUserCheck={(clickedUser, checked) => {
						if (checked) {
							form.setValue("users", [
								...userIds,
								clickedUser.id,
							]);
						} else {
							const newUserList = userIds.filter(
								(userId) => userId !== clickedUser.id,
							);
							form.setValue("users", newUserList);
						}
					}}
					onClose={() => setSelectUserOpen(false)}
				/>
			)}
			{isCommentOpen && (
				<CommentDrawer
					onClose={() => setCommentOpen(false)}
					remoteComments={growingAreaFetched?.comments}
					onSaveComment={async (comments) =>
						handleSaveComment(comments)
					}
				/>
			)}
		</div>
	);
}
