import { Grid } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { formatString } from "common";
import { ITemplateObsCoreSerialized } from "shared-type";

import { DIC, EDIC_KEY } from "App/dictionary";
import useAppSnackbar from "App/hooks/useAppSnackbar";
import { apiTemplateObs } from "App/redux/template-obs/template-obs.api";
import { ApiErrorSnackbar } from "App/redux/utils/api-error-snackbar/ApiErrorSnackbar";
import { ReduxApiError } from "App/redux/utils/errors";
import TemplateObservationCard from "stories/base-components/Card/mostUsed/TemplateObservationCard";
import { HeaderTitleSearchButton } from "stories/base-components/Layout/HeaderTitleSearchButton/HeaderTitleSearchButton";
import { TemplateObservationModal } from "./TemplateObservationModal";

import "./TemplateObservationListPage.scss";

export default function TemplateObservationListPage() {
	const nav = useNavigate();
	const [deleteObsTemplate] =
		apiTemplateObs.useDeleteTemplateObsByIdMutation();
	const [duplicateTemplateObsByIds] =
		apiTemplateObs.useDuplicateTemplateObsByIdsMutation();

	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();
	const [filter, setFilter] = useState("");
	const { data: templateList } = apiTemplateObs.useGetTemplateObsQuery({});

	const [creationObsTemplatePopupIsOpen, setCreationObsTemplatePopupIsOpen] =
		useState(false);

	const filteredTemplates = useMemo(
		() =>
			templateList?.filter((elt) => {
				// Filter to review to allow fuzzy search everywhere.
				// Remove raw I18N from the filter !
				return elt.name.en.toLowerCase().includes(filter.toLowerCase());
			}),
		[filter, templateList],
	);

	const handleCardClick = (templateObsId: string) => {
		nav(`./${templateObsId}`);
	};

	const handleObservationTemplateClick = () => {
		setCreationObsTemplatePopupIsOpen(true);
	};

	const deleteObsTemplateCallback = useCallback(
		async (elt: ITemplateObsCoreSerialized) => {
			await deleteObsTemplate({
				id: elt._id,
			})
				.unwrap()
				.then(() => {
					enqueueSnackbarSuccess(
						formatString(
							DIC(EDIC_KEY.ENTITY_DELETED),
							DIC(EDIC_KEY.OBSERVATION_TEMPLATE),
						),
					);
				})
				.catch((err: ReduxApiError) => {
					console.warn(err);
					enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
				});
		},
		[deleteObsTemplate, enqueueSnackbarError, enqueueSnackbarSuccess],
	);

	const duplicateObsTemplateCallback = useCallback(
		async (elt: ITemplateObsCoreSerialized) => {
			duplicateTemplateObsByIds([elt._id])
				.unwrap()
				.then(() => {
					enqueueSnackbarSuccess(
						formatString(
							DIC(EDIC_KEY.ENTITY_DUPLICATED),
							DIC(EDIC_KEY.OBSERVATION_TEMPLATE),
						),
					);
				})
				.catch((err: ReduxApiError) => {
					console.warn(err);
					enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
				});
		},
		[
			duplicateTemplateObsByIds,
			enqueueSnackbarError,
			enqueueSnackbarSuccess,
		],
	);

	return (
		<div className="TemplateObservationListPage-container full-parent-size">
			<div className="TemplateObservationListPage-list flex-column full-parent-size">
				<div className="TemplateObservationListPage-list-headerContainer">
					<HeaderTitleSearchButton
						title={DIC(EDIC_KEY.OBSERVATION_TEMPLATES)}
						list={templateList || []}
						searchPlaceholder={formatString(
							DIC(EDIC_KEY.SEARCH_BY),
							DIC(EDIC_KEY.NAME).toLowerCase(),
						)}
						setSearch={setFilter}
						searchValue={filter}
						btnText={DIC(EDIC_KEY.NEW_OBSERVATION_TEMPLATE) + " +"}
						btnOnClick={handleObservationTemplateClick}
					/>
				</div>

				<div className="TemplateObservationListPage-card-list">
					<Grid container spacing={"20px"}>
						{filteredTemplates &&
							filteredTemplates.map((elt) => (
								<Grid item sm={12} md={4} lg={3} key={elt._id}>
									<TemplateObservationCard
										onClick={() => handleCardClick(elt._id)}
										templateObservation={elt}
										dropdown={[
											{
												label: "Duplicate",
												action: () => {
													duplicateObsTemplateCallback(
														elt,
													);
												},
											},
											{
												label: "Delete",
												action: async () => {
													deleteObsTemplateCallback(
														elt,
													);
												},
											},
										]}
									/>
								</Grid>
							))}
					</Grid>
				</div>
			</div>
			<TemplateObservationModal
				onClose={() => setCreationObsTemplatePopupIsOpen(false)}
				open={creationObsTemplatePopupIsOpen}
			/>
		</div>
	);
}
