/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { GridCell, GridCellKind } from "@glideapps/glide-data-grid";
import dayjs from "dayjs";
import React from "react";

import { EReviewStatus } from "shared-type";

import { IExperimentReviewNotationState } from "../../../../../../redux/experiment-review-notation/experiment-review-notation.slice";
import { DropdownCellType } from "../../../../../base-components";
import { UserProfileCellType } from "../../../../../base-components/data-grid/glide-datagrid/cell/UserProfileCell";
import {
	getNotebookCellStatusInfo,
	reviewStatusToString,
} from "../utils/converter";
import {
	ITrialValidationColumn,
	INotationTableRow,
} from "../utils/datagrid.types";
import {
	canEditDataStatus,
	editDataStatus,
} from "./data-status/edit-data-status";
import DataStatusMenu from "./data-status/menus/DataStatusMenu";
import notationCell from "./notation-cell-drawer/notation-cell-selector";
import { INotationCellOption } from "./notation-cell-drawer/notation-cell.types";

const DEFAULT_WIDTH = 160;

// Some column was disable for reduce the scope
// but the display works -> we don't want to remove it
export const TRIAL_DATA_COLUMN: ITrialValidationColumn[] = [
	// {
	// 	glideDataGrid: {
	// 		title: "Variable",
	// 		id: "variable",
	// 		group: "Identifier",
	// 		width: 250
	// 	},
	// 	dataAccess: (row: INotationTableRow) => {
	// 		const cell: GridCell = {
	// 			kind: GridCellKind.Text,
	// 			displayData: row.variable?.name.en || "",
	// 			data: row.variable?.name.en || "",
	// 			allowOverlay: false,
	// 			readonly: true
	// 		};
	// 		return cell;
	// 	}
	// },
	{
		glideDataGrid: {
			title: "Observation unit",
			id: "unit",
			group: "Identifier",
			width: DEFAULT_WIDTH,
		},
		cellRenderer: (row: INotationTableRow) => {
			const cell: GridCell = {
				kind: GridCellKind.Text,
				displayData: row.unit || "",
				data: row.unit || "",
				allowOverlay: false,
				readonly: true,
			};
			return cell;
		},
	},
	{
		glideDataGrid: {
			title: "Id",
			id: "id",
			group: "Identifier",
			width: DEFAULT_WIDTH,
		},
		cellRenderer: (row: INotationTableRow) => {
			const cell: GridCell = {
				kind: GridCellKind.Text,
				displayData: row.subjectName || "",
				data: row.subjectName || "",
				allowOverlay: true,
				readonly: false,
			};
			return cell;
		},
	},
	{
		glideDataGrid: {
			title: "Collected",
			id: "collected",
			group: "Data",
			width: DEFAULT_WIDTH,
		},
		cellRenderer: (
			row: INotationTableRow,
			context: IExperimentReviewNotationState,
		) => {
			const options: INotationCellOption = {};
			options.isModifiable = false;

			if (
				context.computedValue.min !== undefined &&
				context.computedValue.max !== undefined
			) {
				options.colorScale = {
					min: context.computedValue.min,
					max: context.computedValue.max,
				};
			}
			return notationCell(row.notation?.value, row.variable, options);
		},
	},
	// {
	// 	glideDataGrid: {
	// 		title: "Reviewed data",
	// 		id: "reviewedData",
	// 		group: "Data"
	// 	},
	// 	dataAccess: (row: INotationTableRow) => {
	// 		return notationCell(row, "review");
	// 	}
	// },
	// {
	// 	glideDataGrid: {
	// 		title: "Final value",
	// 		id: "finalValue",
	// 		group: "Data",
	// 		width: DEFAULT_WIDTH
	// 	},
	// 	cellRenderer: (
	// 		row: INotationTableRow,
	// 		context: IExperimentReviewNotationState
	// 	) => {
	// 		const options: INotationCellOption = {};
	// 		if (
	// 			context.computedValue.min !== undefined &&
	// 			context.computedValue.max !== undefined
	// 		) {
	// 			options.colorScale = {
	// 				min: context.computedValue.min,
	// 				max: context.computedValue.max
	// 			};
	// 		}
	// 		return notationCell(row, "final", options);
	// 	}
	// },

	{
		glideDataGrid: {
			title: "Validation",
			id: "validation",
			group: "Data",
			width: DEFAULT_WIDTH,
			hasMenu: true,
		},
		cellRenderer: (row: INotationTableRow) => {
			const currentStatus =
				row.notation?.reviewStatus ?? EReviewStatus.NA;
			const statusMetadata = reviewStatusToString.find(
				(elt) => elt.status === currentStatus,
			)!;
			const canEdit = canEditDataStatus(row);
			const cell: DropdownCellType = {
				kind: GridCellKind.Custom,
				copyData: statusMetadata.string,
				data: {
					allowedValues: reviewStatusToString.map(
						(elt) => elt.string,
					),
					kind: "dropdown-cell",
					value: statusMetadata.string,
				},
				allowOverlay: canEdit,
				readonly: canEdit,
				themeOverride: {
					bgCell: statusMetadata.bgColor,
					textDark: statusMetadata.fontColor,
					textLight: statusMetadata.fontColor,
				},
			};
			return cell;
		},
		onEditingCell: (row, cellValue, mutateRows) => {
			const newValueEnum = reviewStatusToString.find(
				(elt) =>
					elt.string === (cellValue as DropdownCellType).data.value,
			)?.status;
			if (newValueEnum === undefined) {
				console.error("Unknown cell value");
				return;
			}
			const editedNotation = editDataStatus(row, newValueEnum);
			if (editedNotation) {
				mutateRows([
					{
						rowId: row.id,
						notationReviewModification: editedNotation,
					},
				]);
			}
		},
		menuComponent: (props) => <DataStatusMenu {...props}></DataStatusMenu>,
	},
	{
		glideDataGrid: {
			title: "Notebook",
			id: "notebookStatus",
			group: "Data",
			width: DEFAULT_WIDTH,
		},
		cellRenderer: (row: INotationTableRow) => {
			const statusInfo = getNotebookCellStatusInfo(row.notebook?.status);
			const cell: GridCell = {
				kind: GridCellKind.Text,
				allowOverlay: false,
				readonly: true,
				data: statusInfo.status,
				displayData: statusInfo.string,
				themeOverride: {
					bgCell: statusInfo.bgColor,
					textDark: statusInfo.fontColor,
					textLight: statusInfo.fontColor,
				},
			};
			return cell;
		},
	},
	{
		glideDataGrid: {
			title: "Date",
			id: "notationDate",
			group: "Other",
			width: DEFAULT_WIDTH,
		},
		cellRenderer: (row: INotationTableRow) => {
			const date = row.notation?.notationDate;
			const dateString = date
				? dayjs(date).format("MM/DD/YYYY - HH:mm")
				: "";
			const cell: GridCell = {
				kind: GridCellKind.Text,
				data: dateString,
				displayData: dateString,
				allowOverlay: false,
				readonly: true,
			};
			return cell;
		},
	},
	{
		glideDataGrid: {
			title: "Notebook of",
			id: "notebookUser",
			group: "Other",
			width: DEFAULT_WIDTH * 2,
		},
		cellRenderer: (row: INotationTableRow) => {
			const user = row.user;
			const name = `${user?.firstName} ${user?.lastName}`;
			const initial = user?.firstName?.charAt(0).toUpperCase() || "";
			const cell: UserProfileCellType = {
				kind: GridCellKind.Custom,
				data: {
					kind: "user-profile-cell",
					initial: initial,
					image: "",
					tint: user?.color || "grey",
					name: name,
				},
				copyData: name,
				allowOverlay: false,
				readonly: true,
			};
			return cell;
		},
	},
	{
		glideDataGrid: {
			title: "Round",
			id: "obsRound",
			group: "Other",
			width: DEFAULT_WIDTH,
		},
		cellRenderer: (row: INotationTableRow) => {
			const cell: GridCell = {
				kind: GridCellKind.Text,
				displayData: row.obsRound || "",
				data: row.obsRound || "",
				allowOverlay: false,
				readonly: true,
			};
			return cell;
		},
	},
];
