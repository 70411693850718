/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { EntitySerializer } from "common";

import {
	ICreateTrial,
	ITrial,
	ITrialSerialized,
	IFilter,
	IPatchTrial,
	ISort,
	PaginateResponse,
	IAssociatedMetTrials,
	ITrialDuplicationInfo,
} from "shared-type";

import { AbstractRequest } from "../abstract-request";

export class TrialRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/trial";
	}

	createTrial(createTrial: ICreateTrial): Promise<AxiosResponse<string, any>> {
		return this.axios.post<string>(this.baseUrl, createTrial);
	}

	duplicateTrials(
		trialDuplicationInfo: ITrialDuplicationInfo
	): Promise<AxiosResponse<string[], any>> {
		return this.axios.post<
			string,
			AxiosResponse<string[], any>,
			ITrialDuplicationInfo
		>(`${this.baseUrl}/duplicate`, trialDuplicationInfo);
	}

	getTrials(
		args?: { page?: number; pageSize?: number; filter?: IFilter; sort?: ISort },
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<PaginateResponse<ITrial>, any>> {
		return this.axios
			.get<PaginateResponse<ITrialSerialized>>(this.baseUrl, {
				...config,
				params: {
					...config?.params,
					page: JSON.stringify(args?.page),
					pageSize: JSON.stringify(args?.pageSize),
					filter: JSON.stringify(args?.filter),
					sort: JSON.stringify(args?.sort),
				},
			})
			.then((resp) => {
				// Need to deserialized
				const returnedValue = resp as unknown as AxiosResponse<
					PaginateResponse<ITrial>,
					any
				>;

				returnedValue.data.data = resp.data.data.map((elt: ITrialSerialized) =>
					EntitySerializer.deserialize<ITrial>(elt)
				);
				return returnedValue;
			});
	}
	getTrialsSerialized(
		args: { page?: number; pageSize?: number; filter?: IFilter; sort?: ISort },
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<PaginateResponse<ITrialSerialized>, any>> {
		return this.axios.get<PaginateResponse<ITrialSerialized>>(this.baseUrl, {
			...config,
			params: {
				...config?.params,
				page: JSON.stringify(args.page),
				pageSize: JSON.stringify(args.pageSize),
				filter: JSON.stringify(args.filter),
				sort: JSON.stringify(args.sort),
			},
		});
	}

	getTrialsByIds(trialIds: string[], config?: AxiosRequestConfig) {
		return this.axios.post<ITrialSerialized[]>(
			this.baseUrl + "/get/many",
			trialIds,
			config
		);
	}

	getTrialsByMETId(metId: string, config?: AxiosRequestConfig) {
		return this.axios.get<ITrialSerialized[]>(
			this.baseUrl + "/met/" + metId,
			config
		);
	}

	getTrialsIdsByMETIds(metIds: string[], config?: AxiosRequestConfig) {
		return this.axios.post<IAssociatedMetTrials[]>(
			this.baseUrl + "/met/get/trials-ids",
			metIds,
			config
		);
	}

	getTrialById(
		id: string,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<ITrial, any>> {
		return this.axios
			.get<ITrialSerialized>(this.baseUrl + "/" + id, config)
			.then((resp) => {
				// Need to convert date
				const returnedValue = resp as unknown as AxiosResponse<ITrial, any>;
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				returnedValue.data = EntitySerializer.deserialize<ITrial>(resp.data)!;
				return returnedValue;
			});
	}

	getTrialByIdSerialized(
		id: string,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<ITrialSerialized, any>> {
		return this.axios.get<ITrialSerialized>(this.baseUrl + "/" + id, config);
	}

	updateTrialById(
		id: string,
		updateTrial: IPatchTrial
	): Promise<AxiosResponse<void, any>> {
		return this.axios.patch<void>(this.baseUrl + "/" + id, updateTrial);
	}

	deleteTrialById(id: string): Promise<AxiosResponse<void, any>> {
		return this.axios.delete<void>(this.baseUrl + "/" + id);
	}
}
