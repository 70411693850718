import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";

import DynamicFormV2 from "App/components/base-components/dynamic-form/DynamicFormV2";
import Box from "App/components/base-components/placement/box/Box";
import FlexBox from "App/components/base-components/placement/flex-box/FlexBox";
import { useAppSelector } from "App/hooks/reduxHook";
import { selectTrial } from "App/redux/experiment/trial/trial.selector";
import { Checkbox } from "stories/base-components/Checkbox/Checkbox";
import { DateRangePicker } from "stories/base-components/DateRangePicker/DateRangePicker";
import { InfoMessage } from "stories/base-components/InfoMessage/InfoMessage";
import { RadioGroup } from "stories/base-components/Radio/Radio";
import { Tooltip } from "stories/base-components/Tooltip/Tooltip";
import { Typography } from "stories/base-components/Typography/Typography";
import Colors from "stories/constants/Colors/Colors";
import Size from "stories/constants/Size/Size";
import { DIC, EDIC_KEY } from "../../../dictionary";
import { EFormInputType } from "../../base-components/dynamic-form";
import FormSection from "../../base-components/dynamic-form/form-section/FormSection";

export interface ITrialDuplicateForm {
	id: string;
	name: string;
	periodFrom?: Dayjs;
	periodTo?: Dayjs;
	material?: boolean;
	growingArea?: boolean;
	obsTemplate?: boolean;
	duplicateTasks?: boolean;
	tasks?: string;
}

interface TrialDuplicateFormProps {
	form: UseFormReturn<ITrialDuplicateForm>;
}

export function TrialDuplicateFormPart1(props: TrialDuplicateFormProps) {
	const form = props.form;
	const [periodFrom, periodTo] = useWatch({
		control: form.control,
		name: ["periodFrom", "periodTo"],
	});

	const {
		formState: { errors },
	} = form;

	const dateRangePickerError = useMemo(() => {
		let dateRangePickerErrorMemo = undefined;
		if (errors.periodFrom && errors.periodTo) {
			dateRangePickerErrorMemo =
				errors.periodFrom.message + " - " + errors.periodTo.message;
		} else if (errors.periodFrom) {
			dateRangePickerErrorMemo = errors.periodFrom.message;
		} else if (errors.periodTo) {
			dateRangePickerErrorMemo = errors.periodTo.message;
		}
		return dateRangePickerErrorMemo;
	}, [errors.periodFrom, errors.periodTo]);

	return (
		<Box>
			<FormSection title={DIC(EDIC_KEY.GENERAL)}>
				<DynamicFormV2
					control={form.control}
					formInfos={[
						{
							inputType: EFormInputType.STRING,
							id: "name",
							label: DIC(EDIC_KEY.NAME),
							required: true,
						},
					]}
				/>
			</FormSection>

			<FormSection title={DIC(EDIC_KEY.PERIOD)}>
				<DateRangePicker
					startDateInput={
						periodFrom
							? dayjs(periodFrom).format("MM/DD/YYYY")
							: null
					}
					setStartDateInput={(date: string) => {
						if (date !== null) {
							form.setValue(
								"periodFrom",
								dayjs(date || "invalid"),
							);
						}
					}}
					endDateInput={
						periodTo ? dayjs(periodTo).format("MM/DD/YYYY") : null
					}
					setEndDateInput={(date: string) => {
						if (date !== null) {
							form.setValue("periodTo", dayjs(date || "invalid"));
						}
					}}
					startDateLabel={DIC(EDIC_KEY.FROM) + "*"}
					endDateLabel={DIC(EDIC_KEY.TO) + "*"}
					error={dateRangePickerError}
				/>
			</FormSection>
		</Box>
	);
}

export function TrialDuplicateFormPart2(props: TrialDuplicateFormProps) {
	const selectedDuplicatedTrial = useAppSelector(
		selectTrial.trialDuplicateSelected,
	);

	const form = props.form;
	const [material, growingArea, obsTemplate, duplicateTasks, tasks] =
		useWatch({
			control: form.control,
			name: [
				"material",
				"growingArea",
				"obsTemplate",
				"duplicateTasks",
				"tasks",
			],
		});

	const [duplicateDependencies, setDuplicateDependencies] = useState(true);

	const isTaskAllowed = useMemo(
		() => material && obsTemplate,
		[material, obsTemplate],
	);

	const { noMaterials, noGrowingArea, noTemplateObs, noDependencies } =
		useMemo(() => {
			const noMaterials =
				(selectedDuplicatedTrial?.materialLevel?.materials.length ||
					0) === 0;
			const noGrowingArea = !selectedDuplicatedTrial?.growingAreaId;
			const noTemplateObs = !selectedDuplicatedTrial?.templateObsId;
			const noDependencies =
				noMaterials && noGrowingArea && noTemplateObs;
			return {
				noMaterials,
				noGrowingArea,
				noTemplateObs,
				noDependencies,
			};
		}, [
			selectedDuplicatedTrial?.growingAreaId,
			selectedDuplicatedTrial?.materialLevel?.materials.length,
			selectedDuplicatedTrial?.templateObsId,
		]);

	useEffect(() => {
		if (noMaterials) {
			form.setValue("material", false);
		}
		if (noGrowingArea) {
			form.setValue("growingArea", false);
		}
		if (noTemplateObs) {
			form.setValue("obsTemplate", false);
		}
		if (noDependencies) {
			setDuplicateDependencies(false);
		}
	}, [form, noDependencies, noGrowingArea, noMaterials, noTemplateObs]);

	useEffect(() => {
		if (duplicateDependencies === false) {
			form.setValue("material", false);
			form.setValue("growingArea", false);
			form.setValue("obsTemplate", false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [duplicateDependencies]);

	if (!selectedDuplicatedTrial) {
		return <></>;
	}

	return (
		<Box>
			<Typography
				variant="heading2"
				text={DIC(EDIC_KEY.DUPLICATE_DEPENDENCIES_QUESTION)}
			/>
			<Box marginLeft={"-" + Size.xs} paddingTop={Size.xs}>
				<RadioGroup
					disabled={noDependencies}
					options={[
						{ value: "true", label: "Yes" },
						{ value: "false", label: "No" },
					]}
					value={duplicateDependencies}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setDuplicateDependencies(event.target.value === "true");
					}}
				/>
			</Box>
			{noDependencies && (
				<Box paddingTop={Size.md}>
					<InfoMessage
						infoType="info"
						message={DIC(EDIC_KEY.DUPLICATE_TRIAL_NO_DEPENDENCIES)}
					/>
				</Box>
			)}
			{duplicateDependencies && (
				<>
					<Box
						marginTop={Size.xs}
						marginBottom={Size.md}
						borderBottom={
							Size.borderStroke + " solid " + Colors.neutral30
						}
					/>
					<Typography
						variant="heading2"
						text={DIC(EDIC_KEY.CHOOSE_WHAT_DUPLICATE)}
					/>
					<FlexBox
						paddingLeft={Size.x2s}
						paddingTop={Size.xs}
						paddingBottom={Size.x2s}
						gap={Size.x2s}
					>
						<FlexBox
							flexDirection="column"
							paddingRight={Size.x2l}
							gap={Size.x2s}
						>
							<Checkbox
								label={
									DIC(EDIC_KEY.MATERIAL) +
									(" (" +
										selectedDuplicatedTrial.materialLevel
											?.materials.length +
										")")
								}
								disabled={noMaterials}
								checked={material || false}
								onChange={(event, checked) => {
									form.setValue("material", checked);
								}}
							/>
							<Checkbox
								label={
									DIC(EDIC_KEY.GROWING_AREA) +
									" (" +
									(noGrowingArea ? "0" : "1") +
									")"
								}
								disabled={noGrowingArea}
								checked={growingArea || false}
								onChange={(event, checked) => {
									form.setValue("growingArea", checked);
								}}
							/>
							<Checkbox
								label={
									DIC(EDIC_KEY.OBSERVATION_TEMPLATE) +
									" (" +
									(noTemplateObs ? "0" : "1") +
									")"
								}
								disabled={noTemplateObs}
								checked={obsTemplate || false}
								onChange={(event, checked) => {
									form.setValue("obsTemplate", checked);
								}}
							/>
						</FlexBox>
						{!(noTemplateObs || noMaterials) && (
							<Tooltip
								text={DIC(
									EDIC_KEY.DUPLICATE_TASKS_ENSURE_SELECTED,
								)}
								addContainer
								placement="top"
								blockOpen={isTaskAllowed}
							>
								<Box>
									<Checkbox
										label={DIC(EDIC_KEY.DUPLICATE_TASKS)}
										checked={
											isTaskAllowed
												? duplicateTasks
												: false
										}
										onChange={(event, checked) => {
											form.setValue(
												"duplicateTasks",
												checked,
											);
											if (checked) {
												form.setValue(
													"tasks",
													"tasks_users",
												);
											}
										}}
										disabled={!isTaskAllowed}
									/>
									<Box paddingLeft={Size.xl}>
										<RadioGroup
											orientation="column"
											options={[
												{
													value: "tasks_users",
													label: "Tasks and users",
												},
												{
													value: "tasks",
													label: "Only tasks",
												},
											]}
											value={tasks || undefined}
											disabled={
												!duplicateTasks ||
												!isTaskAllowed
											}
											onChange={(
												event: React.ChangeEvent<HTMLInputElement>,
											) => {
												form.setValue(
													"tasks",
													event.target.value,
												);
											}}
										/>
									</Box>
								</Box>
							</Tooltip>
						)}
					</FlexBox>
				</>
			)}
		</Box>
	);
}
