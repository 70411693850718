import React, { useMemo } from "react";

import { EGermplasmLevel, IGenotype } from "shared-type";

import { DIC, EDIC_KEY } from "../../../dictionary";
import { apiGermplasm } from "../../../redux/germplasm/germplasm.api";
import MaterialCard from "../../experiment/common/modal/germplasm/material-card/MaterialCard";

import "./GermplasmMaterialList.scss";

export interface IGermplasmMaterialListProps {
	materialIds: string[];
	type: EGermplasmLevel;
	filter?: string;
}

function GermplasmMaterialListLot(
	props: IGermplasmMaterialListProps & { type: EGermplasmLevel.LOT },
) {
	const { data: lots } = apiGermplasm.useGetLotByIdsQuery(props.materialIds);
	const { data: lotsGenotypes } = apiGermplasm.useGetGenotypesByIdsQuery(
		lots?.map((elt) => elt.genotype._id) ?? [],
	);

	const mapLotsGenotypes = useMemo(() => {
		const map = new Map<string, IGenotype>();
		lotsGenotypes?.forEach((gen) => map.set(gen._id, gen));
		return map;
	}, [lotsGenotypes]);

	const filteredLotList = useMemo(
		() =>
			lots?.filter((elt) => {
				// Filter to review to allow fuzzy search everywhere.
				return (
					elt.lotName
						?.toLowerCase()
						.includes(props.filter?.toLowerCase() ?? "") ||
					elt.genotype.name
						?.toLowerCase()
						.includes(props.filter?.toLowerCase() ?? "")
				);
			}),
		[props.filter, lots],
	);

	return (
		<div className="GermplasmMaterialList-container take-remaining-space flex-column">
			{filteredLotList &&
				filteredLotList.map((elt) => (
					<MaterialCard
						key={elt._id}
						tags={[
							{
								title: DIC(EDIC_KEY.LOCATION_SOURCE),
								value: elt.locationSource || "_",
							},
							{
								title: DIC(EDIC_KEY.EXPERIMENT_SOURCE),
								value: elt.expSource || "_",
							},
							{
								title: DIC(EDIC_KEY.GENOTYPE),
								value: elt.genotype.name || "_",
							},
							{
								title: DIC(EDIC_KEY.VARIETY),
								value:
									mapLotsGenotypes.get(elt.genotype._id)
										?.variety || "_",
							},
							{
								title: DIC(EDIC_KEY.GENERATION),
								value:
									mapLotsGenotypes.get(elt.genotype._id)
										?.generation || "_",
							},
						]}
						title={elt.lotIdentifier}
					/>
				))}
		</div>
	);
}

function GermplasmMaterialListGenotype(
	props: IGermplasmMaterialListProps & { type: EGermplasmLevel.GENOTYPE },
) {
	const { data: genotypes } = apiGermplasm.useGetGenotypesByIdsQuery(
		props.materialIds,
	);

	const filteredGenotypeList = useMemo(
		() =>
			genotypes?.filter((elt) => {
				// Filter to review to allow fuzzy search everywhere.
				return (elt.variety ?? elt.name)
					.toLowerCase()
					.includes(props.filter?.toLowerCase() ?? "");
			}),
		[props.filter, genotypes],
	);

	return (
		<div className="GermplasmMaterialList-container take-remaining-space flex-column">
			{filteredGenotypeList &&
				filteredGenotypeList.map((elt) => (
					<MaterialCard
						key={elt._id}
						tags={[
							{
								title: DIC(EDIC_KEY.CROSS_TYPE),
								value: elt.materialType || "_",
							},
							{
								title: DIC(EDIC_KEY.GENOTYPE_NAME),
								value: elt.name,
							},
						]}
						title={elt.variety ?? elt.name}
					/>
				))}
		</div>
	);
}

export default function GermplasmMaterialList(
	props: IGermplasmMaterialListProps,
) {
	if (props.type === EGermplasmLevel.GENOTYPE) {
		return (
			<GermplasmMaterialListGenotype
				{...props}
				type={EGermplasmLevel.GENOTYPE}
			/>
		);
	}
	return <GermplasmMaterialListLot {...props} type={EGermplasmLevel.LOT} />;
}
