import React from "react";

import "./CustomTooltip.scss";

export enum ETooltipPosition {
	ABOVE = "above",
	UNDER = "under",
	LEFT = "left",
	RIGHT = "right",
}

interface CustomTooltipProps {
	tooltipText: string;
	position?: ETooltipPosition; // Not all are implemented
	children: React.ReactNode;
	hidden?: boolean;
}

export default function CustomTooltip(props: CustomTooltipProps) {
	return (
		<div className="CustomTooltip-container">
			<div className="CustomTooltip-children">{props.children}</div>
			{props.hidden ? (
				<div />
			) : (
				<span
					className={`CustomTooltip-text CustomTooltip-position-${
						props.position ? props.position : "above"
					}`}
				>
					{props.tooltipText}
				</span>
			)}
		</div>
	);
}
