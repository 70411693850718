import React from "react";

import { IMarketSegment } from "shared-type";

import { DIC, EDIC_KEY } from "../../../../../dictionary";
import { getDicSpecies } from "../../../../../dictionary/dictionary-key-getter";
import {
	IDorianeFilter,
	IDorianeObjectFilter,
} from "../../../../../interfaces/filters/doriane-filter-interface";
import { apiGrowingArea } from "../../../../../redux/growing-area/growing-area.api";
import GrowingAreaTagList from "../../../../growing-area/tag-list/GrowingAreaTagList";
import { CheckBoxColumn } from "../columns/CheckBoxColumn";
import { IColumn } from "../doriane-data-grid/DorianeDataGrid";
import HeaderWithFilter from "../doriane-data-grid/header/HeaderWithFilter";

import styles from "../../../../../../sassTheme";

export const marketSegmentColumns = (
	filter: IDorianeObjectFilter,
	onFilterChange: (newFilter: IDorianeFilter) => void,
): IColumn<IMarketSegment>[] => [
	{
		id: "name",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "name",
					type: "string",
					value: filter.name?.value,
				}}
				label={DIC(EDIC_KEY.NAME)}
				onFilterChange={onFilterChange}
			/>
		),
		getCellRenderer: (marketSegment) => marketSegment.name ?? "",
	},
	{
		id: "description",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "description",
					type: "string",
					value: filter.description?.value,
				}}
				label={DIC(EDIC_KEY.DESCRIPTION)}
				onFilterChange={onFilterChange}
			/>
		),
		getCellRenderer: (marketSegment) => marketSegment.description ?? "",
	},
	{
		id: "species",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "species",
					type: "string",
					value: filter.species?.value,
				}}
				label={DIC(EDIC_KEY.SPECIES)}
				onFilterChange={onFilterChange}
			/>
		),
		getCellRenderer: (marketSegment) =>
			getDicSpecies(marketSegment.species),
		width: "0.7fr",
	},
	{
		id: "growingAreas",
		getHeaderRenderer: DIC(EDIC_KEY.GROWING_AREA),
		getCellRenderer: (marketSegment) => {
			const { data: growingAreas } =
				apiGrowingArea.useGetGrowingAreaByIdsQuery(
					marketSegment.growingAreas,
				);

			return (
				<div
					style={{
						display: "flex",
						flexWrap: "wrap",
						padding: styles["padding-nano"],
						gap: styles["padding-micro"],
						maxHeight: "150px",
						overflowY: "scroll",
					}}
				>
					{growingAreas && (
						<GrowingAreaTagList growingAreas={growingAreas} />
					)}
				</div>
			);
		},
		width: "1.5fr",
	},
];

export function marketSegmentColumnsForSelection(
	filter: {
		filter: IDorianeObjectFilter;
		onFilterChange: (newFilter: IDorianeFilter) => void;
	},
	rowCallback: {
		isCheck: (row: IMarketSegment) => boolean;
		onCheck: (row: IMarketSegment, check: boolean) => void;
	},
): IColumn<IMarketSegment>[] {
	const checkBoxColumn = CheckBoxColumn({
		isCheck: rowCallback.isCheck,
		onCheck: rowCallback.onCheck,
	});
	return [
		checkBoxColumn,
		...marketSegmentColumns(filter.filter, filter.onFilterChange),
	];
}
