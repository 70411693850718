/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";

import { DIC, EDIC_KEY } from "../../../../dictionary";
import DorianeButton from "../../button/DorianeButton";

import "./BottomMenu.scss";

interface BottomMenuProps {
	onCreateAndClose?: () => void;
	onCreateAndContinue?: () => void;
	createAndCloseLoading?: boolean;
	createAndContinueLoading?: boolean;
	customButton?: React.ReactNode;
}

export default function BottomMenu(props: BottomMenuProps) {
	return (
		<div className="BottomMenu-container">
			{props.onCreateAndClose && (
				<DorianeButton
					style={{ flex: 1 }}
					dorianeStyle="secondary"
					onClick={() => props.onCreateAndClose!()}
					isLoading={props.createAndCloseLoading}
					disabled={
						props.createAndContinueLoading ||
						props.createAndCloseLoading
					}
				>
					{DIC(EDIC_KEY.CREATE_AND_CLOSE)}
				</DorianeButton>
			)}
			{props.onCreateAndContinue && (
				<DorianeButton
					style={{ flex: 1 }}
					dorianeStyle="primary"
					onClick={() => props.onCreateAndContinue!()}
					isLoading={props.createAndContinueLoading}
					disabled={
						props.createAndContinueLoading ||
						props.createAndCloseLoading
					}
				>
					{DIC(EDIC_KEY.CREATE_AND_CONTINUE)}
				</DorianeButton>
			)}
			{props.customButton !== undefined && props.customButton}
		</div>
	);
}
