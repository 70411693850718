import React from "react";

import { IVariableCore } from "shared-type";

import { useGetFilteredGridData } from "App/components/base-components/data-grid/doriane-data-grid/data-grid.utils";
import {
	useGetOtherVarRow,
	variableColumns,
} from "App/components/base-components/data-grid/doriane-data-grid/default-grid-columns/VariableColumns";
import DorianeDataGrid from "App/components/base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";

import "./VariablesOthersPage.scss";

export default function VariablesOthersPage() {
	const { data: otherVarList } = useGetOtherVarRow();

	const { columns, rowsToDisplay } = useGetFilteredGridData({
		columns: variableColumns,
		rows: otherVarList,
	});

	return (
		<div className="VariablesOthersPage-container flex-column full-parent-size">
			<DorianeDataGrid<IVariableCore>
				columns={columns}
				rows={rowsToDisplay}
			/>
		</div>
	);
}
