import React from "react";

import { ITrialGenericBannerProps } from "../TrialGenericBanner";
import { ITrialGenericCardProps } from "../TrialGenericCard";
import MetTrialBanner from "./MetTrialBanner";

import "./MetTrialCard.scss";

export default function MetTrialCard(props: ITrialGenericCardProps) {
	const enhancedProps: ITrialGenericBannerProps = {
		...props,
		className: "MetTrialCard",
		disabledClick: props.onClick === undefined,
	};

	return <MetTrialBanner {...enhancedProps} />;
}
