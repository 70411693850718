import { StoreType } from "../store";

/**
 * Used for not be impacted by the dependencies cycles
 */
export class ReduxStore {
	static store: StoreType;
	static injectStore(_store: StoreType) {
		ReduxStore.store = _store;
	}
}
