import React from "react";
import { useSelector } from "react-redux";

import { templateObsLinkedVarColumns } from "App/components/base-components/data-grid/doriane-data-grid/default-grid-columns/TemplateObsLinkedVarColumns";
import DorianeDataGrid from "App/components/base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import TemplateObsVarGrid from "App/components/templates/observation/edition/variable-grid/TemplateObsVarGrid";
import { DIC, EDIC_KEY } from "App/dictionary";
import { defaultTemplateObsLinkedVar } from "App/mock";
import { selectECW } from "App/redux/ECW/ECW.selector";
import { apiTemplateObs } from "App/redux/template-obs/template-obs.api";
import { variablesCustomHook } from "App/redux/variables/variables.api";
import TemplateObservationCard from "stories/base-components/Card/mostUsed/TemplateObservationCard";
import { ITemplateObsLinkedVar } from "../../../../../../../components/templates/observation/TemplateObsEditionPage.utils";

import "./ExpObsUnitPage.scss";

export default function ExpObsUnitPage() {
	const templateObsId = useSelector(selectECW.templateObservationId);

	const { data: ontologyVariables } =
		variablesCustomHook.useGetOntologyVariableListDeserialized();
	const { data: templateObs } =
		apiTemplateObs.useGetTemplateObsByIdQuery(templateObsId);

	return (
		<div className="ExpObsUnitPage-container take-remaining-space flex-column">
			<div className="ExpObsUnitPage-block flex-column">
				<h1>{DIC(EDIC_KEY.OBSERVATION_TEMPLATE)}</h1>
				{templateObs && (
					<TemplateObservationCard
						templateObservation={templateObs}
					/>
				)}
			</div>
			<div className="ExpObsUnitPage-block flex-column">
				<h1>{DIC(EDIC_KEY.VARIABLES)}</h1>
				{templateObs && (
					<TemplateObsVarGrid
						templateObservation={templateObs}
						variableOntology={ontologyVariables}
						isTemplateObsUsed={true}
						hiddenColorStripe={true}
						displayMode={"tickMark"}
					/>
				)}
				<DorianeDataGrid<ITemplateObsLinkedVar>
					rows={defaultTemplateObsLinkedVar}
					columns={templateObsLinkedVarColumns(true)}
				/>
			</div>
		</div>
	);
}
