import React from "react";

import { ReduxApiError } from "../errors";

import "./ApiErrorSnackbar.scss";

export function ApiErrorSnackbar({ error }: { error: ReduxApiError }) {
	let message = "";
	if (error.statusCode !== undefined) {
		message += error.statusCode + " - ";
	}
	message += error.message;
	return (
		<div className="ApiErrorSnackbar-container">
			<div className="ApiErrorSnackbar-title">{message}</div>
			{error.responsePayload !== undefined && (
				<div className="ApiErrorSnackbar-request">
					{JSON.stringify(error.responsePayload)}
				</div>
			)}
		</div>
	);
}
