import styles from "sassTheme";
import React from "react";

export const muiErrorButtonStyle: React.CSSProperties = {
	fontWeight: "bold",
	color: styles.white,
	maxHeight: styles["element-min-height"],
	backgroundColor: styles["error-color"],
	width: "fit-content",
	textTransform: "none",
	borderWidth: 0,
};
