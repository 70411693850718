import React from "react";

import { EUserRole, IUser } from "shared-type";

import { DIC, EDIC_KEY } from "App/dictionary";
import { Initials } from "stories/base-components/Initials/Initials";
import Box from "App/components/base-components/placement/box/Box";

interface IIInitialsUser {
	user: Partial<IUser>;
}

export default function InitialsUser(props: IIInitialsUser) {
	const user = props.user;
	return (
		<Box
			title={
				user.role === EUserRole.ADMIN
					? DIC(EDIC_KEY.ADMIN)
					: DIC(EDIC_KEY.OBSERVER)
			}
		>
			<Initials
				firstName={user.firstName}
				lastName={user.lastName}
				backgroundColor={user.color}
				upperIconName={
					user.role === EUserRole.ADMIN ? "admin" : "eye--filled"
				}
				size="big"
			/>
		</Box>
	);
}
