import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { convertToUniqueVarIdString } from "common";
import { ETrialLevel, IVariableCoreSerialized } from "shared-type";

import SaveButton from "App/components/base-components/button/SaveButton";
import Combobox from "App/components/base-components/combobox/Combobox";
import { SearchableCombobox } from "App/components/base-components/combobox/SearchableCombobox";
import KPICard from "App/components/experiment/trial/data/validation/kpi-card/KPICard";
import ReviewNotationDataGrid, {
	ITrialValidationDatagridWrapperRef,
} from "App/components/experiment/trial/data/validation/TrialValidationDatagrid";
import { DIC, EDIC_KEY } from "App/dictionary";
import { getDicTrialLevel } from "App/dictionary/dictionary-key-getter";
import { selectExperimentReviewNotation } from "App/redux/experiment-review-notation/experiment-review-notation.selector";
import { apiVariables } from "App/redux/variables/variables.api";

import "./TrialValidationPage.scss";

export default function TrialValidationPage() {
	const reviewNotationDataGridRef =
		useRef<ITrialValidationDatagridWrapperRef | null>(null);

	const { data: variables, isSuccess } =
		apiVariables.useGetAllVariablesQuery();

	const [selectedScopedVariableId, setSelectedScopedVariableId] = useState<
		string | null
	>(null);
	const [selectedObsUnit, setSelectedObsUnit] = useState<ETrialLevel>(
		ETrialLevel.TRIAL,
	);

	const computedValue = useSelector(
		selectExperimentReviewNotation.selectComputedValue,
	);
	const currentVariablesIds = useSelector(
		selectExperimentReviewNotation.selectExperimentLevelVariables(
			selectedObsUnit,
		),
	);

	const variablesMap = useMemo(
		() =>
			new Map<string, IVariableCoreSerialized>(
				variables?.map((variable) => [
					convertToUniqueVarIdString(variable),
					variable,
				]),
			),
		[variables],
	);

	// needed for mui -> log an warn if variable not in values
	const selectedVariableVerified = useMemo(() => {
		if (
			currentVariablesIds.findIndex(
				(elt) => elt === selectedScopedVariableId,
			) === -1
		) {
			return null;
		}
		return selectedScopedVariableId;
	}, [currentVariablesIds, selectedScopedVariableId]);

	useEffect(() => {
		if (
			currentVariablesIds.findIndex(
				(elt) => elt === selectedScopedVariableId,
			) === -1
		) {
			setSelectedScopedVariableId(currentVariablesIds[0] || null);
		}
	}, [selectedObsUnit, currentVariablesIds]);

	return (
		<div className="TrialValidationPage-container full-parent-size flex-column">
			{isSuccess && (
				<>
					<div className="TrialValidationPage-header-menu">
						<div className="TrialValidationPage-header-filter">
							<Combobox<ETrialLevel>
								value={selectedObsUnit}
								label={DIC(EDIC_KEY.OBSERVATION_UNIT)}
								onChange={(value) => setSelectedObsUnit(value)}
								comboboxValues={Object.values(ETrialLevel)}
								translateMethod={getDicTrialLevel}
								className={"TrialValidationPage-lvl"}
							/>
							<SearchableCombobox<string>
								value={selectedVariableVerified}
								label={DIC(EDIC_KEY.VARIABLE)}
								onChange={(value) =>
									setSelectedScopedVariableId(value!)
								}
								comboboxValues={currentVariablesIds}
								translateMethod={(elt) =>
									variablesMap.get(elt)?.name.en || ""
								}
								className={"TrialValidationPage-variable"}
							/>
						</div>
						<div className="TrialValidationPage-header-save">
							<SaveButton
								onSave={() =>
									reviewNotationDataGridRef.current?.handleSave()
								}
								customLabel={DIC(EDIC_KEY.VALIDATE)}
							></SaveButton>
						</div>
					</div>
					<div className="TrialValidationPage-body flex-column take-remaining-space">
						<div className="TrialValidationPage-kpi">
							<KPICard
								value={computedValue.min ?? "_"}
								title="Min"
								hint="final"
								color="#EEA5A5"
								option={{ roundNumber: 2 }}
							/>
							<KPICard
								value={computedValue.average ?? "_"}
								title="Average"
								hint="final"
								color="#FFDE89"
								option={{ roundNumber: 2 }}
							/>
							<KPICard
								value={computedValue.max ?? "_"}
								title="Max"
								hint="final"
								color="#99CC33"
								option={{ roundNumber: 2 }}
							/>
							<KPICard
								value={computedValue.standardDev ?? "_"}
								title="Stand dev"
								hint="final"
								color="#A698FF"
								option={{ roundNumber: 2 }}
							/>
						</div>

						<div className="take-remaining-space">
							<ReviewNotationDataGrid
								selectedLevel={selectedObsUnit}
								selectedVariable={variablesMap.get(
									selectedScopedVariableId || "",
								)}
								ref={(ref) =>
									(reviewNotationDataGridRef.current = ref)
								}
							/>
						</div>
					</div>
				</>
			)}
		</div>
	);
}
