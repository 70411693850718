import {
	EVariableCoreInputType,
	EVariableCoreType,
	EVariableScope,
	IScopedVariable,
	IUniqueVariableInterface,
	IVariableCore,
	IVariableGroup,
	Optional,
	VariableCoreValue,
} from "shared-type";
import { getElementsNotInList } from "./toolbox";

/**
 * Parse a variable core value to a Boolean value
 * @param value
 * @returns true if the value is "true" or 1, false otherwise
 * */
export function parseBooleanVarValue(value: VariableCoreValue): boolean {
	return value === "true" || value === "1" || value === true || value === 1;
}

/**
 * Remove the given variable in all the variables groups and return the new variable groups
 * @param variablesToRemove the variables to remove
 * @param variableGroups the variable groups
 */
export function removeVariablesInVarGroups(
	variablesToRemove: IScopedVariable[],
	variableGroups: IVariableGroup[]
): IVariableGroup[] {
	return variableGroups.map((varGroup) => {
		const filteredVariables = getElementsNotInList<IScopedVariable>(
			varGroup.variables,
			variablesToRemove
		);
		// Create a new variable group with the filtered variables
		return {
			...varGroup,
			variables: filteredVariables,
		};
	});
}

export function convertToUniqueVarIdString(variable: IUniqueVariableInterface) {
	return `${variable.scope}:${variable._id}`;
}

export const scopeUrlVariable: { url: string; scope: EVariableScope }[] = [
	{ url: "system", scope: EVariableScope.SYSTEM },
	{ url: "standard", scope: EVariableScope.STANDARD },
	{ url: "custom", scope: EVariableScope.CUSTOM },
];
export function urlToEVariableScope(url: string): EVariableScope | undefined {
	return scopeUrlVariable.find((elt) => elt.url === url)?.scope;
}
export function EVariableScopeToUrl(scope: EVariableScope): string {
	return scopeUrlVariable.find((elt) => elt.scope === scope)!.url;
}

export const trimVarFieldsToRemove = <
	T extends Optional<
		IVariableCore,
		"_id" | "systemMetadata" | "scope" | "category"
	>
>(
	variable: T
): { trimmedVariable: T; fieldsToRemove: (keyof T)[] } => {
	const res: { trimmedVariable: T; fieldsToRemove: (keyof T)[] } = {
		trimmedVariable: variable,
		fieldsToRemove: [],
	};

	// We are a FREE variable
	if (variable.inputType === EVariableCoreInputType.FREE) {
		const { limitedChoices, ...varOthers } = res.trimmedVariable;
		res.fieldsToRemove.push("limitedChoices");

		if (
			variable.type === EVariableCoreType.BOOLEAN || // No constraints on boolean
			(variable.constraint && // Remove constraint if no constraints (only type is useless)
				Object.keys(variable.constraint).length === 1 &&
				variable.constraint.type !== undefined)
		) {
			res.fieldsToRemove.push("constraint");
			delete varOthers.constraint; // We can delete because it's a copy of variable arg
		}
		res.trimmedVariable = varOthers as T;
	}
	// We are a LIMITED CHOICE variable
	if (variable.inputType === EVariableCoreInputType.LIMITED_CHOICE) {
		const { constraint, ...varOthers } = res.trimmedVariable;
		res.trimmedVariable = varOthers as T;
		res.fieldsToRemove.push("constraint");
	}
	// We have a multiNotation in DB with not new compatible type
	if (
		variable.type !== EVariableCoreType.DEC &&
		variable.type !== EVariableCoreType.INT &&
		variable.multiNotation !== undefined
	) {
		const { multiNotation, ...varOthers } = res.trimmedVariable;
		res.trimmedVariable = varOthers as T;
		res.fieldsToRemove.push("multiNotation");
	}
	// Don't send systemMetadata to the updated entity
	if (variable.systemMetadata) {
		const { systemMetadata, ...varOthers } = res.trimmedVariable;
		res.trimmedVariable = varOthers as T;
	}
	return res;
};
