import React from "react";

import { formatString } from "common";

import { DIC, EDIC_KEY } from "../../../../../../dictionary";
import { apiVariableGroup } from "../../../../../../redux/variable-group/variable-group.api";
import DorianeDrawer from "../../../../../base-components/modal/DorianeDrawer";
import VarGroupList from "../../VarGroupList";

import "./SelectVariableGroupModal.scss";

export interface ISelectVariableGroupModalProps {
	onClose: () => void;
	templateObsId: string;
	onVariableGroupSelect: (varGroupId: string) => void;
	onVarGroupCreateClick: () => void;
	selectedGroupIds?: string[];
}

export default function SelectVariableGroupModal(
	props: ISelectVariableGroupModalProps,
) {
	const { data: remoteVarGroups } =
		apiVariableGroup.useGetVarGroupsByTemplateObsIdQuery(
			props.templateObsId,
		);

	const closeModal = () => {
		props.onClose();
	};

	const handleClick = (id: string) => {
		props.onVariableGroupSelect(id);
		props.onClose();
	};

	return (
		<DorianeDrawer
			onClose={closeModal}
			title={formatString(
				DIC(EDIC_KEY.SELECT_ENTITY),
				DIC(EDIC_KEY.VARIABLE_GROUP).toLowerCase(),
			)}
		>
			<div className="SelectVariableGroupModal-container flex-column take-remaining-space">
				{remoteVarGroups && (
					<VarGroupList
						handleVarGroupClick={handleClick}
						remoteVariableGroups={remoteVarGroups}
						onVarGroupCreateClick={props.onVarGroupCreateClick}
						selectedGroupIds={props.selectedGroupIds}
						mode="selectVariableGroup"
					/>
				)}
			</div>
		</DorianeDrawer>
	);
}
