import React from "react";

import DorianeButton from "../../../../base-components/button/DorianeButton";
import DorianeModal from "../../../../base-components/modal/DorianeModal";
import { DIC, EDIC_KEY } from "../../../../../dictionary";

interface ITemplateObsNeedSaveModalProps {
	onOkClick: () => void;
}

export default function TemplateObsNeedSaveModal(
	props: ITemplateObsNeedSaveModalProps,
) {
	return (
		<DorianeModal
			onClose={() => {
				props.onOkClick();
			}}
			title={DIC(EDIC_KEY.INFORMATIONS)}
			width={"500px"}
			closeOnOutsideClick={true}
		>
			<div className="take-remaining-space flex-column">
				<div className="DorianeModal-child-text-area flex-column">
					<p>{DIC(EDIC_KEY.TEMPLATE_OBS_NEED_TO_SAVE_MSG)}</p>
				</div>
				<div className="DorianeModal-child-button-list">
					<DorianeButton
						onClick={() => {
							props.onOkClick();
						}}
					>
						{DIC(EDIC_KEY.OK)}
					</DorianeButton>
				</div>
			</div>
		</DorianeModal>
	);
}
