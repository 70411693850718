"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mockDictionaryLang = exports.mockDictionary = void 0;
const mockDictionary = [
    {
        key: "GLO_app_name",
        fr: "RnDExperience",
        en: "RnDExperience",
    },
    {
        key: "GLO_cancel",
        fr: "Annuler",
        en: "Cancel",
    },
    {
        key: "GLO_close",
        fr: "Fermer",
        en: "Close",
    },
    {
        key: "GLO_delete",
        fr: "Supprimer",
        en: "Delete",
    },
    {
        key: "GLO_description",
        fr: "Description",
        en: "Description",
    },
    {
        key: "GLO_details",
        fr: "Détails",
        en: "Details",
    },
];
exports.mockDictionary = mockDictionary;
const mockDictionaryLang = [
    {
        key: "GLO_app_name",
        value: "RnDExperience",
    },
    {
        key: "GLO_cancel",
        value: "Cancel",
    },
    {
        key: "GLO_close",
        value: "Close",
    },
    {
        key: "GLO_delete",
        value: "Delete",
    },
    {
        key: "GLO_description",
        value: "Description",
    },
    {
        key: "GLO_details",
        value: "Details",
    },
];
exports.mockDictionaryLang = mockDictionaryLang;
