import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { formatString } from "common";

import { DIC, EDIC_KEY } from "../../../dictionary";
import useAppSnackbar from "../../../hooks/useAppSnackbar";
import { selectECW } from "../../../redux/ECW/ECW.selector";
import { apiMET } from "../../../redux/experiment/met/met.api";
import { apiTrial } from "../../../redux/experiment/trial/trial.api";
import { ApiErrorSnackbar } from "../../../redux/utils/api-error-snackbar/ApiErrorSnackbar";
import { PATH_EXPERIMENTS, PATH_METS, PATH_TRIALS } from "../../../routes";
import TopMenu from "../../base-components/menu/top-menu/TopMenu";
import FlexBox, {
	EGapSize,
} from "../../base-components/placement/flex-box/FlexBox";
import ExperimentDeleteModal from "../../experiment/common/modal/delete-modal/ExperimentDeleteModal";
import ECWHeader from "./header/ECWHeader";

import "./ECW.scss";

export default function CommonECWPart({
	children,
}: {
	children: React.ReactNode;
}) {
	const { trialId, metId } = useParams();

	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();

	const activeStep = useSelector(selectECW.activeStep);
	const stepList = useSelector(selectECW.stepList);
	const wizardType = useSelector(selectECW.wizardType);

	const [deleteMETMutation] = apiMET.useDeleteMETByIdMutation();
	const [deleteTrialMutation] = apiTrial.useDeleteTrialByIdMutation();

	const [isDeletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);

	const nav = useNavigate();
	const handleDeleteClick = () => {
		setDeletePopupOpen(true);
	};

	const deleteAction = async () => {
		if (trialId) {
			deleteTrialMutation(trialId)
				.unwrap()
				.then(() => {
					enqueueSnackbarSuccess(
						formatString(
							DIC(EDIC_KEY.ENTITY_DELETED),
							DIC(EDIC_KEY.TRIAL),
						),
					);
					nav(`${PATH_EXPERIMENTS}${PATH_TRIALS}`);
				})
				.catch((err) => {
					console.warn(err);
					enqueueSnackbarError(err);
				});
		}
		if (metId) {
			deleteMETMutation({
				metId,
				deleteLinkedTrials: true, // No trials for the moment
			})
				.unwrap()
				.then(() => {
					enqueueSnackbarSuccess(
						formatString(
							DIC(EDIC_KEY.ENTITY_DELETED),
							DIC(EDIC_KEY.MET),
						),
					);
					nav(`${PATH_EXPERIMENTS}${PATH_METS}`);
				})
				.catch((err) => {
					console.warn(err);
					enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
				});
		}
	};

	const handleBackClick = () => {
		if (wizardType === "SET") {
			nav(`${PATH_EXPERIMENTS}${PATH_TRIALS}`);
			return;
		}
		nav(`${PATH_EXPERIMENTS}${PATH_METS}`);
	};

	return (
		<div className="ECW-body-container">
			<TopMenu
				onBackClick={() => handleBackClick()}
				// To show or not the delete button
				deleteButton={{
					onClick: trialId || metId ? handleDeleteClick : undefined,
				}}
			/>
			<ECWHeader activeStepIndex={activeStep} steps={stepList} />
			<FlexBox takeRemainingSpace flexDirection={"column"}>
				{children}
			</FlexBox>
			<ExperimentDeleteModal
				open={isDeletePopupOpen}
				isDraft={true}
				isMET={wizardType === "MET"}
				onClose={() => setDeletePopupOpen(false)}
				deleteAction={deleteAction}
			/>
		</div>
	);
}
