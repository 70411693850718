import React from "react";
import { UseFormReturn, useWatch } from "react-hook-form";

import { EUserRole } from "shared-type";

import { EFormInputType } from "App/components/base-components/dynamic-form";
import DynamicFormV2 from "App/components/base-components/dynamic-form/DynamicFormV2";
import { DIC, EDIC_KEY } from "App/dictionary";
import { getDicUserRole } from "App/dictionary/dictionary-key-getter";
import { FormMode } from "../../../const";
import FlexBox from "App/components/base-components/placement/flex-box/FlexBox";
import Size from "stories/constants/Size/Size";
import InitialsUserXXL from "stories/base-components/Initials/mostUsed/InitialsUserXXL";
import { Typography } from "stories/base-components/Typography/Typography";

import "./UserForm.scss";

export interface IUserForm {
	firstName: string;
	lastName: string;
	mail: string;
	role: EUserRole;
	color: string;
}
export interface IUserFormProps {
	mode: FormMode;
	form: UseFormReturn<IUserForm>;
}

function InitialsUser(props: { form: any }) {
	// To refresh from.getValues() for InitialsUserXXL
	useWatch({
		control: props.form.control,
		name: ["firstName", "lastName", "color"],
	});
	return <InitialsUserXXL user={props.form.getValues()} />;
}

export default function UserForm(props: IUserFormProps) {
	const form = props.form;

	return (
		<div className="UserForm-container">
			<div className="UserForm-block flex-column">
				<FlexBox
					flexDirection="row"
					justifyContent="space-between"
					gap={Size.x2l}
					paddingBottom={Size.x3l}
					takeRemainingSpace
				>
					<FlexBox
						flexDirection="column"
						gap={Size.md}
						takeRemainingSpace
					>
						<InitialsUser form={form} />
						<Typography
							variant="heading2"
							text={"Choose a color"}
						/>
						<FlexBox flexDirection="row" gap={Size.md}>
							<DynamicFormV2
								control={form.control}
								formInfos={[
									{
										inputType: EFormInputType.COLOR,
										id: "color",
										label: "color",
										takeAllRow: true,
									},
								]}
							/>
						</FlexBox>
					</FlexBox>
					<FlexBox
						flexDirection="column"
						gap={Size.md}
						takeRemainingSpace
					>
						{form && (
							<DynamicFormV2
								control={form.control}
								formInfos={[
									{
										inputType: EFormInputType.STRING,
										id: "firstName",
										name: "firstName",
										label: DIC(EDIC_KEY.FIRST_NAME),
										required: true,
										takeAllRow: true,
									},
									{
										inputType: EFormInputType.STRING,
										id: "lastName",
										name: "lastName",
										label: DIC(EDIC_KEY.LAST_NAME),
										required: true,
										takeAllRow: true,
									},
									{
										inputType: EFormInputType.STRING,
										id: "mail",
										name: "mail",
										label: DIC(EDIC_KEY.EMAIL),
										required: true,
										takeAllRow: true,
										disabled: props.mode === "used",
									},
									{
										inputType: EFormInputType.COMBOBOX,
										id: "role",
										label: DIC(EDIC_KEY.ROLE),
										comboboxValues:
											Object.values(EUserRole),
										translateMethod: getDicUserRole,
									},
								]}
							/>
						)}
					</FlexBox>
				</FlexBox>
			</div>
		</div>
	);
}
