import React from "react";

import { DIC, EDIC_KEY } from "App/dictionary";
import { Badge } from "stories/base-components/Badge/Badge";
import Colors from "stories/constants/Colors/Colors";
import { StoryTheme } from "stories/StoryTheme";

import "./status.scss";

export type IStatus = {
	status:
		| "draft"
		| "ready"
		| "validated"
		| "not_validated"
		| "completed"
		| "in_progress";
};

const statusType = {
	draft: {
		label: DIC(EDIC_KEY.DRAFT),
		color: Colors.yellow70,
		backgroundColor: Colors.yellow30,
	},
	ready: {
		label: DIC(EDIC_KEY.READY),
		color: Colors.accent70,
		backgroundColor: Colors.accent20,
	},
	validated: {
		label: DIC(EDIC_KEY.VALIDATED),
		color: Colors.accent70,
		backgroundColor: Colors.accent20,
	},
	// eslint-disable-next-line camelcase
	not_validated: {
		label: DIC(EDIC_KEY.NOT_VALIDATED),
		color: Colors.red50,
		backgroundColor: Colors.red10,
	},
	completed: {
		label: DIC(EDIC_KEY.COMPLETED),
		color: Colors.accent70,
		backgroundColor: Colors.accent20,
	},
	// eslint-disable-next-line camelcase
	in_progress: {
		label: DIC(EDIC_KEY.IN_PROGRESS),
		color: Colors.active60,
		backgroundColor: Colors.active20,
	},
};

export const Status = (status: IStatus) => {
	const type = statusType[status.status]
		? statusType[status.status]
		: {
				label: "Unknown",
				color: Colors.yellow70,
				backgroundColor: Colors.yellow30,
		  };
	return (
		<StoryTheme>
			<Badge
				text={type.label}
				color={type.color}
				backgroundColor={type.backgroundColor}
				customIcon={
					<div
						className="status_dot"
						style={{ backgroundColor: type.color }}
					></div>
				}
			/>
		</StoryTheme>
	);
};
