import {
	ITemplateExpStructCore,
	ETemplateExpStructScope,
	ETemplateExpStructType,
	ETemplateExpStructLocation,
	ETemplateExpStructCropCycle,
	ETemplateExpStructDesign,
	ETemplateExpStructCrossOption,
	ETemplateExpStructCrossTypeCategory,
} from "shared-type";

export const SETtemplateExpStructMock = {
	_id: "SLT",
	identifier: "BT_SLT",
	name: { en: "Basic Testing Single environment Trial" },
	shortName: "BT_SLT ",
	description: "Default trial for single environment Product Dev for field crops",
	expScope: ETemplateExpStructScope.VARIETAL,
	expType: ETemplateExpStructType.TESTING,
	location: ETemplateExpStructLocation.SINGLE,
	cropCycle: ETemplateExpStructCropCycle.SINGLE,
	expDesign: ETemplateExpStructDesign.ONE_REP_NO_CONTROL,
	crossOption: ETemplateExpStructCrossOption.NONE,
	materialTypeCategory: ETemplateExpStructCrossTypeCategory.NONE,
};
export const METtemplateExpStructMock = {
	_id: "MET",
	identifier: "BT_MET",
	name: { en: "Basic Testing Multi Environment Trial" },
	shortName: "BT_MET",
	description: "Default trial for multi environment Product Dev for field crops",
	expScope: ETemplateExpStructScope.VARIETAL,
	expType: ETemplateExpStructType.TESTING,
	location: ETemplateExpStructLocation.MULTI,
	cropCycle: ETemplateExpStructCropCycle.SINGLE,
	expDesign: ETemplateExpStructDesign.ONE_REP_NO_CONTROL,
	crossOption: ETemplateExpStructCrossOption.NONE,
	materialTypeCategory: ETemplateExpStructCrossTypeCategory.NONE,
};
export const templateExpStructsMock: ITemplateExpStructCore[] = [
	SETtemplateExpStructMock,
	METtemplateExpStructMock,
];
