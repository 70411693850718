import React, { ReactNode } from "react";

import { IIconType } from "stories/base-components/Icon/IconType";
import { Typography } from "stories/base-components/Typography/Typography";
import Colors from "stories/constants/Colors/Colors";
import Size from "stories/constants/Size/Size";
import { Icon } from "stories/base-components/Icon/Icon";
import { StoryTheme } from "stories/StoryTheme";

import "./badge.scss";

export type IBadge = {
	text: string;
	color?: string;
	backgroundColor?: string;
	iconName?: IIconType;
	customIcon?: ReactNode;
};

export const Badge = (badge: IBadge) => {
	return (
		<StoryTheme>
			<div
				className="badgeContainer"
				style={{
					backgroundColor: badge.backgroundColor
						? badge.backgroundColor
						: Colors.primary20,
				}}
			>
				{badge.iconName && (
					<div className="badgeIconContainer">
						<Icon
							name={badge.iconName}
							fontSize={Size.sm}
							color={badge.color ? badge.color : Colors.primary60}
						/>
					</div>
				)}
				{badge.customIcon && (
					<div className="badgeIconContainer">{badge.customIcon}</div>
				)}
				<Typography
					variant="smallRegular"
					text={badge.text}
					color={badge.color ? badge.color : Colors.primary60}
					noWrap
				/>
			</div>
		</StoryTheme>
	);
};
