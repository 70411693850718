import { undefinedOrStringEmpty } from "common";
import {
	EMultiComputeMethod,
	EMultiExpectationType,
	IVarMultiNotation,
} from "shared-type";

export interface EditingVarMultiNotation {
	expectedNotationNb?: string;
	expectationType: EMultiExpectationType;
	computeMethod: EMultiComputeMethod;
}

export class MultiNotationConverter {
	static convertFromEdition(
		multiNotation: Partial<EditingVarMultiNotation>,
	): IVarMultiNotation {
		const result: any = { ...multiNotation };
		if (!undefinedOrStringEmpty(multiNotation.expectedNotationNb)) {
			result.expectedNotationNb = Number(
				multiNotation.expectedNotationNb,
			);
		}
		return result;
	}

	static convertToEdition(
		multiNotation: Partial<IVarMultiNotation>,
	): EditingVarMultiNotation {
		const result: any = { ...multiNotation };

		if (multiNotation.expectedNotationNb) {
			result.expectedNotationNb =
				multiNotation.expectedNotationNb.toString();
		}
		return result;
	}
}
