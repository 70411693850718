import React, { useState } from "react";

import { ETagType, FilterResource, IFilter, ITag } from "shared-type";

import { DIC, EDIC_KEY } from "App/dictionary";
import DorianeButton from "../../base-components/button/DorianeButton";
import DorianeDrawer from "../../base-components/modal/DorianeDrawer";
import PictureFilterTag from "./PictureFilterTag";
import PictureFilterTagDefault from "./PictureFilterTagDefault";

import "./PictureFilterDrawer.scss";
import TopMenu from "App/components/base-components/menu/top-menu/TopMenu";

export interface IPictureFilterDrawerProps {
	onClose: () => void;
	onFilterChange: (filter: FilterResource) => void;
	errors?: string[];
	hideTrialFilter?: boolean;
}
export default function PictureFilterDrawer(props: IPictureFilterDrawerProps) {
	const hasError = props.errors && props.errors.length > 0;

	const [trials, setTrials] = useState<string[]>([]);
	const [materials, setMaterials] = useState<string[]>([]);
	const [variables, setVariables] = useState<string[]>([]);
	const [defaults, setDefaults] = useState<string[]>([]);

	const getResourceIds = (tempTags: ITag[]) => {
		const ids: string[][] = tempTags.map((tag) => tag.resourceIds);
		if (ids && ids.length > 0) {
			return ids.reduce((arrayA, arrayB) => arrayA.concat(arrayB));
		}
		return [];
	};

	const isIdsIncludes = (ids: string[], id: string) => {
		return ids.length === 0 || ids.includes(id);
	};

	const getFilterIds = (): IFilter => {
		const allIds = trials
			.concat(materials)
			.concat(variables)
			.concat(defaults);

		const validIds = allIds.filter(
			(id) =>
				isIdsIncludes(trials, id) &&
				isIdsIncludes(materials, id) &&
				isIdsIncludes(variables, id) &&
				isIdsIncludes(defaults, id),
		);

		if (validIds.length > 0) {
			return {
				_id: {
					$in: validIds,
				},
			};
		}

		return {};
	};

	return (
		<DorianeDrawer
			onClose={props.onClose}
			title={DIC(EDIC_KEY.PICTURE_FILTERS)}
			width="500px"
		>
			<TopMenu
				additionalButton={
					<DorianeButton
						dorianeStyle="primary"
						onClick={() => {
							props.onFilterChange(getFilterIds());
						}}
						disabled={hasError}
					>
						{DIC(EDIC_KEY.APPLY)}
					</DorianeButton>
				}
			/>
			<div>
				<div className="PictureFilterDrawer-list">
					{!props.hideTrialFilter && (
						<PictureFilterTag
							label={EDIC_KEY.TRIAL}
							tagType={ETagType.TRIAL}
							onTagChange={(tags) => {
								setTrials(getResourceIds(tags));
							}}
						/>
					)}
					<PictureFilterTag
						label={EDIC_KEY.MATERIAL}
						tagType={ETagType.MATERIAL}
						onTagChange={(tags) => {
							setMaterials(getResourceIds(tags));
						}}
					/>
					<PictureFilterTag
						label={EDIC_KEY.VARIABLE}
						tagType={ETagType.VARIABLE}
						onTagChange={(tags) => {
							setVariables(getResourceIds(tags));
						}}
					/>
					<PictureFilterTagDefault
						onTagChange={(tags) => {
							setDefaults(getResourceIds(tags));
						}}
					/>
				</div>
			</div>
		</DorianeDrawer>
	);
}
