import React from "react";
import DataEditor, {
	DataEditorProps,
	DataEditorRef,
} from "@glideapps/glide-data-grid";

import { DorianeCellsRenderer } from "./cell";
import {
	dorianeDatagridOddCellBackground,
	dorianeDatagridTheme,
} from "./glide-datagrid-theme";

export const DorianeGrid = React.forwardRef(function DorianeGrid(
	props: DataEditorProps,
	ref: React.ForwardedRef<DataEditorRef>,
) {
	const getRowThemeOverride = React.useCallback(
		dorianeDatagridOddCellBackground,
		[],
	);

	return (
		<DataEditor
			className="DorianeGrid"
			ref={ref}
			overscrollX={0}
			overscrollY={0}
			smoothScrollX={true}
			smoothScrollY={true}
			getCellsForSelection={true}
			onPaste={true}
			copyHeaders={false}
			{...props}
			customRenderers={DorianeCellsRenderer}
			getRowThemeOverride={getRowThemeOverride}
			theme={{
				...dorianeDatagridTheme,
				...props.theme,
			}}
			rowHeight={28}
			width="100%"
		/>
	);
});
