/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { EntitySerializer, EVariableScopeToUrl } from "common";
import {
	EVariableScope,
	ICreateVariableCore,
	IPatchVariableCore,
	IVariableCore,
	IVariableCoreSerialized,
} from "shared-type";

import { AbstractRequest } from "../abstract-request";

export class VariableRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/variables";
	}

	createCustomVariable(
		createVariable: ICreateVariableCore
	): Promise<AxiosResponse<string, any>> {
		return this.axios.post<
			string,
			AxiosResponse<string, any>,
			ICreateVariableCore
		>(`${this.baseUrl}/custom`, createVariable);
	}

	duplicateCustomVariableByIds(
		variableIds: string[]
	): Promise<AxiosResponse<string[], any>> {
		return this.axios.post<string, AxiosResponse<string[], any>, string[]>(
			`${this.baseUrl}/duplicate/custom`,
			variableIds
		);
	}

	createManyCustomVariable(
		createVariables: ICreateVariableCore[]
	): Promise<AxiosResponse<string[], any>> {
		return this.axios.post<
			string,
			AxiosResponse<string[], any>,
			ICreateVariableCore[]
		>(`${this.baseUrl}/many/custom`, createVariables);
	}

	getVariables(
		scope: EVariableScope
	): Promise<AxiosResponse<IVariableCore[], any>> {
		return this.axios
			.get<IVariableCoreSerialized[]>(
				`${this.baseUrl}/${EVariableScopeToUrl(scope)}`
			)
			.then((resp) => {
				// Need to convert date
				const returnedValue = resp as unknown as AxiosResponse<
					IVariableCore[],
					any
				>;
				returnedValue.data = resp.data.map((elt: IVariableCoreSerialized) =>
					EntitySerializer.deserialize<IVariableCore>(elt)
				);
				return returnedValue;
			});
	}

	getVariableById(
		scope: EVariableScope,
		id: string,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<IVariableCore, any>> {
		return this.axios
			.get<IVariableCoreSerialized>(
				`${this.baseUrl}/${EVariableScopeToUrl(scope)}/${id}`,
				{ ...config }
			)
			.then((resp) => {
				// Need to convert date
				const returnedValue = resp as unknown as AxiosResponse<
					IVariableCore,
					any
				>;
				returnedValue.data = EntitySerializer.deserialize<IVariableCore>(
					resp.data
				);
				return returnedValue;
			});
	}

	getVariableUsagesById(
		scope: EVariableScope,
		id: string
	): Promise<AxiosResponse<string[], any>> {
		return this.axios.get<string[]>(
			`${this.baseUrl}/usage/${EVariableScopeToUrl(scope)}/${id}`
		);
	}

	deleteManyCustomVariableByIds(
		ids: string[]
	): Promise<AxiosResponse<void, any>> {
		return this.axios.delete<void, AxiosResponse<void, any>, string[]>(
			`${this.baseUrl}/custom/many`,
			{ data: ids }
		);
	}

	deleteCustomVariableById(id: string): Promise<AxiosResponse<void, any>> {
		return this.axios.delete<void, AxiosResponse<void, any>>(
			`${this.baseUrl}/custom/${id}`
		);
	}

	updateCustomVariableById(
		id: string,
		updateVariable: IPatchVariableCore
	): Promise<AxiosResponse<void, any>> {
		return this.axios.patch<void, AxiosResponse<void, any>>(
			`${this.baseUrl}/custom/${id}`,
			updateVariable
		);
	}
}
