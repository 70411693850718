import React from "react";
import { useSelector } from "react-redux";
import { selectECW } from "../../../../../../../redux/ECW/ECW.selector";
import PicturePage from "../../../../../picture/PicturePage";

export default function TrialPicturePage() {
	const trial = useSelector(selectECW.trialRemote);

	return (
		<>
			{trial && (
				<PicturePage
					filter={{ "metadata.trialId": trial._id }}
					hideTrialFilter
				/>
			)}
		</>
	);
}
