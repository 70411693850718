"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ELogLevel = void 0;
var ELogLevel;
(function (ELogLevel) {
    ELogLevel["LOG"] = "log";
    ELogLevel["ERROR"] = "error";
    ELogLevel["WARN"] = "warn";
    ELogLevel["DEBUG"] = "debug";
    ELogLevel["VERBOSE"] = "verbose";
})(ELogLevel = exports.ELogLevel || (exports.ELogLevel = {}));
