import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { formatString } from "common";
import { ETaskStatus, ETaskType, IUser, IVariableCore } from "shared-type";

import TopMenu from "App/components/base-components/menu/top-menu/TopMenu";
import CommentDrawer from "App/components/base-components/modal/comment-drawer/CommentDrawer";
import { CustomLinkTab } from "App/components/base-components/tabs/CustomLinkTab";
import { CustomTabs } from "App/components/base-components/tabs/CustomTabs";
import { ETooltipPosition } from "App/components/base-components/tooltip/CustomTooltip";
import ObservationRoundBanner from "App/components/task/observation-round/card/ObservationRoundBanner";
import ObsRoundModalSendNotebooks from "App/components/task/observation-round/modal/ObsRoundModalSendNotebooks";
import SelectUserTaskModal from "App/components/task/select-user/SelectUserObsRoundModal";
import CreateVariableGroupModal from "App/components/templates/experiment/variable-group/modal/create-variable-group/CreateVariableGroupModal";
import EditVariableGroupModal from "App/components/templates/experiment/variable-group/modal/edit-variable-group/EditVariableGroupModal";
import SelectVariableGroupModal from "App/components/templates/experiment/variable-group/modal/select-variable-group/SelectVariableGroupModal";
import { DIC, EDIC_KEY } from "App/dictionary";
import useAppSnackbar from "App/hooks/useAppSnackbar";
import { selectECW } from "App/redux/ECW/ECW.selector";
import { ECWAction } from "App/redux/ECW/ECW.slice";
import { trialWizardFetchAction } from "App/redux/ECW/trial-wizard-fetch-reducer";
import { apiGrowingArea } from "App/redux/growing-area/growing-area.api";
import { apiObsRound } from "App/redux/observation-round/obs-round.api";
import { apiTemplateObs } from "App/redux/template-obs/template-obs.api";
import { ApiErrorSnackbar } from "App/redux/utils/api-error-snackbar/ApiErrorSnackbar";
import { ReduxApiError } from "App/redux/utils/errors";
import { variablesCustomHook } from "App/redux/variables/variables.api";
import { PATH_GENERAL, PATH_NOTEBOOKS } from "App/routes";
import { AppDispatch } from "App/store";
import { getVariableInTemplate } from "../../../../../../../../components/templates/observation/TemplateObsEditionPage.utils";
import { apiMET } from "../../../../../../../../redux/experiment/met/met.api";
import {
	useObsRoundEdition,
	useObsRoundLoader,
} from "./ObsRoundEditionPage.utils";

import "./ObsRoundEditionPage.scss";

export default function ObsRoundEditionPage() {
	const dispatch = useDispatch<AppDispatch>();
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();
	const nav = useNavigate();
	const { trialId, obsRoundId, metId } = useParams();
	const location = useLocation();
	const isMET = useMemo(() => metId !== undefined, [metId]);

	// current context initializer
	const isInit = useObsRoundLoader(obsRoundId);
	const remoteTrial = useSelector(selectECW.trialRemote);
	const { data: remoteMET } = apiMET.useGetMETByIdQuery(metId ?? skipToken);

	const experiment = useMemo(() => {
		if (remoteMET) return remoteMET;

		return remoteTrial;
	}, [remoteMET, remoteTrial]);

	const { data: growingArea } = apiGrowingArea.useGetGrowingAreaByIdQuery(
		remoteTrial?.growingAreaId ?? "",
	);
	const { data: templateObs } = apiTemplateObs.useGetTemplateObsByIdQuery(
		experiment?.templateObsId,
	);
	const { data: ontologyVariables } =
		variablesCustomHook.useGetOntologyVariableListDeserialized();

	const variableInTemplate: IVariableCore[] = getVariableInTemplate(
		templateObs,
		ontologyVariables,
	);

	// State
	const isGenNotebookInProgress = useRef(false);
	const [tabIdx, setTabIdx] = useState(0);
	const {
		currentObsRound,
		canDeleteObsRound,
		isSelectUserOpen,
		isSelectVarGroupOpen,
		isCreateVarGroupOpen,
		isConsultVarGroupOpen,
		isCommentOpen,
		isSendPopupOpen,
		setSelectUserOpen,
		updateCurrentObsRound,
		setCommentOpen,
		setSelectVarGroupOpen,
		setCreateVarGroupOpen,
		setConsultVarGroupOpen,
		setIsSendPopupOpen,
	} = useObsRoundEdition();

	// action
	const [deleteObsRoundById] = apiObsRound.useDeleteObsRoundByIdMutation();
	const [updateObsRoundById] = apiObsRound.useUpdateObsRoundByIdMutation();
	const [generateNotebook] =
		apiObsRound.useGenerateNotebooksByObsRoundIdMutation();

	const onGenerateNotebookClick = async () => {
		// cancel multiple click on button or wrong generation
		if (isGenNotebookInProgress.current || !obsRoundId || isMET) {
			return;
		}
		isGenNotebookInProgress.current = true;
		setIsSendPopupOpen(false);
		await generateNotebook(obsRoundId)
			.unwrap()
			.then(() => {
				enqueueSnackbarSuccess(
					formatString(
						DIC(EDIC_KEY.ENTITY_CREATED),
						DIC(EDIC_KEY.NOTEBOOKS),
					),
				);
			})
			.catch((err: ReduxApiError) => {
				console.warn(err);
				enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
			});
		isGenNotebookInProgress.current = false;
	};

	const errorsForGenerateNotebook = (): string[] | undefined => {
		const errors: string[] = [];
		if (!currentObsRound?.team || currentObsRound.team.length === 0) {
			errors.push(DIC(EDIC_KEY.AT_LEAST_ONE_USER_SELECTED));
		}
		if (errors.length === 0) return undefined;
		return errors;
	};

	const handleDelete = async () => {
		// impossible case
		if (!currentObsRound?._id) {
			return;
		}
		await deleteObsRoundById(currentObsRound._id)
			.unwrap()
			.then(() => {
				enqueueSnackbarSuccess(
					formatString(
						DIC(EDIC_KEY.ENTITY_DELETED),
						DIC(EDIC_KEY.OBSERVATION_ROUND),
					),
				);
				navBackToPlanning();
			})
			.catch((err: ReduxApiError) => {
				console.warn(err);
				enqueueSnackbarError(
					formatString(
						DIC(EDIC_KEY.CANT_ACTION_ENTITY),
						DIC(EDIC_KEY.DELETE).toLowerCase(),
						DIC(EDIC_KEY.OBSERVATION_ROUND).toLowerCase(),
					),
				);
			});
	};

	const handleUserChange = (clickedUser: IUser, checked: boolean) => {
		if (!currentObsRound) return;
		if (checked) {
			updateCurrentObsRound({
				team: [...currentObsRound.team, clickedUser.id],
			});
		} else {
			const newUserList = currentObsRound.team.filter(
				(userId) => userId !== clickedUser.id,
			);
			updateCurrentObsRound({
				team: [...newUserList],
			});
		}
	};

	const handleUsersCheck = (users: Array<IUser>, checked: boolean) => {
		if (!currentObsRound) return;
		const team: Array<string> = [];

		if (checked) {
			users.forEach((user) => {
				team.push(user.id);
			});
		}
		updateCurrentObsRound({
			team: team,
		});
	};

	const navBackToPlanning = () => {
		nav(-1);
	};
	const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
		setTabIdx(Number(newIndex));
	};
	useEffect(() => {
		const pathname = "/" + location.pathname.split("/").slice(-1);
		if (!pathname) return;

		switch (pathname) {
			case PATH_GENERAL:
				setTabIdx(0);
				break;
			case PATH_NOTEBOOKS:
				setTabIdx(1);
				break;
			default:
				nav(`.${PATH_GENERAL}`, { replace: true });
				setTabIdx(0);
				break;
		}
	}, [location, nav]);

	useEffect(() => {
		if (trialId) {
			dispatch(trialWizardFetchAction.fetchTrialAndDependencies(trialId));
		}
		return () => {
			dispatch(ECWAction.reset({ wizardType: "SET", isInit: false }));
		};
	}, [dispatch, trialId]);

	// Component return need to be at the end of the file
	// https://reactjs.org/link/rules-of-hooks
	if (!isInit) {
		return (
			<div className="ObsRoundEditionPage-container full-parent-size">
				{DIC(EDIC_KEY.LOADING) + "..."}
			</div>
		);
	}
	if (!obsRoundId) {
		return (
			<div className="ObsRoundEditionPage-container full-parent-size">
				{DIC(EDIC_KEY.ERROR) + "..."}
			</div>
		);
	}
	return (
		<div className="ObsRoundEditionPage-container full-parent-size">
			<div className="ObsRoundEditionPage-edit-part flex-column">
				{currentObsRound && (
					<TopMenu
						deleteButton={{
							onClick: handleDelete,
							isDisabled: !canDeleteObsRound,
							disabledText: DIC(
								EDIC_KEY.THIS_OBS_ROUND_HAS_NOTEBOOKS,
							),
							disabledTextPosition: ETooltipPosition.UNDER,
						}}
						onBackClick={() => navBackToPlanning()}
						onCommentClick={() => {
							setCommentOpen(true);
							setCreateVarGroupOpen(false);
							setSelectVarGroupOpen(false);
							setSelectUserOpen(false);
						}}
					/>
				)}
				<div className="ObsRoundEditionPage-header-banner">
					{currentObsRound && (
						<ObservationRoundBanner
							isTemplate={isMET}
							observationRound={currentObsRound}
							disabledClick
						/>
					)}
				</div>
				<div className="ObsRoundEditionPage-tabs flex-column ">
					<div>
						<CustomTabs
							value={tabIdx}
							onChange={handleTabChange}
							indicatorColor="secondary"
						>
							<CustomLinkTab
								label={DIC(EDIC_KEY.GENERAL)}
								href={"." + PATH_GENERAL}
								navigateOption={{ replace: true }}
							/>
							{!isMET && (
								<CustomLinkTab
									label={DIC(EDIC_KEY.NOTEBOOKS)}
									href={"." + PATH_NOTEBOOKS}
									navigateOption={{ replace: true }}
								/>
							)}
						</CustomTabs>
					</div>
				</div>
				<div className="take-remaining-space">
					<Outlet />
				</div>
			</div>
			{isSelectUserOpen &&
				remoteTrial &&
				currentObsRound &&
				currentObsRound.status === ETaskStatus.DRAFT && (
					<SelectUserTaskModal
						currentUserIds={currentObsRound.team}
						onUserCheck={handleUserChange}
						onUsersCheck={handleUsersCheck}
						onClose={() => setSelectUserOpen(false)}
						trialOwnerId={
							remoteTrial.systemMetadata.createdBy ?? ""
						}
						growingAreaUsersIds={growingArea?.users ?? []}
					/>
				)}
			{isSelectVarGroupOpen && templateObs && (
				<SelectVariableGroupModal
					templateObsId={templateObs._id}
					selectedGroupIds={[currentObsRound?.variableGroupId ?? ""]}
					onVariableGroupSelect={(varGroupId) => {
						updateCurrentObsRound({
							variableGroupId: varGroupId,
						});
					}}
					onVarGroupCreateClick={() => {
						setCreateVarGroupOpen(true);
						setSelectVarGroupOpen(false);
						setSelectUserOpen(false);
						setCommentOpen(false);
					}}
					onClose={() => {
						setSelectVarGroupOpen(false);
						setCreateVarGroupOpen(false);
					}}
				/>
			)}
			{templateObs && (
				<CreateVariableGroupModal
					onClose={() => setCreateVarGroupOpen(false)}
					templateObservation={templateObs}
					variableInTemplate={variableInTemplate}
					onVariableGroupCreated={(varGroupId) => {
						updateCurrentObsRound({
							variableGroupId: varGroupId,
						});
					}}
					open={isCreateVarGroupOpen}
				/>
			)}
			{currentObsRound && currentObsRound.variableGroupId && (
				<EditVariableGroupModal
					onClose={() => {
						setConsultVarGroupOpen(false);
					}}
					templateObservation={templateObs}
					variableInTemplate={variableInTemplate}
					varGroupId={currentObsRound.variableGroupId}
					isEditionDisabled={true}
					open={isConsultVarGroupOpen}
				/>
			)}
			{isCommentOpen && currentObsRound && (
				<CommentDrawer
					onClose={() => setCommentOpen(false)}
					remoteComments={currentObsRound.comments}
					onSaveComment={async (comments) =>
						await updateObsRoundById({
							id: currentObsRound._id,
							update: {
								comments,
								type: ETaskType.OBSERVATION_ROUND,
							},
						})
							.unwrap()
							.then(() => {
								// Need to put new comment in the state
								updateCurrentObsRound({
									comments,
								});
							})
					}
				/>
			)}
			{isSendPopupOpen && (
				<ObsRoundModalSendNotebooks
					onClose={() => setIsSendPopupOpen(false)}
					generateNotebook={onGenerateNotebookClick}
					errors={errorsForGenerateNotebook()}
				/>
			)}
		</div>
	);
}
