import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { INotationSerialized, IPutNotationReview } from "shared-type";
import { formatString } from "common";

import { DIC, EDIC_KEY } from "../../dictionary";
import { useRequest } from "../../request-provider";
import { defaultApiError } from "../utils/errors";

export const apiNotation = createApi({
	reducerPath: "api-notation",
	baseQuery: () => ({ data: undefined }),
	// global configuration for the api (in second)
	keepUnusedDataFor: 360,
	// global configuration for the api
	refetchOnMountOrArgChange: 360,
	tagTypes: ["Update", "Delete", "Create"],
	endpoints: (build) => ({
		getNotationByNotebookIds: build.query<
			INotationSerialized[] | undefined,
			string[]
		>({
			queryFn: async (notebookIds) => {
				try {
					if (notebookIds.length === 0) {
						return { data: [] };
					}

					const result =
						await useRequest().notation.getNotationsByNotebookIdsSerialized(
							notebookIds,
						);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.FETCH).toLowerCase(),
							DIC(EDIC_KEY.NOTATIONS).toLowerCase(), // FIXME CHANGE
						),
					);
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),
		getNotationByTrialId: build.query<
			INotationSerialized[] | undefined,
			string | undefined
		>({
			queryFn: async (trialId) => {
				try {
					if (trialId === undefined) {
						return { data: [] };
					}

					const result =
						await useRequest().notation.getNotationByTrialIdSerialized(
							trialId,
						);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.FETCH).toLowerCase(),
							DIC(EDIC_KEY.NOTATIONS).toLowerCase(), // FIXME CHANGE
						),
					);
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),
		updateManyNotationsReview: build.mutation<
			undefined,
			IPutNotationReview[]
		>({
			queryFn: async (notationReviewToUpdates) => {
				if (notationReviewToUpdates.length === 0) {
					return { data: undefined };
				}
				try {
					await useRequest()
						.notation.putManyNotationReview(notationReviewToUpdates)
						.then((resp) => resp.data);
					return { data: undefined };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to update the notations review",
					);
				}
			},
			invalidatesTags: [{ type: "Update", id: "ALL" }],
		}),
	}),
});
