"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EChartIdentifier = exports.EChartTrialAnalysis = exports.EChartDashboardTeamDevelopers = exports.EChartDashboardTeamManager = exports.EChartDashboardNetwork = exports.EChartDashboardVariety = exports.EChartAnalysisContext = exports.EChartType = void 0;
var EChartType;
(function (EChartType) {
    EChartType["BAR_GROUPED"] = "barGrouped";
    EChartType["BAR_STACKED"] = "barStacked";
    EChartType["BAR_STACKED_PERCENT"] = "barStackedPercent";
    EChartType["BAR_COLORED"] = "barColored";
    EChartType["COLUMN_GROUPED"] = "columnGrouped";
    EChartType["COLUMN_STACKED"] = "columnStacked";
    EChartType["COLUMN_STACKED_PERCENT"] = "columnStackedPercent";
    EChartType["COLUMN_COLORED"] = "columnColored";
    EChartType["COMBO_GROUP"] = "comboGroup";
    EChartType["COMBO_STACKED"] = "comboStacked";
    EChartType["LINE_DISCRETE"] = "lineDiscrete";
    EChartType["LINE_CONTINUOUS"] = "lineContinuous";
    EChartType["AREA_DISCRETE"] = "areaDiscrete";
    EChartType["AREA_CONTINUOUS"] = "areaContinuous";
    EChartType["AREA_STACKED_PERCENT"] = "areaStackedPercent";
    EChartType["GRID_HEATMAP"] = "gridHeatmap";
    EChartType["GRID_SCATTER"] = "gridScatter";
    EChartType["CIRCULAR_DONUT"] = "circularDonut";
    EChartType["CIRCULAR_GAUGE"] = "circularGauge";
    EChartType["TEXT_TABLE"] = "textTable";
    EChartType["TEXT_NUMBER"] = "textNumber";
    EChartType["TEXT_TOP_ITEM"] = "textTopItem";
    EChartType["TEXT_WORD_CLOUD"] = "textWordCloud";
    EChartType["GEO_CHOROPLETH"] = "geoChoropleth";
    EChartType["GEO_SCATTER"] = "geoScatter";
    EChartType["GEO_HEATMAP"] = "geoHeatmap";
})(EChartType = exports.EChartType || (exports.EChartType = {}));
var EChartAnalysisContext;
(function (EChartAnalysisContext) {
    EChartAnalysisContext["TRIAL"] = "trial";
    EChartAnalysisContext["DASHBOARD_VARIETY"] = "dashboardVariety";
    EChartAnalysisContext["DASHBOARD_NETWORK"] = "dashboardNetwork";
    EChartAnalysisContext["DASHBOARD_TEAM_MANAGER"] = "dashboardTeamManager";
    EChartAnalysisContext["DASHBOARD_TEAM_DEVELOPERS"] = "dashboardTeamDevelopers";
})(EChartAnalysisContext = exports.EChartAnalysisContext || (exports.EChartAnalysisContext = {}));
var EChartDashboardVariety;
(function (EChartDashboardVariety) {
    EChartDashboardVariety["TRIAL_COUNT"] = "DASHBOARD_VARIETY_TRIAL_COUNT";
    EChartDashboardVariety["MATERIAL_TABLE"] = "DASHBOARD_VARIETY_MATERIAL_TABLE";
    EChartDashboardVariety["MATERIAL_VARIABLE_GEO"] = "DASHBOARD_VARIETY_MATERIAL_VARIABLE_GEO";
    EChartDashboardVariety["MATERIAL_VARIABLE"] = "DASHBOARD_VARIETY_MATERIAL_VARIABLE";
})(EChartDashboardVariety = exports.EChartDashboardVariety || (exports.EChartDashboardVariety = {}));
var EChartDashboardNetwork;
(function (EChartDashboardNetwork) {
    EChartDashboardNetwork["MET_COUNT"] = "DASHBOARD_NETWORK_MET_COUNT";
    EChartDashboardNetwork["MET_TABLE"] = "DASHBOARD_NETWORK_MET_TABLE";
    EChartDashboardNetwork["SPECIES_TABLE"] = "DASHBOARD_NETWORK_SPECIES_TABLE";
    EChartDashboardNetwork["TRIAL_COUNT"] = "DASHBOARD_NETWORK_TRIAL_COUNT";
    EChartDashboardNetwork["TRIAL_STATUS"] = "DASHBOARD_NETWORK_TRIAL_STATUS";
    EChartDashboardNetwork["TRIAL_STATUS_GEO"] = "DASHBOARD_NETWORK_TRIAL_STATUS_GEO";
    EChartDashboardNetwork["OVERVIEW_LOCATION"] = "DASHBOARD_NETWORK_OVERVIEW_LOCATION";
    EChartDashboardNetwork["GA_GEO"] = "DASHBOARD_NETWORK_GA_GEO";
})(EChartDashboardNetwork = exports.EChartDashboardNetwork || (exports.EChartDashboardNetwork = {}));
var EChartDashboardTeamManager;
(function (EChartDashboardTeamManager) {
    EChartDashboardTeamManager["TRIAL_COUNT"] = "DASHBOARD_TEAM_MANAGER_TRIAL_COUNT";
    EChartDashboardTeamManager["TRIAL_STATUS"] = "DASHBOARD_TEAM_MANAGER_TRIAL_STATUS";
    EChartDashboardTeamManager["MET_COUNT"] = "DASHBOARD_TEAM_MANAGER_MET_COUNT";
    EChartDashboardTeamManager["TRIAL_STATUS_GEO"] = "DASHBOARD_TEAM_MANAGER_TRIAL_STATUS_GEO";
    EChartDashboardTeamManager["TRIAL_TABLE"] = "DASHBOARD_TEAM_MANAGER_TRIAL_TABLE";
    EChartDashboardTeamManager["OPERATION_PROGRESS_TABLE"] = "DASHBOARD_TEAM_MANAGER_OPERATION_PROGRESS_TABLE";
})(EChartDashboardTeamManager = exports.EChartDashboardTeamManager || (exports.EChartDashboardTeamManager = {}));
var EChartDashboardTeamDevelopers;
(function (EChartDashboardTeamDevelopers) {
    EChartDashboardTeamDevelopers["TRIAL_COUNT"] = "DASHBOARD_TEAM_DEVELOPERS_TRIAL_COUNT";
    EChartDashboardTeamDevelopers["TRIAL_STATUS"] = "DASHBOARD_TEAM_DEVELOPERS_TRIAL_STATUS";
    EChartDashboardTeamDevelopers["MET_COUNT"] = "DASHBOARD_TEAM_DEVELOPERS_MET_COUNT";
    EChartDashboardTeamDevelopers["TRIAL_STATUS_GEO"] = "DASHBOARD_TEAM_DEVELOPERS_TRIAL_STATUS_GEO";
    EChartDashboardTeamDevelopers["TRIAL_TABLE"] = "DASHBOARD_TEAM_DEVELOPERS_TRIAL_TABLE";
    EChartDashboardTeamDevelopers["OPERATION_PROGRESS_TABLE"] = "DASHBOARD_TEAM_DEVELOPERS_OPERATION_PROGRESS_TABLE";
})(EChartDashboardTeamDevelopers = exports.EChartDashboardTeamDevelopers || (exports.EChartDashboardTeamDevelopers = {}));
var EChartTrialAnalysis;
(function (EChartTrialAnalysis) {
    EChartTrialAnalysis["MATERIAL_GRID"] = "TRIAL_MATERIAL_GRID";
    EChartTrialAnalysis["MATERIAL_TIME"] = "TRIAL_MATERIAL_TIME";
})(EChartTrialAnalysis = exports.EChartTrialAnalysis || (exports.EChartTrialAnalysis = {}));
exports.EChartIdentifier = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, EChartDashboardVariety), EChartDashboardNetwork), EChartDashboardTeamManager), EChartDashboardTeamDevelopers), EChartTrialAnalysis);
