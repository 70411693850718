import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { PlainObject } from "@mongodb-js/charts-embed-dom/dist/declarations/src/types";
import React, { useEffect, useMemo, useState } from "react";

import {
	EChartAnalysisContext,
	EChartDashboardVariety,
	EVariableCoreType,
	IChart,
	IDashboardVarietySubFilter,
	IDashboardVarietyFilterMQL,
} from "shared-type";

import { DIC, EDIC_KEY } from "../../../../dictionary";
import { apiChartCustomHook } from "../../../../redux/chart/chart.api";
import { apiGermplasm } from "../../../../redux/germplasm/germplasm.api";
import { variablesCustomHook } from "../../../../redux/variables/variables.api";
import ChartCard from "../../../base-components/chart/ChartCard";
import ChipCombobox from "../../../base-components/combobox/chip-combobox/ChipCombobox";
import Combobox from "../../../base-components/combobox/Combobox";

import "./MaterialDetailCharts.scss";
// fetch data for dashboard
function useFetchVarietyCharts() {
	const { data: genotypeList, isSuccess: isSuccessGermplasm } =
		apiGermplasm.useGetGenotypesQuery();
	const { data: variables, isSuccess: isSuccessVariables } =
		variablesCustomHook.useGetOntologyVariableListDeserialized();

	const isFetchSuccess = useMemo(
		() => isSuccessGermplasm && isSuccessVariables,
		[isSuccessVariables, isSuccessGermplasm],
	);

	return { genotypeList, variables, isFetchSuccess };
}

export default function MaterialDetailCharts({
	sdk,
	filterGlobal,
}: {
	sdk: ChartsEmbedSDK;
	filterGlobal: PlainObject | undefined;
}) {
	const { genotypeList, variables, isFetchSuccess } = useFetchVarietyCharts();

	const { chart: chartVarietyMaterialVariableGeo, isSuccess: isChartLoad } =
		apiChartCustomHook.useChart<IDashboardVarietySubFilter>(
			EChartAnalysisContext.DASHBOARD_VARIETY,
			EChartDashboardVariety.MATERIAL_VARIABLE_GEO,
		);
	const { chart: chartVarietyMaterialVariable } = apiChartCustomHook.useChart(
		EChartAnalysisContext.DASHBOARD_VARIETY,
		EChartDashboardVariety.MATERIAL_VARIABLE,
	);

	const [genotypes, setGenotypes] = useState<string[] | undefined>(undefined);
	const [variable, setVariable] = useState<string | undefined>(undefined);

	const variablesSorted = useMemo(() => {
		if (!variables || variables.length === 0) return [];
		return variables
			.filter(
				// keep only numerical
				(variable) =>
					variable.type === EVariableCoreType.INT ||
					variable.type === EVariableCoreType.DEC,
			)
			.sort((varA, varB) => varA.name.en.localeCompare(varB.name.en))
			.map((variable) => variable.name.en);
	}, [variables]);

	const genotypeSorted = useMemo(() => {
		if (!genotypeList) return [];
		return [...genotypeList]
			.sort((genA, genB) => {
				return String(genA.variety ?? genA.name).localeCompare(
					String(genB.variety ?? genB.name),
				);
			})
			.map(
				(genotype) => genotype.variety ?? `${genotype.name} (genotype)`,
			);
	}, [genotypeList]);

	useEffect(() => {
		if (!isFetchSuccess) {
			return;
		}

		// Init when data are fetched
		const { filter } =
			(chartVarietyMaterialVariableGeo as IChart<IDashboardVarietySubFilter>) ||
			{};

		// Set default filter on genotype name
		let defaultGenotypes: string[] = filter ? filter["genotype.name"] : [];
		if (genotypeSorted.length > 0 && genotypes === undefined) {
			if (defaultGenotypes.length === 0) {
				defaultGenotypes = genotypeSorted;
			}
			setGenotypes(defaultGenotypes);
		}

		// Set default filter on variable name
		let defaultVariable: string | undefined = filter
			? filter["variable.name"]
			: undefined;
		if (variablesSorted.length > 0 && variable === undefined) {
			if (!defaultVariable) {
				defaultVariable = variablesSorted[0];
			}
			setVariable(defaultVariable);
		}
	}, [
		chartVarietyMaterialVariableGeo,
		genotypes,
		variable,
		genotypeSorted,
		variablesSorted,
		isFetchSuccess,
		isChartLoad,
	]);

	const additionalFilter = useMemo(() => {
		const filters: Partial<IDashboardVarietyFilterMQL>[] = [
			{ "variable.name": variable },
		];
		if (genotypes && genotypes.length > 0) {
			filters.push({ "genotype.name": { $in: genotypes } });
		}
		return { $and: filters };
	}, [variable, genotypes]);

	return (
		<div className="MaterialDetailCharts-composed-container flex-column">
			<div>
				<h2 className="DashboardPage-composed-text">
					{chartVarietyMaterialVariableGeo?.name}
				</h2>
				<p className="DashboardPage-composed-text">
					{chartVarietyMaterialVariableGeo?.description}
				</p>
			</div>
			<div className="DashboardPage-composed-options">
				<Combobox<string>
					value={variable ?? ""}
					label={DIC(EDIC_KEY.VARIABLE)}
					onChange={setVariable}
					comboboxValues={variablesSorted}
					translateMethod={(value) => value}
					className="DashboardPage-composed-combobox-small"
				/>
				<ChipCombobox<string>
					value={genotypes ?? []}
					label={DIC(EDIC_KEY.MATERIAL)}
					onChange={setGenotypes}
					comboboxValues={genotypeSorted}
					translateMethod={(value) => value}
					noWrap
					className="DashboardPage-composed-combobox"
					selectAllBtn
				/>
			</div>
			<div className="DashboardVarietyPage-double-charts">
				{filterGlobal &&
					additionalFilter &&
					chartVarietyMaterialVariableGeo && (
						<ChartCard
							chartName={chartVarietyMaterialVariableGeo.name}
							sdk={sdk}
							chartId={
								chartVarietyMaterialVariableGeo.atlasEmbeddedId
							}
							filter={filterGlobal}
							additionalFilter={additionalFilter}
							className="MaterialDetailCharts-chart-materialVariable-geo"
						/>
					)}
				{filterGlobal &&
					additionalFilter &&
					chartVarietyMaterialVariable && (
						<ChartCard
							chartName={chartVarietyMaterialVariable.name}
							sdk={sdk}
							chartId={
								chartVarietyMaterialVariable.atlasEmbeddedId
							}
							filter={filterGlobal}
							additionalFilter={additionalFilter}
							className="MaterialDetailCharts-chart-material-variable"
						/>
					)}
			</div>
		</div>
	);
}
