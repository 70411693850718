import React from "react";

import ProjectCard from "../../../../components/project/card/ProjectCard";
import { Title } from "stories/base-components/Typography/Typography";
import { DIC, EDIC_KEY } from "App/dictionary";

import "./ProjectPage.scss";

export default function ProjectPage() {
	return (
		<div className="ProjectPage-container">
			<Title text={DIC(EDIC_KEY.PROJECTS)} />
			<div className="ProjectPage-list">
				<ProjectCard />
			</div>
		</div>
	);
}
