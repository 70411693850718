import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { paginateList } from "common";
import { IGrowingArea, ISort } from "shared-type";

import {
	convertDorObjFilterToFilter,
	IDorianeObjectFilter,
} from "App/interfaces/filters/doriane-filter-interface";
import { selectECW } from "App/redux/ECW/ECW.selector";
import { apiMarketSegment } from "App/redux/market-segment/market-segment.api";
import { apiUser } from "App/redux/user/user.api";
import { growingAreaCustomHook } from "../../../redux/growing-area/growing-area.api";
import { IGrowingAreaRow } from "../../experiment/met/edition/trials-edition/modal/interface";

export function useGrowingAreaWithUsersAndMarketSegments(props: {
	sort: ISort;
	filter: IDorianeObjectFilter;
	pagination?: { page: number; pageSize: number };
}) {
	const filterForQuery = useMemo(
		() => convertDorObjFilterToFilter(props.filter),
		[props.filter],
	);

	// #region ------ Display growing area with market segments present in experiment first -------
	const mkSegmentIds = useSelector(
		selectECW.generalFormInfo,
	).marketSegmentIds;
	const { data: experimentMkSegs } =
		apiMarketSegment.useGetMarketSegmentByIdsQuery(mkSegmentIds ?? []);

	const expMkSegGAIds = experimentMkSegs?.flatMap((elt) => elt.growingAreas);
	// The growing area of the market segment in the experiment
	const { data: experimentMkSegGAs } =
		growingAreaCustomHook.useGetGrowingAreasQueryDeserialized({
			sort: props.sort,
			filter: {
				...filterForQuery,
				_id: { $in: expMkSegGAIds },
			} as any,
		});
	// The rest
	const { data: othersGrowingAreas } =
		growingAreaCustomHook.useGetGrowingAreasQueryDeserialized({
			sort: props.sort,
			filter: {
				...filterForQuery,
				_id: { $not: { $in: expMkSegGAIds ?? [] } },
			} as any,
		});

	// IMPORTANT : We want to show first the experiment marketSegment's GA, then the others.
	const allGrowingAreasOrdered = useMemo(() => {
		let allGrowingAreas: IGrowingArea[] = othersGrowingAreas ?? [];
		if (experimentMkSegGAs !== undefined) {
			allGrowingAreas = [...experimentMkSegGAs, ...allGrowingAreas];
		}
		return allGrowingAreas;
	}, [experimentMkSegGAs, othersGrowingAreas]);

	const allGrowingAreasMapById = useMemo(() => {
		return new Map(allGrowingAreasOrdered.map((elt) => [elt._id, elt]));
	}, [allGrowingAreasOrdered]);
	// #endregion

	const { data: allMarketSegments } =
		apiMarketSegment.useGetMarketSegmentsQuery({});
	const { data: allUsers } = apiUser.useGetUsersQuery();

	const sortedGAsWithInfos = useMemo((): IGrowingAreaRow[] => {
		const growingAreasOrdered: IGrowingAreaRow[] = _.cloneDeep(
			allGrowingAreasOrdered,
		);
		growingAreasOrdered.forEach((currentGA) => {
			// market segment of current growing area
			const currentMkSegOfGA = allMarketSegments?.filter((mk) =>
				mk.growingAreas.includes(currentGA._id),
			);
			currentGA.marketSegments = currentMkSegOfGA;
			currentGA.marketSegmentsNames = currentMkSegOfGA?.map(
				(elt) => elt.name,
			);
			const currentUsers = allUsers?.filter((user) =>
				currentGA.users.includes(user.id),
			);
			currentGA.usersArray = currentUsers;
		});
		return growingAreasOrdered;
	}, [allGrowingAreasOrdered, allMarketSegments, allUsers]);

	const paginatedGAWithInfos: {
		sortedGAsWithInfos: IGrowingAreaRow[];
		totalPages: number | undefined;
		allGrowingAreasMapById: Map<string, IGrowingArea>;
	} = useMemo(() => {
		if (props.pagination) {
			const result = paginateList<IGrowingAreaRow>(
				sortedGAsWithInfos,
				props.pagination.page,
				props.pagination.pageSize,
			);
			return {
				sortedGAsWithInfos: result.paginatedList,
				totalPages: result.totalPages,
				allGrowingAreasMapById,
			};
		}
		return {
			sortedGAsWithInfos,
			totalPages: undefined,
			allGrowingAreasMapById,
		};
	}, [props.pagination, sortedGAsWithInfos, allGrowingAreasMapById]);

	return paginatedGAWithInfos;
}
