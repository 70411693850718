import * as React from "react";

import ModalTitle from "./ModalTitle";
import Size from "stories/constants/Size/Size";

import "./DorianeDrawer.scss";

interface IPopUpProps {
	children?: React.ReactNode;
	colorSlide?: string;
	onClose: () => void;
	title: string;
	className?: string;
	absolutePos?: boolean;
	takeRemainingSpace?: boolean;
	width?: string;
	appBarSpacing?: boolean;
}

export default function DorianeDrawer(props: IPopUpProps) {
	const computeClassName = () => {
		let className = "DorianeDrawer-container flex-column";

		if (props.absolutePos) {
			className += " DorianeDrawer-absolute";
		}
		if (props.takeRemainingSpace) {
			className += " DorianeDrawer-take-space";
		}
		return className;
	};

	return (
		<div
			className={
				computeClassName() +
				(props.className ? " " + props.className : "")
			}
			style={{
				marginTop: props.appBarSpacing ? Size.x3l : undefined,
				width: props.width !== undefined ? props.width : undefined,
			}}
		>
			<ModalTitle
				colorSlide={props.colorSlide}
				onClose={props.onClose}
				title={props.title}
			/>
			{props.children}
		</div>
	);
}
