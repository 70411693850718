import { Cross, Delete } from "assets";
import React from "react";

import DorianeButton from "./DorianeButton";

interface DeleteButtonProps {
	onDelete: () => void;
	disabled?: boolean;
	className?: string;
	icon: "trash" | "cross";
}
export default function DeleteButton(props: DeleteButtonProps) {
	return (
		<DorianeButton
			className={props.className}
			onClick={() => props.onDelete()}
			disabled={props.disabled}
			dorianeStyle="quaternary"
		>
			{props.icon === "trash" ? (
				<img src={Delete} />
			) : (
				<img src={Cross} />
			)}
		</DorianeButton>
	);
}
