import React, { useMemo, useState } from "react";

import { paginateList } from "common";
import { EUserRole, IUser } from "shared-type";

import {
	Book,
	CommunicationMobile,
	Field,
	ObserverIcon,
} from "../../../../assets";
import { USER_SELECTION_DEFAULT_PAGE_SIZE } from "../../../const";
import { DIC, EDIC_KEY } from "../../../dictionary";
import {
	IDorianeFilter,
	IDorianeObjectFilter,
} from "../../../interfaces/filters/doriane-filter-interface";
import { apiUserCustomHook } from "../../../redux/user/user.api";
import { userColumnsForTaskSelection } from "../../base-components/data-grid/doriane-data-grid/default-grid-columns/UserColumns";
import DorianeDataGrid from "../../base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import DorianeDrawer from "../../base-components/modal/DorianeDrawer";
import PaginationTool from "../../base-components/pagination/PaginationTool";
import FlexBox from "../../base-components/placement/flex-box/FlexBox";
import { ETaskUserSource, IUserWithTaskSource } from "./types";
import { EPaddingSize } from "../../base-components/placement/box/Box";

interface SelectUserTaskModalProps {
	onClose: () => void;
	currentUserIds: string[];
	onUserCheck: (user: IUser, checked: boolean) => void;
	onUsersCheck?: (users: Array<IUser>, checked: boolean) => void;
	trialOwnerId: string;
	growingAreaUsersIds: string[];
}

export default function SelectUserTaskModal(props: SelectUserTaskModalProps) {
	const [filter, setFilter] = useState<IDorianeObjectFilter>({});
	const [page, setPage] = useState<number>(0);

	const { data: filteredUserList } =
		apiUserCustomHook.useGetUsersWithFilter(filter);

	const paginatedUsers = useMemo(() => {
		return paginateList<IUser>(
			filteredUserList,
			page,
			USER_SELECTION_DEFAULT_PAGE_SIZE,
		);
	}, [filteredUserList, page]);

	const handleFilterChange = (newFilter: IDorianeFilter) => {
		setFilter((old) => {
			return { ...old, [newFilter.key]: newFilter };
		});
		setPage(0);
	};

	const isUserCheck = (user: IUser) => {
		const found = props.currentUserIds.find((userId) => userId === user.id);
		return found !== undefined;
	};

	const userSrcIconTitle = (user: IUserWithTaskSource) => {
		switch (user.source) {
			case ETaskUserSource.OWNER:
				return DIC(EDIC_KEY.EXPERIMENT_OWNER);
			case ETaskUserSource.GROWING_AREA:
				return DIC(EDIC_KEY.ASSOCIATED_GROWING_AREA_OBSERVERS);
			case ETaskUserSource.OTHER_OBSERVERS:
				return DIC(EDIC_KEY.OTHER_OBSERVERS);
			default:
				return DIC(EDIC_KEY.OTHERS);
		}
	};
	const userSrcIconSrc = (user: IUserWithTaskSource) => {
		switch (user.source) {
			case ETaskUserSource.OWNER:
				return Book;
			case ETaskUserSource.GROWING_AREA:
				return Field;
			case ETaskUserSource.OTHER_OBSERVERS:
				return ObserverIcon;
			default:
				return CommunicationMobile;
		}
	};
	const getDisplayUser = useMemo(() => {
		if (!paginatedUsers.paginatedList) return [];

		const gaSet = new Set(props.growingAreaUsersIds);
		const res: IUserWithTaskSource[] = [];

		for (const user of paginatedUsers.paginatedList) {
			if (user.id === props.trialOwnerId) {
				res.push({ ...user, source: ETaskUserSource.OWNER });
				continue;
			}
			if (gaSet.has(user.id)) {
				res.push({ ...user, source: ETaskUserSource.GROWING_AREA });
				continue;
			}
			if (user.role === EUserRole.OBSERVER) {
				res.push({
					...user,
					source: ETaskUserSource.OTHER_OBSERVERS,
				});
				continue;
			}
			res.push({ ...user, source: ETaskUserSource.OTHER });
		}
		return res.sort((userA, userB) => userA.source - userB.source);
	}, [
		paginatedUsers.paginatedList,
		props.growingAreaUsersIds,
		props.trialOwnerId,
	]);

	return (
		<DorianeDrawer
			onClose={props.onClose}
			title={DIC(EDIC_KEY.USERS)}
			takeRemainingSpace
		>
			<DorianeDataGrid
				className="take-remaining-space"
				columns={userColumnsForTaskSelection(
					{
						filter: filter,
						onFilterChange: handleFilterChange,
					},
					{ isCheck: isUserCheck, onCheck: props.onUserCheck },
					{ iconSrc: userSrcIconSrc, iconTitle: userSrcIconTitle },
					(check: boolean) => {
						if (props.onUsersCheck) {
							props.onUsersCheck(getDisplayUser, check);
						}
					},
				)}
				rows={getDisplayUser}
			/>
			<FlexBox justifyContent={"center"} padding={EPaddingSize.SMALL}>
				<PaginationTool
					currentPage={page}
					maxPage={paginatedUsers.totalPages}
					onPageRoll={(newPage) => setPage(newPage)}
				/>
			</FlexBox>
		</DorianeDrawer>
	);
}
