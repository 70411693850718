import React from "react";

import { IVariableGroupSerialized } from "shared-type";

import { DIC, EDIC_KEY } from "App/dictionary";
import { Badge } from "stories/base-components/Badge/Badge";
import { Card } from "stories/base-components/Card/Card";
import Colors from "stories/constants/Colors/Colors";

export interface VariableGroupCardProps {
	variableGroup: IVariableGroupSerialized;
	onClick?: () => void;
}

export default function VariableGroupCard(props: VariableGroupCardProps) {
	return (
		<Card
			label={props.variableGroup.name}
			subObject={
				<Badge
					text={
						props.variableGroup.variables.length +
						" " +
						DIC(EDIC_KEY.VARIABLES)
					}
					backgroundColor={Colors.primary10}
					color={Colors.primary60}
					iconName="variable-group"
				/>
			}
			backgroundColor={Colors.neutral10}
			onClick={props.onClick}
		/>
	);
}
