import React, { useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeGrid } from "react-window";
import { IFilter, IResource } from "shared-type";

import DorianeButton from "App/components/base-components/button/DorianeButton";
import TopMenu from "App/components/base-components/menu/top-menu/TopMenu";
import PictureCard, {
	PictureCardSize,
} from "App/components/picture/card/PictureCard";
import PictureDetailDrawer from "App/components/picture/detail/PictureDetailDrawer";
import PictureFilterDrawer from "App/components/picture/filter/PictureFilterDrawer";
import { apiResource } from "App/redux/attachment/resource.api";
import { Filter } from "assets";

import "./PicturePage.scss";

interface PicturePageProps {
	filter?: IFilter;
	hideTrialFilter?: boolean;
}

enum EPictureTools {
	FILTER,
	DETAILS,
	NULL,
}
type AutoSizerProps = {
	height: number;
	width: number;
};

export interface IPictureComplete {
	resource: IResource;
	trialName: string;
	variableName: string;
	materialName: string;
}

export default function PicturePage(props: PicturePageProps) {
	const [resourceIndex, setResourceIndex] = useState<number>(0);
	const [filter, setFilter] = useState<IFilter>();
	const [currentTool, setCurrentTool] = useState<EPictureTools>(
		EPictureTools.NULL,
	);
	const { data: resources } = apiResource.useGetsQuery({
		filter: {
			...props.filter,
			...filter,
		},
	});

	const getTool = () => {
		switch (currentTool) {
			case EPictureTools.DETAILS:
				return (
					<PictureDetailDrawer
						onClose={() => setCurrentTool(EPictureTools.NULL)}
						resource={(resources as IResource[])[resourceIndex]}
					/>
				);
			case EPictureTools.FILTER:
				return (
					<PictureFilterDrawer
						onFilterChange={setFilter}
						onClose={() => setCurrentTool(EPictureTools.NULL)}
						hideTrialFilter={props.hideTrialFilter}
					/>
				);
			case EPictureTools.NULL:
				return <></>;

			default:
				return <></>;
		}
	};

	const toggleFilter = () => {
		if (currentTool === EPictureTools.FILTER) {
			setCurrentTool(EPictureTools.NULL);
		} else {
			setCurrentTool(EPictureTools.FILTER);
		}
	};
	const imageByRow = 2;
	return (
		<div className="PicturePage-container full-parent-size">
			<div className="PicturePage-list-part full-parent-size flex-column">
				<TopMenu
					additionalButton={
						<DorianeButton
							dorianeStyle="quaternary"
							isActive={currentTool === EPictureTools.FILTER}
							className="ImgBannerPictureCard-side-banner-image-layer-like-button"
							onClick={toggleFilter}
						>
							<img
								className={"PicturePage-filter-button-icon"}
								src={Filter}
							/>
						</DorianeButton>
					}
				/>

				<div className="PicturePage-body flex-column take-remaining-space">
					{resources && (
						<AutoSizer>
							{({ height, width }: AutoSizerProps) => (
								<FixedSizeGrid
									width={width}
									height={height}
									rowHeight={PictureCardSize.height + 15}
									columnCount={imageByRow}
									columnWidth={PictureCardSize.width + 15}
									rowCount={
										resources?.length
											? Math.ceil(
													resources.length /
														imageByRow,
											  )
											: 0
									}
								>
									{({ columnIndex, rowIndex, style }) => {
										const index =
											columnIndex + rowIndex * imageByRow;
										const elt = resources[index];
										if (!elt) {
											return <div style={style}></div>;
										}
										return (
											<div style={style}>
												<PictureCard
													key={elt._id}
													resource={elt}
													onClick={() => {
														setResourceIndex(index);
														setCurrentTool(
															EPictureTools.DETAILS,
														);
													}}
												/>
											</div>
										);
									}}
								</FixedSizeGrid>
							)}
						</AutoSizer>
					)}
				</div>
			</div>
			{getTool()}
		</div>
	);
}
