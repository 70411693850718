import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { PlainObject } from "@mongodb-js/charts-embed-dom/dist/declarations/src/types";
import React from "react";

import { EChartAnalysisContext } from "shared-type";
import { MONGODB_ATLAS_CHARTS_BASE_URL } from "../../../const";
import { apiChartCustomHook } from "../../../redux/chart/chart.api";

import ChartCard from "../../base-components/chart/ChartCard";

const sdk = new ChartsEmbedSDK({
	baseUrl: MONGODB_ATLAS_CHARTS_BASE_URL,
});
export default function GridChartRenderer({
	context,
	identifier,
	className,
	graphFilter,
	gridRow,
	gridColumn,
}: {
	context: EChartAnalysisContext;
	identifier: string;
	className?: string;
	graphFilter: PlainObject;
	gridRow?: string;
	gridColumn?: string;
}) {
	const { chart } = apiChartCustomHook.useChart(context, identifier);

	return (
		<div
			className={"flex-column" + (className ? " " + className : "")}
			style={{
				gridRow: gridRow,
				gridColumn: gridColumn,
			}}
		>
			{chart && (
				<ChartCard
					key={chart._id}
					chartName={chart.name}
					sdk={sdk}
					title={chart.name}
					description={chart.description}
					chartId={chart.atlasEmbeddedId}
					filter={graphFilter}
					className={className}
				/>
			)}
		</div>
	);
}
