import { getUserIdFromToken } from "common";
import { IdTokenDecodePayload, IUserInfo } from "shared-type";

export function tokenToUserInfo(
	idTokenDecode: IdTokenDecodePayload
): IUserInfo {
	return {
		id: getUserIdFromToken(idTokenDecode) || "unknown",
		firstName: idTokenDecode.given_name,
		lastName: idTokenDecode.family_name,
		fullName: idTokenDecode.name,
	};
}
