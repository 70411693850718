import { useCallback } from "react";

import { ETaskType, ICreateOperation, IOperationSerialized } from "shared-type";

import { apiOperation } from "../../../../redux/operation/operation.api";
import {
	IOperationForm,
	operationFormToObject,
} from "../../../form/task/operation";

export type opActionType = "create" | "update" | "delete";

export function useOperationModalAction(
	isMET: boolean,
	expId?: string,
	fetchedOp?: IOperationSerialized,
) {
	const [createOperation] = apiOperation.useCreateOperationMutation();
	const [updateOperation] = apiOperation.useUpdateOperationByIdMutation();
	const [deleteOperation] = apiOperation.useDeleteOperationByIdMutation();

	const requestAction = useCallback(
		(data: IOperationForm, action: opActionType) => {
			const operation = operationFormToObject(data);

			if (action === "create") {
				// this method is called only if dependencies are not undefined
				operation.experimentId = expId;
				operation.isTemplate = isMET;
				operation.type = ETaskType.OPERATION;
				return createOperation(operation as ICreateOperation);
			}
			if (action === "update") {
				return updateOperation({
					// this method is called only if dependencies are not undefined
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					id: fetchedOp!._id,
					update: {
						...operation,
						type: ETaskType.OPERATION,
					},
				});
			}
			// this method is called only if dependencies are not undefined
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			return deleteOperation(fetchedOp!._id);
		},
		[
			deleteOperation,
			fetchedOp,
			expId,
			isMET,
			createOperation,
			updateOperation,
		],
	);

	const handleEndAction = useCallback(
		async (
			isCreation: boolean,
			action: opActionType,
			data: IOperationForm,
		) => {
			if ((isCreation && !expId) || (!isCreation && !fetchedOp)) return;

			return await requestAction(data, action).unwrap();
		},
		[fetchedOp, requestAction, expId],
	);

	return { handleEndAction };
}
