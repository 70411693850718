import * as Yup from "yup";

import { EEntitiesLength, EExperimentStatus } from "shared-type";

export const expConclusionValidator = Yup.object().shape({
	conclusion: Yup.string().max(EEntitiesLength.conclusion),
	status: Yup.mixed<EExperimentStatus>()
		.oneOf(Object.values(EExperimentStatus))
		.required(),
});
