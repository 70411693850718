import { createSelector } from "@reduxjs/toolkit";
import { NB_MAX_TRIAL_BY_MET } from "shared-type";
import { RootState } from "../../../store";

// SELECTOR
const trialSelectedWithoutGA = createSelector(
	(state: RootState) => state.METTrialManagement.trialsRows,
	(trials): number[] => {
		const result =
			trials
				?.filter((trial) => trial.isSelected && !trial.growingArea?.id)
				.map((trial) => trial.id) ?? [];
		return result;
	},
);
const trialSelectedWithGA = createSelector(
	(state: RootState) => state.METTrialManagement.trialsRows,
	(trials): number[] => {
		const result =
			trials
				?.filter((trial) => trial.isSelected && trial.growingArea?.id)
				.map((trial) => trial.id) ?? [];
		return result;
	},
);
const GAofSelectedTrial = createSelector(
	(state: RootState) => state.METTrialManagement.trialsRows,
	(trials): Set<string> => {
		return new Set(
			trials
				?.filter(
					(trial) =>
						trial.growingArea?.id !== undefined && trial.isSelected,
				)
				.map((trial) => trial.growingArea?.id ?? "impossible"),
		);
	},
);
const GAOfUnselectedTrial = createSelector(
	(state: RootState) => state.METTrialManagement.trialsRows,
	(trials): Set<string> => {
		return new Set(
			trials
				?.filter(
					(trial) =>
						trial.growingArea?.id !== undefined &&
						!trial.isSelected,
				)

				.map((trial) => trial.growingArea?.id ?? "impossible"),
		);
	},
);
const nbTrialRemaining = createSelector(
	(state: RootState) => state.METTrialManagement.trialsRows?.length ?? 0,
	(nbTrial) => NB_MAX_TRIAL_BY_MET - nbTrial,
);

const isGrowingAreaOpen = (state: RootState) =>
	state.METTrialManagement.isGrowingAreaOpen ?? false;

export const selectMETTrialManagement = {
	trialSelectedWithoutGA,
	trialSelectedWithGA,
	GAofSelectedTrial,
	GAOfUnselectedTrial,
	isGrowingAreaOpen,
	nbTrialRemaining,
};
