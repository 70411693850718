import { calculatePercentage } from "common";
import React from "react";

import "./ProgressBar.scss";

interface ProgressBarProps {
	currentProgress: number;
	goalProgress: number;
	isLabelHidden?: boolean;
	color?: string;
	title?: string;
	height?: string;
}

export default function ProgressBar(props: ProgressBarProps) {
	return (
		<div className="ProgressBar-container">
			<h1>{props?.title}</h1>
			<div
				className="ProgressBar-body"
				style={{ height: props.height ?? "20px" }}
			>
				<div
					className="ProgressBar-progress"
					style={{
						width: `${calculatePercentage(
							props.currentProgress,
							props.goalProgress,
						)}%`,
						backgroundColor: props.color,
					}}
				></div>
				<div className="ProgressBar-label">
					{!props.isLabelHidden &&
						`${props.currentProgress} / ${props.goalProgress}`}
				</div>
			</div>
		</div>
	);
}
