import React, { useMemo } from "react";

import { IVariableCore } from "shared-type";
import { formatString } from "common";

import { CheckBoxColumn } from "App/components/base-components/data-grid/doriane-data-grid/columns/CheckBoxColumn";
import {
	IVariableCoreRow,
	variableColumns,
} from "App/components/base-components/data-grid/doriane-data-grid/default-grid-columns/VariableColumns";
import DorianeDataGrid from "App/components/base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import { DIC, EDIC_KEY } from "App/dictionary";
import { useGetFilteredGridData } from "../../../../base-components/data-grid/doriane-data-grid/data-grid.utils";
import { Modal } from "stories/base-components/Modal/Modal";

export default function TemplateObsAddCustomVariablesModal(props: {
	variables: IVariableCoreRow[];
	variablesCheck: Set<string>;
	onVariableCheck: (newSet: IVariableCore, selected: boolean) => void;
	onVariableCheckMultiple?: (
		newSet: Array<IVariableCore>,
		selected: boolean,
	) => void;
	onClose: () => void;
	open: boolean;
	saveVariables: any;
	editDisplay?: boolean;
}) {
	const { columns, rowsToDisplay } = useGetFilteredGridData({
		columns: variableColumns,
		rows: props.variables,
	});

	const columnsWithCheck = useMemo(() => {
		const selectionColumn = CheckBoxColumn<IVariableCore>({
			isCheck: (row) => props.variablesCheck.has(row._id),
			onCheck: props.onVariableCheck,
			headerCheckAllAction: (checked) => {
				if (props.onVariableCheckMultiple) {
					props.onVariableCheckMultiple(rowsToDisplay, checked);
				}
			},
		});
		// Add at the start of the grid
		columns.splice(1, 0, selectionColumn);
		return columns;
	}, [props, columns, rowsToDisplay]);

	return (
		<Modal
			open={props.open}
			title={formatString(
				props.editDisplay
					? DIC(EDIC_KEY.EDIT_VARIABLES_TO_ENTITY)
					: DIC(EDIC_KEY.ADD_VARIABLES_TO_ENTITY),
				DIC(EDIC_KEY.TEMPLATE).toLowerCase(),
			)}
			handleClose={props.onClose}
			validateBtn={{
				action: () => {
					props.saveVariables();
					props.onClose();
				},
				label: props.editDisplay
					? DIC(EDIC_KEY.EDIT_VARIABLES)
					: DIC(EDIC_KEY.ADD_VARIABLES),
				isLoading: false,
			}}
			maxSize
		>
			<DorianeDataGrid<IVariableCore>
				className="full-parent-size"
				columns={columnsWithCheck}
				rows={rowsToDisplay}
			/>
		</Modal>
	);
}
