import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { PlainObject } from "@mongodb-js/charts-embed-dom/dist/declarations/src/types";
import React, { useMemo, useState } from "react";

import {
	EChartAnalysisContext,
	EChartDashboardVariety,
	EReviewStatus,
	IDashboardVarietyFilterMQL,
} from "shared-type";

import ChartCard from "../../../../components/base-components/chart/ChartCard";
import DashboardVarietyHeader from "../../../../components/dashboard/header/variety/DashboardVarietyHeader";
import { IDashboardHeaderFilter } from "../../../../components/dashboard/header/variety/types";
import MaterialDetailCharts from "../../../../components/dashboard/variety/material-detail-charts/MaterialDetailCharts";
import { MONGODB_ATLAS_CHARTS_BASE_URL } from "../../../../const";
import { apiChartCustomHook } from "../../../../redux/chart/chart.api";

import "./DashboardVarietyPage.scss";

export default function DashboardVarietyPage() {
	const sdk = new ChartsEmbedSDK({
		baseUrl: MONGODB_ATLAS_CHARTS_BASE_URL,
	});

	const { chart: varietyTrialCount } = apiChartCustomHook.useChart(
		EChartAnalysisContext.DASHBOARD_VARIETY,
		EChartDashboardVariety.TRIAL_COUNT,
	);
	const { chart: varietyTrialTable } = apiChartCustomHook.useChart(
		EChartAnalysisContext.DASHBOARD_VARIETY,
		EChartDashboardVariety.MATERIAL_TABLE,
	);

	const [filterGlobal, setFilterGlobal] = useState<
		IDashboardHeaderFilter | undefined
	>(undefined);
	const graphFilter = useMemo(() => {
		if (filterGlobal === undefined) {
			return undefined;
		}
		const dataStatus: number[] = [];
		if (filterGlobal?.validChecked) dataStatus.push(EReviewStatus.REVIEWED);
		if (filterGlobal?.inProgressChecked) dataStatus.push(EReviewStatus.NA);

		const filters: Partial<IDashboardVarietyFilterMQL>[] = [];
		if (filterGlobal?.expYears && filterGlobal.expYears.length > 0) {
			filters.push({ "trial.year": { $in: filterGlobal?.expYears } });
		}
		if (filterGlobal?.expMETIds && filterGlobal.expMETIds.length > 0) {
			filters.push({
				"met._id": {
					$in: filterGlobal?.expMETIds.map((elt) =>
						(elt === null ? null : { $oid: elt }),
					),
				},
			});
		}
		if (filterGlobal?.regions && filterGlobal?.regions.length > 0) {
			filters.push({
				"growingArea.region": { $in: filterGlobal?.regions },
			});
		}
		if (filterGlobal?.users && filterGlobal?.users.length > 0) {
			filters.push({ "notebook.user": { $in: filterGlobal?.users } });
		}
		if (filterGlobal?.species && filterGlobal?.species.length > 0) {
			filters.push({ "trial.species": { $in: filterGlobal?.species } });
		}
		if (dataStatus.length > 0) {
			filters.push({ "notation.status": { $in: dataStatus } });
		}
		if (filters.length === 0) {
			return {};
		}
		return { $and: filters } as PlainObject;
	}, [filterGlobal]);

	const isFilterReady = graphFilter !== undefined;
	return (
		<>
			<DashboardVarietyHeader
				filterGlobal={filterGlobal}
				submitNewFilter={setFilterGlobal}
			/>
			<div className="DashboardVarietyPage-body take-remaining-space flex-column">
				<div className="DashboardVarietyPage-double-charts">
					{isFilterReady && (
						<>
							{varietyTrialCount && (
								<ChartCard
									chartName={varietyTrialCount.name}
									sdk={sdk}
									title={varietyTrialCount.name}
									description={varietyTrialCount.description}
									chartId={varietyTrialCount.atlasEmbeddedId}
									filter={graphFilter}
									className={
										"DashboardVarietyPage-chart-exp-status"
									}
								/>
							)}
							{varietyTrialTable && (
								<ChartCard
									chartName={varietyTrialTable.name}
									sdk={sdk}
									title={varietyTrialTable.name}
									description={varietyTrialTable.description}
									chartId={varietyTrialTable.atlasEmbeddedId}
									filter={graphFilter}
									className={
										"DashboardVarietyPage-chart-material-grid"
									}
								/>
							)}
						</>
					)}
				</div>
				<MaterialDetailCharts sdk={sdk} filterGlobal={graphFilter} />
			</div>
		</>
	);
}
