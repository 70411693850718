import { ITemplateObsCoreSerialized } from "shared-type";

import { ITemplateObsForm } from "./TemplateObsForm";

export function templateObsFormToObject(
	form: Partial<ITemplateObsForm>,
): Partial<ITemplateObsCoreSerialized> {
	return {
		name: { en: form.name ?? "" },
		identifier: form.identifier,
		description: form.description,
		shortName: form.shortName,
		species: form.species,
	};
}

export function templateObsObjectToForm(
	templateObs: Partial<ITemplateObsCoreSerialized>,
): Partial<ITemplateObsForm> {
	return {
		name: templateObs.name?.en,
		identifier: templateObs.identifier,
		description: templateObs.description,
		shortName: templateObs.shortName,
		species: templateObs.species,
	};
}
