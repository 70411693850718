import { IVariableGroupSerialized } from "shared-type";

import { IVarGroupForm } from "./VariableGroupForm";

export function varGroupFormToObject(
	form: Partial<IVarGroupForm>,
): Partial<IVariableGroupSerialized> {
	return {
		name: form.name,
		variables: form.variables ?? [],
	};
}

export function varGroupObjectToForm(
	varGroup: Partial<IVariableGroupSerialized>,
): Partial<IVarGroupForm> {
	return {
		name: varGroup.name,
		variables: varGroup.variables ?? [],
	};
}
