import { AxiosInstance } from "axios";

export class AbstractRequest {
	protected axios: AxiosInstance;
	protected hostConfig: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		this.axios = axios;
		this.hostConfig = hostConfig;
	}
}
