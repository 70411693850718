import {
	CompositeFilterDescriptor,
	filterBy,
	orderBy,
	SortDescriptor,
} from "@progress/kendo-data-query";
import {
	Grid,
	GridColumn as Column,
	GridFilterChangeEvent,
	GridPageChangeEvent,
	GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import React, { useCallback, useMemo, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { useDebounce } from "use-debounce";

import { EntitySerializer } from "common";
import { IGrowingAreaSerialized } from "shared-type";

import { useGrowingAreaWithUsersAndMarketSegments } from "App/components/growing-area/hooks/growingAreaHook";
import { DIC, EDIC_KEY } from "../../../../../../dictionary";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../../hooks/reduxHook";
import { selectMETTrialManagement } from "../../../../../../redux/experiment/met/met-trial-management.select";
import { METTrialManagementActions } from "../../../../../../redux/experiment/met/met-trial-management.slice";
import {
	CheckboxCustomCell,
	CheckboxHeaderCell,
	GridCheckboxContext,
} from "../../../../../base-components/data-grid/kendo/cell/CheckboxCell";
import { KendoChipListCell } from "../../../../../base-components/data-grid/kendo/cell/ChipListCell";
import { UserCell } from "../../../../../base-components/data-grid/kendo/cell/UserCell";
import { ColumnMenu } from "../../../../../base-components/data-grid/kendo/menu/ColumnMenu";
import {
	isDisabledRowGetter,
	RowDisableRenderer,
} from "../../../../../base-components/data-grid/kendo/row/RowDisable";
import DorianeDrawer from "../../../../../base-components/modal/DorianeDrawer";
import { SelectedTrialGrowingAreaRow } from "./interface";

import "./METTrialsGA.scss";

const minWidth = 26;

const rowHeight = 55; // warn if you reduce the size, check the page size correctly display

export default function METTrialsGrowingAreaModal() {
	const dispatch = useAppDispatch();

	const onCloseModal = useCallback(() => {
		dispatch(METTrialManagementActions.setOpenGrowingAreaModal(false));
	}, [dispatch]);

	const [isHeaderCheck, setIsGeneralCheck] = useState(false);

	// #region Data filter/sort/page
	const [filter, setFilter] = useState<CompositeFilterDescriptor>({
		logic: "and",
		filters: [],
	});
	const [sort, setSort] = useState<SortDescriptor[]>([]);
	const [skip, setSkip] = React.useState<number>(0);
	const pageChange = (event: GridPageChangeEvent) => {
		setSkip(event.page.skip);
	};
	// #endregion --------------------
	const GAofSelectedTrial = useAppSelector<Set<string>>(
		selectMETTrialManagement.GAofSelectedTrial,
	);

	const GAOfUnselectedTrial = useAppSelector<Set<string>>(
		selectMETTrialManagement.GAOfUnselectedTrial,
	);

	const trialToSelectGA = useAppSelector(
		selectMETTrialManagement.trialSelectedWithoutGA,
	);
	const trialToUnselectGA = useAppSelector(
		selectMETTrialManagement.trialSelectedWithGA,
	);

	const onGrowingAreaSelected = useCallback(
		({
			id,
			select,
			growingArea,
		}: {
			id: string;
			growingArea: IGrowingAreaSerialized;
			select: boolean;
		}) => {
			if (select) {
				const firstTrialIdWithoutGa = trialToSelectGA[0];
				if (firstTrialIdWithoutGa === undefined) {
					return;
				}
				dispatch(
					METTrialManagementActions.onAddGAForTrial({
						rowId: firstTrialIdWithoutGa,
						growingArea,
					}),
				);
			} else {
				dispatch(
					METTrialManagementActions.onRemoveGAForTrial({
						growingAreaId: id,
					}),
				);
			}
		},
		[dispatch, trialToSelectGA],
	);

	const rowIsSelected = useCallback(
		(id: string) =>
			GAofSelectedTrial.has(id) || GAOfUnselectedTrial.has(id),
		[GAofSelectedTrial, GAOfUnselectedTrial],
	);

	const isDisabledRow = useCallback(
		(rowId: string) => {
			if (GAOfUnselectedTrial.has(rowId)) {
				return true;
			}
			if (trialToSelectGA.length === 0 && !GAofSelectedTrial.has(rowId)) {
				return true;
			}
			return false;
		},
		[GAofSelectedTrial, GAOfUnselectedTrial, trialToSelectGA.length],
	);

	// #region Fetch and prepare rows
	const sortedGrowingAreasList = useGrowingAreaWithUsersAndMarketSegments({
		sort: { name: 1 },
		filter: {},
	});

	const gaRows: SelectedTrialGrowingAreaRow[] = useMemo(() => {
		return sortedGrowingAreasList.sortedGAsWithInfos.map((ga) => ({
			...ga,
			selected: rowIsSelected(ga._id),
			isDisabledRow: isDisabledRow(ga._id),
		}));
	}, [isDisabledRow, rowIsSelected, sortedGrowingAreasList]);

	const [filterDebounce] = useDebounce(filter, 500);
	const data = useMemo(
		() => orderBy(filterBy(gaRows, filterDebounce), sort),
		[filterDebounce, gaRows, sort],
	);
	// #endregion ---------------------------------

	// #region Selection of growing areas
	const onGrowingAreasSelected = useCallback(
		({
			growingAreas,
			selected,
		}: {
			growingAreas: IGrowingAreaSerialized[];
			selected: boolean;
		}) => {
			if (selected) {
				dispatch(
					METTrialManagementActions.onAddGAsForTrials({
						trials: trialToSelectGA,
						growingAreas: growingAreas,
					}),
				);
			} else {
				dispatch(
					METTrialManagementActions.onRemoveGAsForTrials({
						trials: trialToUnselectGA,
					}),
				);
			}
		},
		[dispatch, trialToSelectGA, trialToUnselectGA],
	);
	const onHeaderCheck = useCallback(
		(column: string, check: boolean) => {
			setIsGeneralCheck(check);
			onGrowingAreasSelected({
				growingAreas: data
					?.filter((GA) => !GA.selected)
					.map((row) => {
						const GA =
							sortedGrowingAreasList.allGrowingAreasMapById.get(
								row._id,
							);
						if (!GA) {
							throw new Error("unable to find GA");
						}
						return EntitySerializer.serialize(GA);
					}),
				selected: check,
			});
		},
		[
			data,
			onGrowingAreasSelected,
			sortedGrowingAreasList.allGrowingAreasMapById,
		],
	);

	const gridSelectionContextValue = useMemo(
		() => ({
			header: {
				isSelected: () => isHeaderCheck,
				onSelect: onHeaderCheck,
			},
			row: {
				onSelectRow: (
					column: string,
					row: SelectedTrialGrowingAreaRow,
					check: boolean,
				) => {
					const ga =
						sortedGrowingAreasList.allGrowingAreasMapById.get(
							row._id,
						);
					if (!ga) {
						return;
					}
					onGrowingAreaSelected({
						growingArea: EntitySerializer.serialize(ga),
						select: check,
						id: row._id,
					});
				},
				isDisabled: (column: string, values: unknown) =>
					isDisabledRowGetter(values),
			},
		}),
		[
			isHeaderCheck,
			onGrowingAreaSelected,
			onHeaderCheck,
			sortedGrowingAreasList.allGrowingAreasMapById,
		],
	);
	// #endregion ---------------------------------
	return (
		<DorianeDrawer
			takeRemainingSpace
			onClose={onCloseModal}
			title={DIC(EDIC_KEY.GROWING_AREA)}
		>
			<div className="take-remaining-space no-animation">
				<AutoSizer className="METTrialsGrowingAreaModal-grid-container">
					{({ width, height }: { width: number; height: number }) => (
						<GridCheckboxContext.Provider
							value={gridSelectionContextValue}
						>
							<Grid
								data={data}
								rowHeight={rowHeight}
								style={{ width: width, height: height }}
								scrollable={"virtual"}
								resizable={true}
								skip={skip}
								total={data.length}
								pageSize={40}
								onPageChange={pageChange}
								rowRender={RowDisableRenderer}
								// filterable={true}
								// columnVirtualization={true}
								filter={filter}
								onFilterChange={(e: GridFilterChangeEvent) => {
									setFilter(e.filter);
								}}
								sortable={true}
								sort={sort}
								onSortChange={(e: GridSortChangeEvent) => {
									setSort(e.sort);
								}}
								dataItemKey={"_id"}
							>
								<Column
									field="selected"
									width={50}
									id="selected"
									resizable={false}
									filterable={false}
									cell={CheckboxCustomCell}
									headerCell={CheckboxHeaderCell}
									title=""
									locked={true}
								/>
								<Column
									field="identifier"
									id="identifier"
									title="ID"
									width={200}
									minResizableWidth={minWidth}
									columnMenu={ColumnMenu}
									locked={true}
								/>
								<Column
									field="name"
									title="Name"
									width={200}
									minResizableWidth={minWidth}
									columnMenu={ColumnMenu}
								/>
								<Column
									field="label"
									title="Label"
									width={200}
									minResizableWidth={minWidth}
									columnMenu={ColumnMenu}
								/>
								<Column
									field="description"
									title="Description"
									width={200}
									minResizableWidth={minWidth}
									columnMenu={ColumnMenu}
								/>
								<Column
									field="marketSegmentsNames"
									title="Market Segment"
									width={200}
									minResizableWidth={minWidth}
									columnMenu={ColumnMenu}
									cell={KendoChipListCell}
								/>
								<Column
									field="country"
									title="Country"
									width={200}
									minResizableWidth={minWidth}
									columnMenu={ColumnMenu}
								/>
								<Column
									field="region"
									title="Region"
									width={200}
									minResizableWidth={minWidth}
									columnMenu={ColumnMenu}
								/>
								<Column
									field="season"
									title="Season"
									width={200}
									minResizableWidth={minWidth}
									columnMenu={ColumnMenu}
								/>
								<Column
									field="startDate"
									title="From"
									width={200}
									minResizableWidth={minWidth}
									columnMenu={ColumnMenu}
									filter="date"
									format="{0:d}"
								/>
								<Column
									field="endDate"
									title="To"
									width={200}
									minResizableWidth={minWidth}
									columnMenu={ColumnMenu}
									filter="date"
									format="{0:d}"
								/>
								<Column
									field="year"
									title="Year"
									width={200}
									minResizableWidth={minWidth}
									columnMenu={ColumnMenu}
								/>
								<Column
									field="climate"
									title="Climate"
									width={200}
									minResizableWidth={minWidth}
									columnMenu={ColumnMenu}
									cell={KendoChipListCell}
								/>
								<Column
									field="soilType"
									title="Soil type"
									width={200}
									minResizableWidth={minWidth}
									columnMenu={ColumnMenu}
									cell={KendoChipListCell}
								/>
								<Column
									field="soilDetails"
									title="Soil details"
									width={200}
									minResizableWidth={minWidth}
									columnMenu={ColumnMenu}
								/>
								<Column
									field="usersArray"
									title="Users"
									width={200}
									minResizableWidth={minWidth}
									columnMenu={ColumnMenu}
									cell={UserCell}
								/>
								<Column
									field="comments"
									title="Comments"
									width={200}
									minResizableWidth={minWidth}
									columnMenu={ColumnMenu}
								/>
							</Grid>
						</GridCheckboxContext.Provider>
					)}
				</AutoSizer>
			</div>
		</DorianeDrawer>
	);
}
