import React from "react";
import { RouteObject } from "react-router-dom";

import {
	PATH_DASHBOARD,
	PATH_DASHBOARD_ORGANIZATION,
	PATH_DASHBOARD_PLANNING,
	PATH_DASHBOARD_TEAM,
	PATH_DASHBOARD_TEAM_DEV,
	PATH_DASHBOARD_TEAM_MANAGER,
	PATH_DASHBOARD_VARIETY,
	removeStartingSlash,
} from "../../routes";
import DashboardPage from "../../Views/main/dashboard/DashboardPage";
import DashboardNetworkPage from "../../Views/main/dashboard/network/DashboardNetworkPage";
import DashboardPlanningPage from "../../Views/main/dashboard/planning/DashboardPlanningPage";
import DashboardTeamPage from "../../Views/main/dashboard/team/DashboardTeamPage";
import DashboardTeamDevelopers from "../../Views/main/dashboard/team/developers/DashboardTeamDevelopers";
import DashboardTeamManagers from "../../Views/main/dashboard/team/managers/DashboardTeamManagers";
import DashboardVarietyPage from "../../Views/main/dashboard/variety/DashboardVarietyPage";

export function dashboardRouter(): RouteObject {
	return {
		path: PATH_DASHBOARD,
		element: <DashboardPage />,
		children: [
			{
				element: <DashboardVarietyPage />,
				path: removeStartingSlash(PATH_DASHBOARD_VARIETY),
			},
			{
				element: <DashboardTeamPage />,
				path: removeStartingSlash(PATH_DASHBOARD_TEAM),
				children: [
					{
						element: <DashboardTeamManagers />,
						path: removeStartingSlash(PATH_DASHBOARD_TEAM_MANAGER),
					},
					{
						element: <DashboardTeamDevelopers />,
						path: removeStartingSlash(PATH_DASHBOARD_TEAM_DEV),
					},
				],
			},
			{
				element: <DashboardNetworkPage />,
				path: removeStartingSlash(PATH_DASHBOARD_ORGANIZATION),
			},
			{
				element: <DashboardPlanningPage />,
				path: removeStartingSlash(PATH_DASHBOARD_PLANNING),
			},
		],
	};
}
