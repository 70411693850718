import React from "react";

import { IColumn } from "../doriane-data-grid/DorianeDataGrid";

import "./IconColumn.scss";

export function IconColumn<Row>(
	src: (row: Row) => string,
	title: (row: Row) => string,
	headerName?: string,
	width?: string,
	id = "iconColumn",
): IColumn<Row> {
	return {
		id: id,
		getHeaderRenderer: headerName ?? "",
		getCellRenderer: (row: Row) => (
			<div className="IconColumn-cell">
				<img src={src(row)} title={title(row)} alt={title(row)} />
			</div>
		),
		width: width ?? "50px",
	};
}
