import dayjs from "dayjs";

// This does not works with little numbers like 0.0000001 (they are stringified to 1e-7).
export function countDecimalDigits(value: number) {
	if (Math.floor(value) === value) return 0;
	return value.toString().split(".")[1].length || 0;
}

export type StringConstraints = {
	required?: boolean;
	notEmpty?: boolean;
	minLength?: number;
	maxLength?: number;
	length?: number;
	pattern?: RegExp;
	patternErrorMessage?: string;
};
export function validateString(
	value: unknown,
	constraint: StringConstraints
): string[] {
	if (value === undefined || value === null) {
		if (constraint.required) {
			return ["Must be defined"];
		}
		return [];
	}
	if (typeof value !== "string") {
		return ["Must be a string"];
	}
	const errors: string[] = [];

	if (value.length === 0 && constraint.notEmpty) {
		errors.push("Cannot be empty");
		return errors;
	}
	if (constraint.length && value.length !== constraint.length) {
		errors.push(`Length need to be equal to ${constraint.length} characters`);
	}
	if (
		constraint.minLength !== undefined &&
		value.length < constraint.minLength
	) {
		errors.push(
			`Length need to be more or equal than ${constraint.minLength} characters`
		);
	}
	if (
		constraint.maxLength !== undefined &&
		value.length > constraint.maxLength
	) {
		errors.push(
			`Length need to be less or equal than ${constraint.maxLength} characters`
		);
	}
	if (constraint.pattern && !constraint.pattern.test(value)) {
		errors.push(constraint.patternErrorMessage ?? "Pattern not respected");
	}
	return errors;
}

export type NumberConstraints = {
	required?: boolean;
	isInt?: boolean;
	min?: number;
	max?: number;
	nbDigits?: number;
};
export function validateNumber(
	value: unknown,
	constraint: NumberConstraints
): string[] {
	if (value === undefined || value === null) {
		if (constraint.required) {
			return ["Must be defined"];
		}
		return [];
	}
	if (typeof value !== "number") {
		return ["Must be a number"];
	}
	if (Number.isNaN(value)) {
		return ["Must be a valid number : NaN"];
	}
	const errors: string[] = [];
	if (constraint.isInt && !Number.isInteger(value)) {
		return ["Value must be an integer"];
	}

	if (constraint.min !== undefined && value < constraint.min) {
		errors.push(`Value need to be more or equal than ${constraint.min}`);
	}
	if (constraint.max !== undefined && value > constraint.max) {
		errors.push(`Value need to be less or equal than ${constraint.max}`);
	}
	if (
		constraint.nbDigits !== undefined &&
		countDecimalDigits(value) > constraint.nbDigits
	) {
		errors.push(
			`Number of decimals of the value need to be less or equal than ${constraint.nbDigits}`
		);
	}
	return errors;
}

export type DateConstraints = {
	required?: boolean;
	minDate?: Date;
	maxDate?: Date;
};
export function validateDate(
	value: unknown,
	constraint: DateConstraints
): string[] {
	if (value === undefined || value === null) {
		if (constraint.required) {
			return ["Must be defined"];
		}
		return [];
	}
	let valueDate;
	if (value instanceof Date) {
		valueDate = value as Date;
		if (!dayjs(value).isValid()) {
			return ["Invalid datetime format"];
		}
	} else if (typeof value === "string") {
		if (!dayjs(value).isValid()) {
			return ["Invalid datetime format"];
		}
		valueDate = new Date(value as string);
	} else {
		return ["Must be a Date type"];
	}
	const currentDateTime = valueDate.getTime();
	const minDateTime = constraint.minDate?.getTime();
	const maxDateTime = constraint.maxDate?.getTime();

	const errors: string[] = [];
	if (minDateTime && currentDateTime < minDateTime) {
		errors.push(`Date need to be more or equal than ${constraint.minDate}`);
	}
	if (maxDateTime && currentDateTime > maxDateTime) {
		errors.push(`Date need to be less or equal than ${constraint.maxDate}`);
	}
	return errors;
}

export function fixedFieldValidator<T extends object>(
	storedObject: T,
	newObject: T,
	keysFixed: (keyof T)[]
) {
	const fixedKeyError: string[] = [];
	for (const key of keysFixed) {
		const diffExist =
			JSON.stringify(storedObject[key]) !== JSON.stringify(newObject[key]);
		if (diffExist) {
			fixedKeyError.push(`${key.toString()} cannot be modified`);
		}
	}
	return fixedKeyError;
}

export function verifyAcceptedTransition<T>(
	from: T,
	to: T,
	acceptedTransitionsMap: Map<T, T[]>
) {
	if (from === to) {
		return true;
	}
	const acceptedTransitions = acceptedTransitionsMap.get(from);
	const find = acceptedTransitions?.find((elt) => elt === to);
	if (find) {
		return true;
	}
	return false;
}

export const emailRegex = /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/;
export const colorRegex = /^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/;
