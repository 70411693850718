import * as React from "react";
import { MultiViewCalendar } from "@progress/kendo-react-dateinputs";
import MaskedInput from "react-text-mask";
import { Popover } from "@mui/material";
import { Dayjs } from "dayjs";

import { dateFormat } from "common";

import { Input } from "stories/base-components/Input/Input";
import { StoryTheme } from "stories/StoryTheme";
import Size from "stories/constants/Size/Size";
import Values from "stories/constants/Values";
import { HelperError } from "../Typography/Typography";

import "@progress/kendo-theme-default/dist/all.css";
import "./dateRangePicker.scss";

export type IDateRangePicker = {
	startDateInput: string | Dayjs | null;
	setStartDateInput: (date: string) => void;
	endDateInput: string | Dayjs | null;
	setEndDateInput: (date: string) => void;
	startDateLabel?: string;
	endDateLabel?: string;
	disabled?: boolean;
	error?: string;
};

// eslint-disable-next-line react/display-name, @typescript-eslint/no-unused-vars
const TextMaskCustom = React.forwardRef((props, ref) => {
	return (
		<MaskedInput
			{...props}
			mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={"\u2000"}
		/>
	);
});

export function InputDate(props: {
	show: boolean;
	setShow: React.Dispatch<React.SetStateAction<boolean>>;
	label: string;
	value: string;
	setValue: (date: string) => void;
	disabled?: boolean;
}) {
	const handleChange = (event: { target: { value: string } }) => {
		const valueFiltered = event.target.value
			.replace(/ /g, "")
			.replace(/\//g, "");
		props.setValue(valueFiltered);
	};

	return (
		<StoryTheme>
			<div style={{ display: "flex", alignItems: "center" }}>
				<Input
					label={props.label}
					endIconButton={{
						icon: { name: "calendar" },
						iconButton: true,
						buttonType: "tertiary",
						magicIcon: true,
						onClick: () => {
							!props.disabled && props.setShow(true);
						},
					}}
					disabled={props.disabled}
					// eslint-disable-next-line react/prop-types
					value={props.value}
					onChange={handleChange}
					mask={TextMaskCustom}
					className="marginRightIcon"
					width={Values.inputWidth}
				/>
			</div>
		</StoryTheme>
	);
}

export function DateRangePicker(dateRangePicker: IDateRangePicker) {
	const [show, setShow] = React.useState<boolean>(false);

	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null,
	);

	const maskFormat = (dateString: string) => {
		const dateStringFiltered = dateString.replace(/\s/g, "");
		if (dateStringFiltered.length === 10) {
			return dateString;
		} else if (
			dateStringFiltered.length === 8 &&
			!dateStringFiltered.includes("/")
		) {
			const day = dateStringFiltered.substring(0, 2);
			const month = dateStringFiltered.substring(2, 4);
			const year = dateStringFiltered.substring(4, 8);

			return `${day}/${month}/${year}`;
		}
		return "";
	};

	return (
		<StoryTheme>
			<div
				style={{ display: "inline-flex" }}
				onClick={(event: any) => {
					setAnchorEl(event.currentTarget);
				}}
			>
				<InputDate
					show={show}
					setShow={setShow}
					label={
						dateRangePicker.startDateLabel
							? dateRangePicker.startDateLabel
							: "Start Date"
					}
					value={dateRangePicker.startDateInput?.toString() || ""}
					setValue={dateRangePicker.setStartDateInput}
					disabled={dateRangePicker.disabled}
				/>
				<div style={{ marginLeft: Size.md }}>
					<InputDate
						show={show}
						setShow={setShow}
						label={
							dateRangePicker.endDateLabel
								? dateRangePicker.endDateLabel
								: "End Date"
						}
						value={dateRangePicker.endDateInput?.toString() || ""}
						setValue={dateRangePicker.setEndDateInput}
						disabled={dateRangePicker.disabled}
					/>
				</div>
			</div>
			{dateRangePicker.error && (
				<div>
					<HelperError text={dateRangePicker.error} />
				</div>
			)}
			<Popover
				open={show}
				onClose={() => setShow(false)}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
			>
				<MultiViewCalendar
					value={{
						start: maskFormat(
							dateRangePicker.startDateInput?.toString() || "",
						)
							? new Date(
									maskFormat(
										dateRangePicker.startDateInput?.toString() ||
											"",
									),
							  )
							: null,
						end: maskFormat(
							dateRangePicker.endDateInput?.toString() || "",
						)
							? new Date(
									maskFormat(
										dateRangePicker.endDateInput?.toString() ||
											"",
									),
							  )
							: null,
					}}
					onChange={(event: any) => {
						if (event.value?.start) {
							dateRangePicker.setStartDateInput(
								dateFormat(new Date(event.value?.start)),
							);
						}
						if (event.value?.end) {
							dateRangePicker.setEndDateInput(
								dateFormat(new Date(event.value?.end)),
							);
						}
					}}
					mode="range"
				/>
			</Popover>
		</StoryTheme>
	);
}
