import React from "react";
import { IInput, Input } from "../Input";
import Colors from "stories/constants/Colors/Colors";

export const SearchInput = (input: Partial<IInput> & { setInput: any }) => {
	return (
		<Input
			{...input}
			value={input.value}
			onChange={(event) => {
				input.setInput(event.target.value);
			}}
			width={400}
			startIcon={{ name: "search" }}
			endIcon={
				input.value === ""
					? undefined
					: { name: "delete-text", color: Colors.neutral60 }
			}
			endIconAction={
				input.value === ""
					? undefined
					: () => {
							input.setInput("");
					  }
			}
		/>
	);
};
