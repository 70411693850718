"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ETagType = void 0;
var ETagType;
(function (ETagType) {
    ETagType["DEFAULT"] = "DEFAULT";
    ETagType["TRIAL"] = "TRIAL";
    ETagType["MATERIAL"] = "MATERIAL";
    ETagType["VARIABLE"] = "VARIABLE";
})(ETagType = exports.ETagType || (exports.ETagType = {}));
