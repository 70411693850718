import React, { useMemo } from "react";
import { Popover } from "@mui/material";

import { EUserRole, IUser } from "shared-type";

import { Typography } from "stories/base-components/Typography/Typography";
import { Initials } from "stories/base-components/Initials/Initials";
import Colors from "stories/constants/Colors/Colors";
import { StoryTheme } from "stories/StoryTheme";
import Box from "App/components/base-components/placement/box/Box";
import FlexBox from "App/components/base-components/placement/flex-box/FlexBox";
import InitialsUser from "stories/base-components/Initials/mostUsed/InitialsUser";
import Size from "stories/constants/Size/Size";
import { DIC, EDIC_KEY } from "App/dictionary";
import { Button } from "stories/base-components/Button/Button";
import UserEditModal from "App/components/users/user-modal/UserEditModal";
import store from "App/store";
import { userAction } from "App/redux/user/user.slice";

import "./appbar.scss";

export type IAppBar = {
	companyName?: string;
	user?: Partial<IUser>;
};
export function AppBar(props: IAppBar) {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null,
	);
	const user = props.user;

	const userNamesAndRole = useMemo(() => {
		return (
			user?.firstName +
			" " +
			user?.lastName +
			" (" +
			(user?.role === EUserRole.ADMIN
				? DIC(EDIC_KEY.ADMIN)
				: DIC(EDIC_KEY.OBSERVER)) +
			")"
		);
	}, [user?.firstName, user?.lastName, user?.role]);

	return (
		<StoryTheme>
			<>
				<div className="AppBarContainer">
					{props.companyName && (
						<Typography
							text={"(" + props.companyName + ")"}
							color={Colors.primary60}
						/>
					)}
					{user && (
						<>
							<FlexBox
								onClick={(event: any) =>
									setAnchorEl(event.currentTarget)
								}
								className="AppBar-initialsContainer"
							>
								<div className="AppBar-initials">
									<Initials
										firstName={user?.firstName}
										lastName={user?.lastName}
										backgroundColor={user.color}
									/>
								</div>
								<Typography
									text={
										user?.firstName + " " + user?.lastName
									}
									variant="heading2"
									color={Colors.primary60}
								/>
							</FlexBox>
							<Popover
								open={Boolean(anchorEl)}
								anchorEl={anchorEl}
								onClose={() => {
									setAnchorEl(null);
								}}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
								anchorPosition={{
									left: -16,
									top: 0,
								}}
							>
								<Box className="AppBar-userPopOverContainer">
									<FlexBox gap={Size.base}>
										<InitialsUser user={user} />
										<Box>
											<Typography
												variant="heading2"
												text={userNamesAndRole}
											/>
											<Typography
												text={user.mail}
												variant="body2"
											/>
										</Box>
									</FlexBox>
									<FlexBox
										paddingTop={Size.base}
										flexDirection="column"
									>
										<Button
											startIcon={{ name: "edit" }}
											buttonType="secondary"
											text={DIC(EDIC_KEY.MY_PROFILE)}
											onClick={() =>
												store.dispatch(
													userAction.selectUser(
														user.id,
													),
												)
											}
										/>
									</FlexBox>
								</Box>
							</Popover>
						</>
					)}
				</div>
				<UserEditModal />
			</>
		</StoryTheme>
	);
}
