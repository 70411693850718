/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { Control, useController } from "react-hook-form";

import LabelledCard from "../card/labelled-card/LabelledCard";
import ChipCombobox from "../combobox/chip-combobox/ChipCombobox";
import Combobox from "../combobox/Combobox";
import DorianeDatePicker from "../date-picker/DorianeDatePicker";
import InputText from "../input-text/InputText";
import {
	BooleanInputInfoWithProps,
	CheckBoxInfoWithProps,
	ColorInputInfoWithProps,
	ComboboxInputInfoWithProps,
	DateInputInfoWithProps,
	EFormInputType,
	InputInformation,
	InputInfoWithProps,
	NumberInputInfoWithProps,
	StringInputInfoWithProps,
} from "./dynamicForm.interface";
import DotColorPicker from "../dot-color-picker/DotColorPicker";
import { CustomCheckbox } from "../checkbox/CustomCheckbox";

import "./DynamicForm.scss";

interface DynamicFormProps {
	formInfos: InputInformation[];
	control: Control<any>;
}

export default function DynamicForm(props: DynamicFormProps) {
	const formInputs = useMemo(() => {
		return props.formInfos.map((formInfo, index) => {
			return (
				<InputDisplay
					key={index}
					inputProps={formInfo}
					control={props.control}
				/>
			);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.formInfos]);

	return <div className="DynamicForm-container">{formInputs}</div>;
}

function InputDisplay(props: InputInfoWithProps) {
	const input = useMemo(() => {
		switch (props.inputProps.inputType) {
			case EFormInputType.NUMBER:
				return <NumberInput {...(props as NumberInputInfoWithProps)} />;
			case EFormInputType.DATE:
				return <DateInput {...(props as DateInputInfoWithProps)} />;
			case EFormInputType.COMBOBOX:
				return (
					<ComboboxInput {...(props as ComboboxInputInfoWithProps)} />
				);
			case EFormInputType.CHIP_COMBOBOX:
				return (
					<ChipComboboxInput
						{...(props as ComboboxInputInfoWithProps)}
					/>
				);
			case EFormInputType.BOOLEAN:
				return (
					<BooleanInput {...(props as BooleanInputInfoWithProps)} />
				);
			case EFormInputType.STRING:
				return <StringInput {...(props as StringInputInfoWithProps)} />;
			case EFormInputType.COLOR:
				return <ColorInput {...(props as ColorInputInfoWithProps)} />;
			case EFormInputType.CHECKBOX:
				return <CheckBoxInput {...(props as CheckBoxInfoWithProps)} />;
			default:
				return <div>Missing Input</div>;
		}
	}, [props]);
	return (
		<div
			className="DynamicForm-input"
			style={{
				gridColumn: `span ${props.inputProps.takeAllRow ? 2 : 1}`,
			}}
		>
			{input}
		</div>
	);
}

export function StringInput(props: StringInputInfoWithProps) {
	const { inputProps, control } = props;
	const { field, fieldState } = useController({
		name: inputProps.id,
		control,
	});
	return (
		<InputText
			style={{ width: "100%" }}
			value={field.value ?? ""}
			onChange={field.onChange}
			multiline={inputProps.multiline}
			rows={inputProps.multiline ? 4 : undefined}
			disabled={inputProps.disabled}
			label={inputProps.label}
			required={inputProps.required}
			error={fieldState.invalid}
			helperText={fieldState.error?.message}
			InputProps={inputProps.InputProps}
			type={inputProps.type}
		/>
	);
}

export function NumberInput(props: NumberInputInfoWithProps) {
	const { inputProps, control } = props;
	const { field, fieldState } = useController({
		name: inputProps.id,
		control,
	});
	return (
		<InputText
			style={{ width: "100%" }}
			value={field.value ?? ""}
			onChange={(event) => {
				const newValue = event.target.value;
				if (newValue === "") {
					field.onChange(undefined);
					return;
				}
				field.onChange(newValue);
			}}
			disabled={inputProps.disabled}
			label={inputProps.label}
			required={inputProps.required}
			error={fieldState.invalid}
			helperText={fieldState.error?.message}
			type="number"
		/>
	);
}

export function DateInput(props: DateInputInfoWithProps) {
	const { inputProps, control } = props;
	const { field, fieldState } = useController({
		name: inputProps.id,
		control,
	});

	const value = field.value ? dayjs(field.value) : null;
	return (
		<DorianeDatePicker
			label={`${inputProps.label} ${inputProps.required ? "*" : ""}`}
			minDate={inputProps.minDate}
			disabled={inputProps.disabled}
			helperTextError={true}
			value={value || null}
			onChange={(value) => field.onChange(value as any)}
			helperText={fieldState.error?.message}
		/>
	);
}

export function BooleanInput(props: BooleanInputInfoWithProps) {
	const { inputProps, control } = props;
	const { field } = useController({
		name: inputProps.id,
		control,
	});
	return (
		<div>
			<CustomCheckbox
				checked={field.value}
				onChange={field.onChange}
				id={inputProps.id}
				disabled={inputProps.disabled}
				label={inputProps.label}
			/>
		</div>
	);
}

export function ComboboxInput(props: ComboboxInputInfoWithProps) {
	const { inputProps, control } = props;
	const { field } = useController({
		name: inputProps.id,
		control,
	});
	return inputProps.disabled ? (
		<LabelledCard
			label={`${inputProps.label} ${inputProps.required ? "*" : ""}`}
			value={
				inputProps.translateMethod
					? inputProps.translateMethod(field.value)
					: field.value
			}
		/>
	) : (
		<Combobox<number | string>
			style={{ width: "100%" }}
			value={field.value}
			onChange={(value) => field.onChange(value)}
			translateMethod={inputProps.translateMethod}
			label={inputProps.label}
			required={inputProps.required}
			comboboxValues={inputProps.comboboxValues ?? []}
		/>
	);
}

export function ChipComboboxInput(props: ComboboxInputInfoWithProps) {
	const { inputProps, control } = props;
	const { field } = useController({
		name: inputProps.id,
		control,
	});
	return inputProps.disabled ? (
		<LabelledCard
			label={`${inputProps.label} ${inputProps.required ? "*" : ""}`}
			value={
				inputProps.translateMethod
					? field.value.map((val: unknown) =>
							inputProps.translateMethod(val),
					  )
					: field.value
			}
		/>
	) : (
		<ChipCombobox<number | string>
			style={{ width: "100%" }}
			value={field.value || []}
			onChange={(value) => field.onChange(value)}
			translateMethod={inputProps.translateMethod}
			label={inputProps.label}
			required={inputProps.required}
			comboboxValues={inputProps.comboboxValues ?? []}
		/>
	);
}

export function ColorInput(props: ColorInputInfoWithProps) {
	const { inputProps, control } = props;
	const { field } = useController({
		name: inputProps.id,
		control,
	});
	return (
		<DotColorPicker
			color={field.value ?? ""}
			onColorChange={field.onChange}
		/>
	);
}

export function CheckBoxInput(props: CheckBoxInfoWithProps) {
	const { inputProps, control } = props;
	const { field } = useController({
		name: inputProps.id,
		control,
	});
	return (
		<CustomCheckbox
			checked={field.value ?? false}
			onChange={field.onChange}
			id={inputProps.id}
			disabled={inputProps.disabled}
			label={inputProps.label}
		/>
	);
}
