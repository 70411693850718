import * as React from "react";

import TitledTag, { ITitledTagProps } from "../titled-tag/TitledTag";

import "./ImgTag.scss";

interface IUserTagProps extends ITitledTagProps {
	imageSrc: string;
}

export default function ImgTag(props: IUserTagProps) {
	return (
		<TitledTag
			title={props.title}
			style={props.style}
			tagValue={props.tagValue}
		>
			<img className="ImgTag-img" src={props.imageSrc} />
			{props.children && props.children}
		</TitledTag>
	);
}
