import React from "react";

import { capitalize } from "common";

import { DIC, EDIC_KEY } from "../../../../../dictionary";
import { IColumn } from "../doriane-data-grid/DorianeDataGrid";
import { IMETTrialRow } from "App/redux/experiment/met/met-trial-management.slice";
import ColorTag from "App/components/base-components/tag/color-tag/ColorTag";
import { getColorFromTrialStatus } from "App/utils";
import Box from "App/components/base-components/placement/box/Box";
import { Typography } from "stories/base-components/Typography/Typography";
import { apiUser } from "App/redux/user/user.api";
import FlexBox from "App/components/base-components/placement/flex-box/FlexBox";
import { AvatarIcon } from "stories/base-components/AvatarIcon/AvatarIcon";
import Colors from "stories/constants/Colors/Colors";
import Size from "stories/constants/Size/Size";
import { getDicSpecies } from "App/dictionary/dictionary-key-getter";
import { Badge } from "stories/base-components/Badge/Badge";
import { Initials } from "stories/base-components/Initials/Initials";
import { Status } from "stories/base-components/Status/Status";
import {
	DropdownButton,
	IDropdownOption,
} from "stories/base-components/DropdownButton/DropdownButton";
import store from "App/store";
import { trialAction } from "App/redux/experiment/trial/trial.slice";

export const trialColumns = () => {
	const columns: IColumn<IMETTrialRow>[] = [
		{
			getCellRenderer: (row) => (
				<div className="DorianeDataGridCell-padding-horizontal flex-center-full-height">
					{row.trial?.status && (
						<ColorTag
							tagValue={row.trial?.status}
							title=""
							colorIndicator={getColorFromTrialStatus(
								row.trial?.status,
							)}
						/>
					)}
				</div>
			),
			getHeaderRenderer: DIC(EDIC_KEY.STATUS),
			id: "status",
			width: "100px",
		},
		{
			getCellRenderer: (row: IMETTrialRow) => row.trial?.name || "",
			getHeaderRenderer: DIC(EDIC_KEY.NAME),
			id: "name",
			width: "300px",
		},
		{
			getCellRenderer: (row: IMETTrialRow) => row.growingArea?.name || "",
			getHeaderRenderer: DIC(EDIC_KEY.GROWING_AREA),
			id: "gaName",
			width: "300px",
		},
		{
			getCellRenderer: (row: IMETTrialRow) =>
				row.growingArea?.country || "",
			getHeaderRenderer: DIC(EDIC_KEY.COUNTRY),
			id: "gaCountry",
			width: "150px",
		},
		{
			getCellRenderer: (row: IMETTrialRow) =>
				row.growingArea?.region || "",
			getHeaderRenderer: DIC(EDIC_KEY.REGION),
			id: "gaRegion",
			width: "150px",
		},
		{
			getCellRenderer: (row: IMETTrialRow) =>
				row.trial?.marketSegments?.join(", ") || "",
			getHeaderRenderer: DIC(EDIC_KEY.MARKET_SEGMENTS),
			id: "marketSegments",
			width: "150px",
		},
		{
			getCellRenderer: (row: IMETTrialRow) =>
				(row.trial?.replication ? String(row.trial?.replication) : ""),
			getHeaderRenderer: DIC(EDIC_KEY.REPLICATION),
			id: "replication",
			width: "150px",
		},
	];

	return columns;
};

export default function CreatedByUser(props: { createdBy: string }) {
	const { data: user } = apiUser.useGetUserByIdQuery(props.createdBy);

	return (
		<>
			{user && (
				<Badge
					text={user.firstName + " " + user.lastName}
					color={Colors.primary60}
					backgroundColor={Colors.primary10}
					customIcon={
						<Initials
							firstName={user.firstName}
							lastName={user.lastName}
							size="small"
						/>
					}
				/>
			)}
		</>
	);
}

const NoBadge = () => (
	<Badge
		text={"N/A"}
		color={Colors.neutral60}
		backgroundColor={Colors.neutral30}
	/>
);

const actionColumn = (row: IMETTrialRow) => {
	// TODO : Actions à développer dans une autre feature
	const actionsDropdown = [
		{
			label: DIC(EDIC_KEY.DUPLICATE),
			iconName: "template",
			action: async (event: any) => {
				event.stopPropagation();
				store.dispatch(trialAction.selectDuplicateTrial(row.trial));
			},
		},
		{
			label: DIC(EDIC_KEY.DELETE),
			iconName: "bin",
			action: async (event: any) => {
				event.stopPropagation();
				store.dispatch(trialAction.selectDeleteTrialId(row.trial?._id));
			},
		},
	];

	return (
		<DropdownButton
			btn={{
				iconButton: true,
				icon: { name: "dots-vertical" },
				magicIcon: true,
				buttonType: "tertiary",
			}}
			options={actionsDropdown as Array<IDropdownOption>}
		/>
	);
};

export const trialColumnsV2 = () => {
	const columns: IColumn<IMETTrialRow>[] = [
		// Actions placed here because after color column
		{
			id: "actions",
			getHeaderRenderer: "",
			getCellRenderer: (row) => actionColumn(row),
			minWidth: "48px",
		},
		{
			getCellRenderer: (row: IMETTrialRow) => (
				<FlexBox>
					<Box paddingRight={Size.base}>
						{row.trial?.metId ? (
							<AvatarIcon
								iconName="MET-trial"
								color={Colors.primary50}
								backgroundColor={Colors.accent20}
							/>
						) : (
							<AvatarIcon
								iconName="experiment--filled"
								color={Colors.primary60}
								backgroundColor={Colors.primary20}
							/>
						)}
					</Box>
					<Box>
						<Typography
							variant="body2Medium"
							text={row.trial?.name || ""}
						/>
						<Typography
							variant="body2"
							text={row.trial?.description || ""}
							color={Colors.neutral90}
						/>
					</Box>
				</FlexBox>
			),
			getHeaderRenderer: DIC(EDIC_KEY.NAME_AND_DESCRIPTION),
			id: "name",
			width: "350px",
		},
		{
			getCellRenderer: (row: IMETTrialRow) =>
				(row.trial?.systemMetadata?.createdBy ? (
					<CreatedByUser
						createdBy={row.trial?.systemMetadata?.createdBy}
					/>
				) : (
					""
				)),
			getHeaderRenderer: DIC(EDIC_KEY.CREATED_BY),
			id: "createdBy",
			width: "200px",
		},
		{
			getCellRenderer: (row: IMETTrialRow) =>
				(row.trial?.year ? (
					<Badge
						text={row.trial?.year + ""}
						color={Colors.primary60}
						backgroundColor={Colors.primary10}
					/>
				) : (
					""
				)),
			getHeaderRenderer: DIC(EDIC_KEY.YEAR),
			id: "year",
			width: "100px",
		},
		{
			getCellRenderer: (row: IMETTrialRow) =>
				(row.trial?.program ? (
					<Badge
						text={capitalize(row.trial?.program)}
						color={Colors.primary60}
						backgroundColor={Colors.primary10}
					/>
				) : (
					<NoBadge />
				)),
			getHeaderRenderer: DIC(EDIC_KEY.SCOPE),
			id: "program",
			width: "150px",
		},
		{
			getCellRenderer: (row: IMETTrialRow) =>
				(row.trial?.project ? (
					<Badge
						text={capitalize(row.trial?.project)}
						color={Colors.primary60}
						backgroundColor={Colors.primary10}
					/>
				) : (
					<NoBadge />
				)),
			getHeaderRenderer: DIC(EDIC_KEY.TYPE),
			id: "project",
			width: "150px",
		},
		{
			getCellRenderer: (row: IMETTrialRow) =>
				(row.growingArea?.name ? (
					<Badge
						text={row.growingArea?.name}
						color={Colors.brown40}
						backgroundColor={Colors.brown20}
					/>
				) : (
					<NoBadge />
				)),
			getHeaderRenderer: DIC(EDIC_KEY.GROWING_AREA),
			id: "gaName",
			width: "300px",
		},
		{
			getCellRenderer: (row: IMETTrialRow) =>
				(row.trial?.species ? (
					<Badge
						text={getDicSpecies(row.trial?.species)}
						color={Colors.primary60}
						backgroundColor={Colors.primary10}
					/>
				) : (
					<NoBadge />
				)),
			getHeaderRenderer: DIC(EDIC_KEY.SPECIES),
			id: "species",
			width: "300px",
		},
		{
			getCellRenderer: (row) =>
				(row.trial?.status ? (
					<Status status={row.trial?.status as any} />
				) : (
					""
				)),
			getHeaderRenderer: DIC(EDIC_KEY.STATUS),
			id: "status",
			width: "100px",
		},
	];

	return columns;
};
