export type FieldError = string[] | undefined;

/**
 * extract error message of the field
 * @param error error to extract message
 * @param args optional args for some validation like i18n errors
 * @returns the error message
 */
export function getErrorMessage(error: FieldError) {
	if (error === undefined) {
		return "";
	}
	if (Array.isArray(error)) {
		return error.join("; ");
	}
	return error;
}
