import { createSlice } from "@reduxjs/toolkit";

import {
	EGermplasmLevel,
	IGenotype,
	ILot,
	IMaterial,
	ITrialSerialized,
} from "shared-type";

import { CustomStep } from "App/components/base-components/stepper/DorianeStepper";
import { DIC, EDIC_KEY } from "App/dictionary";
import { IExpGeneralInfoState } from "../../components/experiment-wizard/common/types";
import { ECWReducerBuilder } from "./ECW-reducer";
import { trialWizardSaveReducerBuilder } from "./trial-wizard.save";

export const startStepsValue = (wizardType: "MET" | "SET"): CustomStep[] => {
	const steps = [
		{
			name: "1*",
			description: DIC(EDIC_KEY.GENERAL),
			index: 0,
			completed: false,
		},
		{
			name: "2*",
			description: DIC(EDIC_KEY.OBSERVATION),
			index: 1,
			completed: false,
			noLinkToPrevious: true,
			disabled: true,
		},
		{
			name: "3*",
			description: DIC(EDIC_KEY.MATERIAL),
			index: 2,
			completed: false,
			noLinkToPrevious: true,
			disabled: true,
		},
		{
			name: "4",
			description:
				wizardType === "SET"
					? DIC(EDIC_KEY.LOCATION)
					: DIC(EDIC_KEY.TRIALS),
			index: 3,
			completed: false,
			noLinkToPrevious: true,
			disabled: true,
		},
	];
	return steps;
};

interface IGenotypeMaterialInfo {
	type: EGermplasmLevel.GENOTYPE;
	material: IGenotype;
}

interface ILotMaterialInfo {
	type: EGermplasmLevel.LOT;
	material: ILot;
}

export type ECWNextMode = "save" | "doItLater";

export type IMaterialInfo = IGenotypeMaterialInfo | ILotMaterialInfo;

export type METTrialDraft = {
	trialId: number;
	trialName: string;
	growingAreaId?: string;
	growingAreaName?: string;
	isSelected: boolean;
};
export type TrialGenerationPartType = {
	trials: METTrialDraft[];
	isGASelectionOpen: boolean;
};
export interface IECWState {
	wizardType: "MET" | "SET";
	isInit: boolean;
	trialRemote?: ITrialSerialized;
	activeStep: number;
	stepList: CustomStep[];

	hasChanges: boolean;
	isPageFullSize: boolean;
	isPageBannerDisplay: boolean;

	generalPart: {
		comments?: string;
		generalInfo: Partial<IExpGeneralInfoState>;
	};
	observationPart: {
		templateObsId?: string;
	};
	germplasmPart: {
		isAddMaterialOpen: boolean;
		materialType: EGermplasmLevel;
		selectedMaterials: IMaterial[];
	};
	growingAreaPart: {
		isSelectGAOpen: boolean;
		growingAreaId?: string;
		growingAreaInEdition?: string;
		isEditGAOpen: boolean;
	};
	planningPart: {
		isCreateObsRoundOpen: boolean;
		isCreateOperationOpen: boolean;
		isConclusionOpen: boolean;
		editedOperationId?: string;
	};
	trialGenerationPart: TrialGenerationPartType;
	saveModalInfo: {
		isOpen: boolean;
		action: ECWNextMode;
	};
}

export function createInitialState(
	wizardType: "MET" | "SET",
	isInit: boolean,
): IECWState {
	return {
		isInit: isInit,
		wizardType: wizardType,
		activeStep: 0,
		hasChanges: false,
		isPageBannerDisplay: true,
		isPageFullSize: false,
		stepList: startStepsValue(wizardType),
		generalPart: {
			generalInfo: {},
		},
		germplasmPart: {
			isAddMaterialOpen: false,
			materialType: EGermplasmLevel.GENOTYPE,
			selectedMaterials: [],
		},
		observationPart: {},
		growingAreaPart: { isSelectGAOpen: false, isEditGAOpen: false },
		planningPart: {
			isCreateObsRoundOpen: false,
			isConclusionOpen: false,
			isCreateOperationOpen: false,
		},
		saveModalInfo: {
			isOpen: false,
			action: "save",
		},
		trialGenerationPart: {
			isGASelectionOpen: true,
			trials: [],
		},
	};
}

const ECWSlice = createSlice({
	name: "experimentCreationWizard",
	initialState: createInitialState("SET", false),
	reducers: ECWReducerBuilder,
	extraReducers: trialWizardSaveReducerBuilder,
});

export const ECWReducer = ECWSlice.reducer;
export const ECWAction = { ...ECWSlice.actions };
