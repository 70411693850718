import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

import { formatString } from "common";
import { ICreateTemplateObservationCore } from "shared-type";

import {
	templateObsFormToObject,
	templateObsValidator,
} from "App/components/form/template-observation";
import TemplateObsForm, {
	ITemplateObsForm,
} from "App/components/form/template-observation/TemplateObsForm";
import { apiTemplateObs } from "../../../../../redux/template-obs/template-obs.api";
import { DIC, EDIC_KEY } from "App/dictionary";
import useAppSnackbar from "App/hooks/useAppSnackbar";
import { ReduxApiError } from "App/redux/utils/errors";
import { ApiErrorSnackbar } from "App/redux/utils/api-error-snackbar/ApiErrorSnackbar";
import { PATH_OBSERVATION, PATH_TEMPLATES, PATH_VARIABLES } from "App/routes";
import { Modal } from "stories/base-components/Modal/Modal";

type TemplateObservationModalProps = {
	onClose: () => void;
	open: boolean;
};

export function TemplateObservationModal(props: TemplateObservationModalProps) {
	const { enqueueSnackbarError, enqueueSnackbarSuccess } = useAppSnackbar();
	const nav = useNavigate();
	const form = useForm<ITemplateObsForm>({
		resolver: yupResolver(templateObsValidator as any),
	});

	const { formState } = form;
	const { isSubmitting } = formState;

	const [createObsTemplate] = apiTemplateObs.useCreateObsTemplateMutation();

	const handleCreate = async (closeMode: boolean) => {
		form.clearErrors();

		form.handleSubmit(
			async (data) => {
				if (closeMode) {
					props.onClose();
				}
				const templateObsForm = templateObsFormToObject(data);

				const templateWithEmptyData = {
					...templateObsForm,
					variablesPool: [],
					templateObsUnits: {},
				};

				await createObsTemplate(
					templateWithEmptyData as ICreateTemplateObservationCore,
				)
					.unwrap()
					.then((obsTemplateIdCreated) => {
						// After creation continue or close
						props.onClose();
						if (!closeMode) {
							nav(
								`${PATH_TEMPLATES}${PATH_OBSERVATION}/${obsTemplateIdCreated}${PATH_VARIABLES}`,
							);
						}
						enqueueSnackbarSuccess(
							formatString(
								DIC(EDIC_KEY.ENTITY_CREATED),
								DIC(EDIC_KEY.OBSERVATION_TEMPLATE),
							),
						);
					})
					.catch((err: ReduxApiError) => {
						console.warn(err);
						enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
					});
			},
			() => enqueueSnackbarError(DIC(EDIC_KEY.BAD_INPUT)),
		)();
	};

	return (
		<Modal
			open={props.open}
			titleIcon={"obs-template"}
			title={`${DIC(EDIC_KEY.CREATE)} ${DIC(
				EDIC_KEY.OBSERVATION_TEMPLATE,
			)}`}
			handleClose={props.onClose}
			validateBtn={{
				action: () => {
					handleCreate(false);
				},
				label: DIC(EDIC_KEY.CREATE_AND_CONFIGURE),
				isLoading: isSubmitting,
			}}
			secondaryBtn={{
				action: () => {
					handleCreate(true);
				},
				label: DIC(EDIC_KEY.CREATE_AND_CLOSE),
				isLoading: isSubmitting,
			}}
			width={750}
		>
			<TemplateObsForm form={form} mode="creation" />
		</Modal>
	);
}
