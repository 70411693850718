import dayjs from "dayjs";
import * as Yup from "yup";

import { EClimate, EEntitiesLength, ESeason, ESoilType } from "shared-type";

import { DIC, EDIC_KEY } from "../../../dictionary";
import { dayjsYup, dayjsYupTestConstraint } from "../utils";

export const growingAreaValidator = Yup.object().shape({
	name: Yup.string().required()
.max(EEntitiesLength.name),
	identifier: Yup.string()
		.required()
		.max(EEntitiesLength.identifier)
		.matches(/^[a-zA-Z0-9_-]+$/, {
			message:
				"Need to start by letter and can contain only alphanumeric characters or '_' and '-'",
		}),
	label: Yup.string().max(EEntitiesLength.label),
	description: Yup.string().max(EEntitiesLength.description),

	startDate: dayjsYup.required("Field is required").test(
		dayjsYupTestConstraint({
			maxDate: new Date("2099-12-31T23:59:59"),
		}),
	),
	endDate: dayjsYup
		.nullable()
		.test(
			dayjsYupTestConstraint({
				maxDate: new Date("2099-12-31T23:59:59"),
			}),
		)
		.test({
			message: DIC(EDIC_KEY.DATE_GREATER_THAN_START_DATE_ERROR),
			test: (value, ctx) =>
				!value || !dayjs(value).isBefore(ctx.parent.startDate),
		}),

	season: Yup.mixed<ESeason>().oneOf(Object.values(ESeason)),

	country: Yup.string().max(EEntitiesLength.country),
	region: Yup.string().max(EEntitiesLength.region),
	latitude: Yup.number().min(-90)
.max(90),
	longitude: Yup.number().min(-180)
.max(180),

	climate: Yup.array().of(
		Yup.mixed<EClimate>().oneOf(Object.values(EClimate)),
	),
	soilType: Yup.array().of(
		Yup.mixed<ESoilType>().oneOf(Object.values(ESoilType)),
	),
	soilDetails: Yup.string().max(EEntitiesLength.soilDetails),
	users: Yup.array().required(),
});
