import { ClearSharp } from "@mui/icons-material";
import { Chip } from "@mui/material";
import React from "react";

import styles from "../../../../sassTheme";
import "./DorianeChip.scss";

interface DorianeChipProps<T> {
	onChipDelete?: (value: T) => void;
	chipValue: T;
	label: JSX.Element | string;
}
export default function DorianeChip<T>(props: DorianeChipProps<T>) {
	return (
		<Chip
			sx={{
				backgroundColor: styles["secondary-background-color"],
				borderRadius: 2,
			}}
			size="small"
			variant="outlined"
			color="primary"
			label={props.label}
			onDelete={
				props.onChipDelete
					? () => {
							if (props.onChipDelete) {
								props.onChipDelete(props.chipValue);
							}
					  }
					: undefined
			}
			deleteIcon={<ClearSharp />}
		/>
	);
}
