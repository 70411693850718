import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { CSSProperties } from "react";

import { EUserRole } from "shared-type";

import CustomCard from "../../base-components/card/CustomCard";
import UserAvatar from "../user-avatar/UserAvatar";
import { IUserCardProps } from "./UserCard";

import styles from "../../../../sassTheme";
import "./UserBanner.scss";

export interface IUserBannerProps extends IUserCardProps {
	style?: CSSProperties;
}

export default function UserBanner(props: IUserBannerProps) {
	function disabledStyle(): CSSProperties {
		if (props.user?.disabled) {
			return { backgroundColor: styles.grey };
		}
		return {};
	}

	return (
		<CustomCard
			onClick={props.onClick}
			className="UserBanner-container"
			disabledClick={props.disabledClick}
			style={{ ...props.style, ...disabledStyle() }}
		>
			<div className="UserBanner-left">
				<div className="UserBanner-icon">
					<UserAvatar user={props.user} />
				</div>
				<div className="UserBanner-name">
					<p>{`${props.user?.firstName || "_"} ${
						props.user?.lastName || "_"
					}`}</p>
				</div>
			</div>
			<div className="UserBanner-right">
				{!props.user?.disabled && (
					<div className="UserBanner-crest">
						{props.user?.role === EUserRole.OBSERVER ? (
							// If there are more than two roles, refactor this (with a switch, here or in a util function maybe ?).
							<VisibilityIcon color="primary" />
						) : (
							<AdminPanelSettingsIcon color="primary" />
						)}
					</div>
				)}
			</div>
		</CustomCard>
	);
}
