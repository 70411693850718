import React from "react";
import { TextField, TextareaAutosize, styled } from "@mui/material";

import Colors from "stories/constants/Colors/Colors";
import { HelperError } from "stories/base-components/Typography/Typography";
import { IIcon, Icon } from "stories/base-components/Icon/Icon";
import { Button, IButton } from "stories/base-components/Button/Button";
import { Tooltip } from "stories/base-components/Tooltip/Tooltip";
import { variants } from "stories/base-components/Typography/parameters";
import { StoryTheme } from "stories/StoryTheme";
import Size from "stories/constants/Size/Size";
import Values from "stories/constants/Values";

import "./input.scss";

export type IInput = {
	label?: string;
	onChange?:
		| (React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> &
				((event: any) => void))
		| undefined;
	onClick?: any;
	onBlur?: any;
	value?: string;
	placeholder?: string;
	required?: boolean;
	type?: "text" | "password" | "number";
	disabled?: boolean;
	success?: boolean;
	error?: boolean;
	errorMessage?: string;
	startIcon?: IIcon;
	startIconAction?: () => void;
	endIconButton?: IButton;
	endIcon?: IIcon;
	endIconAction?: () => void;
	mask?: any;
	inputRef?: any;
	className?: string;
	info?: string;
	width?: number;
	rows?: number;
	InputLabelProps?: object;
	InputProps?: object;
	inputProps?: object;
};

const TextFieldStyled = styled(TextField)<IInput>(
	({ theme, disabled, success, error, info, width, rows }) => {
		let borderColorFixed = null;
		if (error) {
			borderColorFixed = Colors.red60;
		}
		if (success) {
			borderColorFixed = Colors.accent60;
		}
		const lightPaddingRight = Boolean(info);
		return {
			// eslint-disable-next-line no-nested-ternary
			width: width
				? width
				: rows
				? Values.doubleInputWidth
				: Values.inputWidth,
			"& .MuiInputLabel-root": {
				color: Colors.neutral60,
				"&.Mui-focused": {
					color: borderColorFixed
						? Colors.neutral90
						: Colors.active60,
				},
				"&.Mui-error": {
					color: Colors.neutral90,
				},
			},
			"& .MuiOutlinedInput-root": {
				borderRadius: Size.sm,
				padding: rows ? 0 : undefined,
				border: Size.x3s + " solid transparent",
				color: Colors.neutral100,
				paddingRight: lightPaddingRight ? Size.xs : undefined,
				"& input:disabled": {
					color: Colors.neutral100,
				},
				"&.Mui-disabled > fieldset": {
					zIndex: 0,
					backgroundColor: Colors.primary20,
				},
				"&.MuiOutlinedInput-input": {
					padding: 0,
				},
				"& fieldset": {
					border:
						Size.borderStroke +
						" solid " +
						(borderColorFixed
							? borderColorFixed
							: Colors.neutral70),
					borderRadius: Size.cornerRadius,
				},
				"&.Mui-focused": {
					border:
						Size.x3s +
						" solid " +
						(borderColorFixed ? "transparent" : Colors.active20),
					"& fieldset": {
						border:
							Size.borderStroke +
							" solid " +
							(borderColorFixed
								? borderColorFixed
								: Colors.active60),
					},
				},
				"&:hover": {
					"& fieldset": {
						border: disabled
							? undefined
							: Size.borderStroke +
							  " solid " +
							  (borderColorFixed
									? borderColorFixed
									: Colors.active50),
					},
				},
			},
			"& .textAreaCustomContainer": {
				width: "100%",
				maxWidth: "100%",
				resize: "vertical",
				padding: `${Size.x2s} ${Size.x2s} 0`,
				backgroundColor: disabled ? Colors.primary20 : Colors.neutral10,
				borderRadius: Size.cornerRadius,
				"&>.textAreaCustom": {
					width: "100%",
					boxSizing: "border-box",
					maxWidth: "100%",
					resize: "vertical",
					border: "none",
					outline: "none",
					padding: Size.sm_small,
					fontFamily: "Inter",
					fontSize: variants.body1.fontSize,
					fontWeight: variants.body1.fontWeight,
					backgroundColor: disabled
						? Colors.primary20
						: Colors.neutral10,
					color: Colors.neutral100,
				},
			},
		};
	},
);

type IInputIcon = {
	inputValue?: string;
	icon: IIcon;
	action?: () => void;
};

const InputIcon = (inputIcon: IInputIcon) => {
	const iconColor = inputIcon.inputValue
		? Colors.neutral100
		: Colors.neutral60;
	return (
		<div
			onClick={inputIcon.action}
			style={{
				cursor: inputIcon.action ? "pointer" : undefined,
			}}
		>
			<Icon
				{...inputIcon.icon}
				color={inputIcon.icon?.color ? inputIcon.icon.color : iconColor}
			/>
		</div>
	);
};

// eslint-disable-next-line react/display-name, @typescript-eslint/no-unused-vars
const CustomTextareaAutosize = React.forwardRef((props: IInput, ref) => (
	<div className="textAreaCustomContainer">
		<TextareaAutosize
			{...props}
			minRows={props.rows}
			maxRows={10}
			className="textAreaCustom"
		/>
	</div>
));

export const Input = (input: IInput) => {
	return (
		<StoryTheme>
			<TextFieldStyled
				className={
					"InputTextCustom" +
					(input.className ? " " + input.className : "")
				}
				required={input.required}
				label={input.label}
				value={input.value}
				onChange={input.onChange}
				onClick={input.onClick}
				onBlur={input.onChange}
				disabled={input.disabled}
				success={input.success}
				error={input.error}
				placeholder={input.placeholder}
				InputProps={
					input.InputProps
						? input.InputProps
						: {
								startAdornment: input.startIcon && (
									<InputIcon
										inputValue={input.value}
										icon={input.startIcon}
										action={input.startIconAction}
									/>
								),
								// eslint-disable-next-line no-nested-ternary
								endAdornment: input.endIconButton ? (
									<Button {...input.endIconButton} />
								) : input.info ? (
									<Tooltip text={input.info} placement="top">
										<div
											style={{
												width: 36,
												height: 36,
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												cursor: "help",
											}}
										>
											<InputIcon
												icon={{ name: "info--filled" }}
											/>
										</div>
									</Tooltip>
								) : (
									input.endIcon && (
										<InputIcon
											inputValue={input.value}
											icon={input.endIcon}
											action={input.endIconAction}
										/>
									)
								),
								inputComponent: input.rows
									? CustomTextareaAutosize
									: input.mask,
								style: { width: "100%" },
						  }
				}
				inputProps={input.inputProps}
				InputLabelProps={
					input.InputLabelProps
						? input.InputLabelProps
						: {
								shrink:
									input.placeholder || input.value
										? true
										: undefined,
						  }
				}
				rows={input.rows}
				type={input.type}
				info={input.info}
				width={input.width}
				inputRef={input.inputRef}
			/>
			{input.error && input.errorMessage && (
				<HelperError text={input.errorMessage || ""} />
			)}
		</StoryTheme>
	);
};
