import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import {
	EntitySerializer,
	formatString,
	SETtemplateExpStructMock,
} from "common";
import { EExperimentStatus } from "shared-type";

import DorianeButton from "App/components/base-components/button/DorianeButton";
import TopMenu from "App/components/base-components/menu/top-menu/TopMenu";
import CommentDrawer from "App/components/base-components/modal/comment-drawer/CommentDrawer";
import { CustomLinkTab } from "App/components/base-components/tabs/CustomLinkTab";
import { CustomTabs } from "App/components/base-components/tabs/CustomTabs";
import { DIC, EDIC_KEY } from "App/dictionary";
import { selectECW } from "App/redux/ECW/ECW.selector";
import { ECWAction } from "App/redux/ECW/ECW.slice";
import { trialWizardFetchAction } from "App/redux/ECW/trial-wizard-fetch-reducer";
import { trialWizardSaveAction } from "App/redux/ECW/trial-wizard.save";
import {
	PATH_DATA,
	PATH_EXPERIMENTS,
	PATH_GENERAL,
	PATH_GERMPLASM,
	PATH_LOCATION,
	PATH_OBSERVATION_VARIABLES,
	PATH_PLANNING,
	PATH_SYNTHESIS,
	PATH_TRIALS,
	PATH_TRIALS_CREATION,
} from "App/routes";
import { AppDispatch } from "App/store";
import { Validate } from "assets";
import styles from "../../../../../../sassTheme";
import FlexBox from "../../../../../components/base-components/placement/flex-box/FlexBox";
import ExperimentConclusionModal from "../../../../../components/experiment/common/modal/conclusion/ExperimentConclusionModal";
import ExperimentDeleteModal from "../../../../../components/experiment/common/modal/delete-modal/ExperimentDeleteModal";
import ExpSelectGrowingArea from "../../../../../components/experiment/common/modal/ExpSelectGrowingArea";
import { ExperimentAddMaterial } from "../../../../../components/experiment/common/modal/germplasm/add-material/ExperimentAddMaterial";
import TrialGenericBanner from "../../../../../components/experiment/trial/card/TrialGenericBanner";
import { useTrialWithDeps } from "../../../../../components/experiment/trial/list/TrialWithDeps.hook";
import CreationObsRoundModal from "../../../../../components/task/observation-round/modal/CreationObsRoundModal";
import OperationModal from "../../../../../components/task/operation/modal/OperationModal";
import useAppSnackbar from "../../../../../hooks/useAppSnackbar";
import { apiTrial } from "../../../../../redux/experiment/trial/trial.api";
import { ApiErrorSnackbar } from "../../../../../redux/utils/api-error-snackbar/ApiErrorSnackbar";

import "./TrialEditionPage.scss";

export default function TrialEditionPage() {
	const dispatch = useDispatch<AppDispatch>();
	const { trialId } = useParams();
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();
	const nav = useNavigate();
	const location = useLocation();

	const [isCommentOpen, setCommentOpen] = useState<boolean>(false);
	const [tabIdx, setTabIdx] = useState(0);
	const [isDeletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);

	const [deleteTrialMutation] = apiTrial.useDeleteTrialByIdMutation();

	const isPageBannerDisplay = useSelector(selectECW.isPageBannerDisplay);
	const isPageFullSize = useSelector(selectECW.isPageFullSize);
	const trial = useSelector(selectECW.trialRemote);
	const { trialsWithDeps } = useTrialWithDeps(
		trial ? [EntitySerializer.serialize(trial)] : [],
		false,
		true,
	);
	const trialMaterials = useSelector(selectECW.selectedMaterials);
	const trialComments = useSelector(selectECW.comments);
	const isAddMaterialOpen = useSelector(selectECW.isAddMaterialOpen);
	const isSelectLocationOpen = useSelector(selectECW.isSelectGrowingAreaOpen);
	const isCreateObsRoundOpen = useSelector(selectECW.isCreateObsRoundOpen);
	const isCreateOperationOpen = useSelector(selectECW.isCreateOperationOpen);
	const isConclusionOpen = useSelector(selectECW.isConclusionOpen);
	const trialHasChanged = useSelector(selectECW.hasChanges);
	const editedOperationId = useSelector(selectECW.editedOperationId);

	useEffect(() => {
		if (trialId) {
			dispatch(trialWizardFetchAction.fetchTrialAndDependencies(trialId));
		}
		return () => {
			dispatch(ECWAction.reset({ wizardType: "SET", isInit: false }));
		};
	}, [dispatch, trialId]);

	useEffect(() => {
		const splittedPath = location.pathname.split("/");
		const currentTab = "/" + splittedPath[4] ?? undefined;

		switch (currentTab) {
			case PATH_GENERAL:
				setTabIdx(0);
				break;
			case PATH_OBSERVATION_VARIABLES:
				setTabIdx(1);
				break;
			case PATH_GERMPLASM:
				setTabIdx(2);
				break;
			case PATH_LOCATION:
				setTabIdx(3);
				break;
			case PATH_PLANNING:
				setTabIdx(4);
				break;
			case PATH_DATA:
				setTabIdx(5);
				break;
			case PATH_SYNTHESIS:
				setTabIdx(6);
				break;
			default:
				nav(`.${PATH_GENERAL}`, { replace: true });
				setTabIdx(0);
				break;
		}
	}, [location, nav]);

	useEffect(() => {
		if (trialId === "new" || trial?.status === EExperimentStatus.DRAFT) {
			nav(`${PATH_TRIALS_CREATION}/${trialId}`);
		}
	}, [trialId, trial?.status, nav]);

	useEffect(() => {
		if (trialHasChanged) {
			dispatch(ECWAction.setConclusionIsOpen(false));
		}
	}, [dispatch, trialHasChanged]);

	const handleDelete = async () => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		deleteTrialMutation(trial!._id)
			.unwrap()
			.then(() => {
				enqueueSnackbarSuccess(
					formatString(
						DIC(EDIC_KEY.ENTITY_DELETED),
						DIC(EDIC_KEY.TRIAL),
					),
				);
				nav(`${PATH_EXPERIMENTS}${PATH_TRIALS}`);
			})
			.catch((err) => {
				enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
			});
	};

	const handleSaveClick = () => {
		dispatch(trialWizardSaveAction.saveTrial(true))
			.unwrap()
			.then(() => {
				enqueueSnackbarSuccess(DIC(EDIC_KEY.SAVE));
			})
			.catch((err) => {
				console.warn(err);
				enqueueSnackbarError(
					formatString(
						DIC(EDIC_KEY.CANT_ACTION_ENTITY),
						DIC(EDIC_KEY.SAVE).toLowerCase(),
						DIC(EDIC_KEY.TRIAL).toLowerCase(),
					),
				);
			});
	};

	const isSaveButtonDisabled = () => {
		if (trialMaterials !== undefined) {
			return trialMaterials.length === 0;
		}
		return true;
	};
	const displayValidatedTrialButton = useMemo(() => {
		return (
			<DorianeButton
				dorianeStyle="quaternary"
				onClick={() => {
					dispatch(ECWAction.setConclusionIsOpen(true));
					setCommentOpen(false);
				}}
				disabled={trialHasChanged}
			>
				<img src={Validate} />
			</DorianeButton>
		);
	}, [dispatch, trialHasChanged]);

	return (
		<FlexBox className="TrialEditionPage-container" fullParentSize>
			<FlexBox
				flexDirection={"column"}
				takeRemainingSpace
				className={
					"TrialEditionPage-elements" +
					(isPageFullSize ? "" : " TrialEditionPage-elements-small")
				}
			>
				{trial && (
					<>
						<TopMenu
							onBackClick={() =>
								nav(`${PATH_EXPERIMENTS}${PATH_TRIALS}`)
							}
							deleteButton={{
								onClick: () => setDeletePopupOpen(true),
							}}
							saveButton={{
								onClick: !isPageBannerDisplay
									? undefined
									: handleSaveClick,
								isDisabled: isSaveButtonDisabled(),
							}}
							onCommentClick={() => {
								setCommentOpen(true);
								dispatch(ECWAction.setConclusionIsOpen(false));
							}}
							additionalButton={displayValidatedTrialButton}
							title={
								!isPageBannerDisplay ? trial.name : undefined
							}
						/>
						{isPageBannerDisplay && (
							<TrialGenericBanner
								trial={
									trialsWithDeps[0] ??
									EntitySerializer.serialize(trial)
								}
								templateExpStructure={SETtemplateExpStructMock}
								disabledClick
							/>
						)}
					</>
				)}
				<FlexBox takeRemainingSpace flexDirection={"column"}>
					<CustomTabs
						value={tabIdx}
						sx={{
							backgroundColor: styles["primary-background-color"],
						}}
					>
						<CustomLinkTab
							href={`.${PATH_GENERAL}`}
							label={DIC(EDIC_KEY.GENERAL)}
						/>
						<CustomLinkTab
							href={`.${PATH_OBSERVATION_VARIABLES}`}
							label={DIC(EDIC_KEY.OBSERVATION_VARIABLES)}
						/>
						<CustomLinkTab
							href={`.${PATH_GERMPLASM}`}
							label={DIC(EDIC_KEY.VARIETY_LIST)}
						/>
						<CustomLinkTab
							href={`.${PATH_LOCATION}`}
							label={DIC(EDIC_KEY.LOCATION)}
						/>
						<CustomLinkTab
							href={`.${PATH_PLANNING}`}
							label={DIC(EDIC_KEY.PLANNING)}
						/>
						<CustomLinkTab
							href={`.${PATH_DATA}`}
							label={DIC(EDIC_KEY.DATA)}
						/>
						<CustomLinkTab
							href={`.${PATH_SYNTHESIS}`}
							label={DIC(EDIC_KEY.SYNTHESIS)}
						/>
					</CustomTabs>

					<FlexBox takeRemainingSpace flexDirection={"column"}>
						{trial ? (
							<Outlet />
						) : (
							<div>{DIC(EDIC_KEY.LOADING_DOTS)}</div>
						)}
					</FlexBox>
				</FlexBox>
			</FlexBox>
			{isAddMaterialOpen && (
				<FlexBox
					takeRemainingSpace
					className="TrialEditionPage-right-side"
				>
					<ExperimentAddMaterial />
				</FlexBox>
			)}
			{isSelectLocationOpen && (
				<FlexBox
					takeRemainingSpace
					className="TrialEditionPage-right-side"
				>
					<ExpSelectGrowingArea />
				</FlexBox>
			)}
			<ExperimentDeleteModal
				open={isDeletePopupOpen}
				isDraft={false}
				isMET={false}
				onClose={() => setDeletePopupOpen(false)}
				deleteAction={handleDelete}
			/>
			{isCommentOpen && (
				<CommentDrawer
					onClose={() => setCommentOpen(false)}
					remoteComments={trialComments}
					onSaveComment={async (comments) => {
						await dispatch(
							trialWizardSaveAction.saveTrialComments(comments),
						).unwrap();
					}}
				/>
			)}
			{isCreateObsRoundOpen && (
				<FlexBox
					takeRemainingSpace
					className="TrialEditionPage-right-side"
				>
					<CreationObsRoundModal
						onClose={() =>
							dispatch(ECWAction.setCreateObsRoundOpen(false))
						}
					/>
				</FlexBox>
			)}
			{isCreateOperationOpen && (
				<FlexBox
					takeRemainingSpace
					className="TrialEditionPage-right-side"
				>
					<OperationModal
						mode="creation"
						onClose={() =>
							dispatch(ECWAction.setCreateOperationOpen(false))
						}
					/>
				</FlexBox>
			)}
			{editedOperationId && (
				<FlexBox
					takeRemainingSpace
					className="TrialEditionPage-right-side"
				>
					<OperationModal
						mode="edition"
						onClose={() =>
							dispatch(
								ECWAction.updateEditedOperationId(undefined),
							)
						}
					/>
				</FlexBox>
			)}
			{isConclusionOpen && <ExperimentConclusionModal />}
		</FlexBox>
	);
}
