import React from "react";

import { formatString, SETtemplateExpStructMock } from "common";

import { DIC, EDIC_KEY } from "../../../../dictionary";
import SkeletonCustomCard from "../../../base-components/card/SkeletonCustomCard";
import PaginationTool from "../../../base-components/pagination/PaginationTool";
import FlexBox, {
	EGapSize,
} from "../../../base-components/placement/flex-box/FlexBox";
import TrialGenericCard from "../card/TrialGenericCard";
import { TrialSerializedWithDeps } from "./TrialWithDeps.hook";

export interface ITrialCardListProps {
	trials: Partial<TrialSerializedWithDeps>[] | undefined;
	isLoading?: boolean;
	isError?: boolean;
	onCardClick?: (trial: Partial<TrialSerializedWithDeps>) => void;
	className?: string;
	paginate?: {
		onPageChange: (newPage: number) => void;
		currentPage: number;
		totalElements: number;
	};
}
export default function TrialCardList(props: ITrialCardListProps) {
	const paginate = props.paginate;

	if (props.isLoading) {
		return (
			<FlexBox
				className={props.className}
				flexDirection={"column"}
				gap={EGapSize.MEDIUM}
			>
				{[1, 1, 1, 1].map((elt, index) => (
					<SkeletonCustomCard key={index} />
				))}
			</FlexBox>
		);
	}
	if (props.isError) {
		return (
			<div className={props.className}>
				{formatString(
					DIC(EDIC_KEY.CANT_ACTION_ENTITY),
					DIC(EDIC_KEY.FETCH).toLowerCase(),
					DIC(EDIC_KEY.TRIALS).toLowerCase(),
				)}
			</div>
		);
	}
	if (!props.trials || props.trials.length === 0) {
		return (
			<div className={props.className}>
				{formatString(
					DIC(EDIC_KEY.NO_ENTITIES_FOUND),
					DIC(EDIC_KEY.TRIALS),
				)}
			</div>
		);
	}
	return (
		<FlexBox
			className={props.className}
			flexDirection="column"
			overflow={"auto"}
			takeRemainingSpace
			gap={EGapSize.MEDIUM}
		>
			<FlexBox
				flexDirection="column"
				takeRemainingSpace
				overflow={"auto"}
				gap={EGapSize.MEDIUM}
			>
				{props.trials?.map((elt) => (
					<TrialGenericCard
						onClick={() => {
							props.onCardClick?.(elt);
						}}
						trial={elt}
						templateExpStructure={SETtemplateExpStructMock}
						key={elt._id}
					/>
				))}
			</FlexBox>
			{paginate !== undefined && (
				<FlexBox justifyContent={"center"}>
					<PaginationTool
						currentPage={paginate.currentPage}
						maxPage={paginate.totalElements}
						onPageRoll={paginate.onPageChange}
					/>
				</FlexBox>
			)}
		</FlexBox>
	);
}
