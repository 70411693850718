import { configuration } from "../config";
import { ResourcesConfig } from "aws-amplify";

export const amplifyConfig = (): ResourcesConfig => {
	return {
		Auth: {
			Cognito: {
				userPoolId: configuration.COGNITO_USER_POOL_ID,
				userPoolClientId: configuration.COGNITO_CLIENT_ID,
				loginWith: {
					oauth: {
						domain: configuration.COGNITO_DOMAIN,
						scopes: [
							"aws.cognito.signin.user.admin",
							"email",
							"openid",
							"phone",
							"profile",
						],
						redirectSignIn: [window.location.origin],
						redirectSignOut: [window.location.origin + "/"],
						responseType: "code",
					},
				},
			},
		},
	};
};
