import React from "react";

import { calculatePercentage } from "common";

import "./CompletionColumn.scss";
import styles from "../../../../../../sassTheme";

interface CompletionColumnProps {
	levelCompletion?: {
		actual: number;
		expected: number;
	};
}

export default function CompletionColumn(props: CompletionColumnProps) {
	const percentage = calculatePercentage(
		props.levelCompletion?.actual ?? 0,
		props.levelCompletion?.expected ?? 0,
	);

	return (
		<div className="CompletionColumn-container">
			{props.levelCompletion && (
				<div
					className="CompletionColumn-background"
					style={{
						width: `${percentage === 0 ? 5 : percentage}%`,
						backgroundColor:
							percentage === 100
								? styles["completion-completed-color"]
								: styles["completion-partial-color"],
					}}
				/>
			)}
			<div className="CompletionColumn-text-container">
				{props.levelCompletion
					? `${props.levelCompletion.actual}/${props.levelCompletion.expected}`
					: ""}
			</div>
		</div>
	);
}
