import React from "react";

import { Icon } from "stories/base-components/Icon/Icon";
import { IIconType } from "stories/base-components/Icon/IconType";
import Colors from "stories/constants/Colors/Colors";
import { StoryTheme } from "stories/StoryTheme";
import Size from "stories/constants/Size/Size";

import "./avatarIcon.scss";

export type IAvatarIcon = {
	iconName: IIconType;
	color?: string;
	backgroundColor?: string;
	template?: boolean;
};

export const AvatarIcon = (avatarIcon: IAvatarIcon) => {
	return (
		<StoryTheme>
			<div
				className="avatarIcon"
				style={{
					backgroundColor: avatarIcon.backgroundColor
						? avatarIcon.backgroundColor
						: Colors.primary20,
				}}
			>
				<Icon
					name={avatarIcon.iconName}
					color={
						avatarIcon.color ? avatarIcon.color : Colors.primary60
					}
					fontSize={Size.md}
				/>
				{avatarIcon.template && (
					<div className="avatarIconTemplateContainer">
						<div
							className="avatarIconTemplate"
							style={{
								backgroundColor: avatarIcon.backgroundColor
									? avatarIcon.backgroundColor
									: Colors.primary20,
							}}
						>
							<Icon
								name="template"
								color={
									avatarIcon.color
										? avatarIcon.color
										: Colors.primary60
								}
								fontSize={Size.sm_small}
							/>
						</div>
					</div>
				)}
			</div>
		</StoryTheme>
	);
};
