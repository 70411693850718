import React from "react";
import { RouteObject } from "react-router-dom";

import { PARAM_GROWING_AREA_ID, PATH_GROWING_AREA } from "../../routes";
import GrowingAreaEditPage from "../../Views/main/growing-area/edition/GrowingAreaEditPage";
import GrowingAreaPage from "../../Views/main/growing-area/GrowingAreaPage";

export function growingAreaRouter(): RouteObject {
	return {
		path: PATH_GROWING_AREA,
		children: [
			{ path: PATH_GROWING_AREA, element: <GrowingAreaPage /> },
			{
				path: PATH_GROWING_AREA + PARAM_GROWING_AREA_ID,
				element: <GrowingAreaEditPage hideNavBack={false} />,
			},
		],
	};
}
