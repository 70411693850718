import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IModalButton, IModalType } from "stories/base-components/Modal/Modal";

export interface IGlobalModal {
	title: string;
	type: IModalType;
	btn: IModalButton;
	descriptions: Array<string>;
	cancelLabel?: string;
	width?: number;
}

export interface IGlobalState {
	modal?: IGlobalModal;
}
function createInitialState(): IGlobalState {
	return {};
}
const global = createSlice({
	name: "global",
	initialState: createInitialState(),
	reducers: {
		selectGlobalModal: (
			state,
			action: PayloadAction<IGlobalModal | undefined>,
		) => {
			state.modal = action.payload;
		},
	},
});

export const globalReducer = global.reducer;
export const globalAction = {
	...global.actions,
};
