import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DorianeButton from "App/components/base-components/button/DorianeButton";
import TextSearchBar from "App/components/base-components/searchBar/TextSearchBar";
import GermplasmMaterialList from "App/components/germplasm/material-list/GermplasmMaterialList";
import { DIC, EDIC_KEY } from "App/dictionary";
import { selectECW } from "App/redux/ECW/ECW.selector";
import { ECWAction } from "App/redux/ECW/ECW.slice";
import { AppDispatch } from "App/store";

import "./ExpVarietyListPage.scss";

export default function ExpVarietyListPage() {
	const dispatch = useDispatch<AppDispatch>();

	const materialType = useSelector(selectECW.germplasmLevel);
	const materials = useSelector(selectECW.selectedMaterials);
	const addMaterialIsOpen = useSelector(selectECW.isAddMaterialOpen);

	const [filter, setFilter] = useState<string>("");

	const isMaterialOriginSelected = useMemo(
		() => materialType !== undefined,
		[materialType],
	);

	useEffect(() => {
		return () => {
			dispatch(ECWAction.setAddMaterialOpen(false));
		};
	}, [dispatch]);

	return (
		<div className="ExpVarietyListPage-container take-remaining-space flex-column">
			{isMaterialOriginSelected && (
				<div className="ExpVarietyListPage-body flex-column take-remaining-space">
					<div className="ExpVarietyListPage-list-header">
						<TextSearchBar
							onChange={(newFilter) => {
								setFilter(newFilter);
							}}
						/>
						<DorianeButton
							dorianeStyle="tertiary"
							onClick={() =>
								dispatch(
									ECWAction.setAddMaterialOpen(
										!addMaterialIsOpen,
									),
								)
							}
						>
							{DIC(EDIC_KEY.EDIT_SELECTION)}
						</DorianeButton>
					</div>
					<GermplasmMaterialList
						type={materialType}
						materialIds={materials}
						filter={filter}
					/>
				</div>
			)}
		</div>
	);
}
