import React, { useState } from "react";

import DorianeButton from "../../../components/base-components/button/DorianeButton";
import DorianeModal from "../../../components/base-components/modal/DorianeModal";
import FlexBox, {
	EGapSize,
} from "../../../components/base-components/placement/flex-box/FlexBox";
import useAppSnackbar from "../../../hooks/useAppSnackbar";
import { useGetCurrentTenant } from "../../../redux/tenant/hooks";
import { apiUserBackup } from "../../../redux/user/backup/backup.api";
import { ApiErrorSnackbar } from "../../../redux/utils/api-error-snackbar/ApiErrorSnackbar";

export default function Backup() {
	const { enqueueSnackbarSuccess, enqueueSnackbarWarning } = useAppSnackbar();
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const { tenant } = useGetCurrentTenant();
	const [restoreDumpQuery, restoreDumpResult] =
		apiUserBackup.useRestoreDumpMutation();

	const restoreDumpHandler = async () => {
		if (restoreDumpResult.isLoading) {
			return;
		}
		await restoreDumpQuery(undefined)
			.unwrap()
			.then(() => {
				enqueueSnackbarSuccess("Restore completed");
			})
			.catch((err) => {
				enqueueSnackbarWarning(<ApiErrorSnackbar error={err} />);
			});
		setIsPopupOpen(false);
	};
	return (
		<div>
			<div>
				If you click on this button, all data from the &quot;
				<b>{tenant?.displayName ?? "unknown"}</b>&quot; tenant will be
				restored to the latest backup point.
			</div>
			<DorianeButton onClick={() => setIsPopupOpen(true)}>
				Restore
			</DorianeButton>
			{isPopupOpen && (
				<DorianeModal onClose={() => setIsPopupOpen(false)}>
					<FlexBox
						flexDirection={"column"}
						fullParentSize
						gap={EGapSize.MEDIUM}
					>
						<div>
							Please, confirm the restore for tenant :
							<b>{tenant?.displayName ?? "unknown"}</b>
						</div>
						<FlexBox
							justifyContent={"flex-end"}
							gap={EGapSize.MEDIUM}
						>
							<DorianeButton
								onClick={() => setIsPopupOpen(false)}
								dorianeStyle="secondary"
								disabled={restoreDumpResult.isLoading}
							>
								Cancel
							</DorianeButton>
							<DorianeButton
								onClick={restoreDumpHandler}
								dorianeStyle="primary"
								disabled={restoreDumpResult.isLoading}
								isLoading={restoreDumpResult.isLoading}
							>
								Confirm
							</DorianeButton>
						</FlexBox>
					</FlexBox>
				</DorianeModal>
			)}
		</div>
	);
}
