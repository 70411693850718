import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import {
	ICreateVariableGroup,
	IPatchVariableGroup,
	IScopedVarWithLevelInfo,
	IVarGroupDetailedSerialized,
	IVariableGroupSerialized,
} from "shared-type";

import { AbstractRequest } from "../abstract-request";

export class VariableGroupRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/variable-group";
	}

	createVariableGroup(
		createVarGroup: ICreateVariableGroup
	): Promise<AxiosResponse<string, any>> {
		return this.axios.post<
			string,
			AxiosResponse<string, any>,
			ICreateVariableGroup
		>(`${this.baseUrl}`, createVarGroup);
	}

	getVariableGroupById(
		id: string,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<IVariableGroupSerialized, any>> {
		return this.axios.get<IVariableGroupSerialized>(
			`${this.baseUrl}/${id}`,
			config
		);
	}

	getVariableGroupDetailedByIds(
		ids: string[],
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<IVarGroupDetailedSerialized[], any>> {
		return this.axios.post<IVarGroupDetailedSerialized[]>(
			`${this.baseUrl}/get/many/detailed`,
			ids,
			config
		);
	}

	getVariableGroupsByIds(varGroupIds: string[], config?: AxiosRequestConfig) {
		return this.axios.post<IVariableGroupSerialized[]>(
			this.baseUrl + "/get/many",
			varGroupIds,
			config
		);
	}

	getVarLevelInfoByVarGroupId(
		id: string,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<IScopedVarWithLevelInfo, any>> {
		return this.axios.get<IScopedVarWithLevelInfo>(
			`${this.baseUrl}/level-infos/${id}`,
			config
		);
	}

	getVariableGroupUsagesById(
		id: string
	): Promise<AxiosResponse<string[], any>> {
		return this.axios.get<string[]>(`${this.baseUrl}/usage/${id}`);
	}

	getVarGroupsByTemplateObsId(
		templateObsId: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<IVariableGroupSerialized[], any>> {
		return this.axios.get<IVariableGroupSerialized[]>(
			this.baseUrl + "/template-observation/" + templateObsId,
			config
		);
	}

	updateVarGroupById(
		id: string,
		updateVarGroup: IPatchVariableGroup
	): Promise<AxiosResponse<void, any>> {
		return this.axios.patch<void, AxiosResponse<void, any>>(
			`${this.baseUrl}/${id}`,
			updateVarGroup
		);
	}

	deleteVarGroupById(id: string): Promise<AxiosResponse<void, any>> {
		return this.axios.delete<void, AxiosResponse<void, any>>(
			`${this.baseUrl}/${id}`
		);
	}
}
