import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { DIC, EDIC_KEY } from "App/dictionary";
import { PATH_EXPERIMENTS, PATH_METS, PATH_TRIALS } from "../../../../routes";
import { Tabs } from "stories/base-components/Navigation/Tabs/Tabs";

import "./ExperimentsPage.scss";

export default function ExperimentsPage() {
	const location = useLocation();
	const nav = useNavigate();

	useEffect(() => {
		const redirectToTrial =
			location.pathname
				.split("/")
				.filter((pathPart: string) => Boolean(pathPart)).length === 1;

		if (redirectToTrial) {
			nav(`${PATH_EXPERIMENTS}${PATH_TRIALS}`, { replace: true });
		}
	}, [location.pathname, nav]);

	return (
		<div className="ExperimentsPage-container full-parent-size flex-column">
			<div className="ExperimentsPage-header">
				<Tabs
					tabs={[
						{
							tabLabel: DIC(EDIC_KEY.TRIAL),
							path: `.${PATH_TRIALS}`,
						},
						{
							tabLabel: DIC(EDIC_KEY.MET),
							path: `.${PATH_METS}`,
						},
					]}
					validatePathLevel={2}
				/>
			</div>
			<div className="flex-column take-remaining-space">
				<Outlet />
			</div>
		</div>
	);
}
