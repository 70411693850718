import React from "react";
import { useNavigate } from "react-router-dom";

import DorianeButton from "App/components/base-components/button/DorianeButton";
import CustomCard from "App/components/base-components/card/CustomCard";
import { NotFound } from "assets";

import "./NotFoundPage.scss";

export const NotFoundPage = () => {
	const navigation = useNavigate();
	return (
		<div className="NotFoundPage center">
			<CustomCard className="NotFoundPage-container" disabledClick>
				<h1>The page you’re looking for doesn’t exist.</h1>
				<img src={NotFound} className="NotFoundPage-img" alt="" />
				<DorianeButton onClick={() => navigation("/projects")}>
					Back Home
				</DorianeButton>
			</CustomCard>
		</div>
	);
};
