import React from "react";

import { concatClassName } from "common";

import { DorianeDataGridCell } from "../cell/DorianeDataGridCell";
import { IColumn } from "../DorianeDataGrid";

export type IDorianeDataGridRowProps<Row> = {
	row: Row;
	columns: IColumn<Row>[];
	computedColumnsWidth: string;
	className?: string;
	cellOnClick?: (
		event: React.MouseEvent<HTMLDivElement>,
		row: Row,
		column: IColumn<Row>,
	) => void;
	rowOnClick?: (event: React.MouseEvent<HTMLDivElement>, row: Row) => void;
	rowHeight: string;
	secondaryStyle?: boolean;
};
export function DorianeDataGridRow<Row>(props: IDorianeDataGridRowProps<Row>) {
	return (
		<div
			className={concatClassName(
				"DorianeDataGridRow",
				props.secondaryStyle ? "DorianeDataGridRowSecondaryStyle" : "",
				props.className ? props.className : "",
			)}
			style={{
				gridTemplateColumns: props.computedColumnsWidth,
			}}
			onClick={(event) => props.rowOnClick?.(event, props.row)}
		>
			{props.columns.map((column) => (
				<DorianeDataGridCell
					column={column}
					row={props.row}
					key={column.id}
					cellOnClick={props.cellOnClick}
					secondaryStyle={props.secondaryStyle}
				/>
			))}
		</div>
	);
}
