import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { IFilter, IChart, ISort, IAnalyticViewMetadata } from "shared-type";

import { AbstractRequest } from "../abstract-request";

export class ChartRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/charts";
	}

	getCharts(
		filter?: IFilter,
		sort?: ISort,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<IChart[], any>> {
		return this.axios.get<IChart[]>(`${this.baseUrl}`, {
			...config,
			params: {
				...config?.params,
				filter: JSON.stringify(filter),
				sort: JSON.stringify(sort),
			},
		});
	}

	getAnalyticViewMetadata(
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<IAnalyticViewMetadata, any>> {
		return this.axios.get<IAnalyticViewMetadata>(
			`${this.baseUrl + "/analyticView/metadata"}`,
			{
				...config,
				params: {
					...config?.params,
				},
			}
		);
	}

	updateAnalyticView(
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<void | undefined, any>> {
		return this.axios.post<void | undefined>(
			`${this.baseUrl + "/analyticView"}`,
			{
				...config,
				params: {
					...config?.params,
				},
			}
		);
	}
}
