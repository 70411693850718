"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMultiComputeMethod = exports.EMultiExpectationType = exports.EVariableCoreInputType = void 0;
var EVariableCoreInputType;
(function (EVariableCoreInputType) {
    EVariableCoreInputType["LIMITED_CHOICE"] = "limited choice";
    EVariableCoreInputType["FREE"] = "free";
})(EVariableCoreInputType = exports.EVariableCoreInputType || (exports.EVariableCoreInputType = {}));
var EMultiExpectationType;
(function (EMultiExpectationType) {
    EMultiExpectationType["FIXED"] = "fixed";
    EMultiExpectationType["MINIMUM"] = "minimum";
    EMultiExpectationType["FREE"] = "free";
})(EMultiExpectationType = exports.EMultiExpectationType || (exports.EMultiExpectationType = {}));
var EMultiComputeMethod;
(function (EMultiComputeMethod) {
    EMultiComputeMethod["COUNT"] = "count";
    EMultiComputeMethod["SUM"] = "sum";
    EMultiComputeMethod["MIN"] = "minimum";
    EMultiComputeMethod["MAX"] = "maximum";
    EMultiComputeMethod["AVERAGE"] = "average";
    EMultiComputeMethod["MEDIAN"] = "median";
    EMultiComputeMethod["STANDARD_DEV"] = "standard deviation";
})(EMultiComputeMethod = exports.EMultiComputeMethod || (exports.EMultiComputeMethod = {}));
