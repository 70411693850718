import axios, { Axios, AxiosResponse } from "axios";

// FIXME: move those constants to a config file or env variables
const clientId = "69abaa93-5b54-456b-b5a4-5cbcd22a2a4f";
const tenantName = "RnDExperience";
const userFlow = "B2C_1_ropc";

/**
 * Interface representing the aggregation of access, refresh and id_token Azure B2C tokens.
 * access and refresh tokens are following the OAuth2 standard (RFC 6749).
 * id_token is following the JWT standard (RFC 7519).
 */
export interface IAzureB2CTokens {
	access_token: string;
	token_type: string;
	expires_in: string;
	refresh_token: string;
}

/**
 * Interface representing B2C tokens returned by a refresh request.
 * This API payload is specific to the refresh token endpoint and contains additional information.
 */
export interface IAzureB2CRefreshedTokens {
	access_token: string;
	id_token: string;
	token_type: string;
	not_before: number;
	expires_in: number;
	expires_on: number;
	resource: string;
	id_token_expires_in: number;
	profile_info: string;
	scope: string;
	refresh_token: string;
}

/**
 * Interface representing the payload sent to Azure B2C token endpoint to get tokens.
 */
export interface IAzureB2CTokensRequestPayload {
	client_id: string;
	scope: string;
	grant_type: string;
	username: string;
	password: string;
}

/**
 * Interface representing the payload sent to Azure B2C token endpoint to get a refreshed token.
 */
export interface IAzureB2CRefreshTokensRequestPayload {
	grant_type: string;
	refresh_token: string;
}

export class AzureAuth {
	private axios: Axios;
	private host: string;
	constructor() {
		this.axios = axios.create();
		this.host = `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${userFlow}`;
	}

	/**
	 * Get user tokens from Azure B2C token endpoint
	 * @param username username
	 * @param password user's password
	 * @returns generated B2C tokens (access, refresh and id)
	 */
	getToken = async (
		username: string,
		password: string
	): Promise<AxiosResponse<IAzureB2CTokens, any>> => {
		const requestParams: IAzureB2CTokensRequestPayload = {
			grant_type: "password",
			client_id: clientId,
			scope: `${clientId} openid offline_access`,
			username: username,
			password,
		};

		return this.axios.post<
			IAzureB2CTokens,
			AxiosResponse<IAzureB2CTokens>,
			any
		>(this.host + "/oauth2/v2.0/token", undefined, {
			params: { ...requestParams },
		});
	};

	/**
	 * Get a refreshed token from Azure B2C token endpoint
	 * @param refreshToken : refresh token
	 * @returns azure B2C refreshed tokens (access, refresh and id)
	 */
	getTokenWithRefresh = async (
		refreshToken: string
	): Promise<AxiosResponse<IAzureB2CRefreshedTokens, any>> => {
		const requestParams: IAzureB2CRefreshTokensRequestPayload = {
			grant_type: "refresh_token",
			refresh_token: refreshToken,
		};

		return this.axios.post<
			IAzureB2CRefreshedTokens,
			AxiosResponse<IAzureB2CRefreshedTokens>
		>(this.host + "/oauth2/v2.0/token", undefined, {
			params: { ...requestParams },
		});
	};
}
