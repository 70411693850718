import React, { CSSProperties } from "react";

import { CaptainSilverTiger, Market } from "App/../assets";

import { DIC, EDIC_KEY } from "../../../dictionary";
import { getDicSpecies } from "../../../dictionary/dictionary-key-getter";
import { apiGrowingArea } from "../../../redux/growing-area/growing-area.api";
import ImgBannerCard from "../../base-components/card/img-banner-card/ImgBannerCard";
import TitledTag from "../../base-components/tag/titled-tag/TitledTag";
import GrowingAreaTagList from "../../growing-area/tag-list/GrowingAreaTagList";
import { IMarketSegmentCardProps } from "./MarketSegmentCard";

import "./MarketSegmentBanner.scss";

export interface IMarketSegmentBannerProps extends IMarketSegmentCardProps {
	style?: CSSProperties;
	className?: string;
}

export default function MarketSegmentBanner(props: IMarketSegmentBannerProps) {
	const { data: growingAreas } = apiGrowingArea.useGetGrowingAreaByIdsQuery(
		props.marketSegment.growingAreas,
	);

	return (
		<ImgBannerCard
			onClick={props.onClick}
			className={props.className}
			disabledClick={props.disabledClick}
			style={{ ...props.style }}
			isSelected={props.isSelected}
			sideBanner={{
				imageSrc: CaptainSilverTiger,
				logoSrc: Market,
			}}
		>
			<div className="MarketSegmentBanner-container full-parent-size flex-column">
				<div className="MarketSegmentBanner-title-species">
					<h1 className="MarketSegmentBanner-title">
						{props.marketSegment.name}
					</h1>
					<TitledTag
						title={DIC(EDIC_KEY.SPECIES)}
						tagValue={getDicSpecies(props.marketSegment.species)}
					/>
				</div>
				<h4 className="TitledTag-title">
					{`${DIC(EDIC_KEY.GROWING_AREA)} (${
						props.marketSegment.growingAreas.length
					})`}
				</h4>
				<div className="MarketSegmentBanner-tags">
					{growingAreas && (
						<GrowingAreaTagList growingAreas={growingAreas} />
					)}
				</div>
			</div>
		</ImgBannerCard>
	);
}
