import React, { useEffect, useState } from "react";

import { ETagType, ITag } from "shared-type";

import { DIC } from "App/dictionary";
import { apiTag } from "App/redux/attachment/tag.api";
import ChipCombobox from "../../base-components/combobox/chip-combobox/ChipCombobox";

import "./PictureFilterDrawer.scss";

export interface IPictureFilterTags {
	label: string;
	onTagChange: (tags: ITag[]) => void;
	tagType: ETagType;
}
export default function PictureFilterTag(props: IPictureFilterTags) {
	const [selectedTags, setSelectedTags] = useState<ITag[]>([]);
	const [availableTags, setAvailableTags] = useState<ITag[]>([]);

	const { data: tags } = apiTag.useGetsQuery({
		filter: { type: props.tagType },
	});

	useEffect(() => {
		if (tags) {
			setAvailableTags(tags);
		}
	}, [tags]);

	return (
		<ChipCombobox<string>
			label={DIC(props.label)}
			value={selectedTags.map((tag) => tag.value)}
			onChange={(value) => {
				const tags = availableTags.filter((tag) =>
					value.includes(tag.value),
				);

				setSelectedTags(tags);
				props.onTagChange(tags);
			}}
			translateMethod={(value) => value}
			selectAllBtn
			comboboxValues={availableTags.map((tag) => tag.value)}
			className={"PictureFilterDrawer-list-item"}
		/>
	);
}
