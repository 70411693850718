import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm, UseFormReturn } from "react-hook-form";

import {
	EExperimentProgram,
	EExperimentProject,
	EYesNo,
	ESpecies,
	IMarketSegment,
	ECurrency,
} from "shared-type";

import { FormMode } from "../../../../const";
import { DIC, EDIC_KEY } from "../../../../dictionary";
import {
	getDicCurrency,
	getDicExpProgram,
	getDicExpProject,
	getDicSpecies,
	getDicYEsNo,
} from "../../../../dictionary/dictionary-key-getter";
import DorianeButton from "../../../base-components/button/DorianeButton";
import { EFormInputType } from "../../../base-components/dynamic-form";
import DynamicForm from "../../../base-components/dynamic-form/DynamicForm";
import FormSection from "../../../base-components/dynamic-form/form-section/FormSection";
import FormSectionTitle from "../../../base-components/dynamic-form/form-section/FormSectionTitle";
import { IExpGeneralInfo } from "../../../experiment-wizard/common/types";
import MarketSegmentCard from "../../../market-segment/card/MarketSegmentCard";
import { expGeneralValidator, ExpGeneralSchemaType } from "./validator";

import "./ExpGeneralForm.scss";

interface ExpGeneralFormProps {
	type: "MET" | "SET";
	mode: FormMode;
	form: UseFormReturn<ExpGeneralSchemaType>;
	marketSegments?: IMarketSegment[];
	onSelectSegmentClick: () => void;
}
export function useExpGeneralForm(props: {
	defaultValue?: Partial<IExpGeneralInfo>;
	values?: IExpGeneralInfo;
	checkErrorOnInit?: boolean;
}) {
	const form = useForm<ExpGeneralSchemaType>({
		defaultValues: props.defaultValue,
		values: props.values,
		resolver: yupResolver<ExpGeneralSchemaType>(expGeneralValidator),
		mode: "onChange",
		reValidateMode: "onChange",
	});

	useEffect(() => {
		if (props.checkErrorOnInit) {
			form.trigger(); // trigger all errors
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { form };
}

export function ExpGeneralForm(props: ExpGeneralFormProps) {
	const control = props.form.control;
	return (
		<div>
			<FormSection title={DIC(EDIC_KEY.GENERAL_INFORMATION)}>
				<DynamicForm
					control={control}
					formInfos={[
						{
							inputType: EFormInputType.STRING,
							id: "name",
							label: DIC(EDIC_KEY.NAME),
							takeAllRow: true,
							required: true,
						},
						{
							inputType: EFormInputType.STRING,
							id: "label",
							takeAllRow: true,
							label: DIC(EDIC_KEY.LABEL),
						},
						{
							inputType: EFormInputType.STRING,
							id: "objective",
							label: DIC(EDIC_KEY.OBJECTIVE),
							multiline: true,
							takeAllRow: true,
						},
						{
							inputType: EFormInputType.STRING,
							id: "description",
							label: DIC(EDIC_KEY.DESCRIPTION),
							multiline: true,
							takeAllRow: true,
						},
					]}
				/>
			</FormSection>
			<FormSection title={DIC(EDIC_KEY.PERIOD)}>
				<DynamicForm
					control={control}
					formInfos={[
						{
							inputType: EFormInputType.DATE,
							id: "startDate",
							label: DIC(EDIC_KEY.FROM),
							required: true,
						},
						{
							inputType: EFormInputType.DATE,
							id: "endDate",
							label: DIC(EDIC_KEY.TO),
							required: false,
							minDate: props.form.getValues("startDate"),
						},
					]}
				/>
			</FormSection>
			<FormSection title={DIC(EDIC_KEY.SPECIES)}>
				<DynamicForm
					control={control}
					formInfos={[
						{
							inputType: EFormInputType.COMBOBOX,
							id: "species",
							label: DIC(EDIC_KEY.SPECIES),
							comboboxValues: Object.values(ESpecies),
							translateMethod: getDicSpecies,
							required: true,
							disabled: props.mode !== "creation",
						},
					]}
				/>
			</FormSection>
			<FormSection title={DIC(EDIC_KEY.AFFILIATION)}>
				<DynamicForm
					control={control}
					formInfos={[
						{
							inputType: EFormInputType.COMBOBOX,
							id: "program",
							label: DIC(EDIC_KEY.PROGRAM),
							comboboxValues: Object.values(EExperimentProgram),
							translateMethod: getDicExpProgram,
							disabled: props.mode === "used",
						},
						{
							inputType: EFormInputType.COMBOBOX,
							id: "project",
							label: DIC(EDIC_KEY.PROJECT),
							comboboxValues: Object.values(EExperimentProject),
							translateMethod: getDicExpProject,
							disabled: props.mode === "used",
						},
					]}
				/>
			</FormSection>

			<FormSection>
				<div className="ExpGeneralForm-title-container">
					<FormSectionTitle title={DIC(EDIC_KEY.MARKET_SEGMENTS)} />
					<DorianeButton
						dorianeStyle="tertiary"
						onClick={() => props.onSelectSegmentClick()}
						disabled={props.mode === "used"}
					>
						{DIC(EDIC_KEY.EDIT_SELECTION)}
					</DorianeButton>
				</div>

				<div className="ExpGeneralForm-market-segment-list flex-column">
					{props.marketSegments &&
						props.marketSegments.map((segment) => (
							<MarketSegmentCard
								marketSegment={segment}
								key={segment._id}
								disabledClick
							/>
						))}
				</div>
			</FormSection>

			{props.type === "SET" && (
				<>
					<FormSection title={DIC(EDIC_KEY.TRIAL_PARAMETERS)}>
						<DynamicForm
							control={control}
							formInfos={[
								{
									inputType: EFormInputType.NUMBER,
									id: "surface",
									label:
										DIC(EDIC_KEY.SURFACE) +
										" " +
										DIC(EDIC_KEY.IN_M2),
								},
								{
									inputType: EFormInputType.NUMBER,
									id: "rowsNumber",
									label: DIC(EDIC_KEY.ROWS_NUMBER),
								},
								{
									inputType: EFormInputType.NUMBER,
									id: "rowSpacing",
									label: DIC(EDIC_KEY.ROW_SPACING),
								},
								{
									inputType: EFormInputType.NUMBER,
									id: "plantsNumber",
									label: DIC(EDIC_KEY.PLANTS_NUMBER),
								},
								{
									inputType: EFormInputType.NUMBER,
									id: "density",
									label:
										DIC(EDIC_KEY.DENSITY) +
										" " +
										DIC(EDIC_KEY.NUMBER_SEEDS_M2),
								},
								{
									inputType: EFormInputType.STRING,
									id: "cultivationMethod",
									label: DIC(EDIC_KEY.CULTIVATION_METHOD),
								},
							]}
						/>
					</FormSection>

					<FormSection title={DIC(EDIC_KEY.LOCATION)}>
						<DynamicForm
							control={control}
							formInfos={[
								{
									inputType: EFormInputType.NUMBER,
									id: "latitude",
									label: DIC(EDIC_KEY.LATITUDE),
								},
								{
									inputType: EFormInputType.NUMBER,
									id: "longitude",
									label: DIC(EDIC_KEY.LONGITUDE),
								},
							]}
						/>
					</FormSection>
					<FormSection title={DIC(EDIC_KEY.OTHER_INFORMATION)}>
						<DynamicForm
							control={control}
							formInfos={[
								{
									inputType: EFormInputType.NUMBER,
									id: "trialTotalCosts",
									label: DIC(EDIC_KEY.TRIAL_TOTAL_COSTS),
								},
								{
									inputType: EFormInputType.COMBOBOX,
									id: "currency",
									label: DIC(EDIC_KEY.CURRENCY),
									comboboxValues: Object.values(ECurrency),
									translateMethod: getDicCurrency,
								},
								{
									inputType: EFormInputType.COMBOBOX,
									id: "contract",
									label: DIC(EDIC_KEY.CONTRACT),
									comboboxValues: Object.values(EYesNo),
									translateMethod: getDicYEsNo,
								},
							]}
						/>
					</FormSection>
				</>
			)}
		</div>
	);
}
