import { createApi } from "@reduxjs/toolkit/query/react";
import { IFilter, ISort, ITag } from "shared-type";

import { useRequest } from "../../request-provider";
import { defaultApiError } from "../utils/errors";

export const apiTag = createApi({
	reducerPath: "api-tag",
	baseQuery: () => ({ data: undefined }),

	// global cache configuration for the api (in second)
	keepUnusedDataFor: 30,
	refetchOnMountOrArgChange: 30,

	// update tag
	tagTypes: ["Update", "Delete", "Create"],

	// api definition
	endpoints: (build) => ({
		gets: build.query<
			ITag[],
			{ page?: number; pageSize?: number; filter?: IFilter; sort?: ISort }
		>({
			queryFn: async ({ page, pageSize, filter, sort }) => {
				try {
					const result = await useRequest().tag.getTags(
						page,
						pageSize,
						filter,
						sort,
					);
					return {
						data: result.data.data,
					};
				} catch (err) {
					return defaultApiError(err, "Unable to fetch tags");
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),
	}),
});
