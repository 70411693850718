import { createTheme } from "@mui/material";
import styles from "sassTheme";

export const theme = createTheme({
	typography: {
		fontFamily: "DidactGothic-Regular",
	},
	palette: {
		primary: {
			main: styles["primary-color-dark-1"],
		},
		secondary: {
			main: styles["secondary-color"],
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1600,
			xl: 1800,
		},
	},
});
