import * as React from "react";

import ModalTitle from "./ModalTitle";

import { concatClassName } from "common";

import "./DorianeModal.scss";

interface IPopUpProps {
	children?: React.ReactNode;
	onClose: () => void;
	title?: string;
	className?: string;
	width?: string;
	height?: string;
	closeOnOutsideClick?: boolean;
}

export default function DorianeModal(props: IPopUpProps) {
	return (
		<div className="DorianeModal-container">
			<div
				className={concatClassName(
					"DorianeModal",
					"flex-column",
					props.className,
				)}
				style={{
					width: props.width ?? "fit-content",
					height: props.height ?? "fit-content",
				}}
			>
				{props.title && (
					<ModalTitle onClose={props.onClose} title={props.title} />
				)}
				{props.children}
			</div>
			<div
				className={
					"DorianeModal-background full-parent-size" +
					(props.closeOnOutsideClick
						? " DorianeModal-background-clickable"
						: "")
				}
				onClick={() => {
					if (props.closeOnOutsideClick) {
						props.onClose();
					}
				}}
			></div>
		</div>
	);
}
