import React from "react";
import { UseFormReturn } from "react-hook-form";

import {
	EMultiComputeMethod,
	EMultiExpectationType,
	IVarMultiNotation,
} from "shared-type";

import { FormMode } from "../../../../const";
import { DIC, EDIC_KEY } from "../../../../dictionary";
import {
	getDicVarMultiComputeMethod,
	getDicVarMultiExpectation,
} from "../../../../dictionary/dictionary-key-getter";
import { ICustomVariableForm } from "../../../form/custom-variable";
import { EditingVarMultiNotation } from "./editing-variable-multi-notation";
import { Input } from "stories/base-components/Input/Input";
import DynamicFormV2 from "App/components/base-components/dynamic-form/DynamicFormV2";
import { EFormInputType } from "App/components/base-components/dynamic-form";
import FlexBox from "App/components/base-components/placement/flex-box/FlexBox";

interface MultiNotationFormProps {
	mode: FormMode;
	form: UseFormReturn<ICustomVariableForm>;
	setMultiNotation: (key: keyof IVarMultiNotation, value: any) => void;
	multiNotation: EditingVarMultiNotation;
}

export default function MultiNotationForm(props: MultiNotationFormProps) {
	const isVarUsed = props.mode === "used";

	return (
		<div className="CustomVariableForm-block flex-column">
			<div className="CustomVariableForm-double-input">
				{isVarUsed ? (
					<Input
						disabled
						label={DIC(EDIC_KEY.CALCULATION)}
						value={getDicVarMultiComputeMethod(
							props.multiNotation.computeMethod,
						)}
					/>
				) : (
					<DynamicFormV2
						control={props.form.control}
						formInfos={[
							{
								inputType: EFormInputType.COMBOBOX,
								id: "expectationType",
								label: DIC(EDIC_KEY.CALCULATION),
								comboboxValues:
									Object.values(EMultiComputeMethod),
								translateMethod: getDicVarMultiComputeMethod,
								required: true,
								value: props.multiNotation.computeMethod,
								onChange: (method: string) =>
									props.setMultiNotation(
										"computeMethod",
										method,
									),
							},
						]}
					/>
				)}
			</div>
			<FlexBox>
				{isVarUsed ? (
					<Input
						disabled
						label={DIC(EDIC_KEY.CONSTRAINT)}
						value={getDicVarMultiExpectation(
							props.multiNotation.expectationType,
						)}
					/>
				) : (
					<DynamicFormV2
						control={props.form.control}
						formInfos={[
							{
								inputType: EFormInputType.COMBOBOX,
								id: "expectationType",
								label: DIC(EDIC_KEY.CONSTRAINT),
								comboboxValues: Object.values(
									EMultiExpectationType,
								),
								translateMethod: getDicVarMultiExpectation,
								required: true,
								value: props.multiNotation.expectationType,
								onChange: (type: string) =>
									props.setMultiNotation(
										"expectationType",
										type,
									),
							},
						]}
					/>
				)}
				{props.multiNotation.expectationType !==
					EMultiExpectationType.FREE && (
					<DynamicFormV2
						control={props.form.control}
						formInfos={[
							{
								inputType: EFormInputType.NUMBER,
								id: "expectedNotationNb",
								label: DIC(EDIC_KEY.EXPECTED_NOTATIONS),
								disabled: isVarUsed,
								value:
									props.multiNotation.expectedNotationNb ??
									"1",
								onChange: (event) =>
									props.setMultiNotation(
										"expectedNotationNb",
										event?.target.value,
									),
							},
						]}
						noGap
					/>
				)}
			</FlexBox>
		</div>
	);
}
