import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";
import * as Mathjs from "mathjs";
import {
	EEntitiesLength,
	EMultiComputeMethod,
	PageInformation,
} from "shared-type";

/**
 * Keep only fields given in arguments in the object obj.
 * @param obj the object to filter
 * @param fields the field to keep
 * @returns the stripped object
 */
export function whiteListField<T extends object, R>(
	obj: T,
	fields: Set<keyof T>
): R {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result = {} as any;
	for (const field of fields) {
		if (obj[field] !== undefined) {
			result[field] = obj[field];
		}
	}
	return result;
}

export function groupByKey<T extends Record<string, any>>(
	items: T[],
	keyName: keyof T
): Record<string, any[]> {
	const keyValueSet: Record<string, any[]> = {};

	for (const item of items) {
		const key = item[keyName];

		if (!keyValueSet[key]) {
			keyValueSet[key] = [];
		}
		keyValueSet[key].push(item._id);
	}

	return keyValueSet;
}

export function getValueFromFlattenedKey<T>(
	key: string,
	objectToValidate: T
): any | undefined {
	// I have an array of variables in the format : 'key.attr.attrOfAttr.un'
	// corresponding to the object 	{'key': {'attr': {'attrOfAttr' : {'un' : 1} } } }

	let obj: any = objectToValidate;
	const fields = key.split(".");

	for (const key of fields) {
		// If the key does not exist in the document, we return undefined
		if (obj[key] === undefined) {
			return undefined;
		}

		obj = obj[key];
	}

	return obj;
}

export function findDuplicatesInList<T>(list: T[], returnSet = false): T[] {
	const duplicates: T[] = [];
	const seenItems: T[] = [];

	for (const item of list) {
		const isDuplicate = seenItems.some((seenItem) => _.isEqual(seenItem, item));
		if (isDuplicate) {
			duplicates.push(_.cloneDeep(item));
		} else {
			seenItems.push(_.cloneDeep(item));
		}
	}
	if (returnSet) {
		return removeDuplicates(duplicates);
	}
	return duplicates;
}

/**
 * Add two number that can be undefined,
 * if the two numbers are undefined, return undefined
 */
export function addUndefinedNumber(
	a: number | undefined,
	b: number | undefined
): number | undefined {
	if (a === undefined && b === undefined) {
		return undefined;
	} else if (a === undefined) {
		return b;
	} else if (b === undefined) {
		return a;
	}
	return a + b;
}

export function removeDuplicates<T>(list: T[]): T[] {
	return _.uniqWith(list, _.isEqual);
}

export function formatString(str: string, ...values: string[]) {
	let resString = str;
	for (let index = 0; index < values.length; index++) {
		resString = resString.replace(`{${index}}`, values[index]);
	}
	return resString;
}

export function concatClassName(...classNames: (string | undefined)[]) {
	return classNames.filter((elt) => elt).join(" ");
}

export function dateFormat(date?: Date, time?: boolean) {
	if (!date) {
		return "-";
	}
	if (time) {
		return dayjs(date).format("MM/DD/YYYY - HH:mm");
	}
	return dayjs(date).format("MM/DD/YYYY");
}

export function dateComparison(
	a?: string | number | Date | Dayjs,
	b?: string | number | Date | Dayjs
): number {
	// we need to exclude all undefined case because new Date(undef) === Date.now()
	if (a === undefined && b === undefined) {
		return 0;
	}
	if (a === undefined) {
		return -1;
	}
	if (b === undefined) {
		return 1;
	}
	const dateA = dayjs(a);
	const dateB = dayjs(b);
	return dateA.diff(dateB);
}

/**
 * compute the nb of pages need to display elements
 * @param totalElements Total elements to display
 * @param nbElementByPage nb elements display by page
 */
export function computeTotalPagesNb(
	paginationInfo: PageInformation | undefined
) {
	if (paginationInfo === undefined) return 0;
	return Math.ceil(paginationInfo.total / paginationInfo.pageSize);
}

/**
 * Create a pagination from a list and pagination parameters
 * @param elements the list of elements
 * @param pageNumber the current page number
 * @param pageSize the size of a page
 * @returns the current pagination for the given page and the total of pages
 */
export function paginateList<T>(
	elements: T[],
	pageNumber: number,
	pageSize: number
): { paginatedList: T[]; totalPages: number } {
	const totalElements = elements.length;
	const totalPages = Math.ceil(totalElements / pageSize);

	const startIndex = pageNumber * pageSize;
	const endIndex = startIndex + pageSize;

	const paginatedList = elements.slice(startIndex, endIndex);

	return {
		paginatedList,
		totalPages,
	};
}

export function areObjectsEqual(obj1: any, obj2: any): boolean {
	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		if (obj1[key] !== obj2[key]) {
			return false;
		}
	}

	return true;
}

/**
 * Return the variables in the first list (variablesList) that
 * are not present in the second list (variablesToRemove)
 */
export function getElementsNotInList<T>(
	elementList: T[],
	elementsToRemove: T[]
): T[] {
	return elementList.filter((variable1) => {
		// Check if the variable is not present in variablesList2
		return !elementsToRemove.some((variable2) =>
			areObjectsEqual(variable1, variable2)
		);
	});
}

export function undefinedOrStringEmpty(
	value: string | undefined,
	detectEmptyWhiteSpace = false
): boolean {
	return (
		value === undefined ||
		(detectEmptyWhiteSpace ? value.trim() === "" : value === "")
	);
}

/**
 * Return a random int value with min and max included
 */
export function getRandomInt(min: number, max: number) {
	return Math.floor(Math.random() * (max - min + 1) + min);
}

export function getRandomSubList<T>(list: T[], subListLength: number): T[] {
	if (list.length === 0 || subListLength <= 0) {
		return [];
	}
	if (subListLength >= list.length) {
		return [...list]; // Return a copy of the original list
	}
	const shuffledList = _.shuffle(list);
	return shuffledList.slice(0, subListLength);
}

export function calculatePercentage(value: number, total: number): number {
	if (total === 0) {
		return 0; // Avoid division by zero
	}

	return Math.round((value / total) * 100);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeUndefinedProperties(obj: any) {
	for (const key in obj) {
		if (obj[key] === undefined) {
			delete obj[key];
		}
	}
	return obj;
}

export function areNumbersEqual(
	a: number,
	b: number,
	epsilon: number = Number.EPSILON
): boolean {
	// Use Number.EPSILON as a default tolerance (a very small value)
	return Math.abs(a - b) < epsilon;
}

export function computeAggregationMethod(
	method: EMultiComputeMethod,
	values: number[]
): number {
	if (values.length === 0) {
		throw new Error("Cannot perform aggregation on an empty array");
	}

	switch (method) {
		case EMultiComputeMethod.AVERAGE: {
			return Mathjs.mean(...values);
		}
		case EMultiComputeMethod.COUNT: {
			return values.length;
		}
		case EMultiComputeMethod.MAX: {
			return Mathjs.max(...values);
		}
		case EMultiComputeMethod.MEDIAN: {
			return Mathjs.median(...values);
		}
		case EMultiComputeMethod.MIN: {
			return Mathjs.min(...values);
		}
		case EMultiComputeMethod.STANDARD_DEV: {
			return Mathjs.std(...values);
		}
		case EMultiComputeMethod.SUM: {
			return Mathjs.sum(...values);
		}
		default:
			throw new Error(`${method} is not an acceptable aggregation method`);
	}
}

/**
 * This method convert a string into identifier format.
 * It replace all non alphanumeric characters, "\_" and "-" by "\_".
 * And slice the string to be under the length of an identifier.
 * @param inputString : to string to be converted
 * @returns the converted string into the identifier format
 */
export function convertToIdentifierString(inputString: string) {
	// Use a regular expression to replace non-alphanumeric, "_" and "-" characters with "_"
	let convertedInput = inputString.replace(/[^a-zA-Z0-9_-]/g, "_");
	convertedInput = convertedInput.slice(0, EEntitiesLength.identifier); // Need to be under max length characters
	return convertedInput;
}

export function generateUniqueIDWithLettersAndNumbersOnly(length: number) {
	let result = "";
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

/**
 * This method is used to handle unicity conflict by adding tag "_N" to the unique fields.
 * @param entity : The entity to be handled
 * @param fieldInfos : The fields checked for the unicity and some generation information
 * @param checkUnicityMethod : The method used to check the unicity
 * @param baseIndex : The starting index of the conflict tag
 * @returns A unique entity created from the given entity in arguments.
 */
export async function handleUnicityConflict<T>(
	entity: T,
	fieldInfos: {
		baseValue: string;
		fieldName: keyof T;
		fieldSize: number;
	}[],
	checkUnicityMethod: (entity: T) => Promise<void>,
	isSelfConflict: boolean,
	baseIndex = 1
): Promise<T> {
	// Avoid side effect
	const clonedEntity = _.cloneDeep(entity);

	// Init unique fields
	for (const fieldData of fieldInfos) {
		if (isSelfConflict) {
			const neededSize = fieldData.fieldSize - 2; // Need "_1" length
			// Add conflict tag
			clonedEntity[fieldData.fieldName] = `${fieldData.baseValue.slice(
				0,
				neededSize
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			)}_1` as any;
		} else {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			clonedEntity[fieldData.fieldName] = fieldData.baseValue as any;
		}
	}

	// Check if unique
	let isUnique = false;
	let i = baseIndex;
	// Add a check limit to avoid infinite loop
	while (!isUnique && i <= 100) {
		try {
			await checkUnicityMethod(clonedEntity);
			isUnique = true; // No error we are unique
		} catch (error) {
			// If the entity is still not unique
			isUnique = false;

			for (const fieldData of fieldInfos) {
				const neededSize = fieldData.fieldSize - (1 + i.toString().length); // Need "_" + i length
				// Add conflict tag
				clonedEntity[fieldData.fieldName] = `${fieldData.baseValue.slice(
					0,
					neededSize
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				)}_${i}` as any;
			}
			i++;
		}
	}
	if (i > 100)
		throw Error("Error in handling unicity conflict, max iterations reached");

	return clonedEntity;
}

export function capitalize(sentence: string) {
	if (sentence && sentence.length > 0) {
		return sentence.charAt(0).toUpperCase() + sentence.slice(1);
	}
	return "";
}
