import React, { CSSProperties } from "react";

import { GrowingArea, Landscape } from "../../../../assets";

import { DIC, EDIC_KEY } from "../../../dictionary";
import {
	getDicClimate,
	getDicSeason,
	getDicSoilType,
} from "../../../dictionary/dictionary-key-getter";
import ImgBannerCard from "../../base-components/card/img-banner-card/ImgBannerCard";
import TitledTag from "../../base-components/tag/titled-tag/TitledTag";
import { IGrowingAreaCardProps } from "./GrowingAreaCard";
import DeleteButton from "../../base-components/button/DeleteButton";

import "./GrowingAreaBanner.scss";

export interface IGrowingAreaBannerProps extends IGrowingAreaCardProps {
	style?: CSSProperties;
	className?: string;
}

export default function GrowingAreaBanner(props: IGrowingAreaBannerProps) {
	const stringifyMultiValue = (value: string[] | undefined) => {
		if (value === undefined || value.length === 0) {
			return "_";
		}
		return value.join(", ");
	};

	return (
		<ImgBannerCard
			onClick={props.onClick}
			disabledClick={props.disabledClick}
			className={props.className}
			style={{ ...props.style }}
			sideBanner={{ imageSrc: GrowingArea, logoSrc: Landscape }}
		>
			<div className="GrowingAreaBanner-container flex-column">
				<div className="GrowingAreaBanner-top">
					<div className="GrowingAreaBanner-tags">
						<TitledTag
							title={DIC(EDIC_KEY.SEASON)}
							tagValue={getDicSeason(props.growingArea.season)}
						/>
						<TitledTag
							title={DIC(EDIC_KEY.YEAR)}
							tagValue={props.growingArea.year?.toString() || "_"}
						/>
					</div>
					{props.onDelete && (
						<DeleteButton
							icon="cross"
							className="GrowingAreaBanner-delete"
							onDelete={() => props.onDelete!()}
						/>
					)}
				</div>

				<div className="GrowingAreaBanner-text">
					<h1 className="clamped-text">{props.growingArea.name}</h1>
					<h2 className="clamped-text">
						{props.growingArea.description}
					</h2>
				</div>

				<div className="GrowingAreaBanner-tags">
					<TitledTag
						clampTagValue
						title={DIC(EDIC_KEY.CLIMATE)}
						tagValue={stringifyMultiValue(
							props.growingArea.climate?.map((climate) =>
								getDicClimate(climate),
							),
						)}
					/>
					<TitledTag
						clampTagValue
						title={DIC(EDIC_KEY.SOIL)}
						tagValue={stringifyMultiValue(
							props.growingArea.soilType?.map((soilType) =>
								getDicSoilType(soilType),
							),
						)}
					/>
				</div>
			</div>
		</ImgBannerCard>
	);
}
