import {
	ESubjectType,
	ITrial,
	ILot,
	IGenotype,
	EGermplasmLevel,
	ETrialLevel,
	ENotebookStatus,
	EReviewStatus,
} from "shared-type";

export function extractSubjectGridInformation(
	subject: {
		subjectId: string;
		subjectType: ESubjectType;
	},
	trial: ITrial,
	lotsMap: Map<string, ILot>,
	genotypesMap: Map<string, IGenotype>,
): {
	subjectName?: string;
	subjectValue?: ITrial | ILot | IGenotype;
	subjectRef?: {
		subjectId: string;
		subjectType: ESubjectType;
	};
} {
	switch (subject.subjectType) {
		case ESubjectType.TRIAL:
			return {
				subjectName: trial.name,
				subjectRef: subject,
				subjectValue: trial,
			};
		case ESubjectType.MATERIAL_GENOTYPE: {
			const genotype = genotypesMap.get(subject.subjectId);
			return {
				subjectName: genotype?.name,
				subjectRef: subject,
				subjectValue: genotype,
			};
		}
		case ESubjectType.MATERIAL_LOT: {
			const lot = lotsMap.get(subject.subjectId);
			return {
				subjectName: lot?.lotName,
				subjectRef: subject,
				subjectValue: lot,
			};
		}
		default:
			return { subjectName: "NOT IMPLEMENTED", subjectRef: subject };
	}
}
export function EGermplasmLevelToESubjectType(level: EGermplasmLevel) {
	switch (level) {
		case EGermplasmLevel.GENOTYPE:
			return ESubjectType.MATERIAL_GENOTYPE;
		case EGermplasmLevel.LOT:
			return ESubjectType.MATERIAL_LOT;
		default:
			console.error(
				`cannot transform EGermplasmLevel (value:${level}) to ESubjectType`,
			);
			return ESubjectType.MATERIAL_GENOTYPE;
	}
}
export function ETrialLevelToESubjectType(level: ETrialLevel): ESubjectType[] {
	switch (level) {
		case ETrialLevel.MATERIAL:
			return [ESubjectType.MATERIAL_GENOTYPE, ESubjectType.MATERIAL_LOT];
		case ETrialLevel.TRIAL:
			return [ESubjectType.TRIAL];
		default:
			throw new Error(
				`cannot transform ETrialLevel (value:${level}) to ESubjectType`,
			);
	}
}
export function extractSubjectOfTrial(
	trial: ITrial,
): { subjectType: ESubjectType; subjectId: string }[] {
	const subject: { subjectType: ESubjectType; subjectId: string }[] = [];
	subject.push({
		subjectId: trial._id,
		subjectType: ESubjectType.TRIAL,
	});
	if (!trial.materialLevel) {
		return subject;
	}
	for (const material of trial.materialLevel.materials || []) {
		subject.push({
			subjectId: material.material._id,
			subjectType: EGermplasmLevelToESubjectType(
				trial.materialLevel.germplasmLevel,
			),
		});
	}
	return subject;
}

export const reviewStatusToString = [
	{
		status: EReviewStatus.NA,
		string: "N/A",
		bgColor: "#fff2cc",
		fontColor: "#808080",
	},
	{
		status: EReviewStatus.REVIEWED,
		string: "Valid",
		bgColor: "#e2efda",
		fontColor: "#00b069",
	},
	{
		status: EReviewStatus.EXCLUDED,
		string: "Outlier",
		bgColor: "#fce4d6",
		fontColor: "#ff0000",
	},
];

export const notebookStatusToString = [
	{
		status: ENotebookStatus.IN_PROGRESS,
		string: "In progress",
		bgColor: "#fff2cc",
		fontColor: "#808080",
	},
	{
		status: ENotebookStatus.COMPLETED,
		string: "Completed",
		bgColor: "#e2efda",
		fontColor: "#00b069",
	},
	{
		status: ENotebookStatus.READY_TO_OBSERVE,
		string: "Ready to observe",
		bgColor: "#fff2cc",
		fontColor: "#808080",
	},
	{
		status: ENotebookStatus.DISABLED,
		string: "Disabled",
		bgColor: "#808080",
		fontColor: "#000",
	},
	{
		status: ENotebookStatus.REVIEWED,
		string: "Reviewed",
		bgColor: "#e2efda",
		fontColor: "#00b069",
	},
];
export function getNotebookCellStatusInfo(status?: ENotebookStatus) {
	return (
		notebookStatusToString.find((elt) => elt.status === status) || {
			status: ENotebookStatus.DISABLED,
			string: "Unknown",
			bgColor: "#808080",
			fontColor: "#000",
		}
	);
}
