import * as Yup from "yup";

import { colorRegex, emailRegex } from "common";
import { EEntitiesLength, EUserRole } from "shared-type";

export const userValidator = Yup.object().shape({
	firstName: Yup.string().required()
.min(1)
.max(EEntitiesLength.firstName),
	lastName: Yup.string().required()
.min(1)
.max(EEntitiesLength.lastName),
	mail: Yup.string().required()
.matches(emailRegex),
	role: Yup.string().required()
.oneOf(Object.values(EUserRole)),
	color: Yup.string().required()
.matches(colorRegex),
});

// Type of the validate schema
export type UserSchemaType = Yup.InferType<typeof userValidator>;
