import React, { useState } from "react";

import { formatString } from "common";

import { DIC, EDIC_KEY } from "../../../../../dictionary";
import { Modal } from "stories/base-components/Modal/Modal";
import { EPaddingSize } from "../../../../base-components/placement/box/Box";
import FlexBox, {
	EGapSize,
} from "../../../../base-components/placement/flex-box/FlexBox";
import { Checkbox } from "stories/base-components/Checkbox/Checkbox";
import { Typography } from "stories/base-components/Typography/Typography";

import "./ExperimentDeleteModal.scss";

interface ExperimentDeleteModalProps {
	open: boolean;
	isMET: boolean;
	isDraft: boolean;
	onClose: () => void;
	deleteAction: (deleteTrials: boolean) => void;
}

export default function ExperimentDeleteModal(
	props: ExperimentDeleteModalProps,
) {
	const [deleteLinkedTrials, setDeleteLinkedTrials] = useState<boolean>(true);

	return (
		<Modal
			open={props.open}
			handleClose={props.onClose}
			title={formatString(
				DIC(EDIC_KEY.DELETE_ENTITY),
				props.isMET ? DIC(EDIC_KEY.MET) : DIC(EDIC_KEY.TRIAL),
			)}
			validateBtn={{
				type: "error",
				label: DIC(EDIC_KEY.DELETE_FOREVER),
				action: () => {
					props.deleteAction(deleteLinkedTrials);
					props.onClose();
				},
			}}
		>
			<FlexBox
				flexDirection={"column"}
				takeRemainingSpace
				gap={EGapSize.SMALL}
			>
				<FlexBox
					padding={EPaddingSize.SMALL}
					flexDirection={"column"}
					gap={EGapSize.MEDIUM}
				>
					<Typography text={DIC(EDIC_KEY.DELETION_MESSAGE_EXP)} />
					{props.isMET && !props.isDraft && (
						<Checkbox
							checked={deleteLinkedTrials}
							onChange={(event, checked) =>
								setDeleteLinkedTrials(checked)
							}
							label={DIC(EDIC_KEY.DELETE_MET_LINKED_TRIALS)}
						/>
					)}
				</FlexBox>
			</FlexBox>
		</Modal>
	);
}
