import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { IGrowingArea } from "shared-type";

import { DIC, EDIC_KEY } from "../../../../../../dictionary";
import { useAppSelector } from "../../../../../../hooks/reduxHook";
import {
	IDorianeFilter,
	IDorianeObjectFilter,
} from "../../../../../../interfaces/filters/doriane-filter-interface";

import { useGrowingAreaWithUsersAndMarketSegments } from "../../../../../../components/growing-area/hooks/growingAreaHook";
import { GROWING_AREA_DEFAULT_SORT } from "../../../../../../const";
import { selectECW } from "../../../../../../redux/ECW/ECW.selector";
import { ECWAction } from "../../../../../../redux/ECW/ECW.slice";
import { AppDispatch } from "../../../../../../store";
import { CheckBoxColumn } from "../../../../../base-components/data-grid/doriane-data-grid/columns/CheckBoxColumn";
import { growingAreaColumns } from "../../../../../base-components/data-grid/doriane-data-grid/default-grid-columns/GrowingAreaColumns";
import DorianeDataGrid from "../../../../../base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import DorianeDrawer from "../../../../../base-components/modal/DorianeDrawer";
import { IGrowingAreaRow } from "../../../../../experiment/met/edition/trials-edition/modal/interface";

import "./TrialGenerationSelectGA.scss";

export default function TrialGenerationSelectGA() {
	const dispatch = useDispatch<AppDispatch>();

	const [filter, setFilter] = useState<IDorianeObjectFilter>({});

	const GAofSelectedTrial = useAppSelector<Set<string>>(
		selectECW.trialGenerationPart.GAofSelectedTrial,
	);
	const GAOfUnselectedTrial = useAppSelector<Set<string>>(
		selectECW.trialGenerationPart.GAOfUnselectedTrial,
	);
	const trialToSelectGA = useAppSelector(
		selectECW.trialGenerationPart.trialSelectedWithoutGA,
	);
	const trialToUnselectGA = useAppSelector(
		selectECW.trialGenerationPart.trialSelectedWithGA,
	);
	const onCloseModal = useCallback(() => {
		dispatch(ECWAction.trialGenerationSetIsOpenGAModal(false));
	}, [dispatch]);

	const onGrowingAreaSelected = useCallback(
		({
			id,
			name,
			select,
		}: {
			id: string;
			name: string;
			select: boolean;
		}) => {
			if (select) {
				const firstTrialIdWithoutGa = trialToSelectGA[0];
				if (!firstTrialIdWithoutGa) {
					return;
				}
				dispatch(
					ECWAction.trialGenerationOnSelectGA({
						trialId: firstTrialIdWithoutGa,
						gaId: id,
						gaName: name,
					}),
				);
			} else {
				dispatch(
					ECWAction.trialGenerationOnUnselectGA({
						id,
					}),
				);
			}
		},
		[dispatch, trialToSelectGA],
	);

	const onGrowingAreaSelectedMany = useCallback(
		({
			growingAreas,
			selected,
		}: {
			growingAreas: Array<IGrowingAreaRow>;
			selected: boolean;
		}) => {
			if (selected) {
				dispatch(
					ECWAction.trialGenerationOnSelectGAMany({
						trials: trialToSelectGA,
						growingAreas: growingAreas,
					}),
				);
			} else {
				dispatch(
					ECWAction.trialGenerationOnUnselectGAMany({
						trials: trialToUnselectGA,
						growingAreas: growingAreas,
					}),
				);
			}
		},
		[dispatch, trialToSelectGA, trialToUnselectGA],
	);

	const rowIsSelected = useCallback(
		(id: string) =>
			GAofSelectedTrial.has(id) || GAOfUnselectedTrial.has(id),
		[GAofSelectedTrial, GAOfUnselectedTrial],
	);

	const handleFilterChange = (newFilter: IDorianeFilter) => {
		setFilter((old) => {
			return { ...old, [newFilter.key]: newFilter };
		});
	};
	// #endregion

	const { sortedGAsWithInfos: growingAreasList } =
		useGrowingAreaWithUsersAndMarketSegments({
			sort: GROWING_AREA_DEFAULT_SORT,
			filter,
		});

	const isDisabledRow = useCallback(
		(row: IGrowingArea) => {
			if (GAOfUnselectedTrial.has(row._id)) {
				return true;
			}
			if (
				trialToSelectGA.length === 0 &&
				!GAofSelectedTrial.has(row._id)
			) {
				return true;
			}
			return false;
		},
		[GAofSelectedTrial, GAOfUnselectedTrial, trialToSelectGA.length],
	);
	return (
		<DorianeDrawer
			takeRemainingSpace
			onClose={onCloseModal}
			title={DIC(EDIC_KEY.GROWING_AREA)}
		>
			<div className="take-remaining-space">
				<DorianeDataGrid<IGrowingArea>
					className="full-parent-size"
					rowHeight="40px"
					columns={[
						CheckBoxColumn({
							isCheck: (row) => rowIsSelected(row._id),
							onCheck: (row, onCheck) => {
								onGrowingAreaSelected({
									id: row._id,
									name: row.name,
									select: onCheck,
								});
							},
							isDisabled: isDisabledRow,
							headerCheckAllAction: (check) => {
								onGrowingAreaSelectedMany({
									growingAreas: growingAreasList?.filter(
										(GA) =>
											!Array.from(
												GAofSelectedTrial,
											).includes(GA._id),
									),
									selected: check,
								});
							},
						}),
						...growingAreaColumns({
							filter: filter,
							onFilterChange: handleFilterChange,
						}),
					]}
					rows={growingAreasList || []}
					customRowClass={(row) =>
						(isDisabledRow(row)
							? ["TrialGenerationSelectGA-disabled-row"]
							: [])
					}
				/>
			</div>
		</DorianeDrawer>
	);
}
