import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import { lotColumns } from "App/components/base-components/data-grid/doriane-data-grid/default-grid-columns/LotColumns";
import DorianeDataGrid from "App/components/base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import {
	convertDorObjFilterToFilter,
	IDorianeFilter,
	IDorianeObjectFilter,
} from "App/interfaces/filters/doriane-filter-interface";
import PaginationTool from "../../../../components/base-components/pagination/PaginationTool";
import { LOT_DEFAULT_PAGE_SIZE, LOT_DEFAULT_SORT } from "../../../../const";
import { selectGermplasm } from "../../../../redux/germplasm/germplasm.selector";
import { germplasmAction } from "../../../../redux/germplasm/germplasm.slice";
import { AppDispatch } from "../../../../store";
import Size from "../../../../../stories/constants/Size/Size";
import FlexBox from "../../../../components/base-components/placement/flex-box/FlexBox";

export default function LotsDataPage() {
	const [page, setPage] = useState<number>(0);
	const [filter, setFilter] = useState<IDorianeObjectFilter>({});

	const pageInfos = useSelector(selectGermplasm.lotPageInfo);
	const lotList = useSelector(selectGermplasm.lotList);
	const dispatch = useDispatch<AppDispatch>();

	const debounceFetch = useDebouncedCallback(
		(
			// Retrieve request parameter to type debounce
			request: Parameters<typeof germplasmAction.getLotsListThunk>[0],
		) => {
			return dispatch(germplasmAction.getLotsListThunk(request));
		},
		500,
	);

	const handleFilterChange = (newFilter: IDorianeFilter) => {
		setFilter((old) => {
			return { ...old, [newFilter.key]: newFilter };
		});
	};

	const onPageChange = useCallback(
		(newPage: number, refetch = true) => {
			setPage(newPage);
			if (refetch) {
				debounceFetch({
					page: newPage,
					pageSize: LOT_DEFAULT_PAGE_SIZE,
					filter: convertDorObjFilterToFilter(filter),
					sort: LOT_DEFAULT_SORT,
				});
				// No delay on page change
				debounceFetch.flush();
			}
		},
		[debounceFetch, filter],
	);

	useEffect(() => {
		// Delayed when filter change
		debounceFetch({
			page: 0,
			pageSize: LOT_DEFAULT_PAGE_SIZE,
			filter: convertDorObjFilterToFilter(filter),
			sort: LOT_DEFAULT_SORT,
		})?.then(() => {
			// reset page to 0 on filter
			onPageChange(0, false);
		});
	}, [debounceFetch, filter, onPageChange]);

	useEffect(() => {
		// cancel request when unmount
		return () => {
			debounceFetch.cancel();
		};
	}, [debounceFetch]);

	return (
		<FlexBox flexDirection={"column"} fullParentSize padding={Size.sm}>
			<FlexBox takeRemainingSpace>
				<DorianeDataGrid
					rows={lotList || []}
					columns={lotColumns({
						filter,
						onFilterChange: handleFilterChange,
					})}
				/>
			</FlexBox>
			<FlexBox justifyContent={"center"} padding={Size.sm}>
				<PaginationTool
					currentPage={page}
					maxPage={pageInfos?.totalPage || 0}
					onPageRoll={onPageChange}
				/>
			</FlexBox>
		</FlexBox>
	);
}
