import React from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import UserTag from "App/components/base-components/tag/user-tag/UserTag";
import { DIC, EDIC_KEY } from "App/dictionary";
import { getColorFromTrialStatus } from "App/utils";
import { AggroScientist, ErlenmeyerFlask } from "../../../../../../assets";
import { getDicSpecies } from "../../../../../dictionary/dictionary-key-getter";
import { apiUser } from "../../../../../redux/user/user.api";
import ImgBannerCard from "../../../../base-components/card/img-banner-card/ImgBannerCard";
import ColorTag from "../../../../base-components/tag/color-tag/ColorTag";
import TitledTag from "../../../../base-components/tag/titled-tag/TitledTag";
import { ITrialGenericBannerProps } from "../TrialGenericBanner";

import "./TrialBanner.scss";

export default function TrialBanner(props: ITrialGenericBannerProps) {
	const { data: user } = apiUser.useGetUserByIdQuery(
		props.trial.systemMetadata?.createdBy ?? skipToken,
	);

	return (
		<ImgBannerCard
			onClick={props.onClick}
			className={props.className}
			disabledClick={props.disabledClick}
			style={{ ...props.style }}
			sideBanner={{ imageSrc: AggroScientist, logoSrc: ErlenmeyerFlask }}
		>
			<div className="TrialBanner-container flex-column">
				<div className="TrialBanner-header">
					<h1 className="TrialBanner-title clamped-text">
						{props.trial.name}
					</h1>
					{props.trial.status && (
						<ColorTag
							title={DIC(EDIC_KEY.STATUS)}
							tagValue={props.trial.status}
							colorIndicator={getColorFromTrialStatus(
								props.trial.status,
							)}
						/>
					)}
				</div>

				{user && (
					<UserTag title={DIC(EDIC_KEY.CREATED_BY)} user={user} />
				)}
				<h2 className="TrialBanner-description clamped-text">
					{props.trial.description}
				</h2>

				<div className="TrialBanner-tags">
					<TitledTag
						title={DIC(EDIC_KEY.YEAR)}
						tagValue={`${props.trial.year}`}
					/>
					<TitledTag
						title={DIC(EDIC_KEY.SCOPE)}
						tagValue={props.templateExpStructure?.expScope || "_"}
					/>
					<TitledTag
						title={DIC(EDIC_KEY.TYPE)}
						tagValue={props.templateExpStructure?.expType || "_"}
					/>
					<TitledTag
						title={DIC(EDIC_KEY.GROWING_AREA)}
						tagValue={props.trial.growingArea?.name || "_"}
					/>
					{props.trial.species && (
						<TitledTag
							title={DIC(EDIC_KEY.SPECIES)}
							tagValue={getDicSpecies(props.trial.species) || "_"}
						/>
					)}
				</div>
			</div>
		</ImgBannerCard>
	);
}
