import React from "react";
import { RouteObject } from "react-router-dom";

import LoginPage from "App/Views/login-page/LoginPage";
import MainLayoutPage from "App/Views/main/MainLayoutPage";
import { NotFoundPage } from "App/Views/main/not-found-page/NotFoundPage";
import { RoutesGuard } from "App/Views/RoutesGuard";
import { devRouter } from "../dev/DevRouter";
import { dashboardRouter } from "./dashboard/DashboardRouter";
import { experimentRouter } from "./experiment/ExperimentRouter";
import { germplasmDataRouter } from "./germplasm-data/GermplasmDataRouter";
import { growingAreaRouter } from "./growing-area/GrowingAreaRouter";
import { settingRouter, templateEditingRouter } from "./setting/SettingRouter";
import { pictureRouter } from "./picture/PictureRouter";
import Backup from "../Views/main/backup/Backup";

function routeGuard(children: RouteObject[]): RouteObject {
	return {
		element: <RoutesGuard />,
		children: children,
	};
}
function mainLayout(children: RouteObject[]): RouteObject {
	return {
		element: <MainLayoutPage />,
		children: children,
	};
}

export default function mainRouter(): RouteObject[] {
	const routeObjects: RouteObject[] = [
		{
			path: "/login",
			element: <LoginPage />,
		},
		{
			index: true,
			element: <LoginPage />,
		},
		{
			path: "*",
			element: <NotFoundPage />,
		},
		routeGuard([
			mainLayout([
				settingRouter(),
				templateEditingRouter(),
				dashboardRouter(),
				germplasmDataRouter(),
				experimentRouter(),
				growingAreaRouter(),
				pictureRouter(),
				{
					path: "backup",
					element: <Backup />,
				},
			]),
		]),
	];

	// only accessible in development mode
	if (process.env.NODE_ENV === "development") {
		console.log("Currently in development mode");
		routeObjects.push(devRouter());
	}
	return routeObjects;
}
