import { useMemo } from "react";
import { useSelector } from "react-redux";

import {
	ETrialLevel,
	ESubjectType,
	EVariableScope,
	ITrial,
	IGenotype,
	ILot,
	INotationSerialized,
	INotebook,
	IObsRoundSerialized,
	IUser,
	IVariableCoreSerialized,
	IVariableGroupDetailed,
} from "shared-type";

import { selectECW } from "../../../../../../redux/ECW/ECW.selector";
import {
	ETrialLevelToESubjectType,
	extractSubjectGridInformation,
	extractSubjectOfTrial,
} from "../utils/converter";
import { INotationTableRow } from "../utils/datagrid.types";
import { useFetchDatagridData } from "./useFetchDataGridData";

interface ISubject {
	subjectId: string;
	subjectType: ESubjectType;
}

class CreateNotationTableGrid {
	private tableRow: INotationTableRow[] = [];

	// set on init
	private subjectToObserve!: ISubject[];
	private notationsCompositeKeyMap!: Map<string, INotationSerialized>;
	constructor(
		private observationRoundsMap: Map<string, IObsRoundSerialized>,
		private variablesMap: Map<string, IVariableCoreSerialized>,
		private lotsMap: Map<string, ILot>,
		private genotypesMap: Map<string, IGenotype>,
		private userMap: Map<string, IUser>,
		private variablesGroupsMap: Map<string, IVariableGroupDetailed>,
		private trial?: ITrial,
		private notebooks?: INotebook[],
		private notations: INotationSerialized[] = [],
	) {}

	private generateUniqueNotationIdFromNotation(
		notation: INotationSerialized,
	) {
		return this.generateUniqueNotationId(
			notation.notebookId,
			notation.subjectId,
			notation.subjectType,
			notation.variableId,
			notation.variableScope,
		);
	}
	private generateUniqueNotationId(
		notebookId: string,
		subjectId: string,
		subjectType: ESubjectType,
		variableId: string,
		variableScope: EVariableScope,
	) {
		return `${notebookId}:${subjectId}-${subjectType}:${variableId}-${variableScope}`;
	}

	private init() {
		if (!this.notebooks || !this.trial) {
			return false;
		}
		this.subjectToObserve = extractSubjectOfTrial(this.trial);
		this.notationsCompositeKeyMap = new Map(
			this.notations.map((notation) => [
				this.generateUniqueNotationIdFromNotation(notation),
				notation,
			]),
		);
		return true;
	}
	createTableRow() {
		this.tableRow = [];
		const initResult = this.init();
		if (!initResult) {
			return this.tableRow;
		}
		for (const notebook of this.notebooks!) {
			this.createRowForOneNotebook(notebook);
		}
		return this.tableRow;
	}

	private createRowForOneNotebook(notebook: INotebook) {
		const obsRound = this.observationRoundsMap.get(
			notebook.observationRoundId,
		);
		if (!obsRound) {
			console.error(
				"Unable to find obs round with id " +
					notebook.observationRoundId,
			);
			return;
		}
		const levels = Object.values(ETrialLevel);
		for (const level of levels) {
			this.createRowForOneNotebookLevel(notebook, obsRound, level);
		}
	}
	private createRowForOneNotebookLevel(
		notebook: INotebook,
		obsRound: IObsRoundSerialized,
		level: ETrialLevel,
	) {
		const levelObsRound = this.variablesGroupsMap.get(
			obsRound.variableGroupId,
		)?.variableByLevel[level];
		// if we didn't find notebook variable -> 0 row to generate
		if (!levelObsRound) {
			return;
		}
		const levelSubjects = this.subjectToObserve.filter((elt) =>
			ETrialLevelToESubjectType(level).includes(elt.subjectType),
		);
		const levelObsRoundVariables = levelObsRound.map(
			(elt) => this.variablesMap.get(`${elt.scope}:${elt.variableId}`)!,
		);

		for (const subject of levelSubjects) {
			this.createRowForOneSubject(
				notebook,
				obsRound,
				level,
				subject,
				levelObsRoundVariables,
			);
		}
	}
	private createRowForOneSubject(
		notebook: INotebook,
		obsRound: IObsRoundSerialized,
		level: ETrialLevel,
		subject: ISubject,
		levelObsRoundVariables: IVariableCoreSerialized[],
	) {
		const subjectInfo = extractSubjectGridInformation(
			subject,
			this.trial!,
			this.lotsMap,
			this.genotypesMap,
		);
		for (const variableOfLevel of levelObsRoundVariables) {
			const notation = this.notationsCompositeKeyMap.get(
				this.generateUniqueNotationId(
					notebook._id,
					subject.subjectId,
					subject.subjectType,
					variableOfLevel._id,
					variableOfLevel.scope,
				),
			);

			const row: INotationTableRow = {
				id: this.tableRow.length,
				subjectName: subjectInfo.subjectName,
				variable: variableOfLevel,
				notebook: notebook,
				obsRound: obsRound.name,
				unit: level,
				user: this.userMap.get(notebook.observerId),
				notation: notation,
			};

			this.tableRow.push(row);
		}
	}
}

export function useGetDatagridData() {
	const trial = useSelector(selectECW.trialRemote);
	const {
		observationRoundsMap,
		notebooks,
		notations,
		variablesMap,
		lotsMap,
		genotypesMap,
		variablesGroupsMap,
		userMap,
		status,
	} = useFetchDatagridData(trial);

	const gridRow: INotationTableRow[] = useMemo(() => {
		if (status.isFetching) {
			return [];
		}
		const result = new CreateNotationTableGrid(
			observationRoundsMap,
			variablesMap,
			lotsMap,
			genotypesMap,
			userMap,
			variablesGroupsMap,
			trial,
			notebooks,
			notations,
		).createTableRow();
		return result;
	}, [
		observationRoundsMap,
		variablesMap,
		lotsMap,
		genotypesMap,
		userMap,
		trial,
		notebooks,
		notations,
		variablesGroupsMap,
		status.isFetching,
	]);

	return {
		gridRow,
		status,
	};
}
