import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import { formatString } from "common";
import { ICreateGrowingArea } from "shared-type";

import { DIC, EDIC_KEY } from "../../../dictionary";
import useAppSnackbar from "../../../hooks/useAppSnackbar";
import { apiGrowingArea } from "../../../redux/growing-area/growing-area.api";
import { apiUserCustomHook } from "../../../redux/user/user.api";
import { ApiErrorSnackbar } from "../../../redux/utils/api-error-snackbar/ApiErrorSnackbar";
import { ReduxApiError } from "../../../redux/utils/errors";
import BottomMenu from "../../base-components/menu/bottom-menu/BottomMenu";
import DorianeDrawer from "../../base-components/modal/DorianeDrawer";
import {
	GrowingAreaForm,
	growingAreaFormToObject,
	growingAreaValidator,
	IGrowingAreaForm,
} from "../../form/growing-area";
import SelectUserGAModal from "../select-user/SelectUserGAModal";

import "./CreateGrowingAreaModal.scss";

interface CreateGrowingAreaModalProps {
	onClose: () => void;
}

export default function CreateGrowingAreaModal(
	props: CreateGrowingAreaModalProps,
) {
	const form = useForm<IGrowingAreaForm>({
		defaultValues: {
			startDate: dayjs().startOf("day"),
			users: [],
		},
		resolver: yupResolver(growingAreaValidator as any),
	});
	const usersIds = useWatch({ control: form.control, name: "users" });
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();

	const [selectUserOpen, setSelectUserOpen] = useState<boolean>(false);
	const { data: fetchedGrowingAreaUsers } =
		apiUserCustomHook.useGetUsersByIds(usersIds);

	const [createGrowingArea] = apiGrowingArea.useCreateGrowingAreaMutation();

	const handleCreate = async (continueMode: boolean) => {
		form.clearErrors();

		form.handleSubmit(
			async (data) => {
				const growingArea = growingAreaFormToObject(data);

				await createGrowingArea(growingArea as ICreateGrowingArea)
					.unwrap()
					.then(() => {
						// After creation continue or close
						if (continueMode) {
							form.reset();
						} else {
							setSelectUserOpen(false);
							props.onClose();
						}
						enqueueSnackbarSuccess(
							formatString(
								DIC(EDIC_KEY.ENTITY_CREATED),
								DIC(EDIC_KEY.GROWING_AREA),
							),
						);
					})
					.catch((err: ReduxApiError) => {
						console.warn(err);
						enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
					});
			},
			() => enqueueSnackbarError(DIC(EDIC_KEY.BAD_INPUT)),
		)();
	};

	return (
		<>
			<DorianeDrawer
				onClose={props.onClose}
				title={formatString(
					DIC(EDIC_KEY.CREATE_ENTITY),
					DIC(EDIC_KEY.GROWING_AREA).toLowerCase(),
				)}
			>
				<div className="CreateGrowingAreaModal-form take-remaining-space">
					<GrowingAreaForm
						growingAreaUsers={fetchedGrowingAreaUsers}
						mode="creation"
						onSelectUserClick={() => setSelectUserOpen(true)}
						form={form}
					/>
				</div>
				<BottomMenu
					onCreateAndClose={() => handleCreate(false)}
					onCreateAndContinue={() => handleCreate(true)}
				/>
			</DorianeDrawer>
			{selectUserOpen && (
				<SelectUserGAModal
					initialUserIds={[]}
					currentUserIds={usersIds}
					onUserCheck={(clickedUser, checked) => {
						if (checked) {
							form.setValue("users", [
								...usersIds,
								clickedUser.id,
							]);
						} else {
							const newUserList = usersIds.filter(
								(userId) => userId !== clickedUser.id,
							);
							form.setValue("users", newUserList);
						}
					}}
					onClose={() => setSelectUserOpen(false)}
				/>
			)}
		</>
	);
}
