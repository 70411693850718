import { extendDataItem, mapTree } from "@progress/kendo-react-common";
import {
	filterBy,
	Gantt as KendoGantt,
	GanttColumnProps,
	GanttColumnReorderEvent,
	GanttColumnResizeEvent,
	GanttDataStateChangeEvent,
	GanttDayView,
	GanttExpandChangeEvent,
	GanttMonthView,
	GanttRow,
	GanttRowProps,
	GanttSelectionChangeEvent,
	GanttTaskClickEvent,
	GanttWeekView,
	GanttYearView,
	orderBy,
} from "@progress/kendo-react-gantt";
import { TreeListRowProps } from "@progress/kendo-react-treelist";
import React, { useCallback, useState } from "react";

import { IBaseGanttData } from "../../experiment/common/experiment-gantt/ExperimentGantt.interface";

export type GanttColumnID = number;

export interface IGanttProps {
	data: IBaseGanttData[];
	columns: GanttColumnProps[];
	fields: Record<string, string>;
	getTaskId: (values: any) => any;
	rowRenderer?: (
		row: React.ReactElement<HTMLTableRowElement>,
		props: TreeListRowProps,
	) => React.ReactNode;
	defaultExpandedTasks?: GanttColumnID[];
	onTaskClick?: (event: GanttTaskClickEvent) => void;
	onSelectionChange?: (event: GanttSelectionChangeEvent) => void;
}

const DefaultGanttStyle = {
	maxHeight: "100%",
	maxWidth: "100%",
};

export default function Gantt(props: IGanttProps) {
	const defaultSort = { field: "start", dir: "asc" };

	const [expandedState, setExpandedState] = useState<GanttColumnID[]>(
		props.defaultExpandedTasks ?? [],
	);
	const [columnsState, setColumnsState] = useState<Array<any>>(props.columns);

	const handleColumnResize = useCallback(
		(event: GanttColumnResizeEvent) => setColumnsState(event.columns),
		[setColumnsState],
	);

	const handleColumnReorder = useCallback(
		(event: GanttColumnReorderEvent) => setColumnsState(event.columns),
		[setColumnsState],
	);

	const [dataState, setDataState] = useState<any>({
		sort: [defaultSort],
		filter: [],
	});

	const handleDataStateChange = useCallback(
		(event: GanttDataStateChangeEvent) =>
			setDataState({
				sort: event.dataState.sort,
				filter: event.dataState.filter,
			}),
		[setDataState],
	);

	const handleExpandChange = useCallback(
		(event: GanttExpandChangeEvent) => {
			const id = props.getTaskId(event.dataItem);
			const newExpandedState = event.value
				? expandedState.filter((currentId) => currentId !== id)
				: [...expandedState, id];

			setExpandedState(newExpandedState);
		},
		[expandedState],
	);

	const processedData = React.useMemo(() => {
		const filteredData = filterBy(
			props.data,
			dataState.filter,
			props.fields.children,
		);
		const sortedData = orderBy(
			filteredData,
			dataState.sort,
			props.fields.children,
		);

		return mapTree(sortedData, props.fields.children, (task) =>
			extendDataItem(task, props.fields.children, {
				[props.fields.isExpanded]: expandedState.includes(
					props.getTaskId(task),
				),
			}),
		);
	}, [dataState, props, expandedState]);

	const rowRenderer = (renderProps: GanttRowProps) => {
		if (props.rowRenderer) {
			return <GanttRow {...renderProps} render={props.rowRenderer} />;
		}

		return <GanttRow {...renderProps} />;
	};

	return (
		<KendoGantt
			// Visual
			className="no-animation"
			style={DefaultGanttStyle}
			defaultView={"month"}
			row={rowRenderer}
			// Data
			taskModelFields={props.fields}
			taskData={processedData}
			columns={columnsState}
			// sort & filter
			sortable
			sort={dataState.sort}
			filter={dataState.filter}
			onDataStateChange={handleDataStateChange}
			// Operations
			onTaskClick={props.onTaskClick}
			resizable={false} // TODO : FIX THIS
			onColumnResize={handleColumnResize}
			// orderable
			// onColumnReorder={onColumnReorder}
			onExpandChange={handleExpandChange}
		>
			<GanttDayView />
			<GanttWeekView />
			<GanttMonthView />
			<GanttYearView />
		</KendoGantt>
	);
}
