import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { ITenant } from "shared-type";

import { AbstractRequest } from "../abstract-request";

export class TenantRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/tenants";
	}

	getTenantById(
		id: string,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<ITenant, any>> {
		return this.axios.get<ITenant>(`${this.baseUrl}/${id}`, config);
	}
}
