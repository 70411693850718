import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { useRequest } from "../../../request-provider";
import { defaultApiError } from "../../utils/errors";

export const apiUserBackup = createApi({
	reducerPath: "api-user-backup",
	baseQuery: () => ({ data: undefined }),

	// global cache configuration for the api (in second)
	keepUnusedDataFor: 30,
	refetchOnMountOrArgChange: 30,

	// update tag
	tagTypes: [],

	// api definition
	endpoints: (build) => ({
		restoreDump: build.mutation<void, void>({
			queryFn: async () => {
				try {
					const result = await useRequest().backup.restoreDump();
					return {
						data: result.data,
					};
				} catch (err) {
					console.error(err);
					return defaultApiError(
						err,
						"Dump restore partially, please read the message for more details",
					);
				}
			},
		}),
	}),
});
