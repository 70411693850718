import _ from "lodash";
import React, { useMemo, useState } from "react";

import { paginateList } from "common";
import { IUser } from "shared-type";

import CreateUserModal from "App/components/users/create/CreateUserModal";
import { DIC, EDIC_KEY } from "App/dictionary";
import PaginationTool from "../../../../components/base-components/pagination/PaginationTool";
import FlexBox from "../../../../components/base-components/placement/flex-box/FlexBox";
import { USER_DEFAULT_PAGE_SIZE } from "../../../../const";
import { apiUser } from "../../../../redux/user/user.api";
import { HeaderTitleSearchButton } from "stories/base-components/Layout/HeaderTitleSearchButton/HeaderTitleSearchButton";
import DorianeDataGrid from "App/components/base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import Size from "stories/constants/Size/Size";
import store from "App/store";
import { userAction } from "App/redux/user/user.slice";
import { userColumns } from "./userColumns";

export default function UsersPage() {
	const { data: userList } = apiUser.useGetUsersQuery();

	const [filter, setFilter] = useState<string>("");
	const [page, setPage] = useState<number>(0);
	const [creationOpen, setCreationOpen] = useState<boolean>(false);

	const filteredUsers = useMemo(() => {
		const userListCopy = _.cloneDeep(userList);
		const sortAndFilterUsers = (userListCopy ?? [])
			.sort((userA, userB) =>
				(userA.lastName ?? "").localeCompare(userB.lastName ?? ""),
			)
			.filter((elt) => {
				return (
					elt.mail?.toLowerCase().includes(filter.toLowerCase()) ||
					elt.firstName
						?.toLowerCase()
						.includes(filter.toLowerCase()) ||
					elt.lastName?.toLowerCase().includes(filter.toLowerCase())
				);
			});
		return sortAndFilterUsers;
	}, [filter, userList]);

	const paginatedUsersInfos = useMemo(() => {
		return paginateList<IUser>(filteredUsers, page, USER_DEFAULT_PAGE_SIZE);
	}, [filteredUsers, page]);

	const handleCardClick = (userId: string) => {
		store.dispatch(userAction.selectUser(userId));
	};

	return (
		<FlexBox fullParentSize>
			<FlexBox
				className="UsersPage-container full-parent-size"
				flexDirection={"column"}
				gap={Size.md}
				padding={`${Size.xs} ${Size.xl}`}
				overflow="auto"
			>
				<HeaderTitleSearchButton
					title={DIC(EDIC_KEY.TEAM_MEMBERS)}
					list={userList || []}
					searchPlaceholder={DIC(EDIC_KEY.SEARCH_BY_NAME_AND_EMAIL)}
					setSearch={(search: string) => {
						setFilter(search);
						setPage(0);
					}}
					searchValue={filter}
					btnText={DIC(EDIC_KEY.NEW_USER) + " +"}
					btnOnClick={() => setCreationOpen(!creationOpen)}
				/>
				<DorianeDataGrid<IUser>
					secondaryStyle
					onRowClick={(event, row) => {
						handleCardClick(row.id);
					}}
					columns={userColumns()}
					rows={paginatedUsersInfos.paginatedList || []}
				/>

				<FlexBox justifyContent={"center"}>
					<PaginationTool
						currentPage={page}
						maxPage={paginatedUsersInfos.totalPages}
						onPageRoll={(newPage) => setPage(newPage)}
					/>
				</FlexBox>
			</FlexBox>
			<CreateUserModal
				open={creationOpen}
				onClose={() => setCreationOpen(false)}
			/>
		</FlexBox>
	);
}
