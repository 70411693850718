import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
	EReviewStatus,
	ETrialLevel,
	ITrialAnalysisFilterMQL,
	IUser,
} from "shared-type";

import { DIC, EDIC_KEY } from "../../../../../dictionary";
import { selectECW } from "../../../../../redux/ECW/ECW.selector";
import { obsRoundCustomHook } from "../../../../../redux/observation-round/obs-round.api";
import { apiUserCustomHook } from "../../../../../redux/user/user.api";
import DorianeButton from "../../../../base-components/button/DorianeButton";
import { CustomCheckbox } from "../../../../base-components/checkbox/CustomCheckbox";
import ChipCombobox from "../../../../base-components/combobox/chip-combobox/ChipCombobox";
import Combobox from "../../../../base-components/combobox/Combobox";
import { getDicTrialLevel } from "../../../../../dictionary/dictionary-key-getter";

import "./TrialAnalysisHeader.scss";

interface TrialAnalysisHeaderProps {
	filterGlobal: any;
	setFilterGlobal: (filter: any) => void;
}

export default function TrialAnalysisHeader(props: TrialAnalysisHeaderProps) {
	const [trialLevel, setTrialLevel] = useState<ETrialLevel>(
		ETrialLevel.MATERIAL,
	);
	const [obsRounds, setObsRounds] = useState<string[] | undefined>(undefined);
	const [users, setUsers] = useState<string[] | undefined>(undefined);
	// NB: Outliers are always excluded.
	const [validChecked, setValidChecked] = useState<boolean>(true);
	const [inProgressChecked, setInProgressChecked] = useState<boolean>(false);

	const trial = useSelector(selectECW.trialRemote);
	const { data: observationRounds } =
		obsRoundCustomHook.useGetObsRoundsByExpIdQueryDeserialized(
			"Trial",
			trial?._id,
		);

	const usersIds = useMemo(() => {
		const ids: Set<string> = new Set();
		observationRounds?.forEach((obsRound) => {
			obsRound.team.forEach((userId) => ids.add(userId));
		});
		const res = Array.from(ids);
		return res;
	}, [observationRounds]);
	const { data: observers } = apiUserCustomHook.useGetUsersByIds(usersIds);

	const userIdsSorted = useMemo(() => {
		return observers
			.sort((userA, userB) => {
				const lastNameComparison = (userA.lastName ?? "").localeCompare(
					userB.lastName ?? "",
				);
				if (lastNameComparison !== 0) {
					return lastNameComparison;
				}
				return (userA.firstName ?? "").localeCompare(
					userB.firstName ?? "",
				);
			})
			.map((user) => user.id);
	}, [observers]);

	const translateUser = (userId: string) => {
		const user: IUser | undefined = observers.find(
			(us: IUser) => us.id === userId,
		);
		if (user) {
			return `${user.firstName} ${user.lastName}`;
		}
		return "";
	};

	const obsRoundsSorted = useMemo(() => {
		if (!observationRounds) return [];
		return observationRounds
			.sort((obsA, obsB) => obsA.name.localeCompare(obsB.name))
			.map((obsRound) => obsRound.name);
	}, [observationRounds]);

	const computeAndSetChartFilter = useCallback(() => {
		if (!trial) return;

		const dataStatus: number[] = [];
		if (validChecked) dataStatus.push(EReviewStatus.REVIEWED);
		if (inProgressChecked) dataStatus.push(EReviewStatus.NA);

		const filters: Partial<ITrialAnalysisFilterMQL>[] = [
			{ "trial._id": { $oid: trial._id } },
		];
		if (obsRounds && obsRounds.length > 0) {
			filters.push({ "obsRound.name": { $in: obsRounds } });
		}
		if (users && users.length > 0) {
			filters.push({ "notebook.user": { $in: users } });
		}
		if (dataStatus.length > 0) {
			filters.push({ "notation.status": { $in: dataStatus } });
		}
		props.setFilterGlobal({ $and: filters });
	}, [inProgressChecked, obsRounds, props, trial, users, validChecked]);

	useEffect(() => {
		// Init when data are fetched
		if (userIdsSorted.length > 0 && users === undefined) {
			setUsers(userIdsSorted);
		}
		if (obsRoundsSorted.length > 0 && obsRounds === undefined) {
			setObsRounds(obsRoundsSorted);
		}
	}, [userIdsSorted, obsRoundsSorted, obsRounds, users]);

	useEffect(() => {
		if (
			// No filter and all default value have been set
			props.filterGlobal === undefined &&
			users?.length &&
			obsRounds?.length &&
			trial !== undefined
		) {
			computeAndSetChartFilter();
		}
	}, [users, obsRounds, trial, props.filterGlobal, computeAndSetChartFilter]);

	// To prevent to display trial charts
	const observationValuesFiltered = Object.values(ETrialLevel).filter(
		(value) => value !== "trial",
	);

	return (
		<div className="TrialAnalysisHeader-body">
			<Combobox<ETrialLevel>
				value={trialLevel}
				label={DIC(EDIC_KEY.OBSERVATION_UNIT)}
				onChange={setTrialLevel}
				comboboxValues={observationValuesFiltered}
				translateMethod={getDicTrialLevel}
				className="TrialAnalysisHeader-combobox"
			/>
			<ChipCombobox<string>
				value={obsRounds ?? []}
				label={DIC(EDIC_KEY.OBSERVATION_ROUND)}
				onChange={setObsRounds}
				comboboxValues={obsRoundsSorted}
				translateMethod={(value) => value}
				noWrap
				className="TrialAnalysisHeader-combobox"
				selectAllBtn
			/>
			<ChipCombobox<string>
				value={users ?? []}
				label={DIC(EDIC_KEY.OBSERVER)}
				onChange={setUsers}
				comboboxValues={userIdsSorted}
				translateMethod={translateUser}
				className="TrialAnalysisHeader-combobox"
				noWrap
				selectAllBtn
			/>
			<div className="TrialAnalysisHeader-checkboxes">
				<div className="TrialAnalysisHeader-valid-title">
					{DIC(EDIC_KEY.DATA_STATUS)}
				</div>
				<div className="TrialAnalysisHeader-checkboxes-container">
					<CustomCheckbox
						label={DIC(EDIC_KEY.VALID)}
						checked={validChecked}
						onChange={(event, checked) => setValidChecked(checked)}
					/>

					<CustomCheckbox
						label={DIC(EDIC_KEY.IN_PROGRESS)}
						checked={inProgressChecked}
						onChange={(event, checked) =>
							setInProgressChecked(checked)
						}
					/>
				</div>
			</div>

			<DorianeButton
				dorianeStyle="primary"
				onClick={() => computeAndSetChartFilter()}
			>
				{DIC(EDIC_KEY.APPLY)}
			</DorianeButton>
		</div>
	);
}
