import { Skeleton } from "@mui/material";
import * as React from "react";

import { concatClassName } from "common";

import "./SkeletonCustomCard.scss";

interface SkeletonCustomCardProps {
	flex?: boolean;
}

export default function SkeletonCustomCard(props: SkeletonCustomCardProps) {
	const className = concatClassName(
		"SkeletonCustomCard",
		props.flex ? "SkeletonCustomCard-flex" : undefined,
	);
	return (
		<div className={className}>
			<Skeleton width={"30%"} variant="rounded" />
			<Skeleton width={"50%"} />
			<Skeleton width={"10%"} />
		</div>
	);
}
