import {
	IOpTask,
	IObservationRound,
	ETaskType,
	IOpTaskSerialized,
	IObsRoundSerialized,
	IOperation,
	IOperationSerialized,
} from "shared-type";

export function isObsRoundTask(value: IOpTask): value is IObservationRound {
	return value.type === ETaskType.OBSERVATION_ROUND;
}

export function isOperationTask(value: IOpTask): value is IOperation {
	return value.type === ETaskType.OPERATION;
}

export function isObsRoundTaskPartial(
	value: Partial<IOpTask>
): value is Partial<IObservationRound> {
	return value.type === ETaskType.OBSERVATION_ROUND;
}

export function isOperationTaskPartial(
	value: Partial<IOpTask>
): value is Partial<IOperation> {
	return value.type === ETaskType.OPERATION;
}

export function isObsRoundTaskSerialized(
	value: IOpTaskSerialized
): value is IObsRoundSerialized {
	return value.type === ETaskType.OBSERVATION_ROUND;
}
export function isOperationTaskSerialized(
	value: IOpTaskSerialized
): value is IOperationSerialized {
	return value.type === ETaskType.OPERATION;
}
