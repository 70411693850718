import React, { useEffect } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { Skeleton } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";

import { DIC, EDIC_KEY } from "App/dictionary";
import { useAppSelector } from "App/hooks/reduxHook";
import { apiUser } from "App/redux/user/user.api";
import { selectUser } from "App/redux/user/user.selector";
import { userAction } from "App/redux/user/user.slice";
import store from "App/store";
import { Modal } from "stories/base-components/Modal/Modal";
import FlexBox from "App/components/base-components/placement/flex-box/FlexBox";
import Size from "stories/constants/Size/Size";
import UserForm, { IUserForm } from "App/components/form/user/UserForm";
import { userValidator } from "App/components/form/user";
import {
	userFormToObject,
	userObjectToForm,
} from "App/components/form/user/transformer";
import useAppSnackbar from "App/hooks/useAppSnackbar";
import { ReduxApiError } from "App/redux/utils/errors";
import { ApiErrorSnackbar } from "App/redux/utils/api-error-snackbar/ApiErrorSnackbar";
import { useBloomeoAuth } from "App/auth/AuthContext";

export default function UserEditModal() {
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();
	const closeModal = () => {
		store.dispatch(userAction.selectUser(undefined));
	};
	const userId = useAppSelector(selectUser.userSelectedId);

	const { data: userRemote } = apiUser.useGetUserByIdQuery(userId);

	const [updateUser] = apiUser.useUpdateUserMutation();

	const form = useForm<IUserForm>({
		resolver: yupResolver(userValidator as Yup.ObjectSchema<IUserForm>),
	});
	const { handleSubmit, formState } = form;
	const { isSubmitting } = formState;

	useEffect(() => {
		if (!userRemote) {
			form.reset();
			return;
		}
		form.reset(userObjectToForm(userRemote));
		form.trigger();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userRemote]);

	const handleSave = async (data: IUserForm) => {
		if (userRemote === undefined) return;
		const user = userFormToObject(data);

		await updateUser({
			id: userRemote.id,
			updateUser: user,
		})
			.unwrap()
			.then(() => {
				closeModal();
				enqueueSnackbarSuccess(DIC(EDIC_KEY.SAVE));
			})
			.catch((err: ReduxApiError) => {
				console.warn(err);
				enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
			});
	};
	const { userLogin: user } = useBloomeoAuth();

	return (
		<Modal
			// Modal open only if userId
			open={userId !== undefined}
			titleIcon={"edit"}
			title={
				user?.id === userRemote?.id
					? DIC(EDIC_KEY.MY_PROFILE)
					: userRemote?.firstName + " " + userRemote?.lastName
			}
			handleClose={closeModal}
			validateBtn={{
				action: handleSubmit(
					(data) => handleSave(data),
					(error) => console.error(error),
				),
				isLoading: isSubmitting,
				label: DIC(EDIC_KEY.SAVE),
			}}
			width={800}
		>
			<FlexBox>
				{userRemote ? (
					<UserForm mode="used" form={form} />
				) : (
					<UserEditSkeleton />
				)}
			</FlexBox>
		</Modal>
	);
}

const UserEditSkeleton = () => (
	<FlexBox
		flexDirection="row"
		flex={1}
		justifyContent="space-between"
		gap={Size.x2l}
		paddingBottom={Size.x3l}
	>
		<FlexBox flexDirection="column" gap={Size.md} flex={1}>
			<Skeleton variant="circular" width={Size.x5l} height={Size.x5l} />
			<Skeleton variant="text" />
			<FlexBox flexDirection="row" gap={Size.md}>
				<Skeleton
					variant="circular"
					width={Size.x2l}
					height={Size.x2l}
				/>
				<Skeleton
					variant="circular"
					width={Size.x2l}
					height={Size.x2l}
				/>
				<Skeleton
					variant="circular"
					width={Size.x2l}
					height={Size.x2l}
				/>
				<Skeleton
					variant="circular"
					width={Size.x2l}
					height={Size.x2l}
				/>
				<Skeleton
					variant="circular"
					width={Size.x2l}
					height={Size.x2l}
				/>
			</FlexBox>
		</FlexBox>
		<FlexBox flexDirection="column" gap={Size.md} flex={1}>
			<Skeleton variant="rounded" width={"100%"} height={Size.x2l} />
			<Skeleton variant="rounded" width={"100%"} height={Size.x2l} />
			<Skeleton variant="rounded" width={"100%"} height={Size.x2l} />
			<Skeleton variant="rounded" width={"100%"} height={Size.x2l} />
		</FlexBox>
	</FlexBox>
);
