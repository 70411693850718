import { IconButton, Tooltip } from "@mui/material";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IGrowingArea } from "shared-type";

import { DIC, EDIC_KEY } from "../../dictionary";
import { PATH_TRIALS } from "App/routes";
import { apiUserCredentials } from "../../redux/user/credentials/credentials.api";
import Box from "../base-components/placement/box/Box";
import { GrowingAreaMapMarker } from "./marker/GrowingAreaMapMarker";
import { MapHelper } from "./utils/map-helper";
import { EmptyState } from "stories/base-components/EmptyState/EmptyState";

import { MapSwitchToListWarn, MapSwitchToList } from "../../../assets";
import "./TrialLocationMap.scss";

const DORIANE_POSTION = { lat: 43.7021697, lng: 7.2668146 };
const mapStyles = {
	height: "100%",
	width: "100%",
};

export type ITrialLocationMapWrappedProps = {
	growingAreas?: IGrowingArea[];
	onGAClick?: (growingArea: IGrowingArea) => void;
	onSwapVueClick?: () => void;
};

function TrialLocationMapWrapped(
	props: ITrialLocationMapWrappedProps & { apiKey: string },
) {
	const nav = useNavigate();
	const [map, setMap] = useState<google.maps.Map | null>(null);
	const [zoom, setZoom] = useState<number>(11);
	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: props.apiKey,
		mapIds: ["1e6a12c673dfd9c5"],
	});
	const onUnmount = useCallback(() => {
		setMap(null);
	}, []);

	const gaWithLocation = useMemo(
		() =>
			props.growingAreas?.filter((ga) =>
				MapHelper.isValidGeoJSONPoint(ga.location),
			),
		[props.growingAreas],
	);
	const gaWithWithoutLocation = useMemo(
		() =>
			props.growingAreas?.filter(
				(ga) => !MapHelper.isValidGeoJSONPoint(ga.location),
			),
		[props.growingAreas],
	);

	const autoCenterOnGA = useCallback(
		(map: google.maps.Map) => {
			const firstGa = props.growingAreas?.[0];
			if (firstGa) {
				if (MapHelper.isValidGeoJSONPoint(firstGa.location)) {
					map.setCenter(
						MapHelper.getGoogleMapPointFromGeoJSON(
							firstGa.location,
						),
					);
				}
			}
		},
		[props.growingAreas],
	);
	const onLoad = useCallback(
		(map: google.maps.Map) => {
			autoCenterOnGA(map);
			setMap(map);
			map.setOptions({
				fullscreenControl: false,
				styles: [
					{
						// Disable point of interest (hotel, market, ...)
						featureType: "poi",
						elementType: "labels",
						stylers: [{ visibility: "off" }],
					},
				],
			});
		},
		[autoCenterOnGA],
	);
	useEffect(() => {
		map && autoCenterOnGA(map);
	}, [autoCenterOnGA, map, props.growingAreas]);

	const swapVueButton = useMemo(() => {
		if (gaWithWithoutLocation?.length) {
			return (
				<Tooltip
					title={`${gaWithWithoutLocation?.length} ${DIC(
						EDIC_KEY.MET_MAP_GROWING_AREA_NO_LOCATION_TOOLTIP,
					)}`}
				>
					<IconButton
						onClick={props.onSwapVueClick}
						className="TrialLocationMap-swap-size"
					>
						<img src={MapSwitchToListWarn} />
					</IconButton>
				</Tooltip>
			);
		}
		return (
			<IconButton
				onClick={props.onSwapVueClick}
				className="TrialLocationMap-swap-size"
			>
				<img src={MapSwitchToList} />
			</IconButton>
		);
	}, [gaWithWithoutLocation?.length, props.onSwapVueClick]);

	if (isLoaded && !props.growingAreas?.length) {
		return (
			<EmptyState
				message={DIC(EDIC_KEY.MET_MAP_GROWING_AREA_NO_LOCATION_MESSAGE)}
				beforeActionMessage={DIC(EDIC_KEY.MET_MAP_GROWING_AREA_NO_LOCATION_BEFORE_ACTION_MESSAGE)}
				actionMessage={DIC(EDIC_KEY.MET_MAP_GROWING_AREA_NO_LOCATION_ACTION_MESSAGE)}
				actionOnClick={() => {
					nav(`..${PATH_TRIALS}`)
				}}
			></EmptyState>
		);
	}

	return (
		<Box takeRemainingSpace className="no-animation TrialLocationMap">
			{isLoaded && (
				<GoogleMap
					mapContainerStyle={mapStyles}
					zoom={zoom}
					onLoad={onLoad}
					onUnmount={onUnmount}
					center={DORIANE_POSTION}
					onZoomChanged={() => setZoom(map?.getZoom() ?? 10)}
				>
					<Box className="TrialLocationMap-swap-container TrialLocationMap-swap-size">
						{swapVueButton}
					</Box>

					{gaWithLocation?.map((ga) => (
						<GrowingAreaMapMarker
							key={ga._id}
							growingArea={ga}
							onClick={props.onGAClick}
						/>
					))}
				</GoogleMap>
			)}
		</Box>
	);
}

export default function TrialLocationMap(props: ITrialLocationMapWrappedProps) {
	const apiCredential = apiUserCredentials.useGetGoogleMapCredentialQuery();
	if (apiCredential.isLoading) {
		return <Box>{DIC(EDIC_KEY.LOADING_DOTS)}</Box>;
	}
	if (apiCredential.isError || !apiCredential.data?.apiKey) {
		return <Box>{DIC(EDIC_KEY.MAP_CANNOT_RETRIEVE_API_KEY)}</Box>;
	}
	return (
		<TrialLocationMapWrapped
			apiKey={apiCredential.data.apiKey}
			growingAreas={props.growingAreas}
			onGAClick={props.onGAClick}
			onSwapVueClick={props.onSwapVueClick}
		/>
	);
}
