import React from "react";

import { EEntitiesLength } from "shared-type";

import { IColumn } from "../../DorianeDataGrid";
import { StringCellEditable } from "../../cell/StringCellEditable";
import { IMETTrialDraftRow } from "../../../../../../experiment-wizard/common/types";

export const METTrialNameColumn = <
	T extends Pick<IMETTrialDraftRow, "trialName" | "id">,
>({
	selectedEditingColumnId,
	onEditClick,
	onSubmitChange,
}: {
	selectedEditingColumnId?: number;
	onEditClick?: (row: T) => void;
	onSubmitChange?: (newValue: string, row: T) => void;
}): IColumn<T> => ({
	id: "trialName",
	getHeaderRenderer: "Name",
	getCellRenderer: (row) => {
		return (
			<StringCellEditable
				onEditClick={onEditClick}
				onSubmitChange={onSubmitChange}
				row={row}
				value={row.trialName}
				isOnEditing={row.id === selectedEditingColumnId}
				onEditDisplayed={!selectedEditingColumnId}
				maxLength={EEntitiesLength.name}
			/>
		);
	},
});
