import { IUser } from "shared-type";

export enum ETaskUserSource {
	OWNER = 0,
	GROWING_AREA = 1,
	OTHER_OBSERVERS = 2,
	OTHER = 4,
}

export interface IUserWithTaskSource extends IUser {
	source: ETaskUserSource;
}
