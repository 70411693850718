import React, { useState } from "react";
import { ColorResult, SketchPicker } from "react-color";

import "./DotColorPicker.scss";

interface DotColorPickerProps {
	color: string;
	onColorChange: (color: string) => void;
	onClose?: () => void;
}

export default function DotColorPicker(props: DotColorPickerProps) {
	const [pickerOpen, setPickerOpen] = useState<boolean>(false);

	const handleClick = () => {
		setPickerOpen(true);
	};

	const handleClose = () => {
		if (props.onClose) {
			props.onClose();
		}
		setPickerOpen(false);
	};

	const handleChange = (color: ColorResult) => {
		props.onColorChange(color.hex);
	};

	return (
		<div className="DotColorPicker-container">
			<div
				className="DotColorPicker-dot-container center full-parent-size"
				onClick={handleClick}
			>
				<div
					className="DotColorPicker-dot"
					style={{ backgroundColor: props.color }}
				/>
			</div>
			{pickerOpen ? (
				<>
					<div className="DotColorPicker-popup">
						<SketchPicker
							onChange={handleChange}
							color={props.color}
						/>
					</div>
					<div
						className="DotColorPicker-overlay"
						onClick={handleClose}
					/>
				</>
			) : null}
		</div>
	);
}
