import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { IFilter, ITag, ISort, PaginateResponse } from "shared-type";

import { AbstractRequest } from "../abstract-request";

export class TagRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/tag";
	}
	getTags(
		page?: number,
		pageSize?: number,
		filter?: IFilter,
		sort?: ISort,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<PaginateResponse<ITag>, any>> {
		return this.axios.get<PaginateResponse<ITag>>(this.baseUrl, {
			...config,
			params: {
				...config?.params,
				page: JSON.stringify(page),
				pageSize: JSON.stringify(pageSize),
				filter: JSON.stringify(filter),
				sort: JSON.stringify(sort),
			},
		});
	}
}
