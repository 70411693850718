import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

import { formatString } from "common";

import InputText from "../../../../input-text/InputText";
import { DIC, EDIC_KEY } from "App/dictionary";

export function StringCellEditable<T>(props: {
	value: string;
	row: T;
	isOnEditing?: boolean;
	onSubmitChange?: (newValue: string, row: T) => void;
	onEditClick?: (row: T) => void;
	onEditDisplayed?: boolean;
	maxLength?: number;
}) {
	const [isHover, setIsShown] = useState(false);
	const [newValue, setNewValue] = useState(props.value);
	const stringTooLong = props.maxLength
		? newValue.length > props.maxLength
		: false;
	const onSubmitTrialNewName = useCallback(() => {
		props.onSubmitChange?.(newValue, props.row);
	}, [props, newValue]);
	useEffect(() => {
		setNewValue(props.value);
	}, [props.value]);

	return (
		<div
			className="ECWTrialGeneration-trial-cell DorianeDataGridCell-string"
			onMouseEnter={() => setIsShown(true)}
			onMouseLeave={() => setIsShown(false)}
		>
			{props.isOnEditing ? (
				<>
					<Tooltip
						title={
							stringTooLong
								? formatString(
										DIC(EDIC_KEY.CHARACTERS_MAX),
										props.maxLength + "",
								  )
								: props.value
						}
						open={stringTooLong}
					>
						{/* We need to wrap in div because tooltip need ref*/}
						<div style={{ flex: 1 }}>
							<InputText
								inputRef={(input) => input && input.focus()}
								style={{
									width: "100%",
								}}
								value={newValue}
								onChange={(event) =>
									setNewValue(event.target.value)
								}
								onKeyPress={(event) => {
									if (event.key === "Enter") {
										onSubmitTrialNewName();
									}
								}}
							/>
						</div>
					</Tooltip>

					<IconButton
						sx={{ padding: 0 }}
						onClick={onSubmitTrialNewName}
					>
						<DoneIcon
							className={stringTooLong ? "text-error" : ""}
						/>
					</IconButton>
				</>
			) : (
				<>
					<Tooltip
						title={
							stringTooLong
								? formatString(
										DIC(EDIC_KEY.CHARACTERS_MAX),
										props.maxLength + "",
								  )
								: props.value
						}
						open={stringTooLong}
					>
						<div
							className={
								"ECWTrialGeneration-name clamped-text" +
								(stringTooLong ? " text-error" : "")
							}
						>
							{props.value}
						</div>
					</Tooltip>
					{props.onEditDisplayed && isHover && (
						<IconButton
							sx={{ padding: 0 }}
							onClick={() => props.onEditClick?.(props.row)}
						>
							<EditIcon />
						</IconButton>
					)}
				</>
			)}
		</div>
	);
}
