import React from "react";
import { RouteObject } from "react-router-dom";

import {
	PARAM_OBSERVATION_ROUND_ID,
	PARAM_TRIAL_ID,
	PATH_ANALYSIS,
	PATH_DATA,
	PATH_SYNTHESIS,
	PATH_EXPERIMENTS,
	PATH_GANTT,
	PATH_GENERAL,
	PATH_GERMPLASM,
	PATH_LOCATION,
	PATH_NOTEBOOKS,
	PATH_OBSERVATION_ROUND,
	PATH_OBSERVATION_VARIABLES,
	PATH_OPERATION,
	PATH_OVERVIEW,
	PATH_PICTURE,
	PATH_PLANNING,
	PATH_TRIALS,
	PATH_VALIDATION,
	removeStartingSlash,
	ROUTING_CREATION,
} from "../../routes";
import ExpEditionGeneralPage from "../../Views/main/experiment/experiments/common/part/general/ExpEditionGeneralPage";
import ExpObsUnitPage from "../../Views/main/experiment/experiments/common/part/observation-unit/ExpObsUnitPage";
import ExpGanttPage from "../../Views/main/experiment/experiments/common/part/planning/gantt/ExpGanttPage";
import NotebooksPage from "../../Views/main/experiment/experiments/common/part/planning/observation-round-edition/NotebooksPage.tsx/NotebooksPage";
import ObsRoundEditionGeneralPage from "../../Views/main/experiment/experiments/common/part/planning/observation-round-edition/ObsRoundEditionGeneralPage/ObsRoundEditionGeneralPage";
import ObsRoundEditionPage from "../../Views/main/experiment/experiments/common/part/planning/observation-round-edition/ObsRoundEditionPage";
import ExpVarietyListPage from "../../Views/main/experiment/experiments/common/part/variety-list/ExpVarietyListPage";
import TrialCreationWizardPage from "../../Views/main/experiment/trial/creation/TrialCreationWizardPage";
import TrialAnalysisPage from "../../Views/main/experiment/trial/edition/data/Analysis/TrialAnalysisPage";
import TrialOverviewPage from "../../Views/main/experiment/trial/edition/data/overview/TrialOverviewPage";
import TrialDataPage from "../../Views/main/experiment/trial/edition/data/TrialDataPage";
import TrialValidationPage from "../../Views/main/experiment/trial/edition/data/Validation/TrialValidationPage";
import TrialLocationPage from "../../Views/main/experiment/trial/edition/location/TrialLocationPage";
import ExpObsRoundPage from "../../Views/main/experiment/experiments/common/part/planning/observation-round/ExpObsRoundPage";
import ExpOperationsPage from "../../Views/main/experiment/experiments/common/part/planning/operations/ExpOperationPage";
import ExpPlanningPage from "../../Views/main/experiment/experiments/common/part/planning/ExpPlanningPage";
import TrialEditionPage from "../../Views/main/experiment/trial/edition/TrialEditionPage";
import TrialPicturePage from "../../Views/main/experiment/trial/edition/data/Picture/TrialPicturePage";
import TrialSynthesisPage from "App/Views/main/experiment/trial/edition/synthesis/TrialSynthesisPage";

export const getTrialPath = (expId: string) =>
	`${PATH_EXPERIMENTS}${PATH_TRIALS}/${expId}`;
export function trialRouter(): RouteObject {
	return {
		path: removeStartingSlash(PATH_TRIALS),
		children: [
			{
				path: ROUTING_CREATION + PARAM_TRIAL_ID, // if trial already exist
				element: <TrialCreationWizardPage />,
			},
			{
				path: ROUTING_CREATION, // if new trial
				element: <TrialCreationWizardPage />,
			},
			{
				path: removeStartingSlash(PARAM_TRIAL_ID),
				element: <TrialEditionPage />,
				children: [
					{
						path: removeStartingSlash(PATH_GENERAL),
						element: <ExpEditionGeneralPage />,
					},
					{
						path: removeStartingSlash(PATH_OBSERVATION_VARIABLES),
						element: <ExpObsUnitPage />,
					},
					{
						path: removeStartingSlash(PATH_GERMPLASM),
						element: <ExpVarietyListPage />,
					},
					{
						path: removeStartingSlash(PATH_LOCATION),
						element: <TrialLocationPage />,
					},
					{
						path: removeStartingSlash(PATH_PLANNING),
						element: <ExpPlanningPage />,
						children: [
							{
								index: true,
								path: removeStartingSlash(PATH_GANTT),
								element: <ExpGanttPage />,
							},
							{
								path: removeStartingSlash(PATH_OPERATION),
								element: <ExpOperationsPage />,
							},
							{
								path: removeStartingSlash(
									PATH_OBSERVATION_ROUND,
								),
								element: <ExpObsRoundPage />,
							},
						],
					},
					{
						path: removeStartingSlash(PATH_DATA),
						element: <TrialDataPage />,
						children: [
							{
								path: removeStartingSlash(PATH_OVERVIEW),
								element: <TrialOverviewPage />,
							},
							{
								path: removeStartingSlash(PATH_VALIDATION),
								element: <TrialValidationPage />,
							},
							{
								path: removeStartingSlash(PATH_ANALYSIS),
								element: <TrialAnalysisPage />,
							},
							{
								path: removeStartingSlash(PATH_PICTURE),

								element: <TrialPicturePage />,
							},
						],
					},
					{
						path: removeStartingSlash(PATH_SYNTHESIS),
						element: <TrialSynthesisPage />,
					},
				],
			},
			{
				path: removeStartingSlash(
					PARAM_TRIAL_ID +
						PATH_PLANNING +
						PATH_OBSERVATION_ROUND +
						PARAM_OBSERVATION_ROUND_ID,
				),
				element: <ObsRoundEditionPage />,
				children: [
					{
						path: removeStartingSlash(PATH_GENERAL),
						element: <ObsRoundEditionGeneralPage />,
					},
					{
						path: removeStartingSlash(PATH_NOTEBOOKS),
						element: <NotebooksPage />,
					},
				],
			},
		],
	};
}
