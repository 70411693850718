import React, { useMemo, useState } from "react";

import { ITemplateObsCoreSerialized } from "shared-type";

import { DIC, EDIC_KEY } from "../../../../../../dictionary";
import TextSearchBar from "../../../../../base-components/searchBar/TextSearchBar";
import TemplateObservationCard from "stories/base-components/Card/mostUsed/TemplateObservationCard";

import "./ECWTemplateObsList.scss";

interface ECWTemplateObsListProps {
	setTemplateObsId: (templateObsId: string | undefined) => void;
	templateObsList: ITemplateObsCoreSerialized[];
}

export default function ECWTemplateObsList(props: ECWTemplateObsListProps) {
	const [filter, setFilter] = useState("");

	const filteredTemplateObsList = useMemo(
		() =>
			props.templateObsList.filter((elt) => {
				// Filter to review to allow fuzzy search everywhere.
				// Remove raw I18N from this filter !
				return elt.name.en
					.toLowerCase()
					.startsWith(filter.toLowerCase());
			}),
		[filter, props.templateObsList],
	);

	return (
		<div className="ECWTemplateObsList-body take-remaining-space flex-column">
			<h1>{DIC(EDIC_KEY.OBSERVATION_TEMPLATE)}</h1>
			{props.templateObsList.length >= 10 && (
				<TextSearchBar
					onChange={(newFilter) => {
						setFilter(newFilter);
					}}
				/>
			)}
			<div className="ECWTemplateObsList-card-list">
				{filteredTemplateObsList.map((elt) => (
					<TemplateObservationCard
						onClick={() => props.setTemplateObsId(elt._id)}
						templateObservation={elt}
						key={elt._id}
					/>
				))}
			</div>
		</div>
	);
}
