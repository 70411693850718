import { AxiosInstance } from "axios";

import {
	ActionRequest,
	ResourceRequest,
	ChartRequest,
	DictionaryRequest,
	EnvVariableRequest,
	TrialRequest,
	GenotypeRequest,
	GrowingAreaRequest,
	LotRequest,
	ObservationRoundRequest,
	TaskRequest,
	TemplateObsRequest,
	UserRequest,
	VariableRequest,
	NotebookRequest,
	NotationRequest,
	VariableGroupRequest,
	ExpLevelCompletionRequest,
	TenantRequest,
	MarketSegmentRequest,
	METRequest,
	OpTaskRequest,
} from "./request";
import { RequestConfig } from "./request-config";
import { TagRequest } from "./request/api-attachment/tag-request";
import { ExpGanttRequest } from "./request/api-experiment/exp-gantt-request";
import { BackupRequest } from "./request/api-user/backup-request";
import { CredentialRequest } from "./request/api-user/credential-request";
import { RequestNoHostFound } from "./RequestNoHostFound";

export class RequestProvider {
	public readonly axios: AxiosInstance;
	public readonly config: RequestConfig;

	// Action
	private _action?: ActionRequest;
	private _envVariable?: EnvVariableRequest;
	private _task?: TaskRequest;

	// Attachment
	private _resource?: ResourceRequest;
	private _tag?: TagRequest;

	// Core
	private _variable?: VariableRequest;
	private _templateObs?: TemplateObsRequest;
	private _variableGroup?: VariableGroupRequest;

	// Experiment
	private _trial?: TrialRequest;
	private _met?: METRequest;
	private _observationRound?: ObservationRoundRequest;
	private _opTask?: OpTaskRequest;
	private _notebook?: NotebookRequest;
	private _notation?: NotationRequest;
	private _expLevelCompletion?: ExpLevelCompletionRequest;
	private _expGantt?: ExpGanttRequest;

	// Germplasm
	private _genotype?: GenotypeRequest;
	private _lot?: LotRequest;

	// Growing Area
	private _growingArea?: GrowingAreaRequest;
	private _marketSegment?: MarketSegmentRequest;

	// Internationalization
	private _dictionary?: DictionaryRequest;

	// User
	private _user?: UserRequest;
	private _backup?: BackupRequest;
	private _credential?: CredentialRequest;

	// Chart
	private _chart?: ChartRequest;

	// Tenant
	private _tenant?: TenantRequest;

	constructor(axios: AxiosInstance, config: RequestConfig) {
		this.axios = axios;
		this.config = config;

		this.initAPIAction(config.hostApiAction);
		this.initAPIAttachment(config.hostApiAttachment);
		this.initAPICore(config.hostApiCore);
		this.initAPIExperiment(config.hostApiExperiment);
		this.initAPIGermplasm(config.hostApiGermplasm);
		this.initAPIGrowingArea(config.hostApiGrowingArea);
		this.initAPIInternationalization(config.hostApiInternationalization);
		this.initAPIUser(config.hostApiUser);
		this.initAPIChart(config.hostApiChart);
		this.initAPITenant(config.hostApiTenant);
	}

	private initAPIAction(hostAction: string | undefined) {
		if (!hostAction) return;

		this._action = new ActionRequest(this.axios, hostAction);
		this._envVariable = new EnvVariableRequest(this.axios, hostAction);
		this._task = new TaskRequest(this.axios, hostAction);
	}

	private initAPIAttachment(hostAttachment: string | undefined) {
		if (!hostAttachment) return;

		this._resource = new ResourceRequest(this.axios, hostAttachment);
		this._tag = new TagRequest(this.axios, hostAttachment);
	}

	private initAPICore(hostCore: string | undefined) {
		if (!hostCore) return;

		this._variable = new VariableRequest(this.axios, hostCore);
		this._templateObs = new TemplateObsRequest(this.axios, hostCore);
		this._variableGroup = new VariableGroupRequest(this.axios, hostCore);
	}

	private initAPIExperiment(hostExperiment: string | undefined) {
		if (!hostExperiment) return;

		this._trial = new TrialRequest(this.axios, hostExperiment);
		this._met = new METRequest(this.axios, hostExperiment);
		this._observationRound = new ObservationRoundRequest(
			this.axios,
			hostExperiment
		);
		this._notebook = new NotebookRequest(this.axios, hostExperiment);
		this._opTask = new OpTaskRequest(this.axios, hostExperiment);
		this._notation = new NotationRequest(this.axios, hostExperiment);
		this._expLevelCompletion = new ExpLevelCompletionRequest(
			this.axios,
			hostExperiment
		);
		this._expGantt = new ExpGanttRequest(this.axios, hostExperiment);
	}

	private initAPIGermplasm(hostGermplasm: string | undefined) {
		if (!hostGermplasm) return;

		this._genotype = new GenotypeRequest(this.axios, hostGermplasm);
		this._lot = new LotRequest(this.axios, hostGermplasm);
	}

	private initAPIGrowingArea(hostGrowingArea: string | undefined) {
		if (!hostGrowingArea) return;

		this._growingArea = new GrowingAreaRequest(this.axios, hostGrowingArea);
		this._marketSegment = new MarketSegmentRequest(this.axios, hostGrowingArea);
	}

	private initAPIInternationalization(hostI18n: string | undefined) {
		if (!hostI18n) return;

		this._dictionary = new DictionaryRequest(this.axios, hostI18n);
	}

	private initAPIUser(hostUser: string | undefined) {
		if (!hostUser) return;

		this._user = new UserRequest(this.axios, hostUser);
		this._credential = new CredentialRequest(this.axios, hostUser);
		this._backup = new BackupRequest(this.axios, hostUser);
	}

	private initAPIChart(hostChart: string | undefined) {
		if (!hostChart) return;

		this._chart = new ChartRequest(this.axios, hostChart);
	}

	private initAPITenant(hostTenant: string | undefined) {
		if (!hostTenant) return;

		this._tenant = new TenantRequest(this.axios, hostTenant);
	}

	private getRequestNode<T>(
		node: keyof RequestProvider,
		errorMessage: string
	): T {
		if (this[node] === undefined) throw new RequestNoHostFound(errorMessage);
		return this[node] as T;
	}

	// Action
	get action() {
		return this.getRequestNode<ActionRequest>(
			"_action" as keyof RequestProvider,
			"No host found for api action"
		);
	}
	get envVariable() {
		return this.getRequestNode<EnvVariableRequest>(
			"_envVariable" as keyof RequestProvider,
			"No host found for api action"
		);
	}
	get task() {
		return this.getRequestNode<TaskRequest>(
			"_task" as keyof RequestProvider,
			"No host found for api action"
		);
	}

	// Attachment
	get resource() {
		return this.getRequestNode<ResourceRequest>(
			"_resource" as keyof RequestProvider,
			"No host found for api attachment"
		);
	}

	get tag() {
		return this.getRequestNode<TagRequest>(
			"_tag" as keyof RequestProvider,
			"No host found for api attachment"
		);
	}

	// Core
	get variable() {
		return this.getRequestNode<VariableRequest>(
			"_variable" as keyof RequestProvider,
			"No host found for api core"
		);
	}
	get templateObs() {
		return this.getRequestNode<TemplateObsRequest>(
			"_templateObs" as keyof RequestProvider,
			"No host found for api core"
		);
	}

	get variableGroup() {
		return this.getRequestNode<VariableGroupRequest>(
			"_variableGroup" as keyof RequestProvider,
			"No host found for api core"
		);
	}

	// Experiment
	get trial() {
		return this.getRequestNode<TrialRequest>(
			"_trial" as keyof RequestProvider,
			"No host found for api experiment"
		);
	}

	get met() {
		return this.getRequestNode<METRequest>(
			"_met" as keyof RequestProvider,
			"No host found for api experiment"
		);
	}

	get observationRound() {
		return this.getRequestNode<ObservationRoundRequest>(
			"_observationRound" as keyof RequestProvider,
			"No host found for api experiment"
		);
	}
	get opTask() {
		return this.getRequestNode<OpTaskRequest>(
			"_opTask" as keyof RequestProvider,
			"No host found for api experiment"
		);
	}

	get notebook() {
		return this.getRequestNode<NotebookRequest>(
			"_notebook" as keyof RequestProvider,
			"No host found for api experiment"
		);
	}

	get notation() {
		return this.getRequestNode<NotationRequest>(
			"_notation" as keyof RequestProvider,
			"No host found for api experiment"
		);
	}

	get expLevelCompletion() {
		return this.getRequestNode<ExpLevelCompletionRequest>(
			"_expLevelCompletion" as keyof RequestProvider,
			"No host found for api experiment"
		);
	}

	get expGantt() {
		return this.getRequestNode<ExpGanttRequest>(
			"_expGantt" as keyof RequestProvider,
			"No host found for api experiment"
		);
	}

	// Germplasm
	get genotype() {
		return this.getRequestNode<GenotypeRequest>(
			"_genotype" as keyof RequestProvider,
			"No host found for api germplasm"
		);
	}
	get lot() {
		return this.getRequestNode<LotRequest>(
			"_lot" as keyof RequestProvider,
			"No host found for api germplasm"
		);
	}

	// Growing Area
	get growingArea() {
		return this.getRequestNode<GrowingAreaRequest>(
			"_growingArea" as keyof RequestProvider,
			"No host found for api growing area"
		);
	}
	get marketSegment() {
		return this.getRequestNode<MarketSegmentRequest>(
			"_marketSegment" as keyof RequestProvider,
			"No host found for api growing area"
		);
	}

	// Internationalization
	get dictionary() {
		return this.getRequestNode<DictionaryRequest>(
			"_dictionary" as keyof RequestProvider,
			"No host found for api internationalization"
		);
	}

	// User
	get user() {
		return this.getRequestNode<UserRequest>(
			"_user" as keyof RequestProvider,
			"No host found for api user"
		);
	}
	get credential() {
		return this.getRequestNode<CredentialRequest>(
			"_credential" as keyof RequestProvider,
			"No host found for api user"
		);
	}
	get backup() {
		return this.getRequestNode<BackupRequest>(
			"_backup" as keyof RequestProvider,
			"No host found for api user"
		);
	}

	// Chart

	get chart() {
		return this.getRequestNode<ChartRequest>(
			"_chart" as keyof RequestProvider,
			"No host found for api chart"
		);
	}

	// Tenant
	get tenant() {
		return this.getRequestNode<TenantRequest>(
			"_tenant" as keyof RequestProvider,
			"No host found for api tenant"
		);
	}
}
