import React, { CSSProperties } from "react";

import { CaptainSilverTiger, Landscape } from "../../../../../assets";

import { DIC, EDIC_KEY } from "App/dictionary";
import {
	getDicTESLocation,
	getDicTESScope,
	getDicTESType,
} from "App/dictionary/dictionary-key-getter";
import ImgBannerCard from "../../../base-components/card/img-banner-card/ImgBannerCard";
import TitledTag from "../../../base-components/tag/titled-tag/TitledTag";
import { ITemplateExperimentStructureCardProps } from "./TemplateExperimentStructureCard";

import "./TemplateExperimentStructureBanner.scss";

export interface ITemplateExperimentStructureBannerProps
	extends ITemplateExperimentStructureCardProps {
	style?: CSSProperties;
	className?: string;
}

export default function TemplateExperimentStructureBanner(
	props: ITemplateExperimentStructureBannerProps,
) {
	return (
		<ImgBannerCard
			onClick={props.onClick}
			disabledClick={props.disabledClick}
			className={props.className}
			style={{ ...props.style }}
			sideBanner={{ imageSrc: CaptainSilverTiger, logoSrc: Landscape }}
		>
			<div className="TemplateExperimentStructureBanner-container flex-column">
				<div className="TemplateExperimentStructureBanner-tags">
					<TitledTag
						title={DIC(EDIC_KEY.SCOPE)}
						tagValue={getDicTESScope(
							props.templateExperimentStructure.expScope,
						)}
					/>
				</div>

				<h1 className="TemplateExperimentStructureBanner-title clamped-text">
					{props.templateExperimentStructure.name.en}
				</h1>
				<h2 className="TemplateExperimentStructureBanner-description clamped-text">
					{props.templateExperimentStructure.description}
				</h2>

				<div className="TemplateExperimentStructureBanner-tags">
					<TitledTag
						title={DIC(EDIC_KEY.TYPE)}
						tagValue={getDicTESType(
							props.templateExperimentStructure.expType,
						)}
					/>
					<TitledTag
						title={DIC(EDIC_KEY.LOCATION)}
						tagValue={getDicTESLocation(
							props.templateExperimentStructure.location,
						)}
					/>
				</div>
			</div>
		</ImgBannerCard>
	);
}
