import { yupResolver } from "@hookform/resolvers/yup";
import SearchIcon from "@mui/icons-material/Search";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import {
	EntitySerializer,
	formatString,
	isObsRoundTask,
	isObsRoundTaskSerialized,
	isOperationTaskSerialized,
	removeDuplicates,
} from "common";
import {
	ETaskType,
	IObservationRound,
	IOperation,
	IOpTask,
	IOpTaskSerialized,
} from "shared-type";

import { DIC, EDIC_KEY } from "../../../../dictionary";
import useAppSnackbar from "../../../../hooks/useAppSnackbar";
import {
	apiObsRound,
	obsRoundCustomHook,
} from "../../../../redux/observation-round/obs-round.api";
import {
	apiOperation,
	operationCustomHook,
} from "../../../../redux/operation/operation.api";
import { ApiErrorSnackbar } from "../../../../redux/utils/api-error-snackbar/ApiErrorSnackbar";
import { ReduxApiError } from "../../../../redux/utils/errors";
import DorianeButton from "../../../base-components/button/DorianeButton";
import { CustomCheckbox } from "../../../base-components/checkbox/CustomCheckbox";
import TopMenu from "../../../base-components/menu/top-menu/TopMenu";
import DorianeModal from "../../../base-components/modal/DorianeModal";
import { EPaddingSize } from "../../../base-components/placement/box/Box";
import FlexBox, {
	EGapSize,
} from "../../../base-components/placement/flex-box/FlexBox";
import TextSearchBar from "../../../base-components/searchBar/TextSearchBar";
import {
	obsRoundObjectToForm,
	obsRoundValidator,
} from "../../../form/task/observation-round";
import ObservationRoundForm, {
	IObservationRoundForm,
} from "../../../form/task/observation-round/ObservationRoundForm";
import {
	operationObjectToForm,
	operationValidator,
} from "../../../form/task/operation";
import OperationForm, {
	IOperationForm,
} from "../../../form/task/operation/OperationForm";
import ObservationRoundCard from "../../observation-round/card/ObservationRoundCard";
import OperationCard from "../../operation/card/OperationCard";

import "./TaskTemplateModal.scss";

function useTaskTemplateModalDeps(
	taskType: ETaskType,
	metId: string,
	currentTemplateId?: string,
) {
	const templateListFetchMethod = useMemo(() => {
		return taskType === ETaskType.OBSERVATION_ROUND
			? obsRoundCustomHook.useGetObsRoundsByExpIdQueryDeserialized
			: operationCustomHook.useGetOperationsByExpIdQueryDeserialized;
	}, [taskType]);

	const currentTemplateFetchMethod = useMemo(() => {
		return taskType === ETaskType.OBSERVATION_ROUND
			? apiObsRound.useGetObservationRoundByIdQuery
			: apiOperation.useGetOperationByIdQuery;
	}, [taskType]);

	const { data: templateList } = templateListFetchMethod("MET", metId);
	const { data: currentTemplate } = currentTemplateFetchMethod(
		currentTemplateId ?? skipToken,
	);

	const [createTaskFromTemplate] =
		taskType === ETaskType.OBSERVATION_ROUND
			? apiObsRound.useCreateManyObsRoundFromTemplatesMutation()
			: apiOperation.useCreateManyOperationFromTemplatesMutation();

	const operationForm = useForm<IOperationForm>({
		resolver: yupResolver(operationValidator as any),
	});
	const obsRoundForm = useForm<IObservationRoundForm>({
		resolver: yupResolver(obsRoundValidator as any),
	});

	return {
		templateList,
		currentTemplate: currentTemplate as IOpTaskSerialized,
		operationForm,
		obsRoundForm,
		createTaskFromTemplate,
	};
}

interface TaskTemplateModalProps {
	onClose: () => void;
	metId: string;
	trialId: string;
	taskType: ETaskType;
}

export default function TaskTemplateModal(props: TaskTemplateModalProps) {
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();

	const [filter, setFilter] = useState("");
	const [currentTemplateId, setCurrentTemplateId] = useState<string>();
	const [selectedTemplates, setSelectedTemplate] = useState<string[]>([]);

	const {
		templateList,
		currentTemplate,
		operationForm,
		obsRoundForm,
		createTaskFromTemplate,
	} = useTaskTemplateModalDeps(
		props.taskType,
		props.metId,
		currentTemplateId,
	);

	const filteredTemplates = useMemo(() => {
		if (!templateList) return [];
		return (templateList as IOpTask[]).filter((elt) => {
			// Filter to review to allow fuzzy search everywhere.
			return elt.name.toLowerCase().includes(filter.toLowerCase());
		});
	}, [filter, templateList]);

	const handleTemplateCheck = (checked: boolean, templateId: string) => {
		if (checked) {
			setSelectedTemplate([...selectedTemplates, templateId]);
		} else {
			// we remove from list
			const filteredTemplate = selectedTemplates.filter(
				(template) => template !== templateId,
			);
			setSelectedTemplate(filteredTemplate);
		}
	};

	const handleAddAll = () => {
		const filteredTemplateId = filteredTemplates.map((elt) => elt._id);
		const newTemplatesChecked = removeDuplicates([
			...selectedTemplates,
			...filteredTemplateId,
		]);
		setSelectedTemplate(newTemplatesChecked);
	};

	const selectionDisplay = useMemo(() => {
		return (
			<FlexBox
				className="TaskTemplateModal-box"
				paddingRight={EPaddingSize.MEDIUM}
				paddingLeft={EPaddingSize.MEDIUM}
			>{`${selectedTemplates.length} selected`}</FlexBox>
		);
	}, [selectedTemplates.length]);

	const formDisplay = useMemo(() => {
		if (props.taskType === ETaskType.OBSERVATION_ROUND) {
			return (
				<ObservationRoundForm
					isTemplate
					form={obsRoundForm}
					mode="used"
				/>
			);
		}
		return <OperationForm isTemplate mode={"used"} form={operationForm} />;
	}, [obsRoundForm, operationForm, props.taskType]);

	const cardDisplayMethod = useCallback(
		(elt: IOperation | IObservationRound) => {
			if (isObsRoundTask(elt)) {
				return (
					<ObservationRoundCard
						isTemplate={true}
						observationRound={elt}
						isSelected={currentTemplateId === elt._id}
						onClick={() => setCurrentTemplateId(elt._id)}
					/>
				);
			}
			return (
				<OperationCard
					isTemplate={true}
					operation={elt}
					isSelected={currentTemplateId === elt._id}
					onClick={() => setCurrentTemplateId(elt._id)}
				/>
			);
		},
		[currentTemplateId],
	);

	async function handleCreate() {
		await createTaskFromTemplate({
			taskTemplateIds: selectedTemplates,
			trialId: props.trialId,
		})
			.unwrap()
			.then(() => {
				props.onClose();
				enqueueSnackbarSuccess(
					formatString(
						DIC(EDIC_KEY.ENTITY_CREATED),
						props.taskType === ETaskType.OBSERVATION_ROUND
							? DIC(EDIC_KEY.OBSERVATION_ROUND)
							: DIC(EDIC_KEY.OPERATION),
					),
				);
			})
			.catch((err: ReduxApiError) => {
				console.error(err);
				enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
			});
	}

	useEffect(() => {
		if (!currentTemplate) {
			obsRoundForm.reset();
			operationForm.reset();
			return;
		}

		if (isObsRoundTaskSerialized(currentTemplate)) {
			obsRoundForm.reset(
				obsRoundObjectToForm(
					EntitySerializer.deserialize<IObservationRound>(
						currentTemplate,
					),
				),
			);
		} else if (isOperationTaskSerialized(currentTemplate)) {
			operationForm.reset(
				operationObjectToForm(
					EntitySerializer.deserialize<IOperation>(currentTemplate),
				),
			);
		}
	}, [currentTemplate, obsRoundForm, operationForm, props.taskType]);

	return (
		<DorianeModal
			onClose={() => {
				props.onClose();
			}}
			title={DIC(EDIC_KEY.SELECT_TEMPLATE)}
			width={"60%"}
			height={"90%"}
		>
			<TopMenu
				additionalButton={selectionDisplay}
				createButton={{
					onClick: handleCreate,
				}}
			/>
			<FlexBox overflow={"auto"} takeRemainingSpace>
				<FlexBox
					className="TaskTemplateModal-left"
					flexDirection={"column"}
				>
					<FlexBox
						flexDirection={"column"}
						padding={EPaddingSize.SMALL}
						className="TaskTemplateModal-search"
						gap={EGapSize.MEDIUM}
					>
						<TextSearchBar
							onChange={(newFilter) => {
								setFilter(newFilter);
							}}
						/>
						<FlexBox
							className="TaskTemplateModal-box"
							padding={EPaddingSize.MEDIUM}
							justifyContent={"space-between"}
						>
							<FlexBox
								alignItems={"center"}
								gap={EGapSize.MEDIUM}
							>
								<SearchIcon sx={{ color: "#008DC0" }} />
								{`${filteredTemplates.length} templates found`}
							</FlexBox>
							<DorianeButton
								onClick={() => handleAddAll()}
								dorianeStyle="primary"
								style={{ backgroundColor: "#008DC0" }}
							>
								{DIC(EDIC_KEY.ADD_ALL)}
							</DorianeButton>
						</FlexBox>
					</FlexBox>
					<FlexBox
						takeRemainingSpace
						flexDirection={"column"}
						gap={EGapSize.SMALL}
						overflow={"auto"}
						padding={EPaddingSize.MEDIUM}
					>
						{filteredTemplates.map((elt) => (
							<FlexBox
								alignItems={"center"}
								key={elt._id}
								gap={EGapSize.MEDIUM}
							>
								<div>
									<CustomCheckbox
										checked={selectedTemplates.includes(
											elt._id,
										)}
										onChange={(event, checked) =>
											handleTemplateCheck(
												checked,
												elt._id,
											)
										}
									/>
								</div>
								{cardDisplayMethod(elt)}
							</FlexBox>
						))}
					</FlexBox>
				</FlexBox>
				<FlexBox className="TaskTemplateModal-right" overflow={"auto"}>
					{currentTemplate && formDisplay}
				</FlexBox>
			</FlexBox>
		</DorianeModal>
	);
}
