import { EExperimentStatus, IMET, ITrial } from "shared-type";

import { EExpConclusionStatus, IExpConclusionForm } from "./ExpConclusionForm";

export function expConclusionFormToObject(form: IExpConclusionForm): {
	status: EExpConclusionStatus;
	conclusion?: string;
} {
	return {
		conclusion: form.conclusion,
		// Must be defined
		status: form.status,
	};
}

export function expConclusionObjectToForm(
	experiment: Partial<ITrial | IMET>,
): Partial<IExpConclusionForm> {
	const result: Partial<IExpConclusionForm> = {
		conclusion: experiment.conclusion,
	};
	if (
		experiment.status === EExperimentStatus.NOT_VALIDATED ||
		experiment.status === EExperimentStatus.VALIDATED
	) {
		result.status = experiment.status;
	}
	return result;
}
