import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ETaskType, ExperimentType, IObservationRound } from "shared-type";

import DorianeButton from "App/components/base-components/button/DorianeButton";
import ObservationRoundCard from "App/components/task/observation-round/card/ObservationRoundCard";
import { DIC, EDIC_KEY } from "App/dictionary";
import { ECWAction } from "App/redux/ECW/ECW.slice";
import { obsRoundCustomHook } from "App/redux/observation-round/obs-round.api";
import { AppDispatch } from "App/store";
import TopMenu from "../../../../../../../../components/base-components/menu/top-menu/TopMenu";
import { apiTrial } from "../../../../../../../../redux/experiment/trial/trial.api";
import TaskTemplateModal from "../../../../../../../../components/task/modal/template/TaskTemplateModal";
import FlexBox, {
	EGapSize,
} from "../../../../../../../../components/base-components/placement/flex-box/FlexBox";
import { EPaddingSize } from "../../../../../../../../components/base-components/placement/box/Box";

interface IObsRoundGrouped {
	date: Date;
	obsRound: IObservationRound[];
}

function useExpObsRoundsGroupByDate(expType: ExperimentType, expId?: string) {
	const { data: obsRoundDeserialized } =
		obsRoundCustomHook.useGetObsRoundsByExpIdQueryDeserialized(
			expType,
			expId,
		);

	const groupByDateObsRound = useMemo(() => {
		if (!obsRoundDeserialized) return [];

		const groups: Record<string, IObservationRound[]> =
			obsRoundDeserialized.reduce(
				(groups: Record<string, IObservationRound[]>, obsRound) => {
					const date = obsRound.startDate.toDateString();
					if (!groups[date]) {
						groups[date] = [];
					}
					groups[date].push(obsRound);
					return groups;
				},
				{},
			);

		const groupedObsRound: IObsRoundGrouped[] = Object.keys(groups).map(
			(dateString) => {
				return {
					date: new Date(dateString),
					obsRound: groups[dateString],
				};
			},
		);

		return groupedObsRound.sort(
			(obsA, obsB) => obsA.date.getTime() - obsB.date.getTime(),
		);
	}, [obsRoundDeserialized]);

	return groupByDateObsRound;
}

export default function ExpObsRoundPage() {
	const dispatch = useDispatch<AppDispatch>();
	const nav = useNavigate();
	const { trialId, metId } = useParams();

	const isMET = useMemo(() => metId !== undefined, [metId]);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [templateOpen, setTemplateOpen] = useState<boolean>(false);

	const { data: remoteTrial } = apiTrial.useGetTrialByIdQuery(
		trialId ?? skipToken,
	);

	const obsRoundListGroupedByDate = useExpObsRoundsGroupByDate(
		isMET ? "MET" : "Trial",
		trialId ?? metId,
	);

	const handleCreateFromScratch = useCallback(() => {
		dispatch(ECWAction.setCreateObsRoundOpen(true));
		setAnchorEl(null);
	}, [dispatch]);

	const handleCreateFromTemplate = useCallback(() => {
		setTemplateOpen(true);
		dispatch(ECWAction.setCreateObsRoundOpen(false));
		setAnchorEl(null);
	}, [dispatch]);

	const handleCreateClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			if (remoteTrial?.metId !== undefined) {
				setAnchorEl(event.currentTarget);
			} else {
				handleCreateFromScratch();
			}
		},
		[handleCreateFromScratch, remoteTrial?.metId],
	);

	const createNewObsRoundButton = useMemo(() => {
		return (
			<div>
				<DorianeButton
					dorianeStyle="primary"
					onClick={(event) => {
						handleCreateClick(event);
					}}
				>
					{`${DIC(
						isMET
							? EDIC_KEY.NEW_OBSERVATION_TEMPLATE
							: EDIC_KEY.NEW_OBSERVATION,
					)} +`}
				</DorianeButton>
				{remoteTrial?.metId && (
					<Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={() => setAnchorEl(null)}
					>
						<MenuItem onClick={handleCreateFromTemplate}>
							{DIC(EDIC_KEY.FROM_TEMPLATE)}
						</MenuItem>
						<MenuItem onClick={handleCreateFromScratch}>
							{DIC(EDIC_KEY.FROM_SCRATCH)}
						</MenuItem>
					</Menu>
				)}
			</div>
		);
	}, [
		anchorEl,
		handleCreateClick,
		handleCreateFromScratch,
		handleCreateFromTemplate,
		isMET,
		remoteTrial?.metId,
	]);

	useEffect(() => {
		return () => {
			dispatch(ECWAction.setCreateObsRoundOpen(false));
		};
	}, [dispatch]);

	return (
		<>
			<FlexBox fullParentSize flexDirection="column">
				<TopMenu additionalButton={createNewObsRoundButton} />
				<FlexBox
					flexDirection="column"
					gap={EGapSize.MEDIUM}
					padding={EPaddingSize.MEDIUM}
					overflow={"auto"}
				>
					{obsRoundListGroupedByDate.map((group, index) => (
						<div key={index}>
							<h2>{group.date.toDateString()}</h2>
							<FlexBox
								flexDirection="column"
								width={"75%"}
								gap={EGapSize.SMALL}
							>
								{group.obsRound.map((elt) => (
									<ObservationRoundCard
										isTemplate={isMET}
										key={elt._id}
										observationRound={elt}
										onClick={() => {
											nav(`./${elt._id}`);
										}}
									/>
								))}
							</FlexBox>
						</div>
					))}
				</FlexBox>
			</FlexBox>
			{templateOpen && remoteTrial?.metId && (
				<TaskTemplateModal
					taskType={ETaskType.OBSERVATION_ROUND}
					metId={remoteTrial.metId}
					trialId={remoteTrial._id}
					onClose={() => setTemplateOpen(false)}
				/>
			)}
		</>
	);
}
