import { createSelector } from "@reduxjs/toolkit";

import { EntitySerializer } from "common";
import {
	EGermplasmLevel,
	IMaterial,
	ITrial,
	NB_MAX_TRIAL_BY_MET,
} from "shared-type";

import { CustomStep } from "App/components/base-components/stepper/DorianeStepper";
import { RootState } from "App/store";
import { IExpGeneralInfo } from "../../components/experiment-wizard/common/types";
import { expGeneralStateObjectToForm } from "../../components/form/experiment/general";

const generalFormInfo = createSelector(
	(state: RootState) => state.trialWizard.generalPart.generalInfo,
	(generalInfo): Partial<IExpGeneralInfo> => {
		return expGeneralStateObjectToForm(generalInfo);
	},
);

const isAddMaterialOpen = (state: RootState): boolean =>
	state.trialWizard.germplasmPart.isAddMaterialOpen;
const isSelectGrowingAreaOpen = (state: RootState): boolean =>
	state.trialWizard.growingAreaPart.isSelectGAOpen;
const isEditGAOpen = (state: RootState): boolean =>
	state.trialWizard.growingAreaPart.isEditGAOpen;
const isCreateObsRoundOpen = (state: RootState): boolean =>
	state.trialWizard.planningPart.isCreateObsRoundOpen;
const isCreateOperationOpen = (state: RootState): boolean =>
	state.trialWizard.planningPart.isCreateOperationOpen;
const isConclusionOpen = (state: RootState): boolean =>
	state.trialWizard.planningPart.isConclusionOpen;
const isSaveModalOpen = (state: RootState): boolean =>
	state.trialWizard.saveModalInfo.isOpen;

const generalInfo = (state: RootState) => state.trialWizard.generalPart;
const comments = (state: RootState) => state.trialWizard.generalPart.comments;

const templateObservationId = (state: RootState) =>
	state.trialWizard.observationPart.templateObsId;

const trialRemote = createSelector(
	(state: RootState) => state.trialWizard.trialRemote,
	(trial) =>
		(trial ? EntitySerializer.deserialize<ITrial>(trial) : undefined),
);
const wizardType = (state: RootState) => state.trialWizard.wizardType;

const selectedMaterials = createSelector(
	(state: RootState): IMaterial[] =>
		state.trialWizard.germplasmPart.selectedMaterials,
	(selectedMaterials) => selectedMaterials.map((elt) => elt.material._id),
);
const germplasmLevel = (state: RootState): EGermplasmLevel =>
	state.trialWizard.germplasmPart.materialType;

const editedOperationId = (state: RootState): string | undefined =>
	state.trialWizard.planningPart.editedOperationId;
const growingAreaId = (state: RootState): string | undefined =>
	state.trialWizard.growingAreaPart.growingAreaId;
const growingAreaInEdition = (state: RootState): string | undefined =>
	state.trialWizard.growingAreaPart.growingAreaInEdition;

const activeStep = (state: RootState): number => state.trialWizard.activeStep;
const activeStepValue = (state: RootState): CustomStep =>
	state.trialWizard.stepList[state.trialWizard.activeStep];

const hasChanges = (state: RootState): boolean => state.trialWizard.hasChanges;
const isPageBannerDisplay = (state: RootState): boolean =>
	state.trialWizard.isPageBannerDisplay;
const isPageFullSize = (state: RootState): boolean =>
	state.trialWizard.isPageFullSize;
const stepList = (state: RootState): CustomStep[] => state.trialWizard.stepList;

const saveModalInfos = (state: RootState) => state.trialWizard.saveModalInfo;

const trialGenerationPart = {
	selectedTrials: createSelector(
		(state: RootState) => state.trialWizard.trialGenerationPart?.trials,
		(trials): Set<number> => {
			return new Set(
				trials
					?.filter((trial) => trial.isSelected)
					.map((trial) => trial.trialId),
			);
		},
	),
	trialSelectedWithoutGA: createSelector(
		(state: RootState) => state.trialWizard.trialGenerationPart?.trials,
		(trials): number[] => {
			const result =
				trials
					?.filter(
						(trial) =>
							trial.isSelected &&
							trial.growingAreaId === undefined,
					)
					.map((trial) => trial.trialId) ?? [];
			return result;
		},
	),
	trialSelectedWithGA: createSelector(
		(state: RootState) => state.trialWizard.trialGenerationPart?.trials,
		(trials): number[] => {
			const result =
				trials
					?.filter(
						(trial) =>
							trial.isSelected &&
							trial.growingAreaId !== undefined,
					)
					.map((trial) => trial.trialId) ?? [];
			return result;
		},
	),
	GAofSelectedTrial: createSelector(
		(state: RootState) => state.trialWizard.trialGenerationPart?.trials,
		(trials): Set<string> => {
			return new Set(
				trials
					?.filter(
						(trial) =>
							trial.growingAreaId !== undefined &&
							trial.isSelected,
					)
					.map((trial) => trial.growingAreaId ?? "impossible"),
			);
		},
	),
	GAOfUnselectedTrial: createSelector(
		(state: RootState) => state.trialWizard.trialGenerationPart?.trials,
		(trials): Set<string> => {
			return new Set(
				trials
					?.filter(
						(trial) =>
							trial.growingAreaId !== undefined &&
							!trial.isSelected,
					)
					.map((trial) => trial.growingAreaId ?? "impossible"),
			);
		},
	),
	isGAModalOpen: (state: RootState) =>
		state.trialWizard.trialGenerationPart.isGASelectionOpen,

	nbTrialRemaining: createSelector(
		(state: RootState) =>
			state.trialWizard.trialGenerationPart.trials.length,
		(nbTrial) => NB_MAX_TRIAL_BY_MET - nbTrial,
	),
	nbOfTrials: (state: RootState) =>
		state.trialWizard.trialGenerationPart.trials.length,
};
const isInit = (state: RootState) => state.trialWizard.isInit;
export const selectECW = {
	isInit,
	generalFormInfo,
	germplasmLevel,
	isAddMaterialOpen,
	isSelectGrowingAreaOpen,
	isEditGAOpen,
	isCreateObsRoundOpen,
	selectedMaterials,
	generalInfo,
	trialRemote,
	templateObservationId,
	activeStep,
	stepList,
	growingAreaId,
	growingAreaInEdition,
	comments,
	hasChanges,
	isConclusionOpen,
	activeStepValue,
	isSaveModalOpen,
	saveModalInfos,
	wizardType,
	trialGenerationPart,
	isCreateOperationOpen,
	isPageBannerDisplay,
	isPageFullSize,
	editedOperationId,
};
