import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { formatString } from "common";
import {
	ICreateVariableGroup,
	IPatchVariableGroup,
	IVarGroupDetailedSerialized,
	IVariableGroupSerialized,
} from "shared-type";

import { DIC, EDIC_KEY } from "../../dictionary";
import { useRequest } from "../../request-provider";
import { defaultApiError, ReduxApiError } from "../utils/errors";

export const apiVariableGroup = createApi({
	reducerPath: "api-variable-group",
	baseQuery: () => ({ data: undefined }),
	keepUnusedDataFor: 30, // global configuration for the api (in second)
	refetchOnMountOrArgChange: 30, // global configuration for the api
	tagTypes: ["Update", "Delete", "Create"],

	endpoints: (build) => ({
		getVarGroupsByTemplateObsId: build.query<
			IVariableGroupSerialized[],
			string | undefined
		>({
			queryFn: async (templateObsId) => {
				if (!templateObsId) {
					return { data: [] };
				}
				try {
					const result =
						await useRequest().variableGroup.getVarGroupsByTemplateObsId(
							templateObsId,
						);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.FETCH).toLowerCase(),
							DIC(EDIC_KEY.VARIABLE_GROUP).toLowerCase(),
						),
					);
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),

		getVariableGroupById: build.query<
			IVariableGroupSerialized | undefined,
			string | undefined
		>({
			queryFn: async (id) => {
				try {
					if (!id) {
						return { data: undefined };
					}
					const result =
						await useRequest().variableGroup.getVariableGroupById(
							id,
						);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch variable group with id : " + id,
					);
				}
			},
			providesTags: (result, error, id) => {
				return [
					{ type: "Delete", id: id },
					{ type: "Update", id: id },
					{ type: "Create", id: id },
				];
			},
		}),

		getVariableGroupDetailsByIds: build.query<
			IVarGroupDetailedSerialized[],
			string[]
		>({
			queryFn: async (ids) => {
				try {
					if (ids.length === 0) {
						return { data: [] };
					}
					const result =
						await useRequest().variableGroup.getVariableGroupDetailedByIds(
							ids,
						);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch variable groups with ids : " + ids,
					);
				}
			},
			providesTags: () => {
				return [
					{ type: "Delete", id: "ALL" },
					{ type: "Update", id: "ALL" },
					{ type: "Create", id: "ALL" },
				];
			},
		}),

		getVariableGroupUsagesById: build.query<
			string[] | undefined,
			string | undefined
		>({
			queryFn: async (id) => {
				try {
					if (!id) {
						return { data: undefined };
					}
					const result =
						await useRequest().variableGroup.getVariableGroupUsagesById(
							id,
						);
					return { data: result.data };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch variable group usages with id : " + id,
					);
				}
			},
			providesTags: (result, error, id) => {
				return [
					{ type: "Delete", id: id },
					{ type: "Update", id: id },
					{ type: "Create", id: id },
				];
			},
		}),

		createVariableGroup: build.mutation<
			IVariableGroupSerialized,
			ICreateVariableGroup
		>({
			queryFn: async (newVarGroup) => {
				let id: string;

				try {
					id = await useRequest()
						.variableGroup.createVariableGroup(newVarGroup)
						.then((resp) => resp.data);
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to create the variable group",
					);
				}
				try {
					const createdVarGroup =
						await useRequest().variableGroup.getVariableGroupById(
							id,
						);
					return {
						data: createdVarGroup.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to get the observation round with id : " + id,
					);
				}
			},
			invalidatesTags: (result, error) => {
				if (error) {
					return [];
				}
				return [
					{ type: "Create", id: result?._id },
					{ type: "Create", id: "ALL" },
				];
			},
		}),

		deleteVarGroupById: build.mutation<void, string | undefined>({
			queryFn: async (id) => {
				if (!id) {
					const error: { error: ReduxApiError } = {
						error: {
							message:
								"Id is required to delete a variable group.",
						},
					};
					return error;
				}

				try {
					const result =
						await useRequest().variableGroup.deleteVarGroupById(id);
					return { data: result.data };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to delete variable group with id : " + id,
					);
				}
			},
			invalidatesTags: (result, error, id) => {
				if (error) {
					return [];
				}
				return [
					{ type: "Delete", id: id },
					{ type: "Delete", id: "ALL" },
				];
			},
		}),

		updateVarGroupById: build.mutation<
			void,
			{ id: string; update: IPatchVariableGroup }
		>({
			queryFn: async (arg) => {
				try {
					const result = await useRequest()
						.variableGroup.updateVarGroupById(arg.id, arg.update)
						.then((resp) => resp.data);
					return { data: result };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to update variable group",
					);
				}
			},
			invalidatesTags: (result, error, arg) => {
				if (error) {
					return [];
				}
				return [
					{ type: "Update", id: arg.id },
					{ type: "Update", id: "ALL" },
				];
			},
		}),
	}),
});
