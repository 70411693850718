import { styled } from "@mui/material/styles";
import { Button as MUIButton } from "@mui/material";

import Colors from "stories/constants/Colors/Colors";
import { IButton, IButtonType } from "./Button";
import Size from "stories/constants/Size/Size";

export const types = {
	primary: {
		colorTheme: "primary",
		variant: "contained",
		textColor: Colors.neutral10,
		backgroundColor: Colors.accent60,
		border: Size.borderStroke + " solid " + Colors.accent40,
		hoverColor: Colors.accent40,
		hoverTextColor: Colors.neutral10,
		hoverIconButtonColor: Colors.neutral10,
		hoverBorder: Size.borderStroke + " solid " + Colors.accent20,
		activeColor: Colors.accent70,
		activeTextColor: Colors.neutral10,
		activeIconButtonColor: Colors.neutral10,
		activeBorder: Size.borderStroke + " solid " + Colors.accent60,
		disabledColor: Colors.neutral30,
		disabledBorder: Size.borderStroke + " solid " + Colors.neutral30,
		disabledTextColor: Colors.neutral60,
	},
	secondary: {
		colorTheme: "secondary",
		variant: "outlined",
		textColor: Colors.primary60,
		backgroundColor: "unset",
		border: Size.borderStroke + " solid " + Colors.primary20,
		hoverColor: "unset",
		hoverTextColor: Colors.primary40,
		hoverIconButtonColor: Colors.primary40,
		hoverBorder: Size.borderStroke + " solid " + Colors.primary40,
		activeColor: "unset",
		activeTextColor: Colors.primary70,
		activeIconButtonColor: Colors.primary70,
		activeBorder: Size.borderStroke + " solid " + Colors.primary70,
		disabledColor: "unset",
		disabledBorder: Size.borderStroke + " solid " + Colors.neutral40,
		disabledTextColor: Colors.neutral40,
	},
	tertiary: {
		colorTheme: "secondary",
		variant: "text",
		textColor: Colors.primary60,
		backgroundColor: "unset",
		border: "none",
		hoverColor: "unset",
		hoverTextColor: undefined,
		hoverIconButtonColor: Colors.primary40,
		hoverBorder: "none",
		hoverUnderline: true,
		activeColor: "unset",
		activeTextColor: Colors.primary60,
		activeIconButtonColor: Colors.primary70,
		activeBorder: "none",
		disabledColor: "unset",
		disabledBorder: "none",
		disabledTextColor: Colors.neutral60,
	},
	info: {
		colorTheme: "info",
		variant: "contained",
		textColor: Colors.neutral10,
		backgroundColor: Colors.active70,
		border: Size.borderStroke + " solid " + Colors.active60,
		hoverColor: Colors.active60,
		hoverIconButtonColor: Colors.neutral10,
		hoverTextColor: Colors.neutral10,
		hoverBorder: Size.borderStroke + " solid " + Colors.active40,
		activeColor: Colors.active80,
		activeTextColor: Colors.neutral10,
		activeIconButtonColor: Colors.neutral10,
		activeBorder: Size.borderStroke + " solid " + Colors.active70,
		disabledColor: Colors.neutral30,
		disabledBorder: Size.borderStroke + " solid " + Colors.neutral30,
		disabledTextColor: Colors.neutral60,
	},
	tab: {
		colorTheme: "secondary",
		variant: "contained",
		textColor: Colors.neutral100,
		backgroundColor: Colors.neutral10,
		border: Size.borderStroke + " solid " + Colors.neutral30,
		hoverColor: Colors.neutral20,
		hoverIconButtonColor: Colors.neutral90,
		hoverTextColor: Colors.neutral90,
		hoverBorder: Size.borderStroke + " solid " + Colors.neutral40,
		activeColor: Colors.neutral20,
		activeTextColor: Colors.neutral100,
		activeIconButtonColor: Colors.neutral100,
		activeBorder: Size.borderStroke + " solid " + Colors.neutral30,
		disabledColor: Colors.neutral30,
		disabledBorder: Size.borderStroke + " solid " + Colors.neutral30,
		disabledTextColor: Colors.neutral60,
	},
	tabSelected: {
		colorTheme: "info",
		variant: "contained",
		textColor: Colors.neutral10,
		backgroundColor: Colors.active60,
		border: Size.borderStroke + " solid " + Colors.active50,
		hoverColor: Colors.active50,
		hoverIconButtonColor: Colors.neutral10,
		hoverTextColor: Colors.neutral10,
		hoverBorder: Size.borderStroke + " solid " + Colors.active40,
		activeColor: Colors.active80,
		activeTextColor: Colors.neutral10,
		activeIconButtonColor: Colors.neutral10,
		activeBorder: Size.borderStroke + " solid " + Colors.active70,
		disabledColor: Colors.neutral30,
		disabledBorder: Size.borderStroke + " solid " + Colors.neutral30,
		disabledTextColor: Colors.neutral60,
	},
	warning: {
		colorTheme: "warning",
		variant: "contained",
		textColor: Colors.neutral10,
		backgroundColor: Colors.yellow70,
		border: Size.borderStroke + " solid " + Colors.yellow50,
		hoverColor: Colors.yellow50,
		hoverIconButtonColor: Colors.neutral10,
		hoverTextColor: Colors.neutral10,
		hoverBorder: Size.borderStroke + " solid " + Colors.yellow40,
		activeColor: Colors.yellow80,
		activeTextColor: Colors.neutral10,
		activeIconButtonColor: Colors.neutral10,
		activeBorder: Size.borderStroke + " solid " + Colors.yellow70,
		disabledColor: Colors.neutral30,
		disabledBorder: Size.borderStroke + " solid " + Colors.neutral30,
		disabledTextColor: Colors.neutral60,
	},
	error: {
		colorTheme: "error",
		variant: "contained",
		textColor: Colors.neutral10,
		backgroundColor: Colors.red50,
		border: Size.borderStroke + " solid " + Colors.red40,
		hoverColor: Colors.red40,
		hoverTextColor: Colors.neutral10,
		hoverIconButtonColor: Colors.neutral10,
		hoverBorder: Size.borderStroke + " solid " + Colors.red30,
		activeColor: Colors.red60,
		activeTextColor: Colors.neutral10,
		activeIconButtonColor: Colors.neutral10,
		activeBorder: Size.borderStroke + " solid " + Colors.red50,
		disabledColor: Colors.neutral30,
		disabledBorder: Size.borderStroke + " solid " + Colors.neutral30,
		disabledTextColor: Colors.neutral60,
	},
};

type ITypeInfo = {
	colorTheme: IButtonType;
	variant: "contained" | "outlined" | "text";
	textColor: string;
	backgroundColor: string;
	border: string;
	hoverColor: string;
	hoverTextColor: string;
	hoverIconButtonColor: string;
	hoverBorder: string;
	hoverUnderline?: boolean;
	activeColor: string;
	activeTextColor: string;
	activeIconButtonColor: string;
	activeBorder: string;
	disabledBorder: string;
	disabledColor: string;
	disabledTextColor: string;
};

export const ButtonStyled = styled(MUIButton, {
	shouldForwardProp: (propName) => {
		if (propName === "buttonType") {
			return false;
		} else if (propName === "magicIcon") {
			return false;
		} else if (propName === "noPadding") {
			return false;
		} else if (propName === "iconButton") {
			return false;
		}
		return true;
	},
})<IButton>(
	({ theme, disabled, noPadding, iconButton, buttonType, magicIcon }) => {
		const currentType = types[buttonType || "primary"] as ITypeInfo;
		return {
			textTransform: "unset",
			fontFamily: "Inter",
			fontWeight: 600,
			fontSize: Size.base,
			borderRadius: magicIcon ? Size.x2l : Size.sm_small,
			padding: noPadding
				? "0px!important"
				: `${Size.sm_small} ${Size.base}`,
			lineHeight: "150%",
			borderSizing: "border-box",
			// eslint-disable-next-line no-nested-ternary
			width: iconButton ? (magicIcon ? Size.xl : Size.x3l) : undefined,
			// eslint-disable-next-line no-nested-ternary
			height: iconButton ? (magicIcon ? Size.xl : Size.x3l) : undefined,
			minWidth: iconButton || noPadding ? 0 : 110,
			"&>.MuiButton-endIcon": {
				marginRight: iconButton ? "0px" : undefined,
				marginLeft: iconButton ? "0px" : undefined,
			},
			"& .svgIconColor svg path ": {
				transition: "all 0.1s ease-out",
			},
			["&.MuiButton-" + currentType.variant]: {
				color: disabled
					? currentType.disabledTextColor
					: currentType.textColor,
				backgroundColor: disabled
					? currentType.disabledColor
					: currentType.backgroundColor,
				border: disabled
					? currentType.disabledBorder
					: currentType.border,
				"& .svgIconColor svg path ": {
					// eslint-disable-next-line no-nested-ternary
					fill: disabled
						? currentType.disabledTextColor
						: magicIcon
						? Colors.neutral100
						: currentType.textColor,
				},
				"&:hover": {
					backgroundColor: magicIcon
						? Colors.active20
						: currentType.hoverColor,
					border: currentType.hoverBorder,
					color: currentType.hoverTextColor,
					textDecoration: currentType.hoverUnderline
						? "underline"
						: undefined,
					textUnderlineOffset: currentType.hoverUnderline
						? 4
						: undefined,
					"& .svgIconColor svg path ": {
						// eslint-disable-next-line no-nested-ternary
						fill: magicIcon
							? Colors.neutral90
							: iconButton
							? currentType.hoverIconButtonColor
							: currentType.hoverTextColor,
					},
				},
				"&:active": {
					backgroundColor: currentType.activeColor,
					border: currentType.activeBorder,
					color: currentType.activeTextColor,
					"& .svgIconColor svg path ": {
						fill: magicIcon
							? Colors.active60
							: iconButton
							? currentType.activeIconButtonColor
							: currentType.activeTextColor,
					},
				},
			},
		};
	},
);
