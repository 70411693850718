import React from "react";
import { RouteObject } from "react-router-dom";

import {
	PARAM_OBS_TEMPLATE_ID,
	PATH_GENERAL,
	PATH_OBSERVATION,
	PATH_PROJECTS,
	PATH_TEMPLATES,
	PATH_VARIABLES,
	PATH_VARIABLE_GROUPS,
	removeStartingSlash,
} from "App/routes";
import ProjectPage from "../../Views/main/settings/project/ProjectPage";
import SettingPage from "../../Views/main/settings/SettingPage";
import TemplateObsEditionGeneralPage from "../../Views/main/settings/templates/observation/edition/general/TemplateObsEditionGeneralPage";
import TemplateObsEditionPage from "../../Views/main/settings/templates/observation/edition/TemplateObsEditionPage";
import TemplateObsEditionVarGroupsPage from "../../Views/main/settings/templates/observation/edition/variable-groups/TemplateObsEditionVariableGroupsPage";
import TemplateObsEditionVariablesPage from "../../Views/main/settings/templates/observation/edition/variables/TemplateObsEditionVariablesPage";
import { templateRouter } from "./template/TemplateRouter";
import { userRouter } from "./user/UserRouter";
import { variableRouter } from "./variable/VariableRouter";

export function settingRouter(): RouteObject {
	return {
		element: <SettingPage />,
		children: [
			{
				index: true,
				path: PATH_PROJECTS,
				element: <ProjectPage />,
			},
			variableRouter(),
			templateRouter(),
			userRouter(),
		],
	};
}

export function templateEditingRouter(): RouteObject {
	return {
		path: PATH_TEMPLATES + PATH_OBSERVATION + PARAM_OBS_TEMPLATE_ID,
		element: <TemplateObsEditionPage />,
		children: [
			{
				path: removeStartingSlash(PATH_GENERAL),
				element: <TemplateObsEditionGeneralPage />,
			},
			{
				path: removeStartingSlash(PATH_VARIABLES),
				element: <TemplateObsEditionVariablesPage />,
			},
			{
				path: removeStartingSlash(PATH_VARIABLE_GROUPS),
				element: <TemplateObsEditionVarGroupsPage />,
			},
		],
	};
}
