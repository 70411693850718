import React from "react";

import { useAppSelector } from "App/hooks/reduxHook";
import { selectGlobal } from "App/redux/global/global.selector";
import { Modal } from "stories/base-components/Modal/Modal";
import { Typography } from "stories/base-components/Typography/Typography";
import Size from "stories/constants/Size/Size";
import FlexBox from "../base-components/placement/flex-box/FlexBox";
import useGlobalModal from "App/hooks/useGlobalModal";

export default function GlobalModal() {
	const globalModal = useAppSelector(selectGlobal.globalModal);
	const { closeModal } = useGlobalModal();

	return (
		<Modal
			open={globalModal !== undefined}
			handleClose={closeModal}
			title={globalModal?.title || ""}
			validateBtn={globalModal?.btn}
			modalType={globalModal?.type}
			cancelLabel={globalModal?.cancelLabel}
			width={globalModal?.width ? globalModal?.width : 600}
		>
			{globalModal?.descriptions && (
				<FlexBox
					flexDirection="column"
					gap={Size.base}
					paddingBottom={Size.base}
				>
					{globalModal?.descriptions.map((description, index) => (
						<Typography
							key={"modalDescription_" + index}
							text={description}
						/>
					))}
				</FlexBox>
			)}
		</Modal>
	);
}
