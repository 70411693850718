import React from "react";

import { IUser } from "shared-type";

import { Initials } from "stories/base-components/Initials/Initials";

interface IIInitialsUser {
	user: Partial<IUser>;
}

export default function InitialsUserXXL(props: IIInitialsUser) {
	const user = props.user;
	return (
		<Initials
			firstName={user.firstName}
			lastName={user.lastName}
			backgroundColor={user.color}
			size="xxl"
		/>
	);
}
