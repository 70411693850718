import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";

import { formatString } from "common";
import { EEntitiesLength } from "shared-type";

import { DIC, EDIC_KEY } from "../../../../dictionary";
import useAppSnackbar from "../../../../hooks/useAppSnackbar";
import BottomMenu from "../../menu/bottom-menu/BottomMenu";
import DorianeDrawer from "../DorianeDrawer";
import { EFormInputType } from "../../dynamic-form";
import DynamicFormV2 from "../../dynamic-form/DynamicFormV2";
import { Button } from "stories/base-components/Button/Button";

import "./CommentDrawer.scss";

const commentsValidator = Yup.object().shape({
	comments: Yup.string().max(EEntitiesLength.comments),
});

interface CommentDrawerProps {
	remoteComments: string | undefined;
	onClose: () => void;
	onSaveComment: (comments: string) => Promise<void>;
	appBarSpacing?: boolean;
}

export default function CommentDrawer(props: CommentDrawerProps) {
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();
	const form = useForm<{ comments?: string }>({
		defaultValues: { comments: props.remoteComments },
		resolver: yupResolver(commentsValidator as any),
	});

	const handleSaveComment = async () => {
		form.clearErrors();
		form.handleSubmit(
			async (data) => {
				// If put undefined, comment will not be overwritten
				await props
					.onSaveComment(data.comments ?? "")
					.then(() => {
						enqueueSnackbarSuccess(
							formatString(
								DIC(EDIC_KEY.ENTITY_UPDATED),
								DIC(EDIC_KEY.COMMENTS),
							),
						);
						props.onClose();
					})
					.catch(() => {
						enqueueSnackbarError(
							formatString(
								DIC(EDIC_KEY.CANT_ACTION_ENTITY),
								DIC(EDIC_KEY.UPDATE).toLowerCase(),
								DIC(EDIC_KEY.COMMENTS).toLowerCase(),
							),
						);
					});
			},
			() => enqueueSnackbarError(DIC(EDIC_KEY.BAD_INPUT)),
		)();
	};

	useEffect(() => {
		if (!props.remoteComments) {
			form.reset();
			return;
		}
		form.reset({ comments: props.remoteComments });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.remoteComments]);

	return (
		<DorianeDrawer
			onClose={() => props.onClose()}
			title={DIC(EDIC_KEY.COMMENTS)}
			absolutePos
			width="350px"
			appBarSpacing={props.appBarSpacing}
		>
			<div className="CommentDrawer-form full-parent-size flex-column">
				<DynamicFormV2
					control={form.control}
					formInfos={[
						{
							inputType: EFormInputType.STRING,
							id: "comments",
							label: "",
							placeholder: DIC(EDIC_KEY.WRITE_A_COMMENT),
							multiline: true,
							takeAllRow: true,
						},
					]}
				/>
			</div>
			<BottomMenu
				customButton={
					<Button
						text={DIC(EDIC_KEY.POST)}
						onClick={() => handleSaveComment()}
					/>
				}
			/>
		</DorianeDrawer>
	);
}
