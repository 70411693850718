import React from "react";
import { useSelector } from "react-redux";

import { DIC, EDIC_KEY } from "../../../../dictionary";
import { selectECW } from "../../../../redux/ECW/ECW.selector";
import DorianeButton from "../../../base-components/button/DorianeButton";
import DorianeModal from "../../../base-components/modal/DorianeModal";

interface ECWSaveModalProps {
	onClose: () => void;
	nextAction: (action: "save" | "doItLater", isLastStep: boolean) => void;
	isMET?: boolean;
}

export default function ECWSaveModal(props: ECWSaveModalProps) {
	const infos = useSelector(selectECW.saveModalInfos);

	return (
		<DorianeModal
			onClose={() => {
				props.onClose();
			}}
			title={DIC(EDIC_KEY.SET_EXPERIMENT_TO_READY)}
			width={"350px"}
			height={"175px"}
			closeOnOutsideClick={false}
		>
			<div className="take-remaining-space flex-column">
				<p className="DorianeModal-child-text-area">
					{props.isMET
						? DIC(EDIC_KEY.MET_TO_READY_TEXT)
						: DIC(EDIC_KEY.TRIAL_TO_READY_TEXT)}
				</p>
				<div className="DorianeModal-child-button-list">
					<DorianeButton
						onClick={() => {
							props.nextAction(infos.action, false);
							props.onClose();
						}}
					>
						{DIC(EDIC_KEY.JUST_SAVE)}
					</DorianeButton>
					<DorianeButton
						dorianeStyle="primary"
						onClick={() => {
							props.nextAction(infos.action, true);
							props.onClose();
						}}
					>
						{DIC(EDIC_KEY.SAVE_AND_SET_READY)}
					</DorianeButton>
				</div>
			</div>
		</DorianeModal>
	);
}
