import { IconButton } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { EEntitiesLength } from "shared-type";

import ColorTag from "App/components/base-components/tag/color-tag/ColorTag";
import { getColorFromTrialStatus } from "App/utils";
import { Delete, LandscapeAdd, SVGDocAdd } from "../../../../../../assets";
import { DIC, EDIC_KEY } from "../../../../../dictionary";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/reduxHook";
import useAppSnackbar from "../../../../../hooks/useAppSnackbar";
import {
	IMETTrialRow,
	METTrialManagementActions,
} from "../../../../../redux/experiment/met/met-trial-management.slice";
import { apiMET } from "../../../../../redux/experiment/met/met.api";
import DorianeButton from "../../../../base-components/button/DorianeButton";
import SaveButton from "../../../../base-components/button/SaveButton";
import { CheckBoxColumn } from "../../../../base-components/data-grid/doriane-data-grid/columns/CheckBoxColumn";
import { StringCellEditable } from "../../../../base-components/data-grid/doriane-data-grid/doriane-data-grid/cell/StringCellEditable";
import DorianeDataGrid from "../../../../base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import Box from "../../../../base-components/placement/box/Box";
import FlexBox, {
	EGapSize,
} from "../../../../base-components/placement/flex-box/FlexBox";
import { GenerationModal } from "../../../../experiment-wizard/common/part/trial-generation/generation-modal/GenerationModal";
import { sortTrialsRows } from "../trials/METTrialHelper";

export default function METTrialsEdition(props: {
	metId: string;
	onEditionEnd: () => void;
	setTableView: (value: boolean) => void;
}) {
	// #region utils -----
	const dispatch = useAppDispatch();
	const { enqueueSnackbarError, enqueueSnackbarSuccess } = useAppSnackbar();
	const { data: met } = apiMET.useGetMETByIdQuery(props.metId);
	// //#endregion

	const rows = useAppSelector((state) => state.METTrialManagement.trialsRows);
	const rowsSorted = useMemo(() => {
		return sortTrialsRows([...(rows || [])]);
	}, [rows]);

	const [generationPopupIsOpen, setGenerationPopupIsOpen] = useState(false);
	const [cellOnEditing, setCellOnEditing] = useState<undefined | number>();
	const [isSaveInProgress, setIsSaveInProgress] = useState(false);

	// loading/unload redux state
	useEffect(() => {
		dispatch(
			METTrialManagementActions.loadTrialFromMET({ id: props.metId }),
		);
		return () => {
			dispatch(METTrialManagementActions.reset());
		};
	}, [dispatch, props.metId]);

	const handleSave = useCallback(async () => {
		setIsSaveInProgress(true);
		await dispatch(METTrialManagementActions.saveMetTrials())
			.unwrap()
			.then(() => {
				enqueueSnackbarSuccess(DIC(EDIC_KEY.SAVE));
				props.onEditionEnd();
				props.setTableView(true);
			})
			.catch((err: string[]) => {
				console.error(err);
				enqueueSnackbarError(err?.join?.(", "));
			});
		setIsSaveInProgress(false);
	}, [dispatch, enqueueSnackbarError, enqueueSnackbarSuccess, props]);

	const columns = useMemo(
		() => [
			CheckBoxColumn<IMETTrialRow>({
				isCheck: (row) => row.isSelected ?? false,
				onCheck: (row, check) =>
					dispatch(
						METTrialManagementActions.onSelectTrial({
							id: row.id,
							check: check,
						}),
					),
				headerCheckAllAction: (check) => {
					dispatch(
						METTrialManagementActions.onSelectTrials({
							rows: rowsSorted,
							check: check,
						}),
					);
				},
			}),
			{
				getCellRenderer: (row) => (
					<div className="DorianeDataGridCell-padding-horizontal flex-center-full-height">
						{row.trial?.status && (
							<ColorTag
								tagValue={row.trial?.status}
								title=""
								colorIndicator={getColorFromTrialStatus(
									row.trial?.status,
								)}
							/>
						)}
					</div>
				),
				getHeaderRenderer: DIC(EDIC_KEY.STATUS),
				id: "status",
				width: "100px",
			},
			{
				getCellRenderer: (row) => (
					<StringCellEditable
						row={row}
						value={row.trial?.name || ""}
						isOnEditing={row.id === cellOnEditing}
						onEditClick={() => setCellOnEditing(row.id)}
						onEditDisplayed={!cellOnEditing}
						onSubmitChange={(newName) => {
							dispatch(
								METTrialManagementActions.changeTrialName({
									id: row.id,
									newName,
								}),
							);
							setCellOnEditing(undefined);
						}}
						maxLength={EEntitiesLength.name}
					/>
				),
				getHeaderRenderer: DIC(EDIC_KEY.NAME),
				id: "name",
				width: "300px",
			},
			{
				getCellRenderer: (row) => row.growingArea?.name || "",
				getHeaderRenderer: DIC(EDIC_KEY.GROWING_AREA),
				id: "gaName",
				width: "300px",
			},
			{
				getCellRenderer: (row) => row.growingArea?.country || "",
				getHeaderRenderer: DIC(EDIC_KEY.COUNTRY),
				id: "gaCountry",
				width: "150px",
			},
			{
				getCellRenderer: (row) => row.growingArea?.region || "",
				getHeaderRenderer: DIC(EDIC_KEY.REGION),
				id: "gaRegion",
				width: "150px",
			},
			{
				getCellRenderer: (row) =>
					row.trial?.marketSegments?.join(", ") || "",
				getHeaderRenderer: DIC(EDIC_KEY.MARKET_SEGMENTS),
				id: "marketSegments",
				width: "150px",
			},
			{
				getCellRenderer: (row) =>
					(row.trial?.replication
						? String(row.trial?.replication)
						: ""),
				getHeaderRenderer: DIC(EDIC_KEY.REPLICATION),
				id: "replication",
				width: "150px",
			},
		],
		[cellOnEditing, dispatch, rowsSorted],
	);

	return (
		<FlexBox flexDirection="column" fullParentSize gap={EGapSize.SMALL}>
			<FlexBox justifyContent="flex-end" gap={EGapSize.SMALL}>
				<DorianeButton onClick={props.onEditionEnd}>
					{DIC(EDIC_KEY.CANCEL)}
				</DorianeButton>
				<SaveButton
					onSave={() => !isSaveInProgress && handleSave()}
					disabled={isSaveInProgress}
				></SaveButton>
			</FlexBox>
			<FlexBox justifyContent="space-between" alignItems="center">
				<h1>{DIC(EDIC_KEY.TRIALS_LIST)}</h1>
				<Box>
					<IconButton
						onClick={() => setGenerationPopupIsOpen(true)}
						color="primary"
						className="ECWTrialGeneration-title-button"
					>
						<img src={SVGDocAdd} />
					</IconButton>
					<IconButton
						onClick={() =>
							dispatch(
								METTrialManagementActions.setOpenGrowingAreaModal(
									true,
								),
							)
						}
						color="primary"
						className="ECWTrialGeneration-title-button"
					>
						<img src={LandscapeAdd} />
					</IconButton>
					<IconButton
						onClick={() =>
							dispatch(
								METTrialManagementActions.removeSelectedTrials(),
							)
						}
						color="primary"
						className="ECWTrialGeneration-title-button"
					>
						<img src={Delete} />
					</IconButton>
				</Box>
			</FlexBox>
			<DorianeDataGrid
				columns={columns}
				rows={rowsSorted}
				className={"take-remaining-space"}
			/>
			{generationPopupIsOpen && (
				<GenerationModal
					onClose={() => setGenerationPopupIsOpen(false)}
					METName={met?.name}
					METIsReady
				/>
			)}
		</FlexBox>
	);
}
