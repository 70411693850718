import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { Dayjs } from "dayjs";
import { EExperimentStatus } from "shared-type";

import {
	IExpGeneralInfo,
	IExpGeneralInfoState,
} from "../../../../../../../components/experiment-wizard/common/types";
import {
	ExpGeneralForm,
	expGeneralFormToStateObject,
	useExpGeneralForm,
} from "../../../../../../../components/form/experiment/general";
import { selectECW } from "../../../../../../../redux/ECW/ECW.selector";
import { ECWAction } from "../../../../../../../redux/ECW/ECW.slice";
import { apiMET } from "../../../../../../../redux/experiment/met/met.api";
import { apiMarketSegment } from "../../../../../../../redux/market-segment/market-segment.api";
import METEditStartDateModal from "../../../../../../../components/experiment/met/modal/METEditStartDateModal";

import "./ExpEditionGeneralPage.scss";

export default function ExpEditionGeneralPage() {
	const dispatch = useDispatch();
	const { metId } = useParams();

	const [isEditStartDateOpen, setIsEditStartDateOpen] = useState(false);

	const remoteTrial = useSelector(selectECW.trialRemote);
	const wizardType = useSelector(selectECW.wizardType);
	const expGeneralFormInfo = useSelector(selectECW.generalFormInfo);

	const { data: remoteMET } = apiMET.useGetMETByIdQuery(metId ?? skipToken);

	const expStatus = useMemo(() => {
		if (wizardType === "MET" && remoteMET) {
			return remoteMET.status;
		}
		if (wizardType === "SET" && remoteTrial) {
			return remoteTrial.status;
		}
	}, [remoteMET, remoteTrial, wizardType]);

	const lastStartDate = useRef<Dayjs | undefined>(
		expGeneralFormInfo.startDate,
	);

	// #region --------- FORM ----------
	const { form } = useExpGeneralForm({
		checkErrorOnInit: true,
		values: expGeneralFormInfo as IExpGeneralInfo,
	});
	const formValues = useWatch({
		control: form.control,
	});
	const [marketSegmentIds, startDate] = useWatch({
		control: form.control,
		name: ["marketSegmentIds", "startDate"],
	});
	const { data: currentMarketSegments } =
		apiMarketSegment.useGetMarketSegmentByIdsQuery(marketSegmentIds ?? []);

	const storeForm = async (
		values: Partial<IExpGeneralInfoState>,
		isError: boolean,
		isInitializing?: boolean,
	) => {
		return dispatch(
			ECWAction.updateExpGeneralInfo({
				expGeneralInfo: values,
				isError: isError,
				isInitializing: isInitializing,
			}),
		);
	};

	const handleSubmit = async (isInitializing?: boolean) => {
		await form.handleSubmit(
			async (values) => {
				storeForm(
					expGeneralFormToStateObject(values),
					false,
					isInitializing,
				);
			},
			(error) => {
				throw error;
			},
		)();
	};

	const onCancelChangeStartDate = () => {
		if (lastStartDate.current) {
			form.setValue("startDate", lastStartDate.current, {
				shouldDirty: true,
			});
		}
		setIsEditStartDateOpen(false);
	};

	const onAcceptChangeStartDate = () => {
		setIsEditStartDateOpen(false);
		lastStartDate.current = startDate;
	};

	const [isInitializingForm, setIsInitializingForm] = useState(true);

	// Debounce to keep redux update with the form
	const autoSubmitForm = useDebouncedCallback(
		() => {
			handleSubmit(isInitializingForm).catch(() => {});

			if (isInitializingForm) {
				setIsInitializingForm(false);
			}
		},
		800, // 0.8 second is enough between typing and clicking on save
		{ maxWait: 2000 },
	);
	useEffect(() => {
		autoSubmitForm();
	}, [autoSubmitForm, formValues]);

	// on unmount
	useEffect(() => {
		return () => {
			// only if form change
			if (form.formState.isDirty) {
				handleSubmit().catch((err) => {
					// If we unmount we absolutely need to save
					storeForm(
						expGeneralFormToStateObject(form.getValues()),
						true,
					);
				});
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// #endregion

	useEffect(() => {
		if (wizardType === "SET") {
			return;
		}
		if (lastStartDate.current === undefined) {
			lastStartDate.current = startDate;
			return;
		}
		// TODO - Show Modal only with save button
		if (!startDate) {
			return;
		}
		if (!startDate.isValid()) {
			return;
		}
		if (!startDate.isSame(lastStartDate.current)) {
			setIsEditStartDateOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate]);

	return (
		<div className="ExpEditionGeneralPage-container full-parent-size">
			<div className="ExpEditionGeneralPage-body flex-column full-parent-size take-remaining-space">
				<div className="ExpEditionGeneralPage-form">
					<ExpGeneralForm
						type={wizardType}
						mode={
							expStatus === EExperimentStatus.READY ||
							expStatus === EExperimentStatus.NOT_VALIDATED ||
							expStatus === EExperimentStatus.VALIDATED
								? "used"
								: "unused"
						}
						form={form}
						onSelectSegmentClick={() => {}}
						marketSegments={currentMarketSegments}
					/>
				</div>
			</div>
			{isEditStartDateOpen && (
				<METEditStartDateModal
					onCancelChange={onCancelChangeStartDate}
					onAcceptChange={onAcceptChangeStartDate}
				/>
			)}
		</div>
	);
}
