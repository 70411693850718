import { createApi } from "@reduxjs/toolkit/dist/query/react";

import {
	IAssociatedMetTrials,
	ICreateTrial,
	IExpDuplicationData,
	IFilter,
	IPatchTrial,
	ISort,
	ITrialDuplicationInfo,
	ITrialSerialized,
	PaginateResponse,
} from "shared-type";

import { ReduxStore } from "../..";
import { useRequest } from "../../../request-provider";
import { defaultApiError } from "../../utils/errors";
import { ExpGanttApiHelper } from "App/redux/experiment-statistics/exp-gantt.api";

export const apiTrial = createApi({
	reducerPath: "api-experiment-trial",
	baseQuery: () => ({ data: undefined }),

	// global cache configuration for the api (in second)
	keepUnusedDataFor: 30,
	refetchOnMountOrArgChange: 30,

	// update tag
	tagTypes: ["Update", "Delete", "Create"],

	// api definition
	endpoints: (build) => ({
		getTrial: build.query<
			PaginateResponse<ITrialSerialized>,
			{
				filter?: IFilter;
				sort?: ISort;
				page?: number;
				pageSize?: number;
			}
		>({
			queryFn: async (args) => {
				try {
					const result = await useRequest().trial.getTrialsSerialized(
						args,
					);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(err, "Unable to fetch trial");
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),
		getTrialById: build.query<ITrialSerialized, string>({
			queryFn: async (id) => {
				try {
					const result =
						await useRequest().trial.getTrialByIdSerialized(id);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						`Unable to fetch trial with id ${id}`,
					);
				}
			},
			providesTags: (res, error, id) => [
				{ type: "Delete", id: id },
				{ type: "Create", id: id },
				{ type: "Update", id: id },
			],
		}),
		getTrialsByMETId: build.query<ITrialSerialized[], string>({
			queryFn: async (id) => {
				try {
					const result = await useRequest().trial.getTrialsByMETId(
						id,
					);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						`Unable to fetch trials by MET with id ${id}`,
					);
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),

		getTrialsIdsByMETIds: build.query<
			IAssociatedMetTrials[],
			string[] | undefined
		>({
			queryFn: async (metIds) => {
				try {
					if (metIds === undefined || metIds.length === 0) {
						return { data: [] };
					}

					const result =
						await useRequest().trial.getTrialsIdsByMETIds(metIds);

					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						`Unable to fetch trials ids by MET with ids ${metIds}`,
					);
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),

		createTrial: build.mutation<string, ICreateTrial>({
			queryFn: async (createTrial) => {
				try {
					const result = await useRequest().trial.createTrial(
						createTrial,
					);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(err, "Unable to create the trial");
				}
			},
			invalidatesTags: (res) => [
				{ type: "Create", id: res },
				{ type: "Create", id: "ALL" },
			],
		}),
		updateTrialById: build.mutation<
			undefined,
			{ id: string; updateTrial: IPatchTrial }
		>({
			queryFn: async ({ id, updateTrial }) => {
				try {
					await useRequest().trial.updateTrialById(id, updateTrial);
					return {
						data: undefined,
					};
				} catch (err) {
					return defaultApiError(err, "Unable to update the trial");
				}
			},
			invalidatesTags: (res, err, { id }) => {
				ExpGanttApiHelper.invalidateTags(["Get"]);
				return [
					{ type: "Update", id: id },
					{ type: "Update", id: "ALL" },
				];
			},
		}),
		deleteTrialById: build.mutation<void, string>({
			queryFn: async (id) => {
				try {
					const result = await useRequest().trial.deleteTrialById(id);
					return { data: result.data };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to delete trial with id : " + id,
					);
				}
			},
			invalidatesTags: (res, err, id) => {
				ExpGanttApiHelper.invalidateTags(["Get"]);
				return [
					{ type: "Delete", id: id },
					{ type: "Delete", id: "ALL" },
				];
			},
		}),
		duplicateTrials: build.mutation<any, ITrialDuplicationInfo>({
			queryFn: async (duplicateTrials) => {
				try {
					const result = await useRequest().trial.duplicateTrials({
						trialsData: duplicateTrials.trialsData.map(
							(trial: IExpDuplicationData) => {
								return { id: trial.id, name: trial.name };
							},
						),
						startDate: duplicateTrials.startDate,
						endDate: duplicateTrials.endDate,
						copyMaterial: duplicateTrials.copyMaterial,
						copyGrowingArea: duplicateTrials.copyGrowingArea,
						copyTemplateObs: duplicateTrials.copyTemplateObs,
						copyTasks: duplicateTrials.copyTasks,
						copyTaskUser: duplicateTrials.copyTaskUser,
					});
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to duplicate the custom variable",
					);
				}
			},
			invalidatesTags: (res) => [
				{ type: "Create", id: res },
				{ type: "Create", id: "ALL" },
			],
		}),
	}),
});

export abstract class TrialApiHelper {
	static invalidateTags(
		...args: Parameters<typeof apiTrial.util.invalidateTags>
	) {
		ReduxStore.store.dispatch(apiTrial.util.invalidateTags(...args));
	}
}
