import React, { useCallback, useEffect, useMemo, useState } from "react";

import { EVariableScope, IVariableCore } from "shared-type";

import VariableCommentDrawer from "App/components/variables/variable-modal/VariableCommentDrawer";
import { useAppSelector } from "App/hooks/reduxHook";
import { selectVariable } from "App/redux/variables/variable.selector";
import { variableAction } from "App/redux/variables/variable.slice";
import store from "App/store";
import { HeaderTitleSearchButton } from "stories/base-components/Layout/HeaderTitleSearchButton/HeaderTitleSearchButton";
import CreateCustomVariableModal from "../../../../../components/variables/variable-modal/create-variable/CreateCustomVariableModal";
import EditCustomVariableModal from "../../../../../components/variables/variable-modal/edit-variable/EditCustomVariableModal";
import { DIC, EDIC_KEY } from "../../../../../dictionary";
import { VariableDataGrid } from "../../../../../components/base-components/data-grid/doriane-data-grid/grid-object/VariableOnthologyDataGrid";

import "./VariablesOntologyPage.scss";

export default function VariablesOntologyPage() {
	const [variableModalOpen, setVariableModalOpen] = useState<
		"close" | "create" | "edit"
	>("close");
	const [clickedId, setClickedId] = useState<string | undefined>(undefined);

	const handleEditCustomVar = useCallback((customVar: IVariableCore) => {
		if (customVar.scope === EVariableScope.CUSTOM) {
			setVariableModalOpen("edit");
			setClickedId(customVar._id);
		}
	}, []);

	const handleCreateCustomVar = useCallback(() => {
		setVariableModalOpen("create");
	}, []);
	const handleCloseModal = useCallback(() => {
		setVariableModalOpen("close");
	}, []);
	const onRowClick = useCallback(
		(
			event: React.MouseEvent<HTMLDivElement, MouseEvent>,
			row: IVariableCore,
		) => {
			handleEditCustomVar(row);
		},
		[],
	);
	const selectedVariableCommentId = useAppSelector(
		selectVariable.variableCommentIdSelected,
	);

	const VariableGridMemo = useMemo(
		() => <VariableDataGrid onRowClick={onRowClick} />,
		[onRowClick],
	);
	useEffect(() => {
		// Close comment modal to avoid undesirable opening
		store.dispatch(variableAction.changeSelectedCommentId(undefined));
	}, []);

	return (
		<>
			<div className="VariablesOntologyPage-container flex-column full-parent-size">
				<HeaderTitleSearchButton
					title={DIC(EDIC_KEY.VARIABLE_ONTOLOGY)}
					btnText={DIC(EDIC_KEY.NEW_CUSTOM_VARIABLE) + " +"}
					btnOnClick={() => handleCreateCustomVar()}
				/>
				<div className="VariablesOntologyPage-listContainer take-remaining-space">
					{VariableGridMemo}
				</div>
				<CreateCustomVariableModal
					onClose={handleCloseModal}
					open={variableModalOpen === "create"}
				/>

				<EditCustomVariableModal
					onClose={() => {
						handleCloseModal();
						setClickedId(undefined);
					}}
					id={clickedId || ""} // Open mean id is defined
					open={Boolean(variableModalOpen === "edit" && clickedId)}
				/>
			</div>
			{selectedVariableCommentId && (
				<VariableCommentDrawer
					variableId={selectedVariableCommentId}
					onClose={() =>
						store.dispatch(
							variableAction.changeSelectedCommentId(undefined),
						)
					}
				/>
			)}
		</>
	);
}
