import React, { ReactNode } from "react";
import { ThemeProvider } from "@mui/material";

import themeStoryBook from "stories/themeStoryBook";

import "./assets/css/common.css";

type IStoryContainer = {
	children: ReactNode;
	noPadding?: boolean;
};

export const StoryTheme = (props: IStoryContainer) => {
	return (
		<ThemeProvider theme={themeStoryBook}>{props.children}</ThemeProvider>
	);
};
