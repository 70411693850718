/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
import {
	DateConstraint,
	DatetimeConstraint,
	EVariableCoreInputType,
	EVariableCoreType,
	ISystemMetadata,
	ISystemMetadataSerialized,
	SerializeType,
} from "shared-type";

export class EntitySerializer {
	static serialize<T extends object>(object: Partial<T>): SerializeType<T> {
		const objectAny = object as any;
		const result: any = { ...object };
		if (objectAny.systemMetadata) {
			result.systemMetadata = SystemMetadataSerializer.serialize(
				objectAny.systemMetadata
			);
		}
		if (objectAny.notationDate) {
			result.notationDate = objectAny.notationDate.toJSON();
		}
		if (objectAny.multiNotationValues) {
			result.multiNotationValues = objectAny.multiNotationValues.map(
				(value: any) => {
					return { ...value, notationDate: value.notationDate.toJSON() };
				}
			);
		}
		if (objectAny.startDate) {
			result.startDate = objectAny.startDate.toJSON();
		}
		if (objectAny.endDate) {
			result.endDate = objectAny.endDate.toJSON();
		}
		if (objectAny.completedDate) {
			result.completedDate = objectAny.completedDate.toJSON();
		}
		if (
			objectAny.type &&
			(objectAny.type === EVariableCoreType.DATE ||
				objectAny.type === EVariableCoreType.DATETIME)
		) {
			if (objectAny.constraint !== undefined) {
				switch (objectAny.constraint.type) {
					case EVariableCoreType.DATETIME: {
						result.constraint = VariableCoreSerializer.serializedMinMaxDate(
							objectAny.constraint
						);
						break;
					}
					case EVariableCoreType.DATE: {
						result.constraint = VariableCoreSerializer.serializedMinMaxDate(
							objectAny.constraint
						);
						break;
					}
					default:
						break;
				}
			}

			if (
				objectAny.inputType &&
				objectAny.inputType === EVariableCoreInputType.LIMITED_CHOICE
			) {
				if (objectAny.limitedChoices !== undefined) {
					switch (objectAny.type) {
						case EVariableCoreType.DATETIME: {
							result.limitedChoices = objectAny.limitedChoices.map(
								(elt: any) => {
									return { ...elt, value: elt.value.toJSON() };
								}
							);
							break;
						}
						case EVariableCoreType.DATE: {
							result.limitedChoices = objectAny.limitedChoices.map(
								(elt: any) => {
									return { ...elt, value: elt.value.toJSON() };
								}
							);
							break;
						}
						default:
							break;
					}
				}
			}
		}
		return result;
	}

	static deserialize<T>(object: Partial<SerializeType<T>>): T {
		const objectAny = object as any;
		const result: any = { ...object };
		if (objectAny.systemMetadata) {
			result.systemMetadata = SystemMetadataSerializer.deserialize(
				objectAny.systemMetadata
			);
		}
		if (objectAny.notationDate) {
			result.notationDate = new Date(objectAny.notationDate);
		}
		if (objectAny.multiNotationValues) {
			result.multiNotationValues = objectAny.multiNotationValues.map(
				(value: any) => {
					return { ...value, notationDate: new Date(value.notationDate) };
				}
			);
		}
		if (objectAny.startDate) {
			result.startDate = new Date(objectAny.startDate);
		}
		if (objectAny.endDate) {
			result.endDate = new Date(objectAny.endDate);
		}
		if (objectAny.completedDate) {
			result.completedDate = new Date(objectAny.completedDate);
		}
		if (
			objectAny.type &&
			(objectAny.type === EVariableCoreType.DATE ||
				objectAny.type === EVariableCoreType.DATETIME)
		) {
			if (objectAny.constraint !== undefined) {
				switch (objectAny.constraint.type) {
					case EVariableCoreType.DATETIME: {
						result.constraint = VariableCoreSerializer.deserializeMinMaxDate(
							objectAny.constraint
						);
						break;
					}
					case EVariableCoreType.DATE: {
						result.constraint = VariableCoreSerializer.deserializeMinMaxDate(
							objectAny.constraint
						);
						break;
					}
					default:
						break;
				}
			}

			if (
				objectAny.inputType &&
				objectAny.inputType === EVariableCoreInputType.LIMITED_CHOICE
			) {
				if (objectAny.limitedChoices !== undefined) {
					switch (objectAny.type) {
						case EVariableCoreType.DATETIME: {
							result.limitedChoices = objectAny.limitedChoices.map(
								(elt: any) => {
									return { ...elt, value: new Date(elt.value as string) };
								}
							);
							break;
						}
						case EVariableCoreType.DATE: {
							result.limitedChoices = objectAny.limitedChoices.map(
								(elt: any) => {
									return { ...elt, value: new Date(elt.value as string) };
								}
							);
							break;
						}
						default:
							break;
					}
				}
			}
		}
		return result;
	}
}
class SystemMetadataSerializer {
	static serialize<
		T extends Partial<ISystemMetadataSerialized> = Partial<ISystemMetadataSerialized>
	>(object: Partial<ISystemMetadata>): T {
		const result: any = { ...object };
		if (object.createdDate) {
			result.createdDate = object.createdDate.toJSON();
		}
		if (object.updatedDate) {
			result.updatedDate = object.updatedDate.toJSON();
		}
		return result;
	}

	static deserialize<
		T extends Partial<ISystemMetadata> = Partial<ISystemMetadata>
	>(object: Partial<ISystemMetadataSerialized>): T {
		const result: any = { ...object };
		if (object.createdDate) {
			result.createdDate = new Date(object.createdDate);
		}
		if (object.updatedDate) {
			result.updatedDate = new Date(object.updatedDate);
		}
		return result;
	}
}

export class VariableCoreSerializer {
	static serializedMinMaxDate(
		constraint: DateConstraint | DatetimeConstraint
	): SerializeType<DateConstraint | DatetimeConstraint> {
		const result: any = { ...constraint };
		if (constraint?.minDate) {
			result.minDate = constraint.minDate.toJSON();
		}
		if (constraint?.maxDate) {
			result.maxDate = constraint.maxDate.toJSON();
		}
		return result;
	}

	static deserializeMinMaxDate(
		constraint: SerializeType<DateConstraint | DatetimeConstraint>
	) {
		const result: any = { ...constraint };

		if (constraint?.minDate) {
			result.minDate = new Date(constraint.minDate);
		}
		if (constraint?.maxDate) {
			result.maxDate = new Date(constraint.maxDate);
		}

		return result;
	}
}
