/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { Control, useController } from "react-hook-form";

import LabelledCard from "../card/labelled-card/LabelledCard";
import ChipCombobox from "../combobox/chip-combobox/ChipCombobox";
import DorianeDatePicker from "../date-picker/DorianeDatePicker";
import {
	BooleanInputInfoWithProps,
	CheckBoxInfoWithProps,
	ColorInputInfoWithProps,
	ComboboxInputInfoWithProps,
	DateInputInfoWithProps,
	EFormInputType,
	InputInformation,
	InputInfoWithProps,
	NumberInputInfoWithProps,
	StringInputInfoWithProps,
} from "./dynamicForm.interface";
import { CustomCheckbox } from "../checkbox/CustomCheckbox";
import { Input } from "stories/base-components/Input/Input";
import { Dropdown } from "stories/base-components/Dropdown/Dropdown";
import Size from "stories/constants/Size/Size";
import Values from "stories/constants/Values";
import { ColorSelector } from "stories/base-components/ColorSelector/ColorSelector";

import "./DynamicForm.scss";

interface DynamicFormProps {
	formInfos: InputInformation[];
	control: Control<any>;
	noGap?: boolean;
}

export default function DynamicFormV2(props: DynamicFormProps) {
	const formInputs = useMemo(() => {
		return props.formInfos.map((formInfo, index) => {
			return (
				<InputDisplay
					key={index}
					inputProps={formInfo}
					control={props.control}
				/>
			);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.formInfos]);

	return (
		<div
			className="DynamicForm-container"
			style={{
				gap: props.noGap ? 0 : Size.md,
			}}
		>
			{formInputs}
		</div>
	);
}

function InputDisplay(props: InputInfoWithProps) {
	const input = useMemo(() => {
		switch (props.inputProps.inputType) {
			case EFormInputType.NUMBER:
				return <NumberInput {...(props as NumberInputInfoWithProps)} />;
			case EFormInputType.DATE:
				return <DateInput {...(props as DateInputInfoWithProps)} />;
			case EFormInputType.COMBOBOX:
				return (
					<ComboboxInput {...(props as ComboboxInputInfoWithProps)} />
				);
			case EFormInputType.CHIP_COMBOBOX:
				return (
					<ChipComboboxInput
						{...(props as ComboboxInputInfoWithProps)}
					/>
				);
			case EFormInputType.BOOLEAN:
				return (
					<BooleanInput {...(props as BooleanInputInfoWithProps)} />
				);
			case EFormInputType.STRING:
				return <StringInput {...(props as StringInputInfoWithProps)} />;
			case EFormInputType.COLOR:
				return <ColorInput {...(props as ColorInputInfoWithProps)} />;
			case EFormInputType.CHECKBOX:
				return <CheckBoxInput {...(props as CheckBoxInfoWithProps)} />;
			default:
				return <div>Missing Input</div>;
		}
	}, [props]);
	return (
		<div
			className="DynamicForm-inputV2"
			style={{
				gridColumn: `span ${props.inputProps.takeAllRow ? 2 : 1}`,
			}}
		>
			{input}
		</div>
	);
}

export function StringInput(props: StringInputInfoWithProps) {
	const { inputProps, control } = props;
	const { field, fieldState } = useController({
		name: inputProps.id,
		control,
	});
	return (
		<Input
			value={field.value ?? ""}
			onChange={field.onChange}
			disabled={inputProps.disabled}
			label={inputProps.label}
			placeholder={inputProps.placeholder}
			required={inputProps.required}
			error={fieldState.invalid}
			errorMessage={fieldState.error?.message}
			InputProps={inputProps.InputProps}
			type={inputProps.type as "text" | "password" | undefined}
			width={props.inputProps.multiline ? undefined : Values.inputWidth}
			rows={props.inputProps.multiline ? 4 : undefined}
		/>
	);
}

export function NumberInput(props: NumberInputInfoWithProps) {
	const { inputProps, control } = props;
	const { field, fieldState } = useController({
		name: inputProps.id,
		control,
	});
	return (
		<Input
			value={inputProps.value ? inputProps.value : field.value ?? ""}
			onChange={(event) => {
				if (inputProps.onChange) {
					inputProps.onChange(event);
				} else {
					const newValue = event.target.value;
					if (newValue === "") {
						field.onChange(undefined);
						return;
					}
					field.onChange(newValue);
				}
			}}
			disabled={inputProps.disabled}
			label={inputProps.label}
			required={inputProps.required}
			error={fieldState.invalid}
			errorMessage={fieldState.error?.message}
			type="number"
			width={Values.inputWidth}
		/>
	);
}

export function DateInput(props: DateInputInfoWithProps) {
	const { inputProps, control } = props;
	const { field, fieldState } = useController({
		name: inputProps.id,
		control,
	});

	const value = field.value ? dayjs(field.value) : null;
	return (
		<DorianeDatePicker
			label={`${inputProps.label} ${inputProps.required ? "*" : ""}`}
			minDate={inputProps.minDate}
			disabled={inputProps.disabled}
			helperTextError={true}
			value={value || null}
			onChange={(value) => field.onChange(value as any)}
			helperText={fieldState.error?.message}
		/>
	);
}

export function BooleanInput(props: BooleanInputInfoWithProps) {
	const { inputProps, control } = props;
	const { field } = useController({
		name: inputProps.id,
		control,
	});
	return (
		<div>
			<CustomCheckbox
				checked={field.value}
				onChange={field.onChange}
				id={inputProps.id}
				disabled={inputProps.disabled}
				label={inputProps.label}
			/>
		</div>
	);
}

export function ComboboxInput(props: ComboboxInputInfoWithProps) {
	const { inputProps, control } = props;
	const { field, fieldState } = useController({
		name: inputProps.id,
		control,
	});

	return (
		<Dropdown
			label={inputProps.label}
			options={inputProps.comboboxValues ?? []}
			value={inputProps.value ? inputProps.value : field.value || null}
			// onChange is typed by MUI. MUI does not provide "value" but only "event" in parameters while the parameter "value" is returned
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			onChange={(event: any, value: any) => {
				inputProps.onChange
					? inputProps.onChange(value)
					: field.onChange(value);
			}}
			disabled={inputProps.disabled}
			getOptionLabel={(option: any) => inputProps.translateMethod(option)}
			renderOption={(props: object, option: any) => {
				return (
					<div {...props}>
						<div>{inputProps.translateMethod(option)}</div>
					</div>
				);
			}}
			error={fieldState.invalid}
			errorMessage={fieldState.error?.message}
		/>
	);
}

export function ChipComboboxInput(props: ComboboxInputInfoWithProps) {
	const { inputProps, control } = props;
	const { field } = useController({
		name: inputProps.id,
		control,
	});
	return inputProps.disabled ? (
		<LabelledCard
			label={`${inputProps.label} ${inputProps.required ? "*" : ""}`}
			value={
				inputProps.translateMethod
					? field.value.map((val: unknown) =>
							inputProps.translateMethod(val),
					  )
					: field.value
			}
		/>
	) : (
		<ChipCombobox<number | string>
			style={{ width: "100%" }}
			value={field.value || []}
			onChange={(value) => field.onChange(value)}
			translateMethod={inputProps.translateMethod}
			label={inputProps.label}
			required={inputProps.required}
			comboboxValues={inputProps.comboboxValues ?? []}
		/>
	);
}

export function ColorInput(props: ColorInputInfoWithProps) {
	const { inputProps, control } = props;
	const { field } = useController({
		name: inputProps.id,
		control,
	});

	return (
		<ColorSelector
			color={field.value ?? ""}
			onColorChange={field.onChange}
		/>
	);
}

export function CheckBoxInput(props: CheckBoxInfoWithProps) {
	const { inputProps, control } = props;
	const { field } = useController({
		name: inputProps.id,
		control,
	});
	return (
		<CustomCheckbox
			checked={field.value ?? false}
			onChange={field.onChange}
			id={inputProps.id}
			disabled={inputProps.disabled}
			label={inputProps.label}
		/>
	);
}
