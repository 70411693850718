import * as Yup from "yup";

import { EEntitiesLength, ESpecies } from "shared-type";

export const templateObsValidator = Yup.object().shape({
	name: Yup.string().required()
.max(EEntitiesLength.name),
	shortName: Yup.string().required()
.max(EEntitiesLength.shortName),
	identifier: Yup.string()
		.required()
		.max(EEntitiesLength.identifier)
		.matches(/^[a-zA-Z0-9_-]+$/, {
			message:
				"Need to start by letter and can contain only alphanumeric characters or '_' and '-'",
		}),
	species: Yup.mixed<ESpecies>().oneOf(Object.values(ESpecies))
.required(),
	label: Yup.string().max(EEntitiesLength.label),
	description: Yup.string().max(EEntitiesLength.description),
});
