import _ from "lodash";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
	EExperimentStatus,
	ESpecies,
	IGrowingAreaSerialized,
	NB_MAX_TRIAL_BY_MET,
} from "shared-type";

import { TrialSerializedWithDeps } from "App/components/experiment/trial/list/TrialWithDeps.hook";
import { LoadingStatus } from "../../../interfaces/loading-status";
import {
	loadTrialFromMET,
	saveMetTrials,
} from "./met-trial-management..fetch-reducer";

export type IMETTrialRowDetails = Partial<
	Omit<TrialSerializedWithDeps, "marketSegments">
> & {
	replication?: number;
	marketSegments?: string[];
};

export type IMETTrialRow = {
	id: number;
	isNew?: boolean;
	trial?: IMETTrialRowDetails;
	growingArea?: {
		id?: string;
		name?: string;
		region?: string;
		country?: string;
	};
	isSelected?: boolean;
	details?: TrialSerializedWithDeps;
};
type IMETTrialManagementState = {
	status: LoadingStatus;
	metId?: string;
	trialsRows?: IMETTrialRow[];
	isGrowingAreaOpen?: boolean;
	idsTrialsToDelete?: string[];
};

const initialState = (): IMETTrialManagementState => ({
	status: "idle",
});

const METTrialManagementSlice = createSlice({
	name: "met-trial-management",
	initialState: initialState(),
	reducers: {
		changeGrowingAreaOfTrial: (
			state,
			{
				payload,
			}: PayloadAction<{
				id: number;
				growingArea: { name?: string; id?: string };
			}>,
		) => {
			const trial = state?.trialsRows?.find(
				(elt) => elt.id === payload.id,
			);
			if (!trial) return;
			trial.growingArea = payload.growingArea;
		},
		changeTrialName: (
			state,
			{
				payload,
			}: PayloadAction<{
				id: number;
				newName: string;
			}>,
		) => {
			if (payload.newName.trim() === "") {
				return;
			}
			const trialRow = state?.trialsRows?.find(
				(elt) => elt.id === payload.id,
			);
			if (!trialRow) return;
			let count = 0;
			let newNameUnique = payload.newName;
			let alreadyUsedName: unknown;
			do {
				if (count > 0) {
					newNameUnique = `${payload.newName}_(${count})`;
				}
				alreadyUsedName = state?.trialsRows?.find(
					// eslint-disable-next-line no-loop-func
					(row) =>
						row.id !== payload.id &&
						row.trial?.name === newNameUnique,
				);
				count++;
			} while (alreadyUsedName !== undefined);
			trialRow.trial = trialRow.trial
				? { ...trialRow.trial, name: newNameUnique }
				: undefined;
		},
		onAddGAForTrial: (
			state,
			action: PayloadAction<{
				rowId: number;
				growingArea: IGrowingAreaSerialized;
			}>,
		) => {
			if (!state.trialsRows) {
				return;
			}
			const { growingArea, rowId } = action.payload;

			const trial = state.trialsRows.find((elt) => elt.id === rowId);
			if (!trial) {
				return;
			}

			trial.growingArea = {
				country: growingArea.country,
				name: growingArea.name,
				id: growingArea._id,
				region: growingArea.region,
			};
		},
		onAddGAsForTrials: (
			state,
			action: PayloadAction<{
				trials: Array<number>;
				growingAreas: Array<IGrowingAreaSerialized>;
			}>,
		) => {
			if (!state.trialsRows) {
				return;
			}
			const { trials, growingAreas } = action.payload;

			trials.forEach((currentTrial, index) => {
				const trial = state.trialsRows?.find(
					(elt) => elt.id === currentTrial,
				);
				if (!trial) {
					return;
				}
				const currentGA = growingAreas[index];
				if (!currentGA) {
					return;
				}
				trial.growingArea = {
					country: currentGA.country,
					name: currentGA.name,
					id: currentGA._id,
					region: currentGA.region,
				};
			});
		},
		onRemoveGAForTrial: (
			state,
			action: PayloadAction<{
				growingAreaId: string;
			}>,
		) => {
			if (!state.trialsRows) {
				return;
			}
			const { growingAreaId } = action.payload;

			const trial = state.trialsRows.find(
				(elt) => elt.growingArea?.id === growingAreaId,
			);
			if (!trial) {
				return;
			}

			trial.growingArea = undefined;
		},
		onRemoveGAsForTrials: (
			state,
			action: PayloadAction<{
				trials: Array<number>;
			}>,
		) => {
			if (!state.trialsRows) {
				return;
			}
			const { trials } = action.payload;
			trials.forEach((currentTrial) => {
				const trial = state.trialsRows?.find(
					(elt) => elt.id === currentTrial,
				);

				if (!trial) {
					return;
				}

				trial.growingArea = undefined;
			});
		},
		onSelectTrial: (
			state,
			{
				payload,
			}: PayloadAction<{
				id: number;
				check: boolean;
			}>,
		) => {
			const trialRow = state?.trialsRows?.find(
				(elt) => elt.id === payload.id,
			);
			if (!trialRow) return;
			trialRow.isSelected = payload.check;
		},
		onSelectTrials: (
			state,
			{
				payload,
			}: PayloadAction<{
				rows: Array<IMETTrialRow>;
				check: boolean;
			}>,
		) => {
			const trialRows = state?.trialsRows || [];
			trialRows.forEach((row) => {
				row.isSelected = payload.check;
			});
		},
		generateTrialByQuantity: (
			state,
			{
				payload: { quantity, baseName },
			}: PayloadAction<{ quantity: number; baseName: string }>,
		) => {
			const trials = state.trialsRows || [];
			let generationSeed = 1;
			const isConflictingTrial = (trialName: string, id: number) => {
				return trials.find(
					(elt) => elt.trial?.name === trialName || elt.id === id,
				);
			};
			for (let i = 0; i < quantity; i++) {
				// limit of trials in draft
				if (trials.length >= NB_MAX_TRIAL_BY_MET) {
					break;
				}
				// generate number with 3 digit like
				let trialName: string;
				do {
					trialName = `${baseName}_Trial_${_.padStart(
						generationSeed.toString(),
						3,
						"0",
					)}`;
					generationSeed++;
				} while (isConflictingTrial(trialName, generationSeed));

				trials.push({
					id: generationSeed,
					isSelected: false,
					isNew: true,
					trial: { name: trialName },
				});
			}

			state.trialsRows = trials;
		},
		reset: () => {
			return initialState();
		},
		setOpenGrowingAreaModal: (state, action: PayloadAction<boolean>) => {
			state.isGrowingAreaOpen = action.payload;
		},
		removeSelectedTrials: (state) => {
			const rowToDeleteSet = new Set(
				state.trialsRows
					?.filter((row) => row.isSelected)
					.map((row) => row.id),
			);
			// find existing trials to delete
			const existingTrialsToRemove = state.trialsRows
				?.filter(
					(row) =>
						rowToDeleteSet.has(row.id) &&
						row.trial?._id !== undefined,
				)
				.map((row) => row.trial?._id)
				.filter(_.isString); // used only for secure typing without cast

			// remove trials
			state.trialsRows = state.trialsRows?.filter(
				(row) => !rowToDeleteSet.has(row.id),
			);
			state.idsTrialsToDelete = _.uniq([
				...(state.idsTrialsToDelete ?? []),
				...(existingTrialsToRemove ?? []),
			]);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(loadTrialFromMET.pending, (state) => {
			return { ...state, status: "pending" };
		});
		builder.addCase(loadTrialFromMET.fulfilled, (state, action) => {
			return {
				...state,
				trialsRows: action.payload.trialRows,
				status: "succeeded",
				metId: action.payload.metId,
			};
		});
		builder.addCase(loadTrialFromMET.rejected, () => {
			return { status: "failed" };
		});
	},
});

export const METTrialManagementActions = {
	...METTrialManagementSlice.actions,
	loadTrialFromMET,
	saveMetTrials,
};
export const METTrialManagementReducer = METTrialManagementSlice.reducer;
