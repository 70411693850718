import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import {
	ICreateTemplateObservationCore,
	IFilter,
	IPatchTemplateObservationCore,
	ISort,
	ITemplateObsCoreSerialized,
} from "shared-type";

import { AbstractRequest } from "../abstract-request";

export class TemplateObsRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/template-observation";
	}

	createTemplateObs(
		createTemplateObs: ICreateTemplateObservationCore
	): Promise<AxiosResponse<string, any>> {
		return this.axios.post<
			string,
			AxiosResponse<string, any>,
			ICreateTemplateObservationCore
		>(`${this.baseUrl}`, createTemplateObs);
	}

	createManyTemplateObs(
		createTemplateObsList: ICreateTemplateObservationCore[]
	): Promise<AxiosResponse<string[], any>> {
		return this.axios.post<
			string,
			AxiosResponse<string[], any>,
			ICreateTemplateObservationCore[]
		>(`${this.baseUrl}/many`, createTemplateObsList);
	}

	duplicateTemplateObsByIds(
		templateObsIds: string[]
	): Promise<AxiosResponse<string[], any>> {
		return this.axios.post<string, AxiosResponse<string[], any>, string[]>(
			`${this.baseUrl}/duplicate`,
			templateObsIds
		);
	}

	getTemplateObsById(
		id: string,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<ITemplateObsCoreSerialized, any>> {
		return this.axios.get<ITemplateObsCoreSerialized>(
			`${this.baseUrl}/${id}`,
			config
		);
	}

	getTemplateObsUsageInfoById(
		id: string
	): Promise<AxiosResponse<string[], any>> {
		return this.axios.get<string[]>(`${this.baseUrl}/usage/${id}`);
	}

	getTemplatesObs(
		filter?: IFilter,
		sort?: ISort,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<ITemplateObsCoreSerialized[], any>> {
		return this.axios.get<ITemplateObsCoreSerialized[]>(`${this.baseUrl}`, {
			...config,
			params: {
				...config?.params,
				filter: JSON.stringify(filter),
				sort: JSON.stringify(sort),
			},
		});
	}

	updateTemplateObsById(
		id: string,
		updateObsTemplate: IPatchTemplateObservationCore
	): Promise<AxiosResponse<void, any>> {
		return this.axios.patch<void, AxiosResponse<void, any>>(
			`${this.baseUrl}/${id}`,
			updateObsTemplate
		);
	}

	deleteTemplateObsById(id: string): Promise<AxiosResponse<void, any>> {
		return this.axios.delete<void, AxiosResponse<void, any>>(
			`${this.baseUrl}/${id}`
		);
	}
}
