import React from "react";

import { AppBar } from "stories/base-components/Navigation/AppBar/AppBar";
import { useBloomeoAuth } from "../../auth/AuthContext";
import { useGetCurrentTenant } from "../../redux/tenant/hooks";

export default function TopBar() {
	const { userLogin: user } = useBloomeoAuth();
	const { tenant } = useGetCurrentTenant();

	return <AppBar companyName={tenant?.displayName || ""} user={user} />;
}
