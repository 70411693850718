import {
	Grid,
	GridColumn,
	GridColumnProps,
	GridDetailRowProps,
	GridExpandChangeEvent,
	GridProps,
} from "@progress/kendo-react-grid";
import { concatClassName } from "common";
import React, { useEffect } from "react";

import { StoryTheme } from "stories/StoryTheme";

import "./gridSimple.scss";

export type IBloomeoGridProps = GridProps & {
	columns: GridColumnProps[];
	details?: React.ComponentType<GridDetailRowProps>;
};

export function GridSimple(props: IBloomeoGridProps) {
	const { details, data, columns, ...gridProps } = props;
	const [gridData, setGridData] = React.useState((data as Array<any>) || []);

	const expandChange = (event: GridExpandChangeEvent) => {
		const newData = gridData.map((item: any) => {
			if (item._id === event.dataItem._id) {
				item.expanded = !event.dataItem.expanded;
			}
			return item;
		});
		setGridData(newData);
	};

	useEffect(() => {
		// This update ensures that each object in gridData has the 'expanded' property
		setGridData(
			data
				? (data as (unknown & { expanded: boolean })[]).map((item) => ({
						...item,
						expanded: false,
				  }))
				: [],
		);
	}, [data]);

	const onExpandChange = details ? expandChange : undefined;

	return (
		<StoryTheme>
			<Grid
				{...gridProps}
				data={gridData}
				resizable
				detail={details}
				expandField={details ? "expanded" : undefined}
				onExpandChange={onExpandChange}
				className={concatClassName(
					"no-animation",
					details ? "noGridHierarchy" : "",
				)}
			>
				{columns.map(
					(
						col: GridColumnProps,
						index: React.Key | null | undefined,
					) => {
						return (
							<GridColumn
								{...col}
								cell={
									col.cell && typeof col.cell === "function"
										? (props) => {
												// eslint-disable-next-line @typescript-eslint/no-explicit-any
												return (col as any).cell({
													...props,
													onExpandChange:
														onExpandChange,
												});
										  }
										: undefined
								}
								key={index}
							/>
						);
					},
				)}
			</Grid>
		</StoryTheme>
	);
}
