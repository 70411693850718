import React, { useMemo } from "react";
import { round } from "mathjs";
import "./KPICard.scss";

export default function KPICard(props: {
	value: string | number;
	title: string;
	hint: string;
	color?: string;
	option?: { roundNumber?: number };
}) {
	const color = props.color || undefined;
	const displayValue: string | number = useMemo(() => {
		if (
			props.option?.roundNumber !== undefined &&
			typeof props.value === "number"
		) {
			return round(props.value, props.option.roundNumber);
		}
		return props.value;
	}, [props]);
	return (
		<div className="KPICard-container flex-column">
			<div className="KPICard-header">
				<div className="KPICard-title" style={{ color }}>
					{props.title}
				</div>
				<div className="KPICard-hint">{props.hint}</div>
			</div>
			<div className="KPICard-value">{displayValue}</div>
		</div>
	);
}
