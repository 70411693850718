import React, { CSSProperties } from "react";

import { EGermplasmLevel, IGenotype, ILot } from "shared-type";

import { apiVariables } from "App/redux/variables/variables.api";
import { apiGermplasm } from "App/redux/germplasm/germplasm.api";
import { apiTrial } from "App/redux/experiment/trial/trial.api";
import { DIC, EDIC_KEY } from "App/dictionary";
import TitledTag from "../../base-components/tag/titled-tag/TitledTag";
import ImgBannerPictureCard from "../img-banner-picture-card/ImgBannerPictureCard";
import { IPictureCardProps } from "./PictureCard";

import "./PictureBanner.scss";

export interface IPictureBannerProps extends IPictureCardProps {
	style?: CSSProperties;
	className?: string;
}

export default function PictureBanner(props: IPictureBannerProps) {
	const { data: variable } = apiVariables.useGetVariableByIdQuery({
		id: props.resource.metadata.variableId,
		scope: props.resource.metadata.variableScope,
	});
	const { data: material } = apiGermplasm.useGetGermplasmByIdQuery({
		id: props.resource.metadata.materialId,
		level: props.resource.metadata.materialLevel,
	});
	const { data: trial } = apiTrial.useGetTrialByIdQuery(
		props.resource.metadata.trialId,
	);

	return (
		<ImgBannerPictureCard
			onClick={props.onClick}
			disabledClick={props.disabledClick}
			className={props.className}
			style={{ ...props.style }}
			sideBanner={{ imageSrc: props.resource.url, logoSrc: "" }}
			resource={props.resource}
		>
			<div className="PictureBanner-container flex-column">
				<div className="PictureBanner-top">
					<div className="PictureBanner-tags">
						<TitledTag
							title={DIC(EDIC_KEY.TRIAL)}
							tagValue={trial?.name}
						/>
						<TitledTag
							title={DIC(EDIC_KEY.MATERIAL)}
							tagValue={
								props.resource.metadata.materialLevel ===
								EGermplasmLevel.GENOTYPE
									? (material as IGenotype)?.name
									: (material as ILot)?.lotName
							}
						/>
					</div>
					<div className="PictureBanner-tags">
						<TitledTag
							title={DIC(EDIC_KEY.VARIABLE)}
							tagValue={variable?.name.en}
						/>
					</div>
				</div>

				<div className="PictureBanner-tags"></div>
			</div>
		</ImgBannerPictureCard>
	);
}
