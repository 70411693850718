import React, { useEffect, useState } from "react";

import { IResource, IUser } from "shared-type";

import { Like, Unlike } from "assets";
import { apiResource } from "App/redux/attachment/resource.api";
import CustomCard, {
	ICustomCardProps,
} from "../../base-components/card/CustomCard";
import { LIKE } from "../picture-constant";

import "./ImgBannerPictureCard.scss";

import UserTag from "App/components/base-components/tag/user-tag/UserTag";
import { DIC, EDIC_KEY } from "App/dictionary";
import DorianeButton from "App/components/base-components/button/DorianeButton";

interface ISideBannerCard {
	imageSrc: string;
	logoSrc: string;
}

interface IImgBannerPictureCardProps extends ICustomCardProps {
	sideBanner: ISideBannerCard;
	resource: IResource;
	user?:IUser
}

export default function ImgBannerPictureCard(
	props: IImgBannerPictureCardProps,
) {
	const [isLike, setIsLike] = useState<boolean>(false);
	const [updateResource] = apiResource.useUpdateResourceMutation();

	useEffect(() => {
		setIsLike(props.resource.tags.includes(LIKE));
	}, [props.resource]);

	const handleClick = async () => {
		let tags = [];

		setIsLike(!isLike);

		if (props.resource.tags.includes(LIKE)) {
			tags = [...props.resource.tags.filter((tag) => tag !== LIKE)];
		} else {
			tags = [...props.resource.tags, LIKE];
		}

		await updateResource({
			id: props.resource._id,
			update: {
				tags,
			},
		});
	};

	return (
		<CustomCard
			onClick={props.onClick}
			className={
				props.className
					? props.className + " ImgBannerPictureCard-container "
					: "ImgBannerPictureCard-container"
			}
			disabledClick={props.disabledClick}
			style={props.style}
			isSelected={props.isSelected}
		>
			<div
				className="ImgBannerPictureCard-side-banner-image"
				style={{
					backgroundImage: `url("${props.sideBanner.imageSrc}")`,
				}}
			>
				<div className="ImgBannerPictureCard-side-banner-image-side">
					{props.user && 	
						<UserTag title={DIC(EDIC_KEY.CREATED_BY)} user={props.user} /> 
					}
					
					<div className="ImgBannerPictureCard-side-banner-image-layer">
					<DorianeButton dorianeStyle="quaternary"
						className="ImgBannerPictureCard-side-banner-image-layer-like-button"
						onClick={handleClick}>
						<img
							className="ImgBannerPictureCard-side-banner-image-icon"
							src={isLike ? Like : Unlike}
						/>
					</DorianeButton>

					</div>
				</div>
			</div>

			<div className="ImgBannerPictureCard-body">{props.children}</div>
		</CustomCard>
	);
}
