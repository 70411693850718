import { ITrialDuplicationInfo } from "shared-type";

import { ITrialDuplicateForm } from "./TrialDuplicateForm";

export function trialDuplicateFormToObject(
	form: Partial<ITrialDuplicateForm> & { id: string },
): ITrialDuplicationInfo {
	return {
		trialsData: [
			{
				name: form.name || "",
				id: form.id,
			},
		],
		startDate: new Date(form.periodFrom?.toISOString() as string),
		endDate: new Date(form.periodTo?.toISOString() as string),
		copyMaterial: form.material || false,
		copyGrowingArea: form.growingArea || false,
		copyTemplateObs: form.obsTemplate || false,
		copyTasks: form.duplicateTasks,
		copyTaskUser: form.duplicateTasks
			? form.tasks === "tasks_users"
			: false,
	};
}
