import React, { ReactNode } from "react";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	styled,
} from "@mui/material";

import { Button, IButtonType } from "stories/base-components/Button/Button";
import { Typography } from "stories/base-components/Typography/Typography";
import Colors from "stories/constants/Colors/Colors";
import { Icon } from "stories/base-components/Icon/Icon";
import { IIconType } from "stories/base-components/Icon/IconType";
import { StoryTheme } from "stories/StoryTheme";
import Size from "stories/constants/Size/Size";
import { Tooltip } from "../Tooltip/Tooltip";
import FlexBox from "App/components/base-components/placement/flex-box/FlexBox";
import Box from "App/components/base-components/placement/box/Box";

import "./modal.scss";

export type IModalType = "info" | "warning" | "success" | "error";

const minWidth = 500;
const maxWidth = 1100;

const types = {
	info: {
		color: Colors.active70,
		btnType: "info",
		titleIcon: "info--filled" as IIconType,
	},
	warning: {
		color: Colors.yellow70,
		btnType: "warning",
		titleIcon: "warning-error--filled" as IIconType,
	},
	error: {
		color: Colors.red50,
		btnType: "error",
		titleIcon: "warning-error--filled" as IIconType,
	},
	success: {
		color: Colors.accent60,
		btnType: "primary",
		titleIcon: "check-circle--filled" as IIconType,
	},
};

export type IModalButton = {
	action?: () => void;
	label?: string;
	isLoading?: boolean;
	disabled?: boolean;
	tooltipMessage?: string;
	type?: IButtonType;
	iconName?: IIconType;
};

const ModalButton = (props: IModalButton) => {
	return (
		<Tooltip text={props.tooltipMessage || ""} addContainer>
			<Button
				onClick={props.action}
				text={props.label}
				isLoading={props.isLoading}
				buttonType={props.type}
				disabled={props.disabled}
				icon={
					props.iconName
						? {
								name: props.iconName,
						  }
						: undefined
				}
			/>
		</Tooltip>
	);
};

export type IModal = {
	open: boolean;
	title: string;
	handleClose: () => void;
	cancelAction?: () => void;
	cancelLabel?: string;
	validateBtn?: IModalButton;
	secondaryBtn?: IModalButton;
	children: ReactNode;
	width?: number;
	maxSize?: boolean;
	modalType?: IModalType;
	titleIcon?: IIconType;
	stepper?: Array<string>;
	stepperSelected?: number;
	onStepperClick?: (step: number) => void;
	maxHeight?: number;
};
const dialogPropsExclude: Set<string> = new Set<keyof IModal>([
	"maxSize",
	"maxHeight",
]);
const DialogStyled = styled(Dialog, {
	shouldForwardProp: (propName: string) => !dialogPropsExclude.has(propName),
})<Partial<IModal>>(({ theme, width, maxSize, modalType, maxHeight }) => {
	let modalWidth = maxSize ? maxWidth : width ? width : minWidth;
	if (modalWidth > maxWidth) {
		modalWidth = maxWidth;
	}
	if (modalWidth < minWidth) {
		modalWidth = minWidth;
	}
	return {
		"& .MuiPaper-root.modalPaper": {
			width: modalWidth,
			height: maxSize || maxHeight ? "100vh" : undefined,
			maxHeight: maxSize ? "calc( 100% - " + Size.x5l + " )" : maxHeight,
			borderTop: modalType
				? Size.borderStroke + " solid " + types[modalType].color
				: undefined,
			borderRight: modalType
				? Size.borderStroke + " solid " + types[modalType].color
				: undefined,
			borderBottom: modalType
				? Size.borderStrokeBig + " solid " + types[modalType].color
				: undefined,
			borderLeft: modalType
				? Size.borderStroke + " solid " + types[modalType].color
				: undefined,
		},
	};
});

export const Modal = (modal: IModal) => {
	const {
		title,
		handleClose,
		validateBtn,
		titleIcon,
		stepperSelected,
		secondaryBtn,
		onStepperClick,
		...dialogStyledProps
	} = modal;

	return (
		<StoryTheme>
			<DialogStyled
				{...dialogStyledProps}
				onClose={(event, reason) => {
					if (reason === "backdropClick") {
						return;
					}
					handleClose();
				}}
				fullWidth
				maxWidth={false}
				PaperProps={{ className: "modalPaper" }}
			>
				<DialogTitle className="modalTitleContainer">
					<FlexBox className="modalTitleContainerTop">
						<div className="modalTitleInnerContainer">
							{(modal.modalType || titleIcon) && (
								<div style={{ marginRight: Size.xs }}>
									<Icon
										name={
											titleIcon
												? titleIcon
												: types[
														modal.modalType as IModalType
												  ].titleIcon
										}
										color={
											titleIcon
												? Colors.neutral100
												: types[
														modal.modalType as IModalType
												  ].color
										}
										fontSize={Size.md}
									/>
								</div>
							)}
							<Typography variant="heading1" text={title} />
						</div>

						<Button
							iconButton
							icon={{ name: "x-large" }}
							buttonType="tertiary"
							magicIcon
							onClick={handleClose}
						/>
					</FlexBox>

					{modal.stepper && (
						<FlexBox justifyContent="center" gap={"75px"}>
							{modal.stepper.map(
								(stepLabel: string, index: number) => (
									<Box
										key={index}
										onClick={() => {
											if (onStepperClick) {
												onStepperClick(index);
											}
										}}
										style={{
											cursor: "pointer",
											borderBottom:
												stepperSelected === index
													? "4px #00b0f0 solid"
													: undefined,
											paddingBottom:
												stepperSelected === index
													? "8px"
													: undefined,
										}}
									>
										<Typography
											text={index + 1 + ". " + stepLabel}
											color={
												stepperSelected === index
													? Colors.active60
													: Colors.neutral100
											}
										/>
									</Box>
								),
							)}
						</FlexBox>
					)}
				</DialogTitle>
				<DialogContent>{modal.children}</DialogContent>
				<DialogActions className="modalActionContainer">
					<Button
						onClick={
							modal.cancelAction
								? modal.cancelAction
								: handleClose
						}
						text={modal.cancelLabel ? modal.cancelLabel : "Cancel"}
						noPadding
						buttonType="tertiary"
					/>
					<div className="modalActionButtonsContainer">
						{secondaryBtn && (
							<div className="modalActionSecondaryContainer">
								<ModalButton
									action={secondaryBtn?.action}
									label={secondaryBtn?.label}
									isLoading={secondaryBtn?.isLoading}
									type={
										modal.modalType
											? (types[modal.modalType]
													.btnType as IButtonType)
											: "secondary"
									}
									disabled={secondaryBtn?.disabled}
									tooltipMessage={
										secondaryBtn?.tooltipMessage
									}
									iconName={secondaryBtn?.iconName}
								/>
							</div>
						)}

						<ModalButton
							action={validateBtn?.action}
							label={validateBtn?.label}
							isLoading={validateBtn?.isLoading}
							type={
								modal.modalType
									? (types[modal.modalType]
											.btnType as IButtonType)
									: "primary"
							}
							disabled={validateBtn?.disabled}
							tooltipMessage={validateBtn?.tooltipMessage}
							iconName={validateBtn?.iconName}
						/>
					</div>
				</DialogActions>
			</DialogStyled>
		</StoryTheme>
	);
};
