import { IFilter } from "shared-type";
import { getValueFromFlattenedKey } from "common";

export type FilterType = "string" | "int";
export interface IDorianeBaseFilter<TValue, Type extends FilterType> {
	key: string;
	type: Type;
	isEnabled?: boolean;
	value?: TValue;
}
export type IDorianeStringFilter = IDorianeBaseFilter<string, "string">;
export type IDorianeIntFilter = IDorianeBaseFilter<string, "int">;

export type IDorianeFilter = IDorianeStringFilter | IDorianeIntFilter;

export type IDorianeObjectFilter = {
	[key: string]: IDorianeFilter | undefined;
};

export function convertDorFilterToFilter(filter: IDorianeFilter): IFilter {
	if (!filter.value) {
		return {};
	}
	switch (filter.type) {
		case "int":
			return { [filter.key]: parseInt(filter.value) };
		default:
			return { [filter.key]: { $regex: filter.value, $options: "i" } };
	}
}
export function convertDorObjFilterToFilter(
	filter: IDorianeObjectFilter,
	ORFilter = false,
): IFilter {
	let mongoFilter: IFilter = ORFilter ? { $or: [] } : {};
	let hasValue = false; // Flag to track if there's at least one valid value
	for (const [key, value] of Object.entries(filter)) {
		if (value) {
			hasValue = true;
			if (ORFilter) {
				(mongoFilter.$or as IFilter[]).push(
					convertDorFilterToFilter(value),
				);
			} else {
				mongoFilter = {
					...mongoFilter,
					...convertDorFilterToFilter(value),
				};
			}
		}
	}
	// If no valid value found, return an empty filter
	if (!hasValue) {
		mongoFilter = {};
	}
	return mongoFilter;
}

export function applyDorianeFilter<T>(data: T[], filter: IDorianeObjectFilter) {
	let result: T[] = data;
	for (const [_, crtFilter] of Object.entries(filter)) {
		if (!crtFilter) continue;
		result = result.filter((elt) => {
			switch (crtFilter.type) {
				case "string": {
					const value = getValueFromFlattenedKey(crtFilter?.key, elt);
					if (!crtFilter.value) {
						return true;
					}
					if (value && typeof value === "string") {
						return value
							.toLowerCase()
							.includes(crtFilter.value.toLowerCase());
					}
					return false;
				}
				default:
					throw new Error("Not implemented");
			}
		});
	}
	return result;
}
