import React, { ReactNode } from "react";
import { CircularProgress } from "@mui/material";

import { concatClassName } from "common";

import styles from "sassTheme";
import "./DorianeButton.scss";

type DorianeButtonStyle =
	| "primary"
	| "secondary"
	| "tertiary"
	| "quaternary"
	| "none";

function getDorianeButtonStyle(dorianeStyle?: DorianeButtonStyle): string {
	switch (dorianeStyle) {
		case "primary":
			return "DorianeButton-primary";
		case "secondary":
			return "DorianeButton-secondary";
		case "tertiary":
			return "DorianeButton-tertiary";
		case "quaternary":
			return "DorianeButton-quaternary";
		case "none":
			return "";
		default:
			return "DorianeButton-secondary";
	}
}

export default function DorianeButton(props: {
	dorianeStyle?: DorianeButtonStyle;
	onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
	disabled?: boolean;
	className?: string;
	children?: ReactNode;
	style?: React.CSSProperties;
	isActive?: boolean;
	isLoading?: boolean;
}) {
	const className = concatClassName(
		"DorianeButton",
		getDorianeButtonStyle(props.dorianeStyle),
		props.className,
		props.disabled ? "DorianeButton-disable" : "",
		props.isActive ? "DorianeButton-active" : "",
	);
	const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if (props.onClick) {
			event.stopPropagation();
			if (!props.disabled) {
				props.onClick(event);
			}
		}
	};

	return (
		<div className={className} onClick={handleOnClick} style={props.style}>
			{props.isLoading ? (
				<CircularProgress
					size={18}
					style={{
						color:
							props.dorianeStyle === "primary"
								? styles.white
								: styles["primary-color-dark-1"],
					}}
				/>
			) : (
				props.children
			)}
		</div>
	);
}
