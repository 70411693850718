import React from "react";

import "./FormSectionTitle.scss";

export interface IFormSectionTitleProps {
	title: string;
}
export default function FormSectionTitle(props: IFormSectionTitleProps) {
	return <h1 className="FormSectionTitle">{props.title}</h1>;
}
