import { createTheme } from "@mui/material/styles";

import Colors from "./constants/Colors/Colors";
import Size from "./constants/Size/Size";

export default createTheme({
	palette: {
		primary: {
			main: Colors.accent60,
			light: Colors.accent40,
			dark: Colors.accent70,
			contrastText: Colors.neutral10,
		},
		secondary: {
			main: Colors.primary60,
			light: Colors.primary20,
			dark: Colors.primary70,
			contrastText: Colors.neutral10,
		},
		info: {
			main: Colors.active70,
			light: Colors.active60,
			dark: Colors.active80,
			contrastText: Colors.neutral10,
		},
		warning: {
			main: Colors.yellow70,
			light: Colors.yellow50,
			dark: Colors.yellow80,
			contrastText: Colors.neutral10,
		},
		error: {
			main: Colors.red50,
			light: Colors.red40,
			dark: Colors.red60,
			contrastText: Colors.neutral10,
		},
	},
	typography: {
		fontFamily: "Inter",
	},
	components: {
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: Colors.primary80,
					padding: `${Size.xs} ${Size.base}`,
					borderRadius: Size.borderRadius,
				},
				arrow: {
					color: Colors.primary80,
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					borderRadius: Size.cornerRadius,
					boxShadow: "unset",
				},
			},
		},
	},
});
