import React, { useEffect, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";

import {
	EMultiComputeMethod,
	EMultiExpectationType,
	EVariableCoreInputType,
	EVariableCoreType,
	IVarLimitedChoice,
	IVarMultiNotation,
	VariableConstraint,
} from "shared-type";

import { EFormInputType } from "App/components/base-components/dynamic-form";
import FormSection from "App/components/base-components/dynamic-form/form-section/FormSection";
import ConstraintForm from "App/components/variables/variable-modal/form/ConstraintForm";
import {
	EditingVarMultiNotation,
	MultiNotationConverter,
} from "App/components/variables/variable-modal/form/editing-variable-multi-notation";
import LimitedChoicesForm from "App/components/variables/variable-modal/form/LimitedChoicesForm";
import MultiNotationForm from "App/components/variables/variable-modal/form/MultiNotationForm";
import { FormMode } from "../../../const";
import { DIC, EDIC_KEY } from "../../../dictionary";
import { getDicVarType } from "../../../dictionary/dictionary-key-getter";
import DynamicFormV2 from "App/components/base-components/dynamic-form/DynamicFormV2";
import { Checkbox } from "stories/base-components/Checkbox/Checkbox";
import Colors from "stories/constants/Colors/Colors";
import { Tooltip } from "stories/base-components/Tooltip/Tooltip";
import { Icon } from "stories/base-components/Icon/Icon";

import "./CustomVariableForm.scss";

export interface ICustomVariableForm {
	identifier: string;
	name: string;
	shortName: string;
	description?: string;
	inputType: EVariableCoreInputType;
	unit?: string;
	type: EVariableCoreType;
	multiNotation?: IVarMultiNotation;
	constraint?: VariableConstraint;
	limitedChoices?: IVarLimitedChoice[];
}

interface CustomVariableFormProps {
	mode: FormMode;
	form: UseFormReturn<ICustomVariableForm>;
	formTabSelected?: number;
	setFormTabSelected?: React.Dispatch<React.SetStateAction<number>>;
}

const isTypeConstraintType = (
	type: EVariableCoreType,
): type is
	| EVariableCoreType.DATE
	| EVariableCoreType.DATETIME
	| EVariableCoreType.STRING
	| EVariableCoreType.INT
	| EVariableCoreType.DEC => {
	const constraintTypes = [
		EVariableCoreType.DATE,
		EVariableCoreType.DATETIME,
		EVariableCoreType.STRING,
		EVariableCoreType.INT,
		EVariableCoreType.DEC,
	];
	return constraintTypes.includes(type);
};
const isTypeLimitedType = (
	type: EVariableCoreType,
): type is
	| EVariableCoreType.BOOLEAN
	| EVariableCoreType.STRING
	| EVariableCoreType.INT
	| EVariableCoreType.DEC => {
	const limitedTypes = [
		EVariableCoreType.BOOLEAN,
		EVariableCoreType.STRING,
		EVariableCoreType.INT,
		EVariableCoreType.DEC,
	];
	return limitedTypes.includes(type);
};
const isMultiNotationType = (
	type: EVariableCoreType,
): type is EVariableCoreType.INT | EVariableCoreType.DEC => {
	const multiNotationType = [EVariableCoreType.INT, EVariableCoreType.DEC];
	return multiNotationType.includes(type);
};

const defaultMultiNotation: IVarMultiNotation = {
	computeMethod: EMultiComputeMethod.AVERAGE,
	expectationType: EMultiExpectationType.FREE,
};

export default function CustomVariableForm(props: CustomVariableFormProps) {
	const form = props.form;
	const { setValue } = form;

	// To force refresh when input onchange
	const [inputType, type, multiNotation, constraint] = useWatch({
		control: form.control,
		name: ["inputType", "type", "multiNotation", "constraint"],
	});

	const onMultiNotationTypeChange = (type: EVariableCoreType) => {
		if (isMultiNotationType(type)) {
			return multiNotation;
		}
		return undefined;
	};

	const handleMultiNotationChange = <
		D extends EditingVarMultiNotation = EditingVarMultiNotation,
		DKey extends keyof D = keyof EditingVarMultiNotation,
	>(
		key: DKey,
		value: D[DKey],
	): void => {
		const multiNotationConverted: IVarMultiNotation = {
			...multiNotation,
			...MultiNotationConverter.convertFromEdition({
				[key]: value,
			}),
		};
		if (
			multiNotationConverted.expectationType ===
			EMultiExpectationType.FREE
		) {
			multiNotationConverted.expectedNotationNb = undefined;
		} else if (multiNotationConverted.expectedNotationNb === undefined) {
			multiNotationConverted.expectedNotationNb = 1;
		}
		setValue("multiNotation", multiNotationConverted);
	};

	const [editInit, setEditInit] = useState<boolean>(false);

	useEffect(() => {
		if (!editInit) {
			setEditInit(true);
			return;
		}
		setValue(
			"inputType",
			isTypeLimitedType(type) ? inputType : EVariableCoreInputType.FREE,
		);
		setValue("constraint", { type: type as any });
		setValue("limitedChoices", []);
		setValue("multiNotation", onMultiNotationTypeChange(type));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	return (
		<div className="CustomVariableForm-container">
			{props.formTabSelected === 0 && (
				<FormSection title={DIC(EDIC_KEY.GENERAL_INFORMATION)}>
					<DynamicFormV2
						control={form.control}
						formInfos={[
							{
								inputType: EFormInputType.STRING,
								id: "identifier",
								label: DIC(EDIC_KEY.ID),
								required: true,
								disabled: props.mode !== "creation",
							},
							{
								inputType: EFormInputType.STRING,
								id: "name",
								label: DIC(EDIC_KEY.NAME),
								required: true,
							},
							{
								inputType: EFormInputType.STRING,
								id: "shortName",
								label: DIC(EDIC_KEY.SHORT_NAME),
								required: true,
							},
							{
								inputType: EFormInputType.STRING,
								id: "description",
								label: DIC(EDIC_KEY.DESCRIPTION),
								takeAllRow: true,
								multiline: true,
							},
						]}
					/>
				</FormSection>
			)}
			{props.formTabSelected === 1 && (
				<>
					<FormSection title={DIC(EDIC_KEY.SETTINGS)}>
						<DynamicFormV2
							control={form.control}
							formInfos={[
								{
									inputType:
										props.mode === "used"
											? EFormInputType.STRING
											: EFormInputType.COMBOBOX,
									id: "type",
									label: DIC(EDIC_KEY.TYPE),
									comboboxValues:
										Object.values(EVariableCoreType),
									translateMethod: getDicVarType,
									required: props.mode === "used",
									disabled: props.mode === "used",
								},
								{
									inputType: EFormInputType.STRING,
									id: "unit",
									label: DIC(EDIC_KEY.UNIT),
								},
							]}
						/>
					</FormSection>
					<div
						className={
							inputType === EVariableCoreInputType.LIMITED_CHOICE
								? "CustomVariableForm-block-limitedChoiceContainer"
								: ""
						}
					>
						<div
							className="CustomVariableForm-block"
							style={{
								display: "flex",
								alignItems: "center",
								gap: 0,
							}}
						>
							<Checkbox
								checked={
									inputType ===
									EVariableCoreInputType.LIMITED_CHOICE
								}
								onChange={(_, checked) =>
									setValue(
										"inputType",
										checked
											? EVariableCoreInputType.LIMITED_CHOICE
											: EVariableCoreInputType.FREE,
									)
								}
								disabled={
									props.mode === "used" ||
									!isTypeLimitedType(type)
								}
								label={DIC(EDIC_KEY.LIMITED_CHOICE)}
							/>
							<Tooltip
								text="Type in label and value to add limited choices."
								addContainer
								placement="top"
							>
								<Icon
									name="info--filled"
									color={Colors.neutral90}
								/>
							</Tooltip>
						</div>

						<div className="CustomVariableForm-block flex-column">
							{type &&
								(inputType ===
								EVariableCoreInputType.LIMITED_CHOICE
									? // LIMITED_CHOICE VALUES
									  isTypeLimitedType(type) && (
											<LimitedChoicesForm
												mode={props.mode}
												form={form}
											/>
									  )
									: // FREE INPUT CONSTRAINTS
									  isTypeConstraintType(type) && (
											<ConstraintForm
												mode={props.mode}
												form={form}
												isTypeConstraintType={
													isTypeConstraintType
												}
											/>
									  ))}
						</div>
					</div>
					<div className="CustomVariableForm-block flex-column">
						{isMultiNotationType(type) && (
							<span>
								<Checkbox
									checked={multiNotation !== undefined}
									onChange={(_, checked) => {
										if (checked) {
											setValue(
												"multiNotation",
												defaultMultiNotation,
											);
										} else {
											setValue(
												"multiNotation",
												undefined,
											);
										}
									}}
									disabled={props.mode === "used"}
									label={DIC(EDIC_KEY.MULTI_NOTATIONS)}
								/>
							</span>
						)}

						{multiNotation !== undefined && (
							<MultiNotationForm
								mode={props.mode}
								form={form}
								multiNotation={MultiNotationConverter.convertToEdition(
									multiNotation ?? defaultMultiNotation,
								)}
								setMultiNotation={handleMultiNotationChange}
							/>
						)}
					</div>
				</>
			)}
		</div>
	);
}
