import { Pagination } from "@mui/material";
import React from "react";

import "./PaginationTool.scss";

interface IProps {
	currentPage: number;
	maxPage: number;
	onPageRoll: (pageIndex: number) => void;
}

const PaginationTool = (props: IProps) => {
	const maxPageInc = props.maxPage;
	const currentPageInc = props.currentPage + 1; // Index translation

	function handlePageChange(
		event: React.ChangeEvent<unknown>,
		page: number,
	): void {
		props.onPageRoll(page - 1); // Index translation
	}

	return (
		<Pagination
			color="secondary"
			onChange={handlePageChange}
			count={maxPageInc}
			page={currentPageInc}
			showFirstButton
			showLastButton
			className="PaginationTool"
		/>
	);
};

export default PaginationTool;
