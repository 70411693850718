import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { CustomLinkTab } from "../../../../../../../components/base-components/tabs/CustomLinkTab";
import { CustomTabs } from "../../../../../../../components/base-components/tabs/CustomTabs";
import { DIC, EDIC_KEY } from "../../../../../../../dictionary";
import { useAppDispatch } from "../../../../../../../hooks/reduxHook";
import { ECWAction } from "../../../../../../../redux/ECW/ECW.slice";
import {
	PATH_GANTT,
	PATH_OBSERVATION_ROUND,
	PATH_OPERATION,
} from "../../../../../../../routes";

import "./ExpPlanningPage.scss";

export default function ExpPlanningPage() {
	const location = useLocation();
	const nav = useNavigate();
	const dispatch = useAppDispatch();
	const { trialId } = useParams();

	// If there is the parameter trialId, it means that the user is on the trial page
	const isTrial = useMemo(() => trialId !== undefined, [trialId]);

	const [tabIdx, setTabIdx] = useState(0);

	useEffect(() => {
		const splittedPath = location.pathname.split("/");
		const currentTab = "/" + splittedPath.slice(-1) ?? undefined;

		switch (currentTab) {
			case PATH_GANTT:
				dispatch(ECWAction.setIsPageFullSize(true));
				setTabIdx(0);
				break;
			case PATH_OPERATION:
				dispatch(ECWAction.setIsPageFullSize(false));
				setTabIdx(1);
				break;
			case PATH_OBSERVATION_ROUND:
				dispatch(ECWAction.setIsPageFullSize(false));
				setTabIdx(2);
				break;
			default:
				nav(`.${PATH_GANTT}`, { replace: true });
				dispatch(ECWAction.setIsPageFullSize(true));
				setTabIdx(0);
				break;
		}
	}, [dispatch, location, nav]);

	useEffect(() => {
		return () => {
			dispatch(ECWAction.setIsPageFullSize(false));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="ExpPlanningPage-container flex-column full-parent-size">
			<CustomTabs value={tabIdx} indicatorColor="secondary">
				<CustomLinkTab
					secondaryLevel={true}
					label={DIC(EDIC_KEY.GANTT)}
					href={`.${PATH_GANTT}`}
				/>
				<CustomLinkTab
					secondaryLevel={true}
					label={DIC(
						isTrial
							? EDIC_KEY.OPERATION
							: EDIC_KEY.OPERATION_TEMPLATE,
					)}
					href={`.${PATH_OPERATION}`}
				/>
				<CustomLinkTab
					secondaryLevel={true}
					label={DIC(
						isTrial
							? EDIC_KEY.OBSERVATION
							: EDIC_KEY.OBSERVATION_TEMPLATE,
					)}
					href={`.${PATH_OBSERVATION_ROUND}`}
				/>
			</CustomTabs>

			<div className="ExpPlanningPage-tab-container take-remaining-space">
				<Outlet />
			</div>
		</div>
	);
}
