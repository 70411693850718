import { Tooltip } from "@mui/material";
import React from "react";

import { concatClassName } from "common";

import { IColumn } from "../DorianeDataGrid";
import { Typography } from "stories/base-components/Typography/Typography";
import Colors from "stories/constants/Colors/Colors";

export type IDorianeDataGridCellProps<Row> = {
	column: IColumn<Row>;
	row: Row;
	cellOnClick?: (
		event: React.MouseEvent<HTMLDivElement>,
		row: Row,
		column: IColumn<Row>,
	) => void;
	secondaryStyle?: boolean;
};
export function DorianeDataGridCell<Row>(
	props: IDorianeDataGridCellProps<Row>,
) {
	const cellRender = props.column.getCellRenderer(props.row);
	const isCellRenderString = typeof cellRender === "string";
	const classString =
		typeof cellRender === "string" ? " DorianeDataGridCell-string" : "";

	return (
		<div
			className={concatClassName(
				props.secondaryStyle
					? "DorianeDataGridCellSecondaryStyle"
					: "DorianeDataGridCell",
				"DorianeDataGridCell-content",
				classString,
				props.column.cellClassName,
			)}
			onClick={(event) =>
				props.cellOnClick?.(event, props.row, props.column)
			}
		>
			{isCellRenderString ? (
				<Tooltip title={cellRender}>
					<div className="DorianeDataGridCellTextContainer">
						<Typography
							text={cellRender}
							variant={
								props.secondaryStyle ? "smallRegular" : "body2"
							}
							color={Colors.neutral100}
							noWrap
						/>
					</div>
				</Tooltip>
			) : (
				cellRender
			)}
		</div>
	);
}
