import React from "react";
import { UseFormReturn } from "react-hook-form";

import { ESpecies } from "shared-type";

import { DIC, EDIC_KEY } from "../../../dictionary";
import { getDicSpecies } from "../../../dictionary/dictionary-key-getter";
import { EFormInputType } from "../../base-components/dynamic-form";
import FormSection from "../../base-components/dynamic-form/form-section/FormSection";
import { FormMode } from "App/const";
import DynamicFormV2 from "App/components/base-components/dynamic-form/DynamicFormV2";

export interface ITemplateObsForm {
	name: string;
	identifier: string;
	shortName: string;
	description?: string;
	species: ESpecies;
}

interface TemplateObsFormProps {
	form: UseFormReturn<ITemplateObsForm>;
	mode: FormMode;
}

export default function TemplateObsForm(props: TemplateObsFormProps) {
	const form = props.form;

	return (
		<div className="TemplateObsForm-container">
			<FormSection title={DIC(EDIC_KEY.GENERAL_INFORMATION)}>
				<DynamicFormV2
					control={form.control}
					formInfos={[
						{
							inputType: EFormInputType.STRING,
							id: "name",
							label: DIC(EDIC_KEY.NAME),
							required: true,
						},
						{
							inputType: EFormInputType.STRING,
							id: "shortName",
							required: true,
							label: DIC(EDIC_KEY.SHORT_NAME),
						},
						{
							inputType: EFormInputType.COMBOBOX,
							id: "species",
							label: DIC(EDIC_KEY.SPECIES),
							comboboxValues: Object.values(ESpecies),
							translateMethod: getDicSpecies,
							required: true,
							disabled: props.mode !== "creation",
						},
						{
							inputType: EFormInputType.STRING,
							id: "identifier",
							label: DIC(EDIC_KEY.ID),
							required: true,
							disabled: props.mode !== "creation",
						},
						{
							inputType: EFormInputType.STRING,
							id: "description",
							label: DIC(EDIC_KEY.DESCRIPTION),
							multiline: true,
							takeAllRow: true,
						},
					]}
				/>
			</FormSection>
		</div>
	);
}
