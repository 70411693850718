import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import _ from "lodash";
import React, { CSSProperties, useCallback, useMemo } from "react";

import { DIC, EDIC_KEY } from "../../../../dictionary";
import DorianeChip from "../../chip/DorianeChip";
import { CustomCheckbox } from "../../checkbox/CustomCheckbox";
import Values from "stories/constants/Values";

import styles from "sassTheme";
import "./ChipCombobox.scss";

interface ChipComboboxProps<T> {
	className?: string;
	required?: boolean;
	value: T[];
	onChange: (value: T[]) => void;
	translateMethod: (value: T) => string;
	label: string;
	comboboxValues: T[];
	noWrap?: boolean;
	groupBy?: (value: T) => string;
	groupSort?: (a: string, b: string) => number;
	style?: CSSProperties;
	selectAllBtn?: boolean;
}
const IGNORE_ITEM = "ignore";
export default function ChipCombobox<T extends string | number>(
	props: ChipComboboxProps<T>,
) {
	const isAllSelected = useMemo(() => {
		return props.value.length === props.comboboxValues.length;
	}, [props.comboboxValues.length, props.value.length]);
	const comboboxList: { groupName: string | null; values: T[] }[] =
		useMemo(() => {
			if (!props.groupBy) {
				return [{ values: props.comboboxValues, groupName: null }];
			}
			const groupedMap = new Map<string, T[]>();
			for (const value of props.comboboxValues) {
				const group = props.groupBy?.(value) || "";
				if (!groupedMap.has(group)) {
					groupedMap.set(group, []);
				}
				groupedMap.get(group)?.push(value);
			}

			const groupArray = Array.from(groupedMap).map(
				([groupName, values]) => {
					return { groupName, values };
				},
			);
			if (props.groupSort !== undefined) {
				groupArray.sort(
					(a, b) => props.groupSort?.(a.groupName, b.groupName) || 0,
				);
			}
			return groupArray;
		}, [props]);

	const onGroupSelect = useCallback(
		(group: string | null) => {
			const groupValues =
				comboboxList.find((elt) => elt.groupName === group)?.values ??
				[];
			const notSelectedItemsOfGroup = _.difference(
				groupValues,
				props.value,
			);

			// remove if all selected
			if (notSelectedItemsOfGroup.length === 0) {
				const setToRemove = new Set(groupValues);
				props.onChange(
					props.value.filter((elt) => !setToRemove.has(elt)),
				);
				return;
			}
			const newValue = [...props.value, ...notSelectedItemsOfGroup];
			props.onChange(_.uniq(newValue));
		},
		[comboboxList, props],
	);
	const onSelectAll = useCallback(() => {
		if (isAllSelected) {
			// unselect all
			props.onChange([]);
			return;
		}
		// Select all
		props.onChange(props.comboboxValues);
	}, [isAllSelected, props]);

	return (
		<FormControl
			className={
				"Combobox" + (props.className ? " " + props.className : "")
			}
			style={props.style}
		>
			<InputLabel required={props.required}>{props.label}</InputLabel>
			<Select
				value={props.value}
				MenuProps={{
					variant: "menu",
					sx: {
						maxHeight: Values.doubleInputWidth,
						maxWidth: "400px",
					},
				}}
				multiple
				label={props.label}
				style={{ minHeight: styles["element-min-height"] }}
				onChange={(event) => {
					const value = event.target.value;
					if (
						typeof value !== "string" &&
						value[value.length - 1] !== IGNORE_ITEM
					) {
						props.onChange(value);
					}
				}}
				renderValue={(selected) => {
					return (
						<div
							className={
								"ChipCombobox-chipList" +
								(props.noWrap ? " NoWrap" : "")
							}
						>
							{selected.map((value, index) => (
								<DorianeChip<T>
									key={index}
									chipValue={value}
									label={props.translateMethod(value)}
								/>
							))}
						</div>
					);
				}}
			>
				{/* Select all btn */}
				{props.selectAllBtn !== undefined && (
					<MenuItem
						key={"SelectAll"}
						value={IGNORE_ITEM}
						onClick={onSelectAll}
						className={"ChipCombobox-group"}
					>
						{isAllSelected
							? DIC(EDIC_KEY.UNSELECT_ALL)
							: DIC(EDIC_KEY.SELECT_ALL)}
					</MenuItem>
				)}

				{/* Item list */}
				{comboboxList.map((group) => [
					group.groupName !== null ? (
						<MenuItem
							key={"grp." + group.groupName}
							value={IGNORE_ITEM}
							onClick={() => onGroupSelect(group.groupName)}
							className={"ChipCombobox-group"}
						>
							{group.groupName}
						</MenuItem>
					) : undefined,
					...group.values.map((value) => (
						<MenuItem key={"value." + value} value={value}>
							<CustomCheckbox
								checked={props.value?.indexOf(value) > -1}
							/>
							<div className="ChipCombobox-checkbox-label">
								{props.translateMethod(value)}
							</div>
						</MenuItem>
					)),
				])}
			</Select>
		</FormControl>
	);
}
