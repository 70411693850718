import { getter } from "@progress/kendo-react-common";
import {
	GanttColumnProps,
	GanttDateFilter,
	GanttRowProps,
	GanttTextFilter,
} from "@progress/kendo-react-gantt";
import { GanttCellProps } from "@progress/kendo-react-gantt/dist/npm/interfaces/GanttCellProps";
import { TreeListCell } from "@progress/kendo-react-treelist";
import React from "react";

import { concatClassName } from "common";

import {
	ErlenmeyerFlask,
	MET,
	ObsRound,
	Operation,
} from "../../../../../assets";
import { DIC, EDIC_KEY } from "../../../../dictionary";
import { getColorFromGanttStatus } from "../../../../utils";
import ColorTag from "../../../base-components/tag/color-tag/ColorTag";
import {
	EExpGanttTaskOrigin,
	EExpGanttTaskStatus,
} from "./ExperimentGantt.interface";

import "./ExperimentGanttColumns.scss";

export const ExpGanttFields = {
	id: "id",
	origin: "origin",
	originId: "originId",
	title: "title",
	start: "start",
	end: "end",
	trial: "trial",
	percentComplete: "percentComplete",
	status: "status",
	isRollup: "isRollup",
	isExpanded: "isExpanded",
	isInEdit: "isInEdit",
	isSelected: "isSelected",
	children: "subtasks",
};
export const getTaskId = getter(ExpGanttFields.id);

export const ExpGanttColumns: GanttColumnProps[] = [
	{
		field: ExpGanttFields.title,
		title: DIC(EDIC_KEY.NAME),
		width: 200,
		expandable: true,
		filter: GanttTextFilter,
		cell: (props: GanttCellProps) => {
			const cellRender = (trElement: any, rowProps: any) => {
				const originIcon = () => {
					let imgPath = "";
					switch (rowProps.dataItem[ExpGanttFields.origin]) {
						case EExpGanttTaskOrigin.MET:
							imgPath = MET;
							break;
						case EExpGanttTaskOrigin.TRIAL:
							imgPath = ErlenmeyerFlask;
							break;
						case EExpGanttTaskOrigin.OPERATION:
							imgPath = Operation;
							break;
						case EExpGanttTaskOrigin.OBS_ROUND:
							imgPath = ObsRound;
							break;
					}
					return (
						<span
							className="k-svg-icon k-svg-i-caret-alt-right"
							key="origin-img"
						>
							<img
								className="ExperimentGantt-origin-img"
								src={imgPath}
							/>
						</span>
					);
				};
				return React.cloneElement(trElement, { ...trElement.props }, [
					originIcon(),
					...trElement.props.children,
				]);
			};

			return <TreeListCell {...props} render={cellRender} />;
		},
	},
	{
		field: ExpGanttFields.start,
		title: DIC(EDIC_KEY.FROM),
		width: 110,
		format: "{0:MM/dd/yyyy}",
		filter: GanttDateFilter,
	},
	{
		field: ExpGanttFields.end,
		title: DIC(EDIC_KEY.TO),
		width: 110,
		format: "{0:MM/dd/yyyy}",
		filter: GanttDateFilter,
	},
	{
		field: ExpGanttFields.percentComplete,
		title: DIC(EDIC_KEY.COMPLETION),
		width: 110,
		sortable: false,
		cell: (props) => {
			const cellRender = (trElement: any, rowProps: any) => {
				const completion = () => {
					const status: EExpGanttTaskStatus =
						rowProps.dataItem[ExpGanttFields.status];
					const percentCompletion: number =
						rowProps.dataItem[ExpGanttFields.percentComplete] * 100;

					switch (rowProps.dataItem[ExpGanttFields.origin]) {
						case EExpGanttTaskOrigin.OBS_ROUND:
							if (status !== EExpGanttTaskStatus.IN_PROGRESS) {
								return (
									<ColorTag
										key={
											rowProps.dataItem[ExpGanttFields.id]
										}
										tagValue={status}
										colorIndicator={getColorFromGanttStatus(
											status,
										)}
									/>
								);
							}
							return `${percentCompletion} %`;
						case EExpGanttTaskOrigin.OPERATION:
						case EExpGanttTaskOrigin.TRIAL:
						case EExpGanttTaskOrigin.MET:
							return (
								<ColorTag
									key={rowProps.dataItem[ExpGanttFields.id]}
									tagValue={status}
									colorIndicator={getColorFromGanttStatus(
										status,
									)}
								/>
							);
						default:
							return;
					}
				};
				return React.cloneElement(trElement, { ...trElement.props }, [
					completion(),
				]);
			};

			return <TreeListCell {...props} render={cellRender} />;
		},
	},
];

export const InterExpGanttColumns: GanttColumnProps[] = [
	ExpGanttColumns[0],
	{
		field: ExpGanttFields.trial,
		title: DIC(EDIC_KEY.TRIAL),
		width: 175,
		filter: GanttTextFilter,
	},
	...ExpGanttColumns.slice(1, 3),
	{
		...ExpGanttColumns[3],
		// TODO TEMPORARY FIX : Hide completion in inter gantt
		cell: (props) => {
			const cellRender = (trElement: any, rowProps: any) => {
				const completion = () => {
					const status: EExpGanttTaskStatus =
						rowProps.dataItem[ExpGanttFields.status];
					switch (rowProps.dataItem[ExpGanttFields.origin]) {
						case EExpGanttTaskOrigin.OBS_ROUND:
						case EExpGanttTaskOrigin.OPERATION:
						case EExpGanttTaskOrigin.TRIAL:
						case EExpGanttTaskOrigin.MET:
							return (
								<ColorTag
									tagValue={status}
									colorIndicator={getColorFromGanttStatus(
										status,
									)}
								/>
							);
						default:
							return;
					}
				};
				return React.cloneElement(trElement, { ...trElement.props }, [
					completion(),
				]);
			};
			return <TreeListCell {...props} render={cellRender} />;
		},
	},
];

export const ExpGanttRowRenderer = (
	trElement: any,
	rowProps: GanttRowProps,
) => {
	const trProps = {
		...trElement.props,
		className: concatClassName(
			trElement.props.className,
			"ExperimentGantt-row",
		),
	};
	return React.cloneElement(
		trElement,
		{ ...trProps },
		trElement.props.children,
	);
};
