import React from "react";

import { formatString } from "common";

import { DIC, EDIC_KEY } from "../../../../../dictionary";
import { ITrialCompletion } from "../../../../../Views/main/experiment/trial/edition/data/overview/TrialOverviewPage";
import { IColumn } from "../doriane-data-grid/DorianeDataGrid";
import CompletionColumn from "../columns/CompletionColumn";

export const trialOverviewColumns = () => {
	const columns: IColumn<ITrialCompletion>[] = [
		{
			id: "variables",
			width: "250px",
			getHeaderRenderer: DIC(EDIC_KEY.OBSERVATION_VARIABLES),
			getCellRenderer: (row) => row.variable,
		},
		{
			id: "notebook_occurrences",
			width: "175px",
			getHeaderRenderer: DIC(EDIC_KEY.ASSIGNED_IN_NOTEBOOKS),
			getCellRenderer: (row) => (
				<div style={{ textAlign: "center" }}>
					{row.notebookAssignment.toString()}
				</div>
			),
		},
		{
			id: "trial_notation",
			width: "175px",
			getHeaderRenderer: formatString(
				DIC(EDIC_KEY.NOTATIONS_AT),
				DIC(EDIC_KEY.TRIAL),
			),
			getCellRenderer: (row) => (
				<CompletionColumn levelCompletion={row.trialCompletion} />
			),
		},
		{
			id: "material_notation",
			width: "175px",
			getHeaderRenderer: formatString(
				DIC(EDIC_KEY.NOTATIONS_AT),
				DIC(EDIC_KEY.MATERIAL),
			),
			getCellRenderer: (row) => (
				<CompletionColumn levelCompletion={row.materialCompletion} />
			),
		},
	];

	return columns;
};
