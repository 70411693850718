import React from "react";
import { useSelector } from "react-redux";

import NotebookCard from "../../../../../../../../../components/notebook/card/NotebookCard";
import { apiNotebook } from "../../../../../../../../../redux/notebook/notebook.api";
import { selectObsRoundEdition } from "../../../../../../../../../redux/observation-round-edition/obs-round-edition.selector";

import "./NotebooksPage.scss";

export default function NotebooksPage() {
	const currentObsRound = useSelector(
		selectObsRoundEdition.editingMergeValueDeserialized,
	);

	const { data: remoteNotebooks } =
		apiNotebook.useGetNotebooksByObsRoundIdQuery(currentObsRound?._id);
	return (
		<div className="NotebooksPage-body full-parent-size">
			<div className="NotebooksPage-card-list">
				{remoteNotebooks?.map((elt) => (
					<NotebookCard disabledClick notebook={elt} key={elt._id} />
				))}
			</div>
		</div>
	);
}
