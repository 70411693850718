import React from "react";

import { IGenotype } from "shared-type";

import { DIC, EDIC_KEY } from "App/dictionary";
import {
	IDorianeFilter,
	IDorianeObjectFilter,
} from "App/interfaces/filters/doriane-filter-interface";
import { getDicGenotypeCrossType, getDicSpecies } from "../../../../../dictionary/dictionary-key-getter";
import { CheckBoxColumn } from "../columns/CheckBoxColumn";
import { IColumn } from "../doriane-data-grid/DorianeDataGrid";
import HeaderWithFilter from "../doriane-data-grid/header/HeaderWithFilter";

export const genotypeColumns = (
	filter: IDorianeObjectFilter,
	onFilterChange: (newFilter: IDorianeFilter) => void,
): IColumn<IGenotype>[] => [
	{
		id: "name",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "name",
					type: "string",
					value: filter.name?.value,
				}}
				label={DIC(EDIC_KEY.GENOTYPE_NAME)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => genotype.name,
	},
	{
		id: "variety",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "variety",
					type: "string",
					value: filter.variety?.value,
				}}
				label={DIC(EDIC_KEY.VARIETY)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => genotype.variety || "",
	},
	{
		id: "alternateName",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "alternateName",
					type: "string",
					value: filter.alternateName?.value,
				}}
				label={DIC(EDIC_KEY.GENOTYPE_COLUMN_ALTERNATE_NAME)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => genotype.alternateName || "",
	},
	{
		id: "group",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "group",
					type: "string",
					value: filter.group?.value,
				}}
				label={DIC(EDIC_KEY.GROUP)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => genotype.group || "",
	},
	{
		id: "species",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "species",
					type: "string",
					value: filter.species?.value,
				}}
				label={DIC(EDIC_KEY.SPECIES_NAME)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => getDicSpecies(genotype.species),
	},
	{
		id: "subgroup",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "subgroup",
					type: "string",
					value: filter.subgroup?.value,
				}}
				label={DIC(EDIC_KEY.SUBGROUP)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => genotype.subgroup || "",
	},
	{
		id: "subspecies",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "subspecies",
					type: "string",
					value: filter.subspecies?.value,
				}}
				label={DIC(EDIC_KEY.SUBSPECIES_NAME)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => genotype.subspecies ?? "",
	},
	{
		id: "marketSegments",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "marketSegments",
					type: "string",
					value: filter.marketSegments?.value,
				}}
				label={DIC(EDIC_KEY.MARKET_SEGMENTS)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) =>
			(Array.isArray(genotype.marketSegments)
				? genotype.marketSegments?.join(", ")
				: ""),
	},
	{
		id: "plc",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "plc",
					type: "string",
					value: filter.plc?.value,
				}}
				label={DIC(EDIC_KEY.GENOTYPE_COLUMN_PLC)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => genotype.plc || "",
	},
	{
		id: "control",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "control",
					type: "string",
					value: filter.control?.value,
				}}
				label={DIC(EDIC_KEY.GENOTYPE_COLUMN_CONTROL)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => genotype.control || "",
	},
	{
		id: "ownership",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "ownership",
					type: "string",
					value: filter.ownership?.value,
				}}
				label={DIC(EDIC_KEY.GENOTYPE_COLUMN_OWNERSHIP)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => genotype.ownership || "",
	},
	{
		id: "buOwner",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "buOwner",
					type: "string",
					value: filter.buOwner?.value,
				}}
				label={DIC(EDIC_KEY.GENOTYPE_COLUMN_BU_OWNER)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => genotype.buOwner || "",
	},
	{
		id: "companyName",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "companyName",
					type: "string",
					value: filter.companyName?.value,
				}}
				label={DIC(EDIC_KEY.GENOTYPE_COLUMN_COMPANY_NAME)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => genotype.companyName || "",
	},
	{
		id: "label",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "label",
					type: "string",
					value: filter.label?.value,
				}}
				label={DIC(EDIC_KEY.LABEL)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => genotype.label || "",
	},
	{
		id: "generation",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "generation",
					type: "string",
					value: filter.generation?.value,
				}}
				label={DIC(EDIC_KEY.GENERATION)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => genotype.generation || "",
	},
	{
		id: "materialType",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "materialType",
					type: "string",
					value: filter.materialType?.value,
				}}
				label={DIC(EDIC_KEY.CROSS_TYPE)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => {
			if (genotype.materialType) {
				return getDicGenotypeCrossType(genotype.materialType);
			}
			return "";
		},
	},
	{
		id: "direct F parent",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "directFParent",
					type: "string",
					value: filter.directFParent?.value,
				}}
				label={DIC(EDIC_KEY.DIRECT_F_PARENT)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => genotype.directFParent || "",
	},
	{
		id: "direct M parent",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "directMParent",
					type: "string",
					value: filter.directMParent?.value,
				}}
				label={DIC(EDIC_KEY.DIRECT_M_PARENT)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => genotype.directMParent || "",
	},
	{
		id: "origin F parent",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "originFParent",
					type: "string",
					value: filter.originFParent?.value,
				}}
				label={DIC(EDIC_KEY.ORIGIN_F_PARENT)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => genotype.originFParent || "",
	},
	{
		id: "origin M parent",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "originMParent",
					type: "string",
					value: filter.originMParent?.value,
				}}
				label={DIC(EDIC_KEY.ORIGIN_M_PARENT)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => genotype.originMParent || "",
	},
	{
		id: "full parentage",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "fullParentage",
					type: "string",
					value: filter.fullParentage?.value,
				}}
				label={DIC(EDIC_KEY.FULL_PARENTAGE)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => genotype.fullParentage || "",
	},
	{
		id: "comments",
		getHeaderRenderer: (
			<HeaderWithFilter
				filter={{
					key: "comments",
					type: "string",
					value: filter.comments?.value,
				}}
				label={DIC(EDIC_KEY.COMMENTS)}
				onFilterChange={onFilterChange}
			/>
		),
		width: "200px",
		getCellRenderer: (genotype) => genotype.comments || "",
	},
];

export function genotypeColumnsForTrialMaterial(
	filter: {
		filter: IDorianeObjectFilter;
		onFilterChange: (newFilter: IDorianeFilter) => void;
	},
	rowCallback: {
		isCheck: (row: IGenotype) => boolean;
		onCheck: (row: IGenotype, check: boolean) => void;
		isDisabled: (row: IGenotype) => boolean;
	},
	onCheckMultiple?: any,
): IColumn<IGenotype>[] {
	const checkBoxColumn = CheckBoxColumn({
		isCheck: rowCallback.isCheck,
		onCheck: rowCallback.onCheck,
		isDisabled: rowCallback.isDisabled,
		headerCheckAllAction: onCheckMultiple,
	});
	return [
		checkBoxColumn,
		...genotypeColumns(filter.filter, filter.onFilterChange),
	];
}
