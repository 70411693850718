import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { formatString } from "common";
import {
	ICreateVariableGroup,
	ITemplateObsCoreSerialized,
	IVariableCore,
} from "shared-type";

import { DIC, EDIC_KEY } from "../../../../../../dictionary";
import useAppSnackbar from "../../../../../../hooks/useAppSnackbar";
import { ApiErrorSnackbar } from "../../../../../../redux/utils/api-error-snackbar/ApiErrorSnackbar";
import { ReduxApiError } from "../../../../../../redux/utils/errors";
import { apiVariableGroup } from "../../../../../../redux/variable-group/variable-group.api";
import { varGroupValidator } from "../../../../../form/variable-group";
import { varGroupFormToObject } from "../../../../../form/variable-group/transformer";
import VariableGroupForm, {
	IVarGroupForm,
} from "../../../../../form/variable-group/VariableGroupForm";
import { Modal } from "stories/base-components/Modal/Modal";

import "./CreateVariableGroupModal.scss";

interface ICreateVariableGroupModalProps {
	onClose: () => void;
	templateObservation: ITemplateObsCoreSerialized;
	variableInTemplate: IVariableCore[];
	onVariableGroupCreated?: (varGroupId: string) => void;
	open: boolean;
}

export default function CreateVariableGroupModal(
	props: ICreateVariableGroupModalProps,
) {
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();

	const [createAndCloseLoading, setCreateAndCloseLoading] =
		useState<boolean>(false);
	const [createAndContinueLoading, setCreateAndContinueLoading] =
		useState<boolean>(false);

	const [createVariableGroup] =
		apiVariableGroup.useCreateVariableGroupMutation();

	const form = useForm<IVarGroupForm>({
		defaultValues: {
			variables: [],
		},
		resolver: yupResolver(varGroupValidator as any),
	});
	const { handleSubmit, formState } = form;
	const { isSubmitting } = formState;

	useEffect(() => {
		if (!isSubmitting) {
			setCreateAndCloseLoading(false);
			setCreateAndContinueLoading(false);
		}
	}, [isSubmitting]);

	async function onSubmit(data: IVarGroupForm, continueMode?: boolean) {
		if (continueMode) {
			setCreateAndContinueLoading(true);
		} else {
			setCreateAndCloseLoading(true);
		}
		const varGroupCreation = varGroupFormToObject(
			data,
		) as ICreateVariableGroup;

		await createVariableGroup({
			...varGroupCreation,
			templateObsId: props.templateObservation._id,
		})
			.unwrap()
			.then((createdVariableGroup) => {
				if (continueMode) {
					form.reset({
						variables: [],
					});
				} else {
					props.onVariableGroupCreated?.(createdVariableGroup._id);
					props.onClose();
				}
				enqueueSnackbarSuccess(
					formatString(
						DIC(EDIC_KEY.ENTITY_CREATED),
						DIC(EDIC_KEY.VARIABLE_GROUP),
					),
				);
			})
			.catch((err: ReduxApiError) => {
				console.warn(err);
				enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
			});
	}

	const handleCreate = async (continueMode: boolean) => {
		handleSubmit(
			(data) => onSubmit(data, continueMode),
			(error) => console.error(error),
		)();
	};

	const handleClose = () => {
		props.onClose();
		form.reset({
			variables: [],
		});
	};

	return (
		<Modal
			open={props.open}
			titleIcon={"variable-group"}
			title={DIC(EDIC_KEY.CREATE_NEW_VARIABLE_GROUP)}
			handleClose={handleClose}
			validateBtn={{
				action: () => {
					handleCreate(true);
				},
				label: DIC(EDIC_KEY.CREATE_AND_CONTINUE),
				isLoading: createAndContinueLoading,
			}}
			secondaryBtn={{
				action: () => {
					handleCreate(false);
				},
				label: DIC(EDIC_KEY.CREATE_AND_CLOSE),
				isLoading: createAndCloseLoading,
			}}
			width={660}
		>
			<div className="CreateVariableGroupModal-container take-remaining-space flex-column">
				<VariableGroupForm
					mode="creation"
					templateObservation={props.templateObservation}
					form={form}
					variablesInTemplate={props.variableInTemplate}
				/>
			</div>
		</Modal>
	);
}
