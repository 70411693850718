import React from "react";

import { concatClassName } from "common";

import { IColumn } from "../DorianeDataGrid";
import { Typography } from "stories/base-components/Typography/Typography";
import Colors from "stories/constants/Colors/Colors";
import Box from "App/components/base-components/placement/box/Box";

export type IDorianeDataGridRowHeaderProps<Row> = {
	columns: IColumn<Row>[];
	computedColumnsWidth: string;
	secondaryStyle?: boolean;
};
export function DorianeDataGridRowHeader<Row>(
	props: IDorianeDataGridRowHeaderProps<Row>,
) {
	return (
		<div
			className={concatClassName(
				"DorianeDataGridRowHeader",
				props.secondaryStyle
					? "DorianeDataGridRowHeaderSecondaryStyle"
					: "",
			)}
			style={{ gridTemplateColumns: props.computedColumnsWidth }}
		>
			{props.columns.map((column) => (
				<div
					className={concatClassName(
						props.secondaryStyle
							? "DorianeDataGridCellSecondaryStyle"
							: "DorianeDataGridCell",
						"DorianeDataGridCell-header",
						column.headerClassName,
					)}
					key={column.id}
				>
					{typeof column.getHeaderRenderer === "string" ? (
						<Box
							textAlign={props.secondaryStyle ? "left" : "center"}
						>
							<Typography
								text={column.getHeaderRenderer}
								variant="body2Medium"
								color={Colors.neutral100}
							/>
						</Box>
					) : (
						column.getHeaderRenderer
					)}
				</div>
			))}
		</div>
	);
}
