import DoneIcon from "@mui/icons-material/Done";
import React from "react";

import { IColumn } from "../doriane-data-grid/DorianeDataGrid";
import DorianeButton from "../../../button/DorianeButton";
import Box from "App/components/base-components/placement/box/Box";
import { Typography } from "stories/base-components/Typography/Typography";
import Colors from "stories/constants/Colors/Colors";

import "./TickMarkColumn.scss";

export function TickMarkColumn<Row>(
	isChecked: (row: Row) => boolean,
	headerName?: string,
	id = "tick",
): IColumn<Row> {
	return {
		id: id,
		getHeaderRenderer: headerName ? (
			<Box textAlign="center">
				<Typography
					text={headerName}
					variant="body2Medium"
					color={Colors.neutral100}
				/>
			</Box>
		) : (
			""
		),
		getCellRenderer: (row) => (
			<div className="TickMarkColumn-container">
				<DorianeButton
					disabled
					onClick={() => {}}
					dorianeStyle="quaternary"
				>
					{isChecked(row) ? <DoneIcon /> : <></>}
				</DorianeButton>
			</div>
		),
		width: "150px",
	};
}
