import produce from "immer";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
	ETrialLevel,
	ITemplateObsCoreSerialized,
	IVariableCore,
} from "shared-type";

import { DIC, EDIC_KEY } from "App/dictionary";
import { defaultTemplateObsLinkedVar } from "App/mock";
import { variablesCustomHook } from "App/redux/variables/variables.api";
import { PATH_VARIABLES } from "App/routes";
import { getVariablesFromScopedVars } from "App/utils";
import { EmptyState } from "stories/base-components/EmptyState/EmptyState";
import { HeaderTitleSearchButton } from "stories/base-components/Layout/HeaderTitleSearchButton/HeaderTitleSearchButton";
import { templateObsLinkedVarColumns } from "../../../base-components/data-grid/doriane-data-grid/default-grid-columns/TemplateObsLinkedVarColumns";
import DorianeDataGrid from "../../../base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import { ITemplateObsLinkedVar } from "../TemplateObsEditionPage.utils";
import TemplateObsVarGrid from "./variable-grid/TemplateObsVarGrid";

import "./TemplateObsVariablesPart.scss";

interface TemplateObsVariablesPartProps {
	templateObservation: ITemplateObsCoreSerialized;
	setTemplateObs: (templateObs: ITemplateObsCoreSerialized) => void;
	setIsAddVariableOpen: (value: boolean) => void;
	isTemplateObsUsed: boolean;
}

export default function TemplateObsVariablesPart(
	props: TemplateObsVariablesPartProps,
) {
	const { data: variableOntology } =
		variablesCustomHook.useGetOntologyVariableListDeserialized();

	const variableInTemplate: IVariableCore[] = getVariablesFromScopedVars(
		props.templateObservation.variablesPool,
		variableOntology,
	);

	const handleCheckObsUnit = (
		row: IVariableCore,
		checked: boolean,
		level: ETrialLevel,
	) => {
		props.setTemplateObs(
			produce(props.templateObservation, (template) => {
				if (!template.templateObsUnits[level]) {
					template.templateObsUnits[level] = [];
				}
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				const levelVariables = template.templateObsUnits[level]!;

				// when check, we add to correct level
				if (
					checked &&
					!levelVariables.find(
						(levelVar) =>
							levelVar.variableId === row._id &&
							levelVar.scope === row.scope,
					)
				) {
					levelVariables.push({
						variableId: row._id,
						scope: row.scope,
					});
				} else {
					// we remove from list
					const indexToRemove = levelVariables.findIndex(
						(levelVar) =>
							levelVar.variableId === row._id &&
							levelVar.scope === row.scope,
					);
					levelVariables.splice(indexToRemove, 1);
				}
			}),
		);
	};
	const handleCheckTrial = (row: IVariableCore, checked: boolean) => {
		return handleCheckObsUnit(row, checked, ETrialLevel.TRIAL);
	};

	const handleCheckMaterial = (row: IVariableCore, checked: boolean) => {
		return handleCheckObsUnit(row, checked, ETrialLevel.MATERIAL);
	};

	const noVariables = useMemo(() => {
		return (
			defaultTemplateObsLinkedVar.length === 0 &&
			variableInTemplate.length === 0
		);
	}, [variableInTemplate.length]);

	const nav = useNavigate();

	return noVariables ? (
		<EmptyState
			message={DIC(EDIC_KEY.NO_VARIABLE_YET)}
			beforeActionMessage={DIC(EDIC_KEY.CREATE_VARIABLES_IN)}
			actionMessage={`${DIC(EDIC_KEY.CONFIGURATION)} > ${DIC(
				EDIC_KEY.VARIABLES,
			)}.`}
			actionOnClick={() => nav(PATH_VARIABLES)}
		/>
	) : (
		<div className="TemplateObsVariablesPart-variable-container flex-column">
			<HeaderTitleSearchButton
				subtitle={DIC(EDIC_KEY.VARIABLES)}
				list={variableInTemplate || []}
				btnText={`${DIC(EDIC_KEY.EDIT)} ${DIC(
					EDIC_KEY.VARIABLES,
				).toLowerCase()} +`}
				disabled={props.isTemplateObsUsed}
				btnCreateText={DIC(EDIC_KEY.ADD_VARIABLES) + " +"}
				btnOnClick={() => props.setIsAddVariableOpen(true)}
				btnType="tertiary"
				btnDisabledTooltipMessage={DIC(
					EDIC_KEY.THIS_TEMPLATE_OBS_IS_USED,
				)}
			/>

			{variableInTemplate.length > 0 && (
				<TemplateObsVarGrid
					templateObservation={props.templateObservation}
					variableOntology={variableInTemplate}
					isTemplateObsUsed={props.isTemplateObsUsed}
					onTrialCheck={handleCheckTrial}
					onMaterialCheck={handleCheckMaterial}
					hiddenColorStripe={false}
					displayMode={"checkbox"}
				/>
			)}
			{defaultTemplateObsLinkedVar.length > 0 && (
				<DorianeDataGrid<ITemplateObsLinkedVar>
					className="TemplateObsVariablesPart-linked-var"
					rows={defaultTemplateObsLinkedVar}
					columns={templateObsLinkedVarColumns(true)}
				/>
			)}
		</div>
	);
}
