import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import {
	ETaskType,
	ExperimentType,
	ICreateOpTask,
	IFilter,
	IOpTaskSerialized,
	IPatchOpTask,
	ISort,
	PaginateResponse,
} from "shared-type";

import { AbstractRequest } from "../abstract-request";

export class OpTaskRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/op-task";
	}

	createOpTask(
		createTask: ICreateOpTask,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<string, any>> {
		return this.axios.post<string>(this.baseUrl, createTask, config);
	}

	createManyOpTask(
		createTaskArray: ICreateOpTask[]
	): Promise<AxiosResponse<string[], any>> {
		return this.axios.post<string[]>(this.baseUrl + "/many", createTaskArray);
	}

	createManyOpTaskFromTemplates(
		templateIds: string[],
		trialId: string
	): Promise<AxiosResponse<string[], any>> {
		return this.axios.post<string[]>(
			this.baseUrl + "/many/template/" + trialId,
			templateIds
		);
	}

	getOpTaskByIds(tasksIds: string[], config?: AxiosRequestConfig) {
		return this.axios.post<IOpTaskSerialized[]>(
			this.baseUrl + "/get/many",
			tasksIds,
			config
		);
	}

	getPaginateOpTasks(
		page?: number,
		pageSize?: number,
		filter?: IFilter,
		sort?: ISort,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<PaginateResponse<IOpTaskSerialized>, any>> {
		return this.axios.get<PaginateResponse<IOpTaskSerialized>>(this.baseUrl, {
			...config,
			params: {
				...config?.params,
				page: JSON.stringify(page),
				pageSize: JSON.stringify(pageSize),
				filter: JSON.stringify(filter),
				sort: JSON.stringify(sort),
			},
		});
	}

	getOpTasksByExpId(
		expId: string,
		expType: ExperimentType,
		type?: ETaskType,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<IOpTaskSerialized[], any>> {
		return this.axios.get<IOpTaskSerialized[]>(
			this.baseUrl + "/experiment/" + expId,
			{ ...config, params: { ...config?.params, type: type, expType: expType } }
		);
	}

	getOpTaskById(
		id: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<IOpTaskSerialized, any>> {
		return this.axios.get<IOpTaskSerialized>(this.baseUrl + "/" + id, config);
	}

	patchOpTaskById(
		id: string,
		patchTask: IPatchOpTask,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<void, any>> {
		return this.axios.patch<void>(this.baseUrl + "/" + id, patchTask, config);
	}

	deleteOpTaskById(
		id: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<void, any>> {
		return this.axios.delete<void>(this.baseUrl + "/" + id, config);
	}

	deleteOpTaskByExpId(
		expId: string,
		expType: ExperimentType,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<number, any>> {
		return this.axios.delete<number>(this.baseUrl + "/experiment/" + expId, {
			...config,
			params: { ...config?.params, expType: expType },
		});
	}
}
