import React from "react";

import { DIC, EDIC_KEY } from "../../../../dictionary";
import DorianeButton from "../../../base-components/button/DorianeButton";

import "./DashboardHeader.scss";

interface DashboardHeaderProps {
	computeAndSubmitChartFilter: () => void;
	children: React.ReactNode;
}

export default function DashboardHeader(props: DashboardHeaderProps) {
	return (
		<div className="DashboardHeader-body">
			{props.children}
			<DorianeButton
				dorianeStyle="primary"
				onClick={() => props.computeAndSubmitChartFilter()}
			>
				{DIC(EDIC_KEY.APPLY)}
			</DorianeButton>
		</div>
	);
}
