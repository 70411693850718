import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { formatString } from "common";

import DorianeButton from "App/components/base-components/button/DorianeButton";
import GrowingAreaCard from "App/components/growing-area/card/GrowingAreaCard";
import { DIC, EDIC_KEY } from "App/dictionary";
import { selectECW } from "App/redux/ECW/ECW.selector";
import { ECWAction } from "App/redux/ECW/ECW.slice";
import { AppDispatch } from "App/store";
import { growingAreaCustomHook } from "../../../../../../redux/growing-area/growing-area.api";

import "./TrialLocationPage.scss";

export default function TrialLocationPage() {
	const dispatch = useDispatch<AppDispatch>();

	const currentGrowingAreaId = useSelector(selectECW.growingAreaId);
	const { data: currentGrowingArea } =
		growingAreaCustomHook.useGetGrowingAreaByIdQueryDeserialized(
			currentGrowingAreaId,
		);
	const isSelectLocationOpen = useSelector(selectECW.isSelectGrowingAreaOpen);

	useEffect(() => {
		// By default : the selection list of germplasm is closed
		dispatch(ECWAction.setSelectGrowingAreaOpen(false));
		return () => {
			dispatch(ECWAction.setSelectGrowingAreaOpen(false));
		};
	}, [dispatch]);

	return (
		<div className="TrialLocationPage-container take-remaining-space flex-column">
			<div className="TrialLocationPage-body flex-column take-remaining-space">
				<div className="TrialLocationPage-title-container">
					<h1>{DIC(EDIC_KEY.GROWING_AREA)}</h1>
					<DorianeButton
						dorianeStyle="tertiary"
						onClick={() =>
							dispatch(
								ECWAction.setSelectGrowingAreaOpen(
									!isSelectLocationOpen,
								),
							)
						}
					>
						{DIC(EDIC_KEY.EDIT_SELECTION)}
					</DorianeButton>
				</div>
				{currentGrowingArea !== undefined ? (
					<GrowingAreaCard
						growingArea={currentGrowingArea}
						disabledClick
						onDelete={() =>
							dispatch(ECWAction.updateGrowingAreaId(undefined))
						}
					/>
				) : (
					<div>
						{formatString(
							DIC(EDIC_KEY.NO_ENTITY_SELECTED),
							DIC(EDIC_KEY.GROWING_AREA).toLowerCase(),
						)}
					</div>
				)}
			</div>
		</div>
	);
}
