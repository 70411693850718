import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { computeTotalPagesNb, formatString } from "common";

import { DIC, EDIC_KEY } from "App/dictionary";
import { PATH_MET_CREATION } from "App/routes";
import { MET_DEFAULT_PAGE_SIZE, MET_DEFAULT_SORT } from "../../../../../const";
import {
	convertDorObjFilterToFilter,
	IDorianeObjectFilter,
} from "../../../../../interfaces/filters/doriane-filter-interface";
import { apiMET } from "../../../../../redux/experiment/met/met.api";
import { apiTrial } from "../../../../../redux/experiment/trial/trial.api";
import { HeaderTitleSearchButton } from "stories/base-components/Layout/HeaderTitleSearchButton/HeaderTitleSearchButton";
import { DataGridSkeleton } from "stories/base-components/Skeleton/mostUsed/DataGridSkeleton";
import FlexBox from "App/components/base-components/placement/flex-box/FlexBox";
import { Typography } from "stories/base-components/Typography/Typography";
import PaginationTool from "App/components/base-components/pagination/PaginationTool";
import Size from "stories/constants/Size/Size";
import { GridSimple } from "stories/base-components/GridSimple/GridSimple";
import { apiMarketSegment } from "App/redux/market-segment/market-segment.api";
import { METColumns, METTrialList } from "./METColumns";
import { METDeleteModal } from "../modal/METDeleteModal";

import "./METListPage.scss";

export default function METListPage() {
	const nav = useNavigate();

	const [page, setPage] = useState<number>(0);
	const [filter, setFilter] = useState<IDorianeObjectFilter>({});

	const filterForQuery = useMemo(
		() => convertDorObjFilterToFilter(filter, true),
		[filter],
	);

	const {
		data: mets,
		isLoading: isMETLoading,
		isError: isMETError,
	} = apiMET.useGetMETQuery({
		sort: MET_DEFAULT_SORT,
		page: page,
		pageSize: MET_DEFAULT_PAGE_SIZE,
		filter: filterForQuery,
	});

	const metIds = useMemo(() => {
		return mets?.data.map((elt) => elt._id);
	}, [mets]);

	const {
		data: trialsIdsByMETId,
		isLoading: isTrialsLoading,
		isError: isTrialsError,
	} = apiTrial.useGetTrialsIdsByMETIdsQuery(metIds);

	const marketSegmentIds = useMemo(() => {
		const msIds = _.flatMap(mets?.data, (obj) =>
			_.get(obj, "marketSegmentIds", []),
		);
		return msIds.filter((value, index) => msIds.indexOf(value) === index);
	}, [mets?.data]);

	const { data: fetchedMkSegments } =
		apiMarketSegment.useGetMarketSegmentByIdsQuery(marketSegmentIds ?? []);

	const handleNewMETClick = () => {
		nav(PATH_MET_CREATION);
	};

	const createMETFilter = useCallback((newFilterValue: string) => {
		const filter: IDorianeObjectFilter = {
			name: { key: "name", type: "string", value: newFilterValue },
			species: {
				key: "species",
				type: "string",
				value: newFilterValue,
			},
			year: { key: "year", type: "int", value: newFilterValue },
		};
		setFilter(filter);
		setPage(0); // Reset page number
	}, []);

	const onMETClick = (id: string) => {
		nav(`${PATH_MET_CREATION}/${id}`);
	};

	const renderList = () => {
		if (isMETLoading || isTrialsLoading) {
			return <DataGridSkeleton />;
		}
		if (isMETError || isTrialsError) {
			return (
				<FlexBox>
					<Typography
						text={formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.FETCH).toLowerCase(),
							DIC(EDIC_KEY.METS).toLowerCase(),
						)}
					/>
				</FlexBox>
			);
		}
		return (
			<>
				<GridSimple
					columns={METColumns(
						onMETClick,
						fetchedMkSegments,
						trialsIdsByMETId,
					)}
					data={mets?.data || []}
					details={METTrialList}
				/>
				<FlexBox justifyContent={"center"} paddingBottom={Size.xl}>
					<PaginationTool
						currentPage={page}
						maxPage={computeTotalPagesNb(mets?.metadata.pageInfo)}
						onPageRoll={(pageIndex) => setPage(pageIndex)}
					/>
				</FlexBox>
			</>
		);
	};

	return (
		<FlexBox
			className="METListPage-body"
			flexDirection="column"
			takeRemainingSpace
		>
			<HeaderTitleSearchButton
				title={
					DIC(EDIC_KEY.MET) +
					(mets?.metadata.pageInfo.total
						? " (" + mets?.metadata.pageInfo.total + ")"
						: "")
				}
				list={mets?.data || []}
				searchPlaceholder={DIC(EDIC_KEY.SEARCH_MET)}
				setSearch={(search: string) => {
					createMETFilter(search);
				}}
				searchValue={filter.name?.value || ""}
				btnText={DIC(EDIC_KEY.NEW_MET) + " +"}
				btnOnClick={handleNewMETClick}
			/>

			{renderList()}
			<METDeleteModal />
		</FlexBox>
	);
}
