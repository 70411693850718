import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import {
	ICreateMET,
	IFilter,
	IMETDuplicationInfo,
	IMETSerialized,
	IPatchMET,
	ISort,
	IUnrefMaterialContext,
	PaginateResponse,
} from "shared-type";
import { AbstractRequest } from "../abstract-request";

export class METRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/met";
	}

	createMET(createMET: ICreateMET): Promise<AxiosResponse<string, any>> {
		return this.axios.post<string>(this.baseUrl, createMET);
	}

	duplicateMET(
		metDuplicationInfo: IMETDuplicationInfo
	): Promise<AxiosResponse<string, any>> {
		return this.axios.post<
			string,
			AxiosResponse<string, any>,
			IMETDuplicationInfo
		>(`${this.baseUrl}/duplicate`, metDuplicationInfo);
	}

	getUnrefMaterialsByMETId(
		id: string,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<IUnrefMaterialContext[], any>> {
		return this.axios.get<IUnrefMaterialContext[]>(
			this.baseUrl + "/unreferenced-material/" + id,
			config
		);
	}

	getMET(
		args: { page?: number; pageSize?: number; filter?: IFilter; sort?: ISort },
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<PaginateResponse<IMETSerialized>, any>> {
		return this.axios.get<PaginateResponse<IMETSerialized>>(this.baseUrl, {
			...config,
			params: {
				...config?.params,
				page: JSON.stringify(args.page),
				pageSize: JSON.stringify(args.pageSize),
				filter: JSON.stringify(args.filter),
				sort: JSON.stringify(args.sort),
			},
		});
	}

	getMETById(
		id: string,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<IMETSerialized, any>> {
		return this.axios.get<IMETSerialized>(this.baseUrl + "/" + id, config);
	}

	getMETsByIds(metIds: string[], config?: AxiosRequestConfig) {
		return this.axios.post<IMETSerialized[]>(
			this.baseUrl + "/get/many",
			metIds,
			config
		);
	}

	updateMETById(
		id: string,
		updateTrial: IPatchMET
	): Promise<AxiosResponse<void, any>> {
		return this.axios.patch<void>(this.baseUrl + "/" + id, updateTrial);
	}

	deleteMETById(
		id: string,
		deleteLinkedTrials: boolean
	): Promise<AxiosResponse<void, any>> {
		return this.axios.delete<void>(this.baseUrl + "/" + id, {
			params: { deleteLinkedTrials },
		});
	}

	checkTrialsUnicity(args: {
		year: number;
		names: string[];
	}): Promise<AxiosResponse<void, any>> {
		return this.axios.post<void>(
			this.baseUrl + "/check-trial-unicity/many",
			args
		);
	}

	generateTrialForMET(args: {
		metId: string;
		trials: { name: string; growingAreaId?: string }[];
	}) {
		return this.axios.post<string[]>(
			`${this.baseUrl}/${args.metId}/generate-trials`,
			{ trials: args.trials }
		);
	}
}
