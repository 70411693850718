import React from "react";
import { Menu, MenuItem } from "@mui/material";

import { Button, IButton } from "stories/base-components/Button/Button";
import { StoryTheme } from "stories/StoryTheme";
import Size from "stories/constants/Size/Size";
import { IIconType } from "../Icon/IconType";
import { Icon } from "../Icon/Icon";
import Box from "App/components/base-components/placement/box/Box";

import "./dropdownButton.scss";

export type IDropdownOption = {
	label: string;
	action: (event?: any) => void;
	disabled?: boolean;
	iconName?: IIconType;
};

export type IDropdownButton = {
	btn: IButton;
	options: Array<IDropdownOption>;
};

export const DropdownButton = (props: IDropdownButton) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClose = (event: any) => {
		event.stopPropagation();
		setAnchorEl(null);
	};

	return (
		<StoryTheme>
			<div>
				<Button
					{...props.btn}
					// Override the button OnClick event.
					onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
						event.stopPropagation();
						setAnchorEl(event.currentTarget);
						props.btn.onClick?.(event);
					}}
				/>
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						"aria-labelledby": "basic-button",
					}}
					style={{ marginTop: Size.x3s }}
				>
					{props.options.map((option, index) => (
						<MenuItem
							key={index}
							disableRipple={option.disabled}
							onClick={(event: any) => {
								event.stopPropagation();
								if (option.disabled) {
									return;
								}
								option.action(event);
								setAnchorEl(null);
							}}
							className={
								option.disabled ? "dropdownOptionDisabled" : ""
							}
						>
							{option.iconName && (
								<Box paddingRight={Size.xs}>
									<Icon name={option.iconName} />
								</Box>
							)}
							{option.label}
						</MenuItem>
					))}
				</Menu>
			</div>
		</StoryTheme>
	);
};
