import React, { useEffect, useState } from "react";

import { DIC, EDIC_KEY } from "../../../../../dictionary";
import ChipCombobox from "../../../../base-components/combobox/chip-combobox/ChipCombobox";
import {
	useDashboardCountryFilter,
	useDashboardFetchMetTrialFilter,
	useDashboardRegionsFilter,
	useDashboardSpeciesFilter,
	useDashboardUserFilter,
	useDashboardYearFilter,
} from "../../../hooks/hooks";
import { WITHOUT_MET_CONST } from "../../const";
import DashboardHeader from "../../dashboard-header/DashboardHeader";
import { IDashboardTeamManagerFilter } from "../types";

interface DashboardTeamManagersHeaderProps {
	filterGlobal: IDashboardTeamManagerFilter | undefined;
	submitNewFilter: (filter: IDashboardTeamManagerFilter) => void;
}

export default function DashboardTeamManagersHeader(
	props: DashboardTeamManagersHeaderProps,
) {
	const { metMap, metData } = useDashboardFetchMetTrialFilter();

	// SELECTED VALUES
	const { expYears, setExpYears, computeExpYearSet } =
		useDashboardYearFilter();
	const { countries, setCountries, countriesSorted } =
		useDashboardCountryFilter();
	const { regions, setRegions, regionsSetSorted } =
		useDashboardRegionsFilter();
	const [metIds, setMetIds] = useState<string[] | undefined>(undefined);
	const {
		usersIds: usersIds,
		setUsersIds: setUsersIds,
		translateUser,
		userIdsSorted,
	} = useDashboardUserFilter();
	const {
		speciesFilter: species,
		setSpeciesFilter: setSpecies,
		speciesSort,
	} = useDashboardSpeciesFilter();

	// END Values list -------

	const computeAndSubmitChartFilter = () => {
		props.submitNewFilter({
			expYears,
			metIds: metIds?.map((elt) =>
				(elt === WITHOUT_MET_CONST ? null : elt),
			),
			countries,
			regions,
			species,
			users: usersIds,
		});
	};
	// set filter on ready
	useEffect(() => {
		computeAndSubmitChartFilter();
	}, []);
	return (
		<DashboardHeader
			computeAndSubmitChartFilter={computeAndSubmitChartFilter}
		>
			<ChipCombobox<number>
				value={expYears ?? []}
				label={DIC(EDIC_KEY.YEAR)}
				onChange={setExpYears}
				comboboxValues={computeExpYearSet}
				translateMethod={(value) => value.toString()}
				className="DashboardHeader-combobox"
				selectAllBtn
			/>
			<ChipCombobox<string>
				value={usersIds ?? []}
				label={DIC(EDIC_KEY.USER)}
				onChange={setUsersIds}
				comboboxValues={userIdsSorted}
				translateMethod={translateUser}
				noWrap
				className="DashboardHeader-combobox"
				selectAllBtn
			/>
			<ChipCombobox<string>
				value={countries ?? []}
				label={DIC(EDIC_KEY.COUNTRY)}
				onChange={setCountries}
				comboboxValues={countriesSorted}
				translateMethod={(value) => value}
				noWrap
				className="DashboardHeader-combobox"
				selectAllBtn
			/>
			<ChipCombobox<string>
				value={regions ?? []}
				label={DIC(EDIC_KEY.REGION)}
				onChange={setRegions}
				comboboxValues={regionsSetSorted}
				translateMethod={(value) => value}
				noWrap
				className="DashboardHeader-combobox"
				selectAllBtn
			/>
			<ChipCombobox<string>
				value={metIds ?? []}
				label={DIC(EDIC_KEY.MET)}
				onChange={setMetIds}
				comboboxValues={[
					WITHOUT_MET_CONST,
					...(metData?.map((elt) => elt._id) || []),
				]}
				translateMethod={(value) =>
					metMap.get(value)?.name ||
					DIC(EDIC_KEY.DASHBOARD_WITHOUT_MET)
				}
				className="DashboardHeader-combobox"
				selectAllBtn
			/>
			<ChipCombobox<string>
				value={species ?? []}
				label={DIC(EDIC_KEY.SPECIES)}
				onChange={setSpecies}
				comboboxValues={speciesSort}
				translateMethod={(value) => value}
				className="DashboardHeader-combobox"
				selectAllBtn
			/>
		</DashboardHeader>
	);
}
