import { ISort } from "shared-type";

/* LOCAL STORAGE */
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

/* CONST */

export type FormMode = "creation" | "used" | "unused";

export const CLIENT_ID = "480dfab5-638c-4e00-8dfa-b5638c7e00d5";
export const CLIENT_SECRET = "CuEuvUfsBAgNFCE47o8bvgf1X7WvbK9KW4isiL2Vz38";

// Default sort and page size
export const VARIABLE_DEFAULT_SORT: ISort = { scope: "asc" };
export const TRIAL_DEFAULT_SORT: ISort = { name: "asc" };
export const GROWING_AREA_DEFAULT_SORT: ISort = { name: "asc" };
export const MET_DEFAULT_SORT: ISort = { name: "asc" };
export const TRIAL_DEFAULT_PAGE_SIZE = 20;
export const USER_DEFAULT_PAGE_SIZE = 15;
export const USER_SELECTION_DEFAULT_PAGE_SIZE = 25;
export const GROWING_AREA_DEFAULT_PAGE_SIZE = 30;
export const MET_DEFAULT_PAGE_SIZE = 20;
export const VARIABLE_DEFAULT_PAGE_SIZE = 20;
export const TEMPLATE_DEFAULT_PAGE_SIZE = 15;
export const TEMPLATE_DEFAULT_SORT: ISort = { _id: "desc" };
export const LOT_DEFAULT_SORT: ISort = {
	"genotype.name": "asc",
	"genotype.species": "asc",
	lotIdentifier: "asc",
};

export const LOT_DEFAULT_PAGE_SIZE = 20;
export const GENOTYPE_DEFAULT_SORT: ISort = {
	name: "asc",
	species: "asc",
};
export const GENOTYPE_DEFAULT_PAGE_SIZE = 20;

export const MONGODB_ATLAS_CHARTS_BASE_URL =
	"https://charts.mongodb.com/charts-project-0-zfxfi";
