import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { IGoogleMapCredential } from "shared-type";

import { configuration } from "../../../config";
import { useRequest } from "../../../request-provider";
import { defaultApiError } from "../../utils/errors";

const halfHourInS = 1800;
export const apiUserCredentials = createApi({
	reducerPath: "api-user-credentials",
	baseQuery: () => ({ data: undefined }),

	// global cache configuration for the api (in second)
	keepUnusedDataFor: halfHourInS,
	refetchOnMountOrArgChange: halfHourInS,

	// update tag
	tagTypes: [],

	// api definition
	endpoints: (build) => ({
		getGoogleMapCredential: build.query<
			IGoogleMapCredential,
			void | undefined
		>({
			queryFn: async () => {
				try {
					if (
						process.env.NODE_ENV !== "production" &&
						configuration.USE_LOCAL_API_KEY
					) {
						console.debug(
							"You currently use the local google api key, please contact Doriane if you see this message in production",
						);
						return {
							data: {
								apiKey: configuration.USE_LOCAL_API_KEY,
							},
						};
					}

					const result =
						await useRequest().credential.getMapCredential();
					return {
						data: result.data,
					};
				} catch (err) {
					console.error(err);
					return defaultApiError(
						err,
						"Unable to fetch google api credentials",
					);
				}
			},
		}),
	}),
});
