import {
	EVariableCoreType,
	IVarLimitedChoice,
	VariableCoreValue,
} from "shared-type";

export interface EditingVarLimitedChoice {
	label: string;
	value: string;
}

export class LimitedChoiceConverter {
	static convertValueFromEdition(
		value: string,
		type: EVariableCoreType,
	): VariableCoreValue {
		switch (type) {
			case EVariableCoreType.BOOLEAN:
				if (value.toLowerCase() === "true" || value === "1") {
					return true;
				}
				if (value.toLowerCase() === "false" || value === "0") {
					return false;
				}
				return value;
			case EVariableCoreType.DATE:
			case EVariableCoreType.DATETIME:
				return new Date(value);
			case EVariableCoreType.DEC:
			case EVariableCoreType.INT:
				return Number(value);
			case EVariableCoreType.STRING:
				return value;
			// case EVariableCoreType.PICTURE:
			// 	return (0);
			default:
				return "";
		}
	}

	static convertFromEdition(
		choices: EditingVarLimitedChoice[],
		type: EVariableCoreType,
	): IVarLimitedChoice[] {
		return choices.map((elt) => {
			const convertedValue =
				LimitedChoiceConverter.convertValueFromEdition(elt.value, type);
			return { ...elt, value: convertedValue };
		});
	}
	static convertToEdition(
		choices: IVarLimitedChoice[],
	): EditingVarLimitedChoice[] {
		return choices.map((elt) => {
			return {
				...elt,
				value: elt.value.toString(),
			};
		});
	}
}
