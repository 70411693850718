import { createApi } from "@reduxjs/toolkit/query/react";
import { useMemo } from "react";

import { EntitySerializer } from "common";
import {
	EVariableCoreCategory,
	EVariableScope,
	ICreateVariableCore,
	IPatchVariableCore,
	IScopedVariable,
	IVariableCore,
	IVariableCoreSerialized,
} from "shared-type";

import { useRequest } from "../../request-provider";
import { defaultApiError, ReduxApiError } from "../utils/errors";

export const apiVariables = createApi({
	reducerPath: "api-variable",
	baseQuery: () => ({ data: undefined }),
	// global configuration for the api (in second)
	keepUnusedDataFor: 60,
	// global configuration for the api
	refetchOnMountOrArgChange: 60,
	tagTypes: ["Update", "Delete", "Create"],
	endpoints: (build) => ({
		getVariableById: build.query<
			IVariableCoreSerialized | undefined,
			{ id: string | undefined; scope: EVariableScope }
		>({
			queryFn: async (arg) => {
				try {
					if (!arg.id) {
						return { data: undefined };
					}
					const result = await useRequest().variable.getVariableById(
						arg.scope,
						arg.id,
					);
					return {
						data: EntitySerializer.serialize<IVariableCore>(
							result.data,
						),
					};
				} catch (err) {
					console.log("ERROR");

					return defaultApiError(
						err,
						`Unable to fetch variable with id : ${arg.id} for scope ${arg.scope}`,
					);
				}
			},
			providesTags: (result, error, arg) => {
				return [
					{ type: "Delete", id: `${arg.id}-${arg.scope}` },
					{ type: "Update", id: `${arg.id}-${arg.scope}` },
					{ type: "Create", id: `${arg.id}-${arg.scope}` },
				];
			},
		}),
		getVariableUsageById: build.query<
			string[] | undefined,
			{ id: string | undefined; scope: EVariableScope }
		>({
			queryFn: async (arg) => {
				try {
					if (!arg.id) {
						return { data: undefined };
					}
					const result =
						await useRequest().variable.getVariableUsagesById(
							arg.scope,
							arg.id,
						);
					return { data: result.data };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch variable usages for id : " + arg,
					);
				}
			},
			providesTags: (result, error, arg) => {
				return [
					{ type: "Delete", id: `${arg.id}-${arg.scope}` },
					{ type: "Update", id: `${arg.id}-${arg.scope}` },
					{ type: "Create", id: `${arg.id}-${arg.scope}` },
				];
			},
		}),
		getAllVariables: build.query<IVariableCoreSerialized[], void>({
			queryFn: async (_) => {
				try {
					const fetchVariablesSystem = useRequest()
						.variable.getVariables(EVariableScope.SYSTEM)
						.then((res) =>
							res.data.map(
								(elt) =>
									EntitySerializer.serialize<IVariableCore>(
										elt,
									), // because lib request deserialize and we need serialized version
							),
						);
					const fetchVariablesStandard = useRequest()
						.variable.getVariables(EVariableScope.STANDARD)
						.then((res) =>
							res.data.map(
								(elt) =>
									EntitySerializer.serialize<IVariableCore>(
										elt,
									), // because lib request deserialize and we need serialized version
							),
						);
					const fetchVariablesCustom = useRequest()
						.variable.getVariables(EVariableScope.CUSTOM)
						.then((res) =>
							res.data.map(
								(elt) =>
									EntitySerializer.serialize<IVariableCore>(
										elt,
									), // because lib request deserialize and we need serialized version
							),
						);

					const [system, standard, custom] = await Promise.all([
						fetchVariablesSystem,
						fetchVariablesStandard,
						fetchVariablesCustom,
					]);

					const allVariables: IVariableCoreSerialized[] = [];
					allVariables.push(...system);
					allVariables.push(...standard);
					allVariables.push(...custom);

					return {
						data: allVariables,
					};
				} catch (err) {
					return defaultApiError(err, "Unable to fetch variables");
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),
		deleteCustomVariableById: build.mutation<void, string | undefined>({
			queryFn: async (id) => {
				if (!id) {
					const error: { error: ReduxApiError } = {
						error: {
							message: "Id is required for deleting a variable",
						},
					};
					return error;
				}
				try {
					const result =
						await useRequest().variable.deleteCustomVariableById(
							id,
						);
					return { data: result.data };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to delete variable with id : " + id,
					);
				}
			},
			invalidatesTags: (result, error, id) => {
				if (error) {
					return [];
				}
				return [
					{ type: "Delete", id: `${id}-${EVariableScope.CUSTOM}` },
					{ type: "Delete", id: "ALL" },
				];
			},
		}),
		duplicateCustomVariableByIds: build.mutation<any, string[]>({
			queryFn: async (duplicateVariableIds) => {
				try {
					const result =
						await useRequest().variable.duplicateCustomVariableByIds(
							duplicateVariableIds,
						);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to duplicate the custom variable",
					);
				}
			},
			invalidatesTags: (res) => [
				{ type: "Create", id: res },
				{ type: "Create", id: "ALL" },
			],
		}),
		createCustomVariable: build.mutation<
			IVariableCoreSerialized,
			ICreateVariableCore
		>({
			queryFn: async (newCustomVariable) => {
				let id: string;
				try {
					id = await useRequest()
						.variable.createCustomVariable(newCustomVariable)
						.then((resp) => resp.data);
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to create the custom variable",
					);
				}
				try {
					const createdCustomVariable: IVariableCore =
						await useRequest()
							.variable.getVariableById(EVariableScope.CUSTOM, id)
							.then((resp) => resp.data);

					const createdCustomVariableSerialized =
						EntitySerializer.serialize<IVariableCore>(
							createdCustomVariable,
						);

					return { data: createdCustomVariableSerialized };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to get the custom variable with id : " + id,
					);
				}
			},
			invalidatesTags: (result, error) => {
				if (error) {
					return [];
				}
				return [
					{
						type: "Create",
						id: `${result?._id}-${EVariableScope.CUSTOM}`,
					},
					{ type: "Create", id: "ALL" },
				];
			},
		}),
		updateCustomVariableById: build.mutation<
			void,
			{ id: string | undefined; update: IPatchVariableCore }
		>({
			queryFn: async (arg) => {
				if (!arg.id) {
					const error: { error: ReduxApiError } = {
						error: {
							message: "Id is needed to update a variable",
						},
					};
					return error;
				}
				try {
					const result = await useRequest()
						.variable.updateCustomVariableById(arg.id, arg.update)
						.then((resp) => resp.data);
					return { data: result };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to update custom variable",
					);
				}
			},
			invalidatesTags: (result, error, arg) => {
				if (error) {
					return [];
				}
				return [
					{
						type: "Update",
						id: `${arg.id}-${EVariableScope.CUSTOM}`,
					},
					{ type: "Update", id: "ALL" },
				];
			},
		}),
	}),
});

const useGetVariableByIdQueryDeserialized = (
	args: { id: string | undefined; scope: EVariableScope },
	option?: Parameters<typeof apiVariables.useGetVariableByIdQuery>[1],
) => {
	const apiResult = apiVariables.useGetVariableByIdQuery(args, option as any);
	const dataDeserialized = useMemo(() => {
		return apiResult.data
			? EntitySerializer.deserialize<IVariableCore>(apiResult.data)
			: undefined;
	}, [apiResult.data]);
	return { ...apiResult, data: dataDeserialized };
};

const useGetAllVariablesQueryDeserialized = (
	option?: Parameters<typeof apiVariables.useGetAllVariablesQuery>[1],
) => {
	const apiResult = apiVariables.useGetAllVariablesQuery(
		undefined,
		option as any,
	);
	const dataDeserialized = useMemo(
		() =>
			apiResult?.data?.map((elt) =>
				EntitySerializer.deserialize<IVariableCore>(elt),
			),
		[apiResult.data],
	);
	return { ...apiResult, data: dataDeserialized };
};

const useGetVariablesByScopedIdsDeserialized = (
	args: IScopedVariable[] | undefined,
	option?: Parameters<typeof apiVariables.useGetAllVariablesQuery>[1],
) => {
	const apiResult = apiVariables.useGetAllVariablesQuery(
		undefined,
		option as any,
	);
	const filteredVariables = useMemo(() => {
		if (apiResult.data === undefined || args === undefined) return [];
		const filtered = apiResult.data.filter((apiVar) => {
			const isFound =
				args.find(
					(varInfo) =>
						apiVar._id === varInfo.variableId &&
						apiVar.scope === varInfo.scope,
				) !== undefined;
			return isFound;
		});
		return filtered.map((elt) =>
			EntitySerializer.deserialize<IVariableCore>(elt),
		);
	}, [apiResult.data, args]);
	return { ...apiResult, data: filteredVariables };
};

const useGetOntologyVariableListDeserialized = () => {
	const apiResult = apiVariables.useGetAllVariablesQuery();
	const ontologyVariables = useMemo(() => {
		if (apiResult.data === undefined) return [];
		const filtered = apiResult.data?.filter(
			(elt) => elt.category === EVariableCoreCategory.OBSERVATION,
		);

		return filtered.map((elt) =>
			EntitySerializer.deserialize<IVariableCore>(elt),
		);
	}, [apiResult.data]);
	return { ...apiResult, data: ontologyVariables };
};

const useGetOthersVariableListDeserialized = () => {
	const apiResult = apiVariables.useGetAllVariablesQuery();

	const otherVariables = useMemo(() => {
		if (apiResult.data === undefined) return [];
		const filtered = apiResult.data.filter(
			(elt) => elt.category === EVariableCoreCategory.DEFAULT,
		);

		return filtered.map((elt) =>
			EntitySerializer.deserialize<IVariableCore>(elt),
		);
	}, [apiResult.data]);
	return { data: otherVariables };
};

export const variablesCustomHook = {
	useGetVariableByIdQueryDeserialized,
	useGetVariablesByScopedIdsDeserialized,
	useGetAllVariablesQueryDeserialized,
	useGetOntologyVariableListDeserialized,
	useGetOthersVariableListDeserialized,
};
