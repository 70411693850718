import { useEffect, useMemo, useState } from "react";

import { ETrialLevel, IVariableCoreSerialized } from "shared-type";
import { dateComparison } from "common";

import { INotationTableRow } from "../utils/datagrid.types";

export function useFilterAndSortDatagrid(
	data: INotationTableRow[],
	selectedVariable?: IVariableCoreSerialized,
	selectedLevel?: ETrialLevel,
) {
	// for mapping[0]->5 -> line 0 of filteredData is 5 line of the dataset
	const [mapping, setMapping] = useState<number[]>([]);

	useEffect(() => {
		const dataFilteredMask = data.map(
			(elt) =>
				elt.unit === selectedLevel &&
				elt.variable?._id === selectedVariable?._id,
		);
		const newMapping: number[] = [];
		dataFilteredMask.forEach((elt, index) => {
			if (elt) {
				newMapping.push(index);
			}
		});

		// sort the grid by date
		newMapping.sort((a, b) => {
			const notationDateA = data[a].notation?.notationDate;
			const notationDateB = data[b].notation?.notationDate;
			return dateComparison(notationDateB, notationDateA);
		});
		setMapping(newMapping);
	}, [selectedVariable, selectedLevel, data]);

	const filteredAndSortData = useMemo(
		() => mapping.map((elt) => data[elt]),
		[data, mapping],
	);
	return { filteredAndSortData, mapping };
}
