import { Radio, RadioProps } from "@mui/material";
import React from "react";

import "./DorianeRadioButton.scss";

export default function DorianeRadioButton(props: RadioProps) {
	return (
		<Radio
			className={
				"DorianeRadioButton" +
				(props.className ? " " + props.className : "")
			}
			{...props}
		/>
	);
}
