"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnvActionVariable = void 0;
class EnvActionVariable {
    constructor(type, value, description = "") {
        this.type = type;
        this.value = value;
        this.description = description;
    }
}
exports.EnvActionVariable = EnvActionVariable;
