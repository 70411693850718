import { Point } from "geojson";
import _ from "lodash";

export interface GoogleMapPosition {
	lat: number;
	lng: number;
}
export abstract class MapHelper {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static isValidGeoJSONPoint(point?: any): point is Point {
		return (
			point !== undefined &&
			typeof point === "object" &&
			_.isNumber(point.coordinates?.[0]) &&
			_.isNumber(point.coordinates?.[1])
		);
	}

	static getGoogleMapPointFromGeoJSON(point: Point) {
		return {
			lat: point.coordinates[1],
			lng: point.coordinates[0],
		};
	}
}
