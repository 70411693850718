import {
	EditableGridCell,
	GridCell,
	GridColumn,
	GridSelection,
	Item,
	Rectangle,
	SizedGridColumn,
} from "@glideapps/glide-data-grid";
import { Popover } from "@mui/material";
import produce from "immer";
import { WritableDraft } from "immer/dist/internal";
import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	ETrialLevel,
	INotationSerialized,
	IPutNotationReview,
	IVariableCoreSerialized,
} from "shared-type";

import { DIC, EDIC_KEY } from "../../../../../dictionary";
import useAppSnackbar from "../../../../../hooks/useAppSnackbar";
import { experimentReviewNotationAction } from "../../../../../redux/experiment-review-notation/experiment-review-notation.slice";
import { selectExperimentReviewNotation } from "../../../../../redux/experiment-review-notation/experiment-review-notation.selector";
import { apiNotation } from "../../../../../redux/notation/notation.api";
import { ApiErrorSnackbar } from "../../../../../redux/utils/api-error-snackbar/ApiErrorSnackbar";
import { ReduxApiError } from "../../../../../redux/utils/errors";
import { AppDispatch } from "../../../../../store";
import { DorianeGrid } from "../../../../base-components";
import DataStatusMenu from "./columns/data-status/menus/DataStatusMenu";
import { TRIAL_DATA_COLUMN } from "./columns/TrialDataColumns";
import { useFilterAndSortDatagrid, useGetDatagridData } from "./hooks";
import {
	IModificationRow,
	INotationTableRow,
	MutateRows,
} from "./utils/datagrid.types";

export interface ITrialValidationDatagridWrapperRef {
	handleSave: () => Promise<void>;
}
interface ITrialValidationDatagridWrapperProps {
	selectedVariable?: IVariableCoreSerialized;
	selectedLevel?: ETrialLevel;
}
const TrialValidationDatagridWrapper = forwardRef<
	ITrialValidationDatagridWrapperRef,
	ITrialValidationDatagridWrapperProps
>(function TrialValidationDatagridWrapper(props, ref) {
	// UTILS
	const dispatch = useDispatch<AppDispatch>();
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();

	// FORCE REFRESH
	const [recompute, setRecompute] = useState<any>();
	const forceUpdate = React.useCallback(() => setRecompute({}), []);

	// ACTION PART
	const [updateManyNewNotationsReview] =
		apiNotation.useUpdateManyNotationsReviewMutation();

	// DATA PART
	const data = useRef<INotationTableRow[]>([]);
	const dataModification = useRef<Map<number, IModificationRow>>(new Map());
	const dataFetch = useGetDatagridData();
	const dataWithModification: INotationTableRow[] = useMemo(() => {
		return data.current.map((elt) => {
			const modification = dataModification.current.get(elt.id);
			if (modification !== undefined && elt.notation !== undefined) {
				const notationModified: INotationSerialized = {
					...elt.notation,
					...modification.notationReviewModification,
				};
				return {
					...elt,
					notation: notationModified,
				};
			}
			return elt;
		});
	}, [recompute]);

	const { filteredAndSortData } = useFilterAndSortDatagrid(
		dataWithModification,
		props.selectedVariable,
		props.selectedLevel,
	);

	const mutateRows: MutateRows = useCallback(
		(modifications: IModificationRow[]) => {
			modifications.forEach((modif) => {
				dataModification.current.set(modif.rowId, modif);
			});
			forceUpdate(); // for now, we rerender all the grid
		},
		[forceUpdate, dataModification],
	);
	const handleSave = useCallback(async () => {
		const notationsReviewToUpdate: IPutNotationReview[] = [];
		dataModification.current.forEach((currentModification, id) => {
			if (currentModification.notationReviewModification !== undefined) {
				notationsReviewToUpdate.push(
					currentModification.notationReviewModification,
				);
			}
		});

		// update notation that already exist
		await updateManyNewNotationsReview(notationsReviewToUpdate)
			.unwrap()
			.then(() => {
				enqueueSnackbarSuccess(DIC(EDIC_KEY.SAVE));
				dataModification.current = new Map(); // reset all local change
			})
			.catch((err: ReduxApiError) => {
				console.warn(err);
				enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
			});
	}, [data]);

	// for pass the save method to the parent
	useImperativeHandle(
		ref,
		() => {
			return {
				handleSave: handleSave,
			};
		},
		[handleSave],
	);

	// when data is fetch -> we want update the grid
	useEffect(() => {
		if (dataFetch.status.isSuccess) {
			data.current = dataFetch.gridRow;
			forceUpdate();
		}
	}, [dataFetch.gridRow]);

	// Each time the data change, we went to compute the kpi
	// (useless without the final value)
	useEffect(() => {
		dispatch(
			experimentReviewNotationAction.computeComputedValue(
				filteredAndSortData
					.map((elt) => {
						const value = elt?.notation?.value;
						if (typeof value === "number") {
							return value;
						}
						return undefined;
					})
					.filter((elt) => elt !== undefined) as number[],
			),
		);
	}, [filteredAndSortData]);

	return (
		<TrialValidationDatagrid
			data={filteredAndSortData}
			mutateRows={mutateRows}
		/>
	);
});

interface ITrialValidationDatagridProps {
	data: INotationTableRow[];
	mutateRows: MutateRows;
}

function TrialValidationDatagrid({
	data,
	mutateRows,
}: ITrialValidationDatagridProps) {
	const context = useSelector(
		selectExperimentReviewNotation.selectGridContext,
	);
	const [datagridSelection, setDatagridSelection] = useState<
		GridSelection | undefined
	>(undefined);

	const [menuOpen, setMenuOpen] = useState<{
		col: number;
		position: Rectangle;
		component: React.ReactElement;
	} | null>(null);

	const [gridColumn, setGridColumn] = useState<GridColumn[]>(
		TRIAL_DATA_COLUMN.map((elt) => elt.glideDataGrid),
	);

	const getContent = useCallback(
		(cell: Item): GridCell => {
			const [col, row] = cell;
			const dataRow = data[row] || {};
			// dumb but simple way to do this

			// If column was reorder we need to add map
			// to access specific data column
			return TRIAL_DATA_COLUMN[col].cellRenderer(dataRow, context);
		},
		[data, context],
	);

	// LISTENER
	const onColumnResize = useCallback(
		(column: GridColumn, newSize: number, colIndex: number) => {
			setGridColumn((old) =>
				produce(
					old,
					(gridColumnMutable: WritableDraft<SizedGridColumn[]>) => {
						gridColumnMutable[colIndex].width = newSize;
					},
				),
			);
		},
		[setGridColumn],
	);

	const onCellEdited = useCallback(
		(cell: Item, newValue: EditableGridCell) => {
			const [colIndex, rowIndex] = cell;
			const column = TRIAL_DATA_COLUMN[colIndex];
			const row = data[rowIndex];
			if (!row) {
				return;
			}
			column?.onEditingCell?.(row, newValue, mutateRows);
		},
		[mutateRows, data],
	);

	const onHeaderMenuClick = React.useCallback(
		(col: number, position: Rectangle) => {
			const rowSelected =
				datagridSelection?.rows
					.toArray()
					.map((rowIndex) => data[rowIndex] || {}) || [];
			setMenuOpen({
				col,
				position,
				component: (
					<DataStatusMenu
						rowSelected={rowSelected}
						mutateRows={mutateRows}
						onClose={() => setMenuOpen(null)}
					></DataStatusMenu>
				),
			});
		},
		[datagridSelection, data, mutateRows],
	);

	useEffect(() => {
		// on content change -> clear selection
		setDatagridSelection(undefined);
	}, [getContent]);

	return (
		<div className="full-parent-size no-animation">
			<DorianeGrid
				rowMarkers={"both"}
				getCellContent={getContent}
				columns={gridColumn}
				rows={data.length}
				minColumnWidth={10}
				maxColumnWidth={10000}
				rowSelectionBlending={"mixed"}
				height={"100%"}
				onColumnResize={onColumnResize}
				onCellEdited={onCellEdited}
				onHeaderMenuClick={onHeaderMenuClick}
				onGridSelectionChange={setDatagridSelection}
				gridSelection={datagridSelection}
			/>
			{menuOpen && (
				<Popover
					open={true}
					anchorReference="anchorPosition"
					anchorPosition={{
						left: menuOpen.position.x,
						top: menuOpen.position.y + menuOpen.position.height,
					}}
					onClose={() => setMenuOpen(null)}
				>
					{menuOpen.component}
				</Popover>
			)}
		</div>
	);
}

export default TrialValidationDatagridWrapper;
