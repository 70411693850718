import React, { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { IAnalyticViewMetadata } from "shared-type";
import DorianeButton from "../../../components/base-components/button/DorianeButton";

import { CustomLinkTab } from "../../../components/base-components/tabs/CustomLinkTab";
import { CustomTabs } from "../../../components/base-components/tabs/CustomTabs";
import { DIC, EDIC_KEY } from "../../../dictionary";
import { apiChart } from "../../../redux/chart/chart.api";
import {
	PATH_DASHBOARD_ORGANIZATION,
	PATH_DASHBOARD_PLANNING,
	PATH_DASHBOARD_TEAM,
	PATH_DASHBOARD_VARIETY,
} from "../../../routes";

import "./DashboardPage.scss";

export interface DashboardFilter {
	expYears?: number[];
	expLabels?: string[];
	users?: string[];
	regions?: string[];
	validChecked?: boolean;
	inProgressChecked?: boolean;
	variable?: string;
	genotypes?: string[];
}

export default function DashboardPage() {
	const [tabIdx, setTabIdx] = useState(0);
	const location = useLocation();
	const nav = useNavigate();
	const [analyticViewDate, setAnalyticViewDate] = useState<Date | undefined>(
		undefined,
	);
	const [forceRefreshCount, setForceRefreshCount] = useState<number>(0);
	const [refreshAnalyticData] = apiChart.useUpdateAnalyticViewMutation();
	const [getAnalyticViewDate] = apiChart.useGetAnalyticViewMetadataMutation();

	useEffect(() => {
		const splittedPath = location.pathname.split("/");
		const currentTab = "/" + splittedPath[2] ?? undefined;
		switch (currentTab) {
			case PATH_DASHBOARD_VARIETY:
				setTabIdx(0);
				break;
			case PATH_DASHBOARD_ORGANIZATION:
				setTabIdx(1);
				break;
			case PATH_DASHBOARD_TEAM:
				setTabIdx(2);
				break;
			case PATH_DASHBOARD_PLANNING:
				setTabIdx(3);
				break;
			default:
				nav("." + PATH_DASHBOARD_VARIETY, { replace: true });
		}
	}, [location, nav]);

	useEffect(() => {
		const fetchDate = async () => {
			const { data } = (await getAnalyticViewDate()) as {
				data: IAnalyticViewMetadata | undefined;
			};
			setAnalyticViewDate(data?.date);
		};

		fetchDate();
	}, [getAnalyticViewDate, forceRefreshCount]);

	// BEWARE - Refreshing analytic view data does NOT refresh the charts.
	// Charts data is cached and auto-refreshed every hour. To force refresh, rebuild all the charts with no cache & auto-refresh.
	const refresh = useCallback(async () => {
		setAnalyticViewDate(undefined);
		await refreshAnalyticData();
		setForceRefreshCount(forceRefreshCount + 1);
	}, [forceRefreshCount, refreshAnalyticData]);

	return (
		<div className="DashboardPage-container full-parent-size flex-column">
			<CustomTabs value={tabIdx} indicatorColor="secondary">
				<CustomLinkTab
					label={DIC(EDIC_KEY.VARIETY_PERF)}
					href={`.${PATH_DASHBOARD_VARIETY}`}
				/>
				<CustomLinkTab
					label={DIC(EDIC_KEY.NETWORK)}
					href={`.${PATH_DASHBOARD_ORGANIZATION}`}
				/>
				<CustomLinkTab
					label={DIC(EDIC_KEY.TEAM)}
					href={`.${PATH_DASHBOARD_TEAM}`}
				/>
				<CustomLinkTab
					label={DIC(EDIC_KEY.PLANNING)}
					href={`.${PATH_DASHBOARD_PLANNING}`}
				/>
			</CustomTabs>
			{/* on change key -> all children are unmounted then mounted again (force refresh) */}
			<div
				className="DashboardPage-body flex-column"
				key={forceRefreshCount}
			>
				<Outlet />
			</div>
			{tabIdx !== 3 && (
				<div className="DashboardPage-footer">
					{DIC(EDIC_KEY.LAST_UPDATED_ON).replace(
						"{0}",
						analyticViewDate?.toString() ??
							DIC(EDIC_KEY.LOADING_IN_PROGRESS),
					)}
					<DorianeButton dorianeStyle="tertiary" onClick={refresh}>
						{DIC(EDIC_KEY.REFRESH)}
					</DorianeButton>
				</div>
			)}
		</div>
	);
}
