import React from "react";
import * as MUIcon from "@mui/icons-material";
import { ReactSVG } from "react-svg";

import { generateUniqueIDWithLettersAndNumbersOnly } from "common";

import { StoryTheme } from "stories/StoryTheme";
import { IIconType } from "./IconType";

import "./icon.scss";

export type IIcon = {
	name?: IIconType;
	muiIcon?: keyof typeof MUIcon;
	color?: string;
	fontSize?: string;
};

export const Icon = (icon: IIcon) => {
	const MUIIcon = icon?.muiIcon && MUIcon[icon?.muiIcon];
	const id = generateUniqueIDWithLettersAndNumbersOnly(20);
	return (
		<StoryTheme>
			{icon.name ? (
				<>
					<ReactSVG
						src={
							process.env.PUBLIC_URL +
							"/icons/" +
							icon.name +
							".svg"
						}
						className={"svgIconColor svgIconColor_" + id}
						fill={icon?.color || undefined}
					/>
					{icon?.color && (
						// The only possibility to update dynamically the icon color
						// Note : The svg file have to be imported with ReactSVG to have a <svg> balise to be updatable
						<style>
							{`
								.svgIconColor_` +
								id +
								" svg path { fill:" +
								icon?.color +
								`; }
								.svgIconColor_` +
								id +
								" svg { width:" +
								icon?.fontSize +
								`; 
								height:` +
								icon?.fontSize +
								`; }
							`}
						</style>
					)}
				</>
			) : (
				MUIIcon && (
					<MUIIcon
						style={{
							color: icon?.color || undefined,
							fontSize: icon?.fontSize || undefined,
						}}
					/>
				)
			)}
		</StoryTheme>
	);
};
