import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IObsRoundSerialized } from "shared-type";

import { selectObsRoundEdition } from "../../../../../../../../redux/observation-round-edition/obs-round-edition.selector";
import { obsRoundEditionActions } from "../../../../../../../../redux/observation-round-edition/obs-round-edition.slice";
import { apiObsRound } from "../../../../../../../../redux/observation-round/obs-round.api";
import { AppDispatch } from "../../../../../../../../store";

// only for more maintainability
export function useObsRoundLoader(obsRoundId?: string) {
	const dispatch = useDispatch<AppDispatch>();
	const isInit = useSelector(selectObsRoundEdition.isInit);

	// initialiser :
	const { data: remoteObsRound } =
		apiObsRound.useGetObservationRoundByIdQuery(obsRoundId);
	const { data: canDeleteObsRound } =
		apiObsRound.useCanDeleteObsRoundByIdQuery(obsRoundId);

	useEffect(() => {
		dispatch(obsRoundEditionActions.setRemoteObsRound(remoteObsRound));
	}, [dispatch, remoteObsRound]);

	useEffect(() => {
		dispatch(
			obsRoundEditionActions.setCanDeleteObsRound(canDeleteObsRound),
		);
	}, [dispatch, canDeleteObsRound]);

	useEffect(() => {
		// on dismount -> reset current obs round edition
		return () => {
			dispatch(obsRoundEditionActions.resetObsRound());
		};
	}, [dispatch]);
	return isInit;
}
export function useObsRoundEdition() {
	const dispatch = useDispatch<AppDispatch>();

	const currentObsRound = useSelector(
		selectObsRoundEdition.editingMergeValueDeserialized,
	);
	const canDeleteObsRound = useSelector(
		selectObsRoundEdition.canDeleteObsRound,
	);
	const isSelectUserOpen = useSelector(
		selectObsRoundEdition.isSelectUserOpen,
	);
	const isSelectVarGroupOpen = useSelector(
		selectObsRoundEdition.isSelectVarGroupOpen,
	);
	const isCreateVarGroupOpen = useSelector(
		selectObsRoundEdition.isCreateVarGroupOpen,
	);
	const isConsultVarGroupOpen = useSelector(
		selectObsRoundEdition.isConsultVarGroupOpen,
	);
	const isCommentOpen = useSelector(selectObsRoundEdition.isCommentOpen);
	const isSendPopupOpen = useSelector(selectObsRoundEdition.isSendPopupOpen);
	const isEditing = useSelector(selectObsRoundEdition.isEditingInProgress);
	const isSendNotebookDisplayed = useSelector(
		selectObsRoundEdition.isSendNotebookDisplayed,
	);

	const updateCurrentObsRound = (update: Partial<IObsRoundSerialized>) =>
		dispatch(obsRoundEditionActions.updateObsRound(update));
	const setCommentOpen = (isOpen: boolean) =>
		dispatch(obsRoundEditionActions.setCommentOpen(isOpen));
	const setSelectUserOpen = (isOpen: boolean) =>
		dispatch(obsRoundEditionActions.setSelectUserOpen(isOpen));
	const setSelectVarGroupOpen = (isOpen: boolean) =>
		dispatch(obsRoundEditionActions.setSelectVarGroupOpen(isOpen));
	const setCreateVarGroupOpen = (isOpen: boolean) =>
		dispatch(obsRoundEditionActions.setCreateVarGroupOpen(isOpen));
	const setConsultVarGroupOpen = (isOpen: boolean) =>
		dispatch(obsRoundEditionActions.setConsultVarGroupOpen(isOpen));
	const setIsSendPopupOpen = (isOpen: boolean) =>
		dispatch(obsRoundEditionActions.setIsOpenSendPopupNb(isOpen));

	return {
		currentObsRound,
		canDeleteObsRound,
		isCommentOpen,
		isSelectUserOpen,
		isSelectVarGroupOpen,
		isCreateVarGroupOpen,
		isConsultVarGroupOpen,
		isSendPopupOpen,
		isEditing,
		isSendNotebookDisplayed,
		updateCurrentObsRound,
		setCommentOpen,
		setSelectUserOpen,
		setSelectVarGroupOpen,
		setCreateVarGroupOpen,
		setConsultVarGroupOpen,
		setIsSendPopupOpen,
	};
}
