import { GridCell, GridCellKind } from "@glideapps/glide-data-grid";

import {
	ENotebookStatus,
	EReviewStatus,
	EVariableCoreType,
	IVariableCoreSerialized,
} from "shared-type";

import { INotationTableRow } from "../../utils/datagrid.types";
import { booleanNotationCellDrawer } from "./cell-by-types/boolean-notation";
import { dateNotationCellDrawer } from "./cell-by-types/date-notation";
import { numberNotationCellDrawer } from "./cell-by-types/number-notation-cell";
import { stringNotationCellDrawer } from "./cell-by-types/string-notation-cell";
import { INotationCellOption } from "./notation-cell.types";

export function isModifiableValue(row: INotationTableRow) {
	return (
		row.notebook?.status === ENotebookStatus.COMPLETED &&
		row.notation?.reviewStatus === EReviewStatus.NA
	);
}

export default function notationCell(
	value: any,
	variable?: IVariableCoreSerialized,
	option?: INotationCellOption,
): GridCell {
	// TODO implement switch with all variables types
	switch (variable?.type) {
		case EVariableCoreType.INT:
		case EVariableCoreType.DEC:
			return numberNotationCellDrawer(value, variable, option);
		case EVariableCoreType.BOOLEAN:
			return booleanNotationCellDrawer(value, variable, option);
		case EVariableCoreType.STRING:
			return stringNotationCellDrawer(value, variable, option);
		case EVariableCoreType.DATE:
			return dateNotationCellDrawer(value, false, option);
		case EVariableCoreType.DATETIME:
			return dateNotationCellDrawer(value, true, option);
		default:
			if (process.env.NODE_ENV !== "production") {
				console.warn(
					`Variable type (${variable?.type}) not implemented`,
				);
			}
			return {
				kind: GridCellKind.Text,
				displayData: value !== undefined ? JSON.stringify(value) : "",
				data: value !== undefined ? JSON.stringify(value) : "",
				allowOverlay: false,
				readonly: true,
			};
	}
}
