import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { formatString, trimVarFieldsToRemove } from "common";
import {
	EVariableCoreInputType,
	EVariableCoreType,
	IVariableCore,
} from "shared-type";

import {
	ICustomVariableForm,
	variableValidator,
} from "App/components/form/custom-variable";
import CustomVariableForm from "App/components/form/custom-variable/CustomVariableForm";
import { DIC, EDIC_KEY } from "../../../../dictionary";
import useAppSnackbar from "../../../../hooks/useAppSnackbar";
import { ApiErrorSnackbar } from "../../../../redux/utils/api-error-snackbar/ApiErrorSnackbar";
import { ReduxApiError } from "../../../../redux/utils/errors";
import { apiVariables } from "../../../../redux/variables/variables.api";
import { customVarFormToObject } from "../../../form/custom-variable/transformer";
import { Modal } from "stories/base-components/Modal/Modal";

import "./CreateCustomVariableModal.scss";

interface CreateCustomVariableModalProps {
	onClose: () => void;
	open: boolean;
}

const defaultVariable: ICustomVariableForm = {
	identifier: "",
	name: "",
	shortName: "",
	description: "",
	type: EVariableCoreType.STRING,
	inputType: EVariableCoreInputType.FREE,
	constraint: { type: EVariableCoreType.STRING },
	limitedChoices: [],
};

export default function CreateCustomVariableModal(
	props: CreateCustomVariableModalProps,
) {
	const [createAndCloseLoading, setCreateAndCloseLoading] =
		useState<boolean>(false);
	const [createAndContinueLoading, setCreateAndContinueLoading] =
		useState<boolean>(false);

	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();

	const [createCustomVariable] =
		apiVariables.useCreateCustomVariableMutation();

	const form = useForm<ICustomVariableForm>({
		defaultValues: { ...defaultVariable },
		resolver: yupResolver(variableValidator as any),
	});

	const { handleSubmit, formState } = form;
	const { isSubmitting } = formState;

	const [formTabSelected, setFormTabSelected] = useState<number>(0);

	const onSubmit = async (
		data: ICustomVariableForm,
		continueMode: boolean,
	) => {
		if (continueMode) {
			setCreateAndContinueLoading(true);
		} else {
			setCreateAndCloseLoading(true);
		}

		const variableData = customVarFormToObject(data) as IVariableCore;
		const { trimmedVariable } = trimVarFieldsToRemove(variableData);

		await createCustomVariable(trimmedVariable)
			.unwrap()
			// After creation continue or close
			.then(() => {
				form.reset();
				setFormTabSelected(0);
				if (!continueMode) {
					props.onClose();
				}
				enqueueSnackbarSuccess(
					formatString(
						DIC(EDIC_KEY.ENTITY_CREATED),
						DIC(EDIC_KEY.VARIABLE),
					),
				);
			})
			.catch((err: ReduxApiError) => {
				console.warn(err);
				enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
			});
	};

	const handleCreate = async (continueMode: boolean) => {
		handleSubmit(
			(data) => onSubmit(data, continueMode),
			(error) => console.error(error),
		)();
	};

	const handleNext = async () => {
		handleSubmit(
			(data) => setFormTabSelected(1),
			(error) => {
				// Block step2 only if fields of first step in errors
				if (
					!(
						error.identifier ||
						error.name ||
						error.shortName ||
						error.description
					)
				) {
					setFormTabSelected(1);
				}
			},
		)();
	};

	const handleClose = () => {
		props.onClose();
		setFormTabSelected(0);
		form.reset(defaultVariable);
	};

	useEffect(() => {
		if (!isSubmitting) {
			setCreateAndCloseLoading(false);
			setCreateAndContinueLoading(false);
		}
	}, [isSubmitting]);

	return (
		<Modal
			open={props.open}
			titleIcon={"brackets-curly"}
			title={DIC(EDIC_KEY.CUSTOM_VARIABLE_CREATION)}
			handleClose={handleClose}
			validateBtn={
				formTabSelected === 1
					? {
							action: () => {
								handleCreate(true);
							},
							label: DIC(EDIC_KEY.CREATE_AND_CONTINUE),
							isLoading: createAndContinueLoading,
					  }
					: {
							action: () => {
								handleNext();
							},
							label: DIC(EDIC_KEY.NEXT),
							iconName: "arrow-line-right",
					  }
			}
			secondaryBtn={
				formTabSelected === 1
					? {
							action: () => {
								handleCreate(false);
							},
							label: DIC(EDIC_KEY.CREATE_AND_CLOSE),
							isLoading: createAndCloseLoading,
					  }
					: undefined
			}
			width={751}
			maxHeight={600}
			stepper={[DIC(EDIC_KEY.GENERAL), DIC(EDIC_KEY.SETTINGS)]}
			stepperSelected={formTabSelected}
			onStepperClick={(step: number) => {
				if (step === 1) {
					handleNext();
				} else {
					setFormTabSelected(step);
				}
			}}
		>
			<div className="CreateCustomVariableModal-container take-remaining-space flex-column">
				<div className="CreateCustomVariableModal-form take-remaining-space overflow-auto">
					<CustomVariableForm
						form={form}
						mode="creation"
						formTabSelected={formTabSelected}
						setFormTabSelected={setFormTabSelected}
					/>
				</div>
			</div>
		</Modal>
	);
}
