import { useMemo } from "react";

import { removeDuplicates } from "common";
import {
	IGrowingAreaSerialized,
	IMarketSegment,
	IMETSerialized,
	ITrialSerialized,
} from "shared-type";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import { apiMET } from "../../../../redux/experiment/met/met.api";
import { apiGrowingArea } from "../../../../redux/growing-area/growing-area.api";
import { apiMarketSegment } from "../../../../redux/market-segment/market-segment.api";

export type TrialSerializedWithDeps = ITrialSerialized & {
	growingArea?: IGrowingAreaSerialized;
	met?: IMETSerialized;
	marketSegments?: IMarketSegment[];
};

export const useTrialWithDeps = (
	trials?: ITrialSerialized[],
	needMarketSegments?: boolean,
	needMET?: boolean,
) => {
	const growingAreaIds = useMemo(() => {
		if (!trials) return [];
		const ids = trials
			.map((trial) => trial.growingAreaId)
			.filter((id) => id !== undefined) as string[];
		return removeDuplicates(ids);
	}, [trials]);

	const metIds = useMemo(() => {
		if (!trials || !needMET) return [];
		const ids = trials
			.map((trial) => trial.metId)
			.filter((id) => id !== undefined) as string[];
		return removeDuplicates(ids);
	}, [needMET, trials]);

	const marketSegmentIds = useMemo(() => {
		if (!trials || !needMarketSegments) return [];
		const ids = trials
			.flatMap((trial) => trial.marketSegmentIds)
			.filter((id) => id !== undefined) as string[];
		return removeDuplicates(ids);
	}, [needMarketSegments, trials]);

	const { data: growingAreas, isError: isGAError } =
		apiGrowingArea.useGetGrowingAreaByIdsQuery(growingAreaIds);

	const { data: mets, isError: isMETError } = apiMET.useGetMETsByIdsQuery(
		needMET ? metIds : skipToken,
	);

	const { data: marketSegments, isError: isMkSegError } =
		apiMarketSegment.useGetMarketSegmentByIdsQuery(
			needMarketSegments ? marketSegmentIds : skipToken,
		);

	const trialsWithDeps = useMemo(() => {
		if (!trials || !growingAreas) return [];

		const trialsWithDeps = trials.map((trial): TrialSerializedWithDeps => {
			const trialWithDeps: TrialSerializedWithDeps = { ...trial };
			if (trial.growingAreaId) {
				trialWithDeps.growingArea = growingAreas.find(
					(growingArea) => growingArea._id === trial.growingAreaId,
				);
			}
			if (trial.metId && needMET && mets) {
				trialWithDeps.met = mets.find((met) => met._id === trial.metId);
			}
			if (
				trial.marketSegmentIds &&
				needMarketSegments &&
				marketSegments
			) {
				trialWithDeps.marketSegments = marketSegments.filter((mkSeg) =>
					trial.marketSegmentIds?.includes(mkSeg._id),
				);
			}
			return trialWithDeps;
		});
		return trialsWithDeps;
	}, [
		needMarketSegments,
		needMET,
		growingAreas,
		marketSegments,
		mets,
		trials,
	]);

	return {
		trialsWithDeps,
		isError: {
			isGAError: isGAError,
			isMETError: isMETError,
			isMkSegError: isMkSegError,
		},
	};
};
