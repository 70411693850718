import React from "react";

import { ITemplateObsCoreSerialized } from "shared-type";

import TemplateObservationCard from "stories/base-components/Card/mostUsed/TemplateObservationCard";
import styles from "../../../../../../../sassTheme";
import { DIC, EDIC_KEY } from "../../../../../../dictionary";
import { defaultTemplateObsLinkedVar } from "../../../../../../mock";
import { variablesCustomHook } from "../../../../../../redux/variables/variables.api";
import DorianeButton from "../../../../../base-components/button/DorianeButton";
import { templateObsLinkedVarColumns } from "../../../../../base-components/data-grid/doriane-data-grid/default-grid-columns/TemplateObsLinkedVarColumns";
import DorianeDataGrid from "../../../../../base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import TemplateObsVarGrid from "../../../../../templates/observation/edition/variable-grid/TemplateObsVarGrid";
import { ITemplateObsLinkedVar } from "../../../../../templates/observation/TemplateObsEditionPage.utils";

import "./ECWTemplateObsSummary.scss";

interface ECWTemplateObsSummaryProps {
	templateObservation: ITemplateObsCoreSerialized;
	setTemplateObsId: (templateObsId: string | undefined) => void;
}

export default function ECWTemplateObsSummary(
	props: ECWTemplateObsSummaryProps,
) {
	const { data: ontologyVariables } =
		variablesCustomHook.useGetOntologyVariableListDeserialized();

	return (
		<div className="ECWTemplateObsSummary-body take-remaining-space flex-column">
			<div className="ECWTemplateObsSummary-obs-template-title">
				<h1>{DIC(EDIC_KEY.OBSERVATION_TEMPLATE)}</h1>

				<DorianeButton
					dorianeStyle="tertiary"
					onClick={() => props.setTemplateObsId(undefined)}
				>
					{DIC(EDIC_KEY.EDIT_SELECTION)}
				</DorianeButton>
			</div>
			<TemplateObservationCard
				borderColor={styles["secondary-color"]}
				templateObservation={props.templateObservation}
				disabledClick
			/>
			<h1>{DIC(EDIC_KEY.VARIABLES)}</h1>
			{props.templateObservation && (
				<TemplateObsVarGrid
					templateObservation={props.templateObservation}
					variableOntology={ontologyVariables}
					isTemplateObsUsed={true}
					hiddenColorStripe={true}
					displayMode={"tickMark"}
				/>
			)}
			<div className="ECWTemplateObsSummary-linked-container">
				<DorianeDataGrid<ITemplateObsLinkedVar>
					rows={defaultTemplateObsLinkedVar}
					columns={templateObsLinkedVarColumns(true)}
				/>
			</div>
		</div>
	);
}
