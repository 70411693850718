import {
	InteractionRequiredAuthError,
	InteractionStatus,
} from "@azure/msal-browser";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { IUser } from "shared-type";

import { Button } from "../../stories/base-components/Button/Button";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHook";
import { selectAuth } from "../redux/authentication/authentication.selector";
import { authAction } from "../redux/authentication/authentication.slice";
import { apiUser } from "../redux/user/user.api";
import { initAuth } from "./cognito-auth-init";
import { signInWithRedirect, getCurrentUser } from "@aws-amplify/auth";
import { fetchAuthSession } from "@aws-amplify/core";
import { Hub } from "aws-amplify/utils";
import * as Auth from "@aws-amplify/auth";

export type IAuthContext = {
	logout: () => Promise<void>;
	loginPopup: () => Promise<void>;
	isAuth: boolean;
	userLogin: IUser | undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AuthContext = createContext<IAuthContext>({} as any);

export default function AuthContextProvider(props: {
	children: React.ReactElement;
}) {
	initAuth();
	const dispatch = useAppDispatch();

	const nav = useNavigate();

	const [userId, setUserId] = useState<string | undefined>(undefined);

	const tenantId = useAppSelector(selectAuth.currentTenantId);

	const userProfileRequest = apiUser.useGetUserByIdQuery(userId ?? skipToken);

	const logout = useCallback(async () => {
		await Auth.signOut();
		nav("/login");
	}, [nav]);

	const loginPopup = useCallback(async () => {
		signInWithRedirect();
		return;
	}, []);

	const isAuth = userId !== undefined;

	useEffect(() => {
		//   user{"username":"dbc5c4c7-2100-4518-bac1-c4583da23983","userId":"af65e508-49b3-4262-b92a-8e55f96c9527"}
		// App.js:47 session {"tokens":{"accessToken":{"payload":{"sub":"af65e508-49b3-4262-b92a-8e55f96c9527","iss":"https://cognito-idp.eu-west-3.amazonaws.com/eu-west-3_Gm71j9hJU","version":2,"client_id":"2f8vlefm3tutmf1uoi78936n5i","origin_jti":"e28ab60a-1fee-4114-acc0-a4feaeb251c5","event_id":"c114af6c-4364-4483-a3b6-48ffeb771cd4","token_use":"access","scope":"aws.cognito.signin.user.admin openid profile email","auth_time":1701436949,"exp":1701440549,"iat":1701436950,"jti":"847aaebc-8f2d-44c6-a905-f31631a95bb8","username":"dbc5c4c7-2100-4518-bac1-c4583da23983"}},"idToken":{"payload":{"at_hash":"GdVU8KQUDjw6x3Hr98_IiQ","sub":"af65e508-49b3-4262-b92a-8e55f96c9527","email_verified":true,"iss":"https://cognito-idp.eu-west-3.amazonaws.com/eu-west-3_Gm71j9hJU","cognito:username":"dbc5c4c7-2100-4518-bac1-c4583da23983","given_name":"Florian","origin_jti":"e28ab60a-1fee-4114-acc0-a4feaeb251c5","custom:tenantId":"demo","aud":"2f8vlefm3tutmf1uoi78936n5i","event_id":"c114af6c-4364-4483-a3b6-48ffeb771cd4","token_use":"id","auth_time":1701436949,"exp":1701440549,"iat":1701436950,"family_name":"chazal","jti":"095394b5-a408-4c11-afb6-5cf56e5f8d42","email":"florianchazal+test-8@gmail.com"}}},"userSub":"af65e508-49b3-4262-b92a-8e55f96c9527"}

		Hub.listen("auth", (data) => {
			console.log("event", data.payload.event);
			switch (data.payload.event) {
				case "signInWithRedirect":
					console.log("signInWithRedirect", data);
					getCurrentUser().then((user) => {
						console.log("user signed in " + JSON.stringify(user));
						if (user.username) {
							setUserId(user.username);
						}
					});
					break;
				case "signInWithRedirect_failure":
					console.log("Error", data);
					break;
				default:
					console.log("default " + event);
					break;
			}
		});
	}, []);

	useEffect(() => {
		getCurrentUser()
			.then((user) => {
				if (!user) {
					console.log("no user");
					return;
				}
				console.log("user" + JSON.stringify(user));
				setUserId(user?.username);
			})
			.catch((error) => {
				console.log("Not authenticated" + JSON.stringify(error));
			});
	}, [userId]);

	useEffect(() => {
		if (userProfileRequest.data) {
			dispatch(
				authAction.loginUser({
					user: {
						firstName: userProfileRequest.data.firstName ?? "",
						lastName: userProfileRequest.data.lastName ?? "",
						id: userProfileRequest.data.id,
					},
					tenantId: userProfileRequest.data?.tenantId,
				}),
			);
		}
	}, [userProfileRequest.data, tenantId, dispatch]);
	const display = useMemo(() => {
		if (userProfileRequest.isError) {
			return (
				<div>
					Not authorized :{" "}
					<Button onClick={logout} text="Logout"></Button>
				</div>
			);
		}
		return props.children;
	}, [props.children, userProfileRequest.isError, logout]);
	return (
		<AuthContext.Provider
			value={{
				logout,
				loginPopup,
				isAuth,
				userLogin: userProfileRequest.data,
			}}
		>
			{display}
		</AuthContext.Provider>
	);
}

export function useBloomeoAuth() {
	const auth = useContext(AuthContext);
	return auth;
}

export abstract class AuthHelper {
	public static getAccessToken = async () => {
		console.log("getAccessToken");
		const session = await fetchAuthSession();
		return session?.tokens?.accessToken;
	};
}
