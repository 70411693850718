import {
	ENotebookStatus,
	EReviewStatus,
	IPutNotationReview,
} from "shared-type";

import { INotationTableRow } from "../../utils/datagrid.types";

export function editDataStatus(
	row: INotationTableRow,
	newValue: EReviewStatus,
): IPutNotationReview | undefined {
	if (!row.notation) {
		console.error(
			"Unknown error on modify review, notation cannot be undefined",
		);
		return undefined;
	}

	const result: IPutNotationReview = {
		_id: row.notation._id,
		reviewStatus: newValue,
	};
	return result;
}

export function canEditDataStatus(row: INotationTableRow) {
	return (
		row.notation?.value !== undefined &&
		row.notebook?.status === ENotebookStatus.COMPLETED
	);
}
