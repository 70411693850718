import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import TemplateObsVariablesPart from "App/components/templates/observation/edition/TemplateObsVariablesPart";
import { AppDispatch } from "App/store";
import { useTemplateObsEdition } from "../../../../../../../components/templates/observation/TemplateObsEditionPage.utils";

export default function TemplateObsEditionVariablesPage() {
	const dispatch = useDispatch<AppDispatch>();

	const {
		currentTemplateObs,
		isTemplateObsUsed,
		updateCurrentTemplateObs,
		setAddVariableOpen,
	} = useTemplateObsEdition();

	useEffect(() => {
		return () => {
			setAddVariableOpen(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // Don't put deps here

	return (
		<>
			{currentTemplateObs && (
				<TemplateObsVariablesPart
					isTemplateObsUsed={isTemplateObsUsed}
					templateObservation={currentTemplateObs}
					setTemplateObs={(updated) => {
						dispatch(updateCurrentTemplateObs(updated));
					}}
					setIsAddVariableOpen={setAddVariableOpen}
				/>
			)}
		</>
	);
}
