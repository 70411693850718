import { IECWState } from "../../../redux/ECW/ECW.slice";

export const isExpCompleted = (state: IECWState) => {
	if (state.wizardType === "SET") {
		return (
			state.generalPart.generalInfo.name !== undefined &&
			state.generalPart.generalInfo.species !== undefined &&
			state.generalPart.generalInfo.startDate !== undefined &&
			state.observationPart.templateObsId !== undefined && // We have an observation template
			state.germplasmPart.selectedMaterials.length // We have at least one material
		);
	} else if (state.wizardType === "MET") {
		return (
			state.generalPart.generalInfo.name !== undefined &&
			state.generalPart.generalInfo.species !== undefined &&
			state.generalPart.generalInfo.startDate !== undefined &&
			state.observationPart.templateObsId !== undefined && // We have an observation template
			state.germplasmPart.selectedMaterials.length // We have at least one material
		);
	}
};

export const computeStepStateFromExp = (state: IECWState) => {
	if (state.wizardType === "SET") {
		let currentStep = 0;
		const trialGeneralInfo = state.generalPart.generalInfo;

		if (
			trialGeneralInfo.name &&
			trialGeneralInfo.startDate &&
			trialGeneralInfo.species
		) {
			currentStep++;
			state.stepList[0].completed = true;
			state.stepList[1].disabled = false;
			state.stepList[2].disabled = false;
			state.stepList[3].disabled = false;

			// If we have an observation template, step 1 is done
			if (state.observationPart.templateObsId !== undefined) {
				state.stepList[1].completed = true;
				currentStep++;
				if (state.germplasmPart.selectedMaterials.length > 0) {
					currentStep++;
				}
			}
			if (state.germplasmPart.selectedMaterials.length > 0) {
				state.stepList[2].completed = true;
			}
			if (
				state.growingAreaPart &&
				state.growingAreaPart.growingAreaId !== undefined
			) {
				state.stepList[3].completed = true;
			}
		}
		state.activeStep = currentStep;
	} else if (state.wizardType === "MET") {
		let currentStep = 0;
		const trialGeneralInfo = state.generalPart.generalInfo;

		if (
			trialGeneralInfo.name &&
			trialGeneralInfo.startDate &&
			trialGeneralInfo.species
		) {
			currentStep++;
			state.stepList[0].completed = true;
			state.stepList[1].disabled = false;
			state.stepList[2].disabled = false;
			state.stepList[3].disabled = false;

			// If we have an observation template, step 1 is done
			if (state.observationPart.templateObsId !== undefined) {
				state.stepList[1].completed = true;
				currentStep++;
			}
			if (state.germplasmPart.selectedMaterials.length > 0) {
				state.stepList[2].completed = true;
				currentStep++;
			}
			if (state.trialGenerationPart.trials.length > 0) {
				state.stepList[3].completed = true;
			}
		}
		state.activeStep = currentStep;
	}
};
