import { Skeleton } from "@mui/material";
import React, { CSSProperties } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { DIC, EDIC_KEY } from "App/dictionary";
import { FieldObservation, ObsRound } from "../../../../../assets";
import { apiLevelCompletion } from "../../../../redux/experiment-statistics/level-completion.api";
import { getColorFromTaskStatus } from "../../../../utils";
import ImgBannerCard from "../../../base-components/card/img-banner-card/ImgBannerCard";
import ProgressBar from "../../../base-components/progress/progress-bar/ProgressBar";
import ColorTag from "../../../base-components/tag/color-tag/ColorTag";
import TitledTag from "../../../base-components/tag/titled-tag/TitledTag";
import { IObservationRoundCardProps } from "./ObservationRoundCard";

import styles from "../../../../../sassTheme";
import "./ObservationRoundBanner.scss";

export interface IObservationRoundBannerProps
	extends IObservationRoundCardProps {
	style?: CSSProperties;
	className?: string;
}

export default function ObservationRoundBanner(
	props: IObservationRoundBannerProps,
) {
	const obsRoundCompletionData =
		props.observationRound.completion ??
		apiLevelCompletion.useComputeObsRoundCompletionByIdQuery(
			props.isTemplate ? skipToken : props.observationRound._id,
		).data;

	return (
		<ImgBannerCard
			onClick={props.onClick}
			className={props.className}
			disabledClick={props.disabledClick}
			style={{ ...props.style }}
			sideBanner={{
				imageSrc: FieldObservation,
				logoSrc: ObsRound,
			}}
			isSelected={props.isSelected}
			backgroundColor={
				props.isTemplate ? styles["image-layer-template"] : undefined
			}
		>
			<div className="ObservationRoundBanner-container flex-column">
				<div className="ObservationRoundBanner-header">
					<h1 className="ObservationRoundBanner-title clamped-text">
						{props.observationRound.name}
					</h1>
					{!props.isTemplate && (
						<ColorTag
							title={DIC(EDIC_KEY.STATUS)}
							tagValue={props.observationRound.status}
							colorIndicator={getColorFromTaskStatus(
								props.observationRound.status,
							)}
						/>
					)}
				</div>
				{!props.isTemplate &&
					(obsRoundCompletionData ? (
						<ProgressBar
							title={DIC(EDIC_KEY.PROGRESSION)}
							currentProgress={obsRoundCompletionData.totalDone}
							goalProgress={obsRoundCompletionData.totalExpected}
						/>
					) : (
						<Skeleton height={40} />
					))}
				<div className="ObservationRoundBanner-tags">
					<TitledTag
						title={DIC(EDIC_KEY.FROM)}
						tagValue={`${new Date(
							props.observationRound.startDate,
						).toLocaleDateString()}`}
					/>
					<TitledTag
						title={DIC(EDIC_KEY.TO)}
						tagValue={`${new Date(
							props.observationRound.endDate,
						).toLocaleDateString()}`}
					/>
					<TitledTag
						title={DIC(EDIC_KEY.STAGE)}
						tagValue={props.observationRound.stage ?? "_"}
					/>
				</div>
				<h2 className="ObservationRoundBanner-description clamped-text">
					{props.observationRound.description}
				</h2>
			</div>
		</ImgBannerCard>
	);
}
