import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectECW } from "App/redux/ECW/ECW.selector";
import { ECWAction, ECWNextMode } from "App/redux/ECW/ECW.slice";
import { apiTemplateObs } from "App/redux/template-obs/template-obs.api";
import { AppDispatch } from "App/store";
import CreationWizardMenu from "../../../../base-components/menu/creation-wizard/CreationWizardMenu";
import CommonECWPart from "../../CommonECWPart";
import ECWTemplateObsList from "./list/ECWTemplateObsList";
import ECWTemplateObsSummary from "./summary/ECWTemplateObsSummary";

import "../../ECW.scss";

interface IECWTemplateObsProps {
	onNext: (action: ECWNextMode) => void;
}

export default function ECWTemplateObs(props: IECWTemplateObsProps) {
	const dispatch = useDispatch<AppDispatch>();

	const templateObservationId = useSelector(selectECW.templateObservationId);

	const speciesFilter = useSelector(selectECW.generalInfo).generalInfo
		.species;

	const { data: templateObsList } = apiTemplateObs.useGetTemplateObsQuery({
		filter: speciesFilter ? { species: speciesFilter } : undefined,
	});
	const { data: selectedTemplateObs } =
		apiTemplateObs.useGetTemplateObsByIdQuery(templateObservationId);

	const setTemplateObsId = (templateObsId: string | undefined) => {
		dispatch(ECWAction.updateExpTemplateObsId(templateObsId));
	};

	return (
		<CommonECWPart>
			<div className="ECW-body take-remaining-space">
				{selectedTemplateObs ? (
					<ECWTemplateObsSummary
						templateObservation={selectedTemplateObs}
						setTemplateObsId={setTemplateObsId}
					/>
				) : (
					templateObsList && (
						<ECWTemplateObsList
							setTemplateObsId={setTemplateObsId}
							templateObsList={templateObsList}
						/>
					)
				)}
			</div>
			<CreationWizardMenu
				onSave={() => props.onNext("save")}
				onDoItLaterClick={() => props.onNext("doItLater")}
				displaySave={selectedTemplateObs !== undefined}
			/>
		</CommonECWPart>
	);
}
