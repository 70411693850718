import { skipToken } from "@reduxjs/toolkit/dist/query";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { IMETSerialized } from "shared-type";

import { DIC, EDIC_KEY } from "../../../../../dictionary";
import { apiMET } from "../../../../../redux/experiment/met/met.api";
import { apiTrial } from "../../../../../redux/experiment/trial/trial.api";
import ChipCombobox from "../../../../base-components/combobox/chip-combobox/ChipCombobox";
import {
	useDashboardCountryFilter,
	useDashboardRegionsFilter,
	useDashboardSpeciesFilter,
	useDashboardUserFilter,
	useDashboardYearFilter,
} from "../../../hooks/hooks";
import DashboardHeader from "../../dashboard-header/DashboardHeader";
import { IDashboardTeamDevelopersFilter } from "../types";

interface DashboardTeamDevelopersHeaderProps {
	filterGlobal: IDashboardTeamDevelopersFilter | undefined;
	submitNewFilter: (filter: IDashboardTeamDevelopersFilter) => void;
}

export default function DashboardTeamDevelopersHeader(
	props: DashboardTeamDevelopersHeaderProps,
) {
	const { data: trials } = apiTrial.useGetTrialQuery({});
	const metIds = useMemo(
		() => trials?.data.map((trial) => trial.metId).filter(_.isString),
		[trials],
	);
	const needFetchMet = metIds && metIds.length > 0;
	const { data: metData } = apiMET.useGetMETsByIdsQuery(
		needFetchMet ? metIds : skipToken,
	);

	// SELECTED VALUES
	const { expYears, setExpYears, computeExpYearSet } =
		useDashboardYearFilter();
	const [expIds, setExpIds] = useState<string[] | undefined>(undefined);
	const { countries, setCountries, countriesSorted } =
		useDashboardCountryFilter();
	const { regions, setRegions, regionsSetSorted } =
		useDashboardRegionsFilter();
	const {
		usersIds: developersIds,
		setUsersIds: setDevelopersIds,
		translateUser,
		userIdsSorted,
	} = useDashboardUserFilter();
	const {
		speciesFilter: species,
		setSpeciesFilter: setSpecies,
		speciesSort,
	} = useDashboardSpeciesFilter();
	// Values list -------
	const metMap = useMemo(
		() =>
			new Map<string, IMETSerialized>(
				metData?.map((elt) => [elt._id, elt]),
			),
		[metData],
	);
	const trialMap = useMemo(
		() => new Map(trials?.data?.map((elt) => [elt._id, elt])),
		[trials],
	);
	const expIdSortByTitle = useMemo(
		() =>
			[...(trials?.data || [])]
				// eslint-disable-next-line id-length
				.sort((a, b) => {
					return a.name.localeCompare(b.name);
				})
				.map((elt) => elt._id),
		[trials],
	);

	const computeAndSubmitChartFilter = () => {
		props.submitNewFilter({
			expYears,
			countries,
			regions,
			species,
			users: developersIds,
			expName: expIds?.map((elt) => trialMap.get(elt)?.name ?? ""),
		});
	};
	// set filter on ready
	useEffect(() => {
		computeAndSubmitChartFilter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const trialIdToMETGroup = useCallback(
		(trialId: string) => {
			const metOfTrial = trialMap.get(trialId)?.metId;
			const metName = metMap.get(metOfTrial || "")?.name;
			return metName ?? DIC(EDIC_KEY.DASHBOARD_WITHOUT_MET);
		},
		[metMap, trialMap],
	);
	return (
		<DashboardHeader
			computeAndSubmitChartFilter={computeAndSubmitChartFilter}
		>
			<ChipCombobox<number>
				value={expYears ?? []}
				label={DIC(EDIC_KEY.YEAR)}
				onChange={setExpYears}
				comboboxValues={computeExpYearSet}
				translateMethod={(value) => value.toString()}
				className="DashboardHeader-combobox"
				selectAllBtn
			/>
			<ChipCombobox<string>
				value={developersIds ?? []}
				label={DIC(EDIC_KEY.USER)}
				onChange={setDevelopersIds}
				comboboxValues={userIdsSorted}
				translateMethod={translateUser}
				noWrap
				className="DashboardHeader-combobox"
				selectAllBtn
			/>
			<ChipCombobox<string>
				value={countries ?? []}
				label={DIC(EDIC_KEY.COUNTRY)}
				onChange={setCountries}
				comboboxValues={countriesSorted}
				translateMethod={(value) => value}
				noWrap
				className="DashboardHeader-combobox"
				selectAllBtn
			/>
			<ChipCombobox<string>
				value={regions ?? []}
				label={DIC(EDIC_KEY.REGION)}
				onChange={setRegions}
				comboboxValues={regionsSetSorted}
				translateMethod={(value) => value}
				noWrap
				className="DashboardHeader-combobox"
				selectAllBtn
			/>
			<ChipCombobox
				comboboxValues={expIdSortByTitle || []}
				label="Trial"
				onChange={setExpIds}
				translateMethod={(value) =>
					trialMap.get(value)?.name ?? "unknown"
				}
				value={expIds || []}
				groupBy={trialIdToMETGroup}
				className="DashboardHeader-combobox"
				noWrap
				selectAllBtn
			></ChipCombobox>
			<ChipCombobox<string>
				value={species ?? []}
				label={DIC(EDIC_KEY.SPECIES)}
				onChange={setSpecies}
				comboboxValues={speciesSort}
				translateMethod={(value) => value}
				className="DashboardHeader-combobox"
				selectAllBtn
			/>
		</DashboardHeader>
	);
}
