import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	EGermplasmLevel,
	ESpecies,
	IFilter,
	IGenotype,
	ILot,
	ISort,
} from "shared-type";
import {
	convertDorObjFilterToFilter,
	IDorianeFilter,
	IDorianeObjectFilter,
} from "../../../../../../interfaces/filters/doriane-filter-interface";
import { selectECW } from "../../../../../../redux/ECW/ECW.selector";
import {
	ECWAction,
	IMaterialInfo,
} from "../../../../../../redux/ECW/ECW.slice";
import { AppDispatch, RootState } from "../../../../../../store";
import DorianeDataGrid, {
	IColumn,
} from "../../../../../base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import PaginationTool from "../../../../../base-components/pagination/PaginationTool";

type IGenericAddMaterialProps<TMaterial extends IGenotype | ILot> = {
	selected: Set<string>;
	initialMaterials: Set<string>;
	materialSelector: (state: RootState) => TMaterial[] | undefined;
	pageInfoSelector: (state: RootState) => { totalPage: number } | undefined;
	fetchData: (action: {
		page: number;
		pageSize: number;
		filter: IFilter;
		sort: ISort;
	}) => any;
	getColumns: (
		filter: {
			filter: IDorianeObjectFilter;
			onFilterChange: (newFilter: IDorianeFilter) => void;
		},
		rowCallBack: {
			isCheck: (row: TMaterial) => boolean;
			onCheck: (row: TMaterial, check: boolean) => void;
			isDisabled: (row: TMaterial) => boolean;
		},
		handleCheckMultiple: any,
	) => IColumn<TMaterial>[];
	germplasmLevel: EGermplasmLevel;
	pageSize?: number;
	defaultSort?: ISort;
	additionalFilter?: IFilter;
};

export function GenericAddMaterial<TMaterial extends IGenotype | ILot>(
	props: IGenericAddMaterialProps<TMaterial>,
) {
	const dispatch = useDispatch<AppDispatch>();

	const species = useSelector(selectECW.generalFormInfo).species;
	const materials = useSelector(props.materialSelector);
	const pageInfo = useSelector(props.pageInfoSelector);

	const pageSize = props.pageSize || 30;
	const [page, setPage] = useState(0);
	const [sort, setSort] = useState<ISort>(props.defaultSort || {});
	const [filter, setFilter] = useState<IDorianeObjectFilter>(
		computeGenericFilterInit(props.germplasmLevel, species),
	);

	const computeFilter = useMemo(() => {
		const baseFilter = convertDorObjFilterToFilter(filter);
		if (props.additionalFilter !== undefined) {
			return { ...baseFilter, ...props.additionalFilter };
		}
		return baseFilter;
	}, [filter, props.additionalFilter]);

	const handleFilterChange = (newFilter: IDorianeFilter) => {
		setFilter((old) => {
			return { ...old, [newFilter.key]: newFilter };
		});
	};

	useEffect(() => {
		dispatch(
			props.fetchData({
				page: page,
				pageSize: pageSize,
				filter: computeFilter,
				sort: sort,
			}),
		);
	}, [pageSize, page, computeFilter, sort, dispatch, props.fetchData]);

	const handleCheck = useCallback(
		(check: boolean, material: TMaterial) => {
			if (check) {
				dispatch(
					ECWAction.addMaterial({
						material,
						type: props.germplasmLevel,
					} as IMaterialInfo),
				);
				return;
			}
			dispatch(
				ECWAction.removeMaterial({
					material,
					type: props.germplasmLevel,
				} as IMaterialInfo),
			);
		},
		[dispatch, props.germplasmLevel],
	);

	const handleCheckMultiple = useCallback(
		(check: boolean) => {
			if (check) {
				dispatch(
					ECWAction.addMaterials({
						materials,
						type: props.germplasmLevel,
						initMaterials: props.initialMaterials,
					}),
				);
				return;
			}
			dispatch(
				ECWAction.removeMaterials({
					materials,
					type: props.germplasmLevel,
					initMaterials: props.initialMaterials,
				}),
			);
		},
		[dispatch, materials, props.germplasmLevel, props.initialMaterials],
	);

	const columns = useMemo(
		() =>
			props.getColumns(
				{ filter: filter, onFilterChange: handleFilterChange },
				{
					isCheck: (row) => props.selected.has(row._id),
					onCheck: (row, check) => handleCheck(check, row),
					isDisabled: (row) => props.initialMaterials.has(row._id),
				},
				(check: boolean) => handleCheckMultiple(check),
			),
		[
			filter,
			handleCheck,
			handleCheckMultiple,
			props.getColumns,
			props.selected,
			props.initialMaterials,
		],
	);
	return (
		<>
			<DorianeDataGrid<TMaterial>
				className="full-parent-size"
				columns={columns}
				rows={materials || []}
			/>
			{(pageInfo?.totalPage || 1) > 1 && (
				// TODO - Fix this generic which uses the ExperimentAddMaterial className ?
				<div className="center ExperimentAddMaterial-pagination">
					<PaginationTool
						currentPage={page}
						maxPage={pageInfo?.totalPage || 0}
						onPageRoll={(page) => setPage(page)}
					/>
				</div>
			)}
		</>
	);
}
function computeGenericFilterInit(
	germplasmLevel: EGermplasmLevel,
	species?: ESpecies,
): IDorianeObjectFilter {
	let filter: IDorianeObjectFilter = {};

	if (species) {
		if (germplasmLevel === EGermplasmLevel.GENOTYPE) {
			filter = {
				species: {
					key: "species",
					type: "string",
					value: species,
				},
			};
		}
		if (germplasmLevel === EGermplasmLevel.LOT) {
			filter = {
				"genotype.species": {
					key: "genotype.species",
					type: "string",
					value: species,
				},
			};
		}
	}
	return filter;
}
