"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotationPatchUniqueBusinessKey = exports.NotationUniqueBusinessKey = void 0;
exports.NotationUniqueBusinessKey = [
    "notebookId",
    "subjectType",
    "subjectId",
    "variableId",
    "variableScope",
];
exports.NotationPatchUniqueBusinessKey = [
    "id",
];
