import React, { ReactNode } from "react";

import { concatClassName } from "common";

import { Typography } from "stories/base-components/Typography/Typography";
import { Badge } from "stories/base-components/Badge/Badge";
import Colors from "stories/constants/Colors/Colors";
import { StoryTheme } from "stories/StoryTheme";
import Box from "App/components/base-components/placement/box/Box";

import "./card.scss";

export type ICard = {
	selected?: boolean;
	label?: string;
	title?: string;
	subTitle?: string;
	subObject?: ReactNode;
	headerIcon?: ReactNode;
	cardHeaderRight?: ReactNode;
	activeTextColor?: string;
	borderColor?: string;
	maxWidth?: string;
	dataList?: Array<{ label: string; value: string }>;
	dataListBackgroundColor?: string;
	dataListSpaceBetween?: boolean;
	bottomArrow?: boolean;
	borderTop?: boolean;
	mainPaddingLeft?: string;
	mainCustom?: ReactNode;
	onClick?: () => void;
	noBorder?: boolean;
	backgroundColor?: string;
};

export const Card = (card: ICard) => {
	return (
		<StoryTheme>
			<Box
				className={concatClassName(
					"cardContainer",
					card.selected ? "cardSelected" : "",
					card.borderTop ? "cardBorderTop" : "",
				)}
				maxWidth={card.maxWidth}
				borderColor={card.borderColor}
				border={card.noBorder ? "none" : undefined}
				borderRadius={card.noBorder ? "unset" : undefined}
				style={{
					backgroundColor: card.backgroundColor,
					cursor: card.onClick ? "pointer" : undefined,
				}}
				onClick={card.onClick}
			>
				<div className="cardHeaderContainer">
					<div
						className={
							"cardHeaderLeft" +
							(!card.subObject && !card.subTitle
								? " cardHeaderLeft-center"
								: "")
						}
					>
						{card.headerIcon && (
							<div className="cardHeaderIconContainer">
								{card.headerIcon}
							</div>
						)}
						<div className="cardHeaderTitleContainer">
							{card.label && (
								<Typography
									text={card.label}
									variant="heading2"
									noWrap
								/>
							)}
							{card.title && (
								<Typography
									text={card.title}
									variant="heading1"
									noWrap
								/>
							)}

							{card.subObject && card.subObject}
							{card.subTitle && (
								<Typography
									text={card.subTitle}
									variant="smallMedium"
									color={card.activeTextColor}
								/>
							)}
						</div>
					</div>
					{card.cardHeaderRight && (
						<div className="cardHeaderRight">
							{card.cardHeaderRight}
						</div>
					)}
				</div>
				<div
					className="cardBody"
					style={{
						paddingLeft: card.mainPaddingLeft
							? card.mainPaddingLeft
							: undefined,
					}}
				>
					{card.mainCustom && card.mainCustom}
					{card.dataList && (
						<div
							className={
								"cardListContainer" +
								(card.dataListSpaceBetween
									? " cardListContainer-spaceBetween"
									: " cardListContainer-flexStart")
							}
						>
							{card.dataList.map((dataElement, index) => (
								<div
									key={"dataElement_" + index}
									className="cardDataListElement"
								>
									<div className="cardDataListElementTitle">
										<Typography
											text={dataElement.label}
											variant="smallRegular"
											color={Colors.neutral90}
										/>
									</div>
									<Badge
										text={dataElement.value}
										color={card.activeTextColor}
										backgroundColor={
											card.dataListBackgroundColor
												? card.dataListBackgroundColor
												: Colors.neutral10
										}
									/>
								</div>
							))}
						</div>
					)}
				</div>

				{card.bottomArrow && (
					<div className="cardArrowContainer">
						<div
							className="cardArrow"
							style={{
								borderTopColor: card.borderColor
									? card.borderColor
									: undefined,
							}}
						></div>
						<div className="cardArrowCover"></div>
					</div>
				)}
			</Box>
		</StoryTheme>
	);
};
