import { CircularProgress } from "@mui/material";
import React, { useMemo } from "react";

import ChipCombobox from "../../../../components/base-components/combobox/chip-combobox/ChipCombobox";
import {
	useDashboardMETFilter,
	useDashboardUserFilter,
} from "../../../../components/dashboard/hooks/hooks";
import ExperimentGantt from "../../../../components/experiment/common/experiment-gantt/ExperimentGantt";
import { createDataFromIInterExpGantt } from "../../../../components/experiment/common/experiment-gantt/ExperimentGantt.interface";
import { InterExpGanttColumns } from "../../../../components/experiment/common/experiment-gantt/ExperimentGanttColumns";
import { DIC, EDIC_KEY } from "../../../../dictionary";
import { apiExpGantt } from "../../../../redux/experiment-statistics/exp-gantt.api";

import "./DashboardPlanningPage.scss";

export default function DashboardPlanningPage() {
	const { data: interGanttExpInfos, isError } =
		apiExpGantt.useComputeInterExpGanttInfoQuery();

	const { usersIds, setUsersIds, translateUser, userIdsSorted } =
		useDashboardUserFilter();
	const { metIds, setMETIds, getMET, metIdsSorted } = useDashboardMETFilter();

	const ganttData = useMemo(() => {
		if (interGanttExpInfos) {
			return createDataFromIInterExpGantt(interGanttExpInfos);
		}
		return undefined;
	}, [interGanttExpInfos]);

	const data = ganttData?.data;
	const expandedTasks = ganttData?.expandedTasks;

	const dataFiltered = useMemo(() => {
		if (!data) return undefined;
		let res = data;
		if (metIds?.length) {
			res = res.filter((interExpData) => {
				if (interExpData.met) {
					return metIds.some(
						(metId) => getMET(metId)?.name === interExpData.met,
					);
				}
				// No MET in this data so don't return it
				return false;
			});
		}
		if (usersIds?.length) {
			res = res.filter((interExpData) => {
				return interExpData.team.some((user) =>
					usersIds?.includes(user.id),
				);
			});
		}
		return res;
	}, [data, getMET, metIds, usersIds]);

	const ganttDisplay = useMemo(() => {
		if (dataFiltered !== undefined) {
			return (
				<div className="full-parent-size">
					<ExperimentGantt
						columns={InterExpGanttColumns}
						data={dataFiltered}
						defaultExpandedTasks={expandedTasks}
					/>
				</div>
			);
		}
		if (isError) {
			return (
				<div className="DashboardPlanningPage-centered full-parent-size">
					{DIC(EDIC_KEY.CANNOT_COMPUTE_GANTT)}
				</div>
			);
		}
		return (
			<div className="DashboardPlanningPage-centered full-parent-size">
				<CircularProgress />
			</div>
		);
	}, [dataFiltered, expandedTasks, isError]);

	return (
		<div className="DashboardPlanningPage-container flex-column full-parent-size">
			<div className="DashboardPlanningPage-header">
				<ChipCombobox<string>
					value={usersIds ?? []}
					label={DIC(EDIC_KEY.USER)}
					onChange={setUsersIds}
					comboboxValues={userIdsSorted}
					translateMethod={translateUser}
					noWrap
					className="DashboardPlanningPage-combobox"
					selectAllBtn
				/>
				<ChipCombobox<string>
					value={metIds ?? []}
					label={DIC(EDIC_KEY.MET)}
					onChange={setMETIds}
					comboboxValues={metIdsSorted}
					translateMethod={(metId) => getMET(metId)?.name ?? ""}
					noWrap
					className="DashboardPlanningPage-combobox"
					selectAllBtn
				/>
			</div>
			<div className="take-remaining-space">{ganttDisplay}</div>
		</div>
	);
}
