import { concatClassName } from "common";
import * as React from "react";
import { useMemo } from "react";

import CustomCard from "../../card/CustomCard";

import "./TitledTag.scss";

export interface ITitledTagProps {
	tagValue?: string | string[];
	clampTagValue?: boolean;
	title?: string;
	children?: React.ReactNode;
	style?: React.CSSProperties;
	className?: string;
}

export default function TitledTag(props: ITitledTagProps) {
	// if tagValue is an array, the first element is rendered, the rest is counted
	const tagValueRender = useMemo(() => {
		if (Array.isArray(props.tagValue)) {
			if (props.tagValue.length === 0) {
				return "_";
			}
			if (props.tagValue.length === 1) {
				return props.tagValue[0];
			}
			return `${props.tagValue[0]} (${props.tagValue.length - 1}...)`;
		}
		return props.tagValue;
	}, [props.tagValue]);

	return (
		<div className="TitledTag-container">
			{props.title && <h4 className="TitledTag-title">{props.title}</h4>}
			<CustomCard
				className={concatClassName("Tag ", props.className)}
				disabledClick
				style={props.style}
			>
				<div className="TitledTag-children">
					{props.children && props.children}
					{props.tagValue && (
						<h3
							className={
								props.clampTagValue ? "clamped-text" : undefined
							}
						>
							{tagValueRender}
						</h3>
					)}
				</div>
			</CustomCard>
		</div>
	);
}
