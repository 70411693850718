import * as d3 from "d3-scale";

function linearGradient(value: number, min: number, max: number) {
	if (value < min) {
		return 0;
	}
	if (value > max) {
		return 1;
	}
	if (min === value && max === value) {
		return 0.5;
	}
	return (value - min) / (max - min);
}
const colors = ["#e8a8a6", "#fbde8b", "#b4d66d"];
const ranges = [0, 0.5, 1];
const scaleColor = d3.scaleLinear(ranges, colors);

export function notationColorScale(value: number, min: number, max: number) {
	return scaleColor(linearGradient(value, min, max));
}
