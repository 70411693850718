import { FormHelperText } from "@mui/material";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import React from "react";

import { DIC, EDIC_KEY } from "../../../dictionary";

import "./DorianeDatePicker.scss";

export type IDorianeDatePicker = DatePickerProps<Dayjs> & {
	helperText?: string;
	helperTextError?: boolean;
};
export default function DorianeDatePicker(props: IDorianeDatePicker) {
	return (
		<div>
			<DatePicker
				className={
					"DorianeDatePicker" +
					(props.disabled ? " DorianeDatePicker-disabled" : "")
				}
				slotProps={{ textField: { fullWidth: true } }}
				{...props}
			/>
			{props.helperText && (
				<FormHelperText error={props.helperTextError}>
					{props.helperText}
				</FormHelperText>
			)}
		</div>
	);
}

export function datePickerErrorToMessage(error?: string | null) {
	switch (error) {
		case "minDate":
			return DIC(EDIC_KEY.DATE_GREATER_THAN_START_DATE_ERROR);
		case "invalidDate":
			return undefined;
		case null:
		case undefined:
			return undefined;
		default:
			return error;
	}
}
