import { skipToken } from "@reduxjs/toolkit/dist/query";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { IGrowingArea } from "shared-type";

import { apiMarketSegment } from "App/redux/market-segment/market-segment.api";
import { apiUser } from "App/redux/user/user.api";
import { Earth } from "../../../../../../../assets";
import DorianeButton from "../../../../../../components/base-components/button/DorianeButton";
import { growingAreaColumns } from "../../../../../../components/base-components/data-grid/doriane-data-grid/default-grid-columns/GrowingAreaColumns";
import DorianeDataGrid from "../../../../../../components/base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import Box, {
	EPaddingSize,
} from "../../../../../../components/base-components/placement/box/Box";
import FlexBox from "../../../../../../components/base-components/placement/flex-box/FlexBox";
import { IGrowingAreaRow } from "../../../../../../components/experiment/met/edition/trials-edition/modal/interface";
import TrialLocationMap from "../../../../../../components/map/TrialLocationMap";
import { useAppDispatch } from "../../../../../../hooks/reduxHook";
import {
	convertDorObjFilterToFilter,
	IDorianeFilter,
	IDorianeObjectFilter,
} from "../../../../../../interfaces/filters/doriane-filter-interface";
import { ECWAction } from "../../../../../../redux/ECW/ECW.slice";
import { apiTrial } from "../../../../../../redux/experiment/trial/trial.api";
import { growingAreaCustomHook } from "../../../../../../redux/growing-area/growing-area.api";

import "./METGrowingAreaPage.scss";

export default function METGrowingAreaPage() {
	const { metId } = useParams();
	const [displayMode, setDisplayMode] = useState<"list" | "map">("map");
	const dispatch = useAppDispatch();

	const [filter, setFilter] = useState<IDorianeObjectFilter>({});

	const filterForQuery = useMemo(
		() => convertDorObjFilterToFilter(filter),
		[filter],
	);

	const { data: METTrials } = apiTrial.useGetTrialsByMETIdQuery(
		metId ?? skipToken,
	);
	const GAIdsOfTrials = useMemo(() => {
		const ids = METTrials?.map((trial) => trial.growingAreaId) ?? [];
		return ids.filter(_.isString);
	}, [METTrials]);

	const { data: growingAreaOfMET } =
		growingAreaCustomHook.useGetGrowingAreasQueryDeserialized({
			sort: { name: 1 },
			filter: {
				...filterForQuery,
				_id: { $in: GAIdsOfTrials },
			},
		});

	const { data: fetchedMarketSegmentList } =
		apiMarketSegment.useGetMarketSegmentsQuery({});
	const { data: usersFetched } = apiUser.useGetUsersQuery();

	const growingAreasList = useMemo((): IGrowingAreaRow[] => {
		let allGAs: IGrowingAreaRow[] = [];
		if (growingAreaOfMET !== undefined) {
			allGAs = [...growingAreaOfMET];
		}
		allGAs.forEach((currentGA) => {
			// market segment of current growing area
			const currentGaOfMk = fetchedMarketSegmentList?.filter((mk) =>
				mk.growingAreas.includes(currentGA._id),
			);
			currentGA.marketSegments = currentGaOfMk;
			const currentUsers = usersFetched?.filter((user) =>
				currentGA.users.includes(user.id),
			);
			currentGA.usersArray = currentUsers;
		});
		return allGAs;
	}, [growingAreaOfMET, fetchedMarketSegmentList, usersFetched]);

	const handleFilterChange = (newFilter: IDorianeFilter) => {
		setFilter((old) => {
			return { ...old, [newFilter.key]: newFilter };
		});
	};

	const swapDisplayMode = useCallback(() => {
		setDisplayMode((old) => (old === "list" ? "map" : "list"));
	}, [setDisplayMode]);

	useEffect(() => {
		return () => {
			dispatch(ECWAction.setIsEditGAOpen({ open: false }));
		};
	}, [dispatch]);

	const openGAModal = useCallback(
		(id: string) => {
			dispatch(
				ECWAction.setIsEditGAOpen({
					open: true,
					growingAreaId: id,
				}),
			);
		},
		[dispatch],
	);
	if (displayMode === "map") {
		return (
			<TrialLocationMap
				growingAreas={growingAreasList ?? []}
				onGAClick={(growingArea) => openGAModal(growingArea._id)}
				onSwapVueClick={swapDisplayMode}
			/>
		);
	}
	return (
		<FlexBox flexDirection={"column"} takeRemainingSpace>
			<FlexBox
				justifyContent={"flex-end"}
				alignItems={"center"}
				paddingX={EPaddingSize.MEDIUM}
			>
				<DorianeButton
					dorianeStyle="quaternary"
					onClick={swapDisplayMode}
				>
					<img src={Earth} />
				</DorianeButton>
			</FlexBox>
			<Box takeRemainingSpace>
				<Box
					paddingX={EPaddingSize.MEDIUM}
					paddingBottom={EPaddingSize.MEDIUM}
					fullParentSize
				>
					<DorianeDataGrid<IGrowingArea>
						className="full-parent-size"
						rowHeight="40px"
						columns={growingAreaColumns({
							filter: filter,
							onFilterChange: handleFilterChange,
						})}
						rows={growingAreasList || []}
						onRowClick={(event, row) => openGAModal(row._id)}
					/>
				</Box>
			</Box>
		</FlexBox>
	);
}
