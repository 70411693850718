import { GridCustomCellProps } from "@progress/kendo-react-grid";
import React from "react";

import { concatClassName } from "common";

import Box from "../../../placement/box/Box";

export const BaseCell = (props: GridCustomCellProps) => {
	return (
		<td
			className={concatClassName(props.className, "BaseCell")}
			colSpan={1}
			style={{
				...props.style,
			}}
		>
			<Box position="absolute" top={0} bottom={0} left={0} right={0}>
				{props.children}
			</Box>
		</td>
	);
};
