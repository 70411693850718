import React from "react";

import { IVariableGroupSerialized } from "shared-type";

import VarGroupList from "../../experiment/variable-group/VarGroupList";

export interface ITemplateObsVarGroupPartProps {
	remoteVariableGroups?: IVariableGroupSerialized[];
	setCurrentVarGroupId: (id?: string | undefined) => void;
	onVarGroupCreateClick: () => void;
	onVarGroupEditClick: () => void;
}
export default function TemplateObsVarGroupPart(
	props: ITemplateObsVarGroupPartProps,
) {
	const handleClick = (id: string) => {
		props.setCurrentVarGroupId(id);
		props.onVarGroupEditClick();
	};

	return (
		<>
			{props.remoteVariableGroups && (
				<VarGroupList
					handleVarGroupClick={handleClick}
					remoteVariableGroups={props.remoteVariableGroups}
					onVarGroupCreateClick={props.onVarGroupCreateClick}
					mode="configuration"
				/>
			)}
		</>
	);
}
