import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { PATH_EXPERIMENTS } from "../../../routes";
import { AppDispatch } from "App/store";
import { projectAction } from "./../../../redux/project/project.slice";
import { Card } from "stories/base-components/Card/Card";
import { Typography } from "stories/base-components/Typography/Typography";
import { Button } from "stories/base-components/Button/Button";
import Colors from "stories/constants/Colors/Colors";

import "./ProjectCard.scss";

interface ProjectCardProps {
	disabledClick?: boolean;
}
export default function ProjectCard(props: ProjectCardProps) {
	const nav = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const handleClick = () => {
		nav(`${PATH_EXPERIMENTS}`);
		dispatch(projectAction.updateCurrentProject("Fluidity"));
	};

	return (
		<Card
			label="Fluidity"
			borderTop
			maxWidth="355px"
			borderColor={Colors.primary60}
			backgroundColor={Colors.neutral10}
			mainCustom={
				<div>
					<Typography
						text="Fluidity PD project with demo data & examples for a Product Development team managing wheat, corn, oil seed rape and tomato crops."
						variant="body1"
						color={Colors.neutral90}
					/>
					<div className="projectCardButtonContainer">
						<Button
							text="Select project"
							buttonType="secondary"
							disabled={props.disabledClick}
							onClick={handleClick}
						/>
					</div>
				</div>
			}
		/>
	);
}
