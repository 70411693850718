import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { computeTotalPagesNb } from "common";

import { trialColumnsV2 } from "App/components/base-components/data-grid/doriane-data-grid/default-grid-columns/TrialColumns";
import DorianeDataGrid from "App/components/base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import PaginationTool from "App/components/base-components/pagination/PaginationTool";
import FlexBox from "App/components/base-components/placement/flex-box/FlexBox";
import { convertTrialsWithDepsToMETTrialRows } from "App/components/experiment/met/edition/trials/METTrialHelper";
import { DIC, EDIC_KEY } from "App/dictionary";
import { PATH_TRIALS_CREATION } from "App/routes";
import { TrialDeleteModal } from "App/Views/main/experiment/trial/modal/TrialDeleteModal";
import { TrialDuplicateModal } from "App/Views/main/experiment/trial/modal/TrialDuplicateModal";
import { InfoMessage } from "stories/base-components/InfoMessage/InfoMessage";
import { HeaderTitleSearchButton } from "stories/base-components/Layout/HeaderTitleSearchButton/HeaderTitleSearchButton";
import { DataGridSkeleton } from "stories/base-components/Skeleton/mostUsed/DataGridSkeleton";
import { useTrialWithDeps } from "../../../../../components/experiment/trial/list/TrialWithDeps.hook";
import {
	TRIAL_DEFAULT_PAGE_SIZE,
	TRIAL_DEFAULT_SORT,
} from "../../../../../const";
import {
	convertDorObjFilterToFilter,
	IDorianeObjectFilter,
} from "../../../../../interfaces/filters/doriane-filter-interface";
import { apiTrial } from "../../../../../redux/experiment/trial/trial.api";

import "./TrialListPage.scss";

export default function TrialListPage() {
	const nav = useNavigate();

	const [page, setPage] = useState<number>(0);
	const [filter, setFilter] = useState<IDorianeObjectFilter>({});

	const filterForQuery = useMemo(
		() => convertDorObjFilterToFilter(filter, true),
		[filter],
	);

	const { data: trials, isLoading } = apiTrial.useGetTrialQuery({
		sort: TRIAL_DEFAULT_SORT,
		page: page,
		pageSize: TRIAL_DEFAULT_PAGE_SIZE,
		filter: filterForQuery,
	});
	const {
		trialsWithDeps,
		isError: { isGAError },
	} = useTrialWithDeps(trials?.data);

	const handleNewTrialClick = () => {
		nav(PATH_TRIALS_CREATION);
	};

	const createTrialFilter = useCallback((newFilterValue: string) => {
		const filter: IDorianeObjectFilter = {
			name: { key: "name", type: "string", value: newFilterValue },
			species: {
				key: "species",
				type: "string",
				value: newFilterValue,
			},
			year: { key: "year", type: "int", value: newFilterValue },
		};
		setFilter(filter);
		setPage(0); // Reset page number
	}, []);

	const convertedTrialsToRows = useMemo(() => {
		return convertTrialsWithDepsToMETTrialRows(trialsWithDeps);
	}, [trialsWithDeps]);

	const trialColumns = useMemo(() => trialColumnsV2(), []);

	return (
		<div className="TrialListPage-body flex-column take-remaining-space">
			<HeaderTitleSearchButton
				title={
					DIC(EDIC_KEY.TRIAL) +
					(trials?.metadata.pageInfo.total
						? " (" + trials?.metadata.pageInfo.total + ")"
						: "")
				}
				list={trials?.data || []}
				searchPlaceholder={DIC(EDIC_KEY.SEARCH_TRIAL)}
				setSearch={(search: string) => {
					createTrialFilter(search);
				}}
				searchValue={filter.name?.value || ""}
				btnText={DIC(EDIC_KEY.NEW_TRIAL) + " +"}
				btnOnClick={handleNewTrialClick}
			/>

			{
				// eslint-disable-next-line no-nested-ternary
				isLoading ? (
					<DataGridSkeleton />
				) : (
					<>
						{isGAError && (
							<InfoMessage
								infoType="error"
								message={DIC(EDIC_KEY.ERROR_FETCHING_GA_TEXT)}
							/>
						)}
						<DorianeDataGrid
							columns={trialColumns}
							rows={convertedTrialsToRows}
							className={"take-remaining-space"}
							onRowClick={(event: any, element: any) => {
								element.trial._id && nav(element.trial._id);
							}}
							secondaryStyle
						/>
						<FlexBox justifyContent={"center"}>
							<PaginationTool
								currentPage={page}
								maxPage={computeTotalPagesNb(
									trials?.metadata.pageInfo,
								)}
								onPageRoll={(pageIndex) => setPage(pageIndex)}
							/>
						</FlexBox>
						<TrialDeleteModal />
						<TrialDuplicateModal />
					</>
				)
			}
		</div>
	);
}
