import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { AbstractRequest } from "../abstract-request";

export class ObservationRoundRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/op-task/observation-round";
	}

	canDeleteObsRoundById(
		obsRoundId: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<boolean, any>> {
		return this.axios.get<boolean>(
			this.baseUrl + "/can-delete/" + obsRoundId,
			config
		);
	}

	generateNotebooksByObsRoundId(
		id: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<string[], any>> {
		return this.axios.patch<string[]>(
			this.baseUrl + "/generate-notebooks/" + id,
			config
		);
	}
}
