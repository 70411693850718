import { Box as MUIBox, BoxProps as MUIBoxPros } from "@mui/material";
import React, { useMemo } from "react";
import styles from "../../../../../sassTheme";

export enum EPaddingSize {
	SMALL,
	MEDIUM,
	LARGE,
}
export type BoxProps = MUIBoxPros & {
	fullParentSize?: boolean;
	takeRemainingSpace?: boolean;
	padding?: MUIBoxPros["padding"] | EPaddingSize;
	paddingBottom?: MUIBoxPros["paddingBottom"] | EPaddingSize;
	paddingTop?: MUIBoxPros["paddingTop"] | EPaddingSize;
	paddingLeft?: MUIBoxPros["paddingLeft"] | EPaddingSize;
	paddingRight?: MUIBoxPros["paddingRight"] | EPaddingSize;
	paddingX?: MUIBoxPros["paddingX"] | EPaddingSize;
	paddingY?: MUIBoxPros["paddingY"] | EPaddingSize;
};
function paddingEnumToValue(gap: MUIBoxPros["padding"] | EPaddingSize) {
	switch (gap) {
		case EPaddingSize.SMALL:
			return styles["padding-small"];
		case EPaddingSize.MEDIUM:
			return styles.padding;
		case EPaddingSize.LARGE:
			return styles["padding-large"];
		default:
			return gap;
	}
}

const Box: React.FC<BoxProps> = ({
	children,
	fullParentSize,
	takeRemainingSpace,
	className,
	padding,
	paddingBottom,
	paddingTop,
	paddingRight,
	paddingLeft,
	paddingX,
	paddingY,
	...rest
}) => {
	const classNameCompute: string = useMemo(() => {
		const result: string[] = [];
		if (fullParentSize) {
			result.push("full-parent-size");
		}
		if (takeRemainingSpace) {
			result.push("take-remaining-space");
		}
		if (className) {
			result.push(className);
		}
		return result.join(" ");
	}, [className, fullParentSize, takeRemainingSpace]);

	return (
		<MUIBox
			className={classNameCompute}
			padding={paddingEnumToValue(padding)}
			paddingBottom={paddingEnumToValue(paddingBottom)}
			paddingTop={paddingEnumToValue(paddingTop)}
			paddingRight={paddingEnumToValue(paddingRight)}
			paddingLeft={paddingEnumToValue(paddingLeft)}
			paddingX={paddingEnumToValue(paddingX)}
			paddingY={paddingEnumToValue(paddingY)}
			{...rest}
		>
			{children}
		</MUIBox>
	);
};

export default Box;
