import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { formatString } from "common";
import {
	IInterExpTaskGanttSerialized,
	IMETGanttSerialized,
	ITrialGanttSerialized,
} from "shared-type";

import { ReduxStore } from "..";
import { DIC, EDIC_KEY } from "../../dictionary";
import { useRequest } from "../../request-provider";
import { defaultApiError } from "../utils/errors";

export const apiExpGantt = createApi({
	reducerPath: "api-exp-gantt",
	baseQuery: () => ({ data: undefined }),
	// global configuration for the api (in second)
	keepUnusedDataFor: 1800, // 30 min
	// global configuration for the api
	refetchOnMountOrArgChange: 1800, // 30 min
	tagTypes: ["Get"],
	endpoints: (build) => ({
		computeTrialsGanttInfoByIds: build.query<
			ITrialGanttSerialized[] | undefined,
			string[]
		>({
			queryFn: async (trialIds) => {
				try {
					if (trialIds.length === 0) {
						return { data: [] };
					}

					const result =
						await useRequest().expGantt.computeTrialsGanttInfoByIds(
							trialIds,
						);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.FETCH).toLowerCase(),
							DIC(EDIC_KEY.DATA).toLowerCase(), // FIXME CHANGE
						),
					);
				}
			},
			providesTags: ["Get"],
		}),
		computeMETsGanttInfoByIds: build.query<
			IMETGanttSerialized | undefined,
			string | undefined
		>({
			queryFn: async (METId) => {
				try {
					if (METId === undefined) {
						return { data: undefined };
					}

					const result =
						await useRequest().expGantt.computeMETGanttInfoById(
							METId,
						);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.FETCH).toLowerCase(),
							DIC(EDIC_KEY.DATA).toLowerCase(), // FIXME CHANGE
						),
					);
				}
			},
			providesTags: ["Get"],
		}),
		computeInterExpGanttInfo: build.query<
			IInterExpTaskGanttSerialized[] | undefined,
			void
		>({
			queryFn: async () => {
				try {
					const result =
						await useRequest().expGantt.computeInterExpGanttInfo();
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.FETCH).toLowerCase(),
							DIC(EDIC_KEY.DATA).toLowerCase(), // FIXME CHANGE
						),
					);
				}
			},
			providesTags: ["Get"],
		}),
	}),
});

export abstract class ExpGanttApiHelper {
	static invalidateTags(
		...args: Parameters<typeof apiExpGantt.util.invalidateTags>
	) {
		ReduxStore.store.dispatch(apiExpGantt.util.invalidateTags(...args));
	}
}
