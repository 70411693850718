import { DIC, EDIC_KEY } from "../../../../../dictionary";
import { IUnitIdentifiers } from "App/components/form/task/observation-round/ObservationRoundForm";
import { IColumn } from "../doriane-data-grid/DorianeDataGrid";

export const unitIdentifiersColumns: IColumn<IUnitIdentifiers>[] = [
	{
		id: "unitName",
		getHeaderRenderer: DIC(EDIC_KEY.OBSERVATION_UNITS),
		getCellRenderer: (row) => row.unitName,
	},
	{
		id: "identifiers",
		getHeaderRenderer: DIC(EDIC_KEY.IDENTIFIERS),
		getCellRenderer: (row) => row.identifiers.join(", "),
	},
];
