/* eslint-disable no-use-before-define */
import {
	EExperimentStatus,
	ETaskStatus,
	ETaskType,
	IInterExpTaskGanttSerialized,
	IMETGanttSerialized,
	IOpTaskGanttSerialized,
	ITrialGanttSerialized,
	IUser,
} from "shared-type";

import { GanttColumnID } from "../../../base-components/gantt/Gantt";

export const enum EExpGanttTaskOrigin {
	MET = "MET",
	TRIAL = "trial",
	OBS_ROUND = "obs round",
	OPERATION = "operation",
}
export const enum EExpGanttTaskStatus {
	READY = "ready",
	IN_PROGRESS = "in progress",
	COMPLETED = "completed",
}

export interface IBaseGanttData {
	id: number;
	origin: string;
	originId: string;
	title: string;
	start: Date;
	end: Date;
	status: EExpGanttTaskStatus;
	percentComplete: number;
	subtasks?: IBaseGanttData[];
}
export interface ITaskGanttData extends IBaseGanttData {
	team: Pick<IUser, "id" | "firstName" | "lastName" | "color">[];
}
export interface IInterExpGanttData extends ITaskGanttData {
	met?: string;
	trial: string;
}

export function createDataFromIMETGantt(
	ganttMETData?: IMETGanttSerialized[],
	settings = { currentIndex: 1 },
	expandedTasks: GanttColumnID[] = [],
	// Don't start at 0 : KendoGantt seems to have a bug that when an element as an id 0,
	// it will generate an key to the current index instead of the id (potentially duplicating a key if the element with id 0 is not the first to be rendered)
):
	| {
			data: IBaseGanttData[];
			expandedTasks: GanttColumnID[];
	  }
	| undefined {
	if (ganttMETData === undefined) {
		return undefined;
	}
	const res: IBaseGanttData[] = [];
	for (let index = 0; index < ganttMETData.length; index++) {
		const met = ganttMETData[index];

		// set the met as expanded
		expandedTasks.push(settings.currentIndex);

		const METRes: IBaseGanttData = {
			id: settings.currentIndex++,
			origin: EExpGanttTaskOrigin.MET,
			originId: met._id,
			title: met.name,
			start: new Date(met.startDate),
			end:
				met.endDate === undefined
					? new Date(met.startDate)
					: new Date(met.endDate),
			percentComplete: 1,
			status: expStatusToGanttStatus(met.status),
			subtasks: [],
		};

		METRes.subtasks?.push(
			...(createDataFromIGanttTrial(met.trials, settings, expandedTasks)
				?.data ?? []),
		);

		res.push(METRes);
	}

	return { data: res, expandedTasks: expandedTasks };
}

export function createDataFromIGanttTrial(
	ganttTrialData?: ITrialGanttSerialized[],
	settings = { currentIndex: 1 },
	expandedTasks: GanttColumnID[] = [],
	// Don't start at 0 : KendoGantt seems to have a bg that when an element as an id 0,
	// it will generate an key to the current index instead of the id (potentially duplicating a key if the element with id 0 is not the first to be rendered)
):
	| {
			data: IBaseGanttData[];
			expandedTasks: GanttColumnID[];
	  }
	| undefined {
	if (ganttTrialData === undefined) {
		return undefined;
	}
	const res: IBaseGanttData[] = [];

	for (let index = 0; index < ganttTrialData.length; index++) {
		const trial = ganttTrialData[index];

		// set the trial as expanded
		expandedTasks.push(settings.currentIndex);

		const trialRes: IBaseGanttData = {
			id: settings.currentIndex++,
			origin: EExpGanttTaskOrigin.TRIAL,
			originId: trial._id,
			title: trial.name,
			start: new Date(trial.startDate),
			end:
				trial.endDate === undefined
					? new Date(trial.startDate)
					: new Date(trial.endDate),
			percentComplete: 1,
			status: expStatusToGanttStatus(trial.status),
			subtasks: [],
		};

		trialRes.subtasks?.push(
			...createDataFromITaskGantt(trial.tasks, settings),
		);

		res.push(trialRes);
	}

	return { data: res, expandedTasks: expandedTasks };
}

export function createDataFromIInterExpGantt(
	interExpGanttData?: IInterExpTaskGanttSerialized[],
	settings = { currentIndex: 1 },
	expandedTasks: GanttColumnID[] = [],
	// Don't start at 0 : KendoGantt seems to have a bg that when an element as an id 0,
	// it will generate an key to the current index instead of the id (potentially duplicating a key if the element with id 0 is not the first to be rendered)
):
	| {
			data: IInterExpGanttData[];
			expandedTasks: GanttColumnID[];
	  }
	| undefined {
	if (interExpGanttData === undefined) {
		return undefined;
	}

	const res: IInterExpGanttData[] = [];
	for (let index = 0; index < interExpGanttData.length; index++) {
		const taskGanttWithExp = interExpGanttData[index];
		const taskGantt = taskGanttWithExp.task;

		// set the task as expanded
		expandedTasks.push(settings.currentIndex);

		const taskGanttData: ITaskGanttData[] = createDataFromITaskGantt(
			[taskGantt],
			settings,
		);
		const expTaskGanttData: IInterExpGanttData = {
			...taskGanttData[0],
			trial: taskGanttWithExp.trial.name,
			met: taskGanttWithExp.met?.name,
		};
		res.push(expTaskGanttData);
	}

	return { data: res, expandedTasks: expandedTasks };
}

export function createDataFromITaskGantt(
	ganttTaskData: IOpTaskGanttSerialized[],
	settings = { currentIndex: 1 },
): ITaskGanttData[] {
	return ganttTaskData.map((task) => {
		return {
			id: settings.currentIndex++,
			origin: taskTypeToGanttOrigin(task.type),
			originId: task._id,
			title: task.name,
			start: new Date(task.startDate),
			end: new Date(task.endDate ?? task.startDate),
			team: task.team,
			status: taskStatusToGanttStatus(task.status),
			percentComplete: task.completion ?? 1,
		};
	});
}

export function expStatusToGanttStatus(
	status: EExperimentStatus,
): EExpGanttTaskStatus {
	switch (status) {
		case EExperimentStatus.NOT_VALIDATED:
		case EExperimentStatus.COMPLETED:
		case EExperimentStatus.VALIDATED:
			return EExpGanttTaskStatus.COMPLETED;
		case EExperimentStatus.READY:
			return EExpGanttTaskStatus.IN_PROGRESS;
		default:
			return EExpGanttTaskStatus.READY;
	}
}

export function taskStatusToGanttStatus(
	status: ETaskStatus,
): EExpGanttTaskStatus {
	switch (status) {
		case ETaskStatus.COMPLETED:
			return EExpGanttTaskStatus.COMPLETED;
		case ETaskStatus.IN_PROGRESS:
			return EExpGanttTaskStatus.IN_PROGRESS;
		case ETaskStatus.READY:
			return EExpGanttTaskStatus.READY;
		default:
			return EExpGanttTaskStatus.READY;
	}
}
export function taskTypeToGanttOrigin(type: ETaskType): EExpGanttTaskOrigin {
	return type === ETaskType.OBSERVATION_ROUND
		? EExpGanttTaskOrigin.OBS_ROUND
		: EExpGanttTaskOrigin.OPERATION;
}
