import React from "react";
import { CircularProgress } from "@mui/material";

import { Icon, IIcon } from "stories/base-components/Icon/Icon";
import { StoryTheme } from "stories/StoryTheme";
import Size from "stories/constants/Size/Size";
import { ButtonStyled, types } from "./parameters";

import "./button.scss";

export type IButtonType =
	| "primary"
	| "secondary"
	| "tertiary"
	| "info"
	| "warning"
	| "tab"
	| "tabSelected"
	| "error";

export type IButton = {
	buttonType?: IButtonType;
	text?: string;
	disabled?: boolean;
	isLoading?: boolean;
	noPadding?: boolean;
	icon?: IIcon;
	iconButton?: boolean;
	magicIcon?: boolean;
	startIcon?: IIcon;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	className?: string;
};

export interface ButtonProps {
	button: IButton;
}

// eslint-disable-next-line react/display-name
export const Button = React.forwardRef<HTMLButtonElement | null, IButton>(
	(button, ref) => {
		let buttonText = button.text || "Button";
		if (button.iconButton) {
			buttonText = "";
		}
		const buttonType = button.buttonType || "primary";
		return (
			<StoryTheme>
				<ButtonStyled
					ref={ref}
					variant={
						types[buttonType].variant as
							| "text"
							| "contained"
							| "outlined"
					}
					color={
						types[buttonType].colorTheme as
							| "primary"
							| "secondary"
							| "info"
							| "warning"
							| "error"
					}
					className={button.className}
					disabled={button.disabled}
					noPadding={button.noPadding}
					endIcon={
						button.isLoading ? (
							<CircularProgress
								className={"btnIcon"}
								style={{
									color: types[buttonType].textColor,
								}}
								size={Size.base}
							/>
						) : (
							button.icon && (
								<Icon
									{...button.icon}
									color={types[buttonType].textColor}
								/>
							)
						)
					}
					iconButton={button.iconButton}
					startIcon={
						button.startIcon && (
							<Icon
								{...button.startIcon}
								color={types[buttonType].textColor}
							/>
						)
					}
					magicIcon={button.magicIcon}
					onClick={button.onClick}
					buttonType={button.buttonType}
				>
					{buttonText && buttonText}
				</ButtonStyled>
			</StoryTheme>
		);
	},
);
