import React from "react";
import { RouteObject } from "react-router-dom";

import { PATH_GERMPLASM, ROUTING_GENOTYPE, ROUTING_LOTS } from "App/routes";
import GermplasmDataPage from "../../Views/main/germplasm-data/GermplasmDataPage";
import GenotypeDataPage from "../../Views/main/germplasm-data/genotype/GenotypeDataPage";
import LotsDataPage from "../../Views/main/germplasm-data/lots/LotsDataPage";

export function germplasmDataRouter(): RouteObject {
	return {
		element: <GermplasmDataPage />,
		path: PATH_GERMPLASM,
		children: [
			{
				index: true,
				path: ROUTING_GENOTYPE,
				element: <GenotypeDataPage />,
			},
			{
				path: ROUTING_LOTS,
				element: <LotsDataPage />,
			},
		],
	};
}
