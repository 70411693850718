import React from "react";

import Gantt, { IGanttProps } from "../../../base-components/gantt/Gantt";
import {
	ExpGanttFields,
	ExpGanttRowRenderer,
	getTaskId,
} from "./ExperimentGanttColumns";

type IExperimentGanttProps = Pick<
	IGanttProps,
	| "data"
	| "defaultExpandedTasks"
	| "onTaskClick"
	| "onSelectionChange"
	| "columns"
>;

export default function ExperimentGantt(props: IExperimentGanttProps) {
	return (
		<Gantt
			data={props.data}
			defaultExpandedTasks={props.defaultExpandedTasks}
			onTaskClick={props.onTaskClick}
			onSelectionChange={props.onSelectionChange}
			columns={props.columns}
			fields={ExpGanttFields}
			getTaskId={getTaskId}
			rowRenderer={ExpGanttRowRenderer}
		/>
	);
}
