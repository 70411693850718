import React, { ReactElement } from "react";
import { Tooltip as MUITooltip } from "@mui/material";
import Zoom from "@mui/material/Zoom";

import { Icon } from "stories/base-components/Icon/Icon";
import { Typography } from "stories/base-components/Typography/Typography";
import Colors from "stories/constants/Colors/Colors";
import { IIconType } from "stories/base-components/Icon/IconType";
import { StoryTheme } from "stories/StoryTheme";
import Size from "stories/constants/Size/Size";

import "./tooltip.scss";

export type ITooltip = {
	text?: string;
	iconName?: IIconType;
	visible?: boolean;
	open?: boolean;
	placement?:
		| "bottom-end"
		| "bottom-start"
		| "bottom"
		| "left-end"
		| "left-start"
		| "left"
		| "right-end"
		| "right-start"
		| "right"
		| "top-end"
		| "top-start"
		| "top";
	children?: ReactElement;
	blockOpen?: boolean;
	addContainer?: boolean;
};

const TooltipComponent = (tooltip: ITooltip) => {
	const { blockOpen, addContainer, ...muiTooltipProps } = tooltip;

	return (
		<MUITooltip
			{...muiTooltipProps}
			title={
				tooltip.text && (
					<div style={{ display: "flex", alignItems: "center" }}>
						{tooltip.iconName && (
							<div style={{ marginRight: Size.xs }}>
								<Icon
									color={Colors.neutral10}
									name={tooltip.iconName}
								/>
							</div>
						)}
						<Typography
							text={tooltip.text}
							color={Colors.neutral10}
							variant="body2"
						/>
					</div>
				)
			}
			arrow
			placement={tooltip.placement}
			PopperProps={{
				className: "tooltipDoriane",
			}}
			TransitionComponent={Zoom}
			disableHoverListener={blockOpen}
		>
			{addContainer ? (
				<div>{tooltip.children}</div>
			) : (
				tooltip.children || <></>
			)}
		</MUITooltip>
	);
};

export const Tooltip = (tooltip: ITooltip) => {
	return (
		<StoryTheme>
			<TooltipComponent {...tooltip} />
		</StoryTheme>
	);
};
