import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IMETState {
	deleteIdSelected?: string;
}
function createInitialState(): IMETState {
	return {};
}
const met = createSlice({
	name: "met",
	initialState: createInitialState(),
	reducers: {
		selectDeleteMETId: (
			state,
			action: PayloadAction<string | undefined>,
		) => {
			state.deleteIdSelected = action.payload;
		},
	},
});

export const metReducer = met.reducer;
export const metAction = {
	...met.actions,
};
