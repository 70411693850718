import React, { useEffect, useState } from "react";

import { ETagType, ITag } from "shared-type";

import { Like, Unlike } from "assets";
import { apiTag } from "App/redux/attachment/tag.api";
import { LIKE } from "../picture-constant";

import "./PictureFilterDrawer.scss";

export interface IPictureFilterTags {
	onTagChange: (tags: ITag[]) => void;
}
export default function PictureFilterTagDefault(props: IPictureFilterTags) {
	const [availableTags, setAvailableTags] = useState<ITag[]>([]);
	const [isLike, setIsLike] = useState<boolean>(false);

	const { data: tags } = apiTag.useGetsQuery({
		filter: { type: ETagType.DEFAULT },
	});

	useEffect(() => {
		if (tags) {
			setAvailableTags(tags);
		}
	}, [tags]);

	const handleTagChange = (like: boolean) => {
		let tempTag: ITag[] = [];
		if (like) {
			tempTag = availableTags.filter((tag) => tag.value === LIKE);
		}

		props.onTagChange(tempTag);
	};

	return (
		<div>
			<div className="PictureFilterDrawer-image-layer">
				<img
					className="PictureFilterDrawer-image-icon"
					onClick={() => {
						handleTagChange(!isLike);
						setIsLike(!isLike);
					}}
					src={isLike ? Like : Unlike}
				/>
			</div>
		</div>
	);
}
