import React, { CSSProperties, useMemo } from "react";

import { DIC, EDIC_KEY } from "App/dictionary";
import { getColorFromTrialStatus } from "App/utils";

import UserTag from "App/components/base-components/tag/user-tag/UserTag";
import { AggroScientist, MultiFields } from "../../../../../assets";
import { getDicSpecies } from "../../../../dictionary/dictionary-key-getter";
import { apiMarketSegment } from "../../../../redux/market-segment/market-segment.api";
import { apiUser } from "../../../../redux/user/user.api";
import ImgBannerCard from "../../../base-components/card/img-banner-card/ImgBannerCard";
import ColorTag from "../../../base-components/tag/color-tag/ColorTag";
import TitledTag from "../../../base-components/tag/titled-tag/TitledTag";
import { IMETCardProps } from "./METCard";

import "./METBanner.scss";

export interface IMETBannerProps extends IMETCardProps {
	style?: CSSProperties;
	className?: string;
}

export default function METBanner(props: IMETBannerProps) {
	const { data: user } = apiUser.useGetUserByIdQuery(
		props.met.systemMetadata.createdBy,
	);
	const { data: fetchedMkSegments } =
		apiMarketSegment.useGetMarketSegmentByIdsQuery(
			props.met.marketSegmentIds ?? [],
		);

	const mkSegmentNames = useMemo(() => {
		return fetchedMkSegments?.map((mkSegment) => mkSegment.name);
	}, [fetchedMkSegments]);

	return (
		<ImgBannerCard
			onClick={props.onClick}
			className={props.className}
			disabledClick={props.disabledClick}
			style={{ ...props.style }}
			sideBanner={{ imageSrc: AggroScientist, logoSrc: MultiFields }}
		>
			<div className="METBanner-container flex-column">
				<div className="METBanner-header">
					<h1 className="METBanner-title clamped-text">
						{props.met.name}
					</h1>
					<ColorTag
						title={DIC(EDIC_KEY.STATUS)}
						tagValue={props.met.status}
						colorIndicator={getColorFromTrialStatus(
							props.met.status,
						)}
					/>
				</div>

				{user && (
					<UserTag title={DIC(EDIC_KEY.CREATED_BY)} user={user} />
				)}
				<h2 className="METBanner-description clamped-text">
					{props.met.description}
				</h2>

				<div className="METBanner-tags">
					<TitledTag
						title={DIC(EDIC_KEY.YEAR)}
						tagValue={`${props.met.year}`}
					/>
					<TitledTag
						title={DIC(EDIC_KEY.SPECIES)}
						tagValue={getDicSpecies(props.met.species) || "_"}
					/>
					<TitledTag
						title={DIC(EDIC_KEY.TRIALS)}
						tagValue={props.trialIds?.length.toString() || "_"}
					/>
					<TitledTag
						title={DIC(EDIC_KEY.MARKET_SEGMENT)}
						tagValue={mkSegmentNames || "_"}
					/>
				</div>
			</div>
		</ImgBannerCard>
	);
}
