"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_TEMPLATE_OBS_FIELDS = void 0;
exports.CREATE_TEMPLATE_OBS_FIELDS = [
    "description",
    "identifier",
    "name",
    "shortName",
    "species",
    "templateObsUnits",
    "variablesPool",
];
