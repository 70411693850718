import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { ICreateUser, IPatchUser, IUser } from "shared-type";

import { AbstractRequest } from "../abstract-request";

export class UserRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/users";
	}

	createUser(
		createUser: ICreateUser,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<string, any>> {
		return this.axios.post<string>(
			this.baseUrl + "/internal",
			createUser,
			config
		);
	}

	getUsers(config?: AxiosRequestConfig): Promise<AxiosResponse<IUser[], any>> {
		return this.axios.get<IUser[]>(this.baseUrl, config);
	}

	getUserById(
		id: string,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<IUser, any>> {
		return this.axios.get<IUser>(`${this.baseUrl}/${id}`, config);
	}

	getUserByIds(
		ids: string,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<IUser[], any>> {
		return this.axios.post<IUser[]>(
			`${this.baseUrl}/get/many`,
			{ ids },
			config
		);
	}

	// deleteUserById(id: string): Promise<AxiosResponse<void, any>> {
	// 	return this.axios.delete<void>(`${this.baseUrl}/${id}`);
	// }

	updateUserById(
		id: string,
		patchUser: IPatchUser,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<void, any>> {
		return this.axios.patch<void, AxiosResponse<void, any>>(
			`${this.baseUrl}/internal/${id}`,
			patchUser,
			config
		);
	}
}
