import * as Yup from "yup";

import {
	EEntitiesLength,
	EVariableCoreInputType,
	EVariableCoreType,
} from "shared-type";

export const variableValidator = Yup.object().shape({
	identifier: Yup.string()
		.required()
		.min(1)
		.max(EEntitiesLength.identifier)
		.matches(/^[a-zA-Z0-9_-]+$/),
	name: Yup.string().required()
.min(1)
.max(EEntitiesLength.name),

	shortName: Yup.string().required()
.min(1)
.max(EEntitiesLength.shortName),
	description: Yup.string().max(EEntitiesLength.description),
	inputType: Yup.string()
		.required()
		.oneOf(Object.values(EVariableCoreInputType)),
	type: Yup.string().required()
.oneOf(Object.values(EVariableCoreType)),
	constraint: Yup.object(),
	limitedChoices: Yup.array(),
});

// Type of the validate schema
export type VariableSchemaType = Yup.InferType<typeof variableValidator>;
