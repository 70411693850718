import { Avatar } from "@mui/material";
import React, { CSSProperties } from "react";

import { IUser } from "shared-type";

import styles from "../../../../sassTheme";

export interface IUserAvatarProps {
	user?: IUser;
	size?: "small" | "medium" | "big";
	style?: CSSProperties;
}

function getAvatarPxFromSize(size?: "small" | "medium" | "big") {
	switch (size) {
		case "small":
			return "22px";
		case "big":
			return "45px";

		default:
			return "30px";
	}
}
function getPPxFromSize(size?: "small" | "medium" | "big") {
	switch (size) {
		case "small":
			return "13px";
		case "big":
			return "40px";

		default:
			return "25px";
	}
}

export default function UserAvatar(props: IUserAvatarProps) {
	return (
		<Avatar
			sx={{
				bgcolor: props.user?.disabled
					? styles["grey-dark"]
					: props.user?.color,
				width: getAvatarPxFromSize(props.size),
				height: getAvatarPxFromSize(props.size),

				p: { fontSize: getPPxFromSize(props.size) },

				...props.style,
			}}
			alt={(props.user?.firstName || "_") + (props.user?.lastName || "_")}
			title={`${props.user?.firstName || "_"} ${
				props.user?.lastName || "_"
			}`}
			// Future : When we add avatars to the users, set the link here.
			/* src={
			props.user?.color === "#fedcba" || props.user?.color === "#abcdef"
				? "/broken-image.jpg"
				: `https://i.pravatar.cc?u=${props.user?.id}`
		}*/
		>
			<p>
				{props.user?.firstName?.[0].toUpperCase() || ""}
				{props.user?.lastName?.[0].toUpperCase() || ""}
			</p>
		</Avatar>
	);
}
