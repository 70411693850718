import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { formatString, getElementsNotInList } from "common";
import {
	EVariableScope,
	IPatchTemplateObservationCore,
	IVariableCore,
} from "shared-type";

import TopMenu from "App/components/base-components/menu/top-menu/TopMenu";
import CommentDrawer from "App/components/base-components/modal/comment-drawer/CommentDrawer";
import FlexBox from "App/components/base-components/placement/flex-box/FlexBox";
import CreateVariableGroupModal from "App/components/templates/experiment/variable-group/modal/create-variable-group/CreateVariableGroupModal";
import EditVariableGroupModal from "App/components/templates/experiment/variable-group/modal/edit-variable-group/EditVariableGroupModal";
import TemplateObsAddCustomVariablesModal from "App/components/templates/observation/edition/add-custom-variables/TemplateObsAddCustomVariablesModal";
import { DIC, EDIC_KEY } from "App/dictionary";
import useAppSnackbar from "App/hooks/useAppSnackbar";
import { templateObsEditionActions } from "App/redux/template-obs-edition/template-obs-edition.slice";
import { apiTemplateObs } from "App/redux/template-obs/template-obs.api";
import { ReduxApiError } from "App/redux/utils/errors";
import {
	PATH_GENERAL,
	PATH_OBSERVATION,
	PATH_TEMPLATES,
	PATH_VARIABLES,
	PATH_VARIABLE_GROUPS,
} from "App/routes";
import { AppDispatch } from "App/store";
import TemplateObservationBanner from "stories/base-components/Card/mostUsed/TemplateObservationBanner";
import { Tabs } from "stories/base-components/Navigation/Tabs/Tabs";
import { useGetOntologyVarRow } from "../../../../../../components/base-components/data-grid/doriane-data-grid/default-grid-columns/VariableColumns";
import TemplateObsNeedSaveModal from "../../../../../../components/templates/observation/edition/modal/TemplateObsNeedSaveModal";
import {
	getVariableInTemplate,
	useTemplateObsEdition,
	useTemplateObsLoader,
} from "../../../../../../components/templates/observation/TemplateObsEditionPage.utils";

import "./TemplateObsEditionPage.scss";

export default function TemplateObsEditionPage() {
	const dispatch = useDispatch<AppDispatch>();
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();
	const nav = useNavigate();
	const { templateObsId } = useParams();
	const location = useLocation();

	const [needSaveOpen, setNeedSaveOpen] = useState(false);
	const isInit = useTemplateObsLoader(templateObsId);

	const {
		currentTemplateObs,
		remoteTemplateObs,
		isTemplateObsUsed,
		currentVarGroupId,
		isCommentOpen,
		isAddVariableOpen,
		isVarGroupCreateOpen,
		isVarGroupEditOpen,
		formHasErrors,
		updateCurrentTemplateObs,
		setCurrentVarGroupId,
		setCommentOpen,
		setAddVariableOpen,
		setVarGroupCreateOpen,
		setVarGroupEditOpen,
	} = useTemplateObsEdition();

	const [updateTemplateObsById] =
		apiTemplateObs.useUpdateTemplateObsByIdMutation();

	const { data: ontologyVariables } = useGetOntologyVarRow();
	const variablesCustom = useMemo(() => {
		return ontologyVariables.filter(
			(variable) => variable.scope === EVariableScope.CUSTOM,
		);
	}, [ontologyVariables]);
	const selectedCustom = useMemo(
		() =>
			new Set(
				currentTemplateObs?.variablesPool
					.filter((elt) => elt.scope === EVariableScope.CUSTOM)
					.map((elt) => elt.variableId),
			),
		[currentTemplateObs],
	);

	const [modalSelectedRow, setModalSelectedRow] = useState(selectedCustom);
	const [isModalSelectedRowInitialized, setIsModalSelectedRowInitialized] =
		useState(false);

	useEffect(() => {
		if (
			modalSelectedRow.size === 0 &&
			selectedCustom.size !== 0 &&
			!isModalSelectedRowInitialized
		) {
			setModalSelectedRow(selectedCustom);
			setIsModalSelectedRowInitialized(true);
		}
	}, [isModalSelectedRowInitialized, modalSelectedRow.size, selectedCustom]);

	const variableInTemplate: IVariableCore[] = getVariableInTemplate(
		currentTemplateObs,
		ontologyVariables,
	);

	useEffect(() => {
		return () => {
			dispatch(templateObsEditionActions.resetTemplateObs);
			setCommentOpen(false);
			setAddVariableOpen(false);
			setVarGroupCreateOpen(false);
			setVarGroupEditOpen(false);
		};
		// Can't put popup deps
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		const splittedPath = location.pathname.split("/");
		if (!splittedPath[4]) {
			nav(`.${PATH_GENERAL}`, { replace: true });
		}
	}, [location, nav]);

	const handleSave = async () => {
		// Should be impossible to click on save if template not defined
		if (!currentTemplateObs) return;

		if (formHasErrors) {
			enqueueSnackbarError(formatString(DIC(EDIC_KEY.BAD_INPUT)));
			return;
		}
		const templateObsPatch: IPatchTemplateObservationCore = {
			comments: currentTemplateObs.comments,
			description: currentTemplateObs.description,
			name: currentTemplateObs.name,
			shortName: currentTemplateObs.shortName,
			templateObsUnits: currentTemplateObs.templateObsUnits,
			variablesPool: currentTemplateObs.variablesPool,
		};
		// Do not override variables part
		if (isTemplateObsUsed) {
			templateObsPatch.variablesPool = undefined;
			templateObsPatch.templateObsUnits = undefined;
		}

		await updateTemplateObsById({
			id: currentTemplateObs._id,
			update: templateObsPatch,
		})
			.unwrap()
			.then(() => {
				enqueueSnackbarSuccess(
					formatString(
						DIC(EDIC_KEY.ENTITY_UPDATED),
						DIC(EDIC_KEY.OBSERVATION_TEMPLATE),
					),
				);
			})
			.catch((err: ReduxApiError) => {
				console.warn(err);
				enqueueSnackbarError(
					formatString(
						DIC(EDIC_KEY.CANT_ACTION_ENTITY),
						DIC(EDIC_KEY.UPDATE).toLowerCase(),
						DIC(EDIC_KEY.OBSERVATION_TEMPLATE).toLowerCase(),
					),
				);
			});
	};

	const saveVariablesTemplateObs = () => {
		if (!currentTemplateObs) {
			return;
		}
		const templateObsCopy = { ...currentTemplateObs };
		templateObsCopy.variablesPool = [];
		const templateObsUnitsCopy = {
			...templateObsCopy.templateObsUnits,
		};
		variablesCustom.forEach((variable) => {
			if (Array.from(modalSelectedRow).includes(variable._id)) {
				templateObsCopy.variablesPool.push({
					variableId: variable._id,
					scope: variable.scope,
				});
			} else {
				// delete in obs units (keep all others)
				const templateObsTrialIds =
					templateObsUnitsCopy.trial?.map(
						(trial) => trial.variableId,
					) || [];
				if (templateObsTrialIds.includes(variable._id)) {
					templateObsUnitsCopy.trial =
						templateObsUnitsCopy.trial?.filter(
							(trial) => trial.variableId !== variable._id,
						);
				}
				const templateObsMaterialIds =
					templateObsUnitsCopy.material?.map(
						(material) => material.variableId,
					) || [];
				if (templateObsMaterialIds.includes(variable._id)) {
					templateObsUnitsCopy.material =
						templateObsUnitsCopy.material?.filter(
							(material) => material.variableId !== variable._id,
						);
				}
			}
		});
		templateObsCopy.templateObsUnits = templateObsUnitsCopy;
		dispatch(updateCurrentTemplateObs(templateObsCopy));
	};

	const handleVariableTemplateObsCheck = (
		variable: IVariableCore,
		check: boolean,
	) => {
		// checkVariablesTemplateObs([variable], check, currentTemplateObs);
		const setCopy = modalSelectedRow;
		if (check) {
			setCopy.add(variable._id);
		} else {
			setCopy.delete(variable._id);
		}
		setModalSelectedRow(setCopy);
		// TO refresh but better using an other refresh action
		if (currentTemplateObs) {
			dispatch(updateCurrentTemplateObs(currentTemplateObs));
		}
	};

	const handleVariableTemplateObsCheckMultiple = (
		variables: Array<IVariableCore>,
		check: boolean,
	) => {
		// checkVariablesTemplateObs(variables, check, currentTemplateObs);
		if (check) {
			setModalSelectedRow(new Set(variables.map((elt) => elt._id)));
		} else {
			setModalSelectedRow(new Set());
		}
		// TO refresh but better using an other refresh action
		if (currentTemplateObs) {
			dispatch(updateCurrentTemplateObs(currentTemplateObs));
		}
	};

	const handleGoToVarGroupTab = () => {
		if (!currentTemplateObs || !remoteTemplateObs) return false;

		const diff = getElementsNotInList(
			currentTemplateObs.variablesPool,
			remoteTemplateObs.variablesPool,
		);
		if (diff.length) {
			if (!needSaveOpen) setNeedSaveOpen(true);
			return false;
		}
		return true;
	};

	return (
		<div className="TemplateObsEditionPage-container full-parent-size">
			<div className="TemplateObsEditionPage-edit-part flex-column full-parent-size">
				{isInit && remoteTemplateObs && (
					<>
						<TopMenu
							onBackClick={() =>
								nav(`${PATH_TEMPLATES}${PATH_OBSERVATION}`)
							}
							saveButton={{ onClick: handleSave }}
							onCommentClick={() => setCommentOpen(true)}
						/>
						<div className="TemplateObsEditionPage-header-banner">
							<TemplateObservationBanner
								templateObservation={remoteTemplateObs}
								disabledClick
							/>
						</div>
					</>
				)}
				<FlexBox
					justifyContent="center"
					className="TemplateObsEditionPageTabContainer"
				>
					<Tabs
						validatePathLevel={4}
						tabs={[
							{
								tabLabel: DIC(EDIC_KEY.GENERAL),
								path: `.${PATH_GENERAL}`,
							},
							{
								tabLabel: DIC(EDIC_KEY.VARIABLES),
								path: `.${PATH_VARIABLES}`,
							},
							{
								tabLabel: DIC(EDIC_KEY.GROUP),
								path: `.${PATH_VARIABLE_GROUPS}`,
								onTabClick: handleGoToVarGroupTab,
							},
						]}
					/>
				</FlexBox>

				<div className="TemplateObsPart-container take-remaining-space">
					<div className="TemplateObsPart-body full-parent-size flex-column">
						<Outlet />
					</div>
				</div>
			</div>
			{isCommentOpen && currentTemplateObs && (
				<CommentDrawer
					onClose={() => setCommentOpen(false)}
					remoteComments={currentTemplateObs.comments}
					onSaveComment={(comments) =>
						updateTemplateObsById({
							id: currentTemplateObs._id,
							update: { comments },
						})
							.unwrap()
							.then(() => {
								updateCurrentTemplateObs({
									comments,
								});
							})
					}
				/>
			)}
			<TemplateObsAddCustomVariablesModal
				onClose={() => setAddVariableOpen(false)}
				saveVariables={saveVariablesTemplateObs}
				variablesCheck={modalSelectedRow}
				variables={variablesCustom}
				onVariableCheck={handleVariableTemplateObsCheck}
				onVariableCheckMultiple={handleVariableTemplateObsCheckMultiple}
				open={isAddVariableOpen}
				editDisplay={
					(currentTemplateObs?.variablesPool?.length || 0) > 0
				}
			/>

			{currentTemplateObs && (
				<CreateVariableGroupModal
					onClose={() => setVarGroupCreateOpen(false)}
					templateObservation={currentTemplateObs}
					variableInTemplate={variableInTemplate}
					open={isVarGroupCreateOpen}
				/>
			)}
			{currentVarGroupId && (
				<EditVariableGroupModal
					onClose={() => {
						setVarGroupEditOpen(false);
						setCurrentVarGroupId(undefined);
					}}
					templateObservation={currentTemplateObs}
					variableInTemplate={variableInTemplate}
					varGroupId={currentVarGroupId}
					open={isVarGroupEditOpen}
				/>
			)}
			{needSaveOpen && (
				<TemplateObsNeedSaveModal
					onOkClick={() => setNeedSaveOpen(false)}
				/>
			)}
		</div>
	);
}
