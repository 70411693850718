import React from "react";
import { RouteObject } from "react-router-dom";

import { PATH_PICTURE } from "App/routes";
import PicturePage from "App/Views/main/picture/PicturePage";

export function pictureRouter(): RouteObject {
	return {
		path: PATH_PICTURE,
		children: [{ path: PATH_PICTURE, element: <PicturePage /> }],
	};
}
