import { createSelector } from "@reduxjs/toolkit";

import { ITemplateObsCoreSerialized } from "shared-type";

import { RootState } from "../../store";

const selectRemoteTemplateObs = (
	state: RootState,
): ITemplateObsCoreSerialized | undefined =>
	state.templateObsEdition.remoteTemplateObs;

const isTemplateObsUsed = (state: RootState) => {
	return state.templateObsEdition.templateObsUsages?.length !== 0;
};

const selectTemplateObsEdition = (state: RootState) =>
	state.templateObsEdition.templateObsEdition;

const selectEditingMergeValue = createSelector(
	selectRemoteTemplateObs,
	selectTemplateObsEdition,
	(
		remoteTemplateObs,
		templateObsEdition,
	): ITemplateObsCoreSerialized | undefined => {
		if (!remoteTemplateObs) {
			return undefined;
		}
		return {
			...remoteTemplateObs,
			...templateObsEdition,
		};
	},
);

const selectFormHasErrors = (state: RootState) =>
	state.templateObsEdition.formHasError;

const selectCurrentVarGroupId = (state: RootState) =>
	state.templateObsEdition.currentVarGroupId;

const isAddVariableOpen = (state: RootState) =>
	state.templateObsEdition.isAddVariableOpen;

const isCommentOpen = (state: RootState) =>
	state.templateObsEdition.isCommentOpen;

const isVarGroupCreateOpen = (state: RootState) =>
	state.templateObsEdition.isVarGroupCreateOpen;

const isVarGroupEditOpen = (state: RootState) =>
	state.templateObsEdition.isVarGroupEditOpen;

const isEditingInProgress = (state: RootState) =>
	state.templateObsEdition.isEditingInProgress;

const isInit = (state: RootState) => state.templateObsEdition.isInit;

export const selectTmpltObsEdition = {
	selectEditingMergeValue,
	isTemplateObsUsed,
	selectTemplateObsEdition,
	selectRemoteTemplateObs,
	selectFormHasErrors,
	selectCurrentVarGroupId,
	isCommentOpen,
	isVarGroupCreateOpen,
	isVarGroupEditOpen,
	isEditingInProgress,
	isAddVariableOpen,
	isInit,
};
