import { createAsyncThunk } from "@reduxjs/toolkit";
import * as _ from "lodash";

import { IGrowingAreaSerialized, IPatchTrial } from "shared-type";

import { RootState } from "../../../store";
import { apiGrowingArea } from "../../growing-area/growing-area.api";
import { apiMarketSegment } from "../../market-segment/market-segment.api";
import { ReduxApiError } from "../../utils/errors";
import { apiTrial } from "../trial/trial.api";
import { IMETTrialRow } from "./met-trial-management.slice";
import { apiMET } from "./met.api";

/**
 * Load trials
 */
export const loadTrialFromMET = createAsyncThunk(
	"met-trial-management/load-trials-from-met",
	async (payload: { id: string }, thunkApi) => {
		const trials = await thunkApi
			.dispatch(apiTrial.endpoints.getTrialsByMETId.initiate(payload.id))
			.unwrap();
		const growingAreas = await thunkApi
			.dispatch(
				apiGrowingArea.endpoints.getGrowingAreaByIds.initiate(
					trials.map((elt) => elt.growingAreaId).filter(_.isString),
				),
			)
			.unwrap();
		const growingAreasMap = new Map<string, IGrowingAreaSerialized>(
			growingAreas?.map((ga) => [ga._id, ga]),
		);
		const marketSegmentIds = _.uniq(
			trials.reduce<string[]>(
				(acc, trial) => [...acc, ...(trial.marketSegmentIds || [])],
				[],
			),
		);
		const marketSegments =
			marketSegmentIds.length > 0
				? await thunkApi
						.dispatch(
							apiMarketSegment.endpoints.getMarketSegmentByIds.initiate(
								marketSegmentIds,
							),
						)
						.then((res) => res.data || [])
				: [];
		const marketSegmentsMap = new Map(
			marketSegments.map((ms) => [ms._id, ms]),
		);
		const trialRows: IMETTrialRow[] = trials.map((trial, index) => {
			const growingAreaOfTrial = trial.growingAreaId
				? growingAreasMap.get(trial.growingAreaId)
				: undefined;
			return {
				id: index,
				growingArea: {
					id: trial.growingAreaId,
					name: growingAreaOfTrial?.name,
					region: growingAreaOfTrial?.region,
					country: growingAreaOfTrial?.country,
				},
				trial: {
					marketSegments: trial.marketSegmentIds?.map(
						(ms) =>
							marketSegmentsMap.get(ms)?.name ||
							`unknown (${ms})`,
					),
					id: trial._id,
					name: trial.name,
					replication: 1,
					status: trial.status,
				},
			};
		});
		return { trialRows, metId: payload.id };
	},
);

/**
 * Save trials
 */
export const saveMetTrials = createAsyncThunk(
	"met-trial-management/met-ready-save-trials",
	async (payload: void, thunkApi) => {
		const state = thunkApi.getState() as RootState;
		const metId = state.METTrialManagement.metId;
		if (!metId) {
			return thunkApi.rejectWithValue(["Failed to load MET"]);
		}
		const problems = {
			deleteFailedIds: [] as string[],
			updateFailedIds: [] as string[],
			createTrialsFailed: undefined as undefined | ReduxApiError,
		};
		// delete trials
		const deletePromise = state.METTrialManagement.idsTrialsToDelete?.map(
			async (elt) => {
				return thunkApi
					.dispatch(apiTrial.endpoints.deleteTrialById.initiate(elt))
					.unwrap()
					.catch((err) => {
						console.error(err);
						problems.deleteFailedIds.push(elt);
					});
			},
		);

		// update the new trials
		const updateTrialsPromise = state.METTrialManagement.trialsRows?.map(
			(row) => {
				if (!row.trial?._id) {
					return undefined;
				}
				const patch: IPatchTrial = {
					name: row.trial.name || undefined,
					growingAreaId: row.growingArea?.id || null,
				};
				return thunkApi
					.dispatch(
						apiTrial.endpoints.updateTrialById.initiate({
							id: row.trial._id || "",
							updateTrial: patch,
						}),
					)
					.unwrap()
					.catch((err) => {
						console.error(err);
						problems.updateFailedIds.push(row.trial?._id || "");
					});
			},
		);

		// create the new trials
		const trialToCreate = state.METTrialManagement.trialsRows
			?.filter((row) => row.isNew && row.trial?.name !== undefined)
			.map((row) => ({
				name: row.trial?.name as string,
				growingAreaId: row.growingArea?.id,
			}));
		let trialsCreatePromise: Promise<string[]> | undefined;
		if (trialToCreate && trialToCreate.length > 0) {
			trialsCreatePromise = thunkApi
				.dispatch(
					apiMET.endpoints.generateTrialsForMET.initiate({
						metId: metId,
						trials: trialToCreate,
					}),
				)
				.unwrap()
				.catch((err: ReduxApiError) => {
					console.error(err);
					problems.createTrialsFailed = err;
					return [];
				});
		}

		await Promise.allSettled([
			...(deletePromise || []),
			...(updateTrialsPromise || []),
			trialsCreatePromise,
		])
			.then(() => {})
			.catch((err) => {
				console.log(err);
			});
		const error: string[] = [];
		if (problems.deleteFailedIds.length > 0) {
			error.push("Failed to delete some trials");
		}
		if (problems.updateFailedIds.length > 0) {
			error.push("Failed to update some trials");
		}
		if (problems.createTrialsFailed) {
			error.push("Failed to create some trials");
		}
		if (error.length > 0) {
			return thunkApi.rejectWithValue(error);
		}
		return;
	},
);
