import React, { useCallback, useEffect, useRef, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";

import {
	getErrorMessage,
	notationValueValidator,
	undefinedOrStringEmpty,
} from "common";

import { FormMode } from "../../../../const";
import { DIC, EDIC_KEY } from "../../../../dictionary";
import { ICustomVariableForm } from "../../../form/custom-variable";
import {
	EditingVarLimitedChoice,
	LimitedChoiceConverter,
} from "./editing-variable-limited-choices";
import { Input } from "stories/base-components/Input/Input";
import { Button } from "stories/base-components/Button/Button";
import { Chip } from "stories/base-components/Chip/Chip";

import "./LimitedChoicesForm.scss";

interface ICustomVariableConstraintFormProps {
	mode: FormMode;
	form: UseFormReturn<ICustomVariableForm>;
}

interface LimitedChoiceErrors {
	[key: string]: string[];
}

export default function LimitedChoicesForm(
	props: ICustomVariableConstraintFormProps,
) {
	const limitedChoiceRef = useRef<HTMLInputElement>(); // Use to focus after "create"
	const form = props.form;
	const { setValue } = form;
	const [limitedChoices, unit, type] = useWatch({
		control: form.control,
		name: ["limitedChoices", "unit", "type"],
	});

	const choices = LimitedChoiceConverter.convertToEdition(
		limitedChoices ?? [],
	);

	const [currentLabel, setCurrentLabel] = useState<string>("");
	const [currentValue, setCurrentValue] = useState<string>("");
	const [currentErrors, setCurrentErrors] = useState<LimitedChoiceErrors>();

	const validateValue = useCallback((): LimitedChoiceErrors | undefined => {
		const convertedValue = LimitedChoiceConverter.convertValueFromEdition(
			currentValue,
			type,
		);
		const valueErrors = notationValueValidator(convertedValue, type);
		if (valueErrors.length > 0) {
			return {
				value: valueErrors,
			};
		}
		return undefined;
	}, [currentValue, type]);

	const validateLabel = useCallback((): LimitedChoiceErrors | undefined => {
		if (currentLabel.length > 64) {
			return {
				label: ["Length need to be less or equal than 64 characters"], // Manual check but use same error format
			};
		}
		return undefined;
	}, [currentLabel.length]);

	const addNewChoice = () => {
		if (!currentLabel || !currentValue) {
			return;
		}
		if (currentErrors !== undefined) {
			return;
		}

		const newChoice: EditingVarLimitedChoice = {
			label: currentLabel,
			value: currentValue,
		};
		setValue("limitedChoices", [...(choices ?? []), newChoice]);
		setCurrentLabel("");
		setCurrentValue("");
		setCurrentErrors(undefined);
		limitedChoiceRef.current?.focus?.(); // After enter, focus again limited choices fields
	};

	const removeChoice = (index: number) => {
		const trimmedArray = [...(choices ?? [])];
		trimmedArray.splice(index, 1);
		setValue("limitedChoices", trimmedArray);
	};

	useEffect(() => {
		let errors = undefined;

		if (!undefinedOrStringEmpty(currentValue)) {
			errors = validateValue();
		}
		if (!undefinedOrStringEmpty(currentLabel)) {
			const labelError = validateLabel();
			if (labelError !== undefined) {
				if (errors !== undefined) {
					errors = { ...errors, ...labelError };
				} else {
					errors = labelError;
				}
			}
		}
		setCurrentErrors(errors);
	}, [currentValue, currentLabel, validateValue, validateLabel]);

	return (
		<>
			<div className="LimitedChoicesForm-choices-container CustomVariableForm-block flex-column">
				{choices.length > 0 && (
					<div className="LimitedChoicesForm-choices-selected-container">
						{choices?.map((choice, index) => (
							<Chip
								key={index}
								label={`${choice.label} (${choice.value}${
									unit ? ` ${unit}` : ""
								})`}
								onClose={
									props.mode === "used"
										? undefined
										: () => removeChoice(index)
								}
							/>
						))}
					</div>
				)}

				{props.mode !== "used" && (
					<>
						<div
							style={{
								display: "flex",
								gap: "10px",
								paddingLeft: "7px",
							}}
						>
							<Input
								inputRef={limitedChoiceRef}
								label={DIC(EDIC_KEY.LABEL)}
								required
								onChange={(event) =>
									setCurrentLabel(event.target.value)
								}
								value={currentLabel}
								error={currentErrors?.label !== undefined}
								errorMessage={getErrorMessage(
									currentErrors?.label,
								)}
							/>

							<Input
								label={DIC(EDIC_KEY.VALUE)}
								required
								onChange={(event) =>
									setCurrentValue(event.target.value)
								}
								value={currentValue}
								error={currentErrors?.value !== undefined}
								errorMessage={getErrorMessage(
									currentErrors?.value,
								)}
							/>
							<Button
								text={DIC(EDIC_KEY.ADD)}
								onClick={addNewChoice}
								buttonType="secondary"
							/>
						</div>
					</>
				)}
			</div>
		</>
	);
}
