import React from "react";
import { FormControlLabel, Checkbox as MUICheckbox } from "@mui/material";

import { Text } from "stories/base-components/Typography/Typography";
import { StoryTheme } from "stories/StoryTheme";

import "./checkbox.scss";

export type ICheckbox = {
	label?: string;
	checked?: boolean;
	indeterminate?: boolean;
	onChange?: (event: any, checked: any) => void;
	checkedType?: "round" | "square";
	disabled?: boolean;
};

const CheckboxComponent = (checkbox: ICheckbox) => {
	const checkTypeString = checkbox.checkedType
		? "-" + checkbox.checkedType
		: "-square";
	const disabledString = checkbox.disabled ? "-disabled" : "";

	return (
		<StoryTheme>
			<MUICheckbox
				{...checkbox}
				icon={
					<img
						src={
							process.env.PUBLIC_URL +
							"/checkbox/unchecked" +
							disabledString +
							checkTypeString +
							".svg"
						}
						alt="uncheckedicon"
					/>
				}
				checkedIcon={
					<img
						src={
							process.env.PUBLIC_URL +
							"/checkbox/checked" +
							disabledString +
							checkTypeString +
							".svg"
						}
						alt="checkedicon"
					/>
				}
				indeterminateIcon={
					<img
						src={
							process.env.PUBLIC_URL +
							"/checkbox/partial" +
							disabledString +
							checkTypeString +
							".svg"
						}
						alt="checkedicon"
					/>
				}
			/>
		</StoryTheme>
	);
};

export const Checkbox = (checkbox: ICheckbox) => {
	return checkbox.label ? (
		<FormControlLabel
			control={<CheckboxComponent {...checkbox} />}
			label={<Text text={checkbox.label} />}
		/>
	) : (
		<CheckboxComponent {...checkbox} />
	);
};
