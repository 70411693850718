import { Close } from "@mui/icons-material";
import React from "react";

import DorianeButton from "App/components/base-components/button/DorianeButton";
import DorianeModal from "../../base-components/modal/DorianeModal";

import "./PictureModal.scss";

export interface IPictureModalProps {
	onClose: () => void;
	url: string;
	errors?: string[];
}
export default function PictureModal(props: IPictureModalProps) {
	return (
		<DorianeModal
			onClose={props.onClose}
			closeOnOutsideClick={true}
			className={"PictureModal"}
		>
			<DorianeButton
				dorianeStyle="quaternary"
				className="PictureModal-img-button"
				onClick={props.onClose}
			>
				<Close />
			</DorianeButton>
			<div className={"PictureModal-scroll-bar"}>
				<img src={props.url} className="PictureModal-picture" />
			</div>
		</DorianeModal>
	);
}
