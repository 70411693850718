import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { EGermplasmLevel } from "shared-type";

import DorianeButton from "App/components/base-components/button/DorianeButton";
import { DIC, EDIC_KEY } from "App/dictionary";
import { getDicGermplasmType } from "App/dictionary/dictionary-key-getter";
import { selectECW } from "App/redux/ECW/ECW.selector";
import { ECWNextMode, ECWAction } from "App/redux/ECW/ECW.slice";
import { AppDispatch } from "App/store";
import Combobox from "../../../../base-components/combobox/Combobox";
import CreationWizardMenu from "../../../../base-components/menu/creation-wizard/CreationWizardMenu";
import GermplasmMaterialList from "../../../../germplasm/material-list/GermplasmMaterialList";
import { ExperimentAddMaterial } from "../../../../experiment/common/modal/germplasm/add-material/ExperimentAddMaterial";
import CommonECWPart from "../../CommonECWPart";

import "./ECWGermplasm.scss";
import "../../ECW.scss";

interface IECWGermplasmProps {
	onNext: (action: ECWNextMode) => void;
}

export default function ECWGermplasm(props: IECWGermplasmProps) {
	const dispatch = useDispatch<AppDispatch>();

	const isAddMaterialOpen = useSelector(selectECW.isAddMaterialOpen);
	const materialType = useSelector(selectECW.germplasmLevel);
	const selectedMaterials = useSelector(selectECW.selectedMaterials);

	const isMaterialOriginSelected = useMemo(
		() => materialType !== undefined,
		[materialType],
	);

	const handleMaterialTypeChange = (type: EGermplasmLevel) => {
		dispatch(ECWAction.setMaterialType(type));
	};

	useEffect(() => {
		// By default : the selection list of germplasm is opened
		dispatch(ECWAction.setAddMaterialOpen(true));
		return () => {
			dispatch(ECWAction.setAddMaterialOpen(false));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch(ECWAction.setAddMaterialOpen(true));
	}, [materialType]);

	return (
		<>
			<CommonECWPart>
				<div className="ECW-body  take-remaining-space flex-column">
					<h1>{DIC(EDIC_KEY.MATERIAL_ORIGIN)}</h1>
					<div className="ECWGermplasm-material-origin">
						<Combobox<EGermplasmLevel>
							className="ECWGermplasm-material-origin-type"
							comboboxValues={Object.values(EGermplasmLevel)}
							onChange={handleMaterialTypeChange}
							value={materialType}
							label={DIC(EDIC_KEY.TYPE)}
							translateMethod={getDicGermplasmType}
						/>
					</div>
					{isMaterialOriginSelected && (
						<>
							<div className="ECW-title-container">
								<h1>{DIC(EDIC_KEY.MATERIAL_LIST)}</h1>
								<DorianeButton
									dorianeStyle="tertiary"
									onClick={() =>
										dispatch(
											ECWAction.setAddMaterialOpen(true),
										)
									}
								>
									{DIC(EDIC_KEY.EDIT_SELECTION)}
								</DorianeButton>
							</div>
							<GermplasmMaterialList
								type={materialType}
								materialIds={selectedMaterials}
							/>
						</>
					)}
				</div>
				<CreationWizardMenu
					onSave={() => props.onNext("save")}
					onDoItLaterClick={() => props.onNext("doItLater")}
					displaySave={selectedMaterials.length > 0}
				/>
			</CommonECWPart>
			{isAddMaterialOpen && (
				<div className="ECW-right-side">
					<ExperimentAddMaterial />
				</div>
			)}
		</>
	);
}
