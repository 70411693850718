import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import LoginCarousel from "App/components/login/login-carousel/LoginCarousel";
import { useBloomeoAuth } from "../../auth/AuthContext";
import DorianeButton from "../../components/base-components/button/DorianeButton";
import { DIC, EDIC_KEY } from "../../dictionary";
import { PATH_LANDING_PAGE } from "../../routes";

import "./LoginPage.scss";
import { Amplify } from "@aws-amplify/core";

export default function LoginPage() {
	const { loginPopup, isAuth } = useBloomeoAuth();

	const nav = useNavigate();

	useEffect(() => {
		console.log("isAuth: " + isAuth);
		if (isAuth) {
			nav(PATH_LANDING_PAGE);
		} else {
			console.log("not auth: " + JSON.stringify(Amplify.getConfig()));
		}
	}, [isAuth, nav]);
	return (
		<div className="LoginPage-container">
			<div className="LoginPage-left-side flex-column">
				<LoginCarousel />
			</div>
			<div className="LoginPage-right-side flex-column">
				<div className="LoginPage-rnd-logo"></div>
				<div className="LoginPage-icon"></div>
				<DorianeButton
					onClick={loginPopup}
					dorianeStyle="primary"
					className="LoginPage-btn"
				>
					{DIC(EDIC_KEY.LOGIN)}
				</DorianeButton>
			</div>
		</div>
	);
}
