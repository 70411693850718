"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EEntitiesLength = exports.MAX_DATE = void 0;
exports.MAX_DATE = new Date("2100-12-31T23:59:59");
var EEntitiesLength;
(function (EEntitiesLength) {
    EEntitiesLength[EEntitiesLength["username"] = 64] = "username";
    EEntitiesLength[EEntitiesLength["password"] = 64] = "password";
    EEntitiesLength[EEntitiesLength["name"] = 64] = "name";
    EEntitiesLength[EEntitiesLength["stage"] = 64] = "stage";
    EEntitiesLength[EEntitiesLength["description"] = 256] = "description";
    EEntitiesLength[EEntitiesLength["identifier"] = 64] = "identifier";
    EEntitiesLength[EEntitiesLength["firstName"] = 64] = "firstName";
    EEntitiesLength[EEntitiesLength["lastName"] = 64] = "lastName";
    EEntitiesLength[EEntitiesLength["mail"] = 64] = "mail";
    EEntitiesLength[EEntitiesLength["lotIdentifier"] = 64] = "lotIdentifier";
    EEntitiesLength[EEntitiesLength["lotName"] = 64] = "lotName";
    EEntitiesLength[EEntitiesLength["expSource"] = 64] = "expSource";
    EEntitiesLength[EEntitiesLength["locationSource"] = 64] = "locationSource";
    EEntitiesLength[EEntitiesLength["country"] = 64] = "country";
    EEntitiesLength[EEntitiesLength["region"] = 64] = "region";
    EEntitiesLength[EEntitiesLength["lotNature"] = 64] = "lotNature";
    EEntitiesLength[EEntitiesLength["destinationExperiment"] = 64] = "destinationExperiment";
    EEntitiesLength[EEntitiesLength["tags"] = 64] = "tags";
    EEntitiesLength[EEntitiesLength["conclusion"] = 256] = "conclusion";
    EEntitiesLength[EEntitiesLength["label"] = 64] = "label";
    EEntitiesLength[EEntitiesLength["unit"] = 64] = "unit";
    EEntitiesLength[EEntitiesLength["comments"] = 256] = "comments";
    EEntitiesLength[EEntitiesLength["objective"] = 256] = "objective";
    EEntitiesLength[EEntitiesLength["shortName"] = 8] = "shortName";
    EEntitiesLength[EEntitiesLength["soilDetails"] = 256] = "soilDetails";
    EEntitiesLength[EEntitiesLength["cultivationMethod"] = 64] = "cultivationMethod";
    EEntitiesLength[EEntitiesLength["subspecies"] = 64] = "subspecies";
    EEntitiesLength[EEntitiesLength["subgroup"] = 64] = "subgroup";
    EEntitiesLength[EEntitiesLength["variety"] = 64] = "variety";
    EEntitiesLength[EEntitiesLength["group"] = 64] = "group";
    EEntitiesLength[EEntitiesLength["generation"] = 64] = "generation";
    EEntitiesLength[EEntitiesLength["fullParentage"] = 256] = "fullParentage";
    EEntitiesLength[EEntitiesLength["directFParent"] = 256] = "directFParent";
    EEntitiesLength[EEntitiesLength["directMParent"] = 256] = "directMParent";
    EEntitiesLength[EEntitiesLength["originFParent"] = 256] = "originFParent";
    EEntitiesLength[EEntitiesLength["originMParent"] = 256] = "originMParent";
    EEntitiesLength[EEntitiesLength["alternateName"] = 64] = "alternateName";
    EEntitiesLength[EEntitiesLength["plc"] = 64] = "plc";
    EEntitiesLength[EEntitiesLength["control"] = 64] = "control";
    EEntitiesLength[EEntitiesLength["ownership"] = 64] = "ownership";
    EEntitiesLength[EEntitiesLength["buOwner"] = 64] = "buOwner";
    EEntitiesLength[EEntitiesLength["companyName"] = 64] = "companyName";
    EEntitiesLength[EEntitiesLength["B2CId"] = 36] = "B2CId";
})(EEntitiesLength = exports.EEntitiesLength || (exports.EEntitiesLength = {}));
