import { PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

import {
	EGermplasmLevel,
	EMaterialSource,
	IMETSerialized,
	ITemplateExpStructCore,
	ITrialSerialized,
	NB_MAX_TRIAL_BY_MET,
} from "shared-type";

import {
	IExpGeneralInfoState,
	IMETTrialDraftRow,
} from "../../components/experiment-wizard/common/types";
import { computeStepStateFromExp } from "../../components/experiment-wizard/common/wizard-utils";
import {
	createInitialState,
	ECWNextMode,
	IECWState,
	IMaterialInfo,
	startStepsValue,
} from "./ECW.slice";

export const ECWReducerBuilder = {
	setMaterialType: (
		state: IECWState,
		action: PayloadAction<EGermplasmLevel>,
	) => {
		state.germplasmPart.isAddMaterialOpen = false;
		state.germplasmPart.materialType = action.payload;
		// reset list on change
		state.germplasmPart.selectedMaterials = [];
	},
	setAddMaterialOpen: (state: IECWState, action: PayloadAction<boolean>) => {
		state.germplasmPart.isAddMaterialOpen = action.payload;
	},
	setSelectGrowingAreaOpen: (
		state: IECWState,
		action: PayloadAction<boolean>,
	) => {
		state.growingAreaPart.isSelectGAOpen = action.payload;
	},
	setIsEditGAOpen: (
		state: IECWState,
		action: PayloadAction<{ open: boolean; growingAreaId?: string }>,
	) => {
		state.growingAreaPart.isEditGAOpen = action.payload.open;
		state.growingAreaPart.growingAreaInEdition =
			action.payload.growingAreaId;
	},
	setCreateObsRoundOpen: (
		state: IECWState,
		action: PayloadAction<boolean>,
	) => {
		state.planningPart.isCreateObsRoundOpen = action.payload;
	},
	setCreateOperationOpen: (
		state: IECWState,
		action: PayloadAction<boolean>,
	) => {
		state.planningPart.isCreateOperationOpen = action.payload;
	},
	setConclusionIsOpen: (state: IECWState, action: PayloadAction<boolean>) => {
		state.planningPart.isConclusionOpen = action.payload;
	},
	addMaterial: (state: IECWState, action: PayloadAction<IMaterialInfo>) => {
		state.hasChanges = true;
		const id = action.payload.material._id;
		if (
			!state.germplasmPart.selectedMaterials.find(
				(elt) => elt.material._id === id,
			)
		) {
			state.germplasmPart.selectedMaterials = [
				{
					material: { _id: id },
					source: EMaterialSource.GERMPLASM,
				},
				...state.germplasmPart.selectedMaterials,
			];
		}
		return;
	},
	addMaterials: (state: IECWState, action: PayloadAction<any>) => {
		state.hasChanges = true;
		action.payload.materials.forEach((material: any) => {
			const id = material._id;
			if (action.payload.initMaterials.has(id)) {
				return;
			}
			if (
				!state.germplasmPart.selectedMaterials.find(
					(elt) => elt.material._id === id,
				)
			) {
				state.germplasmPart.selectedMaterials = [
					{
						material: { _id: id },
						source: EMaterialSource.GERMPLASM,
					},
					...state.germplasmPart.selectedMaterials,
				];
			}
		});
		return;
	},
	removeMaterial: (
		state: IECWState,
		action: PayloadAction<IMaterialInfo>,
	) => {
		state.hasChanges = true;
		const id = action.payload.material._id;
		state.germplasmPart.selectedMaterials =
			state.germplasmPart.selectedMaterials.filter(
				(elt) => elt.material._id !== id,
			);
	},
	removeMaterials: (state: IECWState, action: PayloadAction<any>) => {
		state.hasChanges = true;

		action.payload.materials.forEach((material: any) => {
			const id = material._id;
			if (action.payload.initMaterials.has(id)) {
				return;
			}
			state.germplasmPart.selectedMaterials =
				state.germplasmPart.selectedMaterials.filter(
					(elt) => elt.material._id !== id,
				);
		});
	},
	updateExpGeneralInfo: (
		state: IECWState,
		action: PayloadAction<{
			expGeneralInfo: Partial<IExpGeneralInfoState>;
			isError: boolean;
			isInitializing?: boolean;
		}>,
	) => {
		if (!action.payload.isInitializing) {
			state.hasChanges = true;
		}
		state.generalPart.generalInfo = action.payload.expGeneralInfo;
		state.stepList[0].isError = action.payload.isError;
		if (action.payload.isError) {
			state.stepList[0].errorMessage = "Errors in trial information";
		} else {
			state.stepList[0].errorMessage = undefined;
		}
	},
	resetSpeciesRelatedFields: (state: IECWState) => {
		state.generalPart.generalInfo.marketSegmentIds = [];
		state.observationPart.templateObsId = undefined;
	},
	updateExpTemplateObsId: (
		state: IECWState,
		action: PayloadAction<string | undefined>,
	) => {
		state.observationPart.templateObsId = action.payload;
	},
	updateEditedOperationId: (
		state: IECWState,
		action: PayloadAction<string | undefined>,
	) => {
		state.planningPart.editedOperationId = action.payload;
	},
	updateHasChanges: (state: IECWState, action: PayloadAction<boolean>) => {
		state.hasChanges = action.payload;
	},
	updateGrowingAreaId: (
		state: IECWState,
		action: PayloadAction<string | undefined>,
	) => {
		state.hasChanges = true;
		state.growingAreaPart.growingAreaId = action.payload;
	},
	setIsPageFullSize: (state: IECWState, action: PayloadAction<boolean>) => {
		state.isPageFullSize = action.payload;
	},
	setIsPageBannerDisplay: (
		state: IECWState,
		action: PayloadAction<boolean>,
	) => {
		state.isPageBannerDisplay = action.payload;
	},
	setActiveStep: (state: IECWState, action: PayloadAction<number>) => {
		state.activeStep = action.payload;
	},
	computeStepState: (state: IECWState) => {
		computeStepStateFromExp(state);
	},
	completeStep: (
		state: IECWState,
		{ payload: currentStep }: PayloadAction<number>,
	) => {
		if (state.wizardType === "SET") {
			switch (currentStep) {
				case 0:
					state.stepList[0].completed = true;
					state.stepList[1].disabled = false;
					state.stepList[2].disabled = false;
					state.stepList[3].disabled = false;
					state.activeStep = 1;
					break;
				case 1:
					state.stepList[1].completed = true;
					state.activeStep = 2;
					break;
				case 2:
					state.stepList[2].completed = true;
					state.activeStep = 3;
					break;
				case 3:
					state.stepList[3].completed = true;
					break;
				default:
					console.error(`Unhandled step : ${currentStep}`);
					break;
			}
		} else if (state.wizardType === "MET") {
			switch (currentStep) {
				case 0:
					state.stepList[0].completed = true;
					state.stepList[1].disabled = false;
					state.stepList[2].disabled = false;
					state.stepList[3].disabled = false;
					state.activeStep = 1;
					break;
				case 1:
					state.stepList[1].completed = true;
					state.activeStep = 2;
					break;
				case 2:
					state.stepList[2].completed = true;
					state.activeStep = 3;
					break;
				case 3:
					state.stepList[3].completed = true;
					break;
				default:
					console.error(`Unhandled step : ${currentStep}`);
					break;
			}
		}
	},
	uncompletedStep: (
		state: IECWState,
		{ payload: currentStep }: PayloadAction<number>,
	) => {
		if (state.wizardType === "SET") {
			switch (currentStep) {
				case 0:
					// Reset step states
					state.stepList = startStepsValue(state.wizardType);
					state.activeStep = 0;
					break;
				case 1:
					state.stepList[1].completed = false;
					state.activeStep = state.activeStep + 1;
					break;
				case 2:
					state.stepList[2].completed = false;
					state.activeStep = state.activeStep + 1;
					break;
				case 3:
					state.stepList[3].completed = false;
					break;
				default:
					console.error(`Unhandled step : ${currentStep}`);
					break;
			}
		} else if (state.wizardType === "MET") {
			switch (currentStep) {
				case 0:
					// Reset step states
					state.stepList = startStepsValue(state.wizardType);
					state.activeStep = 0;
					break;
				case 1:
					state.stepList[1].completed = false;
					state.activeStep = state.activeStep + 1;
					break;
				case 2:
					state.stepList[2].completed = false;
					state.activeStep = state.activeStep + 1;
					break;
				case 3:
					state.stepList[3].completed = false;
					break;
				default:
					console.error(`Unhandled step : ${currentStep}`);
					break;
			}
		}
	},
	initStateFromTrial: (
		state: IECWState,
		action: PayloadAction<{
			trial: ITrialSerialized;
			templateExpStruct?: ITemplateExpStructCore;
		}>,
	) => {
		const trial = action.payload.trial;

		state.wizardType = "SET";
		state.stepList = startStepsValue("SET");
		state.isInit = true;
		state.hasChanges = false;
		state.trialRemote = trial;
		state.generalPart = {
			generalInfo: {
				name: trial.name,
				label: trial.label,
				objective: trial.objective,
				description: trial.description,
				startDate: trial.startDate,
				endDate: trial.endDate,
				species: trial.species,
				program: trial.program,
				project: trial.project,
				surface: trial.surface,
				rowsNumber: trial.rowsNumber,
				rowSpacing: trial.rowSpacing,
				plantsNumber: trial.plantsNumber,
				density: trial.density,
				cultivationMethod: trial.cultivationMethod,
				trialTotalCosts: trial.trialTotalCosts,
				currency: trial.currency,
				contract: trial.contract,
				marketSegmentIds: trial.marketSegmentIds,
				location: trial.location,
			},
			comments: trial.comments,
		};
		state.observationPart = {
			templateObsId: trial.templateObsId,
		};
		state.germplasmPart = {
			...state.germplasmPart,
			materialType:
				trial.materialLevel?.germplasmLevel ||
				state.germplasmPart.materialType,
			selectedMaterials: trial.materialLevel?.materials || [],
		};
		state.growingAreaPart.growingAreaId = trial.growingAreaId;
		computeStepStateFromExp(state);
	},
	initStateFromMET: (
		state: IECWState,
		action: PayloadAction<{
			met: IMETSerialized;
			trialGrowingAreas?: { id: string; name: string }[];
		}>,
	) => {
		const gaNameMap = new Map<string, string>(
			action.payload.trialGrowingAreas?.map((ga) => [ga.id, ga.name]),
		);
		const getGaName = (gaId?: string) => {
			if (gaId) {
				return gaNameMap.get(gaId) || "unknown";
			}
			return undefined;
		};
		const met = action.payload.met;
		state.isInit = true;
		state.wizardType = "MET";
		state.stepList = startStepsValue("MET");
		state.hasChanges = false;
		state.generalPart = {
			generalInfo: {
				name: met.name,
				label: met.label,
				objective: met.objective,
				description: met.description,
				startDate: met.startDate,
				endDate: met.endDate,
				species: met.species,
				program: met.program,
				project: met.project,
				marketSegmentIds: met.marketSegmentIds,
			},
			comments: met.comments,
		};
		state.observationPart = {
			templateObsId: met.templateObsId,
		};
		state.germplasmPart = {
			...state.germplasmPart,
			materialType:
				met.materialLevel?.germplasmLevel ||
				state.germplasmPart.materialType,
			selectedMaterials: met.materialLevel?.materials || [],
		};

		state.trialGenerationPart = {
			isGASelectionOpen: false,
			trials:
				met.trialsDraft?.map((trial) => ({
					isSelected: false,
					trialId: trial.id,
					trialName: trial.name,
					growingAreaId: trial.growingAreaId,
					growingAreaName: getGaName(trial.growingAreaId),
				})) ?? [],
		};
		computeStepStateFromExp(state);
	},
	reset: (
		state: IECWState,
		{
			payload: { wizardType, isInit },
		}: PayloadAction<{ wizardType: "SET" | "MET"; isInit: boolean }>,
	) => {
		return createInitialState(wizardType, isInit);
	},
	setSaveModalInfos: (
		state: IECWState,
		action: PayloadAction<ECWNextMode>,
	) => {
		// When we set info we open the popup
		state.saveModalInfo = { action: action.payload, isOpen: true };
	},
	resetSaveModalInfos: (state: IECWState) => {
		state.saveModalInfo = {
			action: "save",
			isOpen: false,
		};
	},

	// #region MET trial generation -----------------------------------------------------------------
	trialGenerationGenerateTrialByQuantity: (
		state: IECWState,
		{ payload: { quantity } }: PayloadAction<{ quantity: number }>,
	) => {
		if (!state.trialGenerationPart) {
			return;
		}
		const parentName = state.generalPart.generalInfo.name ?? "";
		const trials = state.trialGenerationPart.trials;
		let generationSeed = 1;
		const isConflictingTrial = (trialName: string, id: number) => {
			return trials.find(
				(elt) => elt.trialName === trialName || elt.trialId === id,
			);
		};
		for (let i = 0; i < quantity; i++) {
			// limit of trials in draft
			if (trials.length >= NB_MAX_TRIAL_BY_MET) {
				break;
			}
			// generate number with 3 digit like
			let trialName = `${parentName}_Trial_${_.padStart(
				generationSeed.toString(),
				3,
				"0",
			)}`;
			while (isConflictingTrial(trialName, generationSeed)) {
				generationSeed++;

				trialName = `${parentName}_Trial_${_.padStart(
					generationSeed.toString(),
					3,
					"0",
				)}`;
			}
			// we can mutate because redux use immer
			trials.push({
				trialId: generationSeed,
				trialName: trialName,
				isSelected: false,
			});
			generationSeed++;
		}

		state.trialGenerationPart.trials = trials;
	},
	trialGenerationDeleteTrialsById: (
		state: IECWState,
		action: PayloadAction<{ trialsIdsToDelete: number[] }>,
	) => {
		if (!state.trialGenerationPart) {
			return;
		}
		const trialsToDeleteSet = new Set<number>(
			action.payload.trialsIdsToDelete,
		);

		state.trialGenerationPart.trials =
			state.trialGenerationPart.trials.filter(
				(elt) => !trialsToDeleteSet.has(elt.trialId),
			);
	},
	trialGenerationOnUnselectGA: (
		state: IECWState,
		action: PayloadAction<{
			id: string;
		}>,
	) => {
		const trial = state.trialGenerationPart?.trials.find(
			(elt) => elt.growingAreaId === action.payload.id,
		);
		if (!trial) return;
		trial.growingAreaId = undefined;
		trial.growingAreaName = undefined;
	},
	trialGenerationOnSelectGA: (
		state: IECWState,
		action: PayloadAction<{
			trialId: number;
			gaName: string;
			gaId: string;
		}>,
	) => {
		if (!state.trialGenerationPart) {
			return;
		}

		const trial = state.trialGenerationPart?.trials.find(
			(elt) => elt.trialId === action.payload.trialId,
		);
		if (!trial) {
			return;
		}

		// affect the state
		trial.growingAreaId = action.payload.gaId;
		trial.growingAreaName = action.payload.gaName;
	},
	trialGenerationOnUnselectGAMany: (
		state: IECWState,
		action: PayloadAction<{
			trials: Array<number>;
			growingAreas: Array<any>;
		}>,
	) => {
		if (!state.trialGenerationPart?.trials) {
			return;
		}
		const { trials, growingAreas } = action.payload;

		trials.forEach((currentTrial, index) => {
			const trial = state.trialGenerationPart?.trials?.find(
				(elt) => elt.trialId === currentTrial,
			);
			if (!trial) {
				return;
			}
			const currentGA = growingAreas[index];
			if (!currentGA) {
				return;
			}
			trial.growingAreaId = undefined;
			trial.growingAreaName = undefined;
		});
	},
	trialGenerationOnSelectGAMany: (
		state: IECWState,
		action: PayloadAction<{
			trials: Array<number>;
			growingAreas: Array<any>;
		}>,
	) => {
		if (!state.trialGenerationPart?.trials) {
			return;
		}
		const { trials, growingAreas } = action.payload;

		trials.forEach((currentTrial, index) => {
			const trial = state.trialGenerationPart?.trials?.find(
				(elt) => elt.trialId === currentTrial,
			);
			if (!trial) {
				return;
			}
			const currentGA = growingAreas[index];
			if (!currentGA) {
				return;
			}
			trial.growingAreaId = currentGA._id;
			trial.growingAreaName = currentGA.name;
		});
	},
	trialGenerationOnRenameTrial: (
		state: IECWState,
		action: PayloadAction<{ id: number; newName: string }>,
	) => {
		const trialToModify = state.trialGenerationPart.trials.find(
			(elt) => elt.trialId === action.payload.id,
		);
		if (!trialToModify) {
			console.error("Trial to modify name was not found");
			return;
		}
		// if the name was not unique, we need to add '_(X)' at the end
		let newNameUniq = action.payload.newName;
		let count = 1;
		while (
			state.trialGenerationPart.trials.find(
				// eslint-disable-next-line no-loop-func
				(elt) => elt.trialName === newNameUniq,
			) !== undefined
		) {
			newNameUniq = `${action.payload.newName}_(${count})`;
			count++;
		}

		trialToModify.trialName = newNameUniq;
	},
	trialGenerationOnSelectTrial: (
		state: IECWState,
		action: PayloadAction<{ id: number; select: boolean }>,
	) => {
		if (!state.trialGenerationPart) {
			return;
		}

		const trial = state.trialGenerationPart.trials.find(
			(elt) => elt.trialId === action.payload.id,
		);
		if (!trial) {
			return;
		}
		trial.isSelected = action.payload.select;
	},
	trialGenerationOnSelectTrialMany: (
		state: IECWState,
		action: PayloadAction<{
			select: boolean;
			trialsRow: Array<IMETTrialDraftRow>;
		}>,
	) => {
		const trialsVisibles = state.trialGenerationPart.trials.filter((elt) =>
			action.payload.trialsRow
				.map((currentTrial: IMETTrialDraftRow) => currentTrial.id)
				.includes(elt.trialId),
		);
		trialsVisibles.forEach((trial) => {
			trial.isSelected = action.payload.select;
		});
	},
	trialGenerationSetIsOpenGAModal: (
		state: IECWState,
		action: PayloadAction<boolean>,
	) => {
		state.trialGenerationPart.isGASelectionOpen = action.payload;
	},
	// #endregion
};
