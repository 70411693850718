import React from "react";

import { concatClassName } from "common";

import { Subtitle, Title } from "stories/base-components/Typography/Typography";
import { SearchInput } from "stories/base-components/Input/most_used/SearchInput";
import { Button, IButtonType } from "stories/base-components/Button/Button";
import { Tooltip } from "stories/base-components/Tooltip/Tooltip";

import "./HeaderTitleSearchButton.scss";

export type IHeaderTitleSearchButton = {
	title?: string;
	subtitle?: string;
	/** List where the search will be done */
	list?: Array<any>;
	searchPlaceholder?: string;
	setSearch?: any;
	searchValue?: any;
	btnText: string;
	btnCreateText?: string;
	btnOnClick: () => void;
	btnType?: IButtonType;
	btnDisabledTooltipMessage?: string;
	disabled?: boolean;
};

export const HeaderTitleSearchButton = (props: IHeaderTitleSearchButton) => {
	const createDisplay =
		props.btnCreateText && props.list && props.list.length === 0;
	const hasSearch =
		(props.list && props.list.length >= 10 && props.setSearch) ||
		props.searchValue;
	return (
		<div
			className={concatClassName(
				"HeaderTitleSearchButton",
				createDisplay ? "HeaderTitleSearchButton-create" : "",
			)}
			style={{
				// eslint-disable-next-line no-nested-ternary
				alignItems: createDisplay
					? "flex-start"
					: hasSearch
					? "flex-end"
					: "center",
			}}
		>
			<div className="HeaderTitleSearchButton-container">
				{props.title && <Title text={props.title} />}
				{props.subtitle && <Subtitle text={props.subtitle} />}
				{hasSearch && (
					<div className="HeaderTitleSearchButton-search">
						<SearchInput
							placeholder={props.searchPlaceholder}
							setInput={props.setSearch}
							value={props.searchValue}
							onChange={(event) => {
								props.searchValue(event.target.value);
							}}
						/>
					</div>
				)}
			</div>
			{(!props.btnCreateText ||
				(props.list && props.list.length > 0)) && (
				<div className="HeaderTitleSearchButton-button">
					<Tooltip
						text={props.btnDisabledTooltipMessage || ""}
						blockOpen={
							!props.btnDisabledTooltipMessage || !props.disabled
						}
						placement="left"
						addContainer
					>
						<Button
							text={props.btnText}
							onClick={props.btnOnClick}
							buttonType={props.btnType}
							disabled={props.disabled}
						/>
					</Tooltip>
				</div>
			)}
			{createDisplay && (
				<div className="HeaderTitleSearchButton-button">
					<Button
						text={props.btnCreateText}
						onClick={props.btnOnClick}
						buttonType="secondary"
					/>
				</div>
			)}
		</div>
	);
};
