import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { useMemo } from "react";

import { ICreateUser, IPatchUser, IUser } from "shared-type";
import {
	applyDorianeFilter,
	IDorianeObjectFilter,
} from "../../interfaces/filters/doriane-filter-interface";

import { useRequest } from "../../request-provider";
import { defaultApiError } from "../utils/errors";

export const apiUser = createApi({
	reducerPath: "api-user",
	baseQuery: () => ({ data: undefined }),

	// global cache configuration for the api (in second)
	keepUnusedDataFor: 30,
	refetchOnMountOrArgChange: 30,

	// update tag
	tagTypes: ["Update", "Delete", "Create"],

	// api definition
	endpoints: (build) => ({
		getUsers: build.query<IUser[], void | undefined>({
			queryFn: async (_) => {
				try {
					const result = await useRequest().user.getUsers();
					return {
						data: result.data,
					};
				} catch (err) {
					console.error(err);
					return defaultApiError(err, "Unable to fetch users");
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),
		getUserById: build.query<IUser | undefined, string | undefined>({
			queryFn: async (id) => {
				if (!id) {
					return { data: undefined };
				}
				try {
					const result = await useRequest().user.getUserById(id);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch user for id " + id,
					);
				}
			},
			providesTags: (result, error, id) => {
				return [
					{ type: "Delete", id: id },
					{ type: "Update", id: id },
					{ type: "Create", id: id },
				];
			},
		}),
		createUser: build.mutation<string, ICreateUser>({
			queryFn: async (createUser) => {
				try {
					const result = await useRequest().user.createUser(
						createUser,
					);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(err, "Unable to create user");
				}
			},
			invalidatesTags: (resultId, error) => {
				if (error) return [];
				return [
					{ type: "Create", id: resultId },
					{ type: "Create", id: "ALL" },
				];
			},
		}),

		updateUser: build.mutation<
			void,
			{ id: string; updateUser: IPatchUser }
		>({
			queryFn: async ({ id, updateUser }) => {
				try {
					const result = await useRequest().user.updateUserById(
						id,
						updateUser,
					);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(err, "Unable to update user");
				}
			},
			invalidatesTags: (result, error, { id }) => {
				if (error) return [];
				return [
					{ type: "Update", id: id },
					{ type: "Update", id: "ALL" },
				];
			},
		}),
	}),
});
function useGetUsersByIds(
	ids: string[],
	option?: Parameters<typeof apiUser.useGetUsersQuery>[1],
) {
	const { data: userList, ...others } = apiUser.useGetUsersQuery(
		undefined,
		option as any,
	);
	const userListByIds = useMemo(() => {
		const idsSet = new Set(ids);
		return userList?.filter((user: IUser) => idsSet.has(user.id));
	}, [userList, ids]);
	return { ...others, data: userListByIds || [] };
}

function useGetUsersWithFilter(
	filter: IDorianeObjectFilter,
	option?: Parameters<typeof apiUser.useGetUsersQuery>[1],
) {
	const { data: userList, ...others } = apiUser.useGetUsersQuery(
		undefined,
		option as any,
	);
	const filteredUserList = useMemo(() => {
		return applyDorianeFilter(userList || [], filter);
	}, [userList, filter]);
	return { ...others, data: filteredUserList || [] };
}
export const apiUserCustomHook = { useGetUsersByIds, useGetUsersWithFilter };
