import React from "react";
import { RouteObject } from "react-router-dom";

import {
	PATH_EXPERIMENTS,
	PATH_OBSERVATION,
	PATH_TEMPLATES,
	removeStartingSlash,
} from "../../../routes";
import TemplateExperimentStructurePage from "../../../Views/main/settings/templates/experiment/TemplateExperimentStructurePage";
import TemplateObservationPage from "../../../Views/main/settings/templates/observation/TemplateObservationListPage";
import TemplatesPage from "../../../Views/main/settings/templates/TemplatesPage";

export function templateRouter(): RouteObject {
	return {
		path: PATH_TEMPLATES,
		element: <TemplatesPage />,
		children: [
			{
				index: true,
				path: removeStartingSlash(PATH_EXPERIMENTS),
				element: <TemplateExperimentStructurePage />,
			},
			{
				path: removeStartingSlash(PATH_OBSERVATION),
				element: <TemplateObservationPage />,
			},
		],
	};
}
