import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { AbstractRequest } from "../abstract-request";

export class BackupRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/backup";
	}

	restoreDump(config?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
		return this.axios.post<void>(this.baseUrl + "/restore", config);
	}
}
