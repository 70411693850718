import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
	GridColumnProps,
	GridDetailRowProps,
} from "@progress/kendo-react-grid";
import _ from "lodash";

import { IAssociatedMetTrials, IMarketSegment } from "shared-type";

import { DIC, EDIC_KEY } from "App/dictionary";
import { PATH_EXPERIMENTS, PATH_TRIALS } from "App/routes";
import { apiTrial } from "../../../../../redux/experiment/trial/trial.api";
import FlexBox from "App/components/base-components/placement/flex-box/FlexBox";
import { Typography } from "stories/base-components/Typography/Typography";
import Size from "stories/constants/Size/Size";
import Colors from "stories/constants/Colors/Colors";
import Box from "App/components/base-components/placement/box/Box";
import { apiMarketSegment } from "App/redux/market-segment/market-segment.api";
import CreatedByUser from "App/components/base-components/data-grid/doriane-data-grid/default-grid-columns/TrialColumns";
import { Badge } from "stories/base-components/Badge/Badge";
import { getDicSpecies } from "App/dictionary/dictionary-key-getter";
import { Status } from "stories/base-components/Status/Status";
import { Icon } from "stories/base-components/Icon/Icon";
import { DropdownButton } from "stories/base-components/DropdownButton/DropdownButton";
import { AvatarIcon } from "stories/base-components/AvatarIcon/AvatarIcon";
import { IIconType } from "stories/base-components/Icon/IconType";
import store from "App/store";
import { metAction } from "App/redux/experiment/met/met.slice";
import FoldingChip from "../../../../../../stories/base-components/FoldingChip/FoldingChip";

const metColumnsWidth = {
	smallSpace: 4,
	actions: 50,
	nameDescription: 300,
	createdBy: 150,
	year: 64,
	marketSegment: 150,
	species: 150,
	expanded: 100,
};
export const METNameDescriptionCell = (props: {
	iconName: IIconType;
	iconColor: string;
	iconBackgroundColor: string;
	name: string;
	description?: string;
}) => (
	<FlexBox gap={Size.base}>
		<AvatarIcon
			iconName={props.iconName}
			color={props.iconColor}
			backgroundColor={props.iconBackgroundColor}
		/>
		<Box gap={Size.x2s} overflow="hidden">
			<Typography text={props.name} variant="body2Medium" noWrap />
			<Typography
				text={props.description}
				variant="body2"
				noWrap
				color={Colors.neutral90}
			/>
		</Box>
	</FlexBox>
);

const getMSName = (msId: string, fetchedMkSegments?: Array<IMarketSegment>) =>
	fetchedMkSegments?.find((marketSegment) => marketSegment._id === msId)
		?.name || "";

export const METTrialList = (props: GridDetailRowProps) => {
	const nav = useNavigate();
	const { data: trials } = apiTrial.useGetTrialsByMETIdQuery(
		props.dataItem._id,
	);

	const marketSegmentIds = useMemo(() => {
		const msIds = _.flatMap(trials, (obj) =>
			_.get(obj, "marketSegmentIds", []),
		);
		return msIds.filter((value, index) => msIds.indexOf(value) === index);
	}, [trials]);

	const { data: fetchedMkSegments } =
		apiMarketSegment.useGetMarketSegmentByIdsQuery(marketSegmentIds ?? []);

	return (
		<Box style={{ backgroundColor: Colors.neutral20 }}>
			{trials?.map((trial) => (
				<FlexBox
					key={trial._id}
					padding={Size.xs}
					className="gridDetailsRow"
					onClick={() =>
						nav(`${PATH_EXPERIMENTS}${PATH_TRIALS}/${trial._id}`)
					}
				>
					<Box
						paddingLeft={
							metColumnsWidth.actions +
							metColumnsWidth.smallSpace +
							"px"
						}
						width={
							metColumnsWidth.nameDescription +
							metColumnsWidth.actions +
							metColumnsWidth.smallSpace +
							"px"
						}
					>
						<METNameDescriptionCell
							iconName="MET-trial"
							iconColor={Colors.primary50}
							iconBackgroundColor={Colors.accent20}
							name={trial.name}
							description={trial.description}
						/>
					</Box>
					<FlexBox
						alignItems="center"
						width={metColumnsWidth.createdBy + "px"}
					>
						<CreatedByUser
							createdBy={trial.systemMetadata.createdBy}
						/>
					</FlexBox>
					<FlexBox
						alignItems="center"
						width={metColumnsWidth.year + "px"}
					>
						<Badge text={trial.year.toString()} />
					</FlexBox>
					<FlexBox
						alignItems="center"
						width={metColumnsWidth.marketSegment + "px"}
						gap={Size.x2s}
					>
						{(trial.marketSegmentIds || []).map((msId: string) => (
							<Badge
								text={getMSName(msId, fetchedMkSegments)}
								key={msId}
							/>
						))}
					</FlexBox>
					<FlexBox
						alignItems="center"
						width={metColumnsWidth.species + "px"}
					>
						<Badge text={getDicSpecies(trial.species)} />
					</FlexBox>
					<FlexBox alignItems="center">
						{/* TODO : Create a global status ? */}
						<Status status={trial.status as any} />
					</FlexBox>
				</FlexBox>
			))}
		</Box>
	);
};

export const METColumns = (
	onMETClick: (id: string) => void,
	fetchedMkSegments?: IMarketSegment[],
	trialsIdsByMETId?: IAssociatedMetTrials[],
) => {
	const metColumns: GridColumnProps[] = [
		{
			field: "actions",
			headerCell: () => <></>,
			width: metColumnsWidth.actions,
			cell: (props) => {
				const { dataItem } = props;
				return (
					<td>
						<DropdownButton
							btn={{
								iconButton: true,
								icon: { name: "dots-vertical" },
								magicIcon: true,
								buttonType: "tertiary",
							}}
							options={[
								{
									label: DIC(EDIC_KEY.DUPLICATE),
									iconName: "template",
									action: async (event) => {
										event.stopPropagation();
										alert(
											"It will be available soon : " +
												dataItem._id,
										);
										store.dispatch(
											metAction.selectDeleteMETId(
												dataItem._id,
											),
										);
									},
								},
								{
									label: DIC(EDIC_KEY.DELETE),
									iconName: "bin",
									action: async (event) => {
										event.stopPropagation();
										store.dispatch(
											metAction.selectDeleteMETId(
												dataItem._id,
											),
										);
									},
								},
							]}
						/>
					</td>
				);
			},
		},
		{
			field: "nameDescription",
			headerCell: () => (
				<Typography
					text={DIC(EDIC_KEY.NAME_AND_DESCRIPTION)}
					variant="body2"
					color={Colors.neutral90}
				/>
			),
			width: metColumnsWidth.nameDescription,
			cell: (props) => {
				const { dataItem } = props;
				return (
					<td onClick={() => onMETClick(dataItem._id)}>
						<METNameDescriptionCell
							iconName="MET"
							iconColor={Colors.accent60}
							iconBackgroundColor={Colors.accent20}
							name={dataItem.name}
							description={dataItem.description}
						/>
					</td>
				);
			},
		},
		{
			field: "createdBy",
			headerCell: () => (
				<Typography
					text={DIC(EDIC_KEY.CREATED_BY)}
					variant="body2"
					color={Colors.neutral90}
				/>
			),
			width: metColumnsWidth.createdBy,
			cell: (props) => {
				const { dataItem } = props;
				return (
					<td onClick={() => onMETClick(dataItem._id)}>
						<CreatedByUser
							createdBy={dataItem.systemMetadata.createdBy}
						/>
					</td>
				);
			},
		},
		{
			field: "year",
			headerCell: () => (
				<Typography
					text={DIC(EDIC_KEY.YEAR)}
					variant="body2"
					color={Colors.neutral90}
				/>
			),
			width: metColumnsWidth.year,
			cell: (props) => {
				const { dataItem } = props;
				return (
					<td onClick={() => onMETClick(dataItem._id)}>
						<Badge text={dataItem.year} />
					</td>
				);
			},
		},
		{
			field: "marketSegment",
			headerCell: () => (
				<Typography
					text={DIC(EDIC_KEY.MARKET_SEGMENT)}
					variant="body2"
					color={Colors.neutral90}
				/>
			),
			width: metColumnsWidth.marketSegment,
			cell: (props) => {
				const { dataItem } = props;
				return (
					<td onClick={() => onMETClick(dataItem._id)}>
						<FlexBox gap={Size.x2s}>
							{dataItem.marketSegmentIds.map((msId: string) => (
								<Badge
									text={getMSName(msId, fetchedMkSegments)}
									key={msId}
								/>
							))}
						</FlexBox>
					</td>
				);
			},
		},
		{
			field: "species",
			width: metColumnsWidth.species,
			headerCell: () => (
				<Typography
					text={DIC(EDIC_KEY.SPECIES)}
					variant="body2"
					color={Colors.neutral90}
				/>
			),
			cell: (props) => {
				const { dataItem } = props;
				return (
					<td onClick={() => onMETClick(dataItem._id)}>
						<Badge text={getDicSpecies(dataItem.species)} />
					</td>
				);
			},
		},
		{
			field: "status",
			headerCell: () => (
				<Typography
					text={DIC(EDIC_KEY.STATUS)}
					variant="body2"
					color={Colors.neutral90}
				/>
			),
			cell: (props) => {
				const { dataItem } = props;
				return (
					<td onClick={() => onMETClick(dataItem._id)}>
						<Status status={dataItem.status} />
					</td>
				);
			},
		},
		{
			field: "expanded",
			headerCell: () => (
				<Typography
					text={DIC(EDIC_KEY.TRIALS)}
					variant="body2"
					color={Colors.neutral90}
				/>
			),
			width: metColumnsWidth.expanded,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			cell: (props: any) => {
				const { dataItem, onExpandChange } = props;
				const trialsNumber =
					trialsIdsByMETId
						?.find((trial) => trial.metId === dataItem._id)
						?.trialsIds.length.toString() || "0";
				return (
					<td
						onClick={
							onExpandChange && trialsNumber !== "0"
								? (event) => {
										// eslint-disable-next-line @typescript-eslint/no-explicit-any
										(event as any).dataItem = dataItem;
										onExpandChange(event);
								  }
								: undefined
						}
						style={{ cursor: "pointer" }}
					>
						<FoldingChip
							label={trialsNumber}
							isExpanded={dataItem.expanded}
							hideIcon
						/>
					</td>
				);
			},
		},
	];

	return metColumns;
};
