import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { CustomLinkTab } from "../../../../components/base-components/tabs/CustomLinkTab";
import { CustomTabs } from "../../../../components/base-components/tabs/CustomTabs";
import {
	PATH_DASHBOARD_TEAM_DEV,
	PATH_DASHBOARD_TEAM_MANAGER,
} from "../../../../routes";

import "./DashboardTeamPage.scss";

export default function DashboardTeamPage() {
	const [tabIdx, setTabIdx] = useState(0);
	const location = useLocation();
	const nav = useNavigate();

	useEffect(() => {
		const splittedPath = location.pathname.split("/");
		const currentTab = "/" + splittedPath[3] ?? undefined;
		switch (currentTab) {
			case PATH_DASHBOARD_TEAM_MANAGER:
				setTabIdx(0);
				break;
			case PATH_DASHBOARD_TEAM_DEV:
				setTabIdx(1);
				break;
			default:
				nav("." + PATH_DASHBOARD_TEAM_MANAGER, { replace: true });
		}
	}, [location, nav]);
	return (
		<>
			<CustomTabs
				value={tabIdx}
				indicatorColor="secondary"
				className="DashboardTeamPage-tabs"
			>
				<CustomLinkTab
					label={"Manager"}
					secondaryLevel={true}
					href={"." + PATH_DASHBOARD_TEAM_MANAGER}
				/>
				<CustomLinkTab
					label={"Developers"}
					secondaryLevel={true}
					href={"." + PATH_DASHBOARD_TEAM_DEV}
				/>
			</CustomTabs>
			<Outlet />
		</>
	);
}
