import React from "react";

import CustomCard from "App/components/base-components/card/CustomCard";
import TitledTag from "App/components/base-components/tag/titled-tag/TitledTag";

import "./MaterialCard.scss";

export interface IMaterialCardProps {
	title: string;
	tags: { title: string; value: string }[];
}
export default function MaterialCard(props: IMaterialCardProps) {
	return (
		<CustomCard className="MaterialCard">
			<h1>{props.title}</h1>
			<div className="MaterialCard-tags">
				{props.tags.map((elt, index) => (
					<TitledTag
						key={index}
						title={elt.title}
						tagValue={elt.value}
					/>
				))}
			</div>
		</CustomCard>
	);
}
