import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useSelector } from "react-redux";

import { selectAuth } from "../authentication/authentication.selector";
import { apiTenant } from "./tenant.api";

export const useGetCurrentTenant = () => {
	const tenantId = useSelector(selectAuth.currentTenantId);
	const { data: tenant, ...request } = apiTenant.useGetTenantByIdQuery(
		tenantId ?? skipToken,
	);

	return { tenant, ...request };
};
