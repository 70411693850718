import React from "react";

import { ILot } from "shared-type";

import { DIC, EDIC_KEY } from "App/dictionary";
import {
	IDorianeFilter,
	IDorianeObjectFilter,
} from "App/interfaces/filters/doriane-filter-interface";
import { getDicSpecies } from "../../../../../dictionary/dictionary-key-getter";
import { CheckBoxColumn } from "../columns/CheckBoxColumn";
import { IColumn } from "../doriane-data-grid/DorianeDataGrid";
import HeaderWithFilter from "../doriane-data-grid/header/HeaderWithFilter";

export const lotColumns: (filter?: {
	filter: IDorianeObjectFilter;
	onFilterChange: (newFilter: IDorianeFilter) => void;
}) => IColumn<ILot>[] = (filter) => {
	if (!filter) {
		const columns: IColumn<ILot>[] = [
			{
				id: "name",
				getHeaderRenderer: DIC(EDIC_KEY.GENOTYPE_NAME),
				getCellRenderer: (row) => row?.genotype?.name,
			},
			{
				id: "genotypeSpecies",
				getHeaderRenderer: DIC(EDIC_KEY.GENOTYPE_SPECIES),
				getCellRenderer: (row) => getDicSpecies(row?.genotype?.species),
			},
			{
				id: "lotIdentifier",
				getHeaderRenderer: DIC(EDIC_KEY.LOT_ID),
				getCellRenderer: (row) => row?.lotIdentifier,
			},
			{
				id: "lotName",
				getHeaderRenderer: DIC(EDIC_KEY.LOT_NAME),
				getCellRenderer: (row) => row?.lotName || "",
			},
			{
				id: "lotYear",
				getHeaderRenderer: DIC(EDIC_KEY.YEAR),
				getCellRenderer: (row) => row?.lotYear?.toString() || "",
			},
		];
		return columns;
	}
	const columns: IColumn<ILot>[] = [
		{
			id: "name",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "genotype.name",
						type: "string",
						value: filter.filter["genotype.name"]?.value,
					}}
					label={DIC(EDIC_KEY.GENOTYPE_NAME)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (row) => row.genotype.name,
		},
		{
			id: "genotypeSpecies",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "genotype.species",
						type: "string",
						value: filter.filter["genotype.species"]?.value,
					}}
					label={DIC(EDIC_KEY.GENOTYPE_SPECIES)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (row) => getDicSpecies(row.genotype.species),
		},
		{
			id: "lotIdentifier",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "lotIdentifier",
						type: "string",
						value: filter.filter.lotIdentifier?.value,
					}}
					label={DIC(EDIC_KEY.LOT_ID)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (row) => row.lotIdentifier,
		},
		{
			id: "lotName",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "lotName",
						type: "string",
						value: filter.filter.lotName?.value,
					}}
					label={DIC(EDIC_KEY.LOT_NAME)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (row) => row.lotName || "",
		},
		{
			id: "lotYear",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "lotYear",
						type: "int",
						value: filter.filter.lotYear?.value,
					}}
					label={DIC(EDIC_KEY.YEAR)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (row) => row.lotYear?.toString() || "",
		},
	];
	return columns;
};

export function lotColumnsForTrialMaterial(
	filter: {
		filter: IDorianeObjectFilter;
		onFilterChange: (newFilter: IDorianeFilter) => void;
	},
	rowCallback: {
		isCheck: (row: ILot) => boolean;
		onCheck: (row: ILot, check: boolean) => void;
		isDisabled: (row: ILot) => boolean;
	},
): IColumn<ILot>[] {
	const checkBoxColumn = CheckBoxColumn({
		isCheck: rowCallback.isCheck,
		onCheck: rowCallback.onCheck,
		isDisabled: rowCallback.isDisabled,
	});
	return [checkBoxColumn, ...lotColumns(filter)];
}
