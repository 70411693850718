import React from "react";
import { Navigate, RouteObject } from "react-router-dom";

import {
	PATH_EXPERIMENTS,
	PATH_METS,
	PATH_TRIALS,
	removeStartingSlash,
} from "App/routes";
import ExperimentsPage from "App/Views/main/experiment/experiments/ExperimentsPage";
import METListPage from "App/Views/main/experiment/met/list/METListPage";
import TrialListPage from "App/Views/main/experiment/trial/list/TrialListPage";
import { metRouter } from "./METRouter";
import { trialRouter } from "./TrialRouter";

export function experimentRouter(): RouteObject {
	return {
		path: PATH_EXPERIMENTS,
		children: [
			{
				// Need to do that for automatic routing
				index: true,
				element: <Navigate to={`.${PATH_TRIALS}`} replace />,
			},
			{
				element: <ExperimentsPage />,
				children: [
					{
						path: removeStartingSlash(PATH_TRIALS),
						element: <TrialListPage />,
					},
					{
						path: removeStartingSlash(PATH_METS),
						element: <METListPage />,
					},
				],
			},
			metRouter(),
			trialRouter(),
		],
	};
}
