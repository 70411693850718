import { PlainObject } from "@mongodb-js/charts-embed-dom/dist/declarations/src/types";
import React, { useMemo, useState } from "react";

import {
	EChartAnalysisContext,
	EChartDashboardNetwork,
	IDashboardNetworkFilterMQL,
} from "shared-type";

import DashboardNetworkHeader from "../../../../components/dashboard/header/organization/DashboardNetworkHeader";
import { IDashboardNetworkHeaderFilter } from "../../../../components/dashboard/header/organization/types";
import GridChartRenderer from "../../../../components/dashboard/utils/GridChartRenderer";

import "./DashboardNetworkPage.scss";

export default function DashboardNetworkPage() {
	const [filterGlobal, setFilterGlobal] = useState<
		IDashboardNetworkHeaderFilter | undefined
	>(undefined);
	const graphFilter = useMemo(() => {
		if (filterGlobal === undefined) {
			return undefined;
		}
		const filters: Partial<IDashboardNetworkFilterMQL>[] = [];
		if (filterGlobal.countries && filterGlobal.countries.length > 0) {
			filters.push({
				"growingArea.country": { $in: filterGlobal.countries },
			});
		}
		if (filterGlobal.regions && filterGlobal.regions.length > 0) {
			filters.push({
				"growingArea.region": { $in: filterGlobal.regions },
			});
		}
		if (filterGlobal.expYears && filterGlobal.expYears.length > 0) {
			filters.push({ "trial.year": { $in: filterGlobal.expYears } });
		}
		if (filterGlobal.species && filterGlobal.species.length > 0) {
			filters.push({ "trial.species": { $in: filterGlobal.species } });
		}
		if (filters.length === 0) {
			return {};
		}
		return { $and: filters } as PlainObject;
	}, [filterGlobal]);

	const isFilterReady = graphFilter !== undefined;
	return (
		<>
			<DashboardNetworkHeader
				filterGlobal={filterGlobal}
				submitNewFilter={setFilterGlobal}
			/>
			<div className="DashboardOrganizationPage-body take-remaining-space">
				{isFilterReady && (
					<>
						<GridChartRenderer
							context={EChartAnalysisContext.DASHBOARD_NETWORK}
							identifier={EChartDashboardNetwork.MET_COUNT}
							graphFilter={graphFilter}
							gridRow={"span 2"}
							gridColumn={"span 1"}
						/>
						<GridChartRenderer
							context={EChartAnalysisContext.DASHBOARD_NETWORK}
							identifier={EChartDashboardNetwork.TRIAL_COUNT}
							graphFilter={graphFilter}
							className={"ChartSmallWidth"}
							gridRow={"span 2"}
							gridColumn={"span 1"}
						/>
						<GridChartRenderer
							context={EChartAnalysisContext.DASHBOARD_NETWORK}
							identifier={EChartDashboardNetwork.TRIAL_STATUS}
							graphFilter={graphFilter}
							className={"ChartMediumWidth"}
							gridRow={"span 2"}
							gridColumn={"span 2"}
						/>
						<GridChartRenderer
							context={EChartAnalysisContext.DASHBOARD_NETWORK}
							identifier={EChartDashboardNetwork.SPECIES_TABLE}
							graphFilter={graphFilter}
							className={"ChartLargeWidth"}
							gridRow={"span 3"}
							gridColumn={"span 4"}
						/>
						<GridChartRenderer
							context={EChartAnalysisContext.DASHBOARD_NETWORK}
							identifier={EChartDashboardNetwork.TRIAL_STATUS_GEO}
							graphFilter={graphFilter}
							className={"ChartLargeWidth"}
							gridRow={"span 4"}
							gridColumn={"span 4"}
						/>
						<GridChartRenderer
							context={EChartAnalysisContext.DASHBOARD_NETWORK}
							identifier={EChartDashboardNetwork.MET_TABLE}
							graphFilter={graphFilter}
							className={"ChartLargeWidth"}
							gridRow={"span 3"}
							gridColumn={"span 4"}
						/>
						<GridChartRenderer
							context={EChartAnalysisContext.DASHBOARD_NETWORK}
							identifier={EChartDashboardNetwork.GA_GEO}
							graphFilter={graphFilter}
							className={"ChartLargeWidth"}
							gridRow={"span 4"}
							gridColumn={"span 3"}
						/>
						<GridChartRenderer
							context={EChartAnalysisContext.DASHBOARD_NETWORK}
							identifier={
								EChartDashboardNetwork.OVERVIEW_LOCATION
							}
							graphFilter={graphFilter}
							className={"ChartLargeWidth"}
							gridRow={"span 4"}
							gridColumn={"span 5"}
						/>
					</>
				)}
			</div>
		</>
	);
}
