import React from "react";

import InputText from "App/components/base-components/input-text/InputText";
import {
	IDorianeFilter,
	IDorianeStringFilter,
} from "App/interfaces/filters/doriane-filter-interface";

export default function HeaderWithFilter(props: {
	filter: IDorianeFilter;
	label?: string;
	onFilterChange: (newFilter: IDorianeFilter) => void;
}) {
	switch (props.filter.type) {
		case "string": {
			return (
				<TextFilter
					filter={props.filter}
					onFilterChange={props.onFilterChange}
					label={props.label}
				/>
			);
		}
	}

	return (
		<InputText
			onChange={(event) =>
				props.onFilterChange({
					...props.filter,
					value: event.target.value || "",
				})
			}
			label={props.label}
			value={props.filter.value}
		/>
	);
}

export function TextFilter(props: {
	filter: IDorianeStringFilter;
	label?: string;
	onFilterChange: (newFilter: IDorianeStringFilter) => void;
}) {
	return (
		<InputText
			onChange={(event) =>
				props.onFilterChange({
					...props.filter,
					value: event.target.value || "",
				})
			}
			label={props.label}
			value={props.filter.value}
		/>
	);
}
