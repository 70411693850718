import React, { ReactNode } from "react";

import { Button, IButtonType } from "stories/base-components/Button/Button";
import { Icon } from "stories/base-components/Icon/Icon";
import { IIconType } from "stories/base-components/Icon/IconType";
import { Typography } from "stories/base-components/Typography/Typography";
import Colors from "stories/constants/Colors/Colors";
import { StoryTheme } from "stories/StoryTheme";

import "./infoMessage.scss";

type IInfoType = "info" | "warning" | "success" | "error";

export type IInfoMessageText = {
	children?: never;
	message: string;
};
export type IInfoMessageChildren = {
	children: ReactNode;
	message?: never;
};
export type IInfoMessage = (IInfoMessageText | IInfoMessageChildren) & {
	infoType: IInfoType;
	title?: string;
	btnLabel?: string;
	btnAction?: () => void;
};

const types = {
	info: {
		backgroundColor: Colors.active20,
		color: Colors.active70,
		iconName: "info--filled" as IIconType,
		btnType: "info",
	},
	warning: {
		backgroundColor: Colors.yellow30,
		color: Colors.yellow70,
		iconName: "warning-error--filled" as IIconType,
		btnType: "warning",
	},
	error: {
		backgroundColor: Colors.red10,
		color: Colors.red50,
		iconName: "warning-error--filled" as IIconType,
		btnType: "error",
	},
	success: {
		backgroundColor: Colors.accent20,
		color: Colors.accent70,
		iconName: "check-circle--filled" as IIconType,
		btnType: "primary",
	},
};

/**
 * A strip to inform the user. Can be of the following types : info, warning, error, success.
 * If a children is given, it will be rendered instead of the message.
 */
export const InfoMessage = (props: IInfoMessage) => {
	return (
		<StoryTheme>
			<div
				className="infoMessageContainer"
				style={{
					backgroundColor: types[props.infoType].backgroundColor,
				}}
			>
				<div className="infoMessageInnerContainer">
					<div className="infoMessageIconContainer">
						<Icon
							name={types[props.infoType].iconName}
							color={types[props.infoType].color}
						/>
					</div>
					<div>
						{props.title && (
							<Typography variant="heading2" text={props.title} />
						)}
						{props.children ? (
							props.children
						) : (
							<Typography text={props.message} />
						)}
					</div>
				</div>
				{props.btnLabel && (
					<Button
						text={props.btnLabel}
						onClick={props.btnAction}
						buttonType={
							types[props.infoType].btnType as IButtonType
						}
					/>
				)}
			</div>
		</StoryTheme>
	);
};
