import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { useMemo } from "react";

import {
	EntitySerializer,
	formatString,
	isObsRoundTaskSerialized,
} from "common";
import {
	ICreateObservationRound,
	IObservationRound,
	IObsRoundSerialized,
	IPatchObservationRound,
	ETaskType,
	ExperimentType,
} from "shared-type";

import { DIC, EDIC_KEY } from "../../dictionary";
import { useRequest } from "../../request-provider";
import { apiNotebook } from "../notebook/notebook.api";
import { defaultApiError, ReduxApiError } from "../utils/errors";
import { ExpGanttApiHelper } from "../experiment-statistics/exp-gantt.api";

export const apiObsRound = createApi({
	reducerPath: "api-obs-round",
	baseQuery: () => ({ data: undefined }),
	// global configuration for the api (in second)
	keepUnusedDataFor: 30,
	// global configuration for the api
	refetchOnMountOrArgChange: 30,
	tagTypes: ["Update", "Delete", "Create"],
	endpoints: (build) => ({
		getObservationRoundsByExpId: build.query<
			IObsRoundSerialized[],
			{ expId?: string; expType: ExperimentType }
		>({
			queryFn: async (args) => {
				if (!args.expId) {
					return { data: [] };
				}
				try {
					const result = await useRequest().opTask.getOpTasksByExpId(
						args.expId,
						args.expType,
						ETaskType.OBSERVATION_ROUND,
					);
					const obsRound = result.data.filter(
						isObsRoundTaskSerialized,
					);
					return {
						data: obsRound,
					};
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.FETCH).toLowerCase(),
							DIC(EDIC_KEY.OBSERVATION_ROUND).toLowerCase(),
						),
					);
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),

		getObservationRoundById: build.query<
			IObsRoundSerialized | undefined,
			string | undefined
		>({
			queryFn: async (id) => {
				try {
					if (!id) {
						return { data: undefined };
					}
					const result = await useRequest().opTask.getOpTaskById(id);
					const obsRound = result.data;

					if (!isObsRoundTaskSerialized(obsRound)) {
						throw new Error(
							`Invalid task type "${obsRound.type}": not an observation round`,
						);
					}

					return {
						data: obsRound,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch observation round with id : " + id,
					);
				}
			},
			providesTags: (result, error, id) => {
				return [
					{ type: "Delete", id: id },
					{ type: "Update", id: id },
					{ type: "Create", id: id },
				];
			},
		}),
		canDeleteObsRoundById: build.query<
			boolean | undefined,
			string | undefined
		>({
			queryFn: async (id) => {
				try {
					if (!id) {
						return { data: undefined };
					}
					const result =
						await useRequest().observationRound.canDeleteObsRoundById(
							id,
						);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch delete ability for observation round with id : " +
							id,
					);
				}
			},
			providesTags: (result, error, id) => {
				return [
					{ type: "Delete", id: id },
					{ type: "Update", id: id },
					{ type: "Create", id: id },
				];
			},
		}),
		createObsRound: build.mutation<
			IObsRoundSerialized,
			ICreateObservationRound
		>({
			queryFn: async (newObsRound) => {
				let id: string;

				try {
					id = await useRequest()
						.opTask.createOpTask(newObsRound)
						.then((resp) => resp.data);
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to create the observation round",
					);
				}
				try {
					const createdObsRound = await useRequest()
						.opTask.getOpTaskById(id)
						.then((resp) => resp.data);

					if (!isObsRoundTaskSerialized(createdObsRound)) {
						throw new Error(
							"Invalid task type : not an observation round",
						);
					}
					return { data: createdObsRound };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to get the observation round with id : " + id,
					);
				}
			},
			invalidatesTags: (result, error) => {
				if (error) {
					return [];
				}
				ExpGanttApiHelper.invalidateTags(["Get"]);
				return [
					{ type: "Create", id: result?._id },
					{ type: "Create", id: "ALL" },
				];
			},
		}),
		createManyObsRoundFromTemplates: build.mutation<
			string[],
			{ taskTemplateIds: string[]; trialId: string }
		>({
			queryFn: async (args) => {
				try {
					const ids = await useRequest()
						.opTask.createManyOpTaskFromTemplates(
							args.taskTemplateIds,
							args.trialId,
						)
						.then((resp) => resp.data);
					return { data: ids };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to create the observation rounds from templates",
					);
				}
			},
			invalidatesTags: (result, error) => {
				if (error) {
					return [];
				}
				ExpGanttApiHelper.invalidateTags(["Get"]);
				return [{ type: "Create", id: "ALL" }];
			},
		}),

		deleteObsRoundById: build.mutation<void, string | undefined>({
			queryFn: async (id) => {
				if (!id) {
					const error: { error: ReduxApiError } = {
						error: {
							message:
								"Id is required for delete observation round",
						},
					};
					return error;
				}

				try {
					const result = await useRequest().opTask.deleteOpTaskById(
						id,
					);
					return { data: result.data };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to delete observation round with id : " + id,
					);
				}
			},
			invalidatesTags: (result, error, arg) => {
				if (error) {
					return [];
				}
				ExpGanttApiHelper.invalidateTags(["Get"]);
				return [
					{ type: "Delete", id: arg },
					{ type: "Delete", id: "ALL" },
				];
			},
		}),

		updateObsRoundById: build.mutation<
			void,
			{ id: string; update: IPatchObservationRound }
		>({
			queryFn: async (arg) => {
				try {
					const result = await useRequest()
						.opTask.patchOpTaskById(arg.id, arg.update)
						.then((resp) => resp.data);
					return { data: result };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to update observation round",
					);
				}
			},
			invalidatesTags: (result, error, arg) => {
				if (error) {
					return [];
				}
				ExpGanttApiHelper.invalidateTags(["Get"]);
				return [
					{ type: "Update", id: arg.id },
					{ type: "Update", id: "ALL" },
				];
			},
		}),

		generateNotebooksByObsRoundId: build.mutation<string[], string>({
			queryFn: async (id, api) => {
				try {
					const result = await useRequest()
						.observationRound.generateNotebooksByObsRoundId(id)
						.then((resp) => resp.data);

					// invalidate notebook
					api.dispatch(
						apiNotebook.util.invalidateTags([
							{ type: "Create", id: "ALL" },
						]),
					);
					return { data: result };
				} catch (err) {
					return defaultApiError(err, "Unable to generate notebook");
				}
			},
			invalidatesTags: (result, error, id) => {
				if (error) {
					return [];
				}
				return [
					{ type: "Update", id: id },
					{ type: "Update", id: "ALL" },
				];
			},
		}),
	}),
});

const useGetObsRoundsByExpIdQueryDeserialized = (
	expType: ExperimentType,
	id?: string,
	option?: Parameters<
		typeof apiObsRound.useGetObservationRoundsByExpIdQuery
	>[1],
) => {
	const apiResult = apiObsRound.useGetObservationRoundsByExpIdQuery(
		{ expId: id, expType },
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		option as any,
	);
	const dataDeserialized = useMemo(() => {
		return apiResult.data
			? apiResult.data.map((obsRound: IObsRoundSerialized) =>
					EntitySerializer.deserialize<IObservationRound>(obsRound),
			  )
			: undefined;
	}, [apiResult.data]);
	return { ...apiResult, data: dataDeserialized };
};

export const obsRoundCustomHook = {
	useGetObsRoundsByExpIdQueryDeserialized,
};
