import { getter } from "@progress/kendo-react-common";
import { GridCustomCellProps } from "@progress/kendo-react-grid";
import React from "react";

import ChipListCell from "../../doriane-data-grid/doriane-data-grid/cell/ChipListCell";
import { BaseCell } from "./BaseCell";

export const KendoChipListCell = (props: GridCustomCellProps) => {
	const value: string[] = getter(props.field ?? "")(props.dataItem);

	return (
		<BaseCell {...props}>
			<ChipListCell
				items={value?.map((value: string) => {
					return { _id: value, name: value };
				})}
			></ChipListCell>
		</BaseCell>
	);
};
