import { Dayjs } from "dayjs";
import React from "react";
import { UseFormReturn, useWatch } from "react-hook-form";

import { EClimate, ESeason, ESoilType, IUser } from "shared-type";

import { FormMode } from "../../../const";
import { DIC, EDIC_KEY } from "../../../dictionary";
import {
	getDicClimate,
	getDicSeason,
	getDicSoilType,
} from "../../../dictionary/dictionary-key-getter";
import DorianeButton from "../../base-components/button/DorianeButton";
import { EFormInputType } from "../../base-components/dynamic-form";
import DynamicForm from "../../base-components/dynamic-form/DynamicForm";
import FormSection from "../../base-components/dynamic-form/form-section/FormSection";
import FormSectionTitle from "../../base-components/dynamic-form/form-section/FormSectionTitle";
import UserCard from "../../users/user-card/UserCard";

import "./GrowingAreaForm.scss";

export interface IGrowingAreaForm {
	name: string;
	identifier: string;
	label: string;
	description?: string;

	startDate: Dayjs;
	endDate?: Dayjs;
	season?: ESeason;

	country?: string;
	region?: string;
	latitude?: number;
	longitude?: number;

	climate?: EClimate[];
	soilType?: ESoilType[];
	soilDetails?: string;

	users: string[];
}

interface GrowingAreaFormProps {
	growingAreaUsers: IUser[];
	onSelectUserClick: () => void;
	mode: FormMode;
	form: UseFormReturn<IGrowingAreaForm>;
}

export function GrowingAreaForm(props: GrowingAreaFormProps) {
	const form = props.form;
	const startDate = useWatch({ control: form.control, name: "startDate" });

	return (
		<div className="GrowingAreaForm-container">
			<FormSection title={DIC(EDIC_KEY.IDENTIFICATION)}>
				<DynamicForm
					control={form.control}
					formInfos={[
						{
							inputType: EFormInputType.STRING,
							id: "name",
							label: DIC(EDIC_KEY.NAME),
							takeAllRow: true,
							required: true,
							disabled: props.mode === "used",
						},
						{
							inputType: EFormInputType.STRING,
							id: "identifier",
							label: DIC(EDIC_KEY.ID),
							required: true,
							disabled: props.mode !== "creation",
						},
						{
							inputType: EFormInputType.STRING,
							id: "label",
							label: DIC(EDIC_KEY.LABEL),
							disabled: props.mode === "used",
						},
						{
							inputType: EFormInputType.STRING,
							id: "description",
							label: DIC(EDIC_KEY.DESCRIPTION),
							multiline: true,
							takeAllRow: true,
							disabled: props.mode === "used",
						},
					]}
				/>
			</FormSection>
			<FormSection title={DIC(EDIC_KEY.PERIOD)}>
				<DynamicForm
					control={form.control}
					formInfos={[
						{
							inputType: EFormInputType.DATE,
							id: "startDate",
							label: DIC(EDIC_KEY.FROM),
							required: true,
							disabled: props.mode === "used",
						},
						{
							inputType: EFormInputType.DATE,
							id: "endDate",
							label: DIC(EDIC_KEY.TO),
							required: false,
							disabled: props.mode === "used",
							minDate: startDate,
						},
						{
							inputType: EFormInputType.COMBOBOX,
							id: "season",
							label: DIC(EDIC_KEY.SEASON),
							comboboxValues: Object.values(ESeason),
							translateMethod: getDicSeason,
							takeAllRow: true,
							disabled: props.mode === "used",
						},
					]}
				/>
			</FormSection>
			<FormSection title={DIC(EDIC_KEY.LOCATION)}>
				<DynamicForm
					control={form.control}
					formInfos={[
						{
							inputType: EFormInputType.STRING,
							id: "country",
							label: DIC(EDIC_KEY.COUNTRY),
						},
						{
							inputType: EFormInputType.STRING,
							id: "region",
							label: DIC(EDIC_KEY.REGION),
						},
						{
							inputType: EFormInputType.NUMBER,
							id: "latitude",
							label: DIC(EDIC_KEY.LATITUDE),
						},
						{
							inputType: EFormInputType.NUMBER,
							id: "longitude",
							label: DIC(EDIC_KEY.LONGITUDE),
						},
					]}
				/>
			</FormSection>
			<FormSection>
				<div className="GrowingAreaForm-title-container">
					<FormSectionTitle title={DIC(EDIC_KEY.TEAM)} />
					<DorianeButton
						dorianeStyle="tertiary"
						onClick={() => props.onSelectUserClick()}
					>
						{DIC(EDIC_KEY.EDIT_SELECTION)}
					</DorianeButton>
				</div>
				<h2>{DIC(EDIC_KEY.USERS)}</h2>
				<div className="GrowingAreaForm-user-list flex-column">
					{props.growingAreaUsers.map((user) => (
						<UserCard user={user} key={user.id} disabledClick />
					))}
				</div>
			</FormSection>
			<FormSection title={DIC(EDIC_KEY.AGRONOMIC_CONTEXT)}>
				<DynamicForm
					control={form.control}
					formInfos={[
						{
							inputType: EFormInputType.CHIP_COMBOBOX,
							id: "climate",
							label: DIC(EDIC_KEY.CLIMATE),
							takeAllRow: true,
							translateMethod: getDicClimate,
							comboboxValues: Object.values(EClimate),
						},
						{
							inputType: EFormInputType.CHIP_COMBOBOX,
							id: "soilType",
							label: DIC(EDIC_KEY.SOIL_TYPE),
							takeAllRow: true,
							translateMethod: getDicSoilType,
							comboboxValues: Object.values(ESoilType),
						},
						{
							inputType: EFormInputType.STRING,
							id: "soilDetails",
							label: DIC(EDIC_KEY.SOIL_DETAILS),
							takeAllRow: true,
							multiline: true,
						},
					]}
				/>
			</FormSection>
		</div>
	);
}
