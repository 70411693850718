"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESpecies = void 0;
var ESpecies;
(function (ESpecies) {
    ESpecies["WH"] = "wheat";
    ESpecies["CO"] = "corn";
    ESpecies["OSR"] = "oilseed rape";
    ESpecies["TO"] = "tomato";
    ESpecies["PE"] = "pepper";
    ESpecies["SUN"] = "sunflower";
    ESpecies["VI"] = "vine";
    ESpecies["APP"] = "apple trees";
    ESpecies["COF"] = "coffee";
})(ESpecies = exports.ESpecies || (exports.ESpecies = {}));
