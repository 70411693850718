import { yupResolver } from "@hookform/resolvers/yup";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { formatString } from "common";
import {
	ETaskStatus,
	ETaskType,
	ICreateObservationRound,
	IVariableCore,
} from "shared-type";

import { DIC, EDIC_KEY } from "../../../../dictionary";
import useAppSnackbar from "../../../../hooks/useAppSnackbar";
import { selectECW } from "../../../../redux/ECW/ECW.selector";
import { apiMET } from "../../../../redux/experiment/met/met.api";
import { apiGrowingArea } from "../../../../redux/growing-area/growing-area.api";
import { apiObsRound } from "../../../../redux/observation-round/obs-round.api";
import { templateObsEditionActions } from "../../../../redux/template-obs-edition/template-obs-edition.slice";
import { apiTemplateObs } from "../../../../redux/template-obs/template-obs.api";
import { apiUserCustomHook } from "../../../../redux/user/user.api";
import { ApiErrorSnackbar } from "../../../../redux/utils/api-error-snackbar/ApiErrorSnackbar";
import { ReduxApiError } from "../../../../redux/utils/errors";
import { variablesCustomHook } from "../../../../redux/variables/variables.api";
import { AppDispatch } from "../../../../store";
import TopMenu from "../../../base-components/menu/top-menu/TopMenu";
import DorianeDrawer from "../../../base-components/modal/DorianeDrawer";
import {
	IObservationRoundForm,
	obsRoundFormToObject,
	obsRoundValidator,
} from "../../../form/task/observation-round";
import ObservationRoundForm from "../../../form/task/observation-round/ObservationRoundForm";
import CreateVariableGroupModal from "../../../templates/experiment/variable-group/modal/create-variable-group/CreateVariableGroupModal";
import EditVariableGroupModal from "../../../templates/experiment/variable-group/modal/edit-variable-group/EditVariableGroupModal";
import SelectVariableGroupModal from "../../../templates/experiment/variable-group/modal/select-variable-group/SelectVariableGroupModal";
import { getVariableInTemplate } from "../../../templates/observation/TemplateObsEditionPage.utils";
import SelectUserTaskModal from "../../select-user/SelectUserObsRoundModal";

import "./CreationObsRoundModal.scss";

const defaultObservationRound: Partial<IObservationRoundForm> = {
	startDate: dayjs().startOf("day"),
	endDate: dayjs().startOf("day"),
	eventOption: true,
	team: [],
	status: ETaskStatus.DRAFT,
};

interface CreationObsRoundModalProps {
	onClose: () => void;
}

export default function CreationObsRoundModal(
	props: CreationObsRoundModalProps,
) {
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();
	const dispatch = useDispatch<AppDispatch>();
	const { metId } = useParams();

	const [isSelectUserOpen, setSelectUserOpen] = useState<boolean>(false);
	const [isVarGroupCreateOpen, setCreateVarGroupOpen] =
		useState<boolean>(false);
	const [isSelectVarGroupOpen, setSelectVarGroupOpen] =
		useState<boolean>(false);
	const [isConsultVarGroupOpen, setConsultVarGroupOpen] =
		useState<boolean>(false);

	const [createObsRound] = apiObsRound.useCreateObsRoundMutation();

	const remoteTrial = useSelector(selectECW.trialRemote);
	const { data: remoteMET } = apiMET.useGetMETByIdQuery(metId ?? skipToken);

	const experiment = useMemo(() => {
		if (remoteMET) return remoteMET;
		return remoteTrial;
	}, [remoteTrial, remoteMET]);

	const { data: templateObs } = apiTemplateObs.useGetTemplateObsByIdQuery(
		experiment?.templateObsId,
	);
	const { data: growingArea } = apiGrowingArea.useGetGrowingAreaByIdQuery(
		remoteTrial?.growingAreaId ?? "",
	);
	const { data: ontologyVariables } =
		variablesCustomHook.useGetOntologyVariableListDeserialized();

	const variableInTemplate: IVariableCore[] = getVariableInTemplate(
		templateObs,
		ontologyVariables,
	);

	const form = useForm<IObservationRoundForm>({
		defaultValues: defaultObservationRound,
		resolver: yupResolver(obsRoundValidator as any),
	});

	const { handleSubmit, formState, setValue } = form;
	const { isSubmitting } = formState;
	const [team, variableGroupId] = useWatch({
		control: form.control,
		name: ["team", "variableGroupId"],
	});
	const { data: fetchedObsRoundUsers } =
		apiUserCustomHook.useGetUsersByIds(team);

	useEffect(() => {
		return () => {
			setSelectUserOpen(false);
			setSelectVarGroupOpen(false);
			setCreateVarGroupOpen(false);
			setConsultVarGroupOpen(false);
			dispatch(templateObsEditionActions.resetTemplateObs);
		};
	}, [dispatch]);

	async function handleFormSubmit(data: IObservationRoundForm) {
		if (!experiment) return;

		const currentObsRoundEnhanced = {
			...obsRoundFormToObject(data),
			isTemplate: remoteMET !== undefined,
			experimentId: experiment._id,
			type: ETaskType.OBSERVATION_ROUND,
		} as ICreateObservationRound;

		await createObsRound(currentObsRoundEnhanced)
			.unwrap()
			.then(() => {
				setSelectUserOpen(false);
				props.onClose();
				enqueueSnackbarSuccess(
					formatString(
						DIC(EDIC_KEY.ENTITY_CREATED),
						DIC(EDIC_KEY.OBSERVATION_ROUND),
					),
				);
			})
			.catch((err: ReduxApiError) => {
				console.error(err);
				enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
			});
	}

	const handleCreate = async () => {
		handleSubmit(
			(data) => handleFormSubmit(data),
			(error) => {
				enqueueSnackbarError(DIC(EDIC_KEY.BAD_INPUT));
				console.error(error);
			},
		)();
	};

	return (
		<>
			<DorianeDrawer
				takeRemainingSpace
				onClose={props.onClose}
				title={formatString(
					DIC(EDIC_KEY.CREATE_ENTITY),
					(remoteMET !== undefined
						? DIC(EDIC_KEY.OBSERVATION_TEMPLATE)
						: DIC(EDIC_KEY.OBSERVATION_ROUND)
					).toLowerCase(),
				)}
			>
				<TopMenu
					createButton={{
						onClick: handleCreate,
						isLoading: isSubmitting,
					}}
				/>
				<div className="CreationObsRoundModal-form-container take-remaining-space">
					<div className="CreationObsRoundModal-form take-remaining-space">
						<ObservationRoundForm
							mode="creation"
							isTemplate={remoteMET !== undefined}
							form={form}
							obsRoundUsers={fetchedObsRoundUsers}
							onSelectUserClick={() => {
								setSelectUserOpen(true);
								setSelectVarGroupOpen(false);
								setConsultVarGroupOpen(false);
							}}
							onSelectVarGroupClick={() => {
								setSelectVarGroupOpen(true);
								setSelectUserOpen(false);
								setConsultVarGroupOpen(false);
							}}
							onVarGroupClick={() => {
								setConsultVarGroupOpen(true);
								setSelectVarGroupOpen(false);
								setSelectUserOpen(false);
							}}
						/>
					</div>
				</div>
			</DorianeDrawer>
			{remoteTrial && isSelectUserOpen && (
				<SelectUserTaskModal
					currentUserIds={team}
					onUserCheck={(clickedUser, checked) => {
						if (checked) {
							setValue("team", [...team, clickedUser.id]);
						} else {
							const newUserList = team.filter(
								(userId) => userId !== clickedUser.id,
							);
							setValue("team", [...newUserList]);
						}
					}}
					onClose={() => setSelectUserOpen(false)}
					trialOwnerId={remoteTrial.systemMetadata.createdBy ?? ""}
					growingAreaUsersIds={growingArea?.users ?? []}
				/>
			)}
			{isSelectVarGroupOpen && templateObs && (
				<SelectVariableGroupModal
					templateObsId={templateObs._id}
					onVariableGroupSelect={(varGroupId) => {
						setValue("variableGroupId", varGroupId);
					}}
					onVarGroupCreateClick={() => {
						setCreateVarGroupOpen(true);
						setSelectVarGroupOpen(false);
						setSelectUserOpen(false);
					}}
					onClose={() => {
						setSelectVarGroupOpen(false);
						setCreateVarGroupOpen(false);
					}}
					selectedGroupIds={[variableGroupId]}
				/>
			)}
			{variableGroupId && (
				<EditVariableGroupModal
					onClose={() => {
						setConsultVarGroupOpen(false);
					}}
					templateObservation={templateObs}
					variableInTemplate={variableInTemplate}
					varGroupId={variableGroupId}
					isEditionDisabled={true}
					open={isConsultVarGroupOpen}
				/>
			)}
			{templateObs && (
				<CreateVariableGroupModal
					onClose={() => setCreateVarGroupOpen(false)}
					templateObservation={templateObs}
					variableInTemplate={variableInTemplate}
					onVariableGroupCreated={(varGroupId) => {
						setValue("variableGroupId", varGroupId);
					}}
					open={isVarGroupCreateOpen}
				/>
			)}
		</>
	);
}
