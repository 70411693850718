import React from "react";

import { IColumn } from "../doriane-data-grid/DorianeDataGrid";
import { Checkbox } from "stories/base-components/Checkbox/Checkbox";

import "./CheckBoxColumn.scss";

export function CheckBoxColumn<Row>(
	props: {
		isCheck?: (row: Row) => boolean;
		onCheck?: (row: Row, check: boolean) => void;
		isDisabled?: (row: Row) => boolean | undefined;
		headerName?: string;
		width?: string;
		headerCheckAllAction?: (selected: boolean) => void;
	},
	id = "checkbox" + props.headerName,
): IColumn<Row> {
	return {
		id: id,
		getHeaderRenderer: props.headerCheckAllAction ? (
			<Checkbox
				onChange={(event, checked) => {
					if (props.headerCheckAllAction) {
						props.headerCheckAllAction(checked);
					}
				}}
			/>
		) : (
			props.headerName ?? ""
		),
		getCellRenderer: (row: Row) => (
			<div className="CheckBoxColumn-cell">
				<Checkbox
					disabled={props.isDisabled && props.isDisabled(row)}
					checked={props.isCheck && props.isCheck(row)}
					onChange={(event, checked) =>
						props.onCheck && props.onCheck(row, checked)
					}
				/>
			</div>
		),
		width: props.width ?? "50px",
	};
}
