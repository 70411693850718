import { RootState } from "App/store";

const authenticationState = (state: RootState) => state.authentication;

const user = (state: RootState) => {
	return state.authentication.user;
};

const currentTenantId = (state: RootState) => {
	return state.authentication.tenantId;
};
const getAuthUserId = (state: RootState) => {
	return state.authentication.user?.id;
};
export const selectAuth = {
	authenticationState,
	user,
	currentTenantId,
	getAuthUserId,
};
