import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import {
	ICreateResource,
	IFilter,
	IResource,
	ISort,
	IUpdateResource,
	PaginateResponse,
} from "shared-type";

import { AbstractRequest } from "../abstract-request";

export class ResourceRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/resource";
	}

	createResource(
		createResource: ICreateResource
	): Promise<AxiosResponse<string, any>> {
		return this.axios.post<string>(this.baseUrl, createResource);
	}

	update(
		id: string,
		resource: IUpdateResource
	): Promise<AxiosResponse<void, any>> {
		return this.axios.patch<void, AxiosResponse<void, any>>(
			this.baseUrl + "/" + id,
			resource
		);
	}

	getResources(
		page?: number,
		pageSize?: number,
		filter?: IFilter,
		sort?: ISort,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<PaginateResponse<IResource>, any>> {
		return this.axios.get<PaginateResponse<IResource>>(this.baseUrl, {
			...config,
			params: {
				...config?.params,
				page: JSON.stringify(page),
				pageSize: JSON.stringify(pageSize),
				filter: JSON.stringify(filter),
				sort: JSON.stringify(sort),
			},
		});
	}

	getResourceById(
		id: string,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<IResource, any>> {
		return this.axios.get<IResource>(this.baseUrl + "/" + id, config);
	}

	deleteResourceById(id: string): Promise<AxiosResponse<void, any>> {
		return this.axios.delete<void>(this.baseUrl + "/" + id);
	}
}
