import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { EntitySerializer } from "common";
import {
	ESubjectType,
	IGenotype,
	ILot,
	INotebook,
	IObsRoundSerialized,
	ITrial,
	IUser,
	IVariableCoreSerialized,
	IVariableGroupDetailed,
} from "shared-type";

import { experimentReviewNotationAction } from "../../../../../../redux/experiment-review-notation/experiment-review-notation.slice";
import { apiGermplasm } from "../../../../../../redux/germplasm/germplasm.api";
import { apiNotation } from "../../../../../../redux/notation/notation.api";
import { apiNotebook } from "../../../../../../redux/notebook/notebook.api";
import { apiObsRound } from "../../../../../../redux/observation-round/obs-round.api";
import { apiUser } from "../../../../../../redux/user/user.api";
import { apiVariableGroup } from "../../../../../../redux/variable-group/variable-group.api";
import { apiVariables } from "../../../../../../redux/variables/variables.api";
import { extractSubjectOfTrial } from "../utils/converter";

export function useFetchDatagridData(trial?: ITrial) {
	const dispatch = useDispatch();

	// fetch obs rounds
	const obsRoundsRequest = apiObsRound.useGetObservationRoundsByExpIdQuery({
		expId: trial?._id,
		expType: "Trial",
	});
	const obsRoundsMap = useMemo(() => {
		const map = new Map<string, IObsRoundSerialized>(
			obsRoundsRequest.data?.map((obsRound) => [obsRound._id, obsRound]),
		);
		return map;
	}, [obsRoundsRequest.data]);

	// obs round variables groups
	const variablesGroupsRequest =
		apiVariableGroup.useGetVariableGroupDetailsByIdsQuery(
			obsRoundsRequest.data?.map(
				(obsRound) => obsRound.variableGroupId,
			) || [],
		);
	const variablesGroupsMap = useMemo(() => {
		const map = new Map<string, IVariableGroupDetailed>(
			variablesGroupsRequest.data?.map((varGroup) => [
				varGroup._id,
				EntitySerializer.deserialize<IVariableGroupDetailed>(varGroup),
			]),
		);
		return map;
	}, [variablesGroupsRequest.data]);

	// fetch notebooks
	const notebooksRequest = apiNotebook.useGetNotebooksByTrialIdQuery(
		trial?._id,
	);

	// fetch notations
	const notationsRequest = apiNotation.useGetNotationByTrialIdQuery(
		trial?._id,
	);

	// fetch variables
	const variablesRequest = apiVariables.useGetAllVariablesQuery();
	const variablesMap = useMemo(() => {
		const map = new Map<string, IVariableCoreSerialized>(
			variablesRequest.data?.map((variable) => [
				`${variable.scope}:${variable._id}`,
				variable,
			]),
		);
		return map;
	}, [variablesRequest.data]);

	// fetch genotype and lot for trial
	const subjectToObserve = useMemo(() => {
		return trial ? extractSubjectOfTrial(trial) : [];
	}, [trial]);

	// lots
	const lotIdsObserved = useMemo(() => {
		return subjectToObserve
			?.filter((elt) => elt.subjectType === ESubjectType.MATERIAL_LOT)
			.map((elt) => elt.subjectId);
	}, [subjectToObserve]);
	const lotsRequest = apiGermplasm.useGetLotByIdsQuery(lotIdsObserved);
	const lotsMap = useMemo(() => {
		const map: Map<string, ILot> = new Map(
			lotsRequest.data?.map((elt) => [elt._id, elt]),
		);
		return map;
	}, [lotsRequest.data]);

	// genotypes
	const genotypeIdsObserved = useMemo(() => {
		return subjectToObserve
			?.filter(
				(elt) => elt.subjectType === ESubjectType.MATERIAL_GENOTYPE,
			)
			.map((elt) => elt.subjectId);
	}, [subjectToObserve]);
	const genotypesRequest =
		apiGermplasm.useGetGenotypesByIdsQuery(genotypeIdsObserved);
	const genotypesMap = useMemo(() => {
		const map: Map<string, IGenotype> = new Map(
			genotypesRequest.data?.map((elt) => [elt._id, elt]),
		);
		return map;
	}, [genotypesRequest.data]);

	// fetch users
	const usersRequest = apiUser.useGetUsersQuery();
	const userMap = useMemo(
		() =>
			new Map<string, IUser>(
				usersRequest.data?.map((user) => [user.id, user]),
			),
		[usersRequest.data],
	);

	// status merge
	const status = useMemo(() => {
		const requests = [
			obsRoundsRequest,
			notebooksRequest,
			notationsRequest,
			variablesRequest,
			genotypesRequest,
			lotsRequest,
			usersRequest,
			variablesGroupsRequest,
		];
		return {
			isFetching: requests.reduce(
				(acc, elt) => acc || elt.isFetching,
				false,
			),
			isError: requests.reduce((acc, elt) => acc || elt.isError, false),
			isSuccess: requests.reduce(
				(acc, elt) => acc && elt.isSuccess,
				true,
			),
			isLoading: requests.reduce(
				(acc, elt) => acc || elt.isLoading,
				false,
			),
			isUninitialized: requests.reduce(
				(acc, elt) => acc || elt.isUninitialized,
				false,
			),
		};
	}, [
		obsRoundsRequest,
		notebooksRequest,
		notationsRequest,
		variablesRequest,
		genotypesRequest,
		lotsRequest,
		usersRequest,
		variablesGroupsRequest,
	]);

	useEffect(() => {
		dispatch(
			experimentReviewNotationAction.computeVariablesListByLevel(
				variablesGroupsRequest.data?.map(
					(elt) => elt.variableByLevel,
				) || [],
			),
		);
	}, [dispatch, variablesGroupsRequest]);
	return {
		observationRounds: obsRoundsRequest.data,
		observationRoundsMap: obsRoundsMap,
		notebooks: notebooksRequest.data?.map((notebook) =>
			EntitySerializer.deserialize<INotebook>(notebook),
		),
		notations: notationsRequest.data,
		variablesGroups: variablesGroupsRequest.data,
		variablesMap,
		lotsMap,
		genotypesMap,
		variablesGroupsMap,
		usersRequest,
		userMap,
		status,
	};
}
