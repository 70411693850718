"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ETemplateExpStructCrossTypeCategory = exports.ETemplateExpStructCrossOption = exports.ETemplateExpStructDesign = exports.ETemplateExpStructCropCycle = exports.ETemplateExpStructLocation = exports.ETemplateExpStructType = exports.ETemplateExpStructScope = void 0;
var ETemplateExpStructScope;
(function (ETemplateExpStructScope) {
    ETemplateExpStructScope["VARIETAL"] = "varietal";
    ETemplateExpStructScope["PRODUCT"] = "product";
    ETemplateExpStructScope["OTHER"] = "other";
})(ETemplateExpStructScope = exports.ETemplateExpStructScope || (exports.ETemplateExpStructScope = {}));
var ETemplateExpStructType;
(function (ETemplateExpStructType) {
    ETemplateExpStructType["TESTING"] = "testing";
    ETemplateExpStructType["BREEDING"] = "breeding";
    ETemplateExpStructType["CROSSING_BLOCKS"] = "crossing-blocks";
    ETemplateExpStructType["OTHER"] = "other";
})(ETemplateExpStructType = exports.ETemplateExpStructType || (exports.ETemplateExpStructType = {}));
var ETemplateExpStructLocation;
(function (ETemplateExpStructLocation) {
    ETemplateExpStructLocation["SINGLE"] = "single";
    ETemplateExpStructLocation["MULTI"] = "multi";
})(ETemplateExpStructLocation = exports.ETemplateExpStructLocation || (exports.ETemplateExpStructLocation = {}));
var ETemplateExpStructCropCycle;
(function (ETemplateExpStructCropCycle) {
    ETemplateExpStructCropCycle["SINGLE"] = "single";
    ETemplateExpStructCropCycle["MULTI"] = "multi";
    ETemplateExpStructCropCycle["MULTI_ROTATIONS"] = "multi-rotations";
})(ETemplateExpStructCropCycle = exports.ETemplateExpStructCropCycle || (exports.ETemplateExpStructCropCycle = {}));
var ETemplateExpStructDesign;
(function (ETemplateExpStructDesign) {
    ETemplateExpStructDesign["ONE_REP_NO_CONTROL"] = "one-rep-no-control";
    ETemplateExpStructDesign["ONE_REP_CONTROL"] = "one-rep-control";
    ETemplateExpStructDesign["MULTI_REP"] = "multi-rep";
    ETemplateExpStructDesign["COMPLEX"] = "complex";
    ETemplateExpStructDesign["MULTI_FACTOR"] = "multi-factor";
})(ETemplateExpStructDesign = exports.ETemplateExpStructDesign || (exports.ETemplateExpStructDesign = {}));
var ETemplateExpStructCrossOption;
(function (ETemplateExpStructCrossOption) {
    ETemplateExpStructCrossOption["NONE"] = "none";
    ETemplateExpStructCrossOption["SINGLE"] = "single";
    ETemplateExpStructCrossOption["MULTI"] = "multi";
})(ETemplateExpStructCrossOption = exports.ETemplateExpStructCrossOption || (exports.ETemplateExpStructCrossOption = {}));
var ETemplateExpStructCrossTypeCategory;
(function (ETemplateExpStructCrossTypeCategory) {
    ETemplateExpStructCrossTypeCategory["SELFING"] = "selfing";
    ETemplateExpStructCrossTypeCategory["CROSS"] = "cross";
    ETemplateExpStructCrossTypeCategory["HYBRID"] = "hybrid";
    ETemplateExpStructCrossTypeCategory["BACKCROSS"] = "backcross";
    ETemplateExpStructCrossTypeCategory["NONE"] = "none";
})(ETemplateExpStructCrossTypeCategory = exports.ETemplateExpStructCrossTypeCategory || (exports.ETemplateExpStructCrossTypeCategory = {}));
