import { DIC } from "./dictionary";
import { EDIC_KEY } from "./dictionary-key.enum";

// #region ---------------- Trial ----------------------------
import {
	EExperimentProgram,
	EExperimentProject,
	EExperimentStatus,
	EMultiExpectationType,
	ECurrency,
	EYesNo,
} from "shared-type";

export function getDicExpStatus(enumItem?: EExperimentStatus) {
	if (enumItem === undefined) return "_";
	switch (enumItem) {
		case EExperimentStatus.VALIDATED:
			return DIC(EDIC_KEY.VALIDATED);
		case EExperimentStatus.NOT_VALIDATED:
			return DIC(EDIC_KEY.NOT_VALIDATED);
		default:
			return "MISSING_KEY";
	}
}

export function getDicExpProgram(enumItem?: EExperimentProgram) {
	if (enumItem === undefined) return "_";
	switch (enumItem) {
		case EExperimentProgram.BREEDING:
			return DIC(EDIC_KEY.BREEDING);
		case EExperimentProgram.TESTING:
			return DIC(EDIC_KEY.TESTING);
		case EExperimentProgram.PRODUCT_DEV:
			return DIC(EDIC_KEY.PRODUCT_DEV);
		default:
			return "MISSING_KEY";
	}
}

export function getDicExpProject(enumItem?: EExperimentProject) {
	if (enumItem === undefined) return "_";
	switch (enumItem) {
		case EExperimentProject.DISEASE:
			return DIC(EDIC_KEY.DISEASE_RESISTANCE);
		case EExperimentProject.DROUGHT:
			return DIC(EDIC_KEY.DROUGHT_TOLERANCE);
		case EExperimentProject.YIELD:
			return DIC(EDIC_KEY.YIELD_INCREASE);
		default:
			return "MISSING_KEY";
	}
}
// #endregion

// #region --------------- I18N Users ---------------------------
import { ETrialLevel, EUserRole } from "shared-type";

export function getDicUserRole(enumItem?: EUserRole) {
	if (enumItem === undefined) return "_";
	switch (enumItem) {
		case EUserRole.ADMIN:
			return `${DIC(EDIC_KEY.WEB)}/${DIC(EDIC_KEY.MOBILE)}`;
		case EUserRole.OBSERVER:
			return DIC(EDIC_KEY.MOBILE);
		default:
			return "MISSING_KEY";
	}
}

// #endregion

// #region --------------- I18N Germplasm part ------------------------------
import { EGermplasmLevel, EMaterialSource, EMaterialType } from "shared-type";

export function getDicGermplasmType(type: EGermplasmLevel): string {
	switch (type) {
		case EGermplasmLevel.GENOTYPE:
			return `${DIC(EDIC_KEY.GENOTYPES)} / ${DIC(EDIC_KEY.VARIETIES)}`;
		case EGermplasmLevel.LOT:
			return DIC(EDIC_KEY.LOT);
		default:
			return "MISSING_KEY";
	}
}
export function getDicGermplasmSource(source: EMaterialSource): string {
	switch (source) {
		case EMaterialSource.PRIVATE:
			return DIC(EDIC_KEY.PRIVATE);
		case EMaterialSource.GERMPLASM:
			return DIC(EDIC_KEY.GERMPLASM);
		default:
			return "MISSING_KEY";
	}
}

// Genotype
export function getDicGenotypeCrossType(type: EMaterialType): string {
	switch (type) {
		case EMaterialType.SY:
			return DIC(EDIC_KEY.SY);
		case EMaterialType.CR:
			return DIC(EDIC_KEY.CR);
		case EMaterialType.HY:
			return DIC(EDIC_KEY.HY);
		case EMaterialType.BC:
			return DIC(EDIC_KEY.BC);
		case EMaterialType.DH:
			return DIC(EDIC_KEY.DH);
		case EMaterialType.MX:
			return DIC(EDIC_KEY.MX);
		case EMaterialType.LI:
			return DIC(EDIC_KEY.LI);
		case EMaterialType.FL:
			return DIC(EDIC_KEY.FL);
		case EMaterialType.PF:
			return DIC(EDIC_KEY.PF);
		default:
			return "MISSING_KEY";
	}
}

export function getDicTrialLevel(lvl: ETrialLevel) {
	switch (lvl) {
		case ETrialLevel.MATERIAL:
			return DIC(EDIC_KEY.MATERIAL);
		case ETrialLevel.TRIAL:
			return DIC(EDIC_KEY.TRIAL);
		default:
			return "MISSING_KEY";
	}
}

// #endregion

// #region --------------- I18N Variable part -------------------------------
import { EVariableCoreType, EMultiComputeMethod } from "shared-type";

export function getDicVarType(enumItem: EVariableCoreType) {
	switch (enumItem) {
		case EVariableCoreType.DATETIME:
			return DIC(EDIC_KEY.DATETIME);
		case EVariableCoreType.DATE:
			return DIC(EDIC_KEY.DATE);
		case EVariableCoreType.BOOLEAN:
			return DIC(EDIC_KEY.BOOLEAN);
		case EVariableCoreType.DEC:
			return DIC(EDIC_KEY.DEC);
		case EVariableCoreType.INT:
			return DIC(EDIC_KEY.INT);
		case EVariableCoreType.STRING:
			return DIC(EDIC_KEY.STRING);
		case EVariableCoreType.PICTURE:
			return DIC(EDIC_KEY.PICTURE);
		default:
			return "MISSING_KEY";
	}
}

export function getDicVarMultiComputeMethod(enumItem: EMultiComputeMethod) {
	switch (enumItem) {
		case EMultiComputeMethod.AVERAGE:
			return DIC(EDIC_KEY.AVERAGE);
		case EMultiComputeMethod.COUNT:
			return DIC(EDIC_KEY.COUNT);
		case EMultiComputeMethod.MAX:
			return DIC(EDIC_KEY.MAX);
		case EMultiComputeMethod.MEDIAN:
			return DIC(EDIC_KEY.MEDIAN);
		case EMultiComputeMethod.MIN:
			return DIC(EDIC_KEY.MIN);
		case EMultiComputeMethod.STANDARD_DEV:
			return DIC(EDIC_KEY.STANDARD_DEV);
		case EMultiComputeMethod.SUM:
			return DIC(EDIC_KEY.SUM);
		default:
			return "MISSING_KEY";
	}
}

export function getDicVarMultiExpectation(enumItem: EMultiExpectationType) {
	switch (enumItem) {
		case EMultiExpectationType.FIXED:
			return DIC(EDIC_KEY.FIXED);
		case EMultiExpectationType.FREE:
			return DIC(EDIC_KEY.FREE);
		case EMultiExpectationType.MINIMUM:
			return DIC(EDIC_KEY.MINIMUM);

		default:
			return "MISSING_KEY";
	}
}

// #endregion

// #region --------------- I18N Obs Template part ---------------------------
import { ESpecies } from "shared-type";

export function getDicSpecies(enumItem: ESpecies) {
	switch (enumItem) {
		case ESpecies.CO:
			return DIC(EDIC_KEY.CORN);
		case ESpecies.OSR:
			return DIC(EDIC_KEY.OILSEED_RAPE);
		case ESpecies.PE:
			return DIC(EDIC_KEY.PEPPER);
		case ESpecies.TO:
			return DIC(EDIC_KEY.TOMATO_SPECIES);
		case ESpecies.WH:
			return DIC(EDIC_KEY.WHEAT);
		case ESpecies.SUN:
			return DIC(EDIC_KEY.SUNFLOWER);
		case ESpecies.COF:
			return DIC(EDIC_KEY.COFFEE);
		case ESpecies.APP:
			return DIC(EDIC_KEY.APPLE_TREE);
		case ESpecies.VI:
			return DIC(EDIC_KEY.VINE);
		default:
			return "MISSING_KEY";
	}
}
// #endregion

// #region --------------- I18N Exp Template part ---------------------------
import {
	ETemplateExpStructCropCycle,
	ETemplateExpStructCrossOption,
	ETemplateExpStructCrossTypeCategory,
	ETemplateExpStructDesign,
	ETemplateExpStructLocation,
	ETemplateExpStructScope,
	ETemplateExpStructType,
} from "shared-type";

export function getDicTESScope(enumItem: ETemplateExpStructScope) {
	switch (enumItem) {
		case ETemplateExpStructScope.VARIETAL:
			return DIC(EDIC_KEY.VARIETAL);
		case ETemplateExpStructScope.PRODUCT:
			return DIC(EDIC_KEY.PRODUCT);
		case ETemplateExpStructScope.OTHER:
			return DIC(EDIC_KEY.OTHER);

		default:
			return "MISSING_KEY";
	}
}

export function getDicTESType(enumItem: ETemplateExpStructType) {
	switch (enumItem) {
		case ETemplateExpStructType.TESTING:
			return DIC(EDIC_KEY.TESTING);
		case ETemplateExpStructType.BREEDING:
			return DIC(EDIC_KEY.BREEDING);
		case ETemplateExpStructType.CROSSING_BLOCKS:
			return DIC(EDIC_KEY.CROSSING_BLOCKS);
		case ETemplateExpStructType.OTHER:
			return DIC(EDIC_KEY.OTHER);

		default:
			return "MISSING_KEY";
	}
}

export function getDicTESLocation(enumItem: ETemplateExpStructLocation) {
	switch (enumItem) {
		case ETemplateExpStructLocation.SINGLE:
			return DIC(EDIC_KEY.SINGLE);
		case ETemplateExpStructLocation.MULTI:
			return DIC(EDIC_KEY.MULTI);

		default:
			return "MISSING_KEY";
	}
}

export function getDicTESCropCycle(enumItem: ETemplateExpStructCropCycle) {
	switch (enumItem) {
		case ETemplateExpStructCropCycle.SINGLE:
			return DIC(EDIC_KEY.SINGLE);
		case ETemplateExpStructCropCycle.MULTI:
			return DIC(EDIC_KEY.MULTI);
		case ETemplateExpStructCropCycle.MULTI_ROTATIONS:
			return DIC(EDIC_KEY.MULTI_ROTATIONS);

		default:
			return "MISSING_KEY";
	}
}

export function getDicTESDesign(enumItem: ETemplateExpStructDesign) {
	switch (enumItem) {
		case ETemplateExpStructDesign.ONE_REP_NO_CONTROL:
			return DIC(EDIC_KEY.ONE_REP_NO_CONTROL);
		case ETemplateExpStructDesign.ONE_REP_CONTROL:
			return DIC(EDIC_KEY.ONE_REP_CONTROL);
		case ETemplateExpStructDesign.MULTI_REP:
			return DIC(EDIC_KEY.MULTI_REP);
		case ETemplateExpStructDesign.COMPLEX:
			return DIC(EDIC_KEY.COMPLEX);
		case ETemplateExpStructDesign.MULTI_FACTOR:
			return DIC(EDIC_KEY.MULTI_FACTOR);

		default:
			return "MISSING_KEY";
	}
}

export function getDicTESCrossOption(enumItem: ETemplateExpStructCrossOption) {
	switch (enumItem) {
		case ETemplateExpStructCrossOption.NONE:
			return DIC(EDIC_KEY.NONE);
		case ETemplateExpStructCrossOption.SINGLE:
			return DIC(EDIC_KEY.SINGLE);
		case ETemplateExpStructCrossOption.MULTI:
			return DIC(EDIC_KEY.MULTI);

		default:
			return "MISSING_KEY";
	}
}

export function getDicTESCrossType(
	enumItem: ETemplateExpStructCrossTypeCategory,
) {
	switch (enumItem) {
		case ETemplateExpStructCrossTypeCategory.SELFING:
			return DIC(EDIC_KEY.SELFING);
		case ETemplateExpStructCrossTypeCategory.CROSS:
			return DIC(EDIC_KEY.CROSS);
		case ETemplateExpStructCrossTypeCategory.HYBRID:
			return DIC(EDIC_KEY.HYBRID);
		case ETemplateExpStructCrossTypeCategory.BACKCROSS:
			return DIC(EDIC_KEY.BACKCROSS);

		default:
			return "MISSING_KEY";
	}
}
// #endregion

// #region --------------- I18N Growing area ---------------------------
import { ESeason, EClimate, ESoilType } from "shared-type";

export function getDicSeason(enumItem?: ESeason) {
	if (enumItem === undefined) return "_";
	switch (enumItem) {
		case ESeason.AUTUMN:
			return DIC(EDIC_KEY.AUTUMN);
		case ESeason.SPRING:
			return DIC(EDIC_KEY.SPRING);
		case ESeason.SUMMER:
			return DIC(EDIC_KEY.SUMMER);
		case ESeason.WINTER:
			return DIC(EDIC_KEY.WINTER);
		default:
			return "MISSING_KEY";
	}
}

export function getDicClimate(enumItem?: EClimate) {
	if (enumItem === undefined) return "_";
	switch (enumItem) {
		case EClimate.CONTINENTAL:
			return DIC(EDIC_KEY.CONTINENTAL);
		case EClimate.DRY:
			return DIC(EDIC_KEY.DRY);
		case EClimate.POLAR:
			return DIC(EDIC_KEY.POLAR);
		case EClimate.TEMPERATE:
			return DIC(EDIC_KEY.TEMPERATE);
		case EClimate.TROPICAL:
			return DIC(EDIC_KEY.TROPICAL);
		default:
			return "MISSING_KEY";
	}
}

export function getDicSoilType(enumItem?: ESoilType) {
	if (enumItem === undefined) return "_";
	switch (enumItem) {
		case ESoilType.CLAY:
			return DIC(EDIC_KEY.CLAY);
		case ESoilType.LOAMY:
			return DIC(EDIC_KEY.LOAMY);
		case ESoilType.SANDY:
			return DIC(EDIC_KEY.SANDY);
		case ESoilType.SILT:
			return DIC(EDIC_KEY.SILT);
		default:
			return "MISSING_KEY";
	}
}
// #endregion

// #region --------------- OTHER ---------------------------
export function getDicBoolean(value?: boolean) {
	if (value === undefined || value === null) {
		return "";
	}
	return value ? DIC(EDIC_KEY.YES) : DIC(EDIC_KEY.NO);
}

// #endregion

export function getDicCurrency(enumItem: ECurrency) {
	switch (enumItem) {
		case ECurrency.EURO:
			return DIC(EDIC_KEY.EURO);
		case ECurrency.DOLLAR:
			return DIC(EDIC_KEY.DOLLAR);
		case ECurrency.OTHERS:
			return DIC(EDIC_KEY.OTHERS);
		default:
			return "MISSING_KEY";
	}
}

export function getDicYEsNo(enumItem: EYesNo) {
	switch (enumItem) {
		case EYesNo.YES:
			return DIC(EDIC_KEY.YES);
		case EYesNo.NO:
			return DIC(EDIC_KEY.NO);
		default:
			return "MISSING_KEY";
	}
}