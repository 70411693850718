import React from "react";

import { IGrowingArea } from "shared-type";
import { Field } from "../../../../assets";
import ImgTag from "../../base-components/tag/img-tag/ImgTag";

interface IGrowingAreaTagListProps {
	growingAreas: Pick<IGrowingArea, "_id" | "name">[];
}

export default function GrowingAreaTagList(props: IGrowingAreaTagListProps) {
	return (
		<>
			{props.growingAreas.map((elt) => (
				<ImgTag key={elt._id} imageSrc={Field} tagValue={elt.name} />
			))}
		</>
	);
}
