import React from "react";

import { IResource } from "shared-type";

import PictureBanner from "./PictureBanner";

import "./PictureCard.scss";

export interface IPictureCardProps {
	resource: IResource;
	onClick?: () => void;
	disabledClick?: boolean;
	onDelete?: () => void;
}
export const PictureCardSize = { width: 256, height: 260 };
export default function PictureCard(props: IPictureCardProps) {
	const enhancedProps = {
		...props,
		className: "PictureCard",
	};

	return <PictureBanner {...enhancedProps} />;
}
