import { styled, Tabs, TabsProps } from "@mui/material";
import React from "react";

import styles from "sassTheme";

import "./TabStyle.scss";

export const CustomTabs = styled((props: TabsProps) => (
	<Tabs
		className={"CustomLinkTab"}
		{...props}
		TabIndicatorProps={{
			children: <span className="MuiTabs-indicatorSpan" />,
		}}
		style={{
			height: styles["element-min-height"],
			minHeight: styles["element-min-height"],
		}}
	/>
))(({ theme }) => ({
	"& .MuiTabs-indicator": {
		display: "flex",
		justifyContent: "center",
		height: "4px",
	},
	"& .MuiTabs-indicatorSpan": {
		width: "100%",
		backgroundColor: theme.palette.secondary.main,
	},
	"& .MuiTab-root.Mui-selected": {
		color: styles.secondary,
	},
}));
