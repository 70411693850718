import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { CustomLinkTab } from "../../../../../../components/base-components/tabs/CustomLinkTab";
import { CustomTabs } from "../../../../../../components/base-components/tabs/CustomTabs";
import { DIC, EDIC_KEY } from "../../../../../../dictionary";
import { useAppDispatch } from "../../../../../../hooks/reduxHook";
import { selectECW } from "../../../../../../redux/ECW/ECW.selector";
import { ECWAction } from "../../../../../../redux/ECW/ECW.slice";
import {
	PATH_ANALYSIS,
	PATH_OVERVIEW,
	PATH_PICTURE,
	PATH_VALIDATION,
} from "../../../../../../routes";

import "./TrialDataPage.scss";

export default function TrialDataPage() {
	const dispatch = useAppDispatch();
	const [tabIdx, setTabIdx] = useState(0);
	const location = useLocation();
	const nav = useNavigate();

	const isPageBannerDisplay = useSelector(selectECW.isPageBannerDisplay);
	const isPageFullSize = useSelector(selectECW.isPageFullSize);

	useEffect(() => {
		const splittedPath = location.pathname.split("/");
		const currentTab = "/" + splittedPath[5] ?? undefined;

		if (!isPageFullSize) {
			dispatch(ECWAction.setIsPageFullSize(true));
		}
		if (isPageBannerDisplay) {
			dispatch(ECWAction.setIsPageBannerDisplay(false));
		}

		switch (currentTab) {
			case PATH_OVERVIEW:
				setTabIdx(0);
				break;
			case PATH_VALIDATION:
				setTabIdx(1);
				break;
			case PATH_ANALYSIS:
				setTabIdx(2);
				break;
			case PATH_PICTURE:
				setTabIdx(3);
				break;
			default:
				nav(`.${PATH_OVERVIEW}`, { replace: true });
				setTabIdx(0);
				break;
		}
	}, [dispatch, isPageBannerDisplay, isPageFullSize, location, nav]);

	useEffect(() => {
		return () => {
			dispatch(ECWAction.setIsPageFullSize(false));
			dispatch(ECWAction.setIsPageBannerDisplay(true));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="TrialDataPage-container flex-column full-parent-size">
			<CustomTabs value={tabIdx} indicatorColor="secondary">
				<CustomLinkTab
					secondaryLevel={true}
					label={DIC(EDIC_KEY.OVERVIEW)}
					href={`.${PATH_OVERVIEW}`}
				/>
				<CustomLinkTab
					secondaryLevel={true}
					label={DIC(EDIC_KEY.VALIDATION)}
					href={`.${PATH_VALIDATION}`}
				/>
				<CustomLinkTab
					secondaryLevel={true}
					label={DIC(EDIC_KEY.ANALYSIS)}
					href={`.${PATH_ANALYSIS}`}
				/>
				<CustomLinkTab
					secondaryLevel={true}
					label={DIC(EDIC_KEY.PICTURE)}
					href={`.${PATH_PICTURE}`}
				/>
			</CustomTabs>

			<div className="TrialDataPage-tab-container take-remaining-space">
				<Outlet />
			</div>
		</div>
	);
}
