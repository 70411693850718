import { AbstractRequest } from "../abstract-request";
import { EnvActionVariable } from "shared-type";
import { AxiosInstance, AxiosResponse } from "axios";

export class EnvVariableRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/env-variables";
	}

	getEnvVariableNames(): Promise<AxiosResponse<string[], any>> {
		return this.axios.get<string[]>(`${this.baseUrl}/`);
	}

	getVariable(
		envVariableName: string
	): Promise<AxiosResponse<EnvActionVariable, any>> {
		return this.axios.get<EnvActionVariable>(
			`${this.baseUrl}/${envVariableName}`
		);
	}
}
