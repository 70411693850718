import { AbstractRequest } from "../abstract-request";
import { IActionDescription, IActionList } from "shared-type";
import { AxiosInstance, AxiosResponse } from "axios";

export class ActionRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/actions";
	}

	getActionNames(): Promise<AxiosResponse<IActionList, any>> {
		return this.axios.get<IActionList>(`${this.baseUrl}/`);
	}

	getAction(
		actionName: string
	): Promise<AxiosResponse<IActionDescription, any>> {
		return this.axios.get<IActionDescription>(`${this.baseUrl}/${actionName}`);
	}
}
