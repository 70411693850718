import { CircularProgress } from "@mui/material";
import { GanttTaskClickEvent } from "@progress/kendo-react-gantt";
import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { IMETGanttSerialized, ITrialGanttSerialized } from "shared-type";

import ExperimentGantt from "../../../../../../../../components/experiment/common/experiment-gantt/ExperimentGantt";
import {
	createDataFromIGanttTrial,
	createDataFromIMETGantt,
	EExpGanttTaskOrigin,
} from "../../../../../../../../components/experiment/common/experiment-gantt/ExperimentGantt.interface";
import { ExpGanttColumns } from "../../../../../../../../components/experiment/common/experiment-gantt/ExperimentGanttColumns";
import { DIC, EDIC_KEY } from "../../../../../../../../dictionary";
import { useAppDispatch } from "../../../../../../../../hooks/reduxHook";
import { ECWAction } from "../../../../../../../../redux/ECW/ECW.slice";
import { apiExpGantt } from "../../../../../../../../redux/experiment-statistics/exp-gantt.api";
import {
	PATH_OBSERVATION_ROUND,
	PATH_OPERATION,
} from "../../../../../../../../routes";

import "./ExpGanttPage.scss";

export default function ExpGanttPage() {
	const { trialId, metId } = useParams();
	const dispatch = useAppDispatch();
	const nav = useNavigate();

	// If there is the parameter trialId, it means that the user is on the trial page
	const isTrial = useMemo(() => trialId !== undefined, [trialId]);

	const { data: ganttExpInfos, isError } = isTrial
		? apiExpGantt.useComputeTrialsGanttInfoByIdsQuery(
				trialId ? [trialId] : [],
		  )
		: apiExpGantt.useComputeMETsGanttInfoByIdsQuery(metId);

	const ganttData = useMemo(() => {
		if (ganttExpInfos) {
			return isTrial
				? createDataFromIGanttTrial(
						ganttExpInfos as ITrialGanttSerialized[],
				  )
				: createDataFromIMETGantt([
						ganttExpInfos,
				  ] as IMETGanttSerialized[]);
		}
		return undefined;
	}, [ganttExpInfos, isTrial]);

	const data = ganttData?.data;
	const expandedTasks = ganttData?.expandedTasks;

	const handleTaskClick = useCallback(
		(event: GanttTaskClickEvent) => {
			if (!isTrial) return;
			if (event.dataItem.origin === EExpGanttTaskOrigin.OBS_ROUND) {
				nav(`..${PATH_OBSERVATION_ROUND}/${event.dataItem.originId}`);
			} else if (
				event.dataItem.origin === EExpGanttTaskOrigin.OPERATION
			) {
				nav(`..${PATH_OPERATION}`);
				dispatch(
					ECWAction.updateEditedOperationId(event.dataItem.originId),
				);
			}
		},
		[dispatch, nav],
	);

	const ganttDisplay = useMemo(() => {
		if (data !== undefined) {
			return (
				<div className="full-parent-size">
					<ExperimentGantt
						columns={ExpGanttColumns}
						data={data}
						defaultExpandedTasks={expandedTasks}
						onTaskClick={(event) => handleTaskClick(event)}
					/>
				</div>
			);
		}
		if (isError) {
			return (
				<div className="ExpGanttPage-centered full-parent-size">
					{DIC(EDIC_KEY.CANNOT_COMPUTE_GANTT)}
				</div>
			);
		}
		return (
			<div className="ExpGanttPage-centered full-parent-size">
				<CircularProgress />
			</div>
		);
	}, [data, expandedTasks, handleTaskClick, isError]);

	return (
		<div className="ExpGanttPage-container full-parent-size">
			{ganttDisplay}
		</div>
	);
}
