import { isBoolean } from "class-validator";
import dayjs from "dayjs";
import _ from "lodash";
import {
	DateConstraint,
	DecConstraint,
	EVariableCoreType,
	IMultiNotationValue,
	IntConstraint,
	IVariableCore,
	IVariableValue,
	IVarLimitedChoice,
	StringConstraint,
	VariableConstraint,
	VariableCoreValue,
} from "shared-type";
import { areNumbersEqual, computeAggregationMethod } from "../toolbox";
import { validateDate, validateNumber, validateString } from "./utils";

export function variableValueValidator(
	value: IVariableValue,
	variable: IVariableCore,
	multiNotationValues?: IMultiNotationValue[]
): string[] {
	const errors: string[] = [];
	// no error on undef
	if (value === undefined) {
		return errors;
	}

	// We are a multinotation
	if (variable.multiNotation !== undefined && multiNotationValues?.length) {
		const multiValues: number[] = multiNotationValues.map((item) =>
			_.toNumber(String(item.value))
		);
		const computeValue = computeAggregationMethod(
			variable.multiNotation.computeMethod,
			multiValues
		);
		if (!areNumbersEqual(computeValue, value, 0.00001)) {
			errors.push(
				`The value of the notation "${value}" is not the result of the calculation of its multi-noted values "${computeValue}".`
			);
		}
	}
	// Don't validate the value result a of multiNotation
	// Because it may not respect the variable constraint
	else {
		errors.push(
			...notationValueValidator(value, variable.type, variable.constraint)
		);

		if (variable.limitedChoices !== undefined) {
			errors.push(
				...limitedChoiceVariableValueValidator(
					value,
					variable.type,
					variable.limitedChoices
				)
			);
		}
	}

	return errors;
}
export function limitedChoiceVariableValueValidator(
	value: IVariableValue,
	type: EVariableCoreType,
	limitedChoices: IVarLimitedChoice[]
): string[] {
	const errors: string[] = [];
	if (
		!limitedChoices.find((elt) => {
			if (
				type === EVariableCoreType.DATE ||
				type === EVariableCoreType.DATETIME
			) {
				return dayjs(value).isSame(dayjs(elt.value as Date));
			}
			return elt.value === value;
		})
	) {
		return [
			`The value: ${value} is not in the variable limited choices: ${JSON.stringify(
				limitedChoices
			)}`,
		];
	}
	return errors;
}

export function notationValueValidator(
	value: VariableCoreValue,
	type: EVariableCoreType,
	constraint?: VariableConstraint
): string[] {
	const errors: string[] = [];

	switch (type) {
		case EVariableCoreType.DATE:
		case EVariableCoreType.DATETIME:
			return validateDate(value, {
				minDate: constraint
					? (constraint as DateConstraint).minDate
					: undefined,
				maxDate: constraint
					? (constraint as DateConstraint).maxDate
					: undefined,
				required: true,
			});

		case EVariableCoreType.DEC:
		case EVariableCoreType.INT:
			return validateNumber(value, {
				isInt: type === EVariableCoreType.INT,
				min: constraint ? (constraint as IntConstraint).min : undefined,
				max: constraint ? (constraint as IntConstraint).max : undefined,
				nbDigits: constraint
					? type === EVariableCoreType.INT
						? undefined
						: (constraint as DecConstraint).nbDigits
					: undefined,
				required: true,
			});

		case EVariableCoreType.STRING:
			return validateString(value, {
				required: true,
				minLength: constraint
					? (constraint as StringConstraint).min
					: undefined,
				maxLength: constraint
					? (constraint as StringConstraint).max
					: undefined,
			});

		case EVariableCoreType.BOOLEAN:
			if (!isBoolean(value)) {
				errors.push("Must be a boolean");
			}
			break;
		case EVariableCoreType.PICTURE:
			break;

		default:
			errors.push(`unknown type ${type} given.`);
			break;
	}
	return errors;
}
