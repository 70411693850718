import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import {
	ICreateNotebook,
	IFilter,
	INotebookSerialized,
	IPatchNotebook,
	ISort,
} from "shared-type";

import { AbstractRequest } from "../abstract-request";

export class NotebookRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/notebook";
	}

	createNotebook(
		createNotebook: ICreateNotebook,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<string, any>> {
		return this.axios.post<string>(this.baseUrl, createNotebook, config);
	}

	getNotebooksByObsRoundId(
		obsRoundId: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<INotebookSerialized[], any>> {
		return this.axios.get<INotebookSerialized[]>(
			this.baseUrl + "/observation-round/" + obsRoundId,
			config
		);
	}

	getNotebooksByIds(notebookIds: string[], config?: AxiosRequestConfig) {
		return this.axios.post<INotebookSerialized[]>(
			this.baseUrl + "/get/many",
			notebookIds,
			config
		);
	}

	getNotebookById(
		id: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<INotebookSerialized, any>> {
		return this.axios.get<INotebookSerialized>(this.baseUrl + "/" + id, config);
	}

	getNotebooks(
		filter?: IFilter,
		sort?: ISort,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<INotebookSerialized[], any>> {
		return this.axios.get<INotebookSerialized[]>(`${this.baseUrl}`, {
			params: {
				filter: JSON.stringify(filter),
				sort: JSON.stringify(sort),
			},
			...config,
		});
	}

	patchNotebookById(
		id: string,
		patchNotebook: IPatchNotebook
	): Promise<AxiosResponse<void, any>> {
		return this.axios.patch<void>(this.baseUrl + "/" + id, patchNotebook);
	}

	deleteNotebookById(id: string): Promise<AxiosResponse<void, any>> {
		return this.axios.delete<void>(this.baseUrl + "/" + id);
	}

	deleteNotebooksByObsRoundId(
		obsRoundId: string
	): Promise<AxiosResponse<number, any>> {
		return this.axios.delete<number>(
			this.baseUrl + "/observation-round/" + obsRoundId
		);
	}
}
