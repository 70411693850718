import React from "react";

import { EVariableScope } from "shared-type";
import {
	apiVariables,
	variablesCustomHook,
} from "../../../redux/variables/variables.api";
import CommentDrawer from "../../base-components/modal/comment-drawer/CommentDrawer";

interface VariableCommentDrawerProps {
	onClose: () => void;
	variableId: string;
}

export default function VariableCommentDrawer(
	props: VariableCommentDrawerProps,
) {
	const { data: fetchedVariable } =
		variablesCustomHook.useGetVariableByIdQueryDeserialized({
			id: props.variableId,
			scope: EVariableScope.CUSTOM,
		});

	const [updateCustomVariableById] =
		apiVariables.useUpdateCustomVariableByIdMutation();
	return (
		<CommentDrawer
			remoteComments={fetchedVariable?.comments}
			onClose={() => props.onClose()}
			onSaveComment={async (comments: any) =>
				await updateCustomVariableById({
					id: props.variableId,
					update: { comments },
				}).unwrap()
			}
			appBarSpacing
		/>
	);
}
