import * as Yup from "yup";
import dayjs from "dayjs";

import { EEntitiesLength, ETaskStatus } from "shared-type";

import { dayjsYup, dayjsYupTestConstraint } from "../../utils";
import { DIC, EDIC_KEY } from "App/dictionary";

export const obsRoundValidator = Yup.object().shape({
	name: Yup.string().required()
.min(1)
.max(EEntitiesLength.name),
	identifier: Yup.string()
		.required()
		.min(1)
		.max(EEntitiesLength.identifier)
		.matches(/^[a-zA-Z0-9_-]+$/),
	label: Yup.string().max(EEntitiesLength.label),
	description: Yup.string().max(EEntitiesLength.description),
	stage: Yup.string().max(EEntitiesLength.stage),
	startDate: dayjsYup.required("Field is required").test(
		dayjsYupTestConstraint({
			maxDate: new Date("2099-12-31T23:59:59"),
		}),
	),
	endDate: dayjsYup
		.required("Field is required")
		.test(
			dayjsYupTestConstraint({
				maxDate: new Date("2099-12-31T23:59:59"),
			}),
		)
		.test({
			message: DIC(EDIC_KEY.DATE_GREATER_THAN_START_DATE_ERROR),
			test: (value, ctx) =>
				!value || !dayjs(value).isBefore(ctx.parent.startDate),
		}),
	eventOption: Yup.boolean(),
	team: Yup.array().required(),
	status: Yup.mixed<ETaskStatus>().oneOf(Object.values(ETaskStatus)),
	variableGroupId: Yup.string().required(),
});

// Type of the validate schema
export type ObsRoundSchemaType = Yup.InferType<any>;
