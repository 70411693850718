import React from "react";
import { IUser } from "shared-type";

import UserBanner from "./UserBanner";

export interface IUserCardProps {
	user?: IUser;
	onClick?: () => void;
	disabledClick?: boolean;
}

export default function UserCard(props: IUserCardProps) {
	return (
		<UserBanner
			user={props.user}
			onClick={props.onClick}
			disabledClick={props.disabledClick || props.user?.disabled}
		/>
	);
}
