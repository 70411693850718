import React from "react";

import { IObservationRound } from "shared-type";

import ObservationRoundBanner, {
	IObservationRoundBannerProps,
} from "./ObservationRoundBanner";

import "./ObservationRoundCard.scss";

export interface IObservationRoundCardProps {
	observationRound: IObservationRound;
	onClick?: () => void;
	disabledClick?: boolean;
	isTemplate: boolean;
	isSelected?: boolean;
}

export default function ObservationRoundCard(
	props: IObservationRoundCardProps,
) {
	const enhancedProps: IObservationRoundBannerProps = {
		...props,
		className: "ObservationRoundCard",
	};

	return <ObservationRoundBanner {...enhancedProps} />;
}
