import React, { useEffect } from "react";

import { DIC, EDIC_KEY } from "../../../../dictionary";
import ChipCombobox from "../../../base-components/combobox/chip-combobox/ChipCombobox";
import {
	useDashboardCountryFilter,
	useDashboardRegionsFilter,
	useDashboardSpeciesFilter,
	useDashboardYearFilter,
} from "../../hooks/hooks";
import DashboardHeader from "../dashboard-header/DashboardHeader";
import { IDashboardNetworkHeaderFilter } from "./types";

interface DashboardNetworkHeaderProps {
	filterGlobal: IDashboardNetworkHeaderFilter | undefined;
	submitNewFilter: (filter: IDashboardNetworkHeaderFilter) => void;
}

export default function DashboardNetworkHeader(
	props: DashboardNetworkHeaderProps,
) {
	// SELECTED VALUES
	const { expYears, setExpYears, computeExpYearSet } =
		useDashboardYearFilter();
	const {
		speciesFilter: species,
		setSpeciesFilter: setSpecies,
		speciesSort,
	} = useDashboardSpeciesFilter();
	const { countries, setCountries, countriesSorted } =
		useDashboardCountryFilter();
	const { regions, regionsSetSorted, setRegions } =
		useDashboardRegionsFilter();

	// END Values list -------

	const computeAndSubmitChartFilter = () => {
		props.submitNewFilter({
			expYears,
			species,
			countries,
			regions,
		});
	};
	// set filter on ready
	useEffect(() => {
		computeAndSubmitChartFilter();
	}, []);

	return (
		<DashboardHeader
			computeAndSubmitChartFilter={computeAndSubmitChartFilter}
		>
			<ChipCombobox<number>
				value={expYears ?? []}
				label={DIC(EDIC_KEY.YEAR)}
				onChange={setExpYears}
				comboboxValues={computeExpYearSet}
				translateMethod={(value) => value.toString()}
				className="DashboardHeader-combobox"
				selectAllBtn
			/>
			<ChipCombobox<string>
				value={countries ?? []}
				label={DIC(EDIC_KEY.COUNTRY)}
				onChange={setCountries}
				comboboxValues={countriesSorted}
				translateMethod={(value) => value}
				noWrap
				className="DashboardHeader-combobox"
				selectAllBtn
			/>
			<ChipCombobox<string>
				value={regions ?? []}
				label={DIC(EDIC_KEY.REGION)}
				onChange={setRegions}
				comboboxValues={regionsSetSorted}
				translateMethod={(value) => value}
				noWrap
				className="DashboardHeader-combobox"
				selectAllBtn
			/>

			<ChipCombobox<string>
				value={species ?? []}
				label={DIC(EDIC_KEY.SPECIES)}
				onChange={setSpecies}
				comboboxValues={speciesSort}
				translateMethod={(value) => value}
				className="DashboardHeader-combobox"
				selectAllBtn
			/>
		</DashboardHeader>
	);
}
