import * as React from "react";

import TitledTag from "../titled-tag/TitledTag";

import "./ColorTag.scss";

interface IColorTagProps {
	title?: string;
	colorIndicator?: string;
	tagValue: string;
}

export default function ColorTag(props: IColorTagProps) {
	return (
		<TitledTag
			tagValue={props.tagValue}
			title={props.title}
			style={{
				borderLeft: props.colorIndicator
					? `5px ${props.colorIndicator} solid`
					: "",
			}}
		/>
	);
}
