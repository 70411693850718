import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import {
	ICreateGenotype,
	IFilter,
	IGenotype,
	ISort,
	IPatchGenotype,
	PaginateResponse,
} from "shared-type";

import { AbstractRequest } from "../abstract-request";

export class GenotypeRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/genotype";
	}

	createGenotype(
		createGenotype: ICreateGenotype
	): Promise<AxiosResponse<string, any>> {
		return this.axios.post<string>(this.baseUrl, createGenotype);
	}

	createManyGenotypes(
		createGenotypeDtoArray: ICreateGenotype[]
	): Promise<AxiosResponse<string[], any>> {
		return this.axios.post<string[]>(
			this.baseUrl + "/many",
			createGenotypeDtoArray
		);
	}

	getGenotypes(
		page?: number,
		pageSize?: number,
		filter?: IFilter,
		sort?: ISort,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<PaginateResponse<IGenotype>, any>> {
		return this.axios.get<PaginateResponse<IGenotype>>(this.baseUrl, {
			...config,
			params: {
				...config?.params,
				page: JSON.stringify(page),
				pageSize: JSON.stringify(pageSize),
				filter: JSON.stringify(filter),
				sort: JSON.stringify(sort),
			},
		});
	}

	getGenotypeById(
		id: string,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<IGenotype, any>> {
		return this.axios.get<IGenotype>(`${this.baseUrl}/${id}`, config);
	}

	getGenotypeByIds(genotypesIds: string[], config?: AxiosRequestConfig) {
		return this.axios.post<IGenotype[]>(
			this.baseUrl + "/get/many",
			genotypesIds,
			config
		);
	}

	getGenotypeUsageInfoById(
		id: string,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<string[], any>> {
		return this.axios.get<string[]>(`${this.baseUrl}/usage/${id}`, config);
	}

	updateGenotypeById(
		id: string,
		updateGenotype: IPatchGenotype
	): Promise<AxiosResponse<void, any>> {
		return this.axios.patch<void>(`${this.baseUrl}/${id}`, updateGenotype);
	}

	deleteGenotypeById(id: string): Promise<AxiosResponse<number, any>> {
		return this.axios.delete<number>(this.baseUrl + "/" + id);
	}

	deleteManyGenotypesByIds(ids: string[]): Promise<AxiosResponse<number, any>> {
		return this.axios.delete<number>(this.baseUrl + "/many", { data: ids });
	}
}
