import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { ILevelCompletionStats } from "shared-type";
import { AbstractRequest } from "../abstract-request";

export class ExpLevelCompletionRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/level-completion";
	}

	computeNbCompletionById(
		notebookId: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<ILevelCompletionStats, any>> {
		return this.axios.get<ILevelCompletionStats>(
			`${this.baseUrl}/notebook/${notebookId}`,
			config
		);
	}

	computeObsRoundCompletionById(
		obsRoundId: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<ILevelCompletionStats, any>> {
		return this.axios.get<ILevelCompletionStats>(
			`${this.baseUrl}/observation-round/${obsRoundId}`,
			config
		);
	}

	computeTrialCompletionById(
		trialId: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<ILevelCompletionStats, any>> {
		return this.axios.get<ILevelCompletionStats>(
			`${this.baseUrl}/trial/${trialId}`,
			config
		);
	}
}
