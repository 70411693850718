import React, { useMemo } from "react";

import { concatClassName } from "common";

import { StoryTheme } from "stories/StoryTheme";
import { Typography } from "stories/base-components/Typography/Typography";
import { Icon } from "stories/base-components/Icon/Icon";
import Colors from "stories/constants/Colors/Colors";

import "./foldingChip.scss";

export type IFoldingChip = {
	label: string;
	onClick?: (event: any) => void;
	className?: string;
	isExpanded: boolean;
	expandedColor?: {
		backgroundColor: string;
		color: string;
	};
	shrunkenColor?: {
		backgroundColor: string;
		color: string;
	};
	hideIcon?: boolean;
};

export default function FoldingChip(props: IFoldingChip) {
	const backgroundColor = useMemo(() => {
		if (props.isExpanded) {
			return props.expandedColor?.backgroundColor ?? Colors.active20;
		}
		return props.shrunkenColor?.backgroundColor ?? Colors.primary10;
	}, [
		props.expandedColor?.backgroundColor,
		props.isExpanded,
		props.shrunkenColor?.backgroundColor,
	]);

	const color = useMemo(() => {
		if (props.isExpanded) {
			return props.expandedColor?.color ?? Colors.active60;
		}
		return props.shrunkenColor?.color ?? Colors.primary60;
	}, [
		props.expandedColor?.color,
		props.isExpanded,
		props.shrunkenColor?.color,
	]);

	return (
		<StoryTheme>
			<div
				className={concatClassName(
					"foldingChipsContainer",
					props.className,
				)}
				onClick={(event) => props.onClick?.(event)}
				style={{
					backgroundColor: backgroundColor,
				}}
			>
				<Typography
					text={props.label}
					variant="body2Medium"
					color={color}
				/>
				{!props.hideIcon && (
					<Icon
						name={props.isExpanded ? "arrow-down" : "arrow-right"}
						color={color}
					/>
				)}
			</div>
		</StoryTheme>
	);
}
