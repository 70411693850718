import React, { useState } from "react";

import { templateExpStructsMock } from "common";

import TextSearchBar from "../../../../../components/base-components/searchBar/TextSearchBar";
import TemplateExperimentStructureCard from "../../../../../components/templates/experiment/template-experiment-card/TemplateExperimentStructureCard";

import "./TemplateExperimentStructurePage.scss";

export default function TemplateExperimentStructurePage() {
	const [filter, setFilter] = useState<string>("");

	const onSubmit = (filterArg: string | undefined) => {
		if (filterArg) setFilter(filterArg);
	};

	return (
		<div className="TemplateExperimentStructurePage-container flex-column full-parent-size">
			{templateExpStructsMock.length >= 10 && (
				<TextSearchBar onSubmit={onSubmit} />
			)}
			<div className="TemplateExperimentStructurePage-card-list">
				{templateExpStructsMock.map((elt) => (
					<TemplateExperimentStructureCard
						templateExperimentStructure={elt}
						key={elt._id}
					/>
				))}
			</div>
		</div>
	);
}
