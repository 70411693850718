import React from "react";

import { DIC, EDIC_KEY } from "../../../dictionary";
import DorianeButton from "./DorianeButton";

export interface ISaveButtonProps {
	onSave: () => void;
	disabled?: boolean;
	customLabel?: string;
	isLoading?: boolean;
}

export default function SaveButton(props: ISaveButtonProps) {
	return (
		<DorianeButton
			style={{
				width: "200px",
			}}
			dorianeStyle="primary"
			onClick={() => props.onSave()}
			disabled={props.disabled}
			isLoading={props.isLoading}
		>
			{props.customLabel || DIC(EDIC_KEY.SAVE)}
		</DorianeButton>
	);
}
