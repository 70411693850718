import React, { useEffect, useState } from "react";

import { ESpecies, IMarketSegment } from "shared-type";

import { DIC, EDIC_KEY } from "../../../dictionary";
import {
	convertDorObjFilterToFilter,
	IDorianeFilter,
	IDorianeObjectFilter,
} from "../../../interfaces/filters/doriane-filter-interface";
import { apiMarketSegment } from "../../../redux/market-segment/market-segment.api";
import { marketSegmentColumnsForSelection } from "../../base-components/data-grid/doriane-data-grid/default-grid-columns/marketSegmentColumns";
import DorianeDataGrid from "../../base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import DorianeDrawer from "../../base-components/modal/DorianeDrawer";

interface ISelectMarketSegmentModalProps {
	onClose: () => void;
	currentSegmentIds: string[];
	onMarketSegmentCheck: (
		clickedSegment: IMarketSegment,
		checked: boolean,
	) => void;
	initialSegmentIds: string[]; // Segments before edit
	speciesFilter?: ESpecies;
}

export default function SelectMarketSegmentModal(
	props: ISelectMarketSegmentModalProps,
) {
	const [filter, setFilter] = useState<IDorianeObjectFilter>({});

	useEffect(() => {
		if (props.speciesFilter !== undefined) {
			setFilter({
				...filter,
				species: {
					key: "species",
					type: "string",
					value: props.speciesFilter,
				},
			});
		}
	}, [props.speciesFilter]);

	const { data: fetchedMarketSegmentList } =
		apiMarketSegment.useGetMarketSegmentsQuery({
			filter: convertDorObjFilterToFilter(filter),
		});

	const handleFilterChange = (newFilter: IDorianeFilter) => {
		setFilter((old) => {
			return { ...old, [newFilter.key]: newFilter };
		});
	};

	const isSegmentChecked = (marketSegment: IMarketSegment) => {
		return props.currentSegmentIds.some(
			(segmentId) => segmentId === marketSegment._id,
		);
	};

	return (
		<DorianeDrawer
			onClose={props.onClose}
			title={DIC(EDIC_KEY.MARKET_SEGMENTS)}
			takeRemainingSpace
		>
			<DorianeDataGrid
				columns={marketSegmentColumnsForSelection(
					{
						filter: filter,
						onFilterChange: handleFilterChange,
					},
					{
						isCheck: isSegmentChecked,
						onCheck: props.onMarketSegmentCheck,
					},
				)}
				rowHeight={"-1"}
				rows={fetchedMarketSegmentList || []} // Beware that only admin and observers are present in the list
			/>
		</DorianeDrawer>
	);
}
