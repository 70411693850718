/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";

import { EntitySerializer, SETtemplateExpStructMock } from "common";
import {
	DeepPartial,
	EExperimentStatus,
	ICreateTrial,
	IPatchTrial,
	ITrial,
	ITrialSerialized,
} from "shared-type";

import { useRequest } from "App/request-provider";
import { AppDispatch, RootState } from "App/store";
import { isExpCompleted } from "../../components/experiment-wizard/common/wizard-utils";
import { EExpConclusionStatus } from "../../components/experiment/common/modal/conclusion/ExperimentConclusionModal";
import { IECWState } from "./ECW.slice";

const saveTrial = createAsyncThunk<
	ITrialSerialized,
	boolean,
	{
		// Optional fields for defining thunkApi field types
		dispatch: AppDispatch;
		state: RootState;
		rejectValue: undefined;
	}
>("trialWizard/save", async (setToReady: boolean, thunkApi) => {
	const state = thunkApi.getState().trialWizard;

	// if update
	let id: string;
	if (state.trialRemote) {
		id = await updateTrial(setToReady, state);
	} else {
		// else create new
		id = await createTrial(state);
	}
	return await useRequest()
		.trial.getTrialById(id)
		.then((resp) => EntitySerializer.serialize<ITrial>(resp.data));
});

const updateTrial = async (setToReady: boolean, state: IECWState) => {
	// if complete and we are in draft and want to go to ready -> status ready
	let newStatus = state.trialRemote!.status;
	if (
		setToReady && // We can set to ready
		newStatus === EExperimentStatus.DRAFT && // We are in draft
		isExpCompleted(state) // Trial is complete
	) {
		newStatus = EExperimentStatus.READY;
	}

	const trialInfo = state.generalPart.generalInfo;
	const updateTrial: IPatchTrial = {
		name: trialInfo?.name,
		label: trialInfo?.label,
		objective: trialInfo?.objective,
		description: trialInfo?.description,
		startDate: trialInfo?.startDate
			? new Date(trialInfo.startDate)
			: undefined,
		endDate: trialInfo?.endDate ? new Date(trialInfo.endDate) : undefined,
		species: trialInfo?.species,
		program: trialInfo?.program,
		project: trialInfo?.project,
		status: newStatus,
		// if undefined remove the field (with null)
		templateObsId: state.observationPart.templateObsId ?? null,
		materialLevel: {
			germplasmLevel: state.germplasmPart.materialType,
			materials: state.germplasmPart.selectedMaterials,
		},
		// if undefined remove the field (with null)
		growingAreaId: state.growingAreaPart?.growingAreaId ?? null,
		marketSegmentIds: trialInfo?.marketSegmentIds,

		surface: trialInfo?.surface,
		rowsNumber: trialInfo?.rowsNumber,
		rowSpacing: trialInfo?.rowSpacing,
		plantsNumber: trialInfo?.plantsNumber,
		density: trialInfo?.density,
		cultivationMethod: trialInfo?.cultivationMethod,
		trialTotalCosts: trialInfo?.trialTotalCosts,
		currency: trialInfo?.currency,
		contract: trialInfo?.contract,

		location: trialInfo?.location,
	};

	await useRequest().trial.updateTrialById(
		state.trialRemote!._id,
		updateTrial,
	);
	return state.trialRemote!._id;
};

const createTrial = async (state: IECWState) => {
	const fakeTemplateExpStruct = SETtemplateExpStructMock;

	const trialInfo = state.generalPart.generalInfo;
	const createTrial: DeepPartial<ICreateTrial> = {
		name: trialInfo?.name,
		label: trialInfo?.label,
		objective: trialInfo?.objective,
		description: trialInfo?.description,
		startDate: trialInfo?.startDate
			? new Date(trialInfo.startDate)
			: undefined,
		endDate: trialInfo?.endDate ? new Date(trialInfo.endDate) : undefined,
		species: trialInfo?.species,
		program: trialInfo?.program,
		project: trialInfo?.project,
		templateExpStructId: fakeTemplateExpStruct._id,
		materialLevel: {
			germplasmLevel: state.germplasmPart.materialType,
			materials: state.germplasmPart.selectedMaterials,
		},
		growingAreaId: state.growingAreaPart?.growingAreaId,
		marketSegmentIds: trialInfo?.marketSegmentIds,

		surface: trialInfo?.surface,
		rowsNumber: trialInfo?.rowsNumber,
		rowSpacing: trialInfo?.rowSpacing,
		plantsNumber: trialInfo?.plantsNumber,
		density: trialInfo?.density,
		cultivationMethod: trialInfo?.cultivationMethod,
		trialTotalCosts: trialInfo?.trialTotalCosts,
		currency: trialInfo?.currency,
		contract: trialInfo?.contract,

		location: trialInfo?.location,
	};
	const createTrialValidate = createTrial as ICreateTrial;
	const createResponse = await useRequest().trial.createTrial(
		createTrialValidate,
	);
	return createResponse.data;
};

// Need to save only comment of trial (scoped)
const saveTrialComments = createAsyncThunk<
	{ comments: string },
	string,
	{
		state: RootState;
	}
>("trialWizard/saveComments", async (comments, thunkApi) => {
	const state = thunkApi.getState().trialWizard;
	try {
		await useRequest().trial.updateTrialById(state.trialRemote!._id, {
			comments,
		});
		return { comments };
	} catch (err) {
		return thunkApi.rejectWithValue(err);
	}
});

// Need to save only conclusion and status of trial (scoped)
const saveTrialConclusion = createAsyncThunk<
	{ conclusion?: string; status: EExpConclusionStatus },
	{ conclusion?: string; status: EExpConclusionStatus },
	{
		state: RootState;
	}
>("trialWizard/conclude", async (arg, thunkApi) => {
	const state = thunkApi.getState().trialWizard;
	try {
		await useRequest().trial.updateTrialById(state.trialRemote!._id, {
			conclusion: arg.conclusion,
			status: arg.status,
		});
		return { conclusion: arg.conclusion, status: arg.status };
	} catch (err) {
		return thunkApi.rejectWithValue(err);
	}
});

export const trialWizardSaveReducerBuilder = (
	builder: ActionReducerMapBuilder<IECWState>,
) => {
	builder.addCase(saveTrial.fulfilled, (state, action) => {
		state.trialRemote = action.payload;
		state.hasChanges = false;
	});
	builder.addCase(saveTrialConclusion.fulfilled, (state, action) => {
		if (state.trialRemote) {
			state.trialRemote.conclusion = action.payload.conclusion;
			state.trialRemote.status = action.payload.status;
		}
	});
	builder.addCase(saveTrialComments.fulfilled, (state, action) => {
		if (state.trialRemote) {
			state.generalPart.comments = action.payload.comments;
		}
	});
};
export const trialWizardSaveAction = {
	saveTrial,
	saveTrialComments,
	saveTrialConclusion,
};
