export type AxiosHeader = {
	headers: { "tenant-id"?: string; authorization: string };
};
export const createHeader = (
	tenantId: string,
	authorizationHeader: string
): AxiosHeader => {
	return {
		headers: { "tenant-id": tenantId, authorization: authorizationHeader },
	};
};

/**
 * Utils function to convert a dictionary object into a FormData object
 * @param dict standard dictionary object
 * @returns a FormData object
 */
export const convertDictToFormData = (dict: { [key: string]: any }) => {
	const formData = new FormData();
	Object.entries(dict).forEach(([key, value]) => formData.append(key, value));
	return formData;
};
