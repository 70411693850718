import { configureStore } from "@reduxjs/toolkit";

import { apiResource } from "./redux/attachment/resource.api";
import { apiTag } from "./redux/attachment/tag.api";
import { authenticationReducer } from "./redux/authentication/authentication.slice";
import { apiChart } from "./redux/chart/chart.api";
import { ECWReducer } from "./redux/ECW/ECW.slice";
import { experimentReviewNotationReducer } from "./redux/experiment-review-notation/experiment-review-notation.slice";
import { apiExpGantt } from "./redux/experiment-statistics/exp-gantt.api";
import { apiLevelCompletion } from "./redux/experiment-statistics/level-completion.api";
import { METTrialManagementReducer } from "./redux/experiment/met/met-trial-management.slice";
import { apiMET } from "./redux/experiment/met/met.api";
import { apiTrial } from "./redux/experiment/trial/trial.api";
import { apiGermplasm } from "./redux/germplasm/germplasm.api";
import { germplasmReducer } from "./redux/germplasm/germplasm.slice";
import { apiGrowingArea } from "./redux/growing-area/growing-area.api";
import { apiMarketSegment } from "./redux/market-segment/market-segment.api";
import { apiNotation } from "./redux/notation/notation.api";
import { apiNotebook } from "./redux/notebook/notebook.api";
import { obsRoundEditionReducer } from "./redux/observation-round-edition/obs-round-edition.slice";
import { apiObsRound } from "./redux/observation-round/obs-round.api";
import { apiOperation } from "./redux/operation/operation.api";
import { projectReducer } from "./redux/project/project.slice";
import { templateObsEditionReducer } from "./redux/template-obs-edition/template-obs-edition.slice";
import { apiTemplateObs } from "./redux/template-obs/template-obs.api";
import { apiTenant } from "./redux/tenant/tenant.api";
import { apiUser } from "./redux/user/user.api";
import { apiVariableGroup } from "./redux/variable-group/variable-group.api";
import { apiVariables } from "./redux/variables/variables.api";
import { apiUserCredentials } from "./redux/user/credentials/credentials.api";
import { apiUserBackup } from "./redux/user/backup/backup.api";
import { variableReducer } from "./redux/variables/variable.slice";
import { userReducer } from "./redux/user/user.slice";
import { trialReducer } from "./redux/experiment/trial/trial.slice";
import { metReducer } from "./redux/experiment/met/met.slice";
import { globalReducer } from "./redux/global/global.slice";

const store = configureStore({
	reducer: {
		authentication: authenticationReducer,
		[apiChart.reducerPath]: apiChart.reducer,
		[apiLevelCompletion.reducerPath]: apiLevelCompletion.reducer,
		experimentReviewNotation: experimentReviewNotationReducer,
		[apiExpGantt.reducerPath]: apiExpGantt.reducer,
		[apiGermplasm.reducerPath]: apiGermplasm.reducer,
		germplasm: germplasmReducer,
		[apiGrowingArea.reducerPath]: apiGrowingArea.reducer,
		[apiResource.reducerPath]: apiResource.reducer,
		[apiTag.reducerPath]: apiTag.reducer,
		[apiMarketSegment.reducerPath]: apiMarketSegment.reducer,
		[apiNotation.reducerPath]: apiNotation.reducer,
		[apiNotebook.reducerPath]: apiNotebook.reducer,
		[apiObsRound.reducerPath]: apiObsRound.reducer,
		[apiOperation.reducerPath]: apiOperation.reducer,
		obsRoundEdition: obsRoundEditionReducer,
		project: projectReducer,
		METTrialManagement: METTrialManagementReducer,
		[apiTemplateObs.reducerPath]: apiTemplateObs.reducer,
		templateObsEdition: templateObsEditionReducer,
		trialWizard: ECWReducer,
		[apiUser.reducerPath]: apiUser.reducer,
		[apiVariableGroup.reducerPath]: apiVariableGroup.reducer,
		[apiVariables.reducerPath]: apiVariables.reducer,
		[apiTenant.reducerPath]: apiTenant.reducer,
		[apiMET.reducerPath]: apiMET.reducer,
		[apiTrial.reducerPath]: apiTrial.reducer,
		[apiUserCredentials.reducerPath]: apiUserCredentials.reducer,
		[apiUserBackup.reducerPath]: apiUserBackup.reducer,
		variable: variableReducer,
		user: userReducer,
		trial: trialReducer,
		met: metReducer,
		global: globalReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(
			apiChart.middleware,
			apiLevelCompletion.middleware,
			apiExpGantt.middleware,
			apiGermplasm.middleware,
			apiResource.middleware,
			apiTag.middleware,
			apiGrowingArea.middleware,
			apiMarketSegment.middleware,
			apiNotation.middleware,
			apiNotebook.middleware,
			apiObsRound.middleware,
			apiOperation.middleware,
			apiTemplateObs.middleware,
			apiUser.middleware,
			apiVariableGroup.middleware,
			apiVariables.middleware,
			apiTenant.middleware,
			apiMET.middleware,
			apiTrial.middleware,
			apiUserCredentials.middleware,
			apiUserBackup.middleware,
		),
});

export type StoreType = typeof store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
