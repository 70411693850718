import dayjs from "dayjs";
import { ITrial } from "shared-type";

import {
	IExpGeneralInfo,
	IExpGeneralInfoState,
} from "../../../experiment-wizard/common/types";
import { ExpGeneralSchemaType } from "./validator";

// converter
export function expGeneralFormToStateObject(
	form: Partial<ExpGeneralSchemaType>,
): Partial<IExpGeneralInfoState> {
	let location: ITrial["location"] | undefined;
	if (form?.longitude !== undefined || form.latitude !== undefined) {
		location = {
			type: "Point",
			coordinates: [
				form.longitude ? Number(form.longitude) : 0,
				form.latitude ? Number(form.latitude) : 0,
			],
		};
	}
	return {
		name: form.name,
		label: form.label,
		objective: form.objective,
		description: form.description,

		startDate: form.startDate?.toString(),
		endDate: form.endDate?.toString(),

		species: form.species,

		program: form.program,
		project: form.project,

		marketSegmentIds: form.marketSegmentIds,

		surface: form.surface,
		rowsNumber: form.rowsNumber,
		rowSpacing: form.rowSpacing,
		plantsNumber: form.plantsNumber,
		density: form.density,
		cultivationMethod: form.cultivationMethod,
		trialTotalCosts: form.trialTotalCosts,
		currency: form.currency,
		contract: form.contract,

		location: location,
	};
}

export function expGeneralStateObjectToForm(
	stateObject: Partial<IExpGeneralInfoState>,
): Partial<IExpGeneralInfo> {
	return {
		name: stateObject.name,
		label: stateObject.label,
		objective: stateObject.objective,
		description: stateObject.description,

		startDate: stateObject.startDate
			? dayjs(stateObject.startDate)
			: undefined,
		endDate: stateObject.endDate ? dayjs(stateObject.endDate) : undefined,

		species: stateObject.species,

		program: stateObject.program,
		project: stateObject.project,

		marketSegmentIds: stateObject.marketSegmentIds,

		surface: stateObject.surface,
		rowsNumber: stateObject.rowsNumber,
		rowSpacing: stateObject.rowSpacing,
		plantsNumber: stateObject.plantsNumber,
		density: stateObject.density,
		cultivationMethod: stateObject.cultivationMethod,
		trialTotalCosts: stateObject.trialTotalCosts,
		currency: stateObject.currency,
		contract: stateObject.contract,

		latitude: stateObject.location?.coordinates[1],
		longitude: stateObject.location?.coordinates[0],
	};
}
