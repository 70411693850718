import React, { CSSProperties } from "react";

import { DIC, EDIC_KEY } from "App/dictionary";
import { FieldObservation, Operation } from "../../../../../assets";
import ImgBannerCard from "../../../base-components/card/img-banner-card/ImgBannerCard";
import TitledTag from "../../../base-components/tag/titled-tag/TitledTag";
import { IOperationCardProps } from "./OperationCard";
import ColorTag from "../../../base-components/tag/color-tag/ColorTag";
import { getColorFromTaskStatus } from "../../../../utils";

import styles from "../../../../../sassTheme";
import "./OperationBanner.scss";

export interface IOperationBannerProps extends IOperationCardProps {
	style?: CSSProperties;
	className?: string;
}

export default function OperationBanner(props: IOperationBannerProps) {
	return (
		<ImgBannerCard
			onClick={props.onClick}
			className={props.className}
			disabledClick={props.disabledClick}
			style={{ ...props.style }}
			isSelected={props.isSelected}
			sideBanner={{
				imageSrc: FieldObservation,
				logoSrc: Operation,
				logoPadding: "6px",
			}}
			backgroundColor={
				props.isTemplate ? styles["image-layer-template"] : undefined
			}
		>
			<div className="OperationBanner-container flex-column">
				<div className="OperationBanner-header">
					<h1 className="OperationBanner-title clamped-text">
						{props.operation.name}
					</h1>
					{!props.isTemplate && (
						<ColorTag
							title={DIC(EDIC_KEY.STATUS)}
							tagValue={props.operation.status}
							colorIndicator={getColorFromTaskStatus(
								props.operation.status,
							)}
						/>
					)}
				</div>
				<div className="OperationBanner-tags">
					<TitledTag
						title={DIC(EDIC_KEY.FROM)}
						tagValue={`${new Date(
							props.operation.startDate,
						).toLocaleDateString()}`}
					/>
					<TitledTag
						title={DIC(EDIC_KEY.TO)}
						tagValue={`${new Date(
							props.operation.endDate,
						).toLocaleDateString()}`}
					/>
					<TitledTag
						title={DIC(EDIC_KEY.STAGE)}
						tagValue={props.operation.stage ?? "_"}
					/>
				</div>
				<h2 className="OperationBanner-description clamped-text">
					{props.operation.description}
				</h2>
			</div>
		</ImgBannerCard>
	);
}
