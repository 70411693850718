import {
	growingAreaCustomHook,
	apiGrowingArea,
} from "../../../../redux/growing-area/growing-area.api";

export const useRemoteGrowingArea = (id?: string) => {
	const {
		data: growingAreaFetched,
		isError,
		isLoading: gaIsLoading,
	} = growingAreaCustomHook.useGetGrowingAreaByIdQueryDeserialized(id);

	const { data: growingAreaUsedInfo, isLoading: gaUsageIsLoading } =
		apiGrowingArea.useGetGrowingAreaUsageInfoByIdQuery(id);

	return {
		growingAreaFetched: growingAreaFetched,
		isGrowingAreaUsed: (growingAreaUsedInfo?.length || 0) > 0,
		hasError: isError,
		isLoading: gaIsLoading && gaUsageIsLoading,
	};
};
