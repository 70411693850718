"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EResourceType = void 0;
var EResourceType;
(function (EResourceType) {
    EResourceType["AUTO"] = "AUTO";
    EResourceType["IMAGE"] = "IMAGE";
    EResourceType["VIDEO"] = "VIDEO";
    EResourceType["RAW"] = "RAW";
})(EResourceType = exports.EResourceType || (exports.EResourceType = {}));
