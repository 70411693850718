import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { Tabs } from "stories/base-components/Navigation/Tabs/Tabs";
import { DIC, EDIC_KEY } from "../../../../dictionary";
import { PATH_EXPERIMENTS, PATH_OBSERVATION } from "../../../../routes";

import "./TemplatesPage.scss";

export default function TemplatesPage() {
	const nav = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const redirectToObs =
			location.pathname
				.split("/")
				.filter((pathPart: string) => Boolean(pathPart)).length === 1;

		if (redirectToObs) {
			nav(`.${PATH_OBSERVATION}`, { replace: true });
		}
	}, [location.pathname, nav]);

	return (
		<div className="TemplatesPage-container flex-column full-parent-size">
			<div className="TemplatesPage-container-tabs">
				<Tabs
					tabs={[
						{
							tabLabel: DIC(EDIC_KEY.OBSERVATION),
							path: `.${PATH_OBSERVATION}`,
						},
						{
							tabLabel: DIC(EDIC_KEY.EXPERIMENT),
							path: `.${PATH_EXPERIMENTS}`,
						},
					]}
					validatePathLevel={2}
				/>
			</div>

			<div className="TemplatesPage-tab-container take-remaining-space">
				<Outlet />
			</div>
		</div>
	);
}
