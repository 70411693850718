import React from "react";

import DorianeChip from "../../../../chip/DorianeChip";
import FlexBox, { EGapSize } from "../../../../placement/flex-box/FlexBox";

import "./ChipListCell.scss";

export default function ChipListCell(props: {
	items?: { _id: string; name: string }[];
}) {
	return (
		<FlexBox
			fullParentSize
			gap={EGapSize.SMALL}
			alignItems={"center"}
			className="ChipListCell"
		>
			{props.items?.map((item) => (
				<DorianeChip
					key={item._id}
					chipValue={item._id}
					label={item.name}
				/>
			))}
		</FlexBox>
	);
}
