import { createSelector } from "@reduxjs/toolkit";

import { EntitySerializer } from "common";
import {
	ETaskStatus,
	IObservationRound,
	IObsRoundSerialized,
} from "shared-type";

import { RootState } from "../../store";

const remoteObsRound = (state: RootState) =>
	state.obsRoundEdition.remoteObsRound;

const observationRoundEdition = (state: RootState) =>
	state.obsRoundEdition.observationRoundEdition;

const editingMergeValue = createSelector(
	remoteObsRound,
	observationRoundEdition,
	(
		remoteObsRound,
		observationRoundEdition,
	): IObsRoundSerialized | undefined => {
		if (!remoteObsRound) {
			return undefined;
		}
		return {
			...remoteObsRound,
			...observationRoundEdition,
		};
	},
);
const editingMergeValueDeserialized = createSelector(
	remoteObsRound,
	observationRoundEdition,
	(
		remoteObsRound,
		observationRoundEdition,
	): IObservationRound | undefined => {
		if (!remoteObsRound) {
			return undefined;
		}
		return EntitySerializer.deserialize<IObservationRound>({
			...remoteObsRound,
			...observationRoundEdition,
		});
	},
);

const canDeleteObsRound = (state: RootState) =>
	state.obsRoundEdition.canDeleteObsRound;

const isSelectUserOpen = (state: RootState) =>
	state.obsRoundEdition.isSelectUserOpen;

const isSelectVarGroupOpen = (state: RootState) =>
	state.obsRoundEdition.isSelectVarGroupOpen;

const isCreateVarGroupOpen = (state: RootState) =>
	state.obsRoundEdition.isCreateVarGroupOpen;

const isConsultVarGroupOpen = (state: RootState) =>
	state.obsRoundEdition.isConsultVarGroupOpen;

const isCommentOpen = (state: RootState) => state.obsRoundEdition.isCommentOpen;

const isSendPopupOpen = (state: RootState) =>
	state.obsRoundEdition.isSendPopupOpen;

const isEditingInProgress = (state: RootState) =>
	state.obsRoundEdition.isEditingInProgress;

const isInit = (state: RootState) => state.obsRoundEdition.isInit;

// for display send Notebook button, 2 condition
// - no edition pending to save
// - status on DRAFT
const isSendNotebookDisplayed = (state: RootState) =>
	!isEditingInProgress(state) &&
	state.obsRoundEdition.remoteObsRound?.status === ETaskStatus.DRAFT;

export const selectObsRoundEdition = {
	editingMergeValue,
	editingMergeValueDeserialized,
	observationRoundEdition,
	remoteObsRound,
	canDeleteObsRound,
	isCommentOpen,
	isSendPopupOpen,
	isEditingInProgress,
	isSendNotebookDisplayed,
	isSelectUserOpen,
	isSelectVarGroupOpen,
	isCreateVarGroupOpen,
	isConsultVarGroupOpen,
	isInit,
};
