import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";

import { Icon } from "stories/base-components/Icon/Icon";
import { IIconType } from "stories/base-components/Icon/IconType";
import { Typography } from "stories/base-components/Typography/Typography";
import Colors from "stories/constants/Colors/Colors";
import { StoryTheme } from "stories/StoryTheme";
import Size from "stories/constants/Size/Size";

import logoOpen from "../../../../assets/images/logo-bloomeo-sidebar-open.svg";
import logoCLose from "../../../../assets/images/logo-bloomeo-sidebar-close.svg";

import "./sidebar.scss";

const drawerWidth = 270;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: "90px",
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

export type ISideBarElement = {
	label: string;
	icon: IIconType;
	selectedIcon: IIconType;
	selected?: boolean;
	path?: string;
	multiplePathSelected?: Array<string>;
	action?: () => void;
	nav?: any;
};

function SideBarElement(props: ISideBarElement & { open: boolean }) {
	return (
		<div
			className={
				"sidebar-listElement" +
				(props.selected ? " sidebar-listElement-selected" : "")
			}
			onClick={
				props.path
					? () => props.nav && props.nav(props.path + "")
					: props.action
			}
		>
			<div className="sidebar-listElement-iconContainer">
				<div className="sidebar-listElement-icon-hover">
					<Icon
						name={props.selectedIcon}
						color={Colors.neutral10}
						fontSize={Size.base}
					/>
				</div>
				<div className="sidebar-listElement-icon">
					<Icon
						name={props.selected ? props.selectedIcon : props.icon}
						color={Colors.neutral10}
						fontSize={Size.base}
					/>
				</div>
			</div>
			<div
				style={{
					opacity: props.open ? 1 : 0,
					marginLeft: props.open ? Size.base : "auto",
				}}
			>
				<Typography
					className="sidebar-text"
					variant="heading2"
					color={Colors.neutral10}
					text={props.label}
				/>
			</div>
		</div>
	);
}
export type ISideBar = {
	navList?: Array<ISideBarElement>;
	navBottomList?: Array<ISideBarElement>;
	onLogoClick?: () => void;
	location?: any;
	nav?: any;
};
export function SideBar(props: ISideBar) {
	const defaultOpen = localStorage.getItem("side_bar_open");
	const [open, setOpen] = React.useState(
		defaultOpen !== null ? defaultOpen === "true" : true,
	);
	const location = props.location;

	const pathname = React.useMemo(() => {
		return location?.pathname.split("/")[1];
	}, [location]);

	const isPathSelected = React.useCallback(
		(navElement?: ISideBarElement) => {
			if (navElement?.multiplePathSelected) {
				return navElement?.multiplePathSelected.includes(
					"/" + pathname,
				);
			}
			return navElement?.path
				? navElement?.path.split("/")[1] === pathname
				: false;
		},
		[pathname],
	);
	return (
		<StoryTheme>
			<div className="sidebar-drawer-container">
				<Drawer
					variant="permanent"
					open={open}
					className="sidebar-drawer"
				>
					<div className="sidebar-drawer-inner">
						<div
							onClick={props.onLogoClick}
							style={{
								margin: open
									? `${Size.base} ${Size.base} ${Size.base} 36px`
									: `${Size.base} 0`,
								justifyContent: open
									? "space-between"
									: "center",
							}}
							className="sidebar-logo"
						>
							<img src={open ? logoOpen : logoCLose} />

							<div
								onClick={(event) => {
									// To not execute props.onLogoClick
									event.stopPropagation();
									setOpen(!open);
									localStorage.setItem(
										"side_bar_open",
										(!open).toString(),
									);
								}}
								className="sidebar-logo-inner"
							>
								{open ? (
									<Icon
										name="arrow-left"
										color={Colors.neutral10}
										fontSize={Size.lg}
									/>
								) : (
									<div className="sidebar-drawer-headerIconContainer">
										<div className="sidebar-drawer-headerIconInnerContainer">
											<Icon
												name="arrow-right"
												color={Colors.neutral10}
												fontSize={Size.lg}
											/>
										</div>
									</div>
								)}
							</div>
						</div>
						{props.navList?.map((navElement) => (
							<SideBarElement
								key={navElement.label}
								{...navElement}
								open={open}
								selected={isPathSelected(navElement)}
								nav={props.nav}
							/>
						))}
					</div>
					<div
						style={{ marginBottom: Size.base }}
						className="sidebar-drawer-inner-bottom"
					>
						{props.navBottomList?.map((navElement) => (
							<SideBarElement
								key={navElement.label}
								{...navElement}
								open={open}
								selected={isPathSelected(navElement)}
								nav={props.nav}
							/>
						))}
					</div>
				</Drawer>
			</div>
		</StoryTheme>
	);
}
