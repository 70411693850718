import axios from "axios";

import { RequestProvider } from "request";
import { TENANT_HEADER } from "shared-type";
import { configuration } from "./config";
import { ReduxStore } from "./redux";
import { selectAuth } from "./redux/authentication/authentication.selector";

import { initializeAuthInterceptor } from "./redux/authentication/axios-auth.interceptor";
import { RootState, StoreType } from "./store";

function initRequestProvider(store: StoreType): RequestProvider {
	const axiosInstance = axios.create();

	axiosInstance.interceptors.request.use(async (config) => {
		const tenantId = selectAuth.currentTenantId(
			store.getState() as RootState,
		);

		config.headers = {
			...config.headers,
		};
		if (tenantId) {
			config.headers[TENANT_HEADER] = tenantId;
		}
		return { ...config };
	});
	initializeAuthInterceptor(axiosInstance);

	return new RequestProvider(axiosInstance, {
		// Action
		hostApiCore: configuration.HOST_API_CORE,
		hostApiExperiment: configuration.HOST_API_EXPERIMENT,
		hostApiGermplasm: configuration.HOST_API_GERMPLASM,
		hostApiGrowingArea: configuration.HOST_API_GROWING_AREA,
		// I18N
		hostApiUser: configuration.HOST_API_USER,
		hostApiChart: configuration.HOST_API_CHART,
		hostApiTenant: configuration.HOST_API_TENANT,
		hostApiAttachment: configuration.HOST_API_ATTACHMENT,
	});
}
let request: RequestProvider;

export const useRequest = () => {
	if (request === undefined) {
		request = initRequestProvider(ReduxStore.store);
	}
	return request;
};
