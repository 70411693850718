"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_TRIAL_FIELDS = void 0;
exports.CREATE_TRIAL_FIELDS = [
    "contract",
    "cultivationMethod",
    "currency",
    "density",
    "description",
    "endDate",
    "growingAreaId",
    "label",
    "location",
    "marketSegmentIds",
    "materialLevel",
    "metId",
    "name",
    "objective",
    "plantsNumber",
    "program",
    "project",
    "rowSpacing",
    "rowsNumber",
    "species",
    "startDate",
    "surface",
    "templateExpStructId",
    "templateObsId",
    "trialTotalCosts",
];
