import React from "react";
import {
	FormControlLabel,
	Radio as MUIRadio,
	RadioGroup as MUIRadioGroup,
} from "@mui/material";

import { Text } from "stories/base-components/Typography/Typography";
import { StoryTheme } from "stories/StoryTheme";

import "./radio.scss";

export type IRadio = {
	label?: string;
	checked?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	value?: any;
	labelPlacement?: "top" | "start" | "bottom" | "end";
	bordered?: boolean;
};

const RadioComponent = (radio: IRadio) => {
	const disabledString = radio.disabled ? "-disabled" : "";
	const getImg = (isSelected: boolean) => (
		<>
			<img
				className="radio_doriane-default"
				src={
					process.env.PUBLIC_URL +
					"/radio/" +
					(isSelected ? "selected" : "unselected") +
					disabledString +
					".svg"
				}
				alt="radio1"
			/>
			<img
				className="radio_doriane-hover"
				src={
					process.env.PUBLIC_URL +
					"/radio/" +
					(isSelected ? "selected" : "unselected") +
					(!isSelected && !radio.disabled ? "-hover" : "") +
					disabledString +
					".svg"
				}
				alt="radio2"
			/>
		</>
	);

	return (
		<MUIRadio
			{...radio}
			className="radio_doriane"
			icon={getImg(false)}
			color="info"
			checkedIcon={getImg(true)}
		/>
	);
};

export const Radio = (radio: IRadio) => {
	return (
		<StoryTheme>
			{radio.label ? (
				<FormControlLabel
					control={<RadioComponent {...radio} />}
					label={<Text text={radio.label} />}
					labelPlacement={radio.labelPlacement}
					className={
						"radio_doriane_label" +
						(radio.bordered
							? " radio_bordered radio_bordered-" +
							  (radio.labelPlacement
									? radio.labelPlacement
									: "end")
							: "")
					}
				/>
			) : (
				<RadioComponent {...radio} />
			)}
		</StoryTheme>
	);
};

export type IRadioGroup = IRadio & {
	options?: Array<{ value: any; label: string }>;
	orientation?: "row" | "column";
};

export const RadioGroup = (radioGroup: IRadioGroup) => {
	const options = radioGroup.options || [];
	const { value, ...radioOnly } = radioGroup;
	return (
		<StoryTheme>
			<MUIRadioGroup
				{...radioGroup}
				className={
					"radio_group radio_group-" +
					(radioGroup.labelPlacement
						? radioGroup.labelPlacement
						: "end") +
					" radio_group-" +
					(radioGroup.orientation ? radioGroup.orientation : "row")
				}
				value={value}
			>
				{options.map(
					(option: { value: any; label: string }, index: number) => (
						<Radio
							label={option.label}
							key={"radio_" + index}
							value={option.value}
							{...radioOnly}
						/>
					),
				)}
			</MUIRadioGroup>
		</StoryTheme>
	);
};
