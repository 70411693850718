import React from "react";
import { Skeleton } from "@mui/material";

import FlexBox from "App/components/base-components/placement/flex-box/FlexBox";
import Size from "stories/constants/Size/Size";

export const DataGridSkeleton = () => (
	<FlexBox flexDirection="column" gap={Size.md} flex={1}>
		{new Array(8).fill(1)
.map((row, index) => (
			<Skeleton
				key={index}
				variant="rounded"
				width={"100%"}
				height={Size.x2l}
			/>
		))}
	</FlexBox>
);
