import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IUserInfo } from "shared-type";

export interface IAuthenticationState {
	error?: string;
	user?: IUserInfo;
	tenantId?: string;
}
function createInitialState(): IAuthenticationState {
	return {};
}

const authenticationSlice = createSlice({
	name: "authentication",
	initialState: createInitialState(),
	reducers: {
		loginUser: (
			state,
			action: PayloadAction<{
				user: Omit<IUserInfo, "fullName">;
				tenantId?: string;
			}>,
		) => {
			const loginUser = action.payload.user;
			state.user = {
				...loginUser,
				fullName: `${loginUser.firstName} ${loginUser.lastName}`,
			};
			state.tenantId = action.payload.tenantId;
		},
		selectTenant: (state, action: PayloadAction<{ tenantId?: string }>) => {
			state.tenantId = action.payload.tenantId;
		},
	},
});

export const authenticationReducer = authenticationSlice.reducer;
export const authAction = {
	...authenticationSlice.actions,
};
