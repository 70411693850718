import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { formatString, trimVarFieldsToRemove } from "common";
import {
	EVariableCoreInputType,
	EVariableCoreType,
	EVariableScope,
	IVariableCore,
} from "shared-type";

import { variableValidator } from "App/components/form/custom-variable";
import CustomVariableForm, {
	ICustomVariableForm,
} from "App/components/form/custom-variable/CustomVariableForm";
import { DIC, EDIC_KEY } from "../../../../dictionary";
import useAppSnackbar from "../../../../hooks/useAppSnackbar";
import { ReduxApiError } from "../../../../redux/utils/errors";
import {
	apiVariables,
	variablesCustomHook,
} from "../../../../redux/variables/variables.api";
import {
	customVarFormToObject,
	customVarObjectToForm,
} from "../../../form/custom-variable/transformer";
import { Modal } from "stories/base-components/Modal/Modal";

import "./EditCustomVariableModal.scss";

interface EditCustomVariableModalProps {
	onClose: () => void;
	id: string;
	open: boolean;
}

const defaultVariable: ICustomVariableForm = {
	identifier: "",
	name: "",
	shortName: "",
	description: "",
	type: EVariableCoreType.STRING,
	inputType: EVariableCoreInputType.FREE,
	constraint: { type: EVariableCoreType.STRING },
	limitedChoices: [],
};

export default function EditCustomVariableModal(
	props: EditCustomVariableModalProps,
) {
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();

	const { data: fetchedVariable } =
		variablesCustomHook.useGetVariableByIdQueryDeserialized({
			id: props.id,
			scope: EVariableScope.CUSTOM,
		});
	const { data: variableUsages } = apiVariables.useGetVariableUsageByIdQuery({
		id: props.id,
		scope: EVariableScope.CUSTOM,
	});

	const [updateCustomVariableById] =
		apiVariables.useUpdateCustomVariableByIdMutation();

	const isVariableUsed = useMemo(() => {
		if (!variableUsages) return false;
		return variableUsages.length !== 0;
	}, [variableUsages]);

	const form = useForm<ICustomVariableForm>({
		defaultValues: { ...defaultVariable },
		resolver: yupResolver(variableValidator as any),
	});

	const { handleSubmit, formState } = form;
	const { isSubmitting } = formState;

	const [formTabSelected, setFormTabSelected] = useState<number>(0);

	const handleClose = () => {
		props.onClose();
		form.reset();
		setFormTabSelected(0);
	};

	const onSubmit = async (data: ICustomVariableForm) => {
		const variableData = customVarFormToObject(data) as IVariableCore;
		const { trimmedVariable } = trimVarFieldsToRemove(variableData);
		await updateCustomVariableById({
			id: props.id,
			update: trimmedVariable,
		})
			.unwrap()
			.then(() => {
				enqueueSnackbarSuccess(
					formatString(
						DIC(EDIC_KEY.ENTITY_UPDATED),
						DIC(EDIC_KEY.VARIABLE),
					),
				);
				handleClose();
			})
			.catch((err: ReduxApiError) => {
				console.warn(err);
				enqueueSnackbarError(
					formatString(
						DIC(EDIC_KEY.CANT_ACTION_ENTITY),
						DIC(EDIC_KEY.UPDATE).toLowerCase(),
						DIC(EDIC_KEY.VARIABLE).toLowerCase(),
					),
				);
			});
	};

	const handleUpdate = async () => {
		handleSubmit(
			(data) => onSubmit(data),
			(error) => console.error(error),
		)();
	};

	// Work because no required input in second form part
	const handleNext = async () => {
		handleSubmit(
			(data) => setFormTabSelected(1),
			(error) => {
				// Block step2 only if fields of first step in errors
				if (
					!(
						error.identifier ||
						error.name ||
						error.shortName ||
						error.description
					)
				) {
					setFormTabSelected(1);
				}
			},
		)();
	};

	useEffect(() => {
		if (fetchedVariable !== undefined) {
			form.reset(customVarObjectToForm(fetchedVariable));
		}
	}, [fetchedVariable, form]);

	return (
		<Modal
			open={props.open}
			titleIcon={"brackets-curly"}
			title={DIC(EDIC_KEY.CUSTOM_VARIABLE_EDITION)}
			handleClose={handleClose}
			validateBtn={
				formTabSelected === 1
					? {
							action: () => {
								handleUpdate();
							},
							label: DIC(EDIC_KEY.SAVE),
							isLoading: isSubmitting,
					  }
					: {
							action: () => {
								handleNext();
							},
							label: DIC(EDIC_KEY.NEXT),
							iconName: "arrow-line-right",
					  }
			}
			width={751}
			maxHeight={600}
			stepper={[DIC(EDIC_KEY.GENERAL), DIC(EDIC_KEY.SETTINGS)]}
			stepperSelected={formTabSelected}
			onStepperClick={(step: number) => {
				if (step === 1) {
					handleNext();
				} else {
					setFormTabSelected(step);
				}
			}}
		>
			{fetchedVariable && (
				<div className="EditCustomVariableModal-container flex-column">
					<div className="EditCustomVariableModal-form take-remaining-space">
						<CustomVariableForm
							form={form}
							mode={isVariableUsed ? "used" : "unused"}
							formTabSelected={formTabSelected}
							setFormTabSelected={setFormTabSelected}
						/>
					</div>
				</div>
			)}
		</Modal>
	);
}
