"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ENotebookStatus = void 0;
var ENotebookStatus;
(function (ENotebookStatus) {
    ENotebookStatus["READY_TO_OBSERVE"] = "ready to observe";
    ENotebookStatus["IN_PROGRESS"] = "in progress";
    ENotebookStatus["REVIEWED"] = "reviewed";
    ENotebookStatus["COMPLETED"] = "completed";
    ENotebookStatus["DISABLED"] = "disabled";
})(ENotebookStatus = exports.ENotebookStatus || (exports.ENotebookStatus = {}));
