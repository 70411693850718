import dayjs, { Dayjs } from "dayjs";
import React, { useEffect } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { EGermplasmLevel, ETaskStatus, ETrialLevel, IUser } from "shared-type";

import { EFormInputType } from "App/components/base-components/dynamic-form";
import DynamicForm from "App/components/base-components/dynamic-form/DynamicForm";
import FormSection from "App/components/base-components/dynamic-form/form-section/FormSection";
import VariableGroupCard from "../../../../../stories/base-components/Card/mostUsed/VariableGroupCard";
import { FormMode } from "../../../../const";
import { DIC, EDIC_KEY } from "../../../../dictionary";
import {
	defaultUnitGenotypeIdentifiers,
	defaultUnitLotIdentifiers,
} from "../../../../mock";
import { selectECW } from "../../../../redux/ECW/ECW.selector";
import { obsRoundEditionActions } from "../../../../redux/observation-round-edition/obs-round-edition.slice";
import { apiVariableGroup } from "../../../../redux/variable-group/variable-group.api";
import { AppDispatch } from "../../../../store";
import DorianeButton from "../../../base-components/button/DorianeButton";
import { unitIdentifiersColumns } from "../../../base-components/data-grid/doriane-data-grid/default-grid-columns/UnitIdentifiersColumns";
import DorianeDataGrid from "../../../base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import Box, { EPaddingSize } from "../../../base-components/placement/box/Box";
import FlexBox, {
	EGapSize,
} from "../../../base-components/placement/flex-box/FlexBox";
import UserCard from "../../../users/user-card/UserCard";

import "./ObservationRoundForm.scss";

export interface IUnitIdentifiers {
	unitName: ETrialLevel;
	identifiers: string[];
}

export interface IObservationRoundForm {
	name: string;
	identifier: string;
	label?: string;
	description?: string;
	stage: string;
	startDate: Dayjs;
	endDate: Dayjs;
	eventOption: boolean;
	status?: ETaskStatus;
	team: Array<string>;
	variableGroupId: string;
}

interface ObservationRoundFormProps {
	isTemplate: boolean;
	mode: FormMode;
	form: UseFormReturn<IObservationRoundForm>;
	obsRoundUsers?: IUser[];
	onSelectUserClick?: () => void;
	onSelectVarGroupClick?: () => void;
	onVarGroupClick?: () => void;
}

export default function ObservationRoundForm(props: ObservationRoundFormProps) {
	const dispatch = useDispatch<AppDispatch>();
	const form = props.form;
	const [variableGroupId, startDate] = useWatch({
		control: form.control,
		name: ["variableGroupId", "startDate"],
	});

	const trial = useSelector(selectECW.trialRemote);
	const { data: fetchedVariableGroup } =
		apiVariableGroup.useGetVariableGroupByIdQuery(variableGroupId);

	const displayUnitIdentifier = () => {
		if (trial?.materialLevel?.germplasmLevel === EGermplasmLevel.LOT) {
			return defaultUnitLotIdentifiers;
		}
		return defaultUnitGenotypeIdentifiers;
	};

	// Close user selection drawer on form dismount
	useEffect(() => {
		return () => {
			dispatch(obsRoundEditionActions.setSelectUserOpen(false));
		};
	}, [dispatch]);

	return (
		<Box padding={EPaddingSize.MEDIUM}>
			<FlexBox
				paddingBottom={EPaddingSize.SMALL}
				flexDirection={"column"}
			>
				<FormSection title={DIC(EDIC_KEY.GENERAL_INFORMATION)}>
					<DynamicForm
						control={form.control}
						formInfos={[
							{
								inputType: EFormInputType.STRING,
								id: "name",
								label: DIC(EDIC_KEY.NAME),
								required: true,
								disabled: props.mode === "used",
							},
							{
								inputType: EFormInputType.STRING,
								id: "identifier",
								label: DIC(EDIC_KEY.ID),
								required: true,
								disabled: props.mode === "used",
							},
							{
								inputType: EFormInputType.STRING,
								id: "label",
								label: DIC(EDIC_KEY.LABEL),
								disabled: props.mode === "used",
							},
							{
								inputType: EFormInputType.STRING,
								id: "stage",
								label: DIC(EDIC_KEY.STAGE),
								disabled: props.mode === "used",
							},
							{
								inputType: EFormInputType.STRING,
								id: "description",
								label: DIC(EDIC_KEY.DESCRIPTION),
								disabled: props.mode === "used",
								multiline: true,
								takeAllRow: true,
							},
						]}
					/>
				</FormSection>
				<FormSection title={DIC(EDIC_KEY.PERIOD)}>
					<DynamicForm
						control={form.control}
						formInfos={[
							{
								inputType: EFormInputType.DATE,
								id: "startDate",
								label: DIC(EDIC_KEY.FROM),
								required: true,
								disabled: props.mode === "used",
							},
							{
								inputType: EFormInputType.DATE,
								id: "endDate",
								label: DIC(EDIC_KEY.TO),
								disabled: props.mode === "used",
								required: true,
								minDate: dayjs(startDate),
							},
						]}
					/>
				</FormSection>
				<FormSection title={DIC(EDIC_KEY.EVENTS)}>
					<DynamicForm
						control={form.control}
						formInfos={[
							{
								inputType: EFormInputType.CHECKBOX,
								id: "eventOption",
								label: DIC(EDIC_KEY.ALLOW_EVENTS_NOTATION),
								disabled: props.mode === "used",
							},
						]}
					/>
				</FormSection>
			</FlexBox>
			<FlexBox
				paddingBottom={EPaddingSize.MEDIUM}
				gap={EGapSize.SMALL}
				flexDirection={"column"}
			>
				<h1>{DIC(EDIC_KEY.IDENTIFIERS)}</h1>
				<DorianeDataGrid<IUnitIdentifiers>
					rows={displayUnitIdentifier()}
					columns={unitIdentifiersColumns}
				/>
			</FlexBox>
			<FlexBox
				paddingBottom={EPaddingSize.MEDIUM}
				gap={EGapSize.SMALL}
				flexDirection={"column"}
			>
				<FlexBox justifyContent={"space-between"} alignItems={"center"}>
					<FlexBox gap={EGapSize.MEDIUM} alignItems={"center"}>
						<h1>{`${DIC(EDIC_KEY.VARIABLE_GROUP)} *`}</h1>
						{form.formState.errors.variableGroupId?.message && (
							<p className="ObservationRoundForm-error">
								{"Cannot be empty"}
							</p>
						)}
					</FlexBox>
					{props.mode !== "used" && props.onSelectVarGroupClick && (
						<DorianeButton
							dorianeStyle="tertiary"
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							onClick={() => props.onSelectVarGroupClick!()}
						>
							{DIC(EDIC_KEY.EDIT_SELECTION)}
						</DorianeButton>
					)}
				</FlexBox>
				<FlexBox flexDirection={"column"}>
					{fetchedVariableGroup && (
						<VariableGroupCard
							variableGroup={fetchedVariableGroup}
							onClick={props.onVarGroupClick}
						/>
					)}
				</FlexBox>
			</FlexBox>
			{!props.isTemplate && (
				<FlexBox
					paddingBottom={EPaddingSize.MEDIUM}
					gap={EGapSize.SMALL}
					flexDirection={"column"}
				>
					<FlexBox
						justifyContent={"space-between"}
						alignItems={"center"}
					>
						<h1>{DIC(EDIC_KEY.TEAM)}</h1>
						{props.mode !== "used" && props.onSelectUserClick && (
							<DorianeButton
								dorianeStyle="tertiary"
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								onClick={() => props.onSelectUserClick!()}
							>
								{DIC(EDIC_KEY.EDIT_SELECTION)}
							</DorianeButton>
						)}
					</FlexBox>
					<h2>{DIC(EDIC_KEY.OBSERVERS)}</h2>
					<FlexBox gap={EGapSize.SMALL} flexDirection={"column"}>
						{(props.obsRoundUsers ?? []).map((user) => (
							<UserCard user={user} key={user.id} disabledClick />
						))}
					</FlexBox>
				</FlexBox>
			)}
		</Box>
	);
}
