import { GridCellKind, TextCell, Theme } from "@glideapps/glide-data-grid";

import { IVariableCoreSerialized } from "shared-type";

import { getDicBoolean } from "../../../../../../../../dictionary/dictionary-key-getter";
import { CommonNotationCellOption } from "../notation-cell.types";
import { computeLimitedChoicesDisplay } from "./utils";

export const booleanNotationCellDrawer = (
	notationValue?: boolean,
	variable?: IVariableCoreSerialized,
	option?: CommonNotationCellOption,
) => {
	const theme: Partial<Theme> = option?.themeOverride ?? {};
	const stringValue = getDicBoolean(notationValue);
	const cell: TextCell = {
		kind: GridCellKind.Text,
		data: stringValue,
		displayData: computeLimitedChoicesDisplay(
			getDicBoolean(notationValue),
			notationValue,
			variable,
		),
		allowOverlay: option?.isModifiable ?? false,
		readonly: !option?.isModifiable ?? true,
		themeOverride: theme,
		contentAlign: "center",
	};
	return cell;
};
