import { yupResolver } from "@hookform/resolvers/yup";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { EntitySerializer, formatString } from "common";
import { EExperimentStatus } from "shared-type";

import { DIC, EDIC_KEY } from "../../../../../dictionary";
import useAppSnackbar from "../../../../../hooks/useAppSnackbar";
import { selectECW } from "../../../../../redux/ECW/ECW.selector";
import { ECWAction } from "../../../../../redux/ECW/ECW.slice";
import { trialWizardSaveAction } from "../../../../../redux/ECW/trial-wizard.save";
import { apiMET } from "../../../../../redux/experiment/met/met.api";
import { AppDispatch } from "../../../../../store";
import DorianeButton from "../../../../base-components/button/DorianeButton";
import BottomMenu from "../../../../base-components/menu/bottom-menu/BottomMenu";
import DorianeDrawer from "../../../../base-components/modal/DorianeDrawer";
import {
	expConclusionFormToObject,
	expConclusionObjectToForm,
	expConclusionValidator,
} from "../../../../form/experiment/conclusion";
import ExpConclusionForm, {
	IExpConclusionForm,
} from "../../../../form/experiment/conclusion/ExpConclusionForm";

import "./ExperimentConclusionModal.scss";

export type EExpConclusionStatus =
	| EExperimentStatus.NOT_VALIDATED
	| EExperimentStatus.VALIDATED;

export default function ExperimentConclusionModal() {
	const dispatch = useDispatch<AppDispatch>();
	const { metId } = useParams();
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();

	const form = useForm<IExpConclusionForm>({
		resolver: yupResolver(expConclusionValidator as any),
	});

	const remoteTrial = useSelector(selectECW.trialRemote);
	const wizardType = useSelector(selectECW.wizardType);

	const { data: remoteMET } = apiMET.useGetMETByIdQuery(metId ?? skipToken);
	const [patchMETMutation] = apiMET.usePatchMETByIdMutation();

	const experiment = useMemo(() => {
		if (wizardType === "MET" && remoteMET) {
			return remoteMET;
		}
		if (wizardType === "SET" && remoteTrial) {
			return remoteTrial;
		}
	}, [remoteMET, remoteTrial, wizardType]);

	const handleClose = useCallback(() => {
		dispatch(ECWAction.setConclusionIsOpen(false));
	}, [dispatch]);

	const saveConclusionMethod = useCallback(
		(data: IExpConclusionForm) => {
			if (wizardType === "MET" && metId) {
				return patchMETMutation({
					id: metId,
					met: expConclusionFormToObject(data),
				});
			}
			return dispatch(
				trialWizardSaveAction.saveTrialConclusion(
					expConclusionFormToObject(data),
				),
			);
		},
		[dispatch, metId, patchMETMutation, wizardType],
	);

	const handleFormSubmit = useCallback(async () => {
		form.clearErrors();
		form.handleSubmit(
			async (data) => {
				return await saveConclusionMethod(data)
					.unwrap()
					.then(() => {
						enqueueSnackbarSuccess(
							formatString(
								DIC(EDIC_KEY.ENTITY_UPDATED),
								DIC(EDIC_KEY.CONCLUSION),
							),
						);
						handleClose();
					})
					.catch((err) => {
						console.warn(err);
						enqueueSnackbarError(
							formatString(
								DIC(EDIC_KEY.CANT_ACTION_ENTITY),
								DIC(EDIC_KEY.UPDATE).toLowerCase(),
								DIC(EDIC_KEY.CONCLUSION).toLowerCase(),
							),
						);
					});
			},
			() => enqueueSnackbarError(DIC(EDIC_KEY.BAD_INPUT)),
		)();
	}, [
		enqueueSnackbarError,
		enqueueSnackbarSuccess,
		form,
		handleClose,
		saveConclusionMethod,
	]);

	useEffect(() => {
		if (!experiment) {
			form.reset();
			return;
		}
		form.reset(
			expConclusionObjectToForm(
				// Trial is already deserialize so need to put any
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				EntitySerializer.deserialize<any>(experiment),
			),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [experiment]);

	return (
		<DorianeDrawer
			onClose={() => handleClose()}
			title={
				wizardType === "MET"
					? DIC(EDIC_KEY.MET_CONCLUSION)
					: DIC(EDIC_KEY.TRIAL_CONCLUSION)
			}
			absolutePos
		>
			<div className="ExperimentConclusionModal-body full-parent-size">
				<ExpConclusionForm form={form} />
			</div>
			<BottomMenu
				customButton={
					<DorianeButton
						dorianeStyle="primary"
						onClick={() => handleFormSubmit()}
					>
						{DIC(EDIC_KEY.CONCLUDE_TRIAL)}
					</DorianeButton>
				}
			/>
		</DorianeDrawer>
	);
}
