import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { formatString } from "common";
import { ITenant } from "shared-type";

import { DIC, EDIC_KEY } from "../../dictionary";
import { useRequest } from "../../request-provider";
import { defaultApiError } from "../utils/errors";

export const apiTenant = createApi({
	reducerPath: "api-tenant",
	baseQuery: () => ({ data: undefined }),
	// global configuration for the api (in second)
	keepUnusedDataFor: 60,
	// global configuration for the api
	refetchOnMountOrArgChange: 60,
	tagTypes: ["Update", "Delete", "Create"],
	endpoints: (build) => ({
		getTenantById: build.query<ITenant, string>({
			queryFn: async (id) => {
				try {
					const result = await useRequest()
						.tenant.getTenantById(id)
						.catch((err: any) => {
							throw err;
						});
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.FETCH).toLowerCase(),
							DIC(EDIC_KEY.TENANT).toLowerCase(),
						),
					);
				}
			},
		}),
	}),
});
