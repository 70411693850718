import { createApi } from "@reduxjs/toolkit/query/react";
import { IFilter, IResource, ISort, IUpdateResource } from "shared-type";

import { useRequest } from "../../request-provider";
import { defaultApiError, ReduxApiError } from "../utils/errors";

export const apiResource = createApi({
	reducerPath: "api-resource",
	baseQuery: () => ({ data: undefined }),

	// global cache configuration for the api (in second)
	keepUnusedDataFor: 30,
	refetchOnMountOrArgChange: 30,

	// update tag
	tagTypes: ["Update", "Delete", "Create"],

	// api definition
	endpoints: (build) => ({
		gets: build.query<
			IResource[],
			{ page?: number; pageSize?: number; filter?: IFilter; sort?: ISort }
		>({
			queryFn: async ({ page, pageSize, filter, sort }) => {
				try {
					const result = await useRequest().resource.getResources(
						page,
						pageSize,
						filter,
						sort,
					);
					return {
						data: result.data.data,
					};
				} catch (err) {
					return defaultApiError(err, "Unable to fetch resources");
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),

		getById: build.query<IResource | undefined, string | undefined>({
			queryFn: async (id) => {
				try {
					if (!id) {
						return { data: undefined };
					}
					const result = await useRequest().resource.getResourceById(
						id,
					);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch resource with id : " + id,
					);
				}
			},
			providesTags: (result, error, id) => {
				return [
					{ type: "Delete", id: id },
					{ type: "Update", id: id },
					{ type: "Create", id: id },
				];
			},
		}),

		updateResource: build.mutation<
			void,
			{ id: string; update: IUpdateResource }
		>({
			queryFn: async (arg) => {
				if (!arg.id) {
					const error: { error: ReduxApiError } = {
						error: {
							message: "Id is required for updated a resource",
						},
					};
					return error;
				}
				try {
					const result = await useRequest()
						.resource.update(arg.id, arg.update)
						.then((resp) => resp.data);
					return { data: result };
				} catch (err) {
					return defaultApiError(err, "Unable to update resource");
				}
			},
			invalidatesTags: (result, error, arg) => {
				if (error) {
					return [];
				}
				return [
					{ type: "Update", id: arg.id },
					{ type: "Update", id: "ALL" },
				];
			},
		}),
	}),
});
