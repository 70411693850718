import { Autocomplete, TextField, Popper } from "@mui/material";
import React from "react";

import styles from "../../../../sassTheme";

import "./SearchableCombobox.scss";

interface SearchableComboboxProps<T> {
	className?: string;
	required?: boolean;
	value: T | null;
	label: string;
	onChange: (value: T | null) => void;
	comboboxValues: T[];
	translateMethod: (value: T) => string;
}
export function SearchableCombobox<T>(props: SearchableComboboxProps<T>) {
	return (
		<Autocomplete<T, undefined, boolean>
			className={
				"SearchableCombobox" +
				(props.className ? " " + props.className : "")
			}
			style={{ height: styles["element-min-height"] }}
			disablePortal
			options={props.comboboxValues}
			disableClearable
			onChange={(event, value) => props.onChange(value)}
			renderInput={(params) => (
				<TextField {...params} label={props.label} />
			)}
			value={props.value}
			getOptionLabel={(option) => props.translateMethod(option)}
			componentsProps={{
				popper: {
					className: "SearchableCombobox-popper",
					modifiers: [
						{
							name: "offset",
							options: {
								offset: [0, 10],
							},
						},
					],
				},
			}}
		/>
	);
}
