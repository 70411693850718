import React from "react";

import { Subtitle } from "stories/base-components/Typography/Typography";
import Box from "../../placement/box/Box";
import Size from "stories/constants/Size/Size";

import "./FormSection.scss";

export default function FormSection({
	children,
	title,
}: {
	children: React.ReactNode;
	title?: string;
}) {
	return (
		<div className="FormSection flex-column">
			{title && (
				<Box marginBottom={Size.md}>
					<Subtitle text={title} />
				</Box>
			)}
			{children}
		</div>
	);
}
