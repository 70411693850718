import dayjs from "dayjs";
import { IGrowingArea } from "shared-type";
import { IGrowingAreaForm } from "./GrowingAreaForm";

// converter
export function growingAreaFormToObject(
	form: Partial<IGrowingAreaForm>,
): Partial<IGrowingArea> {
	let location: IGrowingArea["location"] | undefined;
	if (form?.longitude !== undefined || form.latitude !== undefined) {
		location = {
			type: "Point",
			coordinates: [
				form.longitude ? Number(form.longitude) : 0,
				form.latitude ? Number(form.latitude) : 0,
			],
		};
	}
	return {
		climate: form.climate,
		country: form.country,
		description: form.description,
		endDate: form.endDate?.toDate(),
		identifier: form.identifier,
		label: form.label,
		location: location,
		name: form.name,
		region: form.region,
		season: form.season,
		soilDetails: form.soilDetails,
		startDate: form.startDate?.toDate(),
		soilType: form.soilType,
		users: form.users,
	};
}

export function growingAreaObjectToForm(
	growingArea: Partial<IGrowingArea>,
): Partial<IGrowingAreaForm> {
	return {
		climate: growingArea.climate,
		country: growingArea.country,
		description: growingArea.description,
		identifier: growingArea.identifier,
		label: growingArea.label,
		name: growingArea.name,
		region: growingArea.region,
		season: growingArea.season,
		soilDetails: growingArea.soilDetails,
		startDate: growingArea.startDate
			? dayjs(growingArea.startDate)
			: undefined,
		endDate: growingArea.endDate ? dayjs(growingArea.endDate) : undefined,
		soilType: growingArea.soilType,
		users: growingArea.users,
		latitude: growingArea.location?.coordinates[1],
		longitude: growingArea.location?.coordinates[0],
	};
}
