import { createAsyncThunk } from "@reduxjs/toolkit";

import { EntitySerializer, templateExpStructsMock } from "common";
import { ITrial } from "shared-type";

import { useRequest } from "App/request-provider";
import { ECWAction } from "./ECW.slice";

const fetchTrialAndDependencies = createAsyncThunk(
	"trialWizard/fetchTrialAndDependencies",
	async (idTrial: string, thunkApi) => {
		const trial = await useRequest()
			.trial.getTrialById(idTrial)
			.then((resp) => EntitySerializer.serialize<ITrial>(resp.data));

		const getTemplateExpStruct = async () => {
			if (trial.templateExpStructId?.length) {
				return templateExpStructsMock.find(
					(elt) => elt._id === trial.templateExpStructId,
				);
			}
		};
		const [templateExpStruct] = await Promise.all([getTemplateExpStruct()]);

		thunkApi.dispatch(
			ECWAction.initStateFromTrial({
				trial: trial,
				templateExpStruct: templateExpStruct,
			}),
		);
	},
);

export const trialWizardFetchAction = {
	fetchTrialAndDependencies,
};
