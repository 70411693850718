"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GrowingAreaBusinessKey2 = exports.GrowingAreaBusinessKey1 = void 0;
exports.GrowingAreaBusinessKey1 = [
    "identifier",
];
exports.GrowingAreaBusinessKey2 = [
    "name",
    "season",
];
