import * as React from "react";
import { useCallback } from "react";
import { Tab, Tabs as MUITabs } from "@mui/material";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { StoryTheme } from "stories/StoryTheme";

import "./tabs.scss";

export type ITabs = {
	tabs: Array<{
		tabLabel: string;
		path: string;
		onTabClick?: () => boolean; // Boolean for allowing navigation or not
	}>;
	defaultSelected?: number;
	validatePathLevel?: number;
};

export function Tabs(props: ITabs) {
	const nav = useNavigate();
	const [tabSelected, setTabSelected] = React.useState(
		props.defaultSelected ? props.defaultSelected : 0,
	);

	const pathname = props.validatePathLevel
		? "./" + location.pathname.split("/")[props.validatePathLevel]
		: "/" + location.pathname.split("/")[1];

	const selectedIndexPath = props.tabs
		.map((tab) => tab.path)
		.findIndex((tabPath) => tabPath === pathname);

	const tabSelectedShown =
		selectedIndexPath === -1 ? tabSelected : selectedIndexPath;

	const navTo = useCallback(
		(newTabValue: number, path: string, nav?: NavigateFunction) => {
			if (nav) {
				nav(path);
			}
			setTabSelected(newTabValue);
		},
		[],
	);

	return (
		<StoryTheme>
			<div className="TabsContainer">
				<MUITabs
					onChange={(
						event: React.SyntheticEvent,
						newValue: number,
					) => {
						const tab = props.tabs[newValue];
						if (tab.onTabClick) {
							if (tab.onTabClick()) {
								navTo(newValue, tab.path, nav);
							}
						} else {
							navTo(newValue, tab.path, nav);
						}
					}}
					value={tabSelectedShown}
					color={"secondary"}
				>
					{props.tabs.map((tab, index) => (
						<Tab label={tab.tabLabel} key={"tab_" + index} />
					))}
				</MUITabs>
			</div>
		</StoryTheme>
	);
}
