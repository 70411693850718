import {
	FilledInputProps,
	InputProps,
	OutlinedInputProps,
} from "@mui/material";
import { Dayjs } from "dayjs";
import { ChangeEvent } from "react";
import { Control } from "react-hook-form";

export enum EFormInputType {
	STRING,
	NUMBER,
	DATE,
	BOOLEAN,
	COMBOBOX,
	CHIP_COMBOBOX,
	COLOR,
	CHECKBOX,
}

export interface BaseInputInfo {
	label: string;
	inputType: EFormInputType;
	id: string;
	disabled?: boolean;
	required?: boolean;
	takeAllRow?: boolean;
}

interface StringInputInfo extends BaseInputInfo {
	multiline?: boolean;
	inputType: EFormInputType.STRING;
	InputProps?:
		| Partial<InputProps>
		| Partial<FilledInputProps>
		| Partial<OutlinedInputProps>;
	name?: string;
	type?: string;
	placeholder?: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface NumberInputInfo extends BaseInputInfo {
	inputType: EFormInputType.NUMBER;
	onChange?: (
		info: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => void;
	value?: string;
}
interface BooleanInputInfo extends BaseInputInfo {
	inputType: EFormInputType.BOOLEAN;
}

interface DateInputInfo extends BaseInputInfo {
	minDate?: Dayjs;
	inputType: EFormInputType.DATE;
}

interface ComboboxInputInfo extends BaseInputInfo {
	comboboxValues: (string | number)[];
	inputType: EFormInputType.CHIP_COMBOBOX | EFormInputType.COMBOBOX;
	translateMethod: (value: any) => string;
	onChange?: (info: any) => void;
	value?: any;
}
interface ColorInputInfo extends BaseInputInfo {
	inputType: EFormInputType.COLOR;
}
interface CheckBoxInfo extends BaseInputInfo {
	inputType: EFormInputType.CHECKBOX;
}

export type InputInformation =
	| StringInputInfo
	| NumberInputInfo
	| BooleanInputInfo
	| DateInputInfo
	| ComboboxInputInfo
	| ColorInputInfo
	| CheckBoxInfo;

export type StringInputInfoWithProps = {
	inputProps: StringInputInfo;
	control: Control;
};
export type NumberInputInfoWithProps = {
	inputProps: NumberInputInfo;
	control: Control;
};
export type BooleanInputInfoWithProps = {
	inputProps: BooleanInputInfo;
	control: Control;
};
export type DateInputInfoWithProps = {
	inputProps: DateInputInfo;
	control: Control;
};
export type ComboboxInputInfoWithProps = {
	inputProps: ComboboxInputInfo;
	control: Control;
};
export type ColorInputInfoWithProps = {
	inputProps: ColorInputInfo;
	control: Control;
};

export type CheckBoxInfoWithProps = {
	inputProps: CheckBoxInfo;
	control: Control;
};

export type InputInfoWithProps = {
	inputProps:
		| StringInputInfo
		| NumberInputInfo
		| BooleanInputInfo
		| DateInputInfo
		| ComboboxInputInfo
		| ColorInputInfo
		| CheckBoxInfo;
	control: Control;
	noGap?: boolean;
};
