import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IVariableState {
	commentIdSelected?: string;
}
function createInitialState(): IVariableState {
	return {};
}
const variable = createSlice({
	name: "variable",
	initialState: createInitialState(),
	reducers: {
		changeSelectedCommentId: (
			state,
			action: PayloadAction<string | undefined>,
		) => {
			state.commentIdSelected = action.payload;
		},
	},
});

export const variableReducer = variable.reducer;
export const variableAction = {
	...variable.actions,
};
