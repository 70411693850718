import { DateConstraints, getErrorMessage, validateDate } from "common";
import dayjs, { Dayjs } from "dayjs";
import * as Yup from "yup";

export const dayjsValidator: Yup.TestConfig = {
	test: (value: any) => {
		if (value === null || value === undefined) {
			return true; // ignore
		}
		return (value as Dayjs)?.isValid() ?? false;
	},
	message: () => "Not a valid date",
	name: "validDayjs",
};

export const dayjsYup = Yup.mixed((input): input is Dayjs =>
	dayjs.isDayjs(input),
)
	.transform((value: any, input, ctx) => {
		if (value === null || value === undefined || ctx.isType(value)) {
			return value; // ignore
		}
		return dayjs(value);
	})
	.test(dayjsValidator);

export const dayjsYupTestConstraint: (
	constraint: DateConstraints,
) => Yup.TestConfig = (constraint: DateConstraints) => ({
	test: (value: any, ctx) => {
		const errors = validateDate(value?.toDate?.(), constraint);
		if (errors.length > 0) {
			return ctx.createError({ message: getErrorMessage(errors) });
		}
		return true;
	},
	name: "validDayjsConstraint",
});
