import React from "react";

import { IGrowingArea } from "shared-type";

import GrowingAreaBanner, {
	IGrowingAreaBannerProps,
} from "./GrowingAreaBanner";

import "./GrowingAreaCard.scss";

export interface IGrowingAreaCardProps {
	growingArea: IGrowingArea;
	onClick?: () => void;
	disabledClick?: boolean;
	onDelete?: () => void;
}

export default function GrowingAreaCard(props: IGrowingAreaCardProps) {
	const enhancedProps: IGrowingAreaBannerProps = {
		...props,
		className: "GrowingAreaCard",
	};

	return <GrowingAreaBanner {...enhancedProps} />;
}
