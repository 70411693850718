export const variants = {
	display1: {
		fontSize: "2rem",
		fontWeight: 500,
	},
	display2: {
		fontSize: "1.5rem",
		fontWeight: 500,
	},
	heading1: {
		fontSize: "1.25rem",
		fontWeight: 500,
	},
	button: {
		fontSize: "1rem",
		fontWeight: 600,
	},
	heading2: {
		fontSize: "1rem",
		fontWeight: 500,
	},
	body1: {
		fontSize: "1rem",
		fontWeight: 400,
	},
	body2: {
		fontSize: "0.875rem",
		fontWeight: 400,
	},
	body2Medium: {
		fontSize: "0.875rem",
		fontWeight: 500,
	},
	smallSemiBold: {
		fontSize: "0.75rem",
		fontWeight: 600,
	},
	smallMedium: {
		fontSize: "0.75rem",
		fontWeight: 500,
	},
	smallRegular: {
		fontSize: "0.75rem",
		fontWeight: 400,
	},
};
