import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { CSSProperties } from "react";

import styles from "../../../../sassTheme";

import "./Combobox.scss";

interface ComboboxProps<T> {
	className?: string;
	style?: CSSProperties;
	required?: boolean;
	value: T | undefined;
	label: string;
	onChange: (value: T) => void;
	comboboxValues: T[];
	translateMethod: (value: T) => string;
}
export default function Combobox<T extends string | number>(
	props: ComboboxProps<T>,
) {
	return (
		<FormControl
			className={
				"Combobox" + (props.className ? " " + props.className : "")
			}
			style={props.style}
		>
			<InputLabel required={props.required}>{props.label}</InputLabel>
			<Select
				value={props.value ?? ""}
				label={props.label}
				onChange={(event) => props.onChange(event.target.value as T)}
				style={{ height: styles["element-min-height"] }}
			>
				{props.comboboxValues.map((selectValue, index) => {
					return (
						<MenuItem key={index} value={selectValue}>
							{props.translateMethod(selectValue)}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
}
