import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import {
	DEFAULT_CREATE_USER_PASSWORD,
	EUserRole,
	ICreateUser,
	IUser,
} from "shared-type";

import { userValidator } from "App/components/form/user";
import UserForm, { IUserForm } from "../../../components/form/user/UserForm";
import { DIC, EDIC_KEY } from "../../../dictionary";
import useAppSnackbar from "../../../hooks/useAppSnackbar";
import { apiUser } from "../../../redux/user/user.api";
import { ApiErrorSnackbar } from "../../../redux/utils/api-error-snackbar/ApiErrorSnackbar";
import { ReduxApiError } from "../../../redux/utils/errors";
import { userFormToObject } from "../../form/user/transformer";
import { Modal } from "stories/base-components/Modal/Modal";
import FlexBox from "App/components/base-components/placement/flex-box/FlexBox";

import styles from "../../../../sassTheme";
import "./CreateUserModal.scss";

interface CreateUserModalProps {
	onClose: () => void;
	open: boolean;
}

export const defaultUser: IUser = {
	firstName: "",
	lastName: "",
	mail: "",
	role: EUserRole.OBSERVER,
	color: styles["primary-color-light-1"],
	id: "",
	tenantId: "",
};

export default function CreateUserModal(props: CreateUserModalProps) {
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();

	const [createUserMutation] = apiUser.useCreateUserMutation();
	const [createAndCloseLoading, setCreateAndCloseLoading] =
		useState<boolean>(false);
	const [createAndContinueLoading, setCreateAndContinueLoading] =
		useState<boolean>(false);

	const form = useForm<IUserForm>({
		defaultValues: defaultUser,
		resolver: yupResolver(userValidator as Yup.ObjectSchema<IUserForm>),
	});

	const { handleSubmit, formState } = form;
	const { isSubmitting } = formState;

	useEffect(() => {
		if (!isSubmitting) {
			setCreateAndCloseLoading(false);
			setCreateAndContinueLoading(false);
		}
	}, [isSubmitting]);

	async function onSubmit(data: IUserForm, continueMode?: boolean) {
		if (continueMode) {
			setCreateAndContinueLoading(true);
		} else {
			setCreateAndCloseLoading(true);
		}
		const userCreation = userFormToObject(data) as ICreateUser;

		await createUserMutation({
			...userCreation,
			defaultPassword: DEFAULT_CREATE_USER_PASSWORD,
		})
			.unwrap()
			.then((resp) => {
				enqueueSnackbarSuccess(DIC(EDIC_KEY.SAVE));
				if (continueMode) {
					form.reset(defaultUser);
				} else {
					props.onClose();
				}
			})
			.catch((err: ReduxApiError) => {
				console.warn(err);
				enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
			});
	}

	const createUser = async (continueMode: boolean) => {
		handleSubmit(
			(data) => onSubmit(data, continueMode),
			(error) => console.error(error),
		)();
	};

	return (
		<Modal
			open={props.open}
			titleIcon={"edit"}
			title={DIC(EDIC_KEY.NEW_USER)}
			handleClose={props.onClose}
			validateBtn={{
				action: () => createUser(true),
				isLoading: createAndContinueLoading,
				label: DIC(EDIC_KEY.CREATE_AND_CONTINUE),
			}}
			secondaryBtn={{
				action: () => createUser(false),
				isLoading: createAndCloseLoading,
				label: DIC(EDIC_KEY.CREATE_AND_CLOSE),
			}}
			width={800}
		>
			<FlexBox>
				<UserForm mode="creation" form={form} />
			</FlexBox>
		</Modal>
	);
}
