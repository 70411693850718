import React from "react";
import { RouteObject } from "react-router-dom";

import { PATH_USERS } from "../../../routes";
import UsersPage from "../../../Views/main/settings/users/UsersPage";

export function userRouter(): RouteObject {
	return {
		path: PATH_USERS,
		children: [
			{
				path: PATH_USERS,
				element: <UsersPage />,
			},
		],
	};
}
