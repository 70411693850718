import { OptionsObject, SnackbarMessage, useSnackbar } from "notistack";
import { useCallback } from "react";

export default function useAppSnackbar() {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const enqueueSnackbarError = useCallback(
		(message: SnackbarMessage, option?: OptionsObject) => {
			return enqueueSnackbar(message, { variant: "error", ...option });
		},
		[enqueueSnackbar],
	);
	const enqueueSnackbarSuccess = useCallback(
		(message: SnackbarMessage, option?: OptionsObject) => {
			return enqueueSnackbar(message, { variant: "success", ...option });
		},
		[enqueueSnackbar],
	);
	const enqueueSnackbarWarning = useCallback(
		(message: SnackbarMessage, option?: OptionsObject) => {
			return enqueueSnackbar(message, { variant: "warning", ...option });
		},
		[enqueueSnackbar],
	);

	return {
		enqueueSnackbar,
		closeSnackbar,
		enqueueSnackbarError,
		enqueueSnackbarSuccess,
		enqueueSnackbarWarning,
	};
}
