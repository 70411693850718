import { createSelector } from "@reduxjs/toolkit";

import { computeTotalPagesNb } from "common";
import { PageInformation } from "shared-type";

import { RootState } from "App/store";

const genotypeList = (state: RootState) =>
	state.germplasm.genotype?.genotypeList;

const genotypePageInfo = createSelector(
	(state: RootState) => state.germplasm.genotype?.paginationInfo,
	(
		paginationInfo,
	): { totalPage: number; pageInfo: PageInformation } | undefined => {
		if (paginationInfo === undefined) {
			return undefined;
		}
		const nbPage = computeTotalPagesNb(paginationInfo);

		return { totalPage: nbPage, pageInfo: paginationInfo };
	},
);

const lotList = (state: RootState) => state.germplasm.lot?.lotList;

const lotPageInfo = (
	state: RootState,
): { totalPage: number; pageInfo: PageInformation } | undefined => {
	const paginationInfo = state.germplasm.lot?.paginationInfo;

	if (paginationInfo === undefined) {
		return undefined;
	}
	const nbPage = computeTotalPagesNb(paginationInfo);

	return { totalPage: nbPage, pageInfo: paginationInfo };
};

export const selectGermplasm = {
	genotypeList,
	genotypePageInfo,
	lotList,
	lotPageInfo,
};
