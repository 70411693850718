import React, { CSSProperties } from "react";

import MetTrialBanner from "./met-trial/MetTrialBanner";
import TrialTrialBanner from "./trial/TrialBanner";

import { ITrialGenericCardProps } from "./TrialGenericCard";

export interface ITrialGenericBannerProps extends ITrialGenericCardProps {
	style?: CSSProperties;
	className?: string;
}

export default function TrialGenericBanner(props: ITrialGenericBannerProps) {
	return props.trial.metId ? (
		<MetTrialBanner {...props} />
	) : (
		<TrialTrialBanner {...props} />
	);
}
