import React from "react";

import {
	Checkbox,
	FormControlLabel,
	FormControlLabelProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import "./CustomCheckbox.scss";

export const CustomCheckbox = styled(
	({
		className,
		required,
		label,
		checked,
		...otherProps
	}: Partial<FormControlLabelProps>) => (
		<FormControlLabel
			className={
				"CustomCheckbox no-animation" +
				(className ? ` ${className}` : "")
			}
			required={required}
			control={
				<Checkbox
					className="CustomCheckbox no-animation"
					checked={checked}
				/>
			}
			label={label ? label : ""}
			{...otherProps}
		/>
	),
)({});
