import React from "react";

import { IOperation } from "shared-type";

import OperationBanner, { IOperationBannerProps } from "./OperationBanner";

import "./OperationCard.scss";

export interface IOperationCardProps {
	operation: IOperation;
	onClick?: () => void;
	disabledClick?: boolean;
	isTemplate: boolean;
	isSelected?: boolean;
}

export default function OperationCard(props: IOperationCardProps) {
	const enhancedProps: IOperationBannerProps = {
		...props,
		className: "OperationCard",
	};

	return <OperationBanner {...enhancedProps} />;
}
