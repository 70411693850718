import { skipToken } from "@reduxjs/toolkit/dist/query";
import _ from "lodash";
import { useCallback, useMemo, useState } from "react";

import { ESpecies, IMETSerialized, IUser } from "shared-type";

import { apiMET } from "../../../redux/experiment/met/met.api";
import { apiTrial } from "../../../redux/experiment/trial/trial.api";
import { growingAreaCustomHook } from "../../../redux/growing-area/growing-area.api";
import { apiUser } from "../../../redux/user/user.api";

export function useDashboardCountryFilter() {
	const { data: fetchedGrowingAreas, isSuccess } =
		growingAreaCustomHook.useGetGrowingAreasQueryDeserialized({});

	const [countries, setCountries] = useState<string[] | undefined>(undefined);

	const countriesSorted = useMemo(() => {
		if (!fetchedGrowingAreas) return [];

		const countries = _.uniq(
			fetchedGrowingAreas.map(
				(growingArea) =>
					growingArea.country ?? `${growingArea.name} (name)`,
			),
		);
		return countries.sort((countryA, countryB) =>
			countryA.localeCompare(countryB),
		);
	}, [fetchedGrowingAreas]);

	return {
		countriesSorted,
		isSuccess,
		countries,
		setCountries,
	};
}
export function useDashboardRegionsFilter() {
	const { data: fetchedGrowingAreas, isSuccess } =
		growingAreaCustomHook.useGetGrowingAreasQueryDeserialized({});
	const [regions, setRegions] = useState<string[] | undefined>(undefined);
	const regionsSetSorted = useMemo(() => {
		if (!fetchedGrowingAreas) return [];

		const regions = _.uniq(
			fetchedGrowingAreas.map(
				(growingArea) =>
					growingArea.region ?? `${growingArea.name} (name)`,
			),
		);
		return regions.sort((regionA, regionB) =>
			regionA.localeCompare(regionB),
		);
	}, [fetchedGrowingAreas]);

	return { regionsSetSorted, isSuccess, regions, setRegions };
}

export function useDashboardUserFilter() {
	const { data: usersFetched, isSuccess } = apiUser.useGetUsersQuery();
	const [usersIds, setUsersIds] = useState<string[] | undefined>(undefined);

	const userToString = useCallback((user?: IUser) => {
		if (user) {
			return `${user.firstName} ${user.lastName}`;
		}
		return "";
	}, []);

	const translateUser = useCallback(
		(userId: string) => {
			if (!usersFetched || !Array.isArray(usersFetched)) return "";

			const user: IUser | undefined = usersFetched?.find(
				(us: IUser) => us.id === userId,
			);
			return userToString(user);
		},
		[usersFetched, userToString],
	);
	const userIdsSorted = useMemo(() => {
		if (!usersFetched) return [];
		const listSort = [...usersFetched] // Can't iterate on redux state directly
			.sort((userA, userB) => {
				return userToString(userA)
					.toLowerCase()
					.localeCompare(userToString(userB).toLowerCase());
			});
		return listSort.map((user) => user.id);
	}, [usersFetched, userToString]);

	return {
		isSuccess,
		usersIds,
		setUsersIds,
		userToString,
		translateUser,
		userIdsSorted,
	};
}

export function useDashboardMETFilter() {
	const { data: metsFetched, isSuccess } = apiMET.useGetMETQuery({});
	const [metIds, setMETIds] = useState<string[] | undefined>(undefined);

	const getMET = useCallback(
		(metId: string) => {
			const met: IMETSerialized | undefined = metsFetched?.data.find(
				(met: IMETSerialized) => met._id === metId,
			);
			return met;
		},
		[metsFetched?.data],
	);

	const metIdsSorted = useMemo(() => {
		if (!metsFetched) return [];
		const listSort = [...metsFetched.data] // Can't iterate on redux state directly
			.sort((metA, metB) => {
				return metA.name
					.toLowerCase()
					.localeCompare(metB.name.toLowerCase());
			});
		return listSort.map((met) => met._id);
	}, [metsFetched]);

	return {
		isSuccess,
		metIds,
		setMETIds,
		getMET,
		metIdsSorted,
	};
}

export function useDashboardYearFilter() {
	const currentYear = new Date().getFullYear(); // Current year in local time

	const { data: trials } = apiTrial.useGetTrialQuery({});

	// SELECTED VALUES
	const [expYears, setExpYears] = useState<number[] | undefined>([
		currentYear,
	]);

	const computeExpYearSet = useMemo(() => {
		const uniqueYears = _.uniq(trials?.data?.map((elt) => elt.year));
		if (uniqueYears.indexOf(currentYear) === -1) {
			uniqueYears.push(currentYear);
		}
		uniqueYears.sort((yearA, yearB) => yearB - yearA);
		return uniqueYears;
	}, [currentYear, trials]);

	return { expYears, setExpYears, computeExpYearSet };
}

export function useDashboardSpeciesFilter() {
	const [speciesFilter, setSpeciesFilter] = useState<string[]>([]);
	const speciesSort = useMemo(() => {
		return Object.values(ESpecies).sort((a, b) =>
			a.localeCompare(b),
		) as string[];
	}, []);

	return { speciesSort, speciesFilter, setSpeciesFilter };
}

export function useDashboardFetchMetTrialFilter() {
	const { data: trials } = apiTrial.useGetTrialQuery({});
	const metIds = useMemo(
		() => trials?.data.map((trial) => trial.metId).filter(_.isString),
		[trials],
	);
	const { data: metData } = apiMET.useGetMETsByIdsQuery(
		metIds && metIds.length > 0 ? metIds : skipToken,
	);

	const metMap = useMemo(
		() =>
			new Map<string, IMETSerialized>(
				metData?.map((elt) => [elt._id, elt]),
			),
		[metData],
	);
	const trialMap = useMemo(
		() => new Map(trials?.data?.map((elt) => [elt._id, elt])),
		[trials],
	);

	return { metData, trialMap, trials, metMap };
}
