"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EVariableCoreCategory = exports.EVariableScope = exports.EVariableCoreType = void 0;
var EVariableCoreType;
(function (EVariableCoreType) {
    EVariableCoreType["BOOLEAN"] = "boolean";
    EVariableCoreType["DATE"] = "date";
    EVariableCoreType["DATETIME"] = "datetime";
    EVariableCoreType["STRING"] = "string";
    EVariableCoreType["INT"] = "int";
    EVariableCoreType["DEC"] = "dec";
    EVariableCoreType["PICTURE"] = "picture";
})(EVariableCoreType = exports.EVariableCoreType || (exports.EVariableCoreType = {}));
var EVariableScope;
(function (EVariableScope) {
    EVariableScope[EVariableScope["SYSTEM"] = 0] = "SYSTEM";
    EVariableScope[EVariableScope["STANDARD"] = 1] = "STANDARD";
    EVariableScope[EVariableScope["CUSTOM"] = 2] = "CUSTOM";
})(EVariableScope = exports.EVariableScope || (exports.EVariableScope = {}));
var EVariableCoreCategory;
(function (EVariableCoreCategory) {
    EVariableCoreCategory[EVariableCoreCategory["DEFAULT"] = 0] = "DEFAULT";
    EVariableCoreCategory[EVariableCoreCategory["OBSERVATION"] = 1] = "OBSERVATION";
})(EVariableCoreCategory = exports.EVariableCoreCategory || (exports.EVariableCoreCategory = {}));
