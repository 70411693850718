export enum EDIC_KEY {
	// Entity composition {0}
	CREATE_ENTITY = "CREATE_ENTITY",
	EDIT_ENTITY = "EDIT_ENTITY",
	SELECT_ENTITY = "SELECT_ENTITY",
	NO_ENTITY_SELECTED = "NO_ENTITY_SELECTED",
	NO_ENTITIES_FOUND = "NO_ENTITIES_FOUND",
	ENTITY_CREATED = "ENTITY_CREATED",
	ENTITY_CREATION = "ENTITY_CREATION",
	ENTITY_UPDATED = "ENTITY_UPDATED",
	ENTITY_DELETED = "ENTITY_DELETED",
	ENTITY_DUPLICATED = "ENTITY_DUPLICATED",
	ENTITY_REQUIRED_ERROR = "ENTITY_REQUIRED_ERROR",
	ENTITY_TOO_LONG_ERROR = "ENTITY_TOO_LONG_ERROR",
	CANT_ACTION_ENTITY = "CANT_ACTION_ENTITY",
	ADD_VARIABLES_TO_ENTITY = "ADD_VARIABLES_TO_ENTITY",
	EDIT_VARIABLES_TO_ENTITY = "EDIT_VARIABLES_TO_ENTITY",
	DELETE_ENTITY = "DELETE_ENTITY",
	ACTION_ENTITY = "ACTION_ENTITY",
	NOTATIONS_AT = "NOTATIONS_AT",
	ACTION_TO_ELEMENT_VIEW = "ACTION_TO_ELEMENT_VIEW",

	// Entities
	SETTINGS = "SETTINGS",
	PROJECTS = "PROJECTS",
	ORGANIZATION = "ORGANIZATION",
	PROJECT = "PROJECT",
	AFFILIATION = "AFFILIATION",
	PROGRAM = "PROGRAM",
	TEMPLATES = "TEMPLATES",
	TEMPLATE = "TEMPLATE",
	OVERVIEW = "OVERVIEW",
	MET = "MET",
	SLE = "SLE",
	TEAM = "TEAM",
	OK = "OK",
	TEMPLATE_OBS_NEED_TO_SAVE_MSG = "TEMPLATE_OBS_NEED_TO_SAVE_MSG",
	PRECISION = "PRECISION",
	VARIABLES = "VARIABLES",
	VARIABLE = "VARIABLE",
	CLIMATE = "CLIMATE",
	SOIL_TYPE = "SOIL_TYPE",
	SOIL = "SOIL",
	SOIL_DETAILS = "SOIL_DETAILS",
	CONFIGURATION = "CONFIGURATION",
	VARIABLE_ONTOLOGY = "VARIABLE_ONTOLOGY",
	VARIABLE_OTHERS = "VARIABLE_OTHERS",
	GROWING_AREA = "GROWING_AREA",
	MARKET_SEGMENT = "MARKET_SEGMENT",
	MARKET_SEGMENTS = "MARKET_SEGMENTS",
	GERMPLASM = "GERMPLASM",
	VARIETY_LIST = "VARIETY_LIST",
	GERMPLASMS = "GERMPLASMS",
	USERS = "USERS",
	VALIDATED = "VALIDATED",
	VALIDATE = "VALIDATE",
	NOT_VALIDATED = "NOT_VALIDATED",
	USER = "USER",
	VALID = "VALID",
	IN_PROGRESS = "IN_PROGRESS",
	GENOTYPE = "GENOTYPE",
	AVERAGE = "AVERAGE",
	COUNT = "COUNT",
	MEDIAN = "MEDIAN",
	STANDARD_DEV = "STANDARD_DEV",
	SUM = "SUM",
	FIXED = "FIXED",
	FREE = "FREE",
	MINIMUM = "MINIMUM",
	GENOTYPES = "GENOTYPES",
	LOT = "LOT",
	LOTS = "LOTS",
	OBSERVATION = "OBSERVATION",
	OBSERVATION_TEMPLATE = "OBSERVATION_TEMPLATE",
	OBSERVATION_TEMPLATES = "OBSERVATION_TEMPLATES",
	OBSERVATION_UNITS = "OBSERVATION_UNITS",
	OBSERVATION_UNIT = "OBSERVATION_UNIT",
	OBSERVATION_VARIABLES = "OBSERVATION_VARIABLES",
	LINKED_VARIABLES = "LINKED_VARIABLES",
	DELETE_MET_LINKED_TRIALS = "DELETE_MET_LINKED_TRIALS",
	MATERIAL = "MATERIAL",
	MATERIAL_ORIGIN = "MATERIAL_ORIGIN",
	MATERIAL_LIST = "MATERIAL_LIST",
	LIBRARY = "LIBRARY",
	CANCEL = "CANCEL",
	GENOTYPE_TEMPLATE = "GENOTYPE_TEMPLATE",
	LOTS_TEMPLATE = "LOTS_TEMPLATE",
	EXPERIMENT = "EXPERIMENT",
	TRIAL_CONCLUSION = "TRIAL_CONCLUSION",
	MET_CONCLUSION = "MET_CONCLUSION",
	EXPERIMENT_TEMPLATE = "EXPERIMENT_TEMPLATE",
	FROM_TEMPLATE = "FROM_TEMPLATE",
	FROM_SCRATCH = "FROM_SCRATCH",
	GENERAL = "GENERAL",
	CONCLUSION = "CONCLUSION",
	CONCLUSION_STATUS = "CONCLUSION_STATUS",
	PRIVATE = "PRIVATE",
	GENERAL_INFORMATION = "GENERAL_INFORMATION",
	EXPERIMENT_SCOPE = "EXPERIMENT_SCOPE",
	TRIAL = "TRIAL",
	TRIALS = "TRIALS",
	METS = "METS",
	GROWING_AREAS = "GROWING_AREAS",
	ANALYSIS = "ANALYSIS",
	VALIDATION = "VALIDATION",
	DASHBOARD = "DASHBOARD",
	NEXT = "NEXT",
	PREVIOUS = "PREVIOUS",
	OBJECTIVE = "OBJECTIVE",
	INFORMATIONS = "INFORMATIONS",
	PLANNING = "PLANNING",
	DATA = "DATA",
	TASKS = "TASKS",
	OBSERVATION_ROUND = "OBSERVATION_ROUND",
	OPERATION = "OPERATION",
	OPERATION_TEMPLATE = "OPERATION_TEMPLATE",
	LEVEL_COMPLETION = "LEVEL_COMPLETION",
	COMPLETION = "COMPLETION",
	LIMITED_CHOICE = "LIMITED_CHOICE",
	LIMITED_CHOICES = "LIMITED_CHOICES",
	MULTI_NOTATIONS = "MULTI_NOTATIONS",
	CALCULATION = "CALCULATION",
	CONSTRAINT = "CONSTRAINT",
	EXPECTED_NOTATIONS = "EXPECTED_NOTATIONS",
	CHOICES = "CHOICES",
	MIN = "MIN",
	MAX = "MAX",
	VALUE = "VALUE",
	MIN_LENGTH = "MIN_LENGTH",
	MAX_LENGTH = "MAX_LENGTH",
	// Fields
	FIRST_NAME = "FIRST_NAME",
	LAST_NAME = "LAST_NAME",
	DATA_STATUS = "DATA_STATUS",
	ROLE = "ROLE",
	ID = "ID",
	NAME = "NAME",
	SEND = "SEND",
	ASSIGNED_IN_NOTEBOOKS = "ASSIGNED_IN_NOTEBOOKS",
	SHORT_NAME = "SHORT_NAME",
	DESCRIPTION = "DESCRIPTION",
	KEY = "KEY",
	ICON = "ICON",
	LABEL = "LABEL",
	GENOTYPE_NAME = "GENOTYPE_NAME",
	GENOTYPE_SPECIES = "GENOTYPE_SPECIES",
	LOT_ID = "LOT_ID",
	LOT_NAME = "LOT_NAME",
	YEAR = "YEAR",
	SPECIES_NAME = "SPECIES_NAME",
	SPECIES = "SPECIES",
	SUBSPECIES_NAME = "SUBSPECIES_NAME",
	SUBGROUP = "SUBGROUP",
	GROUP = "GROUP",
	CORN = "CORN",
	PEPPER = "PEPPER",
	OILSEED_RAPE = "OILSEED_RAPE",
	TOMATO_SPECIES = "TOMATO_SPECIES",
	DROUGHT_TOLERANCE = "DROUGHT_TOLERANCE",
	DISEASE_RESISTANCE = "DISEASE_RESISTANCE",
	YIELD_INCREASE = "YIELD_INCREASE",
	PRODUCT_DEV = "PRODUCT_DEV",
	WHEAT = "WHEAT",
	APPLE_TREE = "APPLE_TREE",
	VINE = "VINE",
	COFFEE = "COFFEE",
	SUNFLOWER = "SUNFLOWER",
	GENERATION = "GENERATION",
	COMMENTS = "COMMENTS",
	COMMENT = "COMMENT",
	TYPE = "TYPE",
	UNIT = "UNIT",
	DIRECT_F_PARENT = "DIRECT_F_PARENT",
	DIRECT_M_PARENT = "DIRECT_M_PARENT",
	ORIGIN_F_PARENT = "ORIGIN_F_PARENT",
	ORIGIN_M_PARENT = "ORIGIN_M_PARENT",
	FULL_PARENTAGE = "FULL_PARENTAGE",
	SY = "SY",
	CR = "CR",
	HY = "HY",
	BC = "BC",
	DH = "DH",
	MX = "MX",
	LI = "LI",
	FL = "FL",
	PF = "PF",
	FROM = "FROM",
	TO = "TO",
	CROSS_TYPE = "CROSS_TYPE",
	SCOPE = "SCOPE",
	SEASON = "SEASON",
	PERIOD = "PERIOD",
	COUNTRY = "COUNTRY",
	REGION = "REGION",
	LONGITUDE = "LONGITUDE",
	LATITUDE = "LATITUDE",
	AGRONOMIC_CONTEXT = "AGRONOMIC_CONTEXT",
	CROP_FAMILY = "CROP_FAMILY",
	LOCATION = "LOCATION",
	CROP_CYCLE = "CROP_CYCLE",
	DESIGN = "DESIGN",
	CROSS_OPTION = "CROSS_OPTION",
	EMAIL = "EMAIL",
	LOGIN = "LOGIN",
	PASSWORD = "PASSWORD",
	STATUS = "STATUS",
	DRAFT = "DRAFT",
	CHECK = "CHECK",
	MATERIALS_NUMBER = "MATERIALS_NUMBER",
	REPETITION_NUMBER = "REPETITION_NUMBER",
	MATERIAL_NUMBER = "MATERIAL_NUMBER",
	LOCATION_SOURCE = "LOCATION_SOURCE",
	EXPERIMENT_SOURCE = "EXPERIMENT_SOURCE",
	GENOTYPE_SOURCE = "GENOTYPE_SOURCE",
	VARIETY = "VARIETY",
	VARIETY_PERF = "VARIETY_PERF",
	CREATED_BY = "CREATED_BY",
	STAGE = "STAGE",
	NOTEBOOKS = "NOTEBOOKS",
	NOTATIONS = "NOTATIONS",
	COMPLETED_NOTEBOOKS = "COMPLETED_NOTEBOOKS",
	EVENTS = "EVENTS",
	IDENTIFIERS = "IDENTIFIERS",
	VARIABLE_GROUP = "VARIABLE_GROUP",
	GANTT = "GANTT",
	LIST = "LIST",

	// Types & Enums
	NONE = "NONE",
	BOOLEAN = "BOOLEAN",
	DATE = "DATE",
	RESET = "RESET",
	DATETIME = "DATETIME",
	INT = "INT",
	DEC = "DEC",
	PICTURE = "PICTURE",
	PICTURES = "PICTURES",
	PICTURE_GALLERY = "PICTURE_GALLERY",
	PICTURE_DETAILS = "PICTURE_DETAILS",
	PICTURE_FILTERS = "PICTURE_FILTERS",
	STRING = "STRING",
	FIELD_CROPS = "FIELD_CROPS",
	VEGETABLES = "VEGETABLES",
	PERENNIALS = "PERENNIALS",
	TESTING = "TESTING",
	BREEDING = "BREEDING",
	CROSSING_BLOCKS = "CROSSING_BLOCKS",
	SINGLE = "SINGLE",
	MULTI = "MULTI",
	APPLY = "APPLY",
	MULTI_ROTATIONS = "MULTI_ROTATIONS",
	ONE_REP_NO_CONTROL = "ONE_REP_NO_CONTROL",
	ONE_REP_CONTROL = "ONE_REP_CONTROL",
	MULTI_REP = "MULTI_REP",
	COMPLEX = "COMPLEX",
	MULTI_FACTOR = "MULTI_FACTOR",
	SELFING = "SELFING",
	CROSS = "CROSS",
	HYBRID = "HYBRID",
	BACKCROSS = "BACKCROSS",
	VARIETAL = "VARIETAL",
	VARIETIES = "VARIETIES",
	PRODUCT = "PRODUCT",
	OTHER = "OTHER",
	OTHERS = "OTHERS",
	AUTUMN = "AUTUMN",
	SPRING = "SPRING",
	SUMMER = "SUMMER",
	WINTER = "WINTER",
	CONTINENTAL = "CONTINENTAL",
	DRY = "DRY",
	POLAR = "POLAR",
	TEMPERATE = "TEMPERATE",
	TROPICAL = "TROPICAL",
	CLAY = "CLAY",
	LOAMY = "LOAMY",
	SANDY = "SANDY",
	SILT = "SILT",
	DETAILS = "DETAILS",
	ADMIN = "ADMIN",
	WEB = "WEB",
	MOBILE = "MOBILE",
	OBSERVER = "OBSERVER",
	OBSERVERS = "OBSERVERS",

	// Actions
	ADD = "ADD",
	ADD_ALL = "ADD_ALL",
	CREATE = "CREATE",
	CONTINUE = "CONTINUE",
	FETCH = "FETCH",
	SWITCH = "SWITCH",
	SET_EXPERIMENT_TO_READY = "SET_EXPERIMENT_TO_READY",
	TRIAL_TO_READY_TEXT = "TRIAL_TO_READY_TEXT",
	MET_TO_READY_TEXT = "MET_TO_READY_TEXT",
	JUST_SAVE = "JUST_SAVE",
	SAVE_AND_SET_READY = "SAVE_AND_SET_READY",
	DELETE_FOREVER = "DELETE_FOREVER",
	DELETION_MESSAGE_EXP = "DELETION_MESSAGE_EXP",
	NEW = "NEW",
	SAVE = "SAVE",
	EDIT = "EDIT",
	DISABLE = "DISABLE",
	EDIT_SELECTION = "EDIT_SELECTION",
	UPDATE = "UPDATE",
	DELETE = "DELETE",
	SEARCH = "SEARCH",
	SEARCH_TRIAL = "SEARCH_TRIAL",
	SEARCH_BY = "SEARCH_BY",
	CONNEXION = "CONNEXION",
	DISCONNECT = "DISCONNECT",
	CREATE_AND_CLOSE = "CREATE_AND_CLOSE",
	CREATE_AND_CONTINUE = "CREATE_AND_CONTINUE",
	CREATE_AND_CONFIGURE = "CREATE_AND_CONFIGURE",
	CUSTOM_VARIABLE_CREATION = "CUSTOM_VARIABLE_CREATION",
	CUSTOM_VARIABLE_EDITION = "CUSTOM_VARIABLE_EDITION",
	NEW_CUSTOM_VARIABLE = "NEW_CUSTOM_VARIABLE",
	NEW_OPERATION = "NEW_OPERATION",
	NEW_OPERATION_TEMPLATE = "NEW_OPERATION_TEMPLATE",
	ADD_VARIABLES = "ADD_VARIABLES",
	EDIT_VARIABLES = "EDIT_VARIABLES",
	ADD_GENOTYPE = "ADD_GENOTYPE",
	ADD_AN_OBSERVATION_UNIT = "ADD_AN_OBSERVATION_UNIT",
	OBSERVATION_TEMPLATE_EDITION = "OBSERVATION_TEMPLATE_EDITION",
	ADD_LOTS = "ADD_LOTS",
	GERMPLASM_SELECTION = "GERMPLASM_SELECTION",
	SELECT_GERMPLASM = "SELECT_GERMPLASM",
	THIS_VARIABLE_IS_USED = "THIS_VARIABLE_IS_USED",
	THIS_VARIABLE_GROUP_IS_USED = "THIS_VARIABLE_GROUP_IS_USED",
	THIS_OBS_ROUND_HAS_NOTEBOOKS = "THIS_OBS_ROUND_HAS_NOTEBOOKS",
	THIS_TEMPLATE_OBS_IS_USED = "THIS_TEMPLATE_OBS_IS_USED",
	THIS_GROWING_AREA_IS_USED = "THIS_GROWING_AREA_IS_USED",
	SELECT_TEMPLATE = "SELECT_TEMPLATE",
	NEW_TRIAL = "NEW_TRIAL",
	NEW_MET = "NEW_MET",
	NEW_GROWING_AREA = "NEW_GROWING_AREA",
	EDIT_GROWING_AREA_DETAILS = "EDIT_GROWING_AREA_DETAILS",
	NEW_USER = "NEW_USER",
	EXPERIMENT_OWNER = "EXPERIMENT_OWNER",
	CONCLUDE_TRIAL = "CONCLUDE_TRIAL",
	ASSOCIATED_GROWING_AREA_OBSERVERS = "ASSOCIATED_GROWING_AREA_OBSERVERS",
	OTHER_OBSERVERS = "OTHER_OBSERVERS",
	NEW_OBSERVATION = "NEW_OBSERVATION",
	NEW_OBSERVATION_TEMPLATE = "NEW_OBSERVATION_TEMPLATE",
	DO_IT_LATER = "DO_IT_LATER",

	MIGRATION = "MIGRATION",
	LOADING = "LOADING",
	LOADING_DOTS = "LOADING_DOTS",
	ERROR = "ERROR",
	IDENTIFICATION = "IDENTIFICATION",
	BLOOMEO = "BLOOMEO",
	DATE_GREATER_THAN_START_DATE_ERROR = "DATE_GREATER_THAN_START_DATE_ERROR",
	PROGRESSION = "PROGRESSION",
	ALLOW_EVENTS_NOTATION = "ALLOW_EVENTS_NOTATION",
	AT_LEAST_ONE_USER_SELECTED = "AT_LEAST_ONE_USER_SELECTED",
	OBS_ROUND_SEND_NOTEBOOK_TEXT = "OBS_ROUND_SEND_NOTEBOOK_TEXT",
	OBS_ROUND_SEND_NOTEBOOK_WARNING_TEXT = "OBS_ROUND_SEND_NOTEBOOK_WARNING_TEXT",
	TRIAL_SPECIES_UPDATE_TEXT = "TRIAL_SPECIES_UPDATE_TEXT",
	TRIAL_SPECIES_UPDATE_TEXT_BULLET_1 = "TRIAL_SPECIES_UPDATE_TEXT_BULLET_1",
	TRIAL_SPECIES_UPDATE_TEXT_BULLET_2 = "TRIAL_SPECIES_UPDATE_TEXT_BULLET_2",
	TRIAL_SPECIES_UPDATE_TEXT_NO_IMPACTS = "TRIAL_SPECIES_UPDATE_TEXT_NO_IMPACTS",
	TRIAL_SPECIES_UPDATE_TEXT_BULLET_3 = "TRIAL_SPECIES_UPDATE_TEXT_BULLET_3",
	TRIAL_SPECIES_UPDATE_TEXT_BULLET_4 = "TRIAL_SPECIES_UPDATE_TEXT_BULLET_4",
	MUST_CORRECT_ERROR_TEXT = "MUST_CORRECT_ERROR_TEXT",
	ERROR_FETCHING_GA_TEXT = "ERROR_FETCHING_GA_TEXT",
	ERROR_FETCHING_MET_TEXT = "ERROR_FETCHING_MET_TEXT",
	ERROR_FETCHING_MKSEG_TEXT = "ERROR_FETCHING_MKSEG_TEXT",
	LIST_OF_MATERIALS = "LIST_OF_MATERIALS",
	ASSIGNED_GROWING_AREA = "ASSIGNED_GROWING_AREA",
	SET_OBS_ROUND_TO_READY = "SET_OBS_ROUND_TO_READY",
	YES = "YES",
	NO = "NO",
	BAD_INPUT = "BAD_INPUT",
	NETWORK = "NETWORK",
	TENANT = "TENANT",
	LOADING_IN_PROGRESS = "LOADING_IN_PROGRESS",
	REFRESH = "REFRESH",
	LAST_UPDATED_ON = "LAST_UPDATED_ON",
	GENERATE = "GENERATE",
	SELECT_ALL = "SELECT_ALL",
	UNSELECT_ALL = "UNSELECT_ALL",

	CANNOT_COMPUTE_GANTT = "CANNOT_COMPUTE_GANTT",
	ECW_CANNOT_FOUND_MET = "ECW__CANNOT_FOUND_MET",
	ECW_TRIAL_GEN__HOW_MANY_TRIAL_TO_GEN = "ECW_TRIAL_GEN__HOW_MANY_TRIAL_TO_GEN",
	ECW_TRIAL_GEN__GEN_METHOD = "ECW_TRIAL_GEN__GEN_METHOD",
	ECW_TRIAL_GEN__PAGE_TITLE = "ECW_TRIAL_GEN__PAGE_TITLE",
	ECW_TRIAL_GEN__METHOD_QUANTITY = "ECW_TRIAL_GEN__METHOD_QUANTITY",
	ECW_TRIAL_GEN__METHOD_GA = "ECW_TRIAL_GEN__METHOD_GA",
	ECW_TRIAL_GEN__NB_MAX_TRIAL = "ECW_TRIAL_GEN__NB_MAX_TRIAL",
	REPLICATION = "REPLICATION",
	TRIALS_CREATION = "TRIALS_CREATION",
	TRIALS_LIST = "TRIALS_LIST",

	MAP_CANNOT_RETRIEVE_API_KEY = "MAP_CANNOT_RETRIEVE_API_KEY",
	MET_MAP_GROWING_AREA_NO_LOCATION_TOOLTIP = "MET_MAP_GROWING_AREA_NO_LOCATION_TOOLTIP",
	MET_MAP_GROWING_AREA_NO_LOCATION_MESSAGE = "MET_MAP_GROWING_AREA_NO_LOCATION_MESSAGE",
	MET_MAP_GROWING_AREA_NO_LOCATION_BEFORE_ACTION_MESSAGE = "MET_MAP_GROWING_AREA_NO_LOCATION_BEFORE_ACTION_MESSAGE",
	MET_MAP_GROWING_AREA_NO_LOCATION_ACTION_MESSAGE = "MET_MAP_GROWING_AREA_NO_LOCATION_ACTION_MESSAGE",

	CHARACTERS_MAX = "CHARACTERS_MAX",

	GENOTYPE_COLUMN_ALTERNATE_NAME = "GENOTYPE_COLUMN_ALTERNATE_NAME",
	GENOTYPE_COLUMN_PLC = "GENOTYPE_COLUMN_PLC",
	GENOTYPE_COLUMN_CONTROL = "GENOTYPE_COLUMN_CONTROL",
	GENOTYPE_COLUMN_OWNERSHIP = "GENOTYPE_COLUMN_OWNERSHIP",
	GENOTYPE_COLUMN_BU_OWNER = "GENOTYPE_COLUMN_BU_OWNER",
	GENOTYPE_COLUMN_COMPANY_NAME = "GENOTYPE_COLUMN_COMPANY_NAME",
	DASHBOARD_WITHOUT_MET = "DASHBOARD_WITHOUT_MET",

	SYNTHESIS = "SYNTHESIS",
	TRIAL_PARAMETERS = "TRIAL_PARAMETERS",
	SURFACE = "SURFACE",
	IN_M2 = "IN_M2",
	ROWS_NUMBER = "ROWS_NUMBER",
	ROW_SPACING = "ROW_SPACING",
	PLANTS_NUMBER = "PLANTS_NUMBER",
	DENSITY = "DENSITY",
	NUMBER_SEEDS_M2 = "NUMBER_SEEDS_M2",
	CULTIVATION_METHOD = "CULTIVATION_METHOD",
	OTHER_INFORMATION = "OTHER_INFORMATION",
	TRIAL_TOTAL_COSTS = "TRIAL_TOTAL_COSTS",
	CURRENCY = "CURRENCY",
	EURO = "EURO",
	DOLLAR = "DOLLAR",
	CONTRACT = "CONTRACT",
	CREATE_VARIABLE_GROUP = "CREATE_VARIABLE_GROUP",
	CREATE_NEW_VARIABLE_GROUP = "CREATE_NEW_VARIABLE_GROUP",
	EDIT_VARIABLE_GROUP = "EDIT_VARIABLE_GROUP",
	NO_VARIABLE_YET = "NO_VARIABLE_YET",
	CREATE_VARIABLES_IN = "CREATE_VARIABLES_IN",
	READY = "READY",
	COMPLETED = "COMPLETED",
	ADD_VARIABLES_IN_THE = "ADD_VARIABLES_IN_THE",
	VARIABLES_TAB = "VARIABLES_TAB",
	TO_CREATE_VARIABLE_GROUPS = "TO_CREATE_VARIABLE_GROUPS",
	FIRST_AND_LASTNAME = "FIRST_AND_LASTNAME",
	MY_PROFILE = "MY_PROFILE",
	POST = "POST",
	WRITE_A_COMMENT = "WRITE_A_COMMENT",
	TEAM_MEMBERS = "TEAM_MEMBERS",
	SEARCH_BY_NAME_AND_EMAIL = "SEARCH_BY_NAME_AND_EMAIL",
	DUPLICATE = "DUPLICATE",
	NAME_AND_DESCRIPTION = "NAME_AND_DESCRIPTION",
	SEARCH_MET = "SEARCH_MET",
	DEPENDENCIES = "DEPENDENCIES",
	DUPLICATE_DEPENDENCIES_QUESTION = "DUPLICATE_DEPENDENCIES_QUESTION",
	DUPLICATE_TRIAL_NO_DEPENDENCIES = "DUPLICATE_TRIAL_NO_DEPENDENCIES",
	CHOOSE_WHAT_DUPLICATE = "CHOOSE_WHAT_DUPLICATE",
	DUPLICATE_TASKS_ENSURE_SELECTED = "DUPLICATE_TASKS_ENSURE_SELECTED",
	DUPLICATE_TASKS = "DUPLICATE_TASKS",
	SEE_DUPLICATED_TRIAL = "SEE_DUPLICATED_TRIAL",
	RETURN_TO_TRIAL_LIST = "RETURN_TO_TRIAL_LIST",
	ENTITY_TRIAL_DUPLICATED = "ENTITY_TRIAL_DUPLICATED",
	TRIAL_DUPLICATED_CONFIRM_QUESTION = "TRIAL_DUPLICATED_CONFIRM_QUESTION",
}
