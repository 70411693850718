import React, { ElementType } from "react";
import { Typography as MUITypography } from "@mui/material";

import Colors from "stories/constants/Colors/Colors";
import { variants } from "./parameters";
import { Icon } from "stories/base-components/Icon/Icon";
import { StoryTheme } from "stories/StoryTheme";
import Size from "stories/constants/Size/Size";

import "./typography.scss";

export type ITypography = {
	variant?:
		| "display1"
		| "display2"
		| "heading1"
		| "button"
		| "heading2"
		| "body1"
		| "body2"
		| "body2Medium"
		| "smallSemiBold"
		| "smallMedium"
		| "smallRegular";
	color?: string;
	fontSize?: string;
	fontWeight?: string;
	marginBottom?: string;
	letterSpacing?: string;
	text?: string;
	component?: ElementType<any>;
	noWrap?: boolean;
	className?: string;
};

type IVariantTypography = {
	fontSize: string;
	fontWeight: number;
};

export const Typography = (typography: ITypography) => {
	const currentType = variants[
		typography.variant || "body1"
	] as IVariantTypography;
	return (
		<StoryTheme>
			<MUITypography
				fontWeight={
					typography.fontWeight
						? typography.fontWeight
						: currentType.fontWeight
				}
				fontSize={
					typography.fontSize
						? typography.fontSize
						: currentType.fontSize
				}
				color={typography?.color || Colors.neutral100}
				letterSpacing={
					typography?.letterSpacing
						? typography?.letterSpacing
						: undefined
				}
				marginBottom={typography.marginBottom}
				noWrap={typography.noWrap}
				component={typography.component || "div"}
				className={typography.className}
			>
				{typography.text || ""}
			</MUITypography>
		</StoryTheme>
	);
};

export const Title = (title: ITypography) => (
	<Typography
		{...title}
		variant="heading1"
		color={title.color ? title.color : Colors.neutral100}
		marginBottom={Size.xs}
	/>
);
export const Subtitle = (subtitle: ITypography) => (
	<Typography
		{...subtitle}
		variant="heading2"
		color={subtitle.color ? subtitle.color : Colors.neutral100}
	/>
);
export const Text = (text: ITypography) => (
	<Typography
		{...text}
		variant="body1"
		color={text.color ? text.color : Colors.neutral100}
	/>
);
export const Details = (details: ITypography) => (
	<Typography
		{...details}
		variant="body2"
		color={details.color ? details.color : Colors.neutral60}
	/>
);
export const HelperError = (helperError: ITypography) => (
	<div style={{ display: "flex", alignItems: "center" }}>
		<div style={{ marginRight: 4 }}>
			<Icon
				name="warning-error--filled"
				color={Colors.red60}
				fontSize={Size.sm}
			/>
		</div>
		<Typography
			{...helperError}
			color={Colors.red60}
			variant="smallMedium"
		/>
	</div>
);
export const HelperSuccess = (helperSuccess: ITypography) => (
	<Typography
		{...helperSuccess}
		variant="smallMedium"
		color={Colors.accent60}
	/>
);
