import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { formatString } from "common";
import {
	ICreateTemplateObservationCore,
	IFilter,
	IPatchTemplateObservationCore,
	ISort,
	ITemplateObsCoreSerialized,
} from "shared-type";

import { DIC, EDIC_KEY } from "../../dictionary";
import { useRequest } from "../../request-provider";
import { defaultApiError } from "../utils/errors";

export const apiTemplateObs = createApi({
	reducerPath: "api-template-obs",
	baseQuery: () => ({ data: undefined }),
	// global configuration for the api (in second)
	keepUnusedDataFor: 30,
	// global configuration for the api
	refetchOnMountOrArgChange: 30,
	tagTypes: ["Update", "Delete", "Create"],
	endpoints: (build) => ({
		getTemplateObs: build.query<
			ITemplateObsCoreSerialized[],
			{ filter?: IFilter; sort?: ISort }
		>({
			queryFn: async ({ filter, sort }) => {
				try {
					const result =
						await useRequest().templateObs.getTemplatesObs(
							filter,
							sort,
						);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.FETCH).toLowerCase(),
							DIC(EDIC_KEY.OBSERVATION_TEMPLATE).toLowerCase(),
						),
					);
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),

		getTemplateObsById: build.query<
			ITemplateObsCoreSerialized | undefined,
			string | undefined
		>({
			queryFn: async (id) => {
				try {
					if (!id) {
						return { data: undefined };
					}
					const result =
						await useRequest().templateObs.getTemplateObsById(id);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.FETCH).toLowerCase(),
							DIC(EDIC_KEY.OBSERVATION_TEMPLATE).toLowerCase(),
						),
					);
				}
			},
			providesTags: (result, error, id) => {
				return [
					{ type: "Delete", id: id },
					{ type: "Update", id: id },
					{ type: "Create", id: id },
				];
			},
		}),

		getTemplateObsUsagesById: build.query<
			string[] | undefined,
			string | undefined
		>({
			queryFn: async (id) => {
				try {
					if (!id) {
						return { data: undefined };
					}
					const result =
						await useRequest().templateObs.getTemplateObsUsageInfoById(
							id,
						);
					return { data: result.data };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch template observation usages for id : " +
							id,
					);
				}
			},
		}),

		createObsTemplate: build.mutation<
			string,
			ICreateTemplateObservationCore
		>({
			queryFn: async (createObsTemplate) => {
				try {
					const result =
						await useRequest().templateObs.createTemplateObs(
							createObsTemplate,
						);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to create the observation template",
					);
				}
			},
			invalidatesTags: (res) => [
				{ type: "Create", id: res },
				{ type: "Create", id: "ALL" },
			],
		}),

		updateTemplateObsById: build.mutation<
			void,
			{ id: string; update: IPatchTemplateObservationCore }
		>({
			queryFn: async (arg) => {
				try {
					const result = await useRequest()
						.templateObs.updateTemplateObsById(arg.id, arg.update)
						.then((resp) => resp.data);
					return { data: result };
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.UPDATE).toLowerCase(),
							DIC(EDIC_KEY.OBSERVATION_TEMPLATE).toLowerCase(),
						),
					);
				}
			},
			invalidatesTags: (result, error, arg) => {
				if (error) {
					return [];
				}
				return [
					{ type: "Update", id: arg.id },
					{ type: "Update", id: "ALL" },
				];
			},
		}),

		deleteTemplateObsById: build.mutation<void, { id: string }>({
			queryFn: async (arg) => {
				try {
					const result = await useRequest()
						.templateObs.deleteTemplateObsById(arg.id)
						.then((resp) => resp.data);
					return { data: result };
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.DELETE).toLowerCase(),
							DIC(EDIC_KEY.OBSERVATION_TEMPLATE).toLowerCase(),
						),
					);
				}
			},
			invalidatesTags: (result, error, arg) => {
				if (error) {
					return [];
				}
				return [
					{ type: "Delete", id: arg.id },
					{ type: "Delete", id: "ALL" },
				];
			},
		}),

		duplicateTemplateObsByIds: build.mutation<any, string[]>({
			queryFn: async (duplicateTemplateObsIds) => {
				try {
					const result =
						await useRequest().templateObs.duplicateTemplateObsByIds(
							duplicateTemplateObsIds,
						);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to duplicate the observation template",
					);
				}
			},
			invalidatesTags: (res) => [
				{ type: "Create", id: res },
				{ type: "Create", id: "ALL" },
			],
		}),
	}),
});
