import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import {
	IInterExpTaskGanttSerialized,
	IMETGanttSerialized,
	ITrialGanttSerialized,
} from "shared-type";
import { AbstractRequest } from "../abstract-request";

export class ExpGanttRequest extends AbstractRequest {
	private baseUrl: string;

	constructor(axios: AxiosInstance, hostConfig: string) {
		super(axios, hostConfig);
		this.baseUrl = hostConfig + "/gantt";
	}

	computeTrialsGanttInfoByIds(trialIds: string[], config?: AxiosRequestConfig) {
		return this.axios.post<ITrialGanttSerialized[]>(
			this.baseUrl + "/trial/get/many",
			trialIds,
			config
		);
	}

	computeMETGanttInfoById(
		METId: string,
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<IMETGanttSerialized, any>> {
		return this.axios.get<IMETGanttSerialized>(
			this.baseUrl + "/met/" + METId,
			config
		);
	}

	computeInterExpGanttInfo(
		config?: AxiosRequestConfig
	): Promise<AxiosResponse<IInterExpTaskGanttSerialized[], any>> {
		return this.axios.get<IInterExpTaskGanttSerialized[]>(
			this.baseUrl + "/inter-exp/task/",
			config
		);
	}
}
