import { GridCellKind, TextCell, Theme } from "@glideapps/glide-data-grid";
import dayjs from "dayjs";

import { CommonNotationCellOption } from "../notation-cell.types";

export const dateNotationCellDrawer = (
	notation?: string,
	isDateTime?: boolean,
	option?: CommonNotationCellOption,
) => {
	const theme: Partial<Theme> = option?.themeOverride ?? {};
	let stringValue = "";
	if (notation !== undefined && notation !== null) {
		const date = dayjs(notation);
		if (date.isValid()) {
			if (isDateTime) {
				stringValue = date.format("MM/DD/YYYY - HH:mm");
			} else {
				stringValue = date.format("MM/DD/YYYY");
			}
		}
	}
	const cell: TextCell = {
		kind: GridCellKind.Text,
		data: stringValue,
		displayData: stringValue,
		allowOverlay: option?.isModifiable ?? false,
		readonly: !option?.isModifiable ?? true,
		themeOverride: theme,
	};
	return cell;
};
