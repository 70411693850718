import { yupResolver } from "@hookform/resolvers/yup";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { EntitySerializer, formatString } from "common";
import { ETaskStatus } from "shared-type";

import { DIC, EDIC_KEY } from "../../../../dictionary";
import useAppSnackbar from "../../../../hooks/useAppSnackbar";
import { selectECW } from "../../../../redux/ECW/ECW.selector";
import { apiMET } from "../../../../redux/experiment/met/met.api";
import { apiGrowingArea } from "../../../../redux/growing-area/growing-area.api";
import { apiOperation } from "../../../../redux/operation/operation.api";
import { apiUserCustomHook } from "../../../../redux/user/user.api";
import { ApiErrorSnackbar } from "../../../../redux/utils/api-error-snackbar/ApiErrorSnackbar";
import { ReduxApiError } from "../../../../redux/utils/errors";
import TopMenu from "../../../base-components/menu/top-menu/TopMenu";
import DorianeDrawer from "../../../base-components/modal/DorianeDrawer";
import {
	IOperationForm,
	operationObjectToForm,
	operationValidator,
} from "../../../form/task/operation";
import OperationForm from "../../../form/task/operation/OperationForm";
import SelectUserTaskModal from "../../select-user/SelectUserObsRoundModal";
import { opActionType, useOperationModalAction } from "./OperationModalHook";

import "./OperationModal.scss";

const defaultOperation: Partial<IOperationForm> = {
	startDate: dayjs().startOf("day"),
	endDate: dayjs().startOf("day"),
	team: [],
	status: ETaskStatus.DRAFT,
};

interface OperationModalProps {
	onClose: () => void;
	mode: "creation" | "edition";
}

export default function OperationModal(props: OperationModalProps) {
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();
	const { metId } = useParams();

	// ---- State ----
	const [isSelectUserOpen, setSelectUserOpen] = useState<boolean>(false);

	// ---- Form ----
	const form = useForm<IOperationForm>({
		defaultValues: defaultOperation,
		resolver: yupResolver(operationValidator as any),
	});
	const { handleSubmit, formState, clearErrors } = form;
	const { isSubmitting } = formState;
	const usersIds = useWatch({ control: form.control, name: "team" });

	// ---- Selector ----
	const remoteTrial = useSelector(selectECW.trialRemote);
	const wizardType = useSelector(selectECW.wizardType);
	const operationId = useSelector(selectECW.editedOperationId);

	// ---- RTK Query ----
	const { data: remoteMET } = apiMET.useGetMETByIdQuery(metId ?? skipToken);
	const { data: fetchedOperation, isError } =
		apiOperation.useGetOperationByIdQuery(operationId ?? skipToken);
	const { data: operationUsers } =
		apiUserCustomHook.useGetUsersByIds(usersIds);
	const { data: growingArea } = apiGrowingArea.useGetGrowingAreaByIdQuery(
		remoteTrial?.growingAreaId ?? "",
	);

	const { handleEndAction } = useOperationModalAction(
		wizardType === "MET",
		remoteMET?._id ?? remoteTrial?._id,
		fetchedOperation,
	);

	const isCreation = useMemo(() => {
		return props.mode === "creation";
	}, [props.mode]);

	const getMessageFromAction = (action: opActionType) => {
		switch (action) {
			case "create":
				return DIC(EDIC_KEY.ENTITY_CREATED);
			case "update":
				return DIC(EDIC_KEY.ENTITY_UPDATED);
			case "delete":
				return DIC(EDIC_KEY.ENTITY_DELETED);
			default:
				return DIC(EDIC_KEY.ENTITY_UPDATED);
		}
	};

	const handleFormSubmit = useCallback(
		async (action: opActionType) => {
			clearErrors();
			handleSubmit(
				async (data) => {
					await handleEndAction(isCreation, action, data)
						.then(() => {
							enqueueSnackbarSuccess(
								formatString(
									getMessageFromAction(action),
									DIC(EDIC_KEY.OPERATION),
								),
							);
							setSelectUserOpen(false);
							if (action !== "update") props.onClose();
						})
						.catch((err: ReduxApiError) => {
							console.warn(err);
							enqueueSnackbarError(
								<ApiErrorSnackbar error={err} />,
							);
						});
				},
				(err) => {
					console.error(err);
					enqueueSnackbarError(DIC(EDIC_KEY.BAD_INPUT));
				},
			)();
		},
		[
			clearErrors,
			handleSubmit,
			handleEndAction,
			isCreation,
			enqueueSnackbarSuccess,
			props,
			enqueueSnackbarError,
		],
	);

	useEffect(() => {
		return () => {
			setSelectUserOpen(false);
		};
	}, []);

	useEffect(() => {
		if (isError || !fetchedOperation) {
			form.reset(defaultOperation);
			return;
		}
		form.reset(
			operationObjectToForm(
				EntitySerializer.deserialize(fetchedOperation),
			),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isError, fetchedOperation]);

	return (
		<>
			<DorianeDrawer
				takeRemainingSpace
				onClose={props.onClose}
				title={formatString(
					isCreation
						? DIC(EDIC_KEY.CREATE_ENTITY)
						: DIC(EDIC_KEY.EDIT_ENTITY),
					DIC(
						wizardType === "MET"
							? EDIC_KEY.OPERATION_TEMPLATE
							: EDIC_KEY.OPERATION,
					).toLowerCase(),
				)}
			>
				<TopMenu
					createButton={{
						onClick: isCreation
							? () => handleFormSubmit("create")
							: undefined,
						isLoading: isCreation ? isSubmitting : undefined,
					}}
					saveButton={{
						onClick: !isCreation
							? () => handleFormSubmit("update")
							: undefined,
						isLoading: isSubmitting,
					}}
					deleteButton={{
						onClick: !isCreation
							? () => handleFormSubmit("delete")
							: undefined,
					}}
				/>
				<div className="OperationModal-form-container take-remaining-space">
					<div className="OperationModal-form take-remaining-space">
						<OperationForm
							isTemplate={wizardType === "MET"}
							operationUsers={operationUsers}
							mode={isCreation ? "creation" : "unused"}
							onSelectUserClick={() => setSelectUserOpen(true)}
							form={form}
						/>
					</div>
				</div>
			</DorianeDrawer>
			{remoteTrial && isSelectUserOpen && (
				<SelectUserTaskModal
					currentUserIds={usersIds}
					onUserCheck={(clickedUser, checked) => {
						if (checked) {
							form.setValue("team", [
								...usersIds,
								clickedUser.id,
							]);
						} else {
							const newUserList = usersIds.filter(
								(userId) => userId !== clickedUser.id,
							);
							form.setValue("team", newUserList);
						}
					}}
					onClose={() => setSelectUserOpen(false)}
					trialOwnerId={remoteTrial.systemMetadata.createdBy ?? ""}
					growingAreaUsersIds={growingArea?.users ?? []}
				/>
			)}
		</>
	);
}
