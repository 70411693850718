import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useParams } from "react-router-dom";

import { ETaskType, ExperimentType, IOperation } from "shared-type";

import DorianeButton from "../../../../../../../../components/base-components/button/DorianeButton";
import TopMenu from "../../../../../../../../components/base-components/menu/top-menu/TopMenu";
import { EPaddingSize } from "../../../../../../../../components/base-components/placement/box/Box";
import FlexBox, {
	EGapSize,
} from "../../../../../../../../components/base-components/placement/flex-box/FlexBox";
import TaskTemplateModal from "../../../../../../../../components/task/modal/template/TaskTemplateModal";
import OperationCard from "../../../../../../../../components/task/operation/card/OperationCard";
import { DIC, EDIC_KEY } from "../../../../../../../../dictionary";
import { useAppDispatch } from "../../../../../../../../hooks/reduxHook";
import { ECWAction } from "../../../../../../../../redux/ECW/ECW.slice";
import { apiTrial } from "../../../../../../../../redux/experiment/trial/trial.api";
import { operationCustomHook } from "../../../../../../../../redux/operation/operation.api";

interface IOperationGrouped {
	date: Date;
	operations: IOperation[];
}

function useExpOperationGroupByDate(expType: ExperimentType, expId?: string) {
	const { data: operations } =
		operationCustomHook.useGetOperationsByExpIdQueryDeserialized(
			expType,
			expId,
		);

	const groupByDateOp = useMemo(() => {
		if (!operations) return [];

		const groups: Record<string, IOperation[]> = operations.reduce(
			(groups: Record<string, IOperation[]>, operation) => {
				const date = operation.startDate.toDateString();
				if (!groups[date]) {
					groups[date] = [];
				}
				groups[date].push(operation);
				return groups;
			},
			{},
		);

		const groupedOperations: IOperationGrouped[] = Object.keys(groups).map(
			(dateString) => {
				return {
					date: new Date(dateString),
					operations: groups[dateString],
				};
			},
		);

		return groupedOperations.sort(
			(opA, opB) => opA.date.getTime() - opB.date.getTime(),
		);
	}, [operations]);

	return groupByDateOp;
}

export default function ExpOperationPage() {
	const dispatch = useAppDispatch();
	const { trialId, metId } = useParams();
	const isMET = useMemo(() => metId !== undefined, [metId]);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [templateOpen, setTemplateOpen] = useState<boolean>(false);

	const { data: remoteTrial } = apiTrial.useGetTrialByIdQuery(
		trialId ?? skipToken,
	);

	const operationsGroupedByDate = useExpOperationGroupByDate(
		isMET ? "MET" : "Trial",
		trialId ?? metId,
	);

	const handleCreateFromScratch = useCallback(() => {
		dispatch(ECWAction.updateEditedOperationId(undefined));
		dispatch(ECWAction.setCreateOperationOpen(true));
		setAnchorEl(null);
	}, [dispatch]);

	const handleCreateFromTemplate = useCallback(() => {
		setTemplateOpen(true);
		dispatch(ECWAction.updateEditedOperationId(undefined));
		dispatch(ECWAction.setCreateOperationOpen(false));
		setAnchorEl(null);
	}, [dispatch]);

	const handleCreateClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			if (remoteTrial?.metId !== undefined) {
				setAnchorEl(event.currentTarget);
			} else {
				handleCreateFromScratch();
			}
		},
		[handleCreateFromScratch, remoteTrial?.metId],
	);

	const createNewOperationButton = useMemo(() => {
		return (
			<div>
				<DorianeButton
					dorianeStyle="primary"
					onClick={(event) => {
						handleCreateClick(event);
					}}
				>
					{`${DIC(
						isMET
							? EDIC_KEY.NEW_OPERATION_TEMPLATE
							: EDIC_KEY.NEW_OPERATION,
					)} +`}
				</DorianeButton>
				{remoteTrial?.metId && (
					<Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={() => setAnchorEl(null)}
					>
						<MenuItem onClick={handleCreateFromTemplate}>
							{DIC(EDIC_KEY.FROM_TEMPLATE)}
						</MenuItem>
						<MenuItem onClick={handleCreateFromScratch}>
							{DIC(EDIC_KEY.FROM_SCRATCH)}
						</MenuItem>
					</Menu>
				)}
			</div>
		);
	}, [
		anchorEl,
		handleCreateClick,
		handleCreateFromScratch,
		handleCreateFromTemplate,
		isMET,
		remoteTrial?.metId,
	]);

	const isMounted = useRef(false); // Create a ref to track component mount status
	useEffect(() => {
		return () => {
			if (isMounted.current) {
				dispatch(ECWAction.setCreateOperationOpen(false));
				dispatch(ECWAction.updateEditedOperationId(undefined));
			} else {
				isMounted.current = true; // Set the ref to true when mounting
			}
		};
	}, [dispatch]);

	return (
		<>
			<FlexBox fullParentSize flexDirection="column">
				<TopMenu additionalButton={createNewOperationButton} />
				<FlexBox
					flexDirection="column"
					gap={EGapSize.MEDIUM}
					padding={EPaddingSize.MEDIUM}
					overflow={"auto"}
				>
					{operationsGroupedByDate.map((group, index) => (
						<div key={index}>
							<h2>{group.date.toDateString()}</h2>
							<FlexBox
								flexDirection="column"
								width={"75%"}
								gap={EGapSize.SMALL}
							>
								{group.operations.map((elt) => (
									<OperationCard
										isTemplate={isMET}
										key={elt._id}
										operation={elt}
										onClick={() => {
											dispatch(
												ECWAction.setCreateOperationOpen(
													false,
												),
											);
											dispatch(
												ECWAction.updateEditedOperationId(
													elt._id,
												),
											);
										}}
									/>
								))}
							</FlexBox>
						</div>
					))}
				</FlexBox>
			</FlexBox>
			{templateOpen && remoteTrial?.metId && (
				<TaskTemplateModal
					taskType={ETaskType.OPERATION}
					metId={remoteTrial.metId}
					trialId={remoteTrial._id}
					onClose={() => setTemplateOpen(false)}
				/>
			)}
		</>
	);
}
