import Skeleton from "@mui/material/Skeleton";
import React, { useMemo } from "react";

import { useParams } from "react-router-dom";

import { trialOverviewColumns } from "../../../../../../../components/base-components/data-grid/doriane-data-grid/default-grid-columns/TrialOverviewColumn";
import DorianeDataGrid from "../../../../../../../components/base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import { apiLevelCompletion } from "../../../../../../../redux/experiment-statistics/level-completion.api";
import { apiVariables } from "../../../../../../../redux/variables/variables.api";

import styles from "../../../../../../../../sassTheme";
import "./TrialOverviewPage.scss";

export interface ITrialCompletion {
	variable: string;
	notebookAssignment: number;
	trialCompletion?: {
		actual: number;
		expected: number;
	};
	materialCompletion?: {
		actual: number;
		expected: number;
	};
}

export default function TrialOverviewPage() {
	const { trialId } = useParams();

	const { data: allVariables } = apiVariables.useGetAllVariablesQuery();
	const { data: trialCompletionData } =
		apiLevelCompletion.useComputeTrialCompletionByIdQuery(trialId);

	const trialCompletionToDisplay: ITrialCompletion[] = useMemo(() => {
		if (trialCompletionData === undefined || allVariables === undefined) return [];

		const displayResult: ITrialCompletion[] =
			trialCompletionData.completionDetails.variablesCompletion.map(
				(varCompletion) => {
					const foundVariable = allVariables?.find(
						(variable) =>
							variable._id ===
								varCompletion.variable.variableId &&
							variable.scope === varCompletion.variable.scope,
					);
					const notebookOccurrence =
						varCompletion.occurrenceInNotebooks ?? 0;

					// Display variable and notebook occurrences
					const variableDisplay: ITrialCompletion = {
						variable:
							foundVariable?.name.en ??
							varCompletion.variable.variableId,
						notebookAssignment: notebookOccurrence,
					};
					// Display completion if exist
					variableDisplay.trialCompletion =
						varCompletion.trialCompletion !== undefined
							? {
									actual: varCompletion.trialCompletion,
									expected:
										trialCompletionData.completionDetails
											.trialExpectedPerVar *
										notebookOccurrence,
							  }
							: undefined;
					variableDisplay.materialCompletion =
						varCompletion.materialCompletion !== undefined
							? {
									actual: varCompletion.materialCompletion,
									expected:
										trialCompletionData.completionDetails
											.materialExpectedPerVar *
										notebookOccurrence,
							  }
							: undefined;

					return variableDisplay;
				},
			);
		return displayResult;
	}, [allVariables, trialCompletionData]);

	const loadingElements = Array.from({ length: 6 }, (_, index) => (
		<Skeleton
			key={index}
			variant="rounded"
			animation="wave"
			height={35}
			sx={{ bgcolor: index % 2 === 0 ? "white" : styles.grey }}
		/>
	));

	return (
		<div className="TrialOverviewPage-container">
			<DorianeDataGrid<ITrialCompletion>
				rows={trialCompletionToDisplay}
				columns={trialOverviewColumns()}
			/>
			{trialCompletionToDisplay.length === 0 && (
				<div className="TrialOverviewPage-loading flex-column">
					{loadingElements}
				</div>
			)}
		</div>
	);
}
