import React from "react";
import { Outlet } from "react-router-dom";
import { DIC, EDIC_KEY } from "../../../dictionary";
import {
	PATH_PROJECTS,
	PATH_TEMPLATES,
	PATH_USERS,
	PATH_VARIABLES,
} from "../../../routes";

import { Tabs } from "stories/base-components/Navigation/Tabs/Tabs";

import "./SettingPage.scss";

export default function SettingPage() {
	return (
		<div className="SettingPage-page flex-column">
			<div className="SettingPage-page-tab">
				<Tabs
					tabs={[
						{
							tabLabel: DIC(EDIC_KEY.PROJECTS),
							path: PATH_PROJECTS,
						},
						{
							tabLabel: DIC(EDIC_KEY.TEMPLATES),
							path: PATH_TEMPLATES,
						},
						{
							tabLabel: DIC(EDIC_KEY.VARIABLES),
							path: PATH_VARIABLES,
						},
						{
							tabLabel: DIC(EDIC_KEY.USERS),
							path: PATH_USERS,
						},
					]}
				/>
			</div>
			<div className="SettingPage-selected-page">
				<Outlet />
			</div>
		</div>
	);
}
