import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	EVariableScope,
	IScopedVariable,
	ITemplateObsCoreSerialized,
	IVariableCore,
} from "shared-type";

import { selectTmpltObsEdition } from "App/redux/template-obs-edition/template-obs-edition.selector";
import { templateObsEditionActions } from "App/redux/template-obs-edition/template-obs-edition.slice";
import { apiTemplateObs } from "App/redux/template-obs/template-obs.api";
import { AppDispatch } from "App/store";
import { useAppDispatch } from "../../../hooks/reduxHook";

export interface ITemplateObsLinkedVar {
	variableScope: EVariableScope;
	varName: string;
	checked: boolean;
}

// only for more maintainability
export function useTemplateObsLoader(templateObsId?: string) {
	const dispatch = useDispatch<AppDispatch>();
	const isInit = useSelector(selectTmpltObsEdition.isInit);

	// initializer :
	const { data: remoteTemplateObs } =
		apiTemplateObs.useGetTemplateObsByIdQuery(templateObsId);
	const { data: templateObsUsages } =
		apiTemplateObs.useGetTemplateObsUsagesByIdQuery(templateObsId);

	useEffect(() => {
		dispatch(
			templateObsEditionActions.setRemoteTemplateObs(remoteTemplateObs),
		);
	}, [dispatch, remoteTemplateObs]);

	useEffect(() => {
		dispatch(
			templateObsEditionActions.setTemplateObsUsages(templateObsUsages),
		);
	}, [dispatch, templateObsUsages]);

	useEffect(() => {
		// on dismount -> reset current tmplt obs edition
		return () => {
			dispatch(templateObsEditionActions.resetTemplateObs());
		};
	}, [dispatch]);

	return isInit;
}

export function useTemplateObsEdition() {
	const dispatch = useAppDispatch();

	const currentTemplateObs = useSelector(
		selectTmpltObsEdition.selectEditingMergeValue,
	);
	const remoteTemplateObs = useSelector(
		selectTmpltObsEdition.selectRemoteTemplateObs,
	);
	const isTemplateObsUsed = useSelector(
		selectTmpltObsEdition.isTemplateObsUsed,
	);
	const formHasErrors = useSelector(
		selectTmpltObsEdition.selectFormHasErrors,
	);
	const currentVarGroupId = useSelector(
		selectTmpltObsEdition.selectCurrentVarGroupId,
	);
	const isCommentOpen = useSelector(selectTmpltObsEdition.isCommentOpen);
	const isAddVariableOpen = useSelector(
		selectTmpltObsEdition.isAddVariableOpen,
	);
	const isVarGroupCreateOpen = useSelector(
		selectTmpltObsEdition.isVarGroupCreateOpen,
	);
	const isVarGroupEditOpen = useSelector(
		selectTmpltObsEdition.isVarGroupEditOpen,
	);

	const setCurrentVarGroupId = (id?: string) =>
		dispatch(templateObsEditionActions.setCurrentVarGroupId(id));
	const updateCurrentTemplateObs = (
		update: Partial<ITemplateObsCoreSerialized>,
	) => dispatch(templateObsEditionActions.updateTemplateObs(update));
	const setCommentOpen = (isOpen: boolean) =>
		dispatch(templateObsEditionActions.setCommentOpen(isOpen));
	const setFormHasError = (hasError: boolean) =>
		dispatch(templateObsEditionActions.setFormHasError(hasError));
	const setAddVariableOpen = (isOpen: boolean) =>
		dispatch(templateObsEditionActions.setAddVariableOpen(isOpen));
	const setVarGroupCreateOpen = (isOpen: boolean) =>
		dispatch(templateObsEditionActions.setVarGroupCreateOpen(isOpen));
	const setVarGroupEditOpen = (isOpen: boolean) =>
		dispatch(templateObsEditionActions.setVarGroupEditOpen(isOpen));

	return {
		currentTemplateObs,
		remoteTemplateObs,
		isTemplateObsUsed,
		formHasErrors,
		currentVarGroupId,
		isCommentOpen,
		isAddVariableOpen,
		isVarGroupCreateOpen,
		isVarGroupEditOpen,
		setCurrentVarGroupId,
		setFormHasError,
		updateCurrentTemplateObs,
		setCommentOpen,
		setAddVariableOpen,
		setVarGroupCreateOpen,
		setVarGroupEditOpen,
	};
}

export function getVariableInTemplate(
	currentTemplateObs: ITemplateObsCoreSerialized | undefined,
	ontologyVariables: IVariableCore[],
): IVariableCore[] {
	return useMemo(() => {
		if (!currentTemplateObs) return [];
		// Retrieve all obs template variables in ontology
		return currentTemplateObs.variablesPool
			.map((variable) => {
				const foundVariable = ontologyVariables.find(
					(ontologyVar) =>
						ontologyVar._id === variable.variableId &&
						ontologyVar.scope === variable.scope,
				);
				return foundVariable;
			})
			.filter((elt) => elt !== undefined) as IVariableCore[];
	}, [ontologyVariables, currentTemplateObs]);
}

export function templateVarGridMethods(
	templateObservation: ITemplateObsCoreSerialized,
	variableOntology: IVariableCore[],
) {
	const variableInTemplate: IVariableCore[] = useMemo(() => {
		// Retrieve all obs template variables in ontology
		return templateObservation?.variablesPool
			.map((variable) => {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				const foundVariable = variableOntology.find(
					(ontologyVar) =>
						ontologyVar._id === variable.variableId &&
						ontologyVar.scope === variable.scope,
				);
				return foundVariable;
			})
			.filter((elt) => elt !== undefined) as IVariableCore[];
	}, [variableOntology, templateObservation?.variablesPool]);

	const isObsUnitCheck = (
		row: IVariableCore,
		obsUnit: IScopedVariable[] | undefined,
	) => {
		// Check means present in a particular observation unit depending on the scope
		const isVarInObsUnit = obsUnit?.find(
			(unitVar) =>
				unitVar.variableId === row._id && unitVar.scope === row.scope,
		);
		return isVarInObsUnit !== undefined;
	};

	const isTrialCheck = (row: IVariableCore) => {
		const trial = templateObservation?.templateObsUnits.trial;
		return isObsUnitCheck(row, trial);
	};

	const isMaterialCheck = (row: IVariableCore) => {
		const material = templateObservation?.templateObsUnits.material;
		return isObsUnitCheck(row, material);
	};

	return { variableInTemplate, isTrialCheck, isMaterialCheck };
}
