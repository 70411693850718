import { Skeleton } from "@mui/material";
import React, { CSSProperties, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Book, NoteBook } from "App/../assets";
import UserTag from "App/components/base-components/tag/user-tag/UserTag";
import { DIC, EDIC_KEY } from "App/dictionary";
import { selectECW } from "App/redux/ECW/ECW.selector";
import { trialWizardFetchAction } from "App/redux/ECW/trial-wizard-fetch-reducer";
import { apiGrowingArea } from "App/redux/growing-area/growing-area.api";
import { apiUser } from "App/redux/user/user.api";
import { AppDispatch } from "App/store";
import { getColorFromNotebookStatus } from "App/utils";
import { apiLevelCompletion } from "../../../redux/experiment-statistics/level-completion.api";
import { selectObsRoundEdition } from "../../../redux/observation-round-edition/obs-round-edition.selector";
import ImgBannerCard from "../../base-components/card/img-banner-card/ImgBannerCard";
import ProgressBar from "../../base-components/progress/progress-bar/ProgressBar";
import ColorTag from "../../base-components/tag/color-tag/ColorTag";
import TitledTag from "../../base-components/tag/titled-tag/TitledTag";
import { INotebookCardProps } from "./NotebookCard";

import "./NotebookBanner.scss";

export interface INotebookBannerProps extends INotebookCardProps {
	style?: CSSProperties;
	className?: string;
}

export default function NotebookBanner(props: INotebookBannerProps) {
	const dispatch = useDispatch<AppDispatch>();

	const obsRound = useSelector(
		selectObsRoundEdition.editingMergeValueDeserialized,
	);
	const trial = useSelector(selectECW.trialRemote);

	const { data: growingArea } = apiGrowingArea.useGetGrowingAreaByIdQuery(
		trial?.growingAreaId,
	);
	const { data: userObserver } = apiUser.useGetUserByIdQuery(
		props.notebook.observerId,
	);
	const nbCompletionData = props.notebook.completion ??
		apiLevelCompletion.useComputeNbCompletionByIdQuery(props.notebook._id).data;

	useEffect(() => {
		if (props.notebook.trialId) {
			dispatch(
				trialWizardFetchAction.fetchTrialAndDependencies(
					props.notebook.trialId,
				),
			);
		}
	}, [props.notebook.trialId, dispatch]);

	return (
		<ImgBannerCard
			onClick={props.onClick}
			className={props.className}
			disabledClick={props.disabledClick}
			style={{ ...props.style }}
			sideBanner={{
				imageSrc: NoteBook,
				logoSrc: Book,
			}}
		>
			<div className="NotebookBanner-container">
				{nbCompletionData ? (
					<ProgressBar
						title={`${nbCompletionData.totalDone}/${nbCompletionData.totalExpected}`}
						currentProgress={nbCompletionData.totalDone}
						goalProgress={nbCompletionData.totalExpected}
						isLabelHidden
						height="10px"
					/>
				) : (
					<Skeleton />
				)}

				<h1 className="NotebookBanner-title">{obsRound?.name}</h1>

				<div className="NotebookBanner-tags">
					<TitledTag
						title={DIC(EDIC_KEY.FROM)}
						tagValue={`${trial?.year ?? "YYYY"} / ${
							trial?.name ?? "_"
						}`}
					/>
				</div>
				<div className="NotebookBanner-tags">
					<TitledTag
						title={DIC(EDIC_KEY.FROM)}
						tagValue={`${
							obsRound?.startDate.toLocaleDateString() ??
							"xx/xx/xxxx"
						}`}
					/>
					<TitledTag
						title={DIC(EDIC_KEY.TO)}
						tagValue={`${
							obsRound?.endDate.toLocaleDateString() ??
							"xx/xx/xxxx"
						}`}
					/>
					<TitledTag
						title={DIC(EDIC_KEY.GROWING_AREA)}
						tagValue={`${growingArea?.name}`}
					/>
					{userObserver && (
						<UserTag
							title={DIC(EDIC_KEY.OBSERVER)}
							user={userObserver}
						/>
					)}
					<div className="NotebookBanner-tags-right-side">
						<ColorTag
							title={DIC(EDIC_KEY.STATUS)}
							tagValue={props.notebook.status}
							colorIndicator={getColorFromNotebookStatus(
								props.notebook.status,
							)}
						/>
					</div>
				</div>
			</div>
		</ImgBannerCard>
	);
}
