import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";
import { DIC, EDIC_KEY } from "../../../dictionary";
import DorianeButton from "../button/DorianeButton";
import InputText from "../input-text/InputText";

import "./TextSearchBar.scss";

interface IProps {
	onSubmit?: (query: string) => void;
	onChange?: (query: string) => void;
}

export default function TextSearchBar(props: IProps) {
	const [query, setQuery] = useState<string>("");
	const handleQueryChange: React.ChangeEventHandler<
		HTMLInputElement | HTMLTextAreaElement
	> = (event) => {
		setQuery(event.target.value);
		if (props.onChange) props.onChange(event.target.value);
	};

	const handleSubmit = async () => {
		if (props.onSubmit) props.onSubmit(query);
	};

	return (
		<div className="SearchBar">
			<InputText
				type="search"
				label={DIC(EDIC_KEY.SEARCH)}
				onChange={handleQueryChange}
			/>
			<DorianeButton onClick={handleSubmit} dorianeStyle="quaternary">
				<SearchIcon />
			</DorianeButton>
		</div>
	);
}
