import React from "react";
import { createRoot } from "react-dom/client";

// Auth service --------------------------------
import { initAuth } from "./App/auth/cognito-auth-init";

// glide data grid import -------
import "@glideapps/glide-data-grid/dist/index.css";

// end --------------------------

// local imports --------------------------------
import App from "./App/App";
import { initConfiguration } from "./App/config";
import * as serviceWorker from "./serviceWorker";

import "./assets/fonts/DidactGothic-Regular.ttf";
import "./style.scss";

initConfiguration().then(() => {
	const container = document.getElementById("root");
	initAuth();
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const root = createRoot(container!);
	root.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
	);

	// If you want your app to work offline and load faster, you can change
	// unregister() to register() below. Note this comes with some pitfalls.
	// Learn more about service workers: https://bit.ly/CRA-PWA
	serviceWorker.unregister();
});
