"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EUnit = void 0;
var EUnit;
(function (EUnit) {
    EUnit[EUnit["GIGA"] = 1000000000] = "GIGA";
    EUnit[EUnit["MEGA"] = 1000000] = "MEGA";
    EUnit[EUnit["KILO"] = 1000] = "KILO";
    EUnit[EUnit["HECTO"] = 100] = "HECTO";
    EUnit[EUnit["DECA"] = 10] = "DECA";
    EUnit[EUnit["DEFAULT"] = 1] = "DEFAULT";
    EUnit[EUnit["DECI"] = 0.1] = "DECI";
    EUnit[EUnit["CENTI"] = 0.01] = "CENTI";
    EUnit[EUnit["MILLI"] = 0.001] = "MILLI";
    EUnit[EUnit["MICRO"] = 0.000001] = "MICRO";
    EUnit[EUnit["NANO"] = 1e-9] = "NANO";
})(EUnit = exports.EUnit || (exports.EUnit = {}));
