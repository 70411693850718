import { PlainObject } from "@mongodb-js/charts-embed-dom/dist/declarations/src/types";
import React, { useMemo, useState } from "react";

import {
	EChartAnalysisContext,
	EChartDashboardTeamDevelopers,
	IDashboardTeamDevelopersFilterMQL,
} from "shared-type";

import DashboardTeamDevelopersHeader from "../../../../../components/dashboard/header/team/developers/DashboardTeamDevelopersHeader";
import { IDashboardTeamDevelopersFilter } from "../../../../../components/dashboard/header/team/types";
import GridChartRenderer from "../../../../../components/dashboard/utils/GridChartRenderer";

import "./DashboardTeamDevelopers.scss";

export default function DashboardTeamDevelopers() {
	const [filterGlobal, setFilterGlobal] = useState<
		IDashboardTeamDevelopersFilter | undefined
	>(undefined);
	const graphFilter = useMemo(() => {
		if (filterGlobal === undefined) {
			return undefined;
		}
		const filters: Partial<IDashboardTeamDevelopersFilterMQL>[] = [];
		if (filterGlobal.countries && filterGlobal.countries.length > 0) {
			filters.push({
				"growingArea.country": { $in: filterGlobal.countries },
			});
		}
		if (filterGlobal.regions && filterGlobal.regions.length > 0) {
			filters.push({
				"growingArea.region": { $in: filterGlobal.regions },
			});
		}
		if (filterGlobal.expYears && filterGlobal.expYears.length > 0) {
			filters.push({ "trial.year": { $in: filterGlobal.expYears } });
		}
		if (filterGlobal.expName && filterGlobal.expName.length > 0) {
			filters.push({ "trial.name": { $in: filterGlobal.expName } });
		}
		if (filterGlobal.users && filterGlobal.users.length > 0) {
			filters.push({ "notebook.user": { $in: filterGlobal.users } });
		}
		if (filterGlobal.species && filterGlobal.species.length > 0) {
			filters.push({ "trial.species": { $in: filterGlobal.species } });
		}
		if (filters.length === 0) {
			return {};
		}
		return { $and: filters } as PlainObject;
	}, [filterGlobal]);

	const isFilterReady = graphFilter !== undefined;
	return (
		<>
			<DashboardTeamDevelopersHeader
				filterGlobal={filterGlobal}
				submitNewFilter={setFilterGlobal}
			/>
			<div className="DashboardTeamDevelopers-body take-remaining-space">
				{isFilterReady && (
					<>
						<GridChartRenderer
							context={
								EChartAnalysisContext.DASHBOARD_TEAM_DEVELOPERS
							}
							identifier={
								EChartDashboardTeamDevelopers.TRIAL_COUNT
							}
							graphFilter={graphFilter}
							gridRow={"span 2"}
							gridColumn={"span 1"}
						/>
						<GridChartRenderer
							context={
								EChartAnalysisContext.DASHBOARD_TEAM_DEVELOPERS
							}
							identifier={
								EChartDashboardTeamDevelopers.TRIAL_STATUS
							}
							graphFilter={graphFilter}
							gridRow={"span 2"}
							gridColumn={"span 2"}
						/>
						<GridChartRenderer
							context={
								EChartAnalysisContext.DASHBOARD_TEAM_DEVELOPERS
							}
							identifier={EChartDashboardTeamDevelopers.MET_COUNT}
							graphFilter={graphFilter}
							gridRow={"span 2"}
							gridColumn={"span 1"}
						/>
						<GridChartRenderer
							context={
								EChartAnalysisContext.DASHBOARD_TEAM_DEVELOPERS
							}
							identifier={
								EChartDashboardTeamDevelopers.TRIAL_TABLE
							}
							graphFilter={graphFilter}
							gridRow={"span 3"}
							gridColumn={"span 4"}
						/>
						<GridChartRenderer
							context={
								EChartAnalysisContext.DASHBOARD_TEAM_DEVELOPERS
							}
							identifier={
								EChartDashboardTeamDevelopers.TRIAL_STATUS_GEO
							}
							graphFilter={graphFilter}
							gridRow={"span 4"}
							gridColumn={"span 4"}
						/>
						<GridChartRenderer
							context={
								EChartAnalysisContext.DASHBOARD_TEAM_DEVELOPERS
							}
							identifier={
								EChartDashboardTeamDevelopers.OPERATION_PROGRESS_TABLE
							}
							graphFilter={graphFilter}
							gridRow={"span 3"}
							gridColumn={"span 4"}
						/>
					</>
				)}
			</div>
		</>
	);
}
