import React, { useEffect, useState } from "react";

import { DIC, EDIC_KEY } from "../../../../dictionary";
import { CustomCheckbox } from "../../../base-components/checkbox/CustomCheckbox";
import ChipCombobox from "../../../base-components/combobox/chip-combobox/ChipCombobox";
import {
	useDashboardFetchMetTrialFilter,
	useDashboardRegionsFilter,
	useDashboardSpeciesFilter,
	useDashboardUserFilter,
	useDashboardYearFilter,
} from "../../hooks/hooks";
import { WITHOUT_MET_CONST } from "../const";
import DashboardHeader from "../dashboard-header/DashboardHeader";
import { IDashboardHeaderFilter } from "./types";

interface DashboardHeaderProps {
	filterGlobal: IDashboardHeaderFilter | undefined;
	submitNewFilter: (filter: IDashboardHeaderFilter) => void;
}

export default function DashboardVarietyHeader(props: DashboardHeaderProps) {
	const { metMap, metData } = useDashboardFetchMetTrialFilter();

	// SELECTED VALUES
	const { expYears, setExpYears, computeExpYearSet } =
		useDashboardYearFilter();
	const [metIds, setMetIds] = useState<string[] | undefined>(undefined);
	const { regions, setRegions, regionsSetSorted } =
		useDashboardRegionsFilter();
	const { usersIds, setUsersIds, translateUser, userIdsSorted } =
		useDashboardUserFilter();
	const [validChecked, setValidChecked] = useState<boolean>(true);
	const [inProgressChecked, setInProgressChecked] = useState<boolean>(false);
	const {
		speciesFilter: species,
		setSpeciesFilter: setSpecies,
		speciesSort,
	} = useDashboardSpeciesFilter();

	const computeAndSubmitChartFilter = () => {
		props.submitNewFilter({
			expYears,
			expMETIds: metIds?.map((id) =>
				(id === WITHOUT_MET_CONST ? null : id),
			),
			regions,
			users: usersIds,
			species: species,
			validChecked,
			inProgressChecked,
		});
	};
	// set filter on ready
	useEffect(() => {
		computeAndSubmitChartFilter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<DashboardHeader
			computeAndSubmitChartFilter={computeAndSubmitChartFilter}
		>
			<ChipCombobox<number>
				value={expYears ?? []}
				label={DIC(EDIC_KEY.YEAR)}
				onChange={setExpYears}
				comboboxValues={computeExpYearSet}
				translateMethod={(value) => value.toString()}
				className="DashboardHeader-combobox"
				selectAllBtn
			/>
			<ChipCombobox<string>
				value={regions ?? []}
				label={DIC(EDIC_KEY.REGION)}
				onChange={setRegions}
				comboboxValues={regionsSetSorted}
				translateMethod={(value) => value}
				noWrap
				className="DashboardHeader-combobox"
				selectAllBtn
			/>
			<ChipCombobox<string>
				value={usersIds ?? []}
				label={DIC(EDIC_KEY.USER)}
				onChange={setUsersIds}
				comboboxValues={userIdsSorted}
				translateMethod={translateUser}
				noWrap
				className="DashboardHeader-combobox"
				selectAllBtn
			/>
			<ChipCombobox<string>
				value={metIds ?? []}
				label={DIC(EDIC_KEY.MET)}
				onChange={setMetIds}
				comboboxValues={[
					WITHOUT_MET_CONST,
					...(metData?.map((elt) => elt._id) || []),
				]}
				translateMethod={(value) =>
					metMap.get(value)?.name ||
					DIC(EDIC_KEY.DASHBOARD_WITHOUT_MET)
				}
				className="DashboardHeader-combobox"
				selectAllBtn
			/>
			<ChipCombobox<string>
				value={species ?? []}
				label={DIC(EDIC_KEY.SPECIES)}
				onChange={setSpecies}
				comboboxValues={speciesSort}
				translateMethod={(value) => value}
				className="DashboardHeader-combobox"
				selectAllBtn
			/>
			<div className="DashboardHeader-checkboxes">
				<div className="DashboardHeader-checkboxes-title">
					{DIC(EDIC_KEY.DATA_STATUS)}
				</div>
				<div className="DashboardHeader-checkboxes-container">
					<CustomCheckbox
						label={DIC(EDIC_KEY.VALID)}
						checked={validChecked}
						onChange={(event, checked) => setValidChecked(checked)}
					/>

					<CustomCheckbox
						label={DIC(EDIC_KEY.IN_PROGRESS)}
						checked={inProgressChecked}
						onChange={(event, checked) =>
							setInProgressChecked(checked)
						}
					/>
				</div>
			</div>
		</DashboardHeader>
	);
}
