import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { useMemo } from "react";

import {
	EntitySerializer,
	formatString,
	isOperationTaskSerialized,
} from "common";
import {
	ETaskType,
	ExperimentType,
	ICreateOperation,
	IOperation,
	IOperationSerialized,
	IPatchOperation,
} from "shared-type";

import { DIC, EDIC_KEY } from "../../dictionary";
import { useRequest } from "../../request-provider";
import { defaultApiError, ReduxApiError } from "../utils/errors";
import { ExpGanttApiHelper } from "../experiment-statistics/exp-gantt.api";

export const apiOperation = createApi({
	reducerPath: "api-operation",
	baseQuery: () => ({ data: undefined }),
	// global configuration for the api (in second)
	keepUnusedDataFor: 30,
	// global configuration for the api
	refetchOnMountOrArgChange: 30,
	tagTypes: ["Update", "Delete", "Create"],

	endpoints: (build) => ({
		getOperationsByExpId: build.query<
			IOperationSerialized[],
			{ expId?: string; expType: ExperimentType }
		>({
			queryFn: async (args) => {
				if (!args.expId) {
					return { data: [] };
				}
				try {
					const result = await useRequest().opTask.getOpTasksByExpId(
						args.expId,
						args.expType,
						ETaskType.OPERATION,
					);
					const operations = result.data.filter(
						isOperationTaskSerialized,
					);
					return {
						data: operations,
					};
				} catch (err) {
					return defaultApiError(
						err,
						formatString(
							DIC(EDIC_KEY.CANT_ACTION_ENTITY),
							DIC(EDIC_KEY.FETCH).toLowerCase(),
							DIC(EDIC_KEY.OPERATION).toLowerCase(),
						),
					);
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),

		getOperationById: build.query<
			IOperationSerialized | undefined,
			string | undefined
		>({
			queryFn: async (id) => {
				try {
					if (!id) {
						return { data: undefined };
					}
					const result = await useRequest().opTask.getOpTaskById(id);
					const operation = result.data;

					if (!isOperationTaskSerialized(operation)) {
						throw new Error(
							`Invalid task type "${operation.type}": not an operation`,
						);
					}

					return {
						data: operation,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch operation with id : " + id,
					);
				}
			},
			providesTags: (result, error, id) => {
				return [
					{ type: "Delete", id: id },
					{ type: "Update", id: id },
					{ type: "Create", id: id },
				];
			},
		}),
		createOperation: build.mutation<IOperationSerialized, ICreateOperation>(
			{
				queryFn: async (newOperation) => {
					let id: string;

					try {
						id = await useRequest()
							.opTask.createOpTask(newOperation)
							.then((resp) => resp.data);
					} catch (err) {
						return defaultApiError(
							err,
							"Unable to create the operation",
						);
					}
					try {
						const createdOperation = await useRequest()
							.opTask.getOpTaskById(id)
							.then((resp) => resp.data);

						if (!isOperationTaskSerialized(createdOperation)) {
							throw new Error(
								"Invalid task type : not an operation",
							);
						}
						return { data: createdOperation };
					} catch (err) {
						return defaultApiError(
							err,
							"Unable to get the operation with id : " + id,
						);
					}
				},
				invalidatesTags: (result, error) => {
					if (error) {
						return [];
					}
					ExpGanttApiHelper.invalidateTags(["Get"]);
					return [
						{ type: "Create", id: result?._id },
						{ type: "Create", id: "ALL" },
					];
				},
			},
		),
		createManyOperationFromTemplates: build.mutation<
			string[],
			{ taskTemplateIds: string[]; trialId: string }
		>({
			queryFn: async (args) => {
				try {
					const ids = await useRequest()
						.opTask.createManyOpTaskFromTemplates(
							args.taskTemplateIds,
							args.trialId,
						)
						.then((resp) => resp.data);
					return { data: ids };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to create the operations from templates",
					);
				}
			},
			invalidatesTags: (result, error) => {
				if (error) {
					return [];
				}
				ExpGanttApiHelper.invalidateTags(["Get"]);
				return [{ type: "Create", id: "ALL" }];
			},
		}),

		deleteOperationById: build.mutation<void, string | undefined>({
			queryFn: async (id) => {
				if (!id) {
					const error: { error: ReduxApiError } = {
						error: {
							message: "Id is required for delete operation",
						},
					};
					return error;
				}

				try {
					const result = await useRequest().opTask.deleteOpTaskById(
						id,
					);
					return { data: result.data };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to delete operation with id : " + id,
					);
				}
			},
			invalidatesTags: (result, error, arg) => {
				if (error) {
					return [];
				}
				ExpGanttApiHelper.invalidateTags(["Get"]);
				return [
					{ type: "Delete", id: arg },
					{ type: "Delete", id: "ALL" },
				];
			},
		}),

		updateOperationById: build.mutation<
			void,
			{ id: string; update: IPatchOperation }
		>({
			queryFn: async (arg) => {
				try {
					const result = await useRequest()
						.opTask.patchOpTaskById(arg.id, arg.update)
						.then((resp) => resp.data);
					return { data: result };
				} catch (err) {
					return defaultApiError(err, "Unable to update operation");
				}
			},
			invalidatesTags: (result, error, arg) => {
				if (error) {
					return [];
				}
				ExpGanttApiHelper.invalidateTags(["Get"]);
				return [
					{ type: "Update", id: arg.id },
					{ type: "Update", id: "ALL" },
				];
			},
		}),
	}),
});

const useGetOperationsByExpIdQueryDeserialized = (
	expType: ExperimentType,
	id?: string,
	option?: Parameters<typeof apiOperation.useGetOperationsByExpIdQuery>[1],
) => {
	const apiResult = apiOperation.useGetOperationsByExpIdQuery(
		{ expId: id, expType },
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		option as any,
	);
	const dataDeserialized = useMemo(() => {
		return apiResult.data
			? apiResult.data.map((operation: IOperationSerialized) =>
					EntitySerializer.deserialize<IOperation>(operation),
			  )
			: undefined;
	}, [apiResult.data]);
	return { ...apiResult, data: dataDeserialized };
};

export const operationCustomHook = {
	useGetOperationsByExpIdQueryDeserialized,
};
