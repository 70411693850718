import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DIC, EDIC_KEY } from "../../../../../dictionary";
import { selectECW } from "../../../../../redux/ECW/ECW.selector";
import { ECWNextMode, ECWAction } from "../../../../../redux/ECW/ECW.slice";
import { growingAreaCustomHook } from "../../../../../redux/growing-area/growing-area.api";
import { AppDispatch } from "../../../../../store";
import DorianeButton from "../../../../base-components/button/DorianeButton";
import CreationWizardMenu from "../../../../base-components/menu/creation-wizard/CreationWizardMenu";
import GrowingAreaCard from "../../../../growing-area/card/GrowingAreaCard";
import ExpSelectGrowingArea from "../../../../experiment/common/modal/ExpSelectGrowingArea";
import CommonECWPart from "../../CommonECWPart";

import "../../ECW.scss";

interface ECWGrowingAreaProps {
	onNext: (action: ECWNextMode) => void;
}

export default function ECWGrowingArea(props: ECWGrowingAreaProps) {
	const dispatch = useDispatch<AppDispatch>();

	const currentGrowingAreaId = useSelector(selectECW.growingAreaId);
	const { data: currentGrowingArea } =
		growingAreaCustomHook.useGetGrowingAreaByIdQueryDeserialized(
			currentGrowingAreaId,
		);
	const isSelectLocationOpen = useSelector(selectECW.isSelectGrowingAreaOpen);

	useEffect(() => {
		// By default : the selection list of germplasm is opened
		dispatch(ECWAction.setSelectGrowingAreaOpen(true));
		return () => {
			dispatch(ECWAction.setSelectGrowingAreaOpen(false));
		};
	}, []);

	return (
		<>
			<CommonECWPart>
				<div className="ECW-body take-remaining-space flex-column">
					<div className="ECW-title-container">
						<h1>{DIC(EDIC_KEY.GROWING_AREA)}</h1>
						<DorianeButton
							dorianeStyle="tertiary"
							onClick={() =>
								dispatch(
									ECWAction.setSelectGrowingAreaOpen(
										!isSelectLocationOpen,
									),
								)
							}
						>
							{DIC(EDIC_KEY.EDIT_SELECTION)}
						</DorianeButton>
					</div>
					{currentGrowingArea && (
						<GrowingAreaCard
							growingArea={currentGrowingArea}
							disabledClick
							onDelete={() =>
								dispatch(
									ECWAction.updateGrowingAreaId(undefined),
								)
							}
						/>
					)}
				</div>
				<CreationWizardMenu
					onSave={() => props.onNext("save")}
					onDoItLaterClick={() => props.onNext("doItLater")}
					displaySave={currentGrowingArea !== undefined}
				/>
			</CommonECWPart>
			{isSelectLocationOpen && (
				<div className="ECW-right-side">
					<ExpSelectGrowingArea />
				</div>
			)}
		</>
	);
}
