import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ITemplateObsCoreSerialized } from "shared-type";

export type ITemplateObsEdition = {
	isInit: boolean;
	remoteTemplateObs?: ITemplateObsCoreSerialized;
	templateObsUsages?: string[];
	templateObsEdition: Partial<ITemplateObsCoreSerialized>;
	formHasError: boolean;
	currentVarGroupId?: string;
	isAddVariableOpen: boolean;
	isCommentOpen: boolean;
	isVarGroupCreateOpen: boolean;
	isVarGroupEditOpen: boolean;
	isEditingInProgress: boolean;
};

function createInitialState(): ITemplateObsEdition {
	return {
		isInit: false,
		templateObsEdition: {},
		isAddVariableOpen: false,
		formHasError: false,
		isCommentOpen: false,
		isVarGroupCreateOpen: false,
		isVarGroupEditOpen: false,
		isEditingInProgress: false,
	};
}

const templateObsEditionSlice = createSlice({
	name: "templateObsEdition",
	initialState: createInitialState(),
	reducers: {
		resetTemplateObs: (state) => {
			state.isInit = false;
			state.remoteTemplateObs = undefined;
			state.templateObsUsages = undefined;
			state.templateObsEdition = {};
			state.currentVarGroupId = undefined;
		},
		setRemoteTemplateObs: (
			state,
			action: PayloadAction<ITemplateObsCoreSerialized | undefined>,
		) => {
			if (action.payload === undefined) {
				state.isInit = false;
				state.remoteTemplateObs = undefined;
			}
			state.isInit = true;
			state.remoteTemplateObs = action.payload;
		},
		setTemplateObsUsages: (
			state,
			action: PayloadAction<string[] | undefined>,
		) => {
			state.templateObsUsages = action.payload;
		},
		updateTemplateObs: (
			state,
			action: PayloadAction<Partial<ITemplateObsCoreSerialized>>,
		) => {
			state.templateObsEdition = {
				...state.templateObsEdition,
				...action.payload,
			};
			state.isEditingInProgress = true;
		},
		setFormHasError: (state, action: PayloadAction<boolean>) => {
			state.formHasError = action.payload;
		},
		setCurrentVarGroupId: (
			state,
			action: PayloadAction<string | undefined>,
		) => {
			state.currentVarGroupId = action.payload;
		},
		setAddVariableOpen: (state, action: PayloadAction<boolean>) => {
			state.isAddVariableOpen = action.payload;
		},
		setCommentOpen: (state, action: PayloadAction<boolean>) => {
			state.isCommentOpen = action.payload;
		},
		setVarGroupCreateOpen: (state, action: PayloadAction<boolean>) => {
			state.isVarGroupCreateOpen = action.payload;
		},
		setVarGroupEditOpen: (state, action: PayloadAction<boolean>) => {
			state.isVarGroupEditOpen = action.payload;
		},
		resetEditingState: (state) => {
			state.isEditingInProgress = false;
		},
	},
});

export const templateObsEditionReducer = templateObsEditionSlice.reducer;
export const templateObsEditionActions = { ...templateObsEditionSlice.actions };
