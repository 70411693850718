import React from "react";

import DorianeRadioButton from "../../../radio-button/DorianeRadioButton";
import { IColumn } from "../doriane-data-grid/DorianeDataGrid";

import "./RadioButtonColumn.scss";

interface RadioButtonColumnProps<Row> {
	isCheck?: (row: Row) => boolean;
	onCheck?: (row: Row) => void;
	headerName?: string;
	width?: string;
	disabled?: boolean;
}

export function RadioButtonColumn<Row>(
	props: RadioButtonColumnProps<Row>,
): IColumn<Row> {
	return {
		id: "radioButton",
		getHeaderRenderer: props.headerName ?? "",
		getCellRenderer: (row: Row) => (
			<div className="RadioButtonColumn-cell">
				<DorianeRadioButton
					disabled={props.disabled}
					checked={props.isCheck && props.isCheck(row)}
					// onClick to detect the event even if already checked
					onClick={(event) => props.onCheck && props.onCheck(row)}
				/>
			</div>
		),
		width: props.width ?? "50px",
	};
}
