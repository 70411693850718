import { Slider } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { UseFormReturn, useWatch } from "react-hook-form";

import { EVariableCoreType, VariableConstraint } from "shared-type";

import { FormMode } from "../../../../const";
import { DIC, EDIC_KEY } from "../../../../dictionary";
import {
	ConstraintConverter,
	EditingDateConstraint,
	EditingDecConstraint,
	EditingIntConstraint,
	EditingStringConstraint,
	EditingVariableConstraint,
} from "./editing-variable-constraint";
import { ICustomVariableForm } from "../../../form/custom-variable";
import { Input } from "stories/base-components/Input/Input";
import { DateRangePicker } from "stories/base-components/DateRangePicker/DateRangePicker";
import Values from "stories/constants/Values";
import Box from "App/components/base-components/placement/box/Box";
import Size from "stories/constants/Size/Size";

import "./ConstraintForm.scss";

interface ICustomVariableConstraintFormProps {
	mode: FormMode;
	form: UseFormReturn<ICustomVariableForm>;
	isTypeConstraintType: (
		type: EVariableCoreType,
	) => type is
		| EVariableCoreType.DATE
		| EVariableCoreType.DATETIME
		| EVariableCoreType.STRING
		| EVariableCoreType.INT
		| EVariableCoreType.DEC;
}

export default function ConstraintForm(
	props: ICustomVariableConstraintFormProps,
) {
	const isVarUsed = props.mode === "used";
	const form = props.form;
	const { setValue } = form;
	const [constraint, type] = useWatch({
		control: form.control,
		name: ["constraint", "type"],
	});

	const constraintConverted = ConstraintConverter.convertToEdition(
		constraint ??
			({
				type: type,
			} as Partial<VariableConstraint>),
	);
	const setConstraint = <
		D extends EditingVariableConstraint =
			| EditingDateConstraint
			| EditingStringConstraint,
		DKey extends keyof D = keyof EditingVariableConstraint,
	>(
		key: DKey,
		value: D[DKey],
	): void => {
		if (props.isTypeConstraintType(type)) {
			const constraintConverted = {
				...constraint,
				...ConstraintConverter.convertFromEdition({
					[key]: value,
					type: type,
				} as Partial<EditingVariableConstraint>),
			};
			setValue("constraint", constraintConverted);
		}
	};

	return (
		<div className="CustomVariableForm-block flex-column">
			{(type === EVariableCoreType.DATE ||
				type === EVariableCoreType.DATETIME) && (
				<Box paddingTop={Size.sm}>
					<DateRangePicker
						startDateInput={
							(constraintConverted as EditingDateConstraint)
								.minDate
								? dayjs(
										(
											constraintConverted as EditingDateConstraint
										).minDate,
								  ).format("MM/DD/YYYY")
								: null
						}
						setStartDateInput={(date: string) => {
							if (date !== null) {
								setConstraint(
									"minDate" as any,
									new Date(date || "invalid"),
								);
							}
						}}
						endDateInput={
							(constraintConverted as EditingDateConstraint)
								.maxDate
								? dayjs(
										(
											constraintConverted as EditingDateConstraint
										).maxDate,
								  ).format("MM/DD/YYYY")
								: null
						}
						setEndDateInput={(date: string) => {
							if (date !== null) {
								setConstraint(
									"maxDate" as any,
									new Date(date || "invalid"),
								);
							}
						}}
						disabled={isVarUsed}
					/>
				</Box>
			)}
			{(type === EVariableCoreType.INT ||
				type === EVariableCoreType.DEC) && (
				<div className="CustomVariableForm-double-input">
					<Input
						label={DIC(EDIC_KEY.MIN)}
						type="number"
						onChange={(event) =>
							setConstraint("min" as any, event?.target.value)
						}
						disabled={isVarUsed}
						value={
							(constraintConverted as EditingIntConstraint).min ??
							(isVarUsed ? "_" : "")
						}
						width={Values.inputWidth}
					/>
					<Input
						label={DIC(EDIC_KEY.MAX)}
						type="number"
						onChange={(event) =>
							setConstraint("max" as any, event?.target.value)
						}
						disabled={isVarUsed}
						value={
							(constraintConverted as EditingIntConstraint).max ??
							(isVarUsed ? "_" : "")
						}
						width={Values.inputWidth}
					/>
				</div>
			)}
			{type === EVariableCoreType.DEC && (
				<div className="flex-column ConstraintForm-slider">
					<h2>{`${DIC(EDIC_KEY.PRECISION)} 0,${"0".repeat(
						(constraint as EditingDecConstraint)?.nbDigits ?? 2,
					)}`}</h2>

					<div className="CustomVariableForm-double-input">
						<Slider
							value={
								(constraint as EditingDecConstraint)
									?.nbDigits ?? 2
							}
							disabled={isVarUsed}
							onChange={(_, newValue) =>
								setConstraint("nbDigits" as any, newValue)
							}
							marks
							step={1}
							min={1}
							max={10}
						/>
					</div>
				</div>
			)}
			{type === EVariableCoreType.STRING && (
				<div className="CustomVariableForm-double-input">
					<Input
						type="number"
						label={DIC(EDIC_KEY.MIN_LENGTH)}
						onChange={(event) =>
							setConstraint("min" as any, event?.target.value)
						}
						disabled={isVarUsed}
						value={
							(constraint as EditingIntConstraint)?.min ??
							(isVarUsed ? "_" : "")
						}
						width={Values.inputWidth}
					/>
					<Input
						type="number"
						label={DIC(EDIC_KEY.MAX_LENGTH)}
						onChange={(event) =>
							setConstraint("max" as any, event?.target.value)
						}
						disabled={isVarUsed}
						value={
							(constraint as EditingIntConstraint)?.max ??
							(isVarUsed ? "_" : "")
						}
						width={Values.inputWidth}
					/>
				</div>
			)}
		</div>
	);
}
