import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";

import { formatString } from "common";
import { ITemplateObsCoreSerialized, IVariableCore } from "shared-type";

import { Modal } from "stories/base-components/Modal/Modal";
import { DIC, EDIC_KEY } from "../../../../../../dictionary";
import useAppSnackbar from "../../../../../../hooks/useAppSnackbar";
import { ApiErrorSnackbar } from "../../../../../../redux/utils/api-error-snackbar/ApiErrorSnackbar";
import { ReduxApiError } from "../../../../../../redux/utils/errors";
import { apiVariableGroup } from "../../../../../../redux/variable-group/variable-group.api";
import { varGroupValidator } from "../../../../../form/variable-group";
import {
	varGroupFormToObject,
	varGroupObjectToForm,
} from "../../../../../form/variable-group/transformer";
import VariableGroupForm, {
	IVarGroupForm,
} from "../../../../../form/variable-group/VariableGroupForm";

interface IEditVariableGroupModalProps {
	onClose: () => void;
	varGroupId: string;
	templateObservation?: ITemplateObsCoreSerialized;
	variableInTemplate: IVariableCore[];
	isEditionDisabled?: boolean;
	open: boolean;
}

export default function EditVariableGroupModal(
	props: IEditVariableGroupModalProps,
) {
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();

	const { data: fetchedVariableGroup } =
		apiVariableGroup.useGetVariableGroupByIdQuery(props.varGroupId);
	const { data: variableGroupUsages } =
		apiVariableGroup.useGetVariableGroupUsagesByIdQuery(props.varGroupId);

	const form = useForm<IVarGroupForm>({
		defaultValues: {
			variables: [],
		},
		resolver: yupResolver(varGroupValidator as any),
	});
	const { handleSubmit, formState } = form;
	const { isSubmitting } = formState;

	const [updateVariableGroupById] =
		apiVariableGroup.useUpdateVarGroupByIdMutation();
	const [deleteVarGroupById] =
		apiVariableGroup.useDeleteVarGroupByIdMutation();

	const isVariableGroupUsed = useMemo(() => {
		// If the edition is disabled, the group is always considered as used.
		if (props.isEditionDisabled) return true;

		if (!variableGroupUsages) return false;
		return variableGroupUsages.length !== 0;
	}, [props.isEditionDisabled, variableGroupUsages]);

	const closeModal = () => {
		props.onClose();
	};

	const handleUpdate = async (data: IVarGroupForm) => {
		if (fetchedVariableGroup === undefined) return;
		const variableGroup = varGroupFormToObject(data);

		await updateVariableGroupById({
			id: fetchedVariableGroup._id,
			update: variableGroup,
		})
			.unwrap()
			.then(() => {
				enqueueSnackbarSuccess(
					formatString(
						DIC(EDIC_KEY.ENTITY_UPDATED),
						DIC(EDIC_KEY.VARIABLE_GROUP),
					),
				);
			})
			.catch((err: ReduxApiError) => {
				console.warn(err);
				enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
			});
	};

	const handleDelete = async () => {
		if (fetchedVariableGroup === undefined) return;
		await deleteVarGroupById(fetchedVariableGroup._id)
			.unwrap()
			.then(() => {
				enqueueSnackbarSuccess(
					formatString(
						DIC(EDIC_KEY.ENTITY_DELETED),
						DIC(EDIC_KEY.VARIABLE_GROUP),
					),
				);
				closeModal();
			})
			.catch((err: ReduxApiError) => {
				console.warn(err);
				enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
			});
	};

	useEffect(() => {
		if (!fetchedVariableGroup) {
			form.reset({
				variables: [],
			});
			return;
		}
		form.reset(varGroupObjectToForm(fetchedVariableGroup));
		form.trigger();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchedVariableGroup]);

	return (
		<Modal
			open={props.open}
			titleIcon={"variable-group"}
			title={DIC(EDIC_KEY.EDIT_VARIABLE_GROUP)}
			handleClose={closeModal}
			validateBtn={{
				action: () => {
					handleSubmit(
						(data) => {
							handleUpdate(data);
							closeModal();
						},
						(error) => console.error(error),
					)();
				},
				label: DIC(EDIC_KEY.SAVE),
				isLoading: isSubmitting,
				disabled: isVariableGroupUsed,
			}}
			secondaryBtn={{
				action: handleDelete,
				label: DIC(EDIC_KEY.DELETE),
				disabled: isVariableGroupUsed,
				tooltipMessage: DIC(EDIC_KEY.THIS_VARIABLE_GROUP_IS_USED),
			}}
			width={660}
		>
			{props.templateObservation && (
				<VariableGroupForm
					mode={isVariableGroupUsed ? "used" : "unused"}
					form={form}
					templateObservation={props.templateObservation}
					variablesInTemplate={props.variableInTemplate}
				/>
			)}
		</Modal>
	);
}
