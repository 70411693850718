import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { PlainObject } from "@mongodb-js/charts-embed-dom/dist/declarations/src/types";
import RefreshIcon from "@mui/icons-material/Refresh";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { concatClassName } from "common";
import { IVariableCore } from "shared-type";

import { DownloadJson, DownloadPng } from "assets";
import { DIC, EDIC_KEY } from "../../../dictionary";
import { selectAuth } from "../../../redux/authentication/authentication.selector";
import { exportChartJSON, exportChartPNG, refreshChart } from "../../../utils";
import DorianeButton from "../button/DorianeButton";
import Combobox from "../combobox/Combobox";

import "./ChartCard.scss";

interface ChartCardProps {
	description?: string;
	title?: string;
	chartName: string;
	variables?: IVariableCore[];
	width?: string;
	sdk: ChartsEmbedSDK;
	chartId: string;
	filter?: PlainObject;
	additionalFilter?: PlainObject;
	className?: string;
	chartAutoRefresh?: boolean;
	/** Max data age in seconds, by default = NO. */
	chartMaxDataAge?: number;
}

export default function ChartCard(props: ChartCardProps) {
	const refChart = useRef(null);
	const tenantId = useSelector(selectAuth.currentTenantId);

	const [variable, setVariable] = useState<string | undefined>(
		props.variables && props.variables[0]?.name.en,
	);
	const [isChartRendered, setIsChartRendered] = useState(false);

	// chart filter -----------
	const chartFilter = useMemo(() => {
		const filter = props.filter;

		let chartFilter = filter;

		// BEWARE: Multi-tenant charts in mongoDB Atlas Charts => Always filter data by tenantId
		chartFilter = { $and: [chartFilter, { tenantId: { $oid: tenantId } }] };

		if (variable) {
			chartFilter = {
				$and: [chartFilter, { "variable.name": variable }],
			};
		}
		if (props.additionalFilter) {
			chartFilter = {
				$and: [chartFilter, props.additionalFilter],
			};
		}
		return chartFilter;
	}, [props.filter, variable, props.additionalFilter]);
	// end chart filter -----------

	const [chart] = useState(
		props.sdk.createChart({
			chartId: props.chartId,
			// Cf. Refresh and Data Caching Behavior at: https://www.mongodb.com/docs/charts/embedded-chart-options
			autoRefresh: !!props.chartAutoRefresh,
			// maxDataAge = 0 - MongoDB Charts doesn't read data from the cache (ONLY IF autoRefresh=false) => REAL-TIME.
			// maxDataAge > 0 AND Charts data age < maxDataAge - MongoDB Charts renders the chart or dashboard with data from the cache => NEAR REAL-TIME.
			// maxDataAge > 0 AND Charts data age > maxDataAge - MongoDB Charts queries the data source for the latest data, refreshes the cache, and renders the chart or dashboard using this data.
			maxDataAge: props.chartMaxDataAge ?? 0,
			showAttribution: false,
			filter: chartFilter,
			// Cf. Customization at: https://www.mongodb.com/docs/charts/embedded-charts-options/embedded-sdk
			renderingSpec: {
				version: 1,
				title: "",
			},
		}),
	);
	const exportJSON = async () =>
		await exportChartJSON(chart, props.chartName);
	const exportPNG = async () => await exportChartPNG(chart, props.chartName);
	const refresh = async () => await refreshChart(chart);

	useEffect(() => {
		const renderChart = async () => {
			await chart.render(refChart.current as unknown as HTMLElement);

			setIsChartRendered(true);
		};
		renderChart().catch((err) => {
			console.error("Error while rendering.", err);
		});
	}, [chart]);

	// rerender graph on filter change
	useEffect(() => {
		if (isChartRendered) {
			chart.setFilter(chartFilter || {});
		}
	}, [chart, chartFilter, isChartRendered]);

	return (
		<div
			className={concatClassName(
				"ChartCard-container",
				"flex-column",
				props.className ? props.className : "",
			)}
			style={{ width: props.width }}
		>
			<div className="ChartCard-header">
				{(props.title || props.description) && (
					<div>
						{props.title && (
							<h2 className="ChartCard-text">{props.title}</h2>
						)}
						{props.description && (
							<p className="ChartCard-text">
								{props.description}
							</p>
						)}
					</div>
				)}
				<div className="ChartCard-option-part">
					{props.variables && (
						<Combobox<string>
							value={variable ?? ""}
							label={DIC(EDIC_KEY.VARIABLES)}
							onChange={setVariable}
							comboboxValues={props.variables.map(
								(variable) => variable.name.en,
							)}
							translateMethod={(value) => value}
							className="ChartCard-combobox"
						/>
					)}
					<div className="ChartCard-option-buttons">
						<div className="ChartCard-option-labeled flex-column">
							<DorianeButton
								onClick={async () => await exportPNG()}
								dorianeStyle="quaternary"
							>
								<img src={DownloadPng} />
							</DorianeButton>
						</div>
						<div className="ChartCard-option-labeled flex-column">
							<DorianeButton
								onClick={async () => await exportJSON()}
								dorianeStyle="quaternary"
							>
								<img src={DownloadJson} />
							</DorianeButton>
						</div>
						<div>
							<DorianeButton
								onClick={async () => await refresh()}
								dorianeStyle="quaternary"
							>
								<RefreshIcon />
							</DorianeButton>
						</div>
					</div>
				</div>
			</div>
			<div className="ChartCard-chart-container take-remaining-space flex-column">
				<div
					className="ChartCard-chart take-remaining-space"
					ref={refChart}
				></div>
			</div>
		</div>
	);
}
