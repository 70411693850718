import { Skeleton } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { EExperimentStatus, IMETSerialized, IPatchMET } from "shared-type";

import ECWSaveModal from "../../../../../components/experiment-wizard/common/modals/ECWSaveModal";
import ECWGeneral from "../../../../../components/experiment-wizard/common/part/general/ECWGeneral";
import ECWGermplasm from "../../../../../components/experiment-wizard/common/part/germplasm/ECWGermplasm";
import ECWTemplateObs from "../../../../../components/experiment-wizard/common/part/template-observation/ECWTemplateObservation";
import ECWTrialGeneration from "../../../../../components/experiment-wizard/common/part/trial-generation/TrialGeneration";
import { isExpCompleted } from "../../../../../components/experiment-wizard/common/wizard-utils";
import {
	computeNewStatus,
	convertWizardToCreateMET,
	convertWizardToPatchMET,
} from "../../../../../components/experiment-wizard/met/met-save-utils";
import { DIC, EDIC_KEY } from "../../../../../dictionary";
import { useAppSelector } from "../../../../../hooks/reduxHook";
import useAppSnackbar from "../../../../../hooks/useAppSnackbar";
import { initializeMETWizard } from "../../../../../redux/ECW/ECW-met-intialize";
import { selectECW } from "../../../../../redux/ECW/ECW.selector";
import { ECWAction, ECWNextMode } from "../../../../../redux/ECW/ECW.slice";
import { apiMET } from "../../../../../redux/experiment/met/met.api";
import { ApiErrorSnackbar } from "../../../../../redux/utils/api-error-snackbar/ApiErrorSnackbar";
import {
	PATH_EXPERIMENTS,
	PATH_METS,
	PATH_MET_CREATION,
} from "../../../../../routes";
import store, { AppDispatch } from "../../../../../store";

import "./METCreationWizardPage.scss";

function useActionHook(metId?: string) {
	const nav = useNavigate();
	const {
		enqueueSnackbarSuccess,
		enqueueSnackbarError,
		enqueueSnackbarWarning,
	} = useAppSnackbar();
	const dispatch = useDispatch<AppDispatch>();

	const activeStep = useSelector(selectECW.activeStep);

	const { data: remoteMET } = apiMET.useGetMETByIdQuery(metId ?? skipToken);
	const [createMETMutation] = apiMET.useCreateMETMutation();
	const [patchMETMutation] = apiMET.usePatchMETByIdMutation();
	const [checkTrialsUnicity] = apiMET.useCheckTrialsUnicityMutation();
	const getWizardState = () => {
		// We need the last data, we cannot use useSelector here
		return store.getState().trialWizard;
	};

	const saveMethod = useCallback(
		async (
			metId: string | undefined,
			setToReady: boolean,
			remoteMET: IMETSerialized | undefined,
		) => {
			const wizardState = getWizardState();
			if (wizardState.trialGenerationPart.trials.length > 0) {
				let trialUnicityError;
				await checkTrialsUnicity({
					year: dayjs(
						wizardState.generalPart.generalInfo.startDate,
					).year(),
					names: wizardState.trialGenerationPart.trials.map(
						(elt) => elt.trialName,
					),
				})
					.unwrap()
					.catch((err) => {
						trialUnicityError = err;
					});
				if (trialUnicityError) {
					return Promise.reject(trialUnicityError);
				}
			}
			const isNewMET = metId === undefined;
			if (isNewMET) {
				const newMET = convertWizardToCreateMET(wizardState);
				return createMETMutation(newMET).unwrap();
			}

			const metPatchNoUpdateToReady = convertWizardToPatchMET(
				wizardState,
				remoteMET,
				false,
			);
			return patchMETMutation({ id: metId, met: metPatchNoUpdateToReady })
				.unwrap()
				.then(() => {
					if (setToReady) {
						const patchReady: IPatchMET = {
							status: computeNewStatus(
								wizardState,
								remoteMET,
								setToReady,
							),
						};
						return patchMETMutation({
							id: metId,
							met: patchReady,
						}).unwrap();
					}
				});
		},
		[
			checkTrialsUnicity,
			createMETMutation,
			enqueueSnackbarWarning,
			patchMETMutation,
		],
	);

	const nextAction = useCallback(
		(action: "save" | "doItLater", setToReady: boolean) => {
			saveMethod(metId, setToReady, remoteMET)
				.then((id) => {
					const isNewMET = metId === undefined;

					enqueueSnackbarSuccess(DIC(EDIC_KEY.SAVE));
					if (action === "save") {
						dispatch(ECWAction.completeStep(activeStep));
						if (isNewMET && id) {
							nav(`${PATH_MET_CREATION}/${id}`, {
								replace: true,
							});
						}
					} else {
						dispatch(ECWAction.uncompletedStep(activeStep));
					}
				})
				.catch((err) => {
					console.warn(err);
					enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
				});
		},
		[
			activeStep,
			dispatch,
			enqueueSnackbarError,
			enqueueSnackbarSuccess,
			metId,
			nav,
			remoteMET,
			saveMethod,
		],
	);

	const handleNextClick = useCallback(
		(action: ECWNextMode) => {
			const wizardState = getWizardState();

			if (isExpCompleted(wizardState)) {
				dispatch(ECWAction.setSaveModalInfos(action));
			} else {
				nextAction(action, false);
			}
		},
		[dispatch, nextAction],
	);

	return { handleNextClick, nextAction };
}

export default function METCreationWizardPage() {
	const { metId } = useParams();
	const nav = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { handleNextClick, nextAction } = useActionHook(metId);

	const isSaveModalOpen = useSelector(selectECW.isSaveModalOpen);
	const activeStep = useSelector(selectECW.activeStep);
	const isInit = useAppSelector(selectECW.isInit);

	const {
		data: remoteMET,
		isLoading,
		isError,
	} = apiMET.useGetMETByIdQuery(metId ?? skipToken);

	useEffect(() => {
		if (isInit) {
			return;
		}
		const isNewMET = !metId;
		if (isNewMET && !isInit) {
			dispatch(ECWAction.reset({ wizardType: "MET", isInit: true }));
		}
		if (metId) {
			dispatch(initializeMETWizard(metId));
		}
	}, [dispatch, isInit, metId]);

	// if met exist and is not in draft status, redirect to editions page
	// we use layout effect for redirect before the web render the page
	useLayoutEffect(() => {
		if (
			remoteMET &&
			remoteMET.status !== EExperimentStatus.DRAFT &&
			metId === remoteMET._id
		) {
			nav(`${PATH_EXPERIMENTS}${PATH_METS}/${metId}`);
		}
	}, [remoteMET, metId, nav]);

	useEffect(() => {
		return () => {
			dispatch(ECWAction.reset({ wizardType: "SET", isInit: false }));
		};
	}, [dispatch]);

	if (isError) {
		return <div>{DIC(EDIC_KEY.ECW_CANNOT_FOUND_MET)}</div>;
	}
	if (isLoading || !isInit) {
		return <Skeleton width="100%" height="100%" />;
	}
	return (
		<div className="METCreationWizardPage-container take-remaining-space">
			{activeStep === 0 && (
				<ECWGeneral onNext={() => handleNextClick("save")} />
			)}
			{activeStep === 1 && <ECWTemplateObs onNext={handleNextClick} />}
			{activeStep === 2 && <ECWGermplasm onNext={handleNextClick} />}
			{activeStep === 3 && (
				<ECWTrialGeneration onNext={handleNextClick} />
			)}
			{isSaveModalOpen && (
				<ECWSaveModal
					onClose={() => dispatch(ECWAction.resetSaveModalInfos())}
					nextAction={nextAction}
					isMET
				/>
			)}
		</div>
	);
}
