import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IUserState {
	userSelectedId?: string;
}
function createInitialState(): IUserState {
	return {};
}
const user = createSlice({
	name: "user",
	initialState: createInitialState(),
	reducers: {
		selectUser: (state, action: PayloadAction<string | undefined>) => {
			state.userSelectedId = action.payload;
		},
	},
});

export const userReducer = user.reducer;
export const userAction = {
	...user.actions,
};
