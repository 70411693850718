import { AxiosInstance } from "axios";
import { AuthHelper } from "../../auth/AuthContext";

export function initializeAuthInterceptor(axios: AxiosInstance) {
	// request interceptor to add token to request headers
	axios.interceptors.request.use(
		async (config) => {
			const token = await AuthHelper.getAccessToken().catch(
				() => undefined,
			);

			if (token) {
				config.headers = {
					authorization: `Bearer ${token}`,
				};
			}
			return {
				...config,
			};
		},
		(error) => Promise.reject(error),
	);

	// response interceptor intercepting 401 responses, refreshing token and retrying the request
	axios.interceptors.response.use(
		(response) => response,
		async (error) => {
			const config = error.config;

			if (error.response.status === 401 && !config._retry) {
				// we use this flag to avoid retrying indefinitely if
				// getting a refresh token fails for any reason
				config._retry = true;

				return axios(config);
			}

			return Promise.reject(error);
		},
	);
}
