import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
	ICreateLot,
	IFilter,
	ILot,
	ISort,
	IUpdateLot,
	PaginateResponse,
} from "shared-type";
import { AbstractRequest } from "../abstract-request";

export class LotRequest extends AbstractRequest {
	lotRoute = "/lot";

	createLot(createLot: ICreateLot): Promise<AxiosResponse<string, any>> {
		return this.axios.post<string>(this.hostConfig + this.lotRoute, createLot);
	}

	createManyLot(
		createLotDtoArray: ICreateLot[]
	): Promise<AxiosResponse<string[], any>> {
		return this.axios.post<string[]>(
			this.hostConfig + this.lotRoute + "/many",
			createLotDtoArray
		);
	}

	getLots(
		page?: number,
		pageSize?: number,
		filter?: IFilter,
		sort?: ISort,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<PaginateResponse<ILot>, any>> {
		return this.axios.get<PaginateResponse<ILot>>(
			this.hostConfig + this.lotRoute,
			{
				...config,
				params: {
					...config?.params,
					page: JSON.stringify(page),
					pageSize: JSON.stringify(pageSize),
					filter: JSON.stringify(filter),
					sort: JSON.stringify(sort),
				},
			}
		);
	}

	getLotById(
		id: string,
		config?: AxiosRequestConfig<unknown>
	): Promise<AxiosResponse<ILot, any>> {
		return this.axios.get<ILot>(
			this.hostConfig + this.lotRoute + "/" + id,
			config
		);
	}

	updateLotById(
		id: string,
		updateLot: IUpdateLot
	): Promise<AxiosResponse<void, any>> {
		return this.axios.put<void>(
			this.hostConfig + this.lotRoute + "/" + id,
			updateLot
		);
	}

	/*
	deleteManyLotByIds(ids: string[]): Promise<AxiosResponse<number, any>> {
		return this.axios.delete<number>(
			this.hostConfig + this.lotRoute + "/many",
			{ data: ids }
		);
	}
 */

	deleteLotById(id: string): Promise<AxiosResponse<void, any>> {
		return this.axios.delete<void>(this.hostConfig + this.lotRoute + "/" + id);
	}
}
