import { PlainObject } from "@mongodb-js/charts-embed-dom/dist/declarations/src/types";
import React, { useMemo, useState } from "react";

import {
	EChartAnalysisContext,
	EChartDashboardTeamManager,
	IDashboardTeamManagerFilterMQL,
} from "shared-type";

import DashboardTeamManagersHeader from "../../../../../components/dashboard/header/team/managers/DashboardTeamManagersHeader";
import { IDashboardTeamManagerFilter } from "../../../../../components/dashboard/header/team/types";
import GridChartRenderer from "../../../../../components/dashboard/utils/GridChartRenderer";

import "./DashboardTeamManagers.scss";

export default function DashboardTeamManagers() {
	const [filterGlobal, setFilterGlobal] = useState<
		IDashboardTeamManagerFilter | undefined
	>(undefined);
	const graphFilter = useMemo(() => {
		if (filterGlobal === undefined) {
			return undefined;
		}
		const filters: Partial<IDashboardTeamManagerFilterMQL>[] = [];
		if (filterGlobal.countries && filterGlobal.countries.length > 0) {
			filters.push({
				"growingArea.country": { $in: filterGlobal.countries },
			});
		}
		if (filterGlobal.regions && filterGlobal.regions.length > 0) {
			filters.push({
				"growingArea.region": { $in: filterGlobal.regions },
			});
		}
		if (filterGlobal.expYears && filterGlobal.expYears.length > 0) {
			filters.push({ "trial.year": { $in: filterGlobal.expYears } });
		}
		if (filterGlobal.metIds && filterGlobal.metIds.length > 0) {
			filters.push({
				"met._id": {
					$in: filterGlobal.metIds.map((elt) =>
						(elt === null ? null : { $oid: elt }),
					),
				},
			});
		}
		if (filterGlobal.users && filterGlobal.users.length > 0) {
			filters.push({ "notebook.user": { $in: filterGlobal.users } });
		}
		if (filterGlobal.species && filterGlobal.species.length > 0) {
			filters.push({ "trial.species": { $in: filterGlobal.species } });
		}
		if (filters.length === 0) {
			return {};
		}
		return { $and: filters } as PlainObject;
	}, [filterGlobal]);

	const isFilterReady = graphFilter !== undefined;
	return (
		<>
			<DashboardTeamManagersHeader
				filterGlobal={filterGlobal}
				submitNewFilter={setFilterGlobal}
			/>
			<div className="DashboardTeamManagers-body take-remaining-space">
				{isFilterReady && (
					<>
						<GridChartRenderer
							context={
								EChartAnalysisContext.DASHBOARD_TEAM_MANAGER
							}
							identifier={EChartDashboardTeamManager.MET_COUNT}
							graphFilter={graphFilter}
							gridRow={"span 2"}
							gridColumn={"span 1"}
						/>
						<GridChartRenderer
							context={
								EChartAnalysisContext.DASHBOARD_TEAM_MANAGER
							}
							identifier={EChartDashboardTeamManager.TRIAL_COUNT}
							graphFilter={graphFilter}
							gridRow={"span 2"}
							gridColumn={"span 1"}
						/>
						<GridChartRenderer
							context={
								EChartAnalysisContext.DASHBOARD_TEAM_MANAGER
							}
							identifier={EChartDashboardTeamManager.TRIAL_STATUS}
							graphFilter={graphFilter}
							gridRow={"span 2"}
							gridColumn={"span 2"}
						/>
						<GridChartRenderer
							context={
								EChartAnalysisContext.DASHBOARD_TEAM_MANAGER
							}
							identifier={EChartDashboardTeamManager.TRIAL_TABLE}
							graphFilter={graphFilter}
							gridRow={"span 3"}
							gridColumn={"span 4"}
						/>
						<GridChartRenderer
							context={
								EChartAnalysisContext.DASHBOARD_TEAM_MANAGER
							}
							identifier={
								EChartDashboardTeamManager.TRIAL_STATUS_GEO
							}
							graphFilter={graphFilter}
							gridRow={"span 4"}
							gridColumn={"span 4"}
						/>
						<GridChartRenderer
							context={
								EChartAnalysisContext.DASHBOARD_TEAM_MANAGER
							}
							identifier={
								EChartDashboardTeamManager.OPERATION_PROGRESS_TABLE
							}
							graphFilter={graphFilter}
							gridRow={"span 3"}
							gridColumn={"span 4"}
						/>
					</>
				)}
			</div>
		</>
	);
}
