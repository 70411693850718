import { RootState } from "App/store";

const trialDeleteIdSelected = (state: RootState) =>
	state.trial.deleteIdSelected;

const trialDuplicateSelected = (state: RootState) =>
	state.trial.duplicateSelected;

export const selectTrial = {
	trialDeleteIdSelected,
	trialDuplicateSelected,
};
