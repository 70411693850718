import { Chart } from "@mongodb-js/charts-embed-dom";
import { useMemo } from "react";

import {
	EExperimentStatus,
	ENotebookStatus,
	ETaskStatus,
	EVariableScope,
	IScopedVariable,
	IVariableCore,
} from "shared-type";

import { EExpGanttTaskStatus } from "./components/experiment/common/experiment-gantt/ExperimentGantt.interface";

import styles from "sassTheme";

// --------------- Variable Color part ---------------------------

export function getColorFromVariableScope(variableScope: EVariableScope) {
	switch (variableScope) {
		case EVariableScope.SYSTEM:
			return styles["system-var-color"];
		case EVariableScope.STANDARD:
			return styles["standard-var-color"];
		case EVariableScope.CUSTOM:
			return styles["custom-var-color"];
		default:
			return "#ff0000";
	}
}

// --------------- Trial status Color part ---------------------------

export function getColorFromTrialStatus(expStatus: EExperimentStatus) {
	switch (expStatus) {
		case EExperimentStatus.DRAFT:
			return styles["draft-color"];
		case EExperimentStatus.READY:
			return styles["ready-color"];
		case EExperimentStatus.VALIDATED:
			return styles["exp-validated-color"];
		case EExperimentStatus.NOT_VALIDATED:
			return styles["exp-not-validated-color"];
		case EExperimentStatus.COMPLETED:
			return styles["completed-color"];
		default:
			return "#ff0000";
	}
}

// --------------- Gantt status Color part ---------------------------

export function getColorFromGanttStatus(ganttStatus: EExpGanttTaskStatus) {
	switch (ganttStatus) {
		case EExpGanttTaskStatus.READY:
			return styles["ready-color"];
		case EExpGanttTaskStatus.IN_PROGRESS:
			return styles["in-progress-color"];
		case EExpGanttTaskStatus.COMPLETED:
			return styles["completed-color"];
		default:
			return "#ff0000";
	}
}

// --------------- Notebook status Color part ---------------------------

export function getColorFromNotebookStatus(bookStatus: ENotebookStatus) {
	switch (bookStatus) {
		case ENotebookStatus.READY_TO_OBSERVE:
			return styles["ready-color"];
		case ENotebookStatus.IN_PROGRESS:
			return styles["in-progress-color"];
		case ENotebookStatus.REVIEWED:
			return styles["notebook-reviewed-color"];
		case ENotebookStatus.COMPLETED:
			return styles["completed-color"];
		case ENotebookStatus.DISABLED:
			return styles["disabled-color"];
		default:
			return "#ff0000";
	}
}

// ---------------Task status Color part ---------------------------

export function getColorFromTaskStatus(bookStatus: ETaskStatus) {
	switch (bookStatus) {
		case ETaskStatus.DRAFT:
			return styles["draft-color"];
		case ETaskStatus.READY:
			return styles["ready-color"];
		case ETaskStatus.IN_PROGRESS:
			return styles["in-progress-color"];
		case ETaskStatus.COMPLETED:
			return styles["completed-color"];
		default:
			return "#ff0000";
	}
}

// --------------- Variables part ---------------------------

/**
 * This useMemo function will get the scoped variables found from the given list.
 * @param templateVarPool The list of scoped var you want to get the IVariables from.
 * @param allVariables The list of the IVariables from which you search the scopedVar.
 * @returns The IVariables found in the allVariables which are also in the templateVarPool.
 */
export function getVariablesFromScopedVars(
	templateVarPool: IScopedVariable[],
	allVariables: IVariableCore[],
): IVariableCore[] {
	return useMemo(() => {
		// Retrieve all obs template variables in ontology
		return templateVarPool
			.map((variable) => {
				const foundVariable = allVariables.find(
					(ontologyVar) =>
						ontologyVar._id === variable.variableId &&
						ontologyVar.scope === variable.scope,
				);
				return foundVariable;
			})
			.filter((elt) => elt !== undefined) as IVariableCore[];
	}, [allVariables, templateVarPool]);
}

// ------------------- CHARTS
export function downloadFile(jsonData: string, fileName: string) {
	const link = document.createElement("a");
	link.href = jsonData;
	link.download = fileName;

	link.click();
}

export function fileNamify(name: string) {
	return name.replace(/[^a-zA-Z0-9]/g, "_");
}

export async function exportChartJSON(chart: Chart, chartName: string) {
	const data = await chart
		.getData()
		.catch((err: any) => console.error("Error while getting data.", err));

	const jsonData = `data:text/json;chatset=utf-8,${encodeURIComponent(
		JSON.stringify(data),
	)}`;

	const fileName: string = fileNamify(`${chartName}_${Date.now()}`);
	downloadFile(jsonData, `${fileName}.json`);
}

export async function exportChartPNG(chart: Chart, chartName: string) {
	const data = await chart
		.getImage({
			encoding: "base64",
		})
		.catch((err: any) => console.error("Error while getting image.", err));

	const jsonData = `data:image/png;base64,${encodeURIComponent(
		data as string,
	)}`;

	const fileName: string = fileNamify(`${chartName}_${Date.now()}`);
	downloadFile(jsonData, `${fileName}.png`);
}

export async function refreshChart(chart: Chart) {
	await chart.refresh();
}
