import dayjs from "dayjs";

import { IObservationRound } from "shared-type";

import { IObservationRoundForm } from "./ObservationRoundForm";

export function obsRoundFormToObject(
	form: Partial<IObservationRoundForm>,
): Partial<IObservationRound> {
	return {
		name: form.name,
		identifier: form.identifier,
		label: form.label,
		description: form.description,
		stage: form.stage,
		startDate: form.startDate?.toDate(),
		endDate: form.endDate?.toDate(),
		eventOption: form.eventOption,
		status: form.status,
		team: form.team,
		variableGroupId: form.variableGroupId,
	};
}

export function obsRoundObjectToForm(
	observationRound: Partial<IObservationRound> | undefined,
): Partial<IObservationRoundForm> {
	return {
		name: observationRound?.name,
		identifier: observationRound?.identifier,
		label: observationRound?.label,
		description: observationRound?.description,
		stage: observationRound?.stage,
		startDate: observationRound?.startDate
			? dayjs(observationRound?.startDate)
			: undefined,
		endDate: observationRound?.endDate
			? dayjs(observationRound?.endDate)
			: undefined,
		eventOption: observationRound?.eventOption,
		status: observationRound?.status,
		team: observationRound?.team,
		variableGroupId: observationRound?.variableGroupId,
	};
}
