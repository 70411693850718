import React from "react";
import { UseFormReturn, useWatch } from "react-hook-form";

import {
	IScopedVariable,
	ITemplateObsCoreSerialized,
	IVariableCore,
} from "shared-type";

import { EFormInputType } from "App/components/base-components/dynamic-form";
import DynamicFormV2 from "App/components/base-components/dynamic-form/DynamicFormV2";
import { DIC, EDIC_KEY } from "App/dictionary";
import { FormMode } from "../../../const";
import TemplateObsVarGroupGrid from "../../templates/observation/edition/variable-grid/TemplateObsVarGroupGrid";

import "./VariableGroupForm.scss";

export interface IVarGroupForm {
	name: string;
	variables: IScopedVariable[];
}
export interface VariableGroupFormProps {
	mode: FormMode;
	form: UseFormReturn<IVarGroupForm>;
	templateObservation: ITemplateObsCoreSerialized;
	variablesInTemplate: IVariableCore[];
}

export default function VariableGroupForm(props: VariableGroupFormProps) {
	const form = props.form;
	const variables = useWatch({ name: "variables", control: form.control });

	const handleGroupVariableCheck = (
		variable: IVariableCore,
		check: boolean,
	) => {
		let newArray = [];
		const variables = form.getValues().variables;
		if (check) {
			newArray = [
				...variables,
				{
					variableId: variable._id,
					scope: variable.scope,
				},
			];
			form.setValue("variables", newArray);
		} else {
			// Delete
			newArray = variables.filter(
				(groupVariable) =>
					-!(groupVariable.scope === variable.scope) ||
					!(groupVariable.variableId === variable._id),
			);
		}
		form.setValue("variables", newArray);
	};

	return (
		<div className="VariableGroupForm-container flex-column take-remaining-space">
			{form && (
				<>
					<DynamicFormV2
						control={form.control}
						formInfos={[
							{
								inputType: EFormInputType.STRING,
								id: "name",
								name: "name",
								label: DIC(EDIC_KEY.NAME),
								required: true,
								disabled: props.mode === "used",
								takeAllRow: true,
							},
						]}
					/>
					<TemplateObsVarGroupGrid
						templateObservation={props.templateObservation}
						variableOntology={props.variablesInTemplate}
						variables={variables}
						onVariableCheck={handleGroupVariableCheck}
						checkDisabled={props.mode === "used"}
					/>
				</>
			)}
		</div>
	);
}
