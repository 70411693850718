import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
	ISideBarElement,
	SideBar,
} from "stories/base-components/Navigation/SideBar/SideBar";
import { DIC, EDIC_KEY } from "../../dictionary";
import { projectAction } from "../../redux/project/project.slice";
import {
	PATH_LANDING_PAGE,
	PATH_DASHBOARD,
	PATH_EXPERIMENTS,
	PATH_GENOTYPE,
	PATH_GERMPLASM,
	PATH_GROWING_AREA,
	PATH_PICTURE,
	PATH_PROJECTS,
	PATH_TEMPLATES,
	PATH_VARIABLES,
	PATH_USERS,
} from "../../routes";
import { AppDispatch } from "../../store";
import { useBloomeoAuth } from "../../auth/AuthContext";

import "./SideMenu.scss";

export default function SideMenu() {
	const dispatch = useDispatch<AppDispatch>();
	const location = useLocation();
	const { logout } = useBloomeoAuth();
	const nav = useNavigate();

	const navList = [
		{
			icon: "dashboard",
			selectedIcon: "dashboard--filled",
			label: DIC(EDIC_KEY.DASHBOARD),
			path: PATH_DASHBOARD,
		},
		{
			icon: "experiment",
			selectedIcon: "experiment--filled",
			label: DIC(EDIC_KEY.EXPERIMENT),
			path: PATH_EXPERIMENTS,
		},
		{
			icon: "variety-list",
			selectedIcon: "variety-list--filled",
			label: DIC(EDIC_KEY.VARIETY_LIST),
			path: PATH_GERMPLASM + PATH_GENOTYPE,
		},
		{
			icon: "growing-area",
			selectedIcon: "growing-area--filled",
			label: DIC(EDIC_KEY.GROWING_AREA),
			path: PATH_GROWING_AREA,
		},
		{
			icon: "picture-gallery",
			selectedIcon: "picture-gallery--filled",
			label: DIC(EDIC_KEY.PICTURE_GALLERY),
			path: PATH_PICTURE,
		},
	] as Array<ISideBarElement>;

	const navBottomList = [
		{
			icon: "configuration",
			selectedIcon: "configuration--filled",
			label: DIC(EDIC_KEY.CONFIGURATION),
			path: PATH_PROJECTS,
			multiplePathSelected: [
				PATH_PROJECTS,
				PATH_TEMPLATES,
				PATH_VARIABLES,
				PATH_USERS,
			],
		},
		{
			icon: "signout",
			selectedIcon: "signout",
			label: DIC(EDIC_KEY.DISCONNECT),
			action: logout,
		},
	] as Array<ISideBarElement>;

	return (
		<div>
			<SideBar
				navList={navList}
				navBottomList={navBottomList}
				onLogoClick={() => {
					nav(PATH_LANDING_PAGE);
					dispatch(projectAction.updateCurrentProject(undefined));
				}}
				location={location}
				nav={nav}
			/>
		</div>
	);
}
