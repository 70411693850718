import { TextField, TextFieldProps } from "@mui/material";
import * as React from "react";

import LabelledCard from "../card/labelled-card/LabelledCard";

import styles from "sassTheme";
import "./InputText.scss";

type IProps = Pick<
	TextFieldProps,
	| "style"
	| "label"
	| "type"
	| "onChange"
	| "InputProps"
	| "disabled"
	| "value"
	| "multiline"
	| "rows"
	| "required"
	| "name"
	| "id"
	| "error"
	| "helperText"
	| "minRows"
	| "onKeyPress"
	| "inputRef"
	| "onBlur"
>;
function DorianeMultiline(props: IProps) {
	if (!props.disabled) {
		return (
			<TextField
				{...props}
				className={"InputText"}
				style={props.style}
				type={props.type ? props.type : "text"}
				label={props.label}
				value={props.value}
				size="small"
				color="secondary"
				variant="outlined"
				onChange={props.onChange}
				disabled={props.disabled}
				multiline={props.multiline}
				rows={props.rows}
				required={props.required}
				name={props.name}
				id={props.id}
				error={props.error}
				helperText={props.helperText}
				minRows={props.minRows}
			/>
		);
	}
	if (props.label) {
		return (
			<LabelledCard
				style={props.style}
				label={`${props.label.toString()} ${props.required ? "*" : ""}`}
				value={props.value ? props.value.toString() : ""}
				isMultiline
			/>
		);
	}
	return <></>;
}

function DorianeTextField(props: IProps) {
	if (!props.disabled) {
		return (
			<TextField
				{...props}
				className={"InputText"}
				style={props.style}
				type={props.type ? props.type : "text"}
				label={props.label}
				value={props.value}
				size="small"
				color="secondary"
				variant="outlined"
				InputProps={{
					...props.InputProps,
					style: {
						height: styles["element-min-height"],
						padding: styles["padding-micro"] + "!important",
						lineHeight: "15px",
					},
				}}
				onChange={props.onChange}
				disabled={props.disabled}
				multiline={props.multiline}
				rows={props.rows}
				required={props.required}
				name={props.name}
				id={props.id}
				error={props.error}
				helperText={props.helperText}
			/>
		);
	}
	if (props.label) {
		return (
			<LabelledCard
				style={props.style}
				label={`${props.label.toString()} ${props.required ? "*" : ""}`}
				value={props.value ? props.value.toString() : ""}
			/>
		);
	}
	return <></>;
}
export default function InputText(props: IProps) {
	if (props.multiline) {
		return <DorianeMultiline {...props} />;
	}
	return <DorianeTextField {...props} />;
}
