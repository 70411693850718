import React from "react";

import styles from "../../../../../sassTheme";
import Box, { BoxProps } from "../box/Box";

export enum EGapSize {
	NONE,
	SMALL,
	MEDIUM,
	LARGE,
}
interface FlexBoxProps extends Omit<BoxProps, "display"> {
	gap?: BoxProps["gap"] | EGapSize;
}
function gapEnumToValue(gap: BoxProps["gap"] | EGapSize = EGapSize.NONE) {
	switch (gap) {
		case EGapSize.SMALL:
			return styles["padding-small"];
		case EGapSize.MEDIUM:
			return styles.padding;
		case EGapSize.LARGE:
			return styles["padding-large"];
		case EGapSize.NONE:
			return undefined;
		default:
			return gap;
	}
}

const FlexBox: React.FC<FlexBoxProps> = ({ children, gap, ...rest }) => {
	return (
		<Box display={"flex"} gap={gapEnumToValue(gap)} {...rest}>
			{children}
		</Box>
	);
};

export default FlexBox;
