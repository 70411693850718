import {
	ActionReducerMapBuilder,
	createAsyncThunk,
	PayloadAction,
} from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { IFilter, IGenotype, ILot, ISort, PaginateResponse } from "shared-type";

import { useRequest } from "App/request-provider";
import { IGermplasmState } from "./germplasm.slice";

const getGenotypesListThunk = createAsyncThunk(
	"germplasm/getGenotypesList",
	async (
		info: {
			page?: number;
			pageSize?: number;
			filter?: IFilter;
			sort?: ISort;
		},
		thunkAPI,
	) => {
		return await useRequest()
			.genotype.getGenotypes(
				info.page,
				info.pageSize,
				info.filter,
				info.sort,
			)
			.then((res) => res.data)
			.catch((err: AxiosError) => thunkAPI.rejectWithValue(err));
	},
);

export const getLotsListThunk = createAsyncThunk(
	"germplasm/getLotsList",
	async (
		info: {
			page?: number;
			pageSize?: number;
			filter?: IFilter;
			sort?: ISort;
		},
		thunkAPI,
	) => {
		return await useRequest()
			.lot.getLots(info.page, info.pageSize, info.filter, info.sort)
			.then((res) => res.data)
			.catch((err: AxiosError) => thunkAPI.rejectWithValue(err));
	},
);

export const germplasmFetchReducerBuilder = (
	builder: ActionReducerMapBuilder<IGermplasmState>,
) => {
	builder.addCase(
		getGenotypesListThunk.fulfilled,
		(state, action: PayloadAction<PaginateResponse<IGenotype>>) => {
			state.genotype = {
				paginationInfo: action.payload.metadata.pageInfo,
				genotypeList: action.payload.data,
			};
		},
	);
	builder.addCase(
		getLotsListThunk.fulfilled,
		(state, action: PayloadAction<PaginateResponse<ILot>>) => {
			state.lot = {
				paginationInfo: action.payload.metadata.pageInfo,
				lotList: action.payload.data,
			};
		},
	);
};

export const germplasmThunk = {
	getGenotypesListThunk,
	getLotsListThunk,
};
