import React from "react";

import { dateFormat } from "common";
import { GA_MARKET_SEGMENT_FILTER_KEY, IGrowingArea } from "shared-type";

import { DIC, EDIC_KEY } from "../../../../../dictionary";
import {
	IDorianeFilter,
	IDorianeObjectFilter,
} from "../../../../../interfaces/filters/doriane-filter-interface";
import { IGrowingAreaRow } from "../../../../experiment/met/edition/trials-edition/modal/interface";
import UserTag from "../../../tag/user-tag/UserTag";
import { CheckBoxColumn } from "../columns/CheckBoxColumn";
import { RadioButtonColumn } from "../columns/RadioButtonColumn";
import ChipListCell from "../doriane-data-grid/cell/ChipListCell";
import { IColumn } from "../doriane-data-grid/DorianeDataGrid";
import HeaderWithFilter from "../doriane-data-grid/header/HeaderWithFilter";

export const growingAreaColumns: (filter: {
	filter: IDorianeObjectFilter;
	onFilterChange: (newFilter: IDorianeFilter) => void;
}) => IColumn<IGrowingAreaRow>[] = (filter) => {
	const columns: IColumn<IGrowingAreaRow>[] = [
		{
			id: "identifier",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "identifier",
						type: "string",
						value: filter.filter?.identifier?.value,
					}}
					label={DIC(EDIC_KEY.ID)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (growingArea) => growingArea.identifier,
			width: "200px",
		},
		{
			id: "name",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "name",
						type: "string",
						value: filter.filter?.name?.value,
					}}
					label={DIC(EDIC_KEY.NAME)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (growingArea) => growingArea.name,
			width: "200px",
		},
		{
			id: "label",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "label",
						type: "string",
						value: filter.filter?.label?.value,
					}}
					label={DIC(EDIC_KEY.LABEL)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (growingArea) => growingArea.label || "",
			width: "200px",
		},
		{
			id: "description",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "description",
						type: "string",
						value: filter.filter?.description?.value,
					}}
					label={DIC(EDIC_KEY.DESCRIPTION)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (growingArea) => growingArea.description || "",
			width: "200px",
		},
		{
			id: "marketSegment",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: GA_MARKET_SEGMENT_FILTER_KEY,
						type: "string",
						value: filter.filter[GA_MARKET_SEGMENT_FILTER_KEY]
							?.value,
					}}
					label={DIC(EDIC_KEY.MARKET_SEGMENT)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (growingArea) => (
				<ChipListCell
					items={growingArea.marketSegments?.map((ms) => ({
						_id: ms._id,
						name: `${ms.name}, ${ms.species}`,
					}))}
				/>
			),
			width: "200px",
		},
		{
			id: "country",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "country",
						type: "string",
						value: filter.filter?.country?.value,
					}}
					label={DIC(EDIC_KEY.COUNTRY)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (growingArea) => growingArea.country || "",
			width: "200px",
		},
		{
			id: "region",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "region",
						type: "string",
						value: filter.filter?.region?.value,
					}}
					label={DIC(EDIC_KEY.REGION)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (growingArea) => growingArea.region || "",
			width: "200px",
		},
		{
			id: "season",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "season",
						type: "string",
						value: filter.filter?.season?.value,
					}}
					label={DIC(EDIC_KEY.SEASON)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (growingArea) =>
				growingArea.season?.toString() || "",
			width: "200px",
		},
		{
			id: "startDate",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "startDate",
						type: "string",
						value: filter.filter?.startDate?.value,
					}}
					label={DIC(EDIC_KEY.FROM)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (growingArea) =>
				dateFormat(growingArea.startDate) || "",
			width: "200px",
		},
		{
			id: "endDate",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "endDate",
						type: "string",
						value: filter.filter?.endDate?.value,
					}}
					label={DIC(EDIC_KEY.TO)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (growingArea) =>
				dateFormat(growingArea.endDate) || "",
			width: "200px",
		},
		{
			id: "year",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "year",
						type: "int",
						value: filter.filter?.year?.value,
					}}
					label={DIC(EDIC_KEY.YEAR)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (growingArea) => growingArea.year.toString(),
			width: "200px",
		},
		{
			id: "climate",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "climate",
						type: "string",
						value: filter.filter?.climate?.value,
					}}
					label={DIC(EDIC_KEY.CLIMATE)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (growingArea) => (
				<ChipListCell
					items={growingArea.climate?.map((climate: string) => {
						return { _id: climate, name: climate };
					})}
				/>
			),
			width: "200px",
		},
		{
			id: "soilType",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "soilType",
						type: "string",
						value: filter.filter?.soilType?.value,
					}}
					label={DIC(EDIC_KEY.SOIL_TYPE)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (growingArea) => (
				<ChipListCell
					items={growingArea.soilType?.map((soilType: string) => {
						return { _id: soilType, name: soilType };
					})}
				/>
			),
			width: "200px",
		},
		{
			id: "soilDetails",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "soilDetails",
						type: "string",
						value: filter.filter?.soilDetails?.value,
					}}
					label={DIC(EDIC_KEY.SOIL_DETAILS)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (growingArea) => growingArea.soilDetails || "",
			width: "200px",
		},
		{
			id: "usersArray",
			getHeaderRenderer: DIC(EDIC_KEY.USERS),
			getCellRenderer: (growingArea) => (
				<div className="flex-center-full-height DorianeDataGridCell-padding-horizontal ">
					{growingArea.usersArray?.map((user, index) => (
						<UserTag
							user={user}
							key={index}
							title=""
							fullNameOnHover
							initialsOnly
						/>
					))}
				</div>
			),
			width: "200px",
		},
		{
			id: "comments",
			getHeaderRenderer: (
				<HeaderWithFilter
					filter={{
						key: "comments",
						type: "string",
						value: filter.filter?.comments?.value,
					}}
					label={DIC(EDIC_KEY.COMMENTS)}
					onFilterChange={filter.onFilterChange}
				/>
			),
			getCellRenderer: (growingArea) => growingArea.comments || "",
			width: "200px",
		},
	];
	return columns;
};

export function growingAreaColumnsForSelection(
	filter: {
		filter: IDorianeObjectFilter;
		onFilterChange: (newFilter: IDorianeFilter) => void;
	},
	selected: {
		isCheck: (row: IGrowingArea) => boolean;
		onCheck: (row: IGrowingArea) => void;
	},
): IColumn<IGrowingArea>[] {
	const radioBoxColumn = RadioButtonColumn({
		isCheck: selected.isCheck,
		onCheck: selected.onCheck,
	});
	return [
		radioBoxColumn,
		...growingAreaColumns({
			filter: filter.filter,
			onFilterChange: filter.onFilterChange,
		}),
	];
}

export function growingAreaColumnsForMultiSelection(
	filter: {
		filter: IDorianeObjectFilter;
		onFilterChange: (newFilter: IDorianeFilter) => void;
	},
	selected: {
		isCheck: (row: IGrowingArea) => boolean;
		onCheck: (row: IGrowingArea, check: boolean) => void;
	},
): IColumn<IGrowingArea>[] {
	const radioBoxColumn = CheckBoxColumn({
		isCheck: selected.isCheck,
		onCheck: selected.onCheck,
	});
	return [
		radioBoxColumn,
		...growingAreaColumns({
			filter: filter.filter,
			onFilterChange: filter.onFilterChange,
		}),
	];
}
