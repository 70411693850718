import { createSlice } from "@reduxjs/toolkit";
import { projectReducers } from "./project.reducers";

export interface IProjectState {
	currentProject?: string;
}

function createInitialState(): IProjectState {
	return {};
}

const projectSlice = createSlice({
	name: "project",
	initialState: createInitialState(),
	reducers: projectReducers,
});

export const projectReducer = projectSlice.reducer;
export const projectAction = { ...projectSlice.actions };
