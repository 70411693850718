import { ETrialLevel } from "shared-type";
import { RootState } from "../../store";

const selectGridContext = (state: RootState) => state.experimentReviewNotation;

const selectComputedValue = (state: RootState) =>
	state.experimentReviewNotation.computedValue;

const selectExperimentLevelVariables =
	(level: ETrialLevel) => (state: RootState) =>
		state.experimentReviewNotation.variablesListByLevel[level];

export const selectExperimentReviewNotation = {
	selectGridContext,
	selectExperimentLevelVariables,
	selectComputedValue,
};
