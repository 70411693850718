import React from "react";
import { Autocomplete } from "@mui/material";

import { IInput, Input } from "stories/base-components/Input/Input";
import { Icon } from "stories/base-components/Icon/Icon";
import Colors from "stories/constants/Colors/Colors";
import { Chip } from "stories/base-components/Chip/Chip";
import { StoryTheme } from "stories/StoryTheme";

import "./dropdown.scss";

export type IDropdown = IInput & {
	options: Array<any>;
	multiple?: boolean;
	renderOption?: any;
	renderTags?: any;
	getOptionLabel?: any;
};

export const Dropdown = (dropdown: IDropdown) => {
	const { errorMessage, error, ...autoCompleteProps } = dropdown;
	return (
		<StoryTheme>
			<Autocomplete
				{...autoCompleteProps}
				className={
					(dropdown.disabled ? "dropdown_disabled" : "") +
					(dropdown.multiple ? " dropdown_multiple" : "")
				}
				disablePortal
				freeSolo={dropdown.multiple}
				clearIcon={<Icon name="delete-text" color={Colors.neutral60} />}
				renderInput={(params) => {
					return (
						<Input
							{...params}
							label={
								dropdown.multiple ? undefined : dropdown.label
							}
							placeholder={
								dropdown.multiple ? dropdown.label : undefined
							}
							error={error}
							errorMessage={errorMessage}
						/>
					);
				}}
				renderTags={
					dropdown.renderTags
						? dropdown.renderTags
						: (value: readonly string[], getTagProps) =>
								value.map((option: any, index: number) => (
									<div
										key={getTagProps({ index }).key}
										className="dropdown_chipContainer"
									>
										<Chip
											className={
												getTagProps({ index }).className
											}
											label={option.label}
											onClose={
												getTagProps({ index }).onDelete
											}
										/>
									</div>
								))
				}
				getOptionLabel={dropdown.getOptionLabel}
			/>
		</StoryTheme>
	);
};
