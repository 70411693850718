import { createApi } from "@reduxjs/toolkit/query/react";

import { IFilter, ISort, IChart, IAnalyticViewMetadata } from "shared-type";

import { useRequest } from "../../request-provider";
import { defaultApiError } from "../utils/errors";

export const apiChart = createApi({
	reducerPath: "api-chart",
	baseQuery: () => ({ data: undefined }),

	// global cache configuration for the api (in second)
	keepUnusedDataFor: 30,
	refetchOnMountOrArgChange: 30,

	// api definition
	endpoints: (build) => ({
		getCharts: build.query<IChart[], { filter?: IFilter; sort?: ISort }>({
			queryFn: async ({ filter, sort }) => {
				try {
					const result = await useRequest().chart.getCharts(
						filter,
						sort,
					);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(err, "Unable to fetch charts");
				}
			},
		}),

		getAnalyticViewMetadata: build.mutation<
			IAnalyticViewMetadata,
			void | undefined
		>({
			queryFn: async (_) => {
				try {
					const result =
						await useRequest().chart.getAnalyticViewMetadata();
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to fetch analytic view metadata",
					);
				}
			},
		}),

		updateAnalyticView: build.mutation<void | undefined, void | undefined>({
			queryFn: async (_) => {
				try {
					const result =
						await useRequest().chart.updateAnalyticView();
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to update analytic view",
					);
				}
			},
		}),
	}),
});

function useChart<T extends object | undefined = IFilter>(
	context: string,
	identifier: string,
) {
	// TODO fetch only required graph
	const chartFetch = apiChart.useGetChartsQuery({
		filter: { context, identifier },
	});

	return {
		chart: chartFetch.data?.[0] as IChart<T> | undefined,
		...chartFetch,
	};
}

export const apiChartCustomHook = {
	useChart,
};
