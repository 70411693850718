import React from "react";

import { ITemplateExpStructCore } from "shared-type";

import { TrialSerializedWithDeps } from "../list/TrialWithDeps.hook";
import MetTrialCard from "./met-trial/MetTrialCard";
import TrialCard from "./trial/TrialCard";

export interface ITrialGenericCardProps {
	trial: Partial<TrialSerializedWithDeps>;
	templateExpStructure?: ITemplateExpStructCore;
	onClick?: () => void;
	disabledClick?: boolean;
	borderColor?: string;
}

export default function TrialGenericCard(props: ITrialGenericCardProps) {
	return props.trial.metId ? (
		<MetTrialCard {...props} />
	) : (
		<TrialCard {...props} />
	);
}
