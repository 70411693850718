import { Box } from "@mui/system";
import { MarkerF, MarkerProps, OverlayViewF } from "@react-google-maps/api";
import React, { useState } from "react";

import { IGrowingArea } from "shared-type";

import { MapPinGA } from "../../../../assets";
import GrowingAreaCard from "../../growing-area/card/GrowingAreaCard";
import { GoogleMapPosition, MapHelper } from "../utils/map-helper";

import "./GrowingAreaMapMarker.scss";

type GrowingAreaMapMarkerRendererProps = {
	growingArea: IGrowingArea;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	onClick?: () => void;
	isPointerHide?: boolean;
};
export function GrowingAreaMapMarkerRenderer(
	props: GrowingAreaMapMarkerRendererProps,
) {
	return (
		<Box
			key={props.growingArea._id}
			sx={{
				translate: "-50% -100%",
				width: "fit-content",
				scale: "0.85",
			}}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
			onClick={props.onClick}
		>
			<GrowingAreaCard growingArea={props.growingArea} />
			<Box
				className={
					"GrowingAreaMapMarker-pointer" +
					(props.isPointerHide
						? " GrowingAreaMapMarker-pointer-hide"
						: "")
				}
			></Box>
		</Box>
	);
}

type GrowingAreaMapMarkerProps = {
	growingArea: IGrowingArea;
	position?: GoogleMapPosition;
	onClick?: (growingArea: IGrowingArea) => void;
	clusterer?: MarkerProps["clusterer"];
};
export function GrowingAreaMapMarker(props: GrowingAreaMapMarkerProps) {
	const [mouseHoverMarker, setMouseHoverMarker] = useState(false);

	const geoPoint = props.growingArea.location;
	const position = MapHelper.isValidGeoJSONPoint(geoPoint)
		? MapHelper.getGoogleMapPointFromGeoJSON(geoPoint)
		: undefined;

	if (!position) {
		if (process.env.NODE_ENV !== "production") {
			console.warn(
				"The growing area is not displayed because we cannot determine the position :",
				props.growingArea,
			);
		}

		// we wont display the marker
		return <></>;
	}
	return (
		<>
			<MarkerF
				position={position}
				clusterer={props.clusterer}
				onMouseOver={() => setMouseHoverMarker(true)}
				onMouseOut={() => setMouseHoverMarker(false)}
				zIndex={mouseHoverMarker ? 1000 : undefined}
				onClick={() => props.onClick?.(props.growingArea)}
				icon={{
					url: MapPinGA,
					scale: 5,
				}}
			></MarkerF>
			{mouseHoverMarker && (
				<OverlayViewF
					position={position}
					mapPaneName={"markerLayer"}
					zIndex={900}
				>
					<GrowingAreaMapMarkerRenderer
						key={props.growingArea._id}
						growingArea={props.growingArea}
						isPointerHide
					/>
				</OverlayViewF>
			)}
		</>
	);
}
