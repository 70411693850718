import dayjs from "dayjs";

import { IOperation } from "shared-type";

import { IOperationForm } from "./OperationForm";

export function operationFormToObject(
	form: Partial<IOperationForm>,
): Partial<IOperation> {
	return {
		name: form.name,
		identifier: form.identifier,
		description: form.description,
		label: form.label,
		status: form.status,
		startDate: form.startDate?.toDate(),
		endDate: form.endDate?.toDate(),
		stage: form.stage,
		team: form.team,
	};
}

export function operationObjectToForm(
	operation: Partial<IOperation>,
): Partial<IOperationForm> {
	return {
		name: operation.name,
		identifier: operation.identifier,
		label: operation.label,
		description: operation.description,
		startDate: operation.startDate ? dayjs(operation.startDate) : undefined,
		endDate: operation.endDate ? dayjs(operation.endDate) : undefined,
		stage: operation.stage,
		team: operation.team,
		status: operation.status,
	};
}
