import React, { useMemo } from "react";

import { DIC, EDIC_KEY } from "../../../../dictionary";
import BackButton from "../../button/BackButton";
import CommentButton from "../../button/CommentButton";
import DeleteButton from "../../button/DeleteButton";
import DorianeButton from "../../button/DorianeButton";
import SaveButton from "../../button/SaveButton";
import CustomTooltip, { ETooltipPosition } from "../../tooltip/CustomTooltip";

import "./TopMenu.scss";

interface ITopMenuButton {
	onClick?: () => void;
	isDisabled?: boolean;
	isLoading?: boolean;
	customDisplay?: React.ReactNode;
}

interface TopMenuProps {
	saveButton?: ITopMenuButton;

	deleteButton?: ITopMenuButton & {
		disabledText?: string;
		disabledTextPosition?: ETooltipPosition;
	};

	createButton?: ITopMenuButton;

	onBackClick?: () => void;
	onCommentClick?: () => void;

	additionalButton?: React.ReactNode;
	customClassName?: string;
	title?: string;
}

export default function TopMenu(props: TopMenuProps) {
	const deleteButtonSkin = useMemo(() => {
		const deleteProps = props.deleteButton;
		if (!deleteProps) return;

		if (deleteProps.customDisplay !== undefined) {
			return deleteProps.customDisplay;
		} else if (deleteProps.onClick) {
			return (
				<CustomTooltip
					position={deleteProps.disabledTextPosition}
					tooltipText={deleteProps.disabledText ?? ""}
					hidden={!deleteProps.isDisabled}
				>
					<DeleteButton
						icon="trash"
						onDelete={deleteProps.onClick}
						disabled={deleteProps.isDisabled}
					/>
				</CustomTooltip>
			);
		}
	}, [props.deleteButton]);

	const saveButtonSkin = useMemo(() => {
		const saveProps = props.saveButton;
		if (!saveProps) return;

		if (saveProps.customDisplay !== undefined) {
			return saveProps.customDisplay;
		} else if (saveProps.onClick) {
			return (
				<SaveButton
					onSave={saveProps.onClick}
					disabled={saveProps.isDisabled}
					isLoading={saveProps.isLoading}
				/>
			);
		}
	}, [props.saveButton]);

	const createButtonSkin = useMemo(() => {
		const createProps = props.createButton;
		if (!createProps) return;

		if (createProps.customDisplay !== undefined) {
			return createProps.customDisplay;
		} else if (createProps.onClick) {
			return (
				<DorianeButton
					dorianeStyle="primary"
					onClick={createProps.onClick}
					disabled={createProps.isDisabled}
					isLoading={createProps.isLoading}
				>
					{DIC(EDIC_KEY.CREATE)}
				</DorianeButton>
			);
		}
	}, [props.createButton]);

	return (
		<div
			className={
				"TopMenu-container" +
				(props.customClassName ? ` ${props.customClassName}` : "")
			}
		>
			<div className="TopMenu-left-side">
				{props.onBackClick !== undefined && (
					<BackButton onBack={props.onBackClick} />
				)}

				{props.title && (
					<h1 className="TopMenu-title clamped-text">
						{props.title}
					</h1>
				)}
			</div>
			<div className="TopMenu-right-side">
				{props.additionalButton !== undefined && props.additionalButton}
				{props.onCommentClick !== undefined && (
					<CommentButton onCommentClick={props.onCommentClick} />
				)}
				{props.deleteButton !== undefined && deleteButtonSkin}
				{props.createButton !== undefined && createButtonSkin}
				{props.saveButton !== undefined && saveButtonSkin}
			</div>
		</div>
	);
}
