import React from "react";

import { formatString } from "common";

import { DIC, EDIC_KEY } from "../../../../dictionary";
import { muiErrorButtonStyle } from "../../../../muiStyle";
import DorianeButton from "../../../base-components/button/DorianeButton";
import DorianeModal from "../../../base-components/modal/DorianeModal";

interface IECWSpeciesUpdateModalProps {
	onAcceptChange: () => void;
	onCancelChange: () => void;
}

export default function ECWSpeciesUpdateModal(
	props: IECWSpeciesUpdateModalProps,
) {
	return (
		<DorianeModal
			onClose={() => {
				props.onCancelChange();
			}}
			title={formatString(
				DIC(EDIC_KEY.ACTION_ENTITY),
				DIC(EDIC_KEY.UPDATE),
				DIC(EDIC_KEY.SPECIES),
			)}
			width={"500px"}
			closeOnOutsideClick={false}
		>
			<div className="take-remaining-space flex-column">
				<div className="DorianeModal-child-text-area flex-column">
					<span>
						{DIC(EDIC_KEY.TRIAL_SPECIES_UPDATE_TEXT)}
						<ul>
							<li className="text-error">
								{DIC(
									EDIC_KEY.TRIAL_SPECIES_UPDATE_TEXT_BULLET_1,
								)}
							</li>
							<li className="text-error">
								{DIC(
									EDIC_KEY.TRIAL_SPECIES_UPDATE_TEXT_BULLET_2,
								)}
							</li>
						</ul>
					</span>
					<span>
						{DIC(EDIC_KEY.TRIAL_SPECIES_UPDATE_TEXT_NO_IMPACTS)}
						<ul>
							<li className="text-warn">
								{DIC(
									EDIC_KEY.TRIAL_SPECIES_UPDATE_TEXT_BULLET_3,
								)}
							</li>
							<li className="text-warn">
								{DIC(
									EDIC_KEY.TRIAL_SPECIES_UPDATE_TEXT_BULLET_4,
								)}
							</li>
						</ul>
					</span>
				</div>
				<div className="DorianeModal-child-button-list">
					<DorianeButton
						onClick={() => {
							props.onCancelChange();
						}}
					>
						{DIC(EDIC_KEY.CANCEL)}
					</DorianeButton>
					<DorianeButton
						style={muiErrorButtonStyle}
						onClick={() => {
							props.onAcceptChange();
						}}
					>
						{DIC(EDIC_KEY.CONTINUE)}
					</DorianeButton>
				</div>
			</div>
		</DorianeModal>
	);
}
