import React from "react";
import { Chat } from "assets";
import DorianeButton from "./DorianeButton";

interface CommentButtonProps {
	onCommentClick: () => void;
}

export default function CommentButton(props: CommentButtonProps) {
	return (
		<DorianeButton
			onClick={() => props.onCommentClick()}
			dorianeStyle="quaternary"
		>
			<img src={Chat} />
		</DorianeButton>
	);
}
