import * as Yup from "yup";
import dayjs from "dayjs";

import { EEntitiesLength } from "shared-type";

import { dayjsYup, dayjsYupTestConstraint } from "../utils";
import { DIC, EDIC_KEY } from "App/dictionary";

export const trialDuplicateValidator = Yup.object().shape({
	name: Yup.string().required()
.max(EEntitiesLength.name),
	periodFrom: dayjsYup.required("Period from is required").test(
		dayjsYupTestConstraint({
			maxDate: new Date("2099-12-31T23:59:59"),
		}),
	),
	periodTo: dayjsYup
		.required("Periode to is required")
		.test(
			dayjsYupTestConstraint({
				maxDate: new Date("2099-12-31T23:59:59"),
			}),
		)
		.test({
			message: DIC(EDIC_KEY.DATE_GREATER_THAN_START_DATE_ERROR),
			test: (value, ctx) =>
				!value || !dayjs(value).isBefore(ctx.parent.periodFrom),
		}),
	material: Yup.boolean(),
	growingArea: Yup.boolean(),
	obsTemplate: Yup.boolean(),
	duplicateTasks: Yup.boolean(),
	tasks: Yup.string(),
});
