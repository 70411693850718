import React from "react";
import { useNavigate } from "react-router-dom";

import { formatString } from "common";

import { DIC, EDIC_KEY } from "App/dictionary";
import { PATH_EXPERIMENTS, PATH_METS } from "App/routes";
import ExperimentDeleteModal from "../../../../../components/experiment/common/modal/delete-modal/ExperimentDeleteModal";
import useAppSnackbar from "../../../../../hooks/useAppSnackbar";
import { ApiErrorSnackbar } from "../../../../../redux/utils/api-error-snackbar/ApiErrorSnackbar";
import { useAppSelector } from "App/hooks/reduxHook";
import store from "App/store";
import { apiMET } from "App/redux/experiment/met/met.api";
import { metAction } from "App/redux/experiment/met/met.slice";
import { selectMET } from "App/redux/experiment/met/met.selector";

export const METDeleteModal = () => {
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();
	const nav = useNavigate();

	const [deleteMETMutation] = apiMET.useDeleteMETByIdMutation();

	const selectedMETDeleteId = useAppSelector(selectMET.metDeleteIdSelected);

	const handleDelete = async (deleteTrials: boolean) => {
		if (selectedMETDeleteId) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			deleteMETMutation({
				metId: selectedMETDeleteId,
				deleteLinkedTrials: deleteTrials,
			})
				.unwrap()
				.then(() => {
					store.dispatch(metAction.selectDeleteMETId(undefined));
					enqueueSnackbarSuccess(
						formatString(
							DIC(EDIC_KEY.ENTITY_DELETED),
							DIC(EDIC_KEY.MET),
						),
					);
					nav(`${PATH_EXPERIMENTS}${PATH_METS}`);
				})
				.catch((err) => {
					enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
				});
		}
	};

	return (
		<ExperimentDeleteModal
			open={Boolean(selectedMETDeleteId)}
			isDraft={false}
			isMET={true}
			onClose={() =>
				store.dispatch(metAction.selectDeleteMETId(undefined))
			}
			deleteAction={handleDelete}
		/>
	);
};
