import React from "react";

import { Typography } from "stories/base-components/Typography/Typography";
import { StoryTheme } from "stories/StoryTheme";
import Box from "App/components/base-components/placement/box/Box";

import bloomeoNoData from "../../../assets/images/bloomeo_no_data.svg";

import "./emptyState.scss";

export type IEmptyState = {
	message?: string;
	noDataImage?: string;
	beforeActionMessage?: string;
	actionMessage?: string;
	actionOnClick?: () => void;
	afterActionMessage?: string;
};

export const EmptyState = (emptyState: IEmptyState) => {
	return (
		<StoryTheme>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				flexDirection="column"
			>
				<img
					src={
						emptyState.noDataImage
							? emptyState.noDataImage
							: bloomeoNoData
					}
				/>
				{emptyState.message && <Typography text={emptyState.message} />}
				{emptyState.actionOnClick && (
					<Box display="flex">
						<Typography text={emptyState.beforeActionMessage} />
						<Box
							display="flex"
							className="emptyState-action-container"
							onClick={emptyState.actionOnClick}
						>
							&nbsp;
							<Typography text={emptyState.actionMessage} />
						</Box>
					</Box>
				)}
				{emptyState.afterActionMessage && (
					<Typography text={emptyState.afterActionMessage} />
				)}
			</Box>
		</StoryTheme>
	);
};
