"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CREATE_VARIABLE_FIELDS = void 0;
exports.CREATE_VARIABLE_FIELDS = [
    "constraint",
    "description",
    "identifier",
    "inputType",
    "limitedChoices",
    "marketSegments",
    "multiNotation",
    "name",
    "shortName",
    "tags",
    "type",
    "unit",
];
