import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";

import { EntitySerializer } from "common";

import {
	ITemplateObsForm,
	templateObsFormToObject,
	templateObsObjectToForm,
	templateObsValidator,
} from "../../../../../../../components/form/template-observation";
import TemplateObsForm from "../../../../../../../components/form/template-observation/TemplateObsForm";
import { useTemplateObsEdition } from "../../../../../../../components/templates/observation/TemplateObsEditionPage.utils";
import { DIC, EDIC_KEY } from "../../../../../../../dictionary";
import useAppSnackbar from "../../../../../../../hooks/useAppSnackbar";

import "./TemplateObsEditionGeneralPage.scss";

export default function TemplateObsEditionGeneralPage() {
	const { enqueueSnackbarError } = useAppSnackbar();
	const form = useForm<ITemplateObsForm>({
		resolver: yupResolver(templateObsValidator as any),
	});
	const formValues = useWatch({
		control: form.control,
	});

	const { updateCurrentTemplateObs, setFormHasError, remoteTemplateObs } =
		useTemplateObsEdition();

	useEffect(() => {
		if (!remoteTemplateObs) {
			form.reset();
			return;
		}
		form.reset(
			templateObsObjectToForm(
				EntitySerializer.deserialize(remoteTemplateObs),
			),
		);
		form.trigger();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [remoteTemplateObs]);

	const handleSubmit = async () => {
		await form.handleSubmit(
			(values) => {
				updateCurrentTemplateObs(templateObsFormToObject(values));
			},
			(error) => {
				throw error;
			},
		)();
	};

	// Debounce to keep redux update with the form
	const autoSubmitForm = useDebouncedCallback(
		() => {
			handleSubmit()
				.then(() => {
					setFormHasError(false);
				})
				.catch((err) => {
					console.error(err);
					setFormHasError(true);
				});
		},
		800, // 0.8 second is enough between typing and clicking on save
		{ maxWait: 2000 },
	);
	useEffect(() => {
		autoSubmitForm();
	}, [autoSubmitForm, formValues]);

	// on unmount
	useEffect(() => {
		return () => {
			// only if form change
			if (form.formState.isDirty) {
				form.handleSubmit(
					(values) => {
						updateCurrentTemplateObs(
							templateObsFormToObject(values),
						);
					},
					() => enqueueSnackbarError(DIC(EDIC_KEY.BAD_INPUT)),
				)();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="TemplateObsEditionGeneralPage-container">
			<TemplateObsForm form={form} mode="unused" />
		</div>
	);
}
