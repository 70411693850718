/**
 * You can find the source code here
 * https://github.com/glideapps/glide-data-grid/blob/main/packages/cells/src/cells/dropdown-cell.tsx
 */
import styled from "@emotion/styled";
import {
	CustomCell,
	ProvideEditorCallback,
	CustomRenderer,
	getMiddleCenterBias,
	useTheme,
	GridCellKind,
} from "@glideapps/glide-data-grid";
import * as React from "react";
import Select, { MenuProps, components } from "react-select";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CustomMenuProps extends MenuProps<any> {}

const CustomMenu: React.FC<CustomMenuProps> = (p) => {
	const { Menu } = components;
	const { children, ...rest } = p;
	return <Menu {...rest}>{children}</Menu>;
};

interface DropdownCellProps {
	readonly kind: "dropdown-cell";
	readonly value: string;
	readonly allowedValues: readonly string[];
	readonly readonly?: boolean;
}

export type DropdownCellType = CustomCell<DropdownCellProps>;

const Wrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	.glide-select {
		font-family: var(--gdg-font-family);
		font-size: var(--gdg-editor-font-size);
	}
`;

const PortalWrap = styled.div`
	font-family: var(--gdg-font-family);
	font-size: var(--gdg-editor-font-size);
	color: var(--gdg-text-dark);
	> div {
		border-radius: 4px;
		border: 1px solid var(--gdg-border-color);
	}
`;

const Editor: ReturnType<ProvideEditorCallback<DropdownCellType>> = (p) => {
	const { value: cell, onFinishedEditing, initialValue } = p;
	const { allowedValues, value: valueIn } = cell.data;

	const [value, setValue] = React.useState(valueIn);
	const [inputValue, setInputValue] = React.useState(initialValue ?? "");

	const theme = useTheme();

	const values = React.useMemo(
		() =>
			allowedValues.map((x) => ({
				value: x,
				label: x,
			})),
		[allowedValues],
	);

	return (
		<Wrap>
			<Select
				className="glide-select"
				inputValue={inputValue}
				onInputChange={setInputValue}
				menuPlacement={"auto"}
				value={values.find((x) => x.value === value)}
				styles={{
					control: (base) => ({
						...base,
						border: 0,
						boxShadow: "none",
					}),
				}}
				theme={(t) => {
					return {
						...t,
						colors: {
							...t.colors,
							neutral0: theme.bgCell, // this is both the background color AND the fg color of
							// the selected item because of course it is.
							neutral5: theme.bgCell,
							neutral10: theme.bgCell,
							neutral20: theme.bgCellMedium,
							neutral30: theme.bgCellMedium,
							neutral40: theme.bgCellMedium,
							neutral50: theme.textLight,
							neutral60: theme.textMedium,
							neutral70: theme.textMedium,
							neutral80: theme.textDark,
							neutral90: theme.textDark,
							neutral100: theme.textDark,
							primary: theme.accentColor,
							primary75: theme.accentColor,
							primary50: theme.accentColor,
							primary25: theme.accentLight, // prelight color
						},
					};
				}}
				menuPortalTarget={document.getElementById("portal")}
				autoFocus={true}
				openMenuOnFocus={true}
				components={{
					DropdownIndicator: () => null,
					IndicatorSeparator: () => null,
					Menu: (props) => (
						<PortalWrap>
							<CustomMenu
								className={"click-outside-ignore"}
								{...props}
							/>
						</PortalWrap>
					),
				}}
				options={values}
				onChange={async (e) => {
					if (e === null) return;
					setValue(e.value);
					await new Promise((r) => window.requestAnimationFrame(r));
					onFinishedEditing({
						...cell,
						data: {
							...cell.data,
							value: e.value,
						},
					});
				}}
			/>
		</Wrap>
	);
};

export const DropdownCellRenderer: CustomRenderer<DropdownCellType> = {
	kind: GridCellKind.Custom,
	isMatch: (c): c is DropdownCellType =>
		(c.data as any).kind === "dropdown-cell",
	draw: (args, cell) => {
		const { ctx, theme, rect } = args;
		const { value } = cell.data;
		ctx.fillStyle = theme.textDark;
		ctx.fillText(
			value,
			rect.x + theme.cellHorizontalPadding,
			rect.y + rect.height / 2 + getMiddleCenterBias(ctx, theme),
		);

		return true;
	},
	provideEditor: () => ({
		editor: Editor,
		disablePadding: true,
		deletedValue: (v) => ({
			...v,
			copyData: "",
			data: {
				...v.data,
				value: "",
			},
		}),
	}),
	onPaste: (v, d) => ({
		...d,
		value: d.allowedValues.includes(v) ? v : d.value,
	}),
};

export default DropdownCellRenderer;
