/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { DIC, EDIC_KEY } from "../../../../dictionary";
import DorianeButton from "../../button/DorianeButton";
import SaveButton from "../../button/SaveButton";

import "./CreationWizardMenu.scss";

interface CreationWizardMenuProps {
	displaySave?: boolean;
	onSave?: () => void;
	onDoItLaterClick?: () => void;

	onNext?: () => void;
	onPrevious?: () => void;
}

export default function CreationWizardMenu(props: CreationWizardMenuProps) {
	const displaySaveOrDoItLater = () => {
		if (props.onSave !== undefined && props.displaySave) {
			return <SaveButton onSave={() => props.onSave!()} />;
		}
		if (props.onDoItLaterClick !== undefined) {
			return (
				<DorianeButton
					onClick={() => props.onDoItLaterClick!()}
					style={{
						width: "200px",
					}}
					dorianeStyle="secondary"
				>
					{DIC(EDIC_KEY.DO_IT_LATER)}
				</DorianeButton>
			);
		}
	};

	return (
		<div className="CreationWizardMenu-container">
			{displaySaveOrDoItLater()}
			{props.onPrevious !== undefined && (
				<DorianeButton
					style={{
						width: "200px",
						marginRight: "auto",
					}}
					dorianeStyle="secondary"
					onClick={() => props.onPrevious!()}
				>
					{DIC(EDIC_KEY.PREVIOUS)}
				</DorianeButton>
			)}
			{props.onNext !== undefined && (
				<DorianeButton
					style={{
						width: "200px",
					}}
					dorianeStyle="primary"
					onClick={() => props.onNext!()}
				>
					{DIC(EDIC_KEY.NEXT)}
				</DorianeButton>
			)}
		</div>
	);
}
