import React from "react";
import { useNavigate } from "react-router-dom";

import { formatString } from "common";

import { DIC, EDIC_KEY } from "App/dictionary";
import { PATH_EXPERIMENTS, PATH_TRIALS } from "App/routes";
import ExperimentDeleteModal from "../../../../../components/experiment/common/modal/delete-modal/ExperimentDeleteModal";
import useAppSnackbar from "../../../../../hooks/useAppSnackbar";
import { apiTrial } from "../../../../../redux/experiment/trial/trial.api";
import { ApiErrorSnackbar } from "../../../../../redux/utils/api-error-snackbar/ApiErrorSnackbar";
import { useAppSelector } from "App/hooks/reduxHook";
import { selectTrial } from "App/redux/experiment/trial/trial.selector";
import store from "App/store";
import { trialAction } from "App/redux/experiment/trial/trial.slice";

export const TrialDeleteModal = () => {
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();
	const nav = useNavigate();

	const [deleteTrialMutation] = apiTrial.useDeleteTrialByIdMutation();

	const selectedTrialDeleteId = useAppSelector(
		selectTrial.trialDeleteIdSelected,
	);

	const handleDelete = async () => {
		if (selectedTrialDeleteId) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			deleteTrialMutation(selectedTrialDeleteId)
				.unwrap()
				.then(() => {
					store.dispatch(trialAction.selectDeleteTrialId(undefined));
					enqueueSnackbarSuccess(
						formatString(
							DIC(EDIC_KEY.ENTITY_DELETED),
							DIC(EDIC_KEY.TRIAL),
						),
					);
					nav(`${PATH_EXPERIMENTS}${PATH_TRIALS}`);
				})
				.catch((err) => {
					enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
				});
		}
	};

	return (
		<ExperimentDeleteModal
			open={Boolean(selectedTrialDeleteId)}
			isDraft={false}
			isMET={false}
			onClose={() =>
				store.dispatch(trialAction.selectDeleteTrialId(undefined))
			}
			deleteAction={handleDelete}
		/>
	);
};
