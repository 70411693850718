import React, { CSSProperties } from "react";

import { getDicSpecies } from "App/dictionary/dictionary-key-getter";
import { Card } from "stories/base-components/Card/Card";
import { Badge } from "stories/base-components/Badge/Badge";
import { AvatarIcon } from "stories/base-components/AvatarIcon/AvatarIcon";
import Colors from "stories/constants/Colors/Colors";
import { Typography } from "stories/base-components/Typography/Typography";
import { DIC, EDIC_KEY } from "App/dictionary";
import { ITemplateObservationCardProps } from "./TemplateObservationCard";

import "./TemplateObservationBanner.scss";

export interface ITemplateObservationBannerProps
	extends ITemplateObservationCardProps {
	style?: CSSProperties;
	className?: string;
}

export default function TemplateObservationBanner(
	props: ITemplateObservationBannerProps,
) {
	return (
		<Card
			title={props.templateObservation.name.en}
			subObject={
				<div>
					<div className="templatObsBannerSpeciesTitleContainer">
						<Typography
							text={DIC(EDIC_KEY.SPECIES)}
							variant="smallRegular"
						/>
					</div>
					<Badge
						text={getDicSpecies(props.templateObservation.species)}
						backgroundColor={Colors.neutral10}
						color={Colors.primary60}
					/>
				</div>
			}
			headerIcon={
				<AvatarIcon
					iconName="obs-template"
					template
					color={Colors.primary60}
					backgroundColor={Colors.active10}
				/>
			}
			onClick={props.onClick}
			noBorder
			backgroundColor={Colors.primary10}
		/>
	);
}
