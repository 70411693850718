import React, { useMemo } from "react";

import { concatClassName } from "common";

import { Typography } from "stories/base-components/Typography/Typography";
import Colors from "stories/constants/Colors/Colors";
import { StoryTheme } from "stories/StoryTheme";
import Size from "stories/constants/Size/Size";
import { Icon } from "../Icon/Icon";
import { IIconType } from "../Icon/IconType";

import "./initials.scss";

export type IInitials = {
	backgroundColor?: string;
	firstName?: string;
	lastName?: string;
	upperIconName?: IIconType;
	upperIconBackgroundColor?: string;
	size?: "small" | "default" | "big" | "xxl";
};

const fontSizeObj = {
	xxl: Size.x2l,
	big: Size.md,
	default: Size.sm_small,
	small: Size.xs,
};

export const Initials = (initials: IInitials) => {
	const fontSize = useMemo(() => {
		return initials.size ? fontSizeObj[initials.size] : fontSizeObj.default;
	}, [initials.size]);

	return (
		<StoryTheme>
			<div
				className={concatClassName(
					"initials_container",
					initials.size === "small" ? "initials_container_small" : "",
					initials.size === "big" ? "initials_container_big" : "",
					initials.size === "xxl" ? "initials_container_xxl" : "",
				)}
				style={{
					// eslint-disable-next-line no-nested-ternary
					backgroundColor: initials.backgroundColor
						? initials.backgroundColor
						: Colors.primary60,
				}}
			>
				<div
					className={concatClassName(
						"initials_centered",
						initials.size === "small"
							? "initials_centered_small"
							: "",
					)}
				>
					<Typography
						text={
							(initials.firstName?.charAt(0).toUpperCase() ||
								"") +
							(initials.lastName?.charAt(0).toUpperCase() || "")
						}
						fontSize={fontSize}
						fontWeight={"500"}
						color={Colors.neutral10}
						letterSpacing="1px"
					/>
					{initials.upperIconName && (
						<div className="initialsIconContainer">
							<div
								className="initialsIcon"
								style={{
									backgroundColor:
										initials.upperIconBackgroundColor
											? initials.upperIconBackgroundColor
											: Colors.neutral90,
								}}
							>
								<Icon
									name={initials.upperIconName}
									color={Colors.neutral10}
									fontSize={Size.base}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</StoryTheme>
	);
};
