import { default as React, useEffect, useMemo, useRef, useState } from "react";
import { useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { ESpecies } from "shared-type";

import { AppDispatch } from "App/store";
import { selectECW } from "../../../../../redux/ECW/ECW.selector";
import { ECWAction } from "../../../../../redux/ECW/ECW.slice";
import { apiMarketSegment } from "../../../../../redux/market-segment/market-segment.api";
import CreationWizardMenu from "../../../../base-components/menu/creation-wizard/CreationWizardMenu";
import {
	ExpGeneralForm,
	expGeneralFormToStateObject,
	useExpGeneralForm,
} from "../../../../form/experiment/general";
import SelectMarketSegmentModal from "../../../../market-segment/select-segment/SelectMarketSegmentModal";
import CommonECWPart from "../../CommonECWPart";
import ECWSpeciesUpdateModal from "../../modals/ECWSpeciesUpdateModal";
import { IExpGeneralInfoState } from "../../types";
import useAppSnackbar from "App/hooks/useAppSnackbar";
import { DIC, EDIC_KEY } from "App/dictionary";

import "../../ECW.scss";
import "./ECWGeneral.scss";

interface IECWGeneralProps {
	onNext: () => void;
}

export default function ECWGeneral(props: IECWGeneralProps) {
	const dispatch = useDispatch<AppDispatch>();
	const { enqueueSnackbarError } = useAppSnackbar();

	const [isSelectMkSegOpen, setIsSelectMkSegOpen] = useState(false);
	const [needToRecomputeSteps, setNeedToRecomputeSteps] = useState(false);
	const [isEditSpeciesPopupOpen, setIsEditSpeciesPopupOpen] = useState(false);

	const generalFormInfo = useSelector(selectECW.generalFormInfo);
	const currentStep = useSelector(selectECW.activeStepValue);
	const wizardType = useSelector(selectECW.wizardType);

	const { form } = useExpGeneralForm({
		checkErrorOnInit: currentStep.isError,
		defaultValue: generalFormInfo,
	});
	const lastSpecies = useRef<ESpecies | undefined>(generalFormInfo.species);
	const [name, startDate, species, marketSegmentIds] = useWatch({
		control: form.control,
		name: ["name", "startDate", "species", "marketSegmentIds"],
	});

	// check can go to next step
	const isNextStepDisplayed = useMemo(
		() => name && startDate && species,
		[name, startDate, species],
	);

	const onCancelChangeSpecies = () => {
		if (lastSpecies.current) {
			form.setValue("species", lastSpecies.current, {
				shouldDirty: true,
			});
		}
		setIsEditSpeciesPopupOpen(false);
	};

	const onAcceptChangeSpecies = () => {
		setIsEditSpeciesPopupOpen(false);
		setNeedToRecomputeSteps(true);
		lastSpecies.current = species;

		// reset market segments & Observation template
		dispatch(ECWAction.resetSpeciesRelatedFields());
		form.setValue("marketSegmentIds", []);

		// Reset the completion of steps
		dispatch(ECWAction.uncompletedStep(1));
		// should always be last to stay at the step 0.
		dispatch(ECWAction.uncompletedStep(0));
	};

	useEffect(() => {
		if (lastSpecies.current === undefined) {
			lastSpecies.current = species;
			form.setValue("marketSegmentIds", []);
			return;
		}
		if (species !== lastSpecies.current) {
			setIsEditSpeciesPopupOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [species]);

	const { data: currentMarketSegments } =
		apiMarketSegment.useGetMarketSegmentByIdsQuery(marketSegmentIds ?? []);

	const storeForm = (
		values: Partial<IExpGeneralInfoState>,
		isError: boolean,
	) => {
		return dispatch(
			ECWAction.updateExpGeneralInfo({
				expGeneralInfo: values,
				isError: isError,
			}),
		);
	};

	const handleNextStepClick = async () => {
		form.handleSubmit(
			(values) => {
				storeForm(expGeneralFormToStateObject(values), false);
				props.onNext();
			},
			(error) => {
				console.warn(error);
				enqueueSnackbarError(DIC(EDIC_KEY.BAD_INPUT));
			},
		)();
		if (needToRecomputeSteps) {
			dispatch(ECWAction.computeStepState());
			setNeedToRecomputeSteps(false);
		}
	};

	// on unmount
	useEffect(() => {
		return () => {
			// only if form change
			if (form.formState.isDirty) {
				form.handleSubmit(
					(values) => {
						storeForm(expGeneralFormToStateObject(values), false);
					},
					(error) => {
						console.warn(error);
						storeForm(
							expGeneralFormToStateObject(form.getValues()),
							true,
						);
					},
				)();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<CommonECWPart>
				<div className="ECW-body take-remaining-space">
					<div className="ECWGeneral-form">
						<ExpGeneralForm
							type={wizardType}
							mode={"creation"}
							form={form}
							onSelectSegmentClick={() =>
								setIsSelectMkSegOpen(true)
							}
							marketSegments={currentMarketSegments}
						/>
					</div>
				</div>
				{isNextStepDisplayed && (
					<CreationWizardMenu onNext={handleNextStepClick} />
				)}
			</CommonECWPart>
			{isSelectMkSegOpen && (
				<SelectMarketSegmentModal
					initialSegmentIds={[]}
					currentSegmentIds={marketSegmentIds ?? []}
					onMarketSegmentCheck={(clickedSegment, checked) => {
						if (checked) {
							const newValue = [
								...(marketSegmentIds ?? []),
								clickedSegment._id,
							];

							form.setValue("marketSegmentIds", newValue);
						} else {
							const newMkSegList = marketSegmentIds?.filter(
								(marketSegmentId) =>
									marketSegmentId !== clickedSegment._id,
							);
							form.setValue("marketSegmentIds", newMkSegList);
						}
					}}
					onClose={() => setIsSelectMkSegOpen(false)}
					speciesFilter={species}
				/>
			)}
			{isEditSpeciesPopupOpen && (
				<ECWSpeciesUpdateModal
					onCancelChange={onCancelChangeSpecies}
					onAcceptChange={onAcceptChangeSpecies}
				/>
			)}
		</>
	);
}
