import React, { ReactNode } from "react";

import "./LoginCarouselItem.scss";

export interface LoginCarouselItemProps {
	iconClass?: string;
	title: string;
	text: ReactNode;
}
export default function LoginCarouselItem({
	iconClass,
	title,
	text,
}: LoginCarouselItemProps) {
	return (
		<div className="LoginCarouselItem-container flex-column">
			<div className="LoginCarouselItem-container">
				{iconClass && (
					<div className={"LoginCarouselItem-img " + iconClass}></div>
				)}
				<h2 className="LoginCarouselItem-title">{title}</h2>
				<p>{text}</p>
			</div>
		</div>
	);
}
