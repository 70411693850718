import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { DIC, EDIC_KEY } from "../../../../dictionary";
import { PATH_ONTOLOGY, PATH_OTHERS } from "../../../../routes";
import { Tabs } from "stories/base-components/Navigation/Tabs/Tabs";

import "./VariablesPage.scss";

export default function VariablesPage() {
	const location = useLocation();
	const nav = useNavigate();

	useEffect(() => {
		const redirectToOntology =
			location.pathname
				.split("/")
				.filter((pathPart: string) => Boolean(pathPart)).length === 1;

		if (redirectToOntology) {
			nav(`.${PATH_ONTOLOGY}`, { replace: true });
		}
	}, [location.pathname, nav]);

	return (
		<div className="VariablesPage-container flex-column full-parent-size">
			<div className="VariablesPage-container-tabs">
				<Tabs
					tabs={[
						{
							tabLabel: DIC(EDIC_KEY.VARIABLE_ONTOLOGY),
							path: `.${PATH_ONTOLOGY}`,
						},
						{
							tabLabel: DIC(EDIC_KEY.VARIABLE_OTHERS),
							path: `.${PATH_OTHERS}`,
						},
					]}
					validatePathLevel={2}
				/>
			</div>
			<div className="VariablesPage-tab-container take-remaining-space">
				<Outlet />
			</div>
		</div>
	);
}
