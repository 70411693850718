import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { formatString } from "common";
import { EExperimentStatus } from "shared-type";

import { trialWizardFetchAction } from "App/redux/ECW/trial-wizard-fetch-reducer";
import { selectECW } from "App/redux/ECW/ECW.selector";
import { ECWNextMode, ECWAction } from "App/redux/ECW/ECW.slice";
import {
	PATH_EXPERIMENTS,
	PATH_TRIALS,
	PATH_TRIALS_CREATION,
} from "App/routes";
import { AppDispatch, RootState } from "App/store";
import ECWGeneral from "../../../../../components/experiment-wizard/common/part/general/ECWGeneral";
import ECWGermplasm from "../../../../../components/experiment-wizard/common/part/germplasm/ECWGermplasm";
import ECWGrowingArea from "../../../../../components/experiment-wizard/common/part/growing-area/ECWGrowingArea";
import ECWTemplateObs from "../../../../../components/experiment-wizard/common/part/template-observation/ECWTemplateObservation";
import { DIC, EDIC_KEY } from "../../../../../dictionary";
import useAppSnackbar from "../../../../../hooks/useAppSnackbar";
import { trialWizardSaveAction } from "../../../../../redux/ECW/trial-wizard.save";
import ECWSaveModal from "../../../../../components/experiment-wizard/common/modals/ECWSaveModal";
import { isExpCompleted } from "../../../../../components/experiment-wizard/common/wizard-utils";

import "./TrialCreationWizardPage.scss";

export default function TrialCreationWizardPage() {
	const dispatch = useDispatch<AppDispatch>();
	const nav = useNavigate();
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();

	const { trialId } = useParams();
	const trialRemote = useSelector(selectECW.trialRemote);

	const activeStep = useSelector(selectECW.activeStep);
	const isSaveModalOpen = useSelector(selectECW.isSaveModalOpen);
	const wizardState = useSelector((state: RootState) => state.trialWizard);

	const nextAction = (
		action: "save" | "doItLater",
		setTrialToReady: boolean,
	) => {
		dispatch(trialWizardSaveAction.saveTrial(setTrialToReady))
			.unwrap()
			.then((trial) => {
				const isFirstSave = !trialId;

				enqueueSnackbarSuccess(DIC(EDIC_KEY.SAVE));
				if (action === "save") {
					dispatch(ECWAction.completeStep(activeStep));
					if (isFirstSave) {
						nav(`${PATH_TRIALS_CREATION}/${trial?._id}`);
					}
				} else {
					dispatch(ECWAction.uncompletedStep(activeStep));
				}
			})
			.catch((err) => {
				console.warn(err);
				enqueueSnackbarError(
					formatString(
						DIC(EDIC_KEY.CANT_ACTION_ENTITY),
						DIC(EDIC_KEY.SAVE).toLowerCase(),
						DIC(EDIC_KEY.TRIAL).toLowerCase(),
					),
				);
			});
	};

	const handleNextClick = (action: ECWNextMode) => {
		if (isExpCompleted(wizardState)) {
			dispatch(ECWAction.setSaveModalInfos(action));
		} else {
			nextAction(action, false);
		}
	};

	useEffect(() => {
		if (trialId && trialId !== "new") {
			dispatch(trialWizardFetchAction.fetchTrialAndDependencies(trialId));
		}
		return () => {
			dispatch(ECWAction.reset({ wizardType: "SET", isInit: false }));
		};
	}, [dispatch, trialId]);

	useEffect(() => {
		if (
			trialRemote &&
			trialRemote.status !== EExperimentStatus.DRAFT &&
			trialId === trialRemote._id
		) {
			nav(`${PATH_EXPERIMENTS}${PATH_TRIALS}/${trialId}`);
		}
	}, [trialRemote, trialId, nav]);

	return (
		<div className="TrialCreationWizardPage-container take-remaining-space">
			{activeStep === 0 && (
				<ECWGeneral onNext={() => handleNextClick("save")} />
			)}
			{activeStep === 1 && <ECWTemplateObs onNext={handleNextClick} />}
			{activeStep === 2 && <ECWGermplasm onNext={handleNextClick} />}
			{activeStep === 3 && <ECWGrowingArea onNext={handleNextClick} />}
			{isSaveModalOpen && (
				<ECWSaveModal
					onClose={() => dispatch(ECWAction.resetSaveModalInfos())}
					nextAction={nextAction}
				/>
			)}
		</div>
	);
}
