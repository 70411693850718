import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
	EExperimentStatus,
	EGermplasmLevel,
	IFilter,
	IGenotype,
	ILot,
} from "shared-type";

import { genotypeColumnsForTrialMaterial } from "App/components/base-components/data-grid/doriane-data-grid/default-grid-columns/GenotypeColumns";
import { lotColumnsForTrialMaterial } from "App/components/base-components/data-grid/doriane-data-grid/default-grid-columns/LotColumns";
import { DIC, EDIC_KEY } from "App/dictionary";
import { selectECW } from "App/redux/ECW/ECW.selector";
import { ECWAction } from "App/redux/ECW/ECW.slice";
import { selectGermplasm } from "App/redux/germplasm/germplasm.selector";
import { germplasmAction } from "App/redux/germplasm/germplasm.slice";
import { AppDispatch } from "App/store";
import { LOT_DEFAULT_SORT } from "../../../../../../const";
import { apiMET } from "../../../../../../redux/experiment/met/met.api";
import DorianeDrawer from "../../../../../base-components/modal/DorianeDrawer";
import { GenericAddMaterial } from "./GenericAddMaterial";

import "./ExperimentAddMaterial.scss";

function useFetchHook(metId?: string) {
	const remoteTrial = useSelector(selectECW.trialRemote);

	const { data: remoteMET } = apiMET.useGetMETByIdQuery(
		metId ?? remoteTrial?.metId ?? skipToken, // Get MET if MET or get MET of trial or skip
	);

	const isMETEdition = useMemo(() => {
		return (
			remoteMET !== undefined &&
			remoteTrial === undefined &&
			remoteMET.status !== EExperimentStatus.DRAFT
		);
	}, [remoteMET, remoteTrial]);

	const trialHasMet = useMemo(
		() => remoteTrial?.metId !== undefined,
		[remoteTrial?.metId],
	);

	const metMaterialsSet = useMemo(() => {
		if (!remoteMET) return new Set<string>();
		return new Set(
			remoteMET.materialLevel?.materials.map(
				(material) => material.material._id,
			),
		);
	}, [remoteMET]);

	return {
		isMETEdition,
		trialHasMet,
		metMaterialsSet,
	};
}

export function ExperimentAddMaterial() {
	const { metId } = useParams();
	const dispatch = useDispatch<AppDispatch>();

	const materialType = useSelector(selectECW.germplasmLevel);
	const selectedMaterials = useSelector(selectECW.selectedMaterials);

	const { isMETEdition, metMaterialsSet, trialHasMet } = useFetchHook(metId);

	const initialMaterialsSet = useMemo(() => {
		if (isMETEdition) return metMaterialsSet;
		return new Set<string>();
	}, [metMaterialsSet, isMETEdition]);

	// Material that are in the trial MET's
	const metMaterialsFilter: IFilter | undefined = useMemo(() => {
		if (!isMETEdition && trialHasMet) {
			// We already compute the material ids
			return { _id: { $in: Array.from(metMaterialsSet) } };
		}
	}, [isMETEdition, trialHasMet, metMaterialsSet]);

	const gridComponent = useMemo(() => {
		const selectedSet = new Set(selectedMaterials);
		if (materialType === EGermplasmLevel.GENOTYPE) {
			return (
				<GenericAddMaterial<IGenotype>
					initialMaterials={initialMaterialsSet}
					selected={selectedSet}
					fetchData={germplasmAction.getGenotypesListThunk}
					getColumns={genotypeColumnsForTrialMaterial}
					materialSelector={selectGermplasm.genotypeList}
					pageInfoSelector={selectGermplasm.genotypePageInfo}
					germplasmLevel={EGermplasmLevel.GENOTYPE}
					defaultSort={{ name: 1 }}
					additionalFilter={metMaterialsFilter}
				/>
			);
		}
		return (
			<GenericAddMaterial<ILot>
				initialMaterials={initialMaterialsSet}
				selected={selectedSet}
				fetchData={germplasmAction.getLotsListThunk}
				getColumns={lotColumnsForTrialMaterial}
				materialSelector={selectGermplasm.lotList}
				pageInfoSelector={selectGermplasm.lotPageInfo}
				germplasmLevel={EGermplasmLevel.LOT}
				defaultSort={LOT_DEFAULT_SORT}
				additionalFilter={metMaterialsFilter}
			/>
		);
	}, [
		selectedMaterials,
		materialType,
		initialMaterialsSet,
		metMaterialsFilter,
	]);

	const title = useMemo(() => {
		if (materialType === EGermplasmLevel.GENOTYPE) {
			return `${DIC(EDIC_KEY.GENOTYPES)} / ${DIC(EDIC_KEY.VARIETIES)}`;
		}
		return DIC(EDIC_KEY.LOTS);
	}, [materialType]);

	return (
		<DorianeDrawer
			takeRemainingSpace
			onClose={() => dispatch(ECWAction.setAddMaterialOpen(false))}
			title={title}
		>
			{gridComponent}
		</DorianeDrawer>
	);
}
