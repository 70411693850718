import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { formatString, templateExpStructsMock } from "common";
import { EExperimentStatus } from "shared-type";

import DorianeButton from "App/components/base-components/button/DorianeButton";
import TopMenu from "App/components/base-components/menu/top-menu/TopMenu";
import CommentDrawer from "App/components/base-components/modal/comment-drawer/CommentDrawer";
import { CustomLinkTab } from "App/components/base-components/tabs/CustomLinkTab";
import { CustomTabs } from "App/components/base-components/tabs/CustomTabs";
import { DIC, EDIC_KEY } from "App/dictionary";
import { selectECW } from "App/redux/ECW/ECW.selector";
import { ECWAction } from "App/redux/ECW/ECW.slice";
import {
	PATH_DATA,
	PATH_EXPERIMENTS,
	PATH_GENERAL,
	PATH_GERMPLASM,
	PATH_GROWING_AREA,
	PATH_METS,
	PATH_MET_CREATION,
	PATH_OBSERVATION_VARIABLES,
	PATH_PLANNING,
	PATH_SYNTHESIS,
	PATH_TRIALS,
} from "App/routes";
import store, { AppDispatch } from "App/store";
import { Validate } from "assets";
import styles from "../../../../../../sassTheme";
import DorianeDrawer from "../../../../../components/base-components/modal/DorianeDrawer";
import Box from "../../../../../components/base-components/placement/box/Box";
import FlexBox, {
	EGapSize,
} from "../../../../../components/base-components/placement/flex-box/FlexBox";
import { convertWizardToPatchMET } from "../../../../../components/experiment-wizard/met/met-save-utils";
import ExperimentConclusionModal from "../../../../../components/experiment/common/modal/conclusion/ExperimentConclusionModal";
import ExperimentDeleteModal from "../../../../../components/experiment/common/modal/delete-modal/ExperimentDeleteModal";
import { ExperimentAddMaterial } from "../../../../../components/experiment/common/modal/germplasm/add-material/ExperimentAddMaterial";
import METBanner from "../../../../../components/experiment/met/card/METBanner";
import METTrialsGrowingAreaModal from "../../../../../components/experiment/met/edition/trials-edition/modal/METTrialsGrowingAreaModal";
import CreationObsRoundModal from "../../../../../components/task/observation-round/modal/CreationObsRoundModal";
import OperationModal from "../../../../../components/task/operation/modal/OperationModal";
import { useAppSelector } from "../../../../../hooks/reduxHook";
import useAppSnackbar from "../../../../../hooks/useAppSnackbar";
import { initializeMETWizard } from "../../../../../redux/ECW/ECW-met-intialize";
import { selectMETTrialManagement } from "../../../../../redux/experiment/met/met-trial-management.select";
import { apiMET } from "../../../../../redux/experiment/met/met.api";
import { apiTrial } from "../../../../../redux/experiment/trial/trial.api";
import { ApiErrorSnackbar } from "../../../../../redux/utils/api-error-snackbar/ApiErrorSnackbar";
import GrowingAreaEditPage from "../../../growing-area/edition/GrowingAreaEditPage";

import "./METEditionPage.scss";

function useActionHook(metId?: string) {
	const { enqueueSnackbarSuccess, enqueueSnackbarError } = useAppSnackbar();
	const nav = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const { data: remoteMET } = apiMET.useGetMETByIdQuery(metId ?? skipToken);
	const [patchMETMutation] = apiMET.usePatchMETByIdMutation();
	const [deleteMETMutation] = apiMET.useDeleteMETByIdMutation();
	const getWizardState = () => {
		// We need the last data, we cannot use useSelector here
		return store.getState().trialWizard;
	};

	const handleSaveClick = useCallback(() => {
		if (metId) {
			const wizardState = getWizardState();
			const metPatch = convertWizardToPatchMET(wizardState, remoteMET);

			patchMETMutation({ id: metId, met: metPatch })
				.unwrap()
				.then(() => {
					dispatch(ECWAction.updateHasChanges(false));
					enqueueSnackbarSuccess(DIC(EDIC_KEY.SAVE));
				})
				.catch((err) => {
					console.warn(err);
					enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
				});
		}
	}, [
		dispatch,
		enqueueSnackbarError,
		enqueueSnackbarSuccess,
		metId,
		patchMETMutation,
		remoteMET,
	]);

	const handleSaveCommentClick = useCallback(
		async (comments: string) => {
			if (metId) {
				await patchMETMutation({
					id: metId,
					met: { comments },
				}).unwrap();
			}
		},
		[metId, patchMETMutation],
	);

	const handleDelete = useCallback(
		(deleteLinkedTrials: boolean) => {
			if (metId) {
				deleteMETMutation({ metId, deleteLinkedTrials })
					.unwrap()
					.then(() => {
						enqueueSnackbarSuccess(
							formatString(
								DIC(EDIC_KEY.ENTITY_DELETED),
								DIC(EDIC_KEY.MET),
							),
						);
						nav(`${PATH_EXPERIMENTS}${PATH_METS}`);
					})
					.catch((err) => {
						console.warn(err);
						enqueueSnackbarError(<ApiErrorSnackbar error={err} />);
					});
			}
		},
		[
			deleteMETMutation,
			enqueueSnackbarError,
			enqueueSnackbarSuccess,
			metId,
			nav,
		],
	);

	return { handleSaveClick, handleDelete, handleSaveCommentClick };
}

export default function METEditionPage() {
	const dispatch = useDispatch<AppDispatch>();
	const { metId } = useParams();
	const nav = useNavigate();
	const location = useLocation();

	const [isCommentOpen, setCommentOpen] = useState<boolean>(false);
	const [isDeletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);
	const [tabIdx, setTabIdx] = useState(0);

	const isPageFullSize = useSelector(selectECW.isPageFullSize);
	const metMaterials = useSelector(selectECW.selectedMaterials);
	const growingAreaInEdition = useSelector(selectECW.growingAreaInEdition);

	// POPUP
	const isAddMaterialOpen = useSelector(selectECW.isAddMaterialOpen);
	const isEditGAOpen = useSelector(selectECW.isEditGAOpen);
	const isConclusionOpen = useSelector(selectECW.isConclusionOpen);
	const metHasChanged = useSelector(selectECW.hasChanges);
	const isGrowingAreaMetOpen = useAppSelector(
		selectMETTrialManagement.isGrowingAreaOpen,
	);
	const isInit = useAppSelector(selectECW.isInit);
	const isCreateOperationOpen = useSelector(selectECW.isCreateOperationOpen);
	const isCreateObsRoundOpen = useSelector(selectECW.isCreateObsRoundOpen);
	const editedOperationId = useSelector(selectECW.editedOperationId);

	const { data: remoteMET } = apiMET.useGetMETByIdQuery(metId ?? skipToken);
	const { data: TrialsIdsByMETId } = apiTrial.useGetTrialsIdsByMETIdsQuery([
		remoteMET?._id || "",
	]);

	const { handleSaveClick, handleDelete, handleSaveCommentClick } =
		useActionHook(metId);

	useEffect(() => {
		if (isInit) {
			return;
		}
		if (metId) {
			dispatch(initializeMETWizard(metId));
		}
	}, [dispatch, isInit, metId]);

	useEffect(() => {
		const splittedPath = location.pathname.split("/");
		const currentTab = "/" + splittedPath[4] ?? undefined;

		switch (currentTab) {
			case PATH_GENERAL:
				setTabIdx(0);
				break;
			case PATH_TRIALS:
				setTabIdx(1);
				break;
			case PATH_OBSERVATION_VARIABLES:
				setTabIdx(2);
				break;
			case PATH_GERMPLASM:
				setTabIdx(3);
				break;
			case PATH_GROWING_AREA:
				setTabIdx(4);
				break;
			case PATH_PLANNING:
				setTabIdx(5);
				break;
			case PATH_DATA:
				setTabIdx(6);
				break;
			case PATH_SYNTHESIS:
				setTabIdx(7);
				break;
			default:
				nav(`.${PATH_GENERAL}`, { replace: true });
				setTabIdx(0);
				break;
		}
	}, [location, nav]);

	useLayoutEffect(() => {
		if (
			(remoteMET && remoteMET.status === EExperimentStatus.DRAFT) ||
			metId === "new"
		) {
			nav(`${PATH_MET_CREATION}/${metId}`);
		}
	}, [remoteMET, metId, nav]);

	useEffect(() => {
		if (metHasChanged) {
			dispatch(ECWAction.setConclusionIsOpen(false));
		}
	}, [dispatch, metHasChanged]);

	useEffect(() => {
		return () => {
			dispatch(ECWAction.reset({ wizardType: "SET", isInit: false }));
		};
	}, [dispatch]);

	const isSaveButtonDisabled = () => {
		if (metMaterials !== undefined) {
			return metMaterials.length === 0;
		}
		return true;
	};

	const displayValidatedMETButton = useMemo(() => {
		return (
			<DorianeButton
				dorianeStyle="quaternary"
				onClick={() => {
					dispatch(ECWAction.setConclusionIsOpen(true));
					setCommentOpen(false);
				}}
				disabled={metHasChanged}
			>
				<img src={Validate} />
			</DorianeButton>
		);
	}, [dispatch, metHasChanged]);

	const trialsIds = useMemo(
		() =>
			TrialsIdsByMETId?.find(
				(associated) => associated.metId === remoteMET?._id,
			)?.trialsIds,
		[TrialsIdsByMETId, remoteMET?._id],
	);

	return (
		<FlexBox className="METEditionPage-container" fullParentSize>
			<FlexBox
				flexDirection={"column"}
				takeRemainingSpace
				className={
					"METEditionPage-elements" +
					(isPageFullSize ? "" : " METEditionPage-elements-small")
				}
			>
				{remoteMET && (
					<>
						<TopMenu
							onBackClick={() =>
								nav(`${PATH_EXPERIMENTS}${PATH_METS}`)
							}
							deleteButton={{
								onClick: () => setDeletePopupOpen(true),
							}}
							saveButton={{
								onClick: handleSaveClick,
								isDisabled: isSaveButtonDisabled(),
							}}
							onCommentClick={() => {
								setCommentOpen(true);
								dispatch(ECWAction.setConclusionIsOpen(false));
							}}
							additionalButton={displayValidatedMETButton}
						/>
						<METBanner
							met={remoteMET}
							templateExpStructure={templateExpStructsMock.find(
								(tes) =>
									tes._id === remoteMET.templateExpStructId,
							)}
							trialIds={trialsIds}
							disabledClick
						/>
					</>
				)}
				<FlexBox takeRemainingSpace flexDirection={"column"}>
					<CustomTabs
						value={tabIdx}
						sx={{
							backgroundColor: styles["primary-background-color"],
						}}
					>
						<CustomLinkTab
							href={`.${PATH_GENERAL}`}
							label={DIC(EDIC_KEY.GENERAL)}
						/>
						<CustomLinkTab
							href={`.${PATH_TRIALS}`}
							label={DIC(EDIC_KEY.TRIALS)}
						/>
						<CustomLinkTab
							href={`.${PATH_OBSERVATION_VARIABLES}`}
							label={DIC(EDIC_KEY.OBSERVATION_VARIABLES)}
						/>
						<CustomLinkTab
							href={`.${PATH_GERMPLASM}`}
							label={DIC(EDIC_KEY.VARIETY_LIST)}
						/>
						<CustomLinkTab
							href={`.${PATH_GROWING_AREA}`}
							label={DIC(EDIC_KEY.GROWING_AREA)}
						/>
						<CustomLinkTab
							href={`.${PATH_PLANNING}`}
							label={DIC(EDIC_KEY.PLANNING)}
						/>
						<CustomLinkTab
							href={`.${PATH_DATA}`}
							label={DIC(EDIC_KEY.DATA)}
						/>
						<CustomLinkTab
							href={`.${PATH_SYNTHESIS}`}
							label={DIC(EDIC_KEY.SYNTHESIS)}
						/>
					</CustomTabs>

					<FlexBox takeRemainingSpace flexDirection={"column"}>
						<Outlet />
					</FlexBox>
				</FlexBox>
			</FlexBox>
			{isAddMaterialOpen && (
				<FlexBox
					takeRemainingSpace
					className="METEditionPage-right-side"
				>
					<ExperimentAddMaterial />
				</FlexBox>
			)}

			<ExperimentDeleteModal
				open={isDeletePopupOpen}
				isDraft={false}
				isMET={true}
				onClose={() => setDeletePopupOpen(false)}
				deleteAction={handleDelete}
			/>
			{isCommentOpen && (
				<CommentDrawer
					onClose={() => setCommentOpen(false)}
					remoteComments={remoteMET?.comments}
					onSaveComment={(comments) =>
						handleSaveCommentClick(comments)
					}
				/>
			)}
			{isConclusionOpen && <ExperimentConclusionModal />}
			{isGrowingAreaMetOpen && (
				<FlexBox
					takeRemainingSpace
					className="METEditionPage-right-side"
				>
					{metId && <METTrialsGrowingAreaModal />}
				</FlexBox>
			)}
			{isEditGAOpen && growingAreaInEdition && (
				<DorianeDrawer
					takeRemainingSpace
					onClose={() =>
						dispatch(ECWAction.setIsEditGAOpen({ open: false }))
					}
					title={DIC(EDIC_KEY.EDIT_GROWING_AREA_DETAILS)}
				>
					<Box takeRemainingSpace>
						<GrowingAreaEditPage
							hideNavBack
							growingAreaId={growingAreaInEdition}
						/>
					</Box>
				</DorianeDrawer>
			)}
			{isCreateObsRoundOpen && (
				<FlexBox
					takeRemainingSpace
					className="METEditionPage-right-side"
				>
					<CreationObsRoundModal
						onClose={() =>
							dispatch(ECWAction.setCreateObsRoundOpen(false))
						}
					/>
				</FlexBox>
			)}
			{isCreateOperationOpen && (
				<FlexBox
					takeRemainingSpace
					className="METEditionPage-right-side"
				>
					<OperationModal
						mode="creation"
						onClose={() =>
							dispatch(ECWAction.setCreateOperationOpen(false))
						}
					/>
				</FlexBox>
			)}
			{editedOperationId && (
				<FlexBox
					takeRemainingSpace
					className="METEditionPage-right-side"
				>
					<OperationModal
						mode="edition"
						onClose={() =>
							dispatch(
								ECWAction.updateEditedOperationId(undefined),
							)
						}
					/>
				</FlexBox>
			)}
		</FlexBox>
	);
}
