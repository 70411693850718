import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import React from "react";

import { Subtitle } from "stories/base-components/Typography/Typography";

import "./ModalTitle.scss";

interface ModalTitleProps {
	onClose: () => void;
	title: string;
	colorSlide?: string;
}

export default function ModalTitle(props: ModalTitleProps) {
	return (
		<div className="ModalTitle-container">
			{props.colorSlide && (
				<div
					className="ModalTitle-color"
					style={{
						backgroundColor: props.colorSlide,
					}}
				/>
			)}
			<Subtitle text={props.title} />
			<div className="ModalTitle-close-button">
				<IconButton color="primary" onClick={props.onClose}>
					<CloseIcon fontSize="medium" />
				</IconButton>
			</div>
		</div>
	);
}
