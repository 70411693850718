"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringifyError = exports.stringifyObject = exports.applyFormat = exports.convertHRTime = exports.getTimeInSeconds = void 0;
const enums_1 = require("./enums");
const getTimeInSeconds = function (aTimeInHour) {
    return aTimeInHour * 60 * 60;
};
exports.getTimeInSeconds = getTimeInSeconds;
const convertHRTime = function (hrtime, unit = enums_1.EUnit.MILLI) {
    const timeNano = hrtime[0] / enums_1.EUnit.NANO + hrtime[1];
    const conversionFactor = enums_1.EUnit.NANO / unit;
    return timeNano * conversionFactor;
};
exports.convertHRTime = convertHRTime;
const applyFormat = function (format, args) {
    return format.replace(/%(\d+)/g, (_, n) => args[+n]);
};
exports.applyFormat = applyFormat;
const stringifyObject = function (object, format = "[%0=%1]") {
    if (!object)
        return "";
    return Object.keys(object).reduce((result, key) => {
        const value = object[key];
        let valueString = "";
        if (typeof value === "object") {
            valueString = JSON.stringify(value, (_, val) => val === undefined ? "$UNDEFINED$" : val).replace('"$UNDEFINED$"', "undefined");
        }
        else {
            valueString = value === null || value === void 0 ? void 0 : value.toString();
        }
        return result + " " + (0, exports.applyFormat)(format, [key, valueString]);
    }, "");
};
exports.stringifyObject = stringifyObject;
const stringifyError = function (error, format = "[%0] %1 \nSTACK=%2") {
    if (!error)
        return "";
    const { name, message, stack } = error;
    return (0, exports.applyFormat)(format, [name, message, stack]);
};
exports.stringifyError = stringifyError;
