"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EReviewStatus = exports.ESubjectType = void 0;
var ESubjectType;
(function (ESubjectType) {
    ESubjectType[ESubjectType["MATERIAL_GENOTYPE"] = 0] = "MATERIAL_GENOTYPE";
    ESubjectType[ESubjectType["MATERIAL_LOT"] = 1] = "MATERIAL_LOT";
    ESubjectType[ESubjectType["TRIAL"] = 2] = "TRIAL";
})(ESubjectType = exports.ESubjectType || (exports.ESubjectType = {}));
var EReviewStatus;
(function (EReviewStatus) {
    EReviewStatus[EReviewStatus["NA"] = 0] = "NA";
    EReviewStatus[EReviewStatus["REVIEWED"] = 1] = "REVIEWED";
    EReviewStatus[EReviewStatus["EXCLUDED"] = 2] = "EXCLUDED";
})(EReviewStatus = exports.EReviewStatus || (exports.EReviewStatus = {}));
