import React from "react";

import { ITemplateObsCoreSerialized } from "shared-type";

import { getDicSpecies } from "App/dictionary/dictionary-key-getter";
import { AvatarIcon } from "stories/base-components/AvatarIcon/AvatarIcon";
import { Badge } from "stories/base-components/Badge/Badge";
import { Card } from "stories/base-components/Card/Card";
import {
	DropdownButton,
	IDropdownOption,
} from "stories/base-components/DropdownButton/DropdownButton";
import Colors from "stories/constants/Colors/Colors";

export interface ITemplateObservationCardProps {
	templateObservation: ITemplateObsCoreSerialized;
	onClick?: () => void;
	disabledClick?: boolean;
	borderColor?: string;
	noBorder?: boolean;
	backgroundColor?: string;
	dropdown?: Array<IDropdownOption>;
}

export default function TemplateObservationCard(
	props: ITemplateObservationCardProps,
) {
	return (
		<Card
			label={props.templateObservation.name.en}
			subObject={
				<Badge
					text={getDicSpecies(props.templateObservation.species)}
					backgroundColor={Colors.primary10}
					color={Colors.primary60}
				/>
			}
			headerIcon={
				<AvatarIcon
					iconName="obs-template"
					template
					color={Colors.primary60}
					backgroundColor={Colors.primary10}
				/>
			}
			backgroundColor={Colors.neutral10}
			borderColor={Colors.neutral40}
			onClick={props.onClick}
			cardHeaderRight={
				props.dropdown ? (
					<DropdownButton
						btn={{
							iconButton: true,
							icon: { name: "dots-vertical" },
							magicIcon: true,
							buttonType: "tertiary",
						}}
						options={props.dropdown}
					/>
				) : undefined
			}
		/>
	);
}
