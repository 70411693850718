import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import { genotypeColumns } from "App/components/base-components/data-grid/doriane-data-grid/default-grid-columns/GenotypeColumns";
import DorianeDataGrid from "App/components/base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import {
	convertDorObjFilterToFilter,
	IDorianeFilter,
	IDorianeObjectFilter,
} from "App/interfaces/filters/doriane-filter-interface";
import Size from "../../../../../stories/constants/Size/Size";
import PaginationTool from "../../../../components/base-components/pagination/PaginationTool";
import FlexBox from "../../../../components/base-components/placement/flex-box/FlexBox";
import {
	GENOTYPE_DEFAULT_PAGE_SIZE,
	GENOTYPE_DEFAULT_SORT,
} from "../../../../const";
import { selectGermplasm } from "../../../../redux/germplasm/germplasm.selector";
import { germplasmAction } from "../../../../redux/germplasm/germplasm.slice";
import { AppDispatch } from "../../../../store";

export default function GenotypeDataPage() {
	const dispatch = useDispatch<AppDispatch>();
	const debounceFetch = useDebouncedCallback(
		(
			// Retrieve request parameter to type debounce
			request: Parameters<
				typeof germplasmAction.getGenotypesListThunk
			>[0],
		) => {
			return dispatch(germplasmAction.getGenotypesListThunk(request));
		},
		500,
	);

	const [page, setPage] = useState<number>(0);
	const [filter, setFilter] = useState<IDorianeObjectFilter>({});

	const pageInfos = useSelector(selectGermplasm.genotypePageInfo);
	const genotypeList = useSelector(selectGermplasm.genotypeList);

	const handleFilterChange = (newFilter: IDorianeFilter) => {
		setFilter((old) => {
			return { ...old, [newFilter.key]: newFilter };
		});
	};

	const onPageChange = useCallback(
		(newPage: number, refetch = true) => {
			setPage(newPage);
			if (refetch) {
				debounceFetch({
					page: newPage,
					pageSize: GENOTYPE_DEFAULT_PAGE_SIZE,
					filter: convertDorObjFilterToFilter(filter),
					sort: GENOTYPE_DEFAULT_SORT,
				});
				// No delay on page change
				debounceFetch.flush();
			}
		},
		[debounceFetch, filter],
	);

	useEffect(() => {
		// Delayed when filter change
		debounceFetch({
			page: 0,
			pageSize: GENOTYPE_DEFAULT_PAGE_SIZE,
			filter: convertDorObjFilterToFilter(filter),
			sort: GENOTYPE_DEFAULT_SORT,
		})?.then(() => {
			// reset page to 0 on filter
			onPageChange(0, false);
		});
	}, [debounceFetch, filter, onPageChange]);

	useEffect(() => {
		// cancel request when unmount
		return () => {
			debounceFetch.cancel();
		};
	}, [debounceFetch]);

	return (
		<FlexBox flexDirection={"column"} fullParentSize padding={Size.sm}>
			<FlexBox takeRemainingSpace>
				<DorianeDataGrid
					columns={genotypeColumns(filter, handleFilterChange)}
					rows={genotypeList || []}
				/>
			</FlexBox>
			<FlexBox justifyContent={"center"} padding={Size.sm}>
				<PaginationTool
					currentPage={page}
					maxPage={pageInfos?.totalPage || 0}
					onPageRoll={onPageChange}
				/>
			</FlexBox>
		</FlexBox>
	);
}
