import React from "react";
import { DIC, EDIC_KEY } from "App/dictionary";
import EmptyComponent from "App/components/base-components/empty-component/EmptyComponent";
import Box, {
	EPaddingSize,
} from "App/components/base-components/placement/box/Box";

export default function TrialSynthesisPage() {
	return (
		<div className="TrialSynthesisPage-container take-remaining-space flex-column">
			<div className="TrialSynthesisPage-body flex-column take-remaining-space">
				<Box padding={EPaddingSize.MEDIUM}>
					<div className="TrialSynthesisPage-title-container">
						<h1>{DIC(EDIC_KEY.SYNTHESIS)}</h1>
					</div>
					<EmptyComponent message="Synthesis page to be configured with Bloomeo Teams" />
				</Box>
			</div>
		</div>
	);
}
