import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

import { IMETSerialized } from "shared-type";

import { apiMET } from "../experiment/met/met.api";
import { apiGrowingArea } from "../growing-area/growing-area.api";
import { ECWAction } from "./ECW.slice";

export const initializeMETWizard = createAsyncThunk(
	"experimentCreationWizard/initializeMetWizard",
	async (idMET: string, thunkApi) => {
		// fetch the met
		const met: IMETSerialized = await thunkApi
			.dispatch(apiMET.endpoints.getMETById.initiate(idMET))
			.unwrap();

		// fetch growing area of each trial of the MET
		const growingAreaIds = _.uniq(
			met.trialsDraft
				?.map((trial) => trial.growingAreaId)
				.filter(_.isString) || [],
		);
		let nameGA: { id: string; name: string }[] = [];
		if (growingAreaIds.length > 0) {
			const growingAreas = await thunkApi
				.dispatch(
					apiGrowingArea.endpoints.getGrowingAreaByIds.initiate(
						growingAreaIds,
					),
				)
				.unwrap()
				.then((resp) => resp || []);
			nameGA = growingAreas.map((ga) => ({ id: ga._id, name: ga.name }));
		}

		// Update the redux state
		thunkApi.dispatch(
			ECWAction.initStateFromMET({ met, trialGrowingAreas: nameGA }),
		);
	},
);
