import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IMETTrialRowDetails } from "../met/met-trial-management.slice";

export interface ITrialState {
	deleteIdSelected?: string;
	duplicateSelected?: IMETTrialRowDetails;
}
function createInitialState(): ITrialState {
	return {};
}
const trial = createSlice({
	name: "trial",
	initialState: createInitialState(),
	reducers: {
		selectDeleteTrialId: (
			state,
			action: PayloadAction<string | undefined>,
		) => {
			state.deleteIdSelected = action.payload;
		},
		selectDuplicateTrial: (
			state,
			action: PayloadAction<IMETTrialRowDetails | undefined>,
		) => {
			state.duplicateSelected = action.payload;
		},
	},
});

export const trialReducer = trial.reducer;
export const trialAction = {
	...trial.actions,
};
