import { createApi } from "@reduxjs/toolkit/dist/query/react";

import {
	ICreateMET,
	IFilter,
	IMETSerialized,
	IPatchMET,
	ISort,
	PaginateResponse,
} from "shared-type";

import { useRequest } from "../../../request-provider";
import { defaultApiError, ReduxApiError } from "../../utils/errors";
import { TrialApiHelper } from "../trial/trial.api";
import { ExpGanttApiHelper } from "App/redux/experiment-statistics/exp-gantt.api";

export const apiMET = createApi({
	reducerPath: "api-experiment-MET",
	baseQuery: () => ({ data: undefined }),

	// global cache configuration for the api (in second)
	keepUnusedDataFor: 30,
	refetchOnMountOrArgChange: 30,

	// update tag
	tagTypes: ["Update", "Delete", "Create"],

	// api definition
	endpoints: (build) => ({
		getMET: build.query<
			PaginateResponse<IMETSerialized>,
			{ filter?: IFilter; sort?: ISort; page?: number; pageSize?: number }
		>({
			queryFn: async (args) => {
				try {
					const result = await useRequest().met.getMET(args);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(err, "Unable to fetch MET");
				}
			},
			providesTags: [
				{ type: "Delete", id: "ALL" },
				{ type: "Create", id: "ALL" },
				{ type: "Update", id: "ALL" },
			],
		}),
		getMETById: build.query<IMETSerialized, string | undefined>({
			queryFn: async (id) => {
				try {
					if (id === undefined) {
						const error: { error: ReduxApiError } = {
							error: {
								message: "Id is required for get MET",
							},
						};
						return error;
					}
					const result = await useRequest().met.getMETById(id);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(
						err,
						`Unable to fetch met with id ${id}`,
					);
				}
			},
			providesTags: (res, error, id) => [
				{ type: "Delete", id: id },
				{ type: "Create", id: id },
				{ type: "Update", id: id },
			],
		}),
		getMETsByIds: build.query<IMETSerialized[], string[]>({
			queryFn: async (ids) => {
				try {
					if (ids.length === 0) {
						return { data: [] };
					}

					const result = await useRequest().met.getMETsByIds(ids);
					return {
						data: result.data,
					};
				} catch (err) {
					console.error(err);
					return defaultApiError(
						err,
						`Unable to fetch met with ids ${ids}`,
					);
				}
			},
			providesTags: () => [
				{ type: "Delete", id: "ALL" },
				{ type: "Update", id: "ALL" },
				{ type: "Create", id: "ALL" },
			],
		}),
		createMET: build.mutation<string, ICreateMET>({
			queryFn: async (createMET) => {
				try {
					const result = await useRequest().met.createMET(createMET);
					return {
						data: result.data,
					};
				} catch (err) {
					return defaultApiError(err, "Unable to create the MET");
				}
			},
			invalidatesTags: (res) => [
				{ type: "Create", id: res },
				{ type: "Create", id: "ALL" },
			],
		}),
		patchMETById: build.mutation<undefined, { id: string; met: IPatchMET }>(
			{
				queryFn: async ({ id, met }) => {
					try {
						await useRequest().met.updateMETById(id, met);
						return {
							data: undefined,
						};
					} catch (err) {
						return defaultApiError(err, "Unable to update the MET");
					}
				},
				invalidatesTags: (res, err, { id }) => [
					{ type: "Update", id: id },
					{ type: "Update", id: "ALL" },
				],
			},
		),
		deleteMETById: build.mutation<
			void,
			{ metId: string; deleteLinkedTrials: boolean }
		>({
			queryFn: async (args) => {
				try {
					const result = await useRequest().met.deleteMETById(
						args.metId,
						args.deleteLinkedTrials,
					);
					return { data: result.data };
				} catch (err) {
					return defaultApiError(
						err,
						"Unable to delete MET with id : " + args.metId,
					);
				}
			},
			invalidatesTags: (res, err, args) => {
				return [
					{ type: "Delete", id: args.metId },
					{ type: "Delete", id: "ALL" },
				];
			},
		}),
		checkTrialsUnicity: build.mutation<
			void,
			{ year: number; names: string[] }
		>({
			queryFn: async (args) => {
				try {
					const result = await useRequest().met.checkTrialsUnicity(
						args,
					);
					return { data: result.data };
				} catch (err) {
					return defaultApiError(err, "Unicity check failed");
				}
			},
		}),
		generateTrialsForMET: build.mutation<
			string[],
			{
				metId: string;
				trials: { name: string; growingAreaId?: string }[];
			}
		>({
			queryFn: async (args) => {
				try {
					const result = await useRequest().met.generateTrialForMET(
						args,
					);

					return { data: result.data };
				} catch (err) {
					return defaultApiError(
						err,
						"Error while creating trials for the MET",
					);
				}
			},
			invalidatesTags: () => {
				TrialApiHelper.invalidateTags([{ type: "Create", id: "ALL" }]);
				ExpGanttApiHelper.invalidateTags(["Get"]);
				return [];
			},
		}),
	}),
});
