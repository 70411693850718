import { IconButton } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";

import { Delete, LandscapeAdd, SVGDocAdd } from "App/../assets";
import { DIC, EDIC_KEY } from "../../../../../dictionary";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/reduxHook";
import { selectECW } from "../../../../../redux/ECW/ECW.selector";
import { ECWAction } from "../../../../../redux/ECW/ECW.slice";
import { CheckBoxColumn } from "../../../../base-components/data-grid/doriane-data-grid/columns/CheckBoxColumn";
import { METTrialNameColumn } from "../../../../base-components/data-grid/doriane-data-grid/doriane-data-grid/column/met-trials/METTrialNameColumn";
import DorianeDataGrid, {
	IColumn,
} from "../../../../base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import CreationWizardMenu from "../../../../base-components/menu/creation-wizard/CreationWizardMenu";
import { EPaddingSize } from "../../../../base-components/placement/box/Box";
import FlexBox from "../../../../base-components/placement/flex-box/FlexBox";
import CommonECWPart from "../../CommonECWPart";
import { IMETTrialDraftRow } from "../../types";
import { GenerationModal } from "./generation-modal/GenerationModal";
import TrialGenerationSelectGA from "./growing-area-selection/TrialGenerationSelectGA";

import "./TrialGeneration.scss";

interface IECWTrialGenerationProps {
	onNext: (action: "save" | "doItLater") => void;
}
export default function ECWTrialGeneration(props: IECWTrialGenerationProps) {
	const dispatch = useAppDispatch();

	const [inEditionTrial, setInEditionTrial] = useState<number | undefined>(
		undefined,
	);

	const nbOfTrials = useAppSelector(selectECW.trialGenerationPart.nbOfTrials);
	const selectedTrial = useAppSelector(
		selectECW.trialGenerationPart.selectedTrials,
	);
	const isGrowingAreaModalOpen = useAppSelector(
		selectECW.trialGenerationPart.isGAModalOpen,
	);
	const trials = useAppSelector(
		(state) => state.trialWizard.trialGenerationPart?.trials || [],
	);

	const trialsRow = useMemo<IMETTrialDraftRow[]>(
		() =>
			trials.map((elt) => ({
				id: elt.trialId,
				growingAreaName: elt.growingAreaName || "",
				trialName: elt.trialName,
			})),
		[trials],
	);

	const checkBoxColumn = CheckBoxColumn<IMETTrialDraftRow>({
		isCheck: (row) => selectedTrial.has(row.id),
		onCheck: (row, check) =>
			dispatch(
				ECWAction.trialGenerationOnSelectTrial({
					id: row.id,
					select: check,
				}),
			),
		headerCheckAllAction: (check) => {
			dispatch(
				ECWAction.trialGenerationOnSelectTrialMany({
					select: check,
					trialsRow: trialsRow,
				}),
			);
		},
	});

	const [isGenerationModalOpen, setIsGenerationModalOpen] = useState(false);

	const onDeleteSelected = useCallback(() => {
		dispatch(
			ECWAction.trialGenerationDeleteTrialsById({
				trialsIdsToDelete: Array.from(selectedTrial),
			}),
		);
	}, [dispatch, selectedTrial]);

	const openGAModal = useCallback(() => {
		dispatch(ECWAction.trialGenerationSetIsOpenGAModal(true));
	}, [dispatch]);

	const onInitTrialNameEdition = useCallback(
		(trialId: number) => {
			const currentTrial = trialsRow.find((elt) => elt.id === trialId);
			if (!currentTrial) {
				return;
			}
			setInEditionTrial(trialId);
		},
		[trialsRow],
	);

	const onSubmitTrialNewName = useCallback(
		(newName: string) => {
			if (!newName || !inEditionTrial) {
				return;
			}
			dispatch(
				ECWAction.trialGenerationOnRenameTrial({
					id: inEditionTrial,
					newName: newName,
				}),
			);
			setInEditionTrial(undefined);
		},
		[inEditionTrial, dispatch],
	);

	const trialNameColumn = useMemo<IColumn<IMETTrialDraftRow>>(
		() =>
			METTrialNameColumn({
				selectedEditingColumnId: inEditionTrial,
				onEditClick: (row) => onInitTrialNameEdition(row.id),
				onSubmitChange: onSubmitTrialNewName,
			}),
		[inEditionTrial, onInitTrialNameEdition, onSubmitTrialNewName],
	);

	return (
		<FlexBox fullParentSize>
			<CommonECWPart>
				<FlexBox takeRemainingSpace flexDirection={"column"}>
					<FlexBox
						takeRemainingSpace
						flexDirection={"column"}
						padding={EPaddingSize.MEDIUM}
					>
						<div className="ECWTrialGeneration-title-container">
							<h1>{DIC(EDIC_KEY.TRIALS_CREATION)}</h1>
							<div>
								<IconButton
									onClick={() =>
										setIsGenerationModalOpen(true)
									}
									color="primary"
									className="ECWTrialGeneration-title-button"
								>
									<img src={SVGDocAdd} />
								</IconButton>
								<IconButton
									onClick={openGAModal}
									color="primary"
									className="ECWTrialGeneration-title-button"
								>
									<img src={LandscapeAdd} />
								</IconButton>
								<IconButton
									onClick={onDeleteSelected}
									color="primary"
									className="ECWTrialGeneration-title-button"
								>
									<img src={Delete} />
								</IconButton>
							</div>
						</div>
						<DorianeDataGrid
							className="ECWTrialGeneration-table"
							getRowId={(row) => row.trialName}
							columns={[
								checkBoxColumn,
								trialNameColumn,
								{
									id: "growingAreaName",
									getHeaderRenderer: "Growing area",
									getCellRenderer: (row) =>
										row?.growingAreaName || "",
								},
							]}
							rows={trialsRow}
							customRowClass={(row) =>
								(selectedTrial.has(row.id)
									? ["ECWTrialGeneration-row-selected"]
									: [])
							}
						/>
					</FlexBox>

					<div className="ECWTrialGeneration-table-footer">{`${nbOfTrials} ${DIC(
						EDIC_KEY.TRIALS,
					)}`}</div>
					<CreationWizardMenu
						onSave={() => props.onNext("save")}
						onDoItLaterClick={() => props.onNext("doItLater")}
						displaySave={trials.length > 0}
					/>
				</FlexBox>

				{isGenerationModalOpen && (
					<GenerationModal
						onClose={() => setIsGenerationModalOpen(false)}
					/>
				)}
			</CommonECWPart>

			{isGrowingAreaModalOpen && (
				<div className="ECW-right-side">
					<TrialGenerationSelectGA />
				</div>
			)}
		</FlexBox>
	);
}
