import * as React from "react";
import { CSSProperties } from "react";

import "./CustomCard.scss";

export interface ICustomCardProps {
	children: React.ReactNode;
	onClick?: () => void;
	className?: string;
	disabledClick?: boolean;
	style?: CSSProperties;
	isSelected?: boolean;
}

export default function CustomCard(props: ICustomCardProps) {
	return (
		<div
			className={
				(props.className ? props.className + " " : " ") +
				(props.disabledClick ? "CustomCard-disabled " : "CustomCard ") +
				(props.isSelected ? "CustomCard-selected " : "")
			}
			style={props.style}
			onClick={props.disabledClick ? () => {} : props.onClick}
		>
			{props.children}
		</div>
	);
}
