import React from "react";

import { EntitySerializer, formatString } from "common";
import { IGrowingAreaSerialized } from "shared-type";

import { DIC, EDIC_KEY } from "../../../dictionary";
import SkeletonCustomCard from "../../base-components/card/SkeletonCustomCard";
import PaginationTool from "../../base-components/pagination/PaginationTool";
import FlexBox, {
	EGapSize,
} from "../../base-components/placement/flex-box/FlexBox";
import GrowingAreaCard from "../card/GrowingAreaCard";

export interface IGrowingAreaCardListProps {
	growingArea: IGrowingAreaSerialized[] | undefined;
	isLoading?: boolean;
	isError?: boolean;
	onCardClick?: (growingArea: IGrowingAreaSerialized) => void;
	className?: string;
	paginate?: {
		onPageChange: (newPage: number) => void;
		currentPage: number;
		totalElements: number;
	};
}
export default function GrowingAreaCardList(props: IGrowingAreaCardListProps) {
	const paginate = props.paginate;

	if (props.isLoading) {
		return (
			<FlexBox
				className={props.className}
				flexDirection={"column"}
				gap={EGapSize.MEDIUM}
			>
				{[1, 1, 1, 1].map((elt, index) => (
					<FlexBox
						gap={EGapSize.MEDIUM}
						key={index}
						takeRemainingSpace
					>
						<SkeletonCustomCard flex />
						<SkeletonCustomCard flex />
					</FlexBox>
				))}
			</FlexBox>
		);
	}
	if (props.isError) {
		return (
			<div className={props.className}>
				{formatString(
					DIC(EDIC_KEY.CANT_ACTION_ENTITY),
					DIC(EDIC_KEY.FETCH).toLowerCase(),
					DIC(EDIC_KEY.GROWING_AREAS).toLowerCase(),
				)}
			</div>
		);
	}
	if (!props.growingArea || props.growingArea.length === 0) {
		return (
			<div className={props.className}>
				{formatString(
					DIC(EDIC_KEY.NO_ENTITIES_FOUND),
					DIC(EDIC_KEY.GROWING_AREAS),
				)}
			</div>
		);
	}
	return (
		<FlexBox
			className={props.className}
			flexDirection="column"
			overflow={"auto"}
			takeRemainingSpace
			gap={EGapSize.MEDIUM}
		>
			<FlexBox
				overflow={"auto"}
				takeRemainingSpace
				flexDirection="column"
			>
				<FlexBox gap={EGapSize.MEDIUM} flexWrap={"wrap"}>
					{props.growingArea?.map((elt) => (
						<GrowingAreaCard
							key={elt._id}
							growingArea={EntitySerializer.deserialize(elt)}
							onClick={() => {
								props.onCardClick?.(elt);
							}}
						/>
					))}
				</FlexBox>
			</FlexBox>
			{paginate !== undefined && (
				<FlexBox justifyContent={"center"}>
					<PaginationTool
						currentPage={paginate.currentPage}
						maxPage={paginate.totalElements}
						onPageRoll={paginate.onPageChange}
					/>
				</FlexBox>
			)}
		</FlexBox>
	);
}
