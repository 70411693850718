import { METtemplateExpStructMock } from "common";
import {
	EExperimentStatus,
	ICreateMET,
	IMETSerialized,
	IMETTrialDefinition,
	IPatchMET,
} from "shared-type";

import { IECWState } from "../../../redux/ECW/ECW.slice";
import { isExpCompleted } from "../common/wizard-utils";

export const convertWizardToPatchMET = (
	state: IECWState,
	remoteMET: IMETSerialized | undefined,
	setToReady?: boolean,
) => {
	// if complete and we are in draft and want to go to ready -> status ready
	const newStatus = computeNewStatus(state, remoteMET, setToReady);

	const generalInfo = state.generalPart.generalInfo;
	const updateMET: IPatchMET = {
		name: generalInfo?.name,
		label: generalInfo?.label,
		objective: generalInfo?.objective,
		description: generalInfo?.description,
		startDate: generalInfo?.startDate
			? new Date(generalInfo.startDate)
			: undefined,
		endDate: generalInfo?.endDate
			? new Date(generalInfo.endDate)
			: undefined,
		species: generalInfo?.species,
		program: generalInfo?.program,
		project: generalInfo?.project,
		status: newStatus,
		// if undefined remove the field (with null)
		templateObsId: state.observationPart.templateObsId ?? null,
		materialLevel: {
			germplasmLevel: state.germplasmPart.materialType,
			materials: state.germplasmPart.selectedMaterials,
		},
		marketSegmentIds: generalInfo?.marketSegmentIds,
		trialsDraft: state.trialGenerationPart.trials.map((trial) => {
			const result: IMETTrialDefinition = {
				id: trial.trialId,
				name: trial.trialName,
				growingAreaId: trial.growingAreaId,
			};
			return result;
		}),
	};

	return updateMET;
};

export const convertWizardToCreateMET = (state: IECWState): ICreateMET => {
	const fakeTemplateExpStruct = METtemplateExpStructMock;

	const generalInfo = state.generalPart.generalInfo;
	const createMET: Partial<ICreateMET> = {
		name: generalInfo?.name,
		label: generalInfo?.label,
		objective: generalInfo?.objective,
		description: generalInfo?.description,
		startDate: generalInfo?.startDate
			? new Date(generalInfo.startDate)
			: undefined,
		endDate: generalInfo?.endDate
			? new Date(generalInfo.endDate)
			: undefined,
		species: generalInfo?.species,
		program: generalInfo?.program,
		project: generalInfo?.project,
		templateExpStructId: fakeTemplateExpStruct._id,
		materialLevel: {
			germplasmLevel: state.germplasmPart.materialType,
			materials: state.germplasmPart.selectedMaterials,
		},
		templateObsId: state.observationPart.templateObsId,
		marketSegmentIds: generalInfo?.marketSegmentIds,
	};
	const createMETValidate = createMET as ICreateMET;
	return createMETValidate;
};
export function computeNewStatus(
	state: IECWState,
	remoteMET: IMETSerialized | undefined,
	setToReady?: boolean,
): EExperimentStatus {
	if (!remoteMET) {
		throw new Error("No remote MET were found");
	}

	let newStatus = remoteMET.status ?? EExperimentStatus.DRAFT;
	if (
		setToReady && // We can set to ready
		remoteMET.status === EExperimentStatus.DRAFT && // We are in draft
		isExpCompleted(state) // Trial is complete
	) {
		newStatus = EExperimentStatus.READY;
	}
	return newStatus;
}
