import dictionaryJSON from "./EN_DIC.json";

interface ILanguage {
	[language: string]: string;
}

interface IDictionary {
	[key: string]: ILanguage;
}

export class Dictionary {
	language = "en";

	translate = (key: string): string => {
		const dictionary = dictionaryJSON as IDictionary;
		if (dictionary[key] && dictionary[key][this.language]) {
			return dictionary[key][this.language];
		}
		return "MISSING_KEY";
	};
}

const DictionaryInst = new Dictionary();
export const DIC = DictionaryInst.translate;
