import { GridCell, GridCellKind, Theme } from "@glideapps/glide-data-grid";

import { IVariableCoreSerialized } from "shared-type";

import { notationColorScale } from "../notation-cell-utils";
import { NumberNotationOption } from "../notation-cell.types";
import { computeLimitedChoicesDisplay } from "./utils";

export const numberNotationCellDrawer = (
	notationValue: number,
	variable?: IVariableCoreSerialized,
	option?: NumberNotationOption,
) => {
	const colorScale =
		option?.colorScale !== undefined
			? notationColorScale(
					notationValue,
					option.colorScale.min,
					option.colorScale.max,
			  )
			: undefined;

	const theme: Partial<Theme> = option?.themeOverride ?? {};
	if (colorScale) {
		theme.bgCell = colorScale;
	}
	const cell: GridCell = {
		kind: GridCellKind.Number,
		data: notationValue,
		displayData: computeLimitedChoicesDisplay(
			`${notationValue ?? ""}`,
			notationValue,
			variable,
		),
		allowOverlay: option?.isModifiable ?? false,
		readonly: !option?.isModifiable ?? true,
		themeOverride: theme,
	};
	return cell;
};
