import { getter } from "@progress/kendo-react-common";
import { GridCustomCellProps } from "@progress/kendo-react-grid";
import React from "react";

import { IUser } from "shared-type";

import UserTag from "../../../tag/user-tag/UserTag";
import { BaseCell } from "./BaseCell";

export const UserCell = (props: GridCustomCellProps) => {
	const value = getter(props.field ?? "")(props.dataItem);
	return (
		<BaseCell {...props}>
			<div className="flex-center-full-height DorianeDataGridCell-padding-horizontal ">
				{value?.map?.((user: IUser, index: number) => (
					<UserTag
						user={user}
						key={index}
						title=""
						fullNameOnHover
						initialsOnly
					/>
				))}
			</div>
		</BaseCell>
	);
};
