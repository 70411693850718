import React from "react";

import { DIC, EDIC_KEY } from "../../../../../dictionary";
import { getColorFromVariableScope } from "../../../../../utils";
import { ITemplateObsLinkedVar } from "../../../../templates/observation/TemplateObsEditionPage.utils";
import { TickMarkColumn } from "../columns/TickMarkColumn";
import { IColumn } from "../doriane-data-grid/DorianeDataGrid";
import { Typography } from "stories/base-components/Typography/Typography";
import Colors from "stories/constants/Colors/Colors";
import { Tooltip } from "stories/base-components/Tooltip/Tooltip";
import { Icon } from "stories/base-components/Icon/Icon";
import FlexBox from "App/components/base-components/placement/flex-box/FlexBox";
import Size from "stories/constants/Size/Size";

export const templateObsLinkedVarColumns: (
	hiddenColorStripe?: boolean,
) => IColumn<ITemplateObsLinkedVar>[] = (hiddenColorStripe) => {
	const colorStripe: IColumn<ITemplateObsLinkedVar> = {
		id: "colorCategory",
		getHeaderRenderer: "",
		width: Size.sm_small,
		getCellRenderer: (row) => {
			return (
				<div
					className="full-parent-size"
					style={{
						backgroundColor: getColorFromVariableScope(
							row.variableScope,
						),
					}}
				/>
			);
		},
		headerClassName: "DorianeDataGrid-color",
		cellClassName: "DorianeDataGrid-color",
	};

	const columns: IColumn<ITemplateObsLinkedVar>[] = [];

	if (!hiddenColorStripe) {
		columns.push(colorStripe);
	}

	columns.push({
		id: "varName",
		getHeaderRenderer: (
			<FlexBox>
				<Typography
					text={DIC(EDIC_KEY.LINKED_VARIABLES)}
					variant="body2Medium"
					color={Colors.neutral100}
				/>
				<FlexBox style={{ paddingLeft: "16px" }} alignItems="center">
					<Tooltip
						text="Linked variables"
						addContainer
						placement="top"
					>
						<Icon
							name="info"
							fontSize="12px"
							color={Colors.neutral70}
						/>
					</Tooltip>
				</FlexBox>
			</FlexBox>
		),
		getCellRenderer: (row) => row.varName,
	});

	columns.push(
		TickMarkColumn(
			(row) => row.checked,
			DIC(EDIC_KEY.LOCATION),
			"tickLocation",
		),
	);

	return columns;
};
