/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios from "axios";

// README :
// When adding a new HOST, you have to keep the file {{project_folder}}/docker/entrypoint.sh up to date. "../../docker/entrypoint.sh"

export let configuration: {
	// Action
	HOST_API_CORE?: string;
	HOST_API_EXPERIMENT?: string;
	HOST_API_GERMPLASM?: string;
	HOST_API_GROWING_AREA?: string;
	// I18N
	HOST_API_STRUCTURE?: string;
	HOST_API_USER?: string;
	HOST_API_VARIABLE?: string;
	HOST_API_CHART?: string;
	HOST_API_TENANT?: string;
	HOST_API_ATTACHMENT?: string;
	USE_LOCAL_API_KEY?: string;

	COGNITO_USER_POOL_ID: string;
	COGNITO_CLIENT_ID: string;
	COGNITO_REGION: string;
	COGNITO_DOMAIN: string;
};
export function initConfiguration() {
	return axios
		.get("/env.json")
		.then((resp) => {
			if (typeof resp.data === "string") {
				console.error(
					"env.json in not a valid json, please check the config",
				);
			}
			configuration = {
				// Look README up above ;)
				// Action
				HOST_API_CORE: resp.data?.REACT_APP_HOST_API_CORE || undefined,
				HOST_API_EXPERIMENT:
					resp.data?.REACT_APP_HOST_API_EXPERIMENT || undefined,
				HOST_API_GERMPLASM:
					resp.data?.REACT_APP_HOST_API_GERMPLASM || undefined,
				HOST_API_GROWING_AREA:
					resp.data?.REACT_APP_HOST_API_GROWING_AREA || undefined,
				// I18N
				HOST_API_STRUCTURE:
					resp.data?.REACT_APP_HOST_API_STRUCTURE || undefined,
				HOST_API_USER: resp.data?.REACT_APP_HOST_API_USER || undefined,
				HOST_API_VARIABLE:
					resp.data?.REACT_APP_HOST_API_VARIABLE || undefined,
				HOST_API_CHART:
					resp.data?.REACT_APP_HOST_API_CHART || undefined,
				HOST_API_TENANT:
					resp.data?.REACT_APP_HOST_API_TENANT || undefined,
				HOST_API_ATTACHMENT:
					resp.data?.REACT_APP_HOST_API_ATTACHMENT || undefined,
				// DO NOT ADD USE_LOCAL_API_KEY

				COGNITO_USER_POOL_ID: resp.data?.REACT_APP_COGNITO_USER_POOL_ID,
				COGNITO_CLIENT_ID: resp.data?.REACT_APP_COGNITO_CLIENT_ID,
				COGNITO_REGION: resp.data?.REACT_APP_COGNITO_REGION,
				COGNITO_DOMAIN: resp.data?.REACT_APP_COGNITO_DOMAIN,
			};
		})
		.catch(() => {
			console.warn(
				"env.json not found, using env variables, please check the config",
			);
			configuration = {
				// Action
				HOST_API_CORE: process.env.REACT_APP_HOST_API_CORE,
				HOST_API_EXPERIMENT: process.env.REACT_APP_HOST_API_EXPERIMENT,
				HOST_API_GERMPLASM: process.env.REACT_APP_HOST_API_GERMPLASM,
				HOST_API_GROWING_AREA:
					process.env.REACT_APP_HOST_API_GROWING_AREA,
				// I18N
				HOST_API_STRUCTURE: process.env.REACT_APP_HOST_API_STRUCTURE,
				HOST_API_USER: process.env.REACT_APP_HOST_API_USER,
				HOST_API_VARIABLE: process.env.REACT_APP_HOST_API_VARIABLE,
				HOST_API_CHART: process.env.REACT_APP_HOST_API_CHART,
				HOST_API_TENANT: process.env.REACT_APP_HOST_API_TENANT,
				HOST_API_ATTACHMENT: process.env.REACT_APP_HOST_API_ATTACHMENT,
				USE_LOCAL_API_KEY: process.env.REACT_APP_USE_LOCAL_API_KEY,

				COGNITO_USER_POOL_ID:
					process.env.REACT_APP_COGNITO_USER_POOL_ID!,
				COGNITO_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID!,
				COGNITO_REGION: process.env.REACT_APP_COGNITO_REGION!,
				COGNITO_DOMAIN: process.env.REACT_APP_COGNITO_DOMAIN!,
			};
		})
		.then(() => {
			const missingKeys = Object.entries(configuration).filter(
				([, value]) => value === undefined,
			);
			if (missingKeys.length > 0) {
				console.error(
					"Configuration loading failed. Missing keys: ",
					missingKeys,
				);
			}
		});
}
