import React from "react";
import { ITemplateObsCoreSerialized, IVariableCore } from "shared-type";
import { templateObsVarColumns } from "../../../../base-components/data-grid/doriane-data-grid/default-grid-columns/TemplateObsVarColumns";
import DorianeDataGrid from "../../../../base-components/data-grid/doriane-data-grid/doriane-data-grid/DorianeDataGrid";
import { templateVarGridMethods } from "../../TemplateObsEditionPage.utils";

export interface ITemplateObsVarGridProps {
	templateObservation: ITemplateObsCoreSerialized;
	variableOntology: IVariableCore[];
	onTrialCheck?: (row: IVariableCore, checked: boolean) => void;
	onMaterialCheck?: (row: IVariableCore, checked: boolean) => void;
	isTemplateObsUsed: boolean;
	hiddenColorStripe?: boolean;
	displayMode?: "checkbox" | "tickMark";
}

export default function TemplateObsVarGrid(props: ITemplateObsVarGridProps) {
	const {
		variableInTemplate,
		isTrialCheck,
		isMaterialCheck,
	}: {
		variableInTemplate: IVariableCore[];
		isTrialCheck: (row: IVariableCore) => boolean;
		isMaterialCheck: (row: IVariableCore) => boolean;
	} = templateVarGridMethods(
		props.templateObservation,
		props.variableOntology,
	);

	return (
		<DorianeDataGrid<IVariableCore>
			rows={variableInTemplate}
			getRowId={(row) => row._id}
			columns={templateObsVarColumns({
				onTrialCheck: props.onTrialCheck,
				onMaterialCheck: props.onMaterialCheck,
				hiddenColorStripe: props.hiddenColorStripe ?? false,
				displayMode: props.displayMode ?? "checkbox",
				checkDisabled: props.isTemplateObsUsed,
				isTrialCheck: isTrialCheck,
				isMaterialCheck: isMaterialCheck,
			})}
		/>
	);
}
