import _ from "lodash";

import { IMETTrialRow } from "App/redux/experiment/met/met-trial-management.slice";
import { TrialSerializedWithDeps } from "../../../trial/list/TrialWithDeps.hook";

/**
 * Helper function to format URL encoded form data.
 */
export function sortTrialsRows(trials: IMETTrialRow[]) {
	const trialsCopy = _.cloneDeep(trials);
	trialsCopy.sort((trialA, trialB) =>
		(trialA.trial?.name ?? "").localeCompare(trialB.trial?.name ?? ""),
	);
	return trialsCopy;
}

export function convertTrialsWithDepsToMETTrialRows(
	trialsWithDeps: TrialSerializedWithDeps[],
): IMETTrialRow[] {
	const trialConverted: IMETTrialRow[] = [];
	for (let i = 0; i < trialsWithDeps.length; i++) {
		const trial = trialsWithDeps[i];
		const growingArea = trial.growingArea;
		const marketSegment = trial.marketSegments;

		// Remove MS to add string Array later
		const { marketSegments, ...trialWithoutMS } = trial;

		const trialRow: IMETTrialRow = {
			id: i,
			trial: { ...trialWithoutMS, replication: 1 },
		};
		if (growingArea) {
			trialRow.growingArea = {
				country: growingArea.country,
				id: growingArea._id,
				name: growingArea.name,
				region: growingArea.region,
			};
		}
		if (marketSegment && marketSegment.length !== 0) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			trialRow.trial!.marketSegments = marketSegment.map(
				(mkSeg) => mkSeg.name,
			);
		}
		trialConverted.push(trialRow);
	}
	return trialConverted;
}
