import React from "react";

import { ITrialGenericBannerProps } from "../TrialGenericBanner";
import { ITrialGenericCardProps } from "../TrialGenericCard";
import TrialBanner from "./TrialBanner";

import "./TrialCard.scss";

export default function TrialCard(props: ITrialGenericCardProps) {
	const enhancedProps: ITrialGenericBannerProps = {
		...props,
		className: "TrialCard",
	};

	return <TrialBanner {...enhancedProps} />;
}
