import { ETrialLevel, EVariableScope } from "shared-type";

import { IUnitIdentifiers } from "App/components/form/task/observation-round/ObservationRoundForm";
import { ITemplateObsLinkedVar } from "./components/templates/observation/TemplateObsEditionPage.utils";
import { DIC, EDIC_KEY } from "./dictionary";

export const defaultTemplateObsLinkedVar: ITemplateObsLinkedVar[] = [
	{
		varName: DIC(EDIC_KEY.CLIMATE),
		variableScope: EVariableScope.STANDARD,
		checked: true,
	},
	{
		varName: DIC(EDIC_KEY.SOIL_TYPE),
		variableScope: EVariableScope.STANDARD,

		checked: true,
	},
];

export const defaultUnitGenotypeIdentifiers: IUnitIdentifiers[] = [
	{
		unitName: ETrialLevel.TRIAL,
		identifiers: ["Trial Name"],
	},
	{
		unitName: ETrialLevel.MATERIAL,
		identifiers: ["Genotype Name", "Species ID", "Variety"],
	},
];

export const defaultUnitLotIdentifiers: IUnitIdentifiers[] = [
	{
		unitName: ETrialLevel.TRIAL,
		identifiers: ["Trial Name"],
	},
	{
		unitName: ETrialLevel.MATERIAL,
		identifiers: ["Lot ID", "Species ID", "Variety"],
	},
];
