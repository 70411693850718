import React from "react";

import { INotebookSerialized } from "shared-type";

import NotebookBanner, { INotebookBannerProps } from "./NotebookBanner";

import "./NotebookCard.scss";

export interface INotebookCardProps {
	notebook: INotebookSerialized;
	onClick?: () => void;
	disabledClick?: boolean;
}

export default function NotebookCard(props: INotebookCardProps) {
	const enhancedProps: INotebookBannerProps = {
		...props,
		className: "NotebookCard",
	};

	return <NotebookBanner {...enhancedProps} />;
}
