"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseTaskUniqueKey = exports.ETaskType = exports.ETaskStatus = void 0;
var ETaskStatus;
(function (ETaskStatus) {
    ETaskStatus["DRAFT"] = "draft";
    ETaskStatus["READY"] = "ready";
    ETaskStatus["IN_PROGRESS"] = "in progress";
    ETaskStatus["COMPLETED"] = "completed";
})(ETaskStatus = exports.ETaskStatus || (exports.ETaskStatus = {}));
var ETaskType;
(function (ETaskType) {
    ETaskType["OBSERVATION_ROUND"] = "observation round";
    ETaskType["OPERATION"] = "operation";
})(ETaskType = exports.ETaskType || (exports.ETaskType = {}));
exports.BaseTaskUniqueKey = ["identifier"];
