import React from "react";

import { IUser } from "shared-type";

import { Icon } from "stories/base-components/Icon/Icon";
import Colors from "stories/constants/Colors/Colors";
import { Typography } from "stories/base-components/Typography/Typography";
import { Initials } from "stories/base-components/Initials/Initials";
import { StoryTheme } from "stories/StoryTheme";

import "./chip.scss";

export type IChip = {
	label: string;
	user?: Partial<IUser>;
	onClose?: (event: any) => void;
	className?: string;
	color?: string;
	// activeColor is used for a different color of initials and close button
	activeColor?: string;
	backgroundColor?: string;
};

export const Chip = (chip: IChip) => {
	return (
		<StoryTheme>
			<div
				className="chipContainer"
				style={{
					backgroundColor: chip.backgroundColor
						? chip.backgroundColor
						: Colors.primary20,
				}}
			>
				<div
					className={
						"chip" + (chip.className ? " " + chip.className : "")
					}
				>
					{chip.user && (
						<div className="chipUserContainer">
							<Initials
								firstName={chip.user.firstName}
								lastName={chip.user.lastName}
								backgroundColor={
									// eslint-disable-next-line no-nested-ternary
									chip.activeColor
										? chip.activeColor
										: chip.color
										? chip.color
										: Colors.primary60
								}
							/>
						</div>
					)}
					<Typography
						variant="heading2"
						text={chip.label}
						color={chip.color ? chip.color : Colors.primary60}
					/>
					<div className="chipCloseContainer" onClick={chip.onClose}>
						<Icon
							name="x-small"
							color={
								// eslint-disable-next-line no-nested-ternary
								chip.activeColor
									? chip.activeColor
									: chip.color
									? chip.color
									: Colors.primary60
							}
						/>
					</div>
				</div>
			</div>
		</StoryTheme>
	);
};
