import { getter } from "@progress/kendo-react-common";
import { GridRowProps } from "@progress/kendo-react-grid";
import React from "react";

export const ROW_DISABLE_FIELD = "isDisabledRow";
export const isDisabledRowGetter = getter(ROW_DISABLE_FIELD);

export const RowDisableRenderer = (
	trElement: React.ReactElement<HTMLTableRowElement>,
	props: GridRowProps,
) => {
	const isDisabledRow = isDisabledRowGetter(props.dataItem);
	const disabledStyle = { color: "grey", opcity: "0.45" };
	const trProps: any = {
		...trElement.props,
		style: {
			...trElement.props.style,
			...(isDisabledRow ? disabledStyle : undefined),
		},
	};
	return React.cloneElement(
		trElement,
		{ ...trProps },
		trElement.props.children as React.ReactNode,
	);
};
