import React, { useMemo, useState } from "react";

import { formatString } from "common";
import { NB_MAX_TRIAL_BY_MET } from "shared-type";

import { DIC, EDIC_KEY } from "../../../../../../dictionary";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../../../../hooks/reduxHook";
import { selectECW } from "../../../../../../redux/ECW/ECW.selector";
import { ECWAction } from "../../../../../../redux/ECW/ECW.slice";
import { selectMETTrialManagement } from "../../../../../../redux/experiment/met/met-trial-management.select";
import { METTrialManagementActions } from "../../../../../../redux/experiment/met/met-trial-management.slice";
import DorianeButton from "../../../../../base-components/button/DorianeButton";
import Combobox from "../../../../../base-components/combobox/Combobox";
import InputText from "../../../../../base-components/input-text/InputText";
import DorianeModal from "../../../../../base-components/modal/DorianeModal";
import { EPaddingSize } from "../../../../../base-components/placement/box/Box";
import FlexBox, {
	EGapSize,
} from "../../../../../base-components/placement/flex-box/FlexBox";

type GenerationModalProps = {
	onClose: () => void;
	METIsReady?: boolean;
	METName?: string;
};

export function GenerationModal(props: GenerationModalProps) {
	const dispatch = useAppDispatch();
	const nbTrialRemainingInDraft = useAppSelector(
		selectECW.trialGenerationPart.nbTrialRemaining,
	);
	const nbTrialRemainingInReady = useAppSelector(
		selectMETTrialManagement.nbTrialRemaining,
	);
	const nbTrialRemaining = props.METIsReady
		? nbTrialRemainingInReady
		: nbTrialRemainingInDraft;

	const [generationMethod, setGenerationMethod] = useState<
		"quantity" | "growingArea"
	>("quantity");
	const [quantity, setQuantity] = useState(0);
	const isGenerateEnable = useMemo(() => {
		if (generationMethod === "quantity" && quantity !== 0) {
			return true;
		}
		return false;
	}, [generationMethod, quantity]);

	const generateTrials = () => {
		if (generationMethod === "quantity") {
			if (!props.METIsReady) {
				dispatch(
					ECWAction.trialGenerationGenerateTrialByQuantity({
						quantity: Number(quantity),
					}),
				);
			} else {
				dispatch(
					METTrialManagementActions.generateTrialByQuantity({
						quantity: Number(quantity),
						baseName: props.METName || "Unknown MET",
					}),
				);
			}
		}
		props.onClose();
	};
	return (
		<DorianeModal
			onClose={props.onClose}
			title={DIC(EDIC_KEY.ECW_TRIAL_GEN__PAGE_TITLE)}
			closeOnOutsideClick={true}
		>
			<FlexBox
				flexDirection={"column"}
				gap={EGapSize.SMALL}
				padding={EPaddingSize.MEDIUM}
			>
				<div>{DIC(EDIC_KEY.ECW_TRIAL_GEN__GEN_METHOD)}</div>
				<Combobox<"quantity" | "growingArea">
					value={generationMethod}
					label={""}
					onChange={setGenerationMethod}
					comboboxValues={["quantity", "growingArea"]}
					translateMethod={(elt) =>
						(elt === "quantity"
							? DIC(EDIC_KEY.ECW_TRIAL_GEN__METHOD_QUANTITY)
							: DIC(EDIC_KEY.ECW_TRIAL_GEN__METHOD_GA))
					}
				></Combobox>
				{generationMethod === "quantity" && (
					<>
						<div>
							{DIC(EDIC_KEY.ECW_TRIAL_GEN__HOW_MANY_TRIAL_TO_GEN)}
						</div>
						<InputText
							label="Number"
							value={quantity}
							helperText={formatString(
								DIC(EDIC_KEY.ECW_TRIAL_GEN__NB_MAX_TRIAL),
								NB_MAX_TRIAL_BY_MET.toString(),
								nbTrialRemaining.toString(),
							)}
							onChange={(event) => {
								setQuantity(
									Math.min(
										Number(
											event.target.value
												?.replace(/[^0-9]+/g, "")
												.substring(0, 3),
										),
										nbTrialRemaining,
									),
								);
							}}
						/>
					</>
				)}
			</FlexBox>
			<FlexBox justifyContent={"flex-end"} gap={EGapSize.SMALL}>
				<DorianeButton onClick={props.onClose}>
					{DIC(EDIC_KEY.CANCEL)}
				</DorianeButton>
				<DorianeButton
					dorianeStyle="primary"
					onClick={generateTrials}
					disabled={!isGenerateEnable}
				>
					{DIC(EDIC_KEY.GENERATE)}
				</DorianeButton>
			</FlexBox>
		</DorianeModal>
	);
}
