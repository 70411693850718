import React from "react";

import { IVariableCore } from "shared-type";

import { DataGridSkeleton } from "stories/base-components/Skeleton/mostUsed/DataGridSkeleton";
import { useGetFilteredGridData } from "../data-grid.utils";
import {
	useGetOntologyVarRow,
	variableColumns,
} from "../default-grid-columns/VariableColumns";
import DorianeDataGrid from "../doriane-data-grid/DorianeDataGrid";

export function VariableDataGrid(props: {
	onRowClick: (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		row: IVariableCore,
	) => void;
}) {
	const { data: ontologyVarList } = useGetOntologyVarRow();
	const { columns, rowsToDisplay } = useGetFilteredGridData({
		columns: variableColumns,
		rows: ontologyVarList,
		withActions: true,
	});

	// Cannot succeeded to get all loading requests status, but better for the moment
	return ontologyVarList.length > 0 ? (
		<DorianeDataGrid<IVariableCore>
			className="full-parent-size"
			columns={columns}
			rows={rowsToDisplay}
			onRowClick={props.onRowClick}
		></DorianeDataGrid>
	) : (
		<DataGridSkeleton />
	);
}
